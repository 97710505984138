import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showSnackbar } from '~/lib/modalServices';
import Logging from '~/logging';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  MenuItem,
  TextField,
} from '@material-ui/core';

import * as selectors from './selectors';
import { actions } from './slice';

const DEFAULT_GROUP = {
  name: '',
  parent_id: null,
  site_assignments: [],
};

export default function NewGroupDialog({ orgid, open, onClose }) {
  const [newGroup, setNewGroup] = React.useState({ ...DEFAULT_GROUP });
  const dispatch = useDispatch();
  const groups = useSelector(selectors.orgData).groups;

  const save = async (newGroup) => {
    if (!newGroup) return;
    try {
      // TODO Action to submit new org to our list
      dispatch(actions.addGroup({ org_id: orgid, ...newGroup }));
      onClose();
    } catch (error) {
      Logging.error('Failed to create new Group', error);
      showSnackbar('error', 'Could not create Group.');
    }
  };

  const handleConfirm = () => {
    save(newGroup);
    setNewGroup({ ...DEFAULT_GROUP });
  };

  const handleCancel = () => {
    setNewGroup({ ...DEFAULT_GROUP });
    onClose();
  };

  const setField = (field, value) => {
    setNewGroup({ ...newGroup, [field]: value });
  };

  const isGroupValid = newGroup.name != '';

  const isComplete = isGroupValid;

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>Create Group</DialogTitle>

      <DialogContent style={{ overflowY: 'auto' }}>
        <List>
          <ListItem>
            <TextField
              fullWidth
              label='Group Name'
              error={newGroup.name == ''}
              value={newGroup.name}
              onChange={(e) => setField('name', e.target.value)}
            />
          </ListItem>
          <ListItem>
            <TextField
              fullWidth
              select
              label='Parent group'
              value={newGroup.parent_id}
              onChange={(e) => setField('parent_id', e.target.value)}>
              <MenuItem value=''>
                <em>No Parent</em>
              </MenuItem>
              {groups &&
                Object.values(groups).map((g) => (
                  <MenuItem key={g.id} value={g.id}>
                    {g.name}
                  </MenuItem>
                ))}
            </TextField>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color='primary'>
          Cancel
        </Button>
        <Button onClick={handleConfirm} color='primary' disabled={!isComplete}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
