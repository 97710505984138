import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { getAnalysisDescriptions } from '~/analysis';
import * as Icons from '~/icons';
import route from '~/lib/activeScene';
import { getAllChannels } from '~/redux/commonSelectors';
import { actions as globalActions } from '~/redux/slices/global';
import ToggleButtonWithTip from '~/shared/ToggleButtonWithTip';

import { ListItemText, Menu, MenuItem } from '@material-ui/core';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { GAevent } from '~/googleAnalytics';

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&.selected': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary, & .MuiListItemText-secondary': {
        color: theme.palette.primary.contrastText,
      },
    },
  },
}))(MenuItem);

function ImageryAnalysisSelector({
  allAnalysisModes,
  setAllAnalysisMode,
  analysisMetrics,
  mapidx,
  ...props
}) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const analysisMode = allAnalysisModes[mapidx];
  const setAnalysisMode = (x) => {
    let newModes = [...allAnalysisModes];
    newModes[mapidx] = x;
    setAllAnalysisMode(newModes);
  };

  return (
    <>
      <ToggleButtonWithTip
        tooltip='Analysis overlay'
        toggled={anchorEl || analysisMode != null}
        onClick={(evt) => setAnchorEl(evt.currentTarget)}
        {...props}>
        <Icons.Analysis />
      </ToggleButtonWithTip>

      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        MenuListProps={{ disablePadding: true }}
        PaperProps={{
          style: {
            // maxHeight: 360,
          },
        }}>
        <StyledMenuItem
          classes={{ root: analysisMode == null ? 'selected' : 'unselected' }}
          onClick={() => setAnalysisMode(null)}>
          <ListItemText primary='None' secondary='Hide analysis overlay' />
        </StyledMenuItem>
        {analysisMetrics.map((id) => {
          const overlay = getAnalysisDescriptions(id);

          return (
            <StyledMenuItem
              key={overlay.id}
              classes={{ root: analysisMode == overlay.id ? 'selected' : 'unselected' }}
              onClick={() => {
                GAevent('Click', `Set analysis overlay ${overlay.id}`, 'setAnalysisMode(overlay.id)');
                setAnalysisMode(overlay.id)
                }}>
              <ListItemText primary={overlay.name} secondary={overlay.tooltip} />
            </StyledMenuItem>
          );
        })}
      </Menu>
    </>
  );
}

const getAnalysisMetrics = createSelector(
  (state) => route.imagesets(state),
  (imagesets) => {
    let analyticsTypes = [];
    imagesets?.forEach((is) => {
      if (is.available_analytics) {
        analyticsTypes.push(...is.available_analytics.map((a) => a.metric));
      }
    }) || [];
    return [...new Set(analyticsTypes)];
  }
);

const mapStateToProps = (state) => ({
  allAnalysisModes: state.global.preferences.analysisMode,
  analysisMetrics: getAnalysisMetrics(state),
});

const mapDispatchToProps = (dispatch) => ({
  setAllAnalysisMode: (x) => dispatch(globalActions.setPreference({ analysisMode: x })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageryAnalysisSelector);
