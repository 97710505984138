import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import * as Icons from '~/icons';
import { actions as noteActions } from '~/redux/slices/notes';
import { actions as uiActions } from '~/redux/slices/ui';
import ImageAttachments from '~/shared/ImageAttachments';
import UploadPhoto from '~/shared/UploadPhoto';
import UserAvatar from '~/shared/UserAvatar';

import {
  Avatar,
  Badge,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Grow,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';

const styles = {};

function PendingNote({ note, discardPendingNote, updatePendingNote, submitPendingNote, classes }) {
  const uploadPhoto = React.useRef();

  const onNewPhoto = (photo) => {
    // var image = new Image()
    // image.src = photo
    // var w = window.open('')
    // w.document.write(image.outerHTML)
    // w.document.close()
    updatePendingNote({ attachments: [...note.attachments, photo] });
  };

  return (
    <>
      <CardContent>
        <div
          style={{
            width: 400,
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: 8,
          }}>
          <ListItemAvatar>
            <UserAvatar userId={note.author_user_id} missingColor={red.A700} />
          </ListItemAvatar>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              maxHeight: 200,
              overflowY: 'auto',
            }}>
            <TextField
              fullWidth
              autoFocus
              multiline
              rowsMax='6'
              label='Note content'
              value={note.note}
              onChange={(event) => updatePendingNote({ note: event.target.value })}
            />
          </div>
        </div>
        <ImageAttachments images={note.attachments} />
      </CardContent>

      <CardActions>
        <Button
          color='primary'
          variant='contained'
          disabled={note.note.length == 0}
          onClick={submitPendingNote}>
          Save
        </Button>
        <Button onClick={discardPendingNote}>Cancel</Button>
        <IconButton
          onClick={() => {
            uploadPhoto.current.trigger();
          }}
          style={{
            marginLeft: 'auto',
          }}>
          <Icons.AddAPhoto />
        </IconButton>
      </CardActions>
      <UploadPhoto ref={uploadPhoto} onNewPhoto={onNewPhoto} />
    </>
  );
}

export default withStyles(styles)(
  connect(
    (state, props) => ({
      note: state.notes.pendingNote,
    }),
    (dispatch) => ({
      submitPendingNote: () => dispatch(noteActions.submitPendingNote()),
      discardPendingNote: () => dispatch(noteActions.discardPendingNote()),
      updatePendingNote: (note) => dispatch(noteActions.updatePendingNote(note)),
    })
  )(PendingNote)
);
