import React from 'react';
import DividerSpacer from '~/shared/DividerSpacer';
import HelpHere from '~/shared/HelpHere';

import { Divider, Typography, useTheme } from '@material-ui/core';

const PREF_KEY = 'analysis_ndvi_anom';
const DEFAULT_PREFS = {};
const CTRL_SZ = [288, 27 * 8];
export const name = 'NDVI Anomaly';
export const description = 'Areas with abnormally low NDVI velocity.';

const WARN_COLOR = '#ff9100';
const ALERT_COLOR = '#ff1744';

export function overlay(key, analysis, cell_data, preferences, colorForNDVI) {
  const analysisOpacity = preferences.analysisOpacity;
  const analysisLabels = preferences.analysisLabels;

  const source = {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: cell_data
        .filter((c) => c.anom > 0)
        .map((c, i) => ({
          type: 'Feature',
          properties: {
            index: i,
            value: c.anom,
            valid: c.valid ? 1 : 0,
          },
          geometry: c.geometry,
        })),
    },
  };

  let colorbins = ['case', ['==', ['get', 'value'], 1], WARN_COLOR, ALERT_COLOR];

  let layers = [];

  layers.push({
    id: `analysis-layer-${name}`,
    type: 'fill',
    source: `analysis-source-${name}`,
    paint: {
      'fill-color': colorbins,
      'fill-opacity': analysisOpacity,
    },
  });

  return [
    {
      // Detail overlay for current trid
      srcid: `analysis-source-${name}`,
      source,
      layers,
    },
  ];
}

function ControlComponent() {
  const theme = useTheme();
  return (
    <div
      style={{
        width: CTRL_SZ[0],
        height: CTRL_SZ[1],
        padding: theme.spacing(1),
      }}>
      <Typography variant='caption' component='p'>
        This analysis mode highlights areas where the NDVI velocity has diverged downward from the
        rest of the field. This indicates that the highlighted area is underperforming and may
        require attention.
      </Typography>
      <DividerSpacer />

      <Typography variant='caption' component='p'>
        <span
          style={{
            padding: 2,
            background: ALERT_COLOR,
            color: theme.palette.getContrastText(ALERT_COLOR),
          }}>
          RED
        </span>{' '}
        indicates an area that has newly been identified as an anomaly in this imagery.
      </Typography>
      <DividerSpacer />
      <Typography variant='caption' component='p'>
        <span
          style={{
            padding: 2,
            background: WARN_COLOR,
            color: theme.palette.getContrastText(WARN_COLOR),
          }}>
          AMBER
        </span>{' '}
        indicates that this area was an anomaly previously, and remains one in the current imagery.
      </Typography>
    </div>
  );
}

export function controls() {
  return {
    width: CTRL_SZ[0],
    height: CTRL_SZ[1],
    component: <ControlComponent />,
  };
}
