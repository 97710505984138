import classNames from 'classnames';
import React from 'react';
import * as Icons from '~/icons';

import { IconButton, MenuItem, TextField, Typography } from '@material-ui/core';
import { lightBlue } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  section: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 12,
    marginRight: 12,
  },
  children: {
    '& > *': {
      margin: 4,
    },
  },
});

class PaginationControl extends React.Component {
  handleLimitChange = (evt) => {
    this.props.onUpdate(this.props.offset, evt.target.value);
  };

  setPage = (i) => {
    this.props.onUpdate(this.props.limit * i, this.props.limit);
  };

  render() {
    const { classes, offset, limit, total, children } = this.props;

    const thisPage = Math.floor(offset / limit);
    const numPages = Math.ceil(total / limit);

    return (
      <div className={classes.root}>
        <Pagination
          count={numPages}
          page={thisPage + 1}
          onChange={(event, page) => {
            this.setPage(page - 1);
          }}
        />

        {/* <div className={classes.section}>
          <IconButton onClick={() => this.setPage(0)} disabled={thisPage == 0}>
            <Icons.First />
          </IconButton>
          <IconButton onClick={() => this.setPage(thisPage - 1)} disabled={thisPage == 0}>
            <Icons.Prev />
          </IconButton>
          <IconButton
            onClick={() => this.setPage(thisPage + 1)}
            disabled={thisPage == numPages - 1}>
            <Icons.Next />
          </IconButton>
          <IconButton
            onClick={() => this.setPage(numPages - 1)}
            disabled={thisPage == numPages - 1}>
            <Icons.Last />
          </IconButton>
          <Typography variant='caption'>
            {Math.min(offset + 1, total)} - {Math.min(offset + limit, total)} of {total}
          </Typography>
        </div>

         */}
        <Typography variant='caption'>{total} results</Typography>
        <div style={{ flexGrow: 1 }} />
        <div className={classes.children}>
          {children}
          <TextField
            style={{ minWidth: 160 }}
            size='small'
            select
            label='Entries Per Page'
            value={limit}
            onChange={this.handleLimitChange}>
            {[10, 20, 30, 40, 50, 100].map((x) => (
              <MenuItem key={x} value={x}>
                {x}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(PaginationControl);
