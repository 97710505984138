import { webrequest } from '~/lib/api';
import { showSnackbar } from '~/lib/modalServices';
import Logging from '~/logging';

import { createSlice } from '@reduxjs/toolkit';

// the column definition given to us for this table. should it come from teh server? yes.
const ORG_COLUMNS = [
  {
    id: 'id',
    title: 'ID',
    category: 'Orgs',
    sortable: true,
    type: 'STR',
    filterable: false,
  },
  {
    id: 'industry',
    title: 'Industry',
    category: 'Orgs',
    sortable: true,
    type: 'STR',
    filterable: false,
  },
  {
    id: 'type',
    title: 'Type',
    category: 'Orgs',
    sortable: true,
    type: 'STR',
    filterable: true,
    filter_opts_str: ['NORMAL', 'DEMO'],
  },
];

// Description of initial state
const slice = createSlice({
  name: 'orglist',
  initialState: {
    pending: true,
    loading: false,
    error: null,
    rows: [],
    columns: ORG_COLUMNS,
  },

  reducers: {
    requestBegin(state) {
      state.pending = true;
      state.loading = false;
      state.error = null;
    },
    requestLoading(state) {
      state.loading = true;
    },
    requestComplete(state, action) {
      const { data, error } = action.payload;

      state.pending = false;
      state.loading = false;
      state.error = error;

      const rowsById = data.orgs.map((r) => {
        let rowById = {};
        ORG_COLUMNS.forEach((c) => {
          rowById[c.id] = r[c.id] || undefined;
        });
        return rowById;
      });

      state.rows = rowsById;
    },
  },
});

const getOrgs = () => async (dispatch) => {
  dispatch(slice.actions.requestBegin());

  const showLoading = setTimeout(() => dispatch(slice.actions.requestLoading()), 500);

  try {
    const data = await webrequest('GET', `orgs`);
    clearTimeout(showLoading);
    dispatch(slice.actions.requestComplete({ data }));
  } catch (error) {
    Logging.error('Failed to get org list', error);
    clearTimeout(showLoading);
    dispatch(slice.actions.requestComplete({ error }));
  }
};

const createOrg = (org) => async (dispatch) => {
  try {
    await webrequest('POST', `orgs`, {
      ...org,
    });
    dispatch(getOrgs);
    showSnackbar('success', 'Created Organization.');
  } catch (error) {
    Logging.error('Failed to create new Org', error);

    showSnackbar('error', 'Could not create Organization.');
  }
};

export default slice.reducer;

export const actions = {
  ...slice.actions,
  getOrgs,
  createOrg,
};
