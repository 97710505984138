import React from 'react';
import * as Icons from '~/icons';
// import { render } from 'react-dom'
import { ACTION_NAMES } from '~/scenes/part/ActionBar';
import SimpleTable from '~/shared/SimpleTable';

import {
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';

function humanFileSize(size) {
  var i = Math.floor(Math.log(size) / Math.log(1024));
  return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

const INITIAL_STATE = {
  isOpen: false,
  action: null,
  edits: null,
  doAction: null,
  changelog: '',
  hasReport: false,
  files: [],
  bypassReport: false,
};

class PartChangeDialog extends React.Component {
  state = INITIAL_STATE;

  handleCancel = () => {
    this.setState(INITIAL_STATE);
    this.resolve(false);
  };

  handleConfirm = () => {
    const { action, edits, doAction, changelog, files } = this.state;

    doAction(action, changelog, edits?.parameters || {}, edits?.subassemblies || {}, files);

    this.setState(INITIAL_STATE);
    this.resolve();
  };

  show = (action, part, edits, hasReport, doAction) => {
    this.setState({ ...INITIAL_STATE, isOpen: true, action, part, edits, doAction, hasReport });
    return new Promise((res) => {
      this.resolve = res;
    });
  };

  render() {
    const { isOpen, action, edits, changelog, files, bypassReport, hasReport } = this.state;

    // This is the human-readable name for this action code.
    const actionName = action ? ACTION_NAMES[action] : null;

    // Some actions include reports
    const requiredReport = !hasReport
      ? false
      : {
          update: false,
          activate: false,
          bringup_complete: true,
          deactivate: false,
          defect_resolved: true,
          found_defect: true,
          maintain: false,
          maintenance_done: true,
          mfg_begin: false,
          mfg_complete: true,
          repair: true,
          repair_done: true,
          req_remfg: true,
          retire: false,
        }[action];

    const uploadFile = ({ target }) => {
      this.setState({
        ...this.state,
        files: [...(this.state.files || []), ...target.files],
      });
    };

    const removeFile = (file) => {
      this.setState({
        ...this.state,
        files: this.state.files.filter((f) => f != file),
      });
    };

    return (
      <Dialog open={isOpen} onClose={this.handleCancel} fullWidth maxWidth='sm'>
        <DialogTitle>Confirm: {actionName}</DialogTitle>
        <Divider />
        <DialogContent>
          {edits ? (
            <>
              <Typography variant='subtitle1'>Changes will be committed:</Typography>
              {edits.parameters && (
                <SimpleTable
                  data={Object.keys(edits.parameters).map((k) => ({
                    title: k,
                    value: edits.parameters[k],
                  }))}
                />
              )}
              {edits.subassemblies && (
                <SimpleTable
                  data={Object.keys(edits.subassemblies).map((k) =>
                    edits.subassemblies[k] == null
                      ? {
                          // Removed
                          title: k,
                          value: `Unset`,
                        }
                      : {
                          // Changed
                          title: k,
                          value: `${edits.subassemblies[k].type} ${edits.subassemblies[k].sn}`,
                        }
                  )}
                />
              )}
            </>
          ) : (
            <Typography variant='caption'>
              No additional changes will be committed at this time.
            </Typography>
          )}
        </DialogContent>
        {requiredReport ? (
          <>
            <Divider />
            <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography gutterBottom variant='body1'>
                This action requires that you submit attachments as a report.
              </Typography>
              <Paper>
                <input type='file' onChange={uploadFile} id='upload-part-report' multiple hidden />

                <Collapse in={!bypassReport}>
                  <Button
                    htmlFor='upload-part-report'
                    color='primary'
                    variant={files == null || !files.length ? 'contained' : 'outlined'}
                    component='label'
                    type='submit'
                    style={{ margin: 4 }}>
                    Upload Report Files
                  </Button>
                </Collapse>
                <List dense>
                  {files && files.length > 0 ? (
                    files.map((file, i) => (
                      <ListItem key={i}>
                        <ListItemText
                          primary={file.name}
                          secondary={`${file.type}, ${humanFileSize(file.size)}`}
                        />
                        <ListItemSecondaryAction>
                          <IconButton onClick={() => removeFile(file)}>
                            <Icons.Delete />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))
                  ) : (
                    <>
                      <ListItem>
                        <Typography color={bypassReport ? undefined : 'error'}>
                          No Files Attached
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={bypassReport}
                              onChange={(evt) =>
                                this.setState({
                                  ...this.state,
                                  bypassReport: evt.target.checked,
                                })
                              }
                              value='bypassReportCheckbox'
                            />
                          }
                          label='Submit without report'
                        />
                      </ListItem>
                    </>
                  )}
                </List>
              </Paper>
            </DialogContent>
          </>
        ) : (
          <>
            <Divider />

            <DialogContent>
              <Typography gutterBottom variant='body1'>
                No report required for this action.
              </Typography>
            </DialogContent>
          </>
        )}
        <Divider />
        <DialogContent>
          <TextField
            autoFocus
            margin='dense'
            rows={3}
            multiline
            id='changelog'
            label='Describe the reason for this update'
            fullWidth
            value={changelog}
            onChange={(e) => this.setState({ ...this.state, changelog: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel}>Cancel</Button>
          <Button
            onClick={this.handleConfirm}
            color='primary'
            disabled={
              !changelog || ((files == null || !files.length) && requiredReport && !bypassReport)
            }>
            {actionName}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default PartChangeDialog;
