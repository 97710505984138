import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { createSelector } from 'reselect';
import { titleCase } from '~/lib/names';

import { Button, Typography } from '@material-ui/core';

import PartPage from './PartPage';
import { matches } from './selectors';
import { actions } from './slice';

export { default as slice } from './slice';

export { matches };

export const title = createSelector(matches, (params) => `${titleCase(params.type)} ${params.sn}`);
export const breadcrumbs = createSelector(matches, (params) => [
  { name: 'Hardware Management', to: '/hardware' },
  `${titleCase(params.type)} ${params.sn}`,
]);
export const component = () => <PartPage />;

export const fetch = createSelector(
  (state) => matches(state).type,
  (state) => matches(state).sn,
  (type, sn) => (dispatch) => {
    dispatch(actions.getPartData(type, sn));
  }
);

export const helpTopic = () => 'Manage Part';
