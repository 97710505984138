import { DateTime, Duration } from 'luxon';
import React from 'react';
import * as Icons from '~/icons';
import history from '~/lib/history';
import { showSnackbar } from '~/lib/modalServices';
import { showConfirmDialog } from '~/lib/modalServices';
import { useSVSelector } from '~/redux/hooks';
import { Goal, ScheduleData } from '~/schema';
import NoFlyTime from '~/schema/NoFlyTime';
import ScheduledFlightRequest from '~/schema/ScheduledFlightRequest';

import {
  Button,
  Container,
  Grid,
  IconButton,
  Paper,
  Toolbar,
  Tooltip,
  makeStyles,
} from '@material-ui/core';

import EventScheduleList from './EventScheduleList';
import GoalEditButton from './GoalEditButton';
import LoadingBackdrop from './LoadingBackdrop';
import NoFlyEditDialog from './NoFlyEditDialog';
import SFREditDialog from './SFREditDialog';
import { deleteNofly, deleteSFR, getScheduleData, uploadGoal, uploadNoFly, uploadSFR } from './api';

interface Props {
  day0: string;
  siteId: string;
}

const style = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    background: theme.palette.background.default,
    maxHeight: '100%',
    maxWidth: '100%',
    padding: theme.spacing(1),
    overflowY: 'auto',
  },
}));

export default function SchedulePage({ day0, siteId }: Props) {
  const classes = style();

  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState<ScheduleData>({
    tz: 'UTC',
    baseSns: [],
    location: [0, 0],
    flights: [],
    scheduledFlightRequests: [],
    noFlyInstances: [],
    noFlyTimes: [],
    regionsForKeepouts: [],
    surveysAvailable: [],
    goals: [],
  });

  const [now, setNow] = React.useState(getNow());

  const [sfrToEdit, setSfrToEdit] = React.useState<ScheduledFlightRequest>();
  const [noFlyToEdit, setNoFlyToEdit] = React.useState<NoFlyTime>();

  const sitePermissions = useSVSelector((state) => state.global.site_permissions[siteId]);
  const setDay0 = React.useCallback(
    (x: DateTime) => history.push(`schedule?site=${siteId}&d=${x.toISODate()}`),
    [siteId]
  );

  // There are a few permissions that control what we can do on this page.
  // GET_SCHEDULE is required to view this at all.
  // EDIT_FLIGHT_REQUESTS applies to the site
  const canEditRequests = sitePermissions.includes('EDIT_FLIGHT_REQUESTS');
  // MODIFY_GOAL applies to the goal itself, which comes from teh site
  const canSeeFlightLog = sitePermissions.includes('SEE_FLIGHT_LOGS');
  // SEE_FLIGHT_LOGS lets us see the link to the flight log.
  const canEditGoals = sitePermissions.includes('MODIFY_GOAL');

  const daydt0 = React.useMemo(() => DateTime.fromISO(day0, { zone: data.tz }), [day0, data.tz]);

  // useEffect here to make webservice calls. Have a loading state?
  const refreshData = React.useCallback(async () => {
    setLoading(true);
    const result = await getScheduleData(siteId, daydt0.plus({ days: -1 }).toISODate(), 3);
    setData(result);
    setLoading(false);
  }, [siteId, daydt0]);

  React.useEffect(() => {
    refreshData().then();
  }, [refreshData]);

  const onDeleteNoFly = React.useCallback(
    async (noflyid: number) => {
      if (
        await showConfirmDialog(
          'Remove No-fly time',
          'This will remove the no-fly time and all instances.',
          'Remove'
        )
      ) {
        try {
          setLoading(true);

          await deleteNofly(noflyid);
          refreshData();
        } catch {
          // We failed :()
        }
        setLoading(false);
      }
    },
    [refreshData]
  );
  const onChangeNoFly = React.useCallback(
    async (nofly: NoFlyTime) => {
      setLoading(true);
      // TODO: modifying one datetime... we should ask if we want to make an exception or do all of them.

      // We preemptively update our data assuming that the change goes through.
      try {
        await uploadNoFly(nofly);
        refreshData(); // Server calculates our isntances so we need to re-request.
      } catch {
        // err, we've already toasted
      }
      setLoading(false);
    },
    [refreshData]
  );
  const onDeleteSFR = React.useCallback(
    async (sfrid: number) => {
      if (
        await showConfirmDialog(
          'Remove Scheduled Flight',
          'This will remove this flight from the schedule.',
          'Remove'
        )
      ) {
        setLoading(true);
        const allSfrs = [...data.scheduledFlightRequests];
        const otherSfrs = [...data.scheduledFlightRequests.filter((x) => x.id != sfrid)];

        setData({
          ...data,
          scheduledFlightRequests: otherSfrs,
        });
        try {
          await deleteSFR(sfrid);
          refreshData();
        } catch {
          // If it fails to delete, revert to the old data.
          setData({
            ...data,
            scheduledFlightRequests: allSfrs,
          });
        }
        setLoading(false);
      }
    },
    [data, refreshData]
  );
  const onChangeSFR = React.useCallback(
    async (sfr: ScheduledFlightRequest) => {
      if (sfr.expected_datetime < DateTime.now()) {
        showSnackbar('warning', `Cannot move to the past.`);
        return;
      }
      setLoading(true);

      const otherSfrs = [...data.scheduledFlightRequests.filter((x) => x.id != sfr.id)];

      // We preemptively update our data assuming that the change goes through.
      setData({
        ...data,
        scheduledFlightRequests: [...otherSfrs, sfr],
      });

      try {
        const newSfr = await uploadSFR(sfr);

        // Once we get the server version, replace it again.
        setData({
          ...data,
          scheduledFlightRequests: [...otherSfrs, newSfr],
        });
        refreshData();
      } catch {
        // If it fails to upload, revert to the old data.
        setData({
          ...data,
          scheduledFlightRequests: otherSfrs,
        });
      }
      setLoading(false);
    },
    [data, refreshData]
  );
  const onChangeGoal = React.useCallback(
    async (goal: Goal) => {
      setLoading(true);
      // We preemptively update our data assuming that the change goes through.
      const otherGoals = [...data.goals.filter((x) => x.id != goal.id)];
      setData({
        ...data,
        goals: [...otherGoals, goal],
      });

      try {
        const newGoal = await uploadGoal(goal);

        // Once we get the server version, replace it again.
        setData({
          ...data,
          goals: [...otherGoals, newGoal],
        });
        refreshData();
      } catch {
        // If it fails to upload, revert to the old data.
        setData({
          ...data,
          goals: otherGoals,
        });
      }
      setLoading(false);
    },
    [data, refreshData]
  );

  function getNow() {
    return DateTime.now().setZone(data.tz);
  }

  const isToday = now.toISODate() == daydt0.toISODate();

  React.useEffect(() => {
    // Every 5 minutes, update Now.
    const i = window.setInterval(() => setNow(getNow()), 5 * 60 * 1000);
    return () => window.clearInterval(i);
  });

  const requestDelta = React.useCallback(
    (delta: number) => {
      setDay0(daydt0.plus({ days: delta }));
    },
    [daydt0, setDay0]
  );

  const requestToday = React.useCallback(() => {
    setDay0(now);
  }, [now, setDay0]);

  return (
    <div className={classes.root}>
      <Container maxWidth='md'>
        <Paper>
          <Toolbar>
            <Button
              aria-label='previous day'
              size='small'
              style={{ minWidth: 32 }}
              onClick={() => requestDelta(-1)}>
              <Icons.Prev />
            </Button>
            <Button aria-label='today' size='small' onClick={requestToday} disabled={isToday}>
              Today
            </Button>
            <Button
              aria-label='next day'
              size='small'
              style={{ minWidth: 32 }}
              onClick={() => requestDelta(1)}>
              <Icons.Next />
            </Button>

            <div style={{ flexGrow: 1 }} />

            {canEditRequests && (
              <>
                <Tooltip title='Request a Flight'>
                  <IconButton
                    onClick={() =>
                      setSfrToEdit({
                        // An empty SFR
                        id: undefined,
                        base_sn: data.baseSns[0],
                        survey_id: undefined,
                        expected_datetime: DateTime.fromObject({
                          year: daydt0.year,
                          month: daydt0.month,
                          day: daydt0.day,
                          hour: now.hour + 1,
                        }),
                        expected_duration_s: Duration.fromMillis(0),
                        processing_parameters_id: undefined,
                        author: undefined,
                      })
                    }>
                    <Icons.Add />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Create No-Fly Time'>
                  <IconButton
                    onClick={() => {
                      setNoFlyToEdit({
                        site_id: siteId,
                        region_ids: [],
                        description: '',
                        start_datetime: daydt0.plus({ hours: now.hour + 1 }),
                        duration_minutes: Duration.fromObject({ minutes: 60 }),
                        timezone: data.tz,
                        exception_datetimes: [],
                        extra_datetimes: [],
                      });
                    }}>
                    <Icons.Close />
                  </IconButton>
                </Tooltip>
              </>
            )}
            {canEditGoals && (
              <GoalEditButton
                siteId={siteId}
                onChangeGoal={onChangeGoal}
                surveysAvailable={data.surveysAvailable}
                goals={data.goals}
              />
            )}
          </Toolbar>

          <div style={{ margin: 12 }}>
            <Grid container spacing={2}>
              {[-1, 0, 1].map((daysAhead) => (
                <Grid item xs key={daysAhead}>
                  <EventScheduleList
                    siteid={siteId}
                    day={daydt0.plus({ days: daysAhead })}
                    data={data}
                    onChangeSFR={onChangeSFR}
                    onChangeNoFly={onChangeNoFly}
                    onDeleteSFR={onDeleteSFR}
                    onDeleteNoFly={onDeleteNoFly}
                    loading={loading}
                    canEditRequests={canEditRequests}
                    canSeeFlightLog={canSeeFlightLog}
                    setNoFlyToEdit={setNoFlyToEdit}
                    setSfrToEdit={setSfrToEdit}
                    now={now}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </Paper>
      </Container>

      <SFREditDialog
        tz={data.tz}
        event={sfrToEdit}
        onClose={() => setSfrToEdit(undefined)}
        availableBases={data.baseSns}
        availableSurveys={data.surveysAvailable}
        updateEvent={async (evt) => {
          await onChangeSFR(evt);
          setSfrToEdit(undefined);
        }}
        deleteEvent={async (evt) => {
          if (evt.id !== undefined) await onDeleteSFR(evt.id);
          setSfrToEdit(undefined);
        }}
      />
      <NoFlyEditDialog
        tz={data.tz}
        open={noFlyToEdit != null}
        onClose={() => setNoFlyToEdit(undefined)}
        updateEvent={async (evt) => {
          await onChangeNoFly(evt);
          setNoFlyToEdit(undefined);
        }}
        deleteEvent={async (evt) => {
          if (evt.id !== undefined) await onDeleteNoFly(evt.id);
          setNoFlyToEdit(undefined);
        }}
        event={noFlyToEdit}
        avaialbleRegions={data.regionsForKeepouts}
      />
      <LoadingBackdrop delay={200} loading={loading} />
    </div>
  );
}
