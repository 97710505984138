import React from 'react';
import { term } from '~/markets';

import { Link, Menu, MenuItem } from '@material-ui/core';

const FieldSelector = ({ fields, field, onSelect }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => setAnchorEl(null);
  const handleSelect = (fieldid) => () => {
    onSelect(fieldid);
    setAnchorEl(null);
  };

  return (
    <>
      <Link variant='h6' color='inherit' onClick={(evt) => setAnchorEl(evt.currentTarget)}>
        {field?.display_name || `${fields.length} ${term('Assets')}`}
      </Link>

      <Menu open={!!anchorEl} anchorEl={anchorEl} keepMounted onClose={handleClose}>
        {[...fields]
          .sort((a, b) => a.display_name.localeCompare(b.display_name))
          .map((f) => (
            <MenuItem key={f.id} onClick={handleSelect(f.id)} selected={f.id == field?.id}>
              {f.display_name}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

export default FieldSelector;
