import classNames from 'classnames';
import React from 'react';
import * as Icons from '~/icons';
import { findDataRegions } from '~/lib/geography';
import { Asset, Region, TargetRequest } from '~/schema';

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Collapse,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import targetRequestControls from './targetRequestControls';
import { RequestType, TargetArea } from '~/schema/TargetRequest';
import { useSVDispatch, useSVSelector } from '~/redux/hooks';

import * as selectors from './selectors';
import { DraftsSharp } from '@material-ui/icons';

const styles = makeStyles((theme) => ({
  buttonList: {
    display: 'flex',
    flexGrow: 1,
  },

  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

interface Props {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  targetAreas: TargetArea[];
  ceiling: number;
  trq: TargetRequest;
  onUpdate: (trq: TargetRequest) => void;
}

export default function EditableTargetRequest({
  expanded,
  setExpanded,
  targetAreas,
  ceiling,
  trq,
  onUpdate,
}: Props) {
  type DataCaptureArea = { id: number; display_name: string };

  const classes = styles();

  const regionsByType = useSVSelector(selectors.getKindsOfRegions);

  const allAssets = useSVSelector(selectors.allAssets);

  const allRegions = React.useMemo(
    () => [...regionsByType.available, ...regionsByType.flight],
    [regionsByType]
  );
  const allDataCapAreas = React.useMemo(() => regionsByType.availableData, [regionsByType]);

  const undeletedRegions = allRegions.filter((r) => !r.deleted);

  const editField = (x: Partial<TargetRequest>) => {
    const editedTarget = { ...trq, ...x };
    onUpdate(editedTarget);
  };

  const dataCapAreas = React.useMemo(() => {
    const drs: DataCaptureArea[] = [];

    const calculateDataCapAreas = (flight_region_id: number) => {
      const flightRegion = allRegions.find((f) => f.id == flight_region_id);

      if (flightRegion != null) {
        const containedDRs = findDataRegions(flightRegion, allDataCapAreas);
        if (containedDRs)
          drs.push(...containedDRs.map((x) => ({ id: x.id, display_name: x.display_name })));
      }
    };

    // Directly imaging an asset counts as a data region.
    for (const targetArea of trq?.target_areas || []) {
      if (targetArea.asset_id) {
        // Assets are always data regions when we target them. So are all their children.
        const asset = allAssets.find((x) => x.id == targetArea.asset_id);
        if (asset) drs.push({ id: asset.id, display_name: asset.display_name });
      } else if (targetArea.flight_region_id) {
        calculateDataCapAreas(targetArea.flight_region_id);
      }
    }

    return drs;
  }, [allDataCapAreas, allAssets, trq?.target_areas, allRegions]);

  let altitudesToShow = [50, 25, 10];
  for (let x = 100; x < Math.floor(ceiling * 3.28084); x += 50) {
    altitudesToShow = [x, ...altitudesToShow];
  }

  const sortedRegions = [...undeletedRegions].sort((a, b) =>
    a.display_name.localeCompare(b.display_name)
  );

  const trqControl = targetRequestControls[trq.request_type];

  const targetAreaObjects = [
    ...allAssets.filter((x) => targetAreas.find((y) => y.asset_id == x.id)),
    ...allRegions.filter((x) => targetAreas.find((y) => y.flight_region_id == x.id)),
  ];

  return (
    <Card style={{ margin: 4 }}>
      <CardContent>
        <div style={{ display: 'flex', alignItems: 'space-between' }}>
          <div>
            <Typography variant='subtitle1'>
              {targetAreaObjects.map((a) => a.display_name).join(',')}
            </Typography>
            <trqControl.Subtitle trq={trq} />
          </div>

          <IconButton
            className={classNames(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={() => setExpanded(!expanded)}>
            <Icons.ExpandMore />
          </IconButton>
        </div>
      </CardContent>
      <Collapse in={expanded}>
        <div>
          {trqControl.operatesOn.includes('assets') && (
            <FormControl fullWidth variant='filled'>
              <InputLabel id='asset-select-label'>Asset(s) to target</InputLabel>
              <Select
                labelId='asset-select-label'
                multiple
                value={trq.target_areas.filter((x) => x.asset_id).map((x) => x.asset_id)}
                onChange={(e) =>
                  editField({
                    target_areas: (e.target.value as string[]).map((x) => ({
                      asset_id: parseInt(x),
                    })),
                  })
                }
                input={<Input />}>
                {allAssets.map((asset) => (
                  <MenuItem key={asset.id} value={asset.id}>
                    {/* This needs to be a single string or the select displays it with commas... */}
                    {`${asset.type}:${asset.display_name}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {trqControl.operatesOn.includes('region') && (
            <FormControl fullWidth>
              <InputLabel htmlFor='flightregion'>Flight Region</InputLabel>

              <Select
                inputProps={{
                  name: 'flightregion',
                  id: 'flightregion',
                }}
                value={
                  trq.target_areas.find((x) => x.flight_region_id !== undefined)
                    ?.flight_region_id || ''
                }
                onChange={(e) => {
                  editField({
                    target_areas: [{ flight_region_id: parseInt(e.target.value as string) }],
                  });
                }}>
                <MenuItem value=''>None</MenuItem>
                {sortedRegions.map((r) => (
                  <MenuItem key={r.id} value={r.id}>
                    {r.display_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {dataCapAreas && dataCapAreas?.length > 1 ? (
            <div>
              <Typography variant='caption'>This target request collects data for:</Typography>
              <ul>
                {dataCapAreas.map((r) => (
                  <Typography variant='caption' key={r.id} component='li'>
                    {r.display_name}
                  </Typography>
                ))}
              </ul>
            </div>
          ) : !dataCapAreas || dataCapAreas?.length == 0 ? (
            <Typography variant='caption'>Data not collected from this region!</Typography>
          ) : null}
          <trqControl.Control
            {...{
              trq,
              editField,
              ceiling,
              targetAreas,
            }}
          />
        </div>
        <CardActions>
          <Button size='small' onClick={() => editField({ deleted: true })}>
            Remove
          </Button>
        </CardActions>
      </Collapse>
    </Card>
  );
}
