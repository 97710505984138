import * as React from 'react';
import * as Icons from '~/icons';
import { useSVSelector } from '~/redux/hooks';
import { Goal, Survey } from '~/schema';

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import DateRange from '@material-ui/icons/DateRange';

import GoalEditDialog from './GoalEditDialog';

export default function GoalEditButton(props: {
  siteId: string;
  onChangeGoal: (goal: Goal) => Promise<void>;
  surveysAvailable: Survey[];
  goals: Goal[];
}) {
  const [goalToEdit, setGoalToEdit] = React.useState<Goal | undefined>();
  const [open, setOpen] = React.useState(false);

  const closeDialog = React.useCallback(() => setOpen(false), [setOpen]);
  const openDialog = React.useCallback(() => setOpen(true), [setOpen]);

  const siteName = useSVSelector(
    (state) => state.global.farms[props.siteId]?.location || 'Unknown'
  );

  const nameForSurvey = React.useCallback(
    (surveyId: number) => {
      return props.surveysAvailable.find((s) => s.id == surveyId)?.display_name || 'Unknown';
    },
    [props.surveysAvailable]
  );

  return (
    <>
      <Tooltip title='Modify Goals'>
        <IconButton onClick={openDialog}>
          <Icons.Goal />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={closeDialog} maxWidth='sm' fullWidth>
        <DialogTitle>Imaging Goals for {siteName}</DialogTitle>

        <List dense disablePadding>
          {props.goals.map((goal) => {
            const editable =
              ['FREQUENT_AS_POSSIBLE', 'ONCE_PER_DAY'].includes(goal.goal_kind) || true;

            return (
              <ListItem key={goal.id}>
                <ListItemIcon>
                  <Icons.Goal />
                </ListItemIcon>
                {goal.goal_kind == 'FREQUENT_AS_POSSIBLE' ? (
                  <ListItemText
                    primary={goal.survey_ids_to_schedule?.map(nameForSurvey).join(',')}
                    secondary='Image as frequently as possible'
                  />
                ) : goal.goal_kind == 'ONCE_PER_DAY' ? (
                  <ListItemText
                    primary={goal.survey_ids_to_schedule?.map(nameForSurvey).join(',')}
                    secondary='Image once per day'
                  />
                ) : goal.goal_kind == 'STOCKPILE_REPORTS' ? (
                  <ListItemText primary='Stockpile Reports' secondary='Automatically Generated' />
                ) : (
                  <ListItemText primary='Unknown goal type' secondary='' />
                )}

                {editable ? (
                  <>
                    <ListItemSecondaryAction>
                      <IconButton onClick={() => setGoalToEdit(goal)}>
                        <Icons.Edit />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </>
                ) : (
                  <></>
                )}
              </ListItem>
            );
          })}

          {props.goals.length == 0 && (
            <ListItem>
              <ListItemIcon>
                <DateRange />
              </ListItemIcon>
              <ListItemText primary='No goals programmed for this site.' />
            </ListItem>
          )}
        </List>
        <DialogActions>
          <Button onClick={closeDialog}>Close</Button>
          <Button
            color='primary'
            onClick={() =>
              setGoalToEdit({
                id: undefined,
                author_id: undefined,
                site_id: props.siteId,
                goal_kind: 'ONCE_PER_DAY', // The default
                enabled: true,
                survey_ids_to_schedule: undefined,
              })
            }>
            Add Imagery Goal
          </Button>
        </DialogActions>
      </Dialog>

      <GoalEditDialog
        open={goalToEdit != null}
        onClose={() => setGoalToEdit(undefined)}
        updateGoal={async (goal) => {
          setGoalToEdit(undefined); // close the dialog
          closeDialog();
          await props.onChangeGoal(goal);
        }}
        goal={goalToEdit}
        availableSurveys={props.surveysAvailable.filter((s) => s.type == 'RECURRING') || []}
      />
    </>
  );
}
