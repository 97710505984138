import React from 'react';
import { createSelector } from 'reselect';
import * as globalSelctors from '~/redux/selectors/global';

import OrgPage from './OrgPage';
import { matches } from './selectors';
import slice, { actions } from './slice';

export { slice, matches };

export const title = createSelector(matches, (params) => `Manage ${params.orgid}`);

export const breadcrumbs = createSelector(
  // TODO: update this to get the name of the org
  matches,
  (params) => [{ name: 'Manage Organizations', to: '/orglist' }, params.orgid]
);

export const component = (state) => <OrgPage />;

export const fetch = createSelector(matches, (params) => (dispatch) => {
  dispatch(actions.getOrgData(params.orgid));
});

export const helpTopic = () => 'Organizations Management';
