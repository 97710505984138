import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Icons from '~/icons';
import route from '~/lib/activeScene';
import { setTimelineTime } from '~/lib/timeline';
import { actions as globalActions } from '~/redux/slices/global';
import { actions as uiActions } from '~/redux/slices/ui';
import ButtonWithTip from '~/shared/ButtonWithTip';
import ImageryAnalysisSelector from '~/shared/ImageryAnalysisSelector';
import ImageryChannelSelector from '~/shared/ImageryChannelSelector';
import ToggleButtonWithTip from '~/shared/ToggleButtonWithTip';

import { ButtonGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    '& > :not(:last-child)': {
      marginBottom: 4,
    },
  },
}));

export default function ImageryControls({
  mapidx,
  showSplitControls,
  showAnalysisControls,
  ...props
}) {
  const classes = styles();
  const isMapSplit = useSelector(route.isMapSplit);
  const mapSplitStyle = useSelector((state) => state.global.preferences.mapSplitStyle);
  const setTime = useSelector(setTimelineTime);

  const dispatch = useDispatch();
  const splitMap = () => dispatch(uiActions.setMapSplit(true));
  const unsplitMap = () => dispatch(uiActions.setMapSplit(false));
  const setMapSplitStyle = (style) =>
    dispatch(globalActions.setPreference({ mapSplitStyle: style }));

  const nextStyle = {
    overlap: {
      id: 'side',
      title: 'Side By Side',
      icon: <Icons.SideBySide style={{ transform: 'rotate(90deg)' }} />,
    },
    side: { id: 'overlap', title: 'Overlapped', icon: <Icons.Overlapped /> },
  };

  const unsplitMapAndClear = () => {
    setTime(1, null); // Reset the B timeline
    unsplitMap();
  };

  return (
    <div className={classes.container}>
      <ButtonGroup
        color='inherit'
        orientation='vertical'
        size='small'
        variant='contained'
        {...props}>
        {/* {isTimelineAvailable && (
          <ToggleButtonWithTip
            tooltip='Timeline'
            toggled={isTimelineVisible}
            onClick={() => (isTimelineVisible ? setTimeline(false) : setTimeline(mapidx))}>
            <Icons.Schedule />
          </ToggleButtonWithTip>
        )} */}

        <ImageryChannelSelector mapidx={mapidx} />
        {showAnalysisControls && <ImageryAnalysisSelector mapidx={mapidx} />}
      </ButtonGroup>

      {showSplitControls && (
        <ButtonGroup
          color='inherit'
          orientation='vertical'
          size='small'
          variant='contained'
          {...props}>
          <ToggleButtonWithTip
            tooltip='Split screen view'
            toggled={isMapSplit}
            onClick={isMapSplit ? unsplitMapAndClear : splitMap}>
            <Icons.Splitscreen />
          </ToggleButtonWithTip>
          {isMapSplit && (
            <ButtonWithTip
              tooltip={nextStyle[mapSplitStyle].title}
              onClick={() => {
                setMapSplitStyle(nextStyle[mapSplitStyle].id);
              }}>
              {nextStyle[mapSplitStyle].icon}
            </ButtonWithTip>
          )}
        </ButtonGroup>
      )}
    </div>
  );
}
