import channelConfig from '~/lib/channelConfig';
import { getCustomLayer } from '~/lib/customLayers';
import accessToken from '~/lib/mapboxAccessToken';

// TODO: factor some of these out to utils
const keyForImagery = (i) => `imagery-${i.id}`;

export function layerForImagery(i, channel, mapidx, palette) {
  const customShader = channelConfig[channel].shader(palette, i.type);
  if (customShader) {
    // We have our own pixel shader
    return getCustomLayer(i, channel, customShader, mapidx, palette);
    // Display as-is
  } else {
    return [
      {
        type: 'raster',
        id: keyForImagery(i),
        minzoom: 13.5,
        layout: {
          visibility: i.visible ? 'visible' : 'none',
        },
        paint: {
          // 'raster-opacity': i.visible ? 1 : 0,
        },
        source: `${keyForImagery(i)}-source`,
      },
    ];
  }
}

export function sourceForImagery(i) {
  const filename = i.tileset.includes('.') ? i.tileset : 'american-robotics.' + i.tileset;

  const bounds = i.bbox;

  const source = {
    id: `imagery-${i.id}-source`, // this ID field becomes the key in style.sources
    type: 'raster',
    tileSize: 256,
    maxzoom: i.maxzoom || 26, // If we know the maxzoom from teh server, use it here. (It avoids a mapbox overzoom bug)
    minzoom: 13,
    scheme: 'xyz',
  };

  if (bounds) {
    source.bounds = bounds; //[bounds[0][0], bounds[0][1], bounds[1][0], bounds[1][1]]
  }

  return {
    ...source,
    format: 'png',
    tiles: [
      `https://a.tiles.mapbox.com/v4/${filename}/{z}/{x}/{y}.pngraw?access_token=${accessToken}`,
    ],
  };
}
