import React from 'react';
import { useDispatch } from 'react-redux';
import * as api from '~/lib/api';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from '@material-ui/core';

import { actions } from './slice';

const DEFAULT_ORG = {
  id: '',
  type: 'REGULAR',
};

export default function NewOrgDialog({ open, onClose }) {
  const [newOrg, setNewOrg] = React.useState({ ...DEFAULT_ORG });

  const dispatch = useDispatch();

  const save = async (newOrg) => {
    dispatch(actions.createOrg(newOrg));
    onClose();
  };

  const handleConfirm = () => {
    save(newOrg);
    setNewOrg({ ...DEFAULT_ORG });
  };

  const handleCancel = () => {
    setNewOrg({ ...DEFAULT_ORG });
    onClose(null);
  };

  const setField = (field, value) => {
    setNewOrg({ ...newOrg, [field]: value });
  };

  const err_id = newOrg.id?.length >= 4 ? null : 'ID must be 4 or more characters';

  const isComplete = err_id == null;

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>Create Organization</DialogTitle>

      <DialogContent style={{ overflowY: 'auto' }}>
        <TextField
          fullWidth
          label='ID'
          error={err_id != null}
          helperText={err_id}
          value={newOrg.id}
          onChange={(e) =>
            setField(
              'id',
              e.target.value
                .replace(' ', '_')
                .toLowerCase()
                .replace(/[^a-z0-9_]/g, '')
            )
          }
        />
        <TextField
          select
          fullWidth
          label='Type'
          value={newOrg.type}
          onChange={(e) => setField('type', e.target.value)}>
          <MenuItem value={'REGULAR'}>Regular</MenuItem>
          <MenuItem value={'DEMO'}>Demo</MenuItem>
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color='primary'>
          Cancel
        </Button>
        <Button onClick={handleConfirm} color='primary' disabled={!isComplete}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
