import { createSelector } from 'reselect';
import { allFarms, getQueryParams } from '~/redux/selectors/global';
import { getUsershapeWKT } from '~/redux/slices/usershape';
import { RootState } from '~/store';
import { Asset } from '~/schema';

export const getTmpAssetWithUsershape = createSelector(
  (state: RootState) => state.assetsScene.tmpAsset,
  getUsershapeWKT,
  (tmpAsset: Asset | undefined, usershapeWkt: string | undefined) => {
    if (!tmpAsset) return null;
    if (usershapeWkt) {
      return { ...tmpAsset, shape: usershapeWkt };
    } else {
      return tmpAsset;
    }
  }
);

export const focussedSite = createSelector(
  getQueryParams,
  allFarms,
  (state: RootState) => state.assetsScene.tmpAsset,
  (query, allSites) => {
    const siteId = query.get('site');
    const site = siteId ? allSites[siteId] : null;
    return site;
  }
);

export const getFocussedSiteAssets = createSelector(
  focussedSite,
  (state: RootState) => state.assetsScene.assets,
  (site, assets) => {
    if (!assets) return [];
    const assetArray: Asset[] = Object.values(assets);
    return assetArray.filter((a) => a.site_id == site?.id && !a.deleted) || [];
  }
);
