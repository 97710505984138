import { Coordinate } from '~/lib/customHooks';
import {
  FlightCalEntry,
  NoFlyInstance,
  NoFlyTime,
  Region,
  ScheduledFlightRequest,
  Survey,
} from '~/schema';

import Goal from './Goal';
import * as noflyinst from './NoFlyInstance';
import * as nofly from './NoFlyTime';
import * as sfr from './ScheduledFlightRequest';

export interface Json {
  tz: string;
  baseSns?: string[];
  location: Coordinate;
  flights?: FlightCalEntry[];
  scheduledFlightRequests?: sfr.Json[];
  noFlyInstances?: noflyinst.Json[];
  noFlyTimes?: nofly.Json[];
  regionsForKeepouts?: Region[];
  surveysAvailable?: Survey[];
  goals: Goal[];
}

export default interface ScheduleData {
  tz: string;
  baseSns: string[];
  location: Coordinate;
  flights: FlightCalEntry[];
  scheduledFlightRequests: ScheduledFlightRequest[];
  noFlyInstances: NoFlyInstance[];
  noFlyTimes: NoFlyTime[];
  regionsForKeepouts: Region[];
  surveysAvailable: Survey[];
  goals: Goal[];
}

export function fromjson(json: Json): ScheduleData {
  return {
    tz: json.tz || 'No TZ Specified',
    baseSns: json.baseSns || [],
    location: json.location || [0, 0],
    flights: json.flights || [],
    scheduledFlightRequests: json.scheduledFlightRequests?.map((x) => sfr.fromjson(x)) || [],
    noFlyInstances: json.noFlyInstances?.map((x) => noflyinst.fromjson(x)) || [],
    noFlyTimes: json.noFlyTimes?.map((x) => nofly.fromjson(x)) || [],
    regionsForKeepouts: json.regionsForKeepouts || [],
    surveysAvailable: json.surveysAvailable || [],
    goals: json.goals || [],
  };
}
