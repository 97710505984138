import React from 'react';
import { createSelector } from 'reselect';
import * as globalSelctors from '~/redux/selectors/global';

import OrgListPage from './OrgListPage';
import slice, { actions } from './slice';

export { slice };

export const matches = (state) => globalSelctors.paramsForPath(state, '/orglist');

export const title = (state) => 'Organizations';

export const breadcrumbs = (state) => ['Organizations'];

export const component = (state) => <OrgListPage />;

export const fetch = createSelector(() => (dispatch) => {
  dispatch(actions.getOrgs());
});

export const helpTopic = () => 'Organizations Management';
