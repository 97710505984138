import axios, { AxiosRequestConfig, Method } from 'axios';

export const cloudURL = '/api';

export async function logout() {
  return webrequest('POST', 'sv/logout');
}

// TODO: Add functions for updating/recovering passwords

export async function webrequest(
  method: Method,
  path: string,
  data = {},
  params = {}
): Promise<unknown> {
  const request: AxiosRequestConfig = {
    method: method,
    url: `${cloudURL}/${path}`,
    params: params,
    responseType: 'json',
    withCredentials: true,
    data: data,
  };

  // const start_time = performance.now()

  const resp = await axios(request);

  // const elapsed = performance.now() - start_time
  // const basepath = path.replace(/\/\d+/, '/<id>')

  return resp.data;
}

export function byId<T extends { id: string | number }>(items: T[] | null): Record<string, T> {
  return (items || []).reduce((map: Record<string, T>, e) => {
    map[e.id] = e;
    return map;
  }, {});
}

export function byNumId<T extends { id: number }>(items: T[] | null): Record<number, T> {
  return (items || []).reduce((map: Record<number, T>, e) => {
    map[e.id] = e;
    return map;
  }, {});
}
