import queryString from 'query-string';
import * as React from 'react';
import { createSelector } from 'reselect';
import { Scene } from '~/lib/activeScene';
import * as geography from '~/lib/geography';
import history from '~/lib/history';
import { displayNameForFarm } from '~/lib/names';
import { jumpToSite } from '~/lib/uiHelpers';
import { Markers } from '~/map';
import { getFarmVisibilitySteps, paramsForPath } from '~/redux/selectors/global';
import { Imagery } from '~/schema';
import { DisplayRegion } from '~/schema/Region';
import OrgSiteLink from '~/shared/Appbar/OrgSiteLink';
import ZoomOutButton from '~/shared/Appbar/ZoomOutButton';
import ImageryControls from '~/shared/ImageryControls';
import store, { RootState, SVDispatch } from '~/store';
import { regionColors } from '~/theme';

import OverviewPanel from './OverviewPanel';
import slice, { actions, allVisibleFieldData, filteredFieldData, visibleSites } from './slice';

export { slice };

const DEFAULT_BOUNDS: [[number, number], [number, number]] = [
  [-124, 48],
  [-67, 27],
];

const requestedFarmIdCache: string[] = [];

const requestFarmOnFirstAccess = (farmid: string) => {
  // Side effect that lazy-loads farms when we zoom into them
  if (!requestedFarmIdCache.includes(farmid)) {
    requestedFarmIdCache.push(farmid);
    (store.dispatch as SVDispatch)(actions.getFarmOverview(farmid));
  }
};

// Common selectors
const params = (state: RootState) => paramsForPath(state, '/');
const imagesets = createSelector(allVisibleFieldData, (allVisibleFieldData) => {
  return allVisibleFieldData.map((field) => field.latest_imageset).filter((x) => x != null);
});

// @ts-ignore: This needs to be fixed when we modernize the OverviewPanel
const overviewPanel = <OverviewPanel />;

const scheduleScene: Partial<Scene> = {
  params,
  title: () => 'Overview',
  breadcrumbs: createSelector(
    visibleSites,
    getFarmVisibilitySteps,
    allVisibleFieldData,
    (state) => state.ui.viewport.automatic,
    (visibleSites, farmVisibility, allVisibleFieldData, automaticViewport) => {
      // Little side effect: update the query string to match our farm
      const focussedFarmId = queryString.parse(location.search).site;

      // This reset should only be in the event of a user moving the map. We need to be able to listen to that separately...
      if (!automaticViewport) {
        const renderedFarmIds = Object.keys(farmVisibility).filter(
          (fid) => farmVisibility[fid] == 2
        );

        if (renderedFarmIds.length == 1 && renderedFarmIds[0] != focussedFarmId) {
          history.push({
            pathname: '/',
            search: `?site=${renderedFarmIds[0]}`,
          });
        }
      }

      return [<OrgSiteLink key='farm' />];
    }
  ),

  appbarActions: createSelector(
    visibleSites,
    getFarmVisibilitySteps,
    (visibleSites, farmVisibility) => {
      const visibleFarmIds = Object.keys(farmVisibility).filter((fid) => farmVisibility[fid] > -1);

      const actions: JSX.Element[] = [];
      if (visibleFarmIds.length == 1) {
        const visibleFarm = visibleSites[visibleFarmIds[0]];
        actions.push(<ZoomOutButton key='zoomout' farm={visibleFarm} />);
      }
      return actions;
    }
  ),

  // Mapbox style data
  mapStyle: createSelector(
    visibleSites,
    (state: RootState) => state.overviewScene.farmOverviews,
    getFarmVisibilitySteps,
    filteredFieldData,
    allVisibleFieldData,
    (farms, overviews, isVisible, filteredFieldData, allVisibleFieldData) => {
      // Make sure we're loading the farms when we've zoomed in
      for (const farmid in isVisible) {
        if (isVisible[farmid] == 2) requestFarmOnFirstAccess(farmid);
      }

      // TODO: Get imagery
      // imagery: loadedImagery.map(i => ({ ...i, visible: true })) || [],

      const regions: DisplayRegion[] = [];

      // Accumulate high-level farm boundaries for distant farms

      const displayRegions = Object.values(farms)
        .map((f): DisplayRegion | null => {
          const isPending = overviews[f.id]?.pending;
          const isLoading = overviews[f.id]?.loading;
          const visLevel = isVisible[f.id];

          const loadingError = overviews[f.id]?.error
            ? `Error loading ${displayNameForFarm(f)}`
            : false;

          const display = visLevel == 1 || (visLevel == 2 && (isPending || loadingError));
          if (!display) return null;

          return {
            id: `${f.id}-keepin`,
            type: 'FARM',
            boundary: f.keepin,
            display_name:
              (visLevel == 2
                ? loadingError || (isLoading ? 'Loading...' : displayNameForFarm(f))
                : displayNameForFarm(f)) || 'Unknown',
            color: regionColors.FARM,
            outline: true,
            fill: true,
            name: true,
          };
        })
        .filter((x): x is DisplayRegion => x != null);

      regions.push(...displayRegions);

      // Accumulate visible farm fields
      regions.push(
        ...filteredFieldData.map((r) => ({
          id: r.id,
          type: r.region.type,
          ceiling: r.region.ceiling,
          boundary: r.region.boundary,
          display_name: r.region.display_name,
          color: regionColors[r.region.type],
          outline: true,
          fill: false,
          name: true,
        }))
      );

      const imagery: Imagery[] = [];

      filteredFieldData.forEach((field) => {
        const i = field.latest_imageset?.imagery;
        if (i) {
          imagery.push(...i.map((x) => ({ ...x, visible: true })));
        }
      });

      return {
        imagery,
        regions,
        extras: { layers: [], sources: {} },
      };
    }
  ),

  imagesets,
  // const targetResults = createSelector(
  //   apiKey,
  //   state => state.data.requests,
  //   (key, requests) => requests[key]?.data?.target_results
  // )

  // TODO: Add back in targeted survey button, maybe?
  controls: createSelector(params, imagesets, (params, imagery) => ({
    NE:
      imagery.length > 0
        ? [
            <ImageryControls
              mapidx={0}
              key='imctl'
              showSplitControls={false}
              showAnalysisControls={false}
            />,
          ]
        : [],
    NW: [
      // <TargetedSurveyButton
      //   farmId={params.farmId}
      //   trs={(trids || []).join(',')}
      //   rs={regions.map(r => r.id).join(',')}
      //   key='target'
      // />,
    ],
  })),

  panel: () => overviewPanel,

  // const getTargetedNotifications = createSelector(
  //   state => state.notifications.all,
  //   notifs => notifs.filter(n => n.target_info && !n.read_at).map(n => n.target_info)
  // )

  markers: createSelector(visibleSites, getFarmVisibilitySteps, (farms, isVisible) => {
    return [
      ...Object.values(farms).map((f) => {
        const farmCenter = geography.centerOfShape(f.keepin);

        const jumpToThisFarm = jumpToSite(f.id);

        return (
          <Markers.FarmIndicator
            key={f.id}
            name={displayNameForFarm(f)}
            coordinate={farmCenter}
            visible={isVisible[f.id] == 0}
            onClick={jumpToThisFarm}
          />
        );
      }),
      // TODO: targeted notifications, base station locations?
    ];
  }),

  resetBounds: createSelector(
    visibleSites,
    (state) => state.router.location,

    (farms) => {
      type BBox = [[number, number], [number, number]];

      const allPoints = Object.values(farms)
        .map((f) => geography.bboxOfWKT(f.keepin))
        .filter((x): x is BBox => x != null)
        .reduce<[number, number][]>((arr, x) => [...arr, ...x], []);

      return allPoints.length == 0 ? DEFAULT_BOUNDS : geography.bboxOfPoints(allPoints);
    }
  ),

  defaultBounds: createSelector(
    visibleSites,
    (state) => state.router.location,

    (farms, location) => {
      type BBox = [[number, number], [number, number]];

      const focussedFarm = queryString.parse(location.search).site;

      if (
        focussedFarm &&
        typeof focussedFarm === 'string' &&
        Object.keys(farms).includes(focussedFarm)
      ) {
        const f = farms[focussedFarm];
        return geography.bboxOfWKT(f.keepin);
      }

      const allPoints = Object.values(farms)
        .map((f) => geography.bboxOfWKT(f.keepin))
        .filter((x): x is BBox => x != null)
        .reduce<[number, number][]>((arr, x) => [...arr, ...x], []);

      return allPoints.length == 0 ? DEFAULT_BOUNDS : geography.bboxOfPoints(allPoints);
    }
  ),

  clickableFeatures: createSelector(
    visibleSites,
    getFarmVisibilitySteps,
    filteredFieldData,
    (farms, isVisible, fieldData) => [
      ...Object.values(farms)
        .filter((f) => isVisible[f.id] == 1)
        .map((f) => ({
          feature: f.keepin,
          cursor: 'zoom-in',
          onClick: jumpToSite(f.id),
        })),

      ...fieldData.map((f) => ({
        feature: f.region.boundary,
        cursor: 'pointer',
        onClick: () => history.push(`/field/${f.id}`),
      })),
    ]
  ),

  imageryResolution: createSelector(imagesets, (imagery) => {
    return imagery.map((i) => i.actual_resolution);
  }),

  helpTopic: () => 'Overview',
};

export default scheduleScene;
