import { DateTime, Duration } from 'luxon';
import { parseUTCISO, serlializeUTCISO } from '~/lib/time';
export default interface NoFlyTime {
  id?: number;
  site_id: string;
  region_ids: number[];
  author?: string;
  description: string;
  start_datetime: DateTime;
  duration_minutes: Duration;
  rrule?: string;
  timezone: string;
  exception_datetimes: DateTime[];
  extra_datetimes: DateTime[];
}

export interface Json {
  id?: number;
  site_id: string;
  region_ids?: number[];
  author?: string;
  description: string;
  start_datetime: string;
  duration_minutes: number;
  rrule?: string;
  timezone: string;
  exception_datetimes?: string[];
  extra_datetimes?: string[];
}

export function fromjson(json: Json): NoFlyTime {
  return {
    region_ids: [],
    ...json,
    start_datetime: parseUTCISO(json.start_datetime),
    duration_minutes: Duration.fromObject({ minutes: json.duration_minutes }),
    exception_datetimes: json.exception_datetimes?.map((edt) => parseUTCISO(edt)) || [],
    extra_datetimes: json.extra_datetimes?.map((edt) => parseUTCISO(edt)) || [],
  };
}

export function tojson(o: NoFlyTime): Json {
  return {
    ...o,

    start_datetime: serlializeUTCISO(o.start_datetime),
    duration_minutes: o.duration_minutes.as('minutes'),
    exception_datetimes: o.exception_datetimes.map((edt) => serlializeUTCISO(edt)),
    extra_datetimes: o.extra_datetimes.map((edt) => serlializeUTCISO(edt)),
  };
}
