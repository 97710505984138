import { webrequest } from '~/lib/api';
import type { SVThunkAction } from '~/store';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface UserAvatarsState {
  avatarsByUserId: { [id: string]: { loading: boolean; img?: string } };
}
type SetAvatarAction = PayloadAction<{ userId: string; loading: boolean; img?: string }>;

const initialState: UserAvatarsState = {
  avatarsByUserId: {},
};

const slice = createSlice({
  name: 'useravatars',
  initialState,
  reducers: {
    setAvatar(state, action: SetAvatarAction) {
      const { userId, loading, img } = action.payload;
      state.avatarsByUserId[userId] = { loading, img };
    },
  },
});

export default slice.reducer;

const requestUserId = (userId: string): SVThunkAction => async (dispatch, getState) => {
  if (Object.keys(getState().useravatars.avatarsByUserId).includes(userId)) {
    return; // Don't need to do this, already loading/loaded
  }

  dispatch(slice.actions.setAvatar({ userId, loading: true, img: undefined }));
  try {
    const resp = (await webrequest('GET', `sv/avatar/${userId}`)) as { image: string };
    const img = resp.image;
    dispatch(slice.actions.setAvatar({ userId, loading: false, img }));
  } catch {
    dispatch(slice.actions.setAvatar({ userId, loading: false, img: undefined }));
  }
};

export const actions = {
  ...slice.actions,
  requestUserId,
};
