import { cellsForSamples, gridForCachedRegion } from '~/lib/analysisGrid';
import Logging from '~/logging';

import * as asset_count from './asset_count';
import * as count from './count';
import * as cover from './cover';
import * as ndvi_anomaly from './ndvi_anomaly';
import * as ndvi_mean from './ndvi_mean';
import * as ndvi_std from './ndvi_std';
import * as ndvi_velocity from './ndvi_velocity';
import * as solar_fault from './solar_fault';

const modes = {
  ndvi_average: ndvi_mean, // Will display any statistics data
  ndvi_std: ndvi_std,
  ndvi_anomaly: ndvi_anomaly,
  ndvi_velocity: ndvi_velocity,
  cover: cover,
  count: count,
  asset_count: asset_count,
  solar_fault: solar_fault,
};

export function clickablesForAnalysis(analysis) {
  if (!analysis) return [];

  const mode = modes[analysis.metric];
  if (!mode) {
    Logging.warn('Analysis mode not supported', { mode: analysis.metric });
    return [];
  }
  if (!mode.clickables) {
    return [];
  }

  return mode.clickables(analysis);
}

// TODO: Should this be a selector, so it can pull in configuration information?
export function overlaysForAnalysis(analysis, region, preferences, colorForNDVI) {
  if (!analysis) return [];

  const mode = modes[analysis.metric];
  if (!mode) {
    Logging.warn('Analysis mode not supported', { mode: analysis.metric });
    return [];
  }

  const key = `${analysis.id}-${analysis.metric}`;

  let cell_data = null;

  if (analysis.cell_data) {
    const cell_geometry = analysis.grid_origin
      ? gridForCachedRegion(
          region,
          analysis.grid_origin,
          analysis.grid_divisions,
          analysis.grid_cell_size
        )
      : cellsForSamples(
          region,
          analysis.sample_lons.map((x, i) => [x, analysis.sample_lats[i]])
        );

    if (analysis.cell_data.length != cell_geometry.length) {
      console.error('Problem matching up analytics data to calculated cell geometry.');
      return [];
    }

    // Combine the geometry and the provided cell data together.
    cell_data = analysis.cell_data.map((cdj, i) => ({
      ...JSON.parse(cdj),
      geometry: cell_geometry[i],
    }));
  }

  return mode.overlay(key, analysis, cell_data, preferences, colorForNDVI);
}

export function controlsForAnalysis(metric, mapidx) {
  const mode = modes[metric]?.controls?.(mapidx);
  if (!mode) {
    Logging.warn('Analysis mode not supported', { mode: metric });
  }
  return mode;
}

export function getAnalysisDescriptions(metric) {
  const mode = modes[metric];

  return {
    id: metric,
    name: mode?.name || metric,
    tooltip: mode?.description || `No description for ${metric}`,
  };
  /*
    {
      id: 'ndvi_average',
      name: 'Average NDVI',
      tooltip: "Average value of a cell's health compared to other cells.",
    },
    {
      id: 'ndvi_velocity',
      name: 'NDVI Velocity',
      tooltip: 'Rate of change of health over time.',
    },
    {
      id: 'ndvi_std',
      name: 'NDVI Variance',
      tooltip: 'Highlights areas with local differences in NDVI.',
    },
    {
      id: 'ndvi_anomaly',
      name: 'Anomaly Detection',
      tooltip: 'Areas where the growth velocity is significantly diverging.',
    },
    {
      id: 'count',
      name: 'Stand Count',
      tooltip: 'Identifies and counts all distinguishable plants.',
    },
    {
      id: 'cover',
      name: 'Ground cover',
      tooltip: 'Fraction of ground covered by plants.',
    },

    {
      id: 'asset_count',
      name: 'Asset Count',
      tooltip: 'Visualize the number and distribution of identified objects in the region.',
    },
    {
      id: 'solar_faults',

      name: 'Fault Detection',
      tooltip: 'Identify various faults in solar panels, such as hot spots.',
    },
    {
      id: 'bgr',
      name: 'Background Removal',
      tooltip: 'Masks imagery and analysis to only consider the crops, not the ground.',
    },
    */
}
