import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

export interface ConfirmPayload {
  title: string;
  message: string;
  confirm?: string;
}
type ConfirmCallback = (confirmed: boolean) => void;

export interface ConfirmPayloadCallback extends ConfirmPayload {
  callback?: ConfirmCallback;
}

export default function ConfirmDialog({
  payload,
  close,
}: {
  payload?: ConfirmPayloadCallback;
  close: () => void;
}) {
  return (
    <Dialog open={payload != null} onClose={() => payload?.callback?.(false)}>
      <DialogTitle>{payload?.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{payload?.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            payload?.callback?.(false);
            close();
          }}
          color='secondary'>
          Cancel
        </Button>
        <Button
          onClick={() => {
            payload?.callback?.(true);
            close();
          }}
          color='primary'>
          {payload?.confirm || 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
