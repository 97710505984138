import classNames from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Icons from '~/icons';
import { showConfirmDialog, showInputDialog, showSnackbar } from '~/lib/modalServices';
import { displayNameForFarm } from '~/lib/names';
import { parseUTCISO } from '~/lib/time';
import PanelTopProgress from '~/shared/PanelTopProgress';
import RightPanel from '~/shared/RightPanel';
import SimpleTable from '~/shared/SimpleTable';

import {
  Button,
  Collapse,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import BatteryMeter from './BatteryMeter';
import { getFarm, getFlightData } from './selectors';
import { actions } from './slice';

const styles = makeStyles((theme) => ({
  relative: {
    position: 'relative',
  },

  module: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  imageryBulletItem: {
    display: 'flex',
  },

  hiddenRow: {
    background: theme.palette.action.disabledBackground,
    color: theme.palette.text.secondary,
  },
}));

const moduleStyles = makeStyles((theme) => ({
  collapser: {
    marginLeft: -4,
    transition: 'transform 0.25s ease',
  },
  rotated: {
    transform: 'rotate(180deg)',
  },

  hidden: {
    opacity: 0.5,
    backgroundImage:
      'repeating-linear-gradient(45deg, rgba(0,0,0,0.1) 0px,  rgba(0,0,0,0.1) 25px, transparent 25px, transparent 50px, gray 50px)',
  },
}));

function CollapseModule({ title, subtitle, children, hidden }) {
  const [collapsed, setCollapsed] = React.useState(true);

  const classes = moduleStyles();

  return (
    <>
      <ListItem
        button
        onClick={() => setCollapsed(!collapsed)}
        className={hidden ? classes.hidden : null}>
        <ListItemText primary={title} secondary={subtitle} />
        <ListItemSecondaryAction>
          <Icons.ExpandMore
            className={classNames(classes.collapser, {
              [classes.rotated]: !collapsed,
            })}
          />
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={!collapsed}>{children}</Collapse>
      <Divider />
    </>
  );
}

const formatTime = (rfctime) =>
  rfctime ? parseUTCISO(rfctime).toLocaleString(DateTime.DATETIME_FULL) : null;

export default function FlightPanel() {
  const classes = styles();
  const data = useSelector(getFlightData);
  const farm = useSelector(getFarm);

  const dispatch = useDispatch();

  const manualHide = (iset) => async () => {
    const reason = await showInputDialog(
      null,
      'Please explain why you are hiding this data.',
      'Hide Data',
      'Reason for hiding'
    );
    if (reason !== null) {
      dispatch(actions.manualHide(data.flight.id, iset.target_results_id, iset.region_id, reason));
    }
  };

  const manualUnhide = (hdid) => async () => {
    const hiddenData = data.hiddendata?.find((hd) => hd.id == hdid);
    const reason = hiddenData.reason || 'Unspecified';

    if (
      await showConfirmDialog(
        'Reveal Data',
        `Are you sure you want to reveal this data to users? The reason given for hiding it was: "${reason}"`,
        'Reveal'
      )
    ) {
      dispatch(actions.manualUnhide(data.flight.id, hdid));
    }
  };

  if (!data || !farm)
    return (
      <RightPanel>
        <div className={classes.module}>
          <Typography variant='subtitle1'>Loading...</Typography>
        </div>
      </RightPanel>
    );

  const T0 = parseUTCISO(data?.notes?.takeoff_time);

  const formatDelta = (t_rfc) => {
    const t = parseUTCISO(t_rfc);
    const sec = t.diff(T0).as('seconds');
    const sign = sec > 0 ? 'T+' : 'T-';
    const usec = Math.abs(sec);

    const hh = Math.floor(usec / 3600);
    const mm = Math.floor((usec - hh * 3600) / 60);
    const ss = Math.floor(usec - hh * 3600 - mm * 60);

    if (hh > 0) {
      return `${sign}${hh}h${mm}m${ss}s`;
    } else if (mm > 0) {
      return `${sign}${mm}m${ss}s`;
    } else {
      return `${sign}${ss}s`;
    }
  };

  return (
    <RightPanel>
      {data.pending && <PanelTopProgress />}

      <List>
        <ListItem dense>
          <ListItemText primary={displayNameForFarm(farm)} />
        </ListItem>
        <ListItem dense>
          <SimpleTable
            data={[
              { title: 'Flight', value: data.flight.id },
              { title: 'Survey', value: data.survey.display_name },
              { title: 'State', value: data.flight.state },
              { title: 'RPIC', value: data.flight.pilot_id },
              { title: 'Rules', value: data.flight.flight_rules },
              { title: 'Purpose', value: data.flight.flight_purpose },
              { title: 'Base SN', value: data.flight.basestation_sn || 'N/A' },
              { title: 'Drone SN', value: data.flight.vehicle_sn || 'N/A' },
            ]}
          />
        </ListItem>
        <Divider />
        {data.flight.abort_reasons && (
          <>
            <CollapseModule title='Aborts recorded'>
              <SimpleTable
                data={data.flight.abort_reasons.map((x) => ({
                  title: x.abort_reason,
                  value: x.abort_message,
                }))}
              />
            </CollapseModule>
          </>
        )}

        {data?.notes?.takeoff_battery && data?.notes?.landing_battery && (
          <>
            <ListItem dense>
              <Typography variant='body1'>Used Battery</Typography>
            </ListItem>
            <ListItem dense>
              <BatteryMeter max={data.notes.takeoff_battery} min={data.notes.landing_battery} />
            </ListItem>
            <Divider />
          </>
        )}

        {data.target_results?.map((trs) => {
          const trq = data.target_requests.find((trq) => trq.id == trs.target_request_id);
          if (!trq) return null;
          const flight_region = data.regions.find((r) => r.id == trq.flight_region_id);

          return (
            <CollapseModule
              key={trs.id}
              title={`TR ${trs.id}: ${flight_region?.display_name}`}
              subtitle={trs.target_state}
              hidden={trs.hidden}>
              <SimpleTable
                data={[
                  {
                    title: 'State',
                    value: trs.target_state,
                  },
                  {
                    title: 'Flight Region',
                    value: flight_region?.display_name || trq.flight_region_id,
                  },

                  {
                    title: 'Req Altitude',
                    value: trq.altitude.toFixed(1),
                    units: 'm',
                  },
                  {
                    title: 'Type',
                    value: trq.request_type,
                  },
                  {
                    title: 'Frontlap',
                    value: trq.frontlap.toFixed(2),
                  },
                  {
                    title: 'Sidelap',
                    value: trq.sidelap.toFixed(2),
                  },

                  {
                    title: 'Dilated',
                    value: trq.dilate ? 'YES' : 'NO',
                  },

                  {
                    title: 'Stitch took',
                    value: trs.stitch_time || 'N/A',
                    units: 's',
                  },

                  {
                    title: 'Data',
                    value: trs.data_aborted ? 'ABORTED' : 'GOOD',
                  },
                  {
                    title: 'Stitch',
                    value: trs.stitch_failed ? 'FAILED' : trs.stitch_cancelled ? 'CANCELLED' : 'OK',
                  },
                  {
                    title: 'Ortho',
                    value: trs.ortho_interpretation,
                  },

                  {
                    title: 'Base Data Path',
                    value: trs.base_raw_data_path || 'N/A',
                  },
                  {
                    title: 'Base Ortho Path',
                    value: trs.base_orthomosaic_path || 'N/A',
                  },

                  {
                    title: 'S3 Key',
                    value: trs.root_s3_key || trs.ortho_s3_key.split('/')[0] || 'N/A',
                  },
                ]}
              />
            </CollapseModule>
          );
        })}

        <CollapseModule title='Imagery'>
          <List>
            {data.imagesets?.map((iset, idx) => {
              const region = data.regions.find((r) => r.id == iset.region_id);

              const hiddenData = data.hiddendata?.find(
                (hd) =>
                  hd.target_results_id == iset.target_results_id && hd.region_id == iset.region_id
              );
              const isHidden = hiddenData != null;

              return (
                <React.Fragment key={idx}>
                  <ListItem className={isHidden ? classes.hiddenRow : undefined}>
                    <ListItemText
                      primary={region.display_name}
                      secondary={
                        isHidden
                          ? hiddenData.confirmed
                            ? `Hidden by ${hiddenData.hidden_user_id}`
                            : `Hidden automatically`
                          : 'Data visible'
                      }
                    />
                    <ListItemSecondaryAction>
                      {isHidden ? (
                        <Tooltip title='Unhide this imagery'>
                          <IconButton onClick={manualUnhide(hiddenData.id)}>
                            <Icons.Hidden />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title='Hide this imagery'>
                          <IconButton onClick={manualHide(iset)}>
                            <Icons.NotHidden />
                          </IconButton>
                        </Tooltip>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>

                  {isHidden && (
                    <ListItem dense className={isHidden ? classes.hiddenRow : undefined}>
                      <ListItemText
                        primary='Reason for hiding:'
                        secondary={hiddenData.reason || 'Unspecified'}
                      />
                    </ListItem>
                  )}
                  <ListItem dense className={isHidden ? classes.hiddenRow : undefined}>
                    <ul style={{ width: '100%' }}>
                      {iset.imagery.map((imagery) => (
                        <li key={imagery.id}>
                          <Typography component='span' variant='body2' style={{ flexGrow: 1 }}>
                            {imagery.type}
                          </Typography>
                          <div style={{ float: 'right' }}>
                            <Typography component='span' variant='body2'>
                              {imagery.id}
                            </Typography>
                            <Typography component='span' variant='body2'>
                              &nbsp;(
                              {imagery.tileset ? (
                                <Tooltip title={imagery.tileset}>
                                  <Link
                                    color='primary'
                                    size='small'
                                    onClick={async () => {
                                      await navigator.clipboard?.writeText(imagery.tileset);
                                      showSnackbar('info', 'Copied to Clipboard');
                                    }}>
                                    Tileset
                                  </Link>
                                </Tooltip>
                              ) : (
                                `${imagery.sampled_images?.length} samples`
                              )}
                              )
                            </Typography>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              );
            })}
          </List>
        </CollapseModule>
        {data.events && (
          <CollapseModule title='System events'>
            <List>
              {data.events?.map((e, ei) => (
                <ListItem
                  dense
                  key={ei}
                  style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                  <Typography style={{ alignSelf: 'flex-end' }} variant='caption'>
                    {formatDelta(e.timestamp)}
                  </Typography>
                  <Typography variant='body1'>{e.type}</Typography>
                  <Typography variant='body2'>{e.message}</Typography>
                </ListItem>
              ))}
            </List>
          </CollapseModule>
        )}
        {data.flight && (
          <CollapseModule title='Notes'>
            <SimpleTable
              data={[
                {
                  title: 'State',
                  value: data.flight.state,
                  units: null,
                },
                {
                  title: 'Initialized',
                  value: formatTime(data.flight.initialize_time),
                  units: null,
                },
                {
                  title: 'Takeoff',
                  value: formatTime(data?.notes?.takeoff_time),
                  units: null,
                },
                {
                  title: 'Descent',
                  value: formatTime(data?.notes?.descent_time),
                  units: null,
                },
                {
                  title: 'Landed',
                  value: formatTime(data?.notes?.landing_time),
                  units: null,
                },
                {
                  title: 'Finalized',
                  value: formatTime(data.flight.finalize_time),
                  units: null,
                },

                {
                  title: 'Battery TO',
                  value: data?.notes?.takeoff_battery,
                  units: '%',
                },
                {
                  title: 'Battery LD',
                  value: data?.notes?.landing_battery,
                  units: '%',
                },
                {
                  title: 'Battery Used',
                  value: data?.notes?.takeoff_battery - data?.notes?.landing_battery,
                  units: '%',
                },
                {
                  title: 'RPIC',
                  value: data.flight.pilot_id,
                  units: null,
                },
                {
                  title: 'Notes',
                  value: data?.notes?.pilot_notes,
                  units: null,
                },
                {
                  title: 'Traffic',
                  value: data?.notes?.traffic_report,
                  units: null,
                },
                {
                  title: 'Land Yaw',
                  value: data?.notes?.landing_yaw,
                  units: '°',
                },
              ]}
            />
          </CollapseModule>
        )}

        {data.notes && (
          <CollapseModule title='Weather'>
            <SimpleTable
              data={[
                {
                  title: 'Temp (obs)',
                  value: data?.notes?.obs_air_temperature?.toFixed(0),
                  units: '°C',
                },
                {
                  title: 'Temp (nws)',
                  value: data?.notes?.air_temperature?.toFixed(0),
                  units: '°C',
                },
                {
                  title: 'Clouds (obs)',
                  value: data?.notes?.obs_cloudcover,
                  units: null,
                },
                {
                  title: 'Clouds (nws)',
                  value: data?.notes?.cloudcover?.toFixed(0),
                  units: '%',
                },
                {
                  title: 'Wind TO (obs)',
                  value: data?.notes?.obs_wind_speed_takeoff,
                  units: 'mph',
                },
                {
                  title: 'Wind LD (obs)',
                  value: data?.notes?.obs_wind_speed_landing,
                  units: 'mph',
                },
                {
                  title: 'Wind (nws)',
                  value: data?.notes?.wind_speed?.toFixed(0),
                  units: 'mph',
                },
                {
                  title: 'Gusts (obs)',
                  value: data?.notes?.obs_wind_gusts,
                  units: 'mph',
                },
                {
                  title: 'Gusts (nws)',
                  value: data?.notes?.wind_gusts?.toFixed(0),
                  units: 'mph',
                },
                {
                  title: 'Pressure (nws)',
                  value: data?.notes?.air_pressure?.toFixed(0),
                  units: 'kPa',
                },
                {
                  title: 'Visibiltiy (nws)',
                  value: data?.notes?.visibility?.toFixed(0),
                  units: null,
                },
              ]}
            />
          </CollapseModule>
        )}
      </List>
    </RightPanel>
  );
}
