import store from '~/store';
import history from '~/lib/history';
import { actions as globalActions } from '~/redux/slices/global';
import type { SVDispatch } from '~/store';

// TODO This might want to live somewhere more general
const changeQueryParams = (params: Record<string, string | null>) => {
  const q = new URLSearchParams(store.getState().router.location.search);
  for (const key of Object.keys(params)) {
    const value = params[key];
    if (value === null) {
      q.delete(key);
    } else {
      q.set(key, value);
    }
  }
  history.push({
    search: '?' + q.toString(),
  });
};

export function changeCurrentOrg(orgid: string) {
  const state = store.getState();
  const allSites = state.global.farms;

  const sitesAtNewOrg = Object.values(allSites).filter((x) => x.grower_id == orgid);
  const newSiteId = sitesAtNewOrg.length == 1 ? sitesAtNewOrg[0].id : null;

  changeQueryParams({ o: orgid, site: newSiteId });

  // Save this org as our preference
  const dispatch: SVDispatch = store.dispatch as SVDispatch;
  dispatch(globalActions.setPreference({ defaultOrgId: orgid }));
}

export function changeCurrentSite(siteid: string) {
  changeQueryParams({ site: siteid });
}
