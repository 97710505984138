import React from 'react';
import * as Icons from '~/icons';

import { amber } from '@material-ui/core/colors';

interface AlertMetadata {
  icon: JSX.Element;
  channel: string;
  analysis: string;
}

const meta: Record<string, AlertMetadata> = {
  CHANGE_ALERT: {
    icon: <Icons.Warning color='error' />,
    channel: 'NDVI',
    analysis: 'CHANGE_DETECT',
  },
  CHANGE_WARNING: {
    icon: <Icons.Warning style={{ color: amber[700] }} />,
    channel: 'NDVI',
    analysis: 'CHANGE_DETECT',
  },
  // TODO: more alert types
  default: {
    icon: <Icons.Warning color='error' />,
    channel: 'NDVI',
    analysis: '',
  },
};

export function metaForAlerts(type: string): AlertMetadata {
  return meta[type] || meta.default;
}
