import { matchSorter } from 'match-sorter';
import React from 'react';

import { TextField, TextFieldProps } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  value: string;
  onChangeSelect: (value: string) => void;
  options: string[];
  disabled: boolean;
  textFieldProps?: TextFieldProps;
  required: boolean;
  allowArbitrary: boolean;
}

export default function SelectOrOther({
  label,
  value,
  onChangeSelect,
  options,
  disabled,
  textFieldProps,
  required,
  allowArbitrary,
  ...etc
}: Props) {
  const filterOptions = (options: string[], { inputValue }: { inputValue: string }) =>
    matchSorter(options, inputValue);

  return (
    <div {...etc}>
      <Autocomplete
        freeSolo={allowArbitrary}
        autoSelect
        selectOnFocus
        disableClearable
        filterOptions={filterOptions}
        options={options}
        size='small'
        onChange={(e, v) => onChangeSelect(v)}
        value={value || ''}
        // onInputChange={(e, v) => onChange(v)}
        // inputValue={value || ''}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            disabled={disabled}
            error={required && !value}
            fullWidth
            required={required}
            helperText={
              value && allowArbitrary && !options?.includes(value) ? 'This is a new entry.' : null
            }
            {...textFieldProps}
            InputProps={{
              ...params.InputProps,
            }}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
            // onMouseDown={e => e.stopPropagation()}
            // inputProps={{ ...params.inputProps }}
          />
        )}
      />
    </div>
  );
}
