import mapboxgl from 'mapbox-gl';

// Reference to the geojson source name created in sourcesForAsset

export function getOutlineLayer(
  id: string,
  color: string | mapboxgl.StyleFunction | mapboxgl.Expression,
  opacity: number
): mapboxgl.LineLayer {
  return {
    id: `asset-${id}-outline`,
    type: 'line',
    minzoom: 1,
    source: `${id}-src`,
    layout: {
      'line-join': 'miter',
      visibility: opacity > 0 ? 'visible' : 'none',
    },
    paint: {
      'line-color': color,
      // 'line-width': ['interpolate', ['linear'], ['zoom'], 13, 1, 16, 3],
      'line-width': 2,
      'line-opacity': opacity,
    },
  };
}
export function getOutlineShadowLayer(id: string, opacity: number): mapboxgl.LineLayer {
  return {
    id: `${id}-outline-shadow`,
    type: 'line',
    minzoom: 13.5,
    source: `${id}-src`,
    layout: {
      'line-join': 'miter',
      visibility: opacity > 0 ? 'visible' : 'none',
    },
    paint: {
      'line-color': '#000',
      // 'line-width': ['interpolate', ['linear'], ['zoom'], 13, 1, 16, 8],
      'line-width': 4,
      'line-opacity': 0.5 * opacity,
    },
  };
}

export function getFillLayer(
  id: string,
  height: number,
  color: string,
  opacity: number,
  tilted: boolean
): mapboxgl.FillExtrusionLayer | mapboxgl.FillLayer {
  return {
    id: `${id}-fill-tilt`,
    type: 'fill-extrusion',
    minzoom: 1,
    source: `${id}-src`,
    paint: {
      'fill-extrusion-color': color,
      'fill-extrusion-height': height || 0, // The 213 here is the floor of class E. It should probably never come up?
      'fill-extrusion-base': 0,
      'fill-extrusion-opacity': opacity,
    },
    layout: {
      visibility: opacity == 0 ? 'none' : 'visible',
    },
  };
}

function getTextLayer(id: string, offset: number[], showText: boolean): mapboxgl.SymbolLayer {
  return {
    id: `${id}-text`,
    type: 'symbol',
    minzoom: 1,
    layout: {
      'text-field': '{title}',
      'text-size': 14,
      'text-font': ['Montserrat Regular', 'Open Sans Regular', 'Arial Unicode MS Regular'],
      'text-offset': offset,
      'text-allow-overlap': false,
      'text-anchor': 'center',
      visibility: showText ? 'visible' : 'none',
    },
    paint: {
      'text-color': '#fff',
      'text-opacity': 1,
      'text-halo-color': 'rgba(0,0,0,0.5)',
      'text-halo-width': 2,
      'text-halo-blur': 0,
    },

    source: `${id}-text-src`,
  };
}

export default function LayersForAsset(
  id: string,
  height: number,
  color: string,
  opacity: number,
  fillopacity: number,
  showText: boolean,
  tilted: boolean
) {
  return {
    id: id,
    fill: getFillLayer(id, height, color, fillopacity, tilted),
    outline: getOutlineLayer(id, color, opacity),
    outlineShadow: getOutlineShadowLayer(id, opacity),
    text: getTextLayer(id, [0, 0], showText),
  };
}
