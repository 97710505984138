import { DateTime } from 'luxon';
import queryString from 'query-string';
import { createSelector } from 'reselect';
import history from '~/lib/history';
import { RootState } from '~/store';

import { parseUTCISO, serlializeUTCISO } from './time';

// Handles very short base64 encoding for timestamps.
const ENCODE_ALPHABET = '-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz';
function encodeTime(tstr: string): string {
  const number = parseUTCISO(tstr).toSeconds();
  let rixit: number; // like 'digit', only in some non-decimal radix
  let residual = Math.floor(number);
  let result = '';
  for (;;) {
    rixit = residual % 64;
    result = ENCODE_ALPHABET.charAt(rixit) + result;
    residual = Math.floor(residual / 64);

    if (residual == 0) break;
  }

  return result;
}

function decodeTime(str: string): string {
  let result = 0;
  const rixits = str.split('');
  for (let e = 0; e < rixits.length; e++) {
    result = result * 64 + ENCODE_ALPHABET.indexOf(rixits[e]);
  }
  const time = serlializeUTCISO(DateTime.fromSeconds(result));
  return time;
}

export const setTimelineTime = createSelector(
  (state: RootState) => state.router.location,
  (location) => {
    const query = queryString.parse(location.search);

    return (channel: number, time: string) => {
      const tcode = time ? encodeTime(time) : undefined;

      const channels = [{ t_a: tcode }, { t_b: tcode }];

      history.push({
        pathname: location.pathname,
        search: queryString.stringify({ ...query, ...channels[channel] }),
      });
    };
  }
);

export const getTimelineTime = createSelector(
  (state: RootState) => state.router.location.search,
  (state: RootState, channel: number) => channel,
  (search: string, channel: number) => {
    const query = queryString.parse(search);

    const t_a = query.t_a ? decodeTime(query.t_a as string) : null;
    const t_b = query.t_b ? decodeTime(query.t_b as string) : null;

    if (channel == 0) {
      return t_a;
    } else if (channel == 1) {
      return t_b || t_a;
    }
    return null;
  }
);
