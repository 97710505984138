import merge from 'lodash/merge';
import { MARKET_THEMES } from '~/markets';
import type { IndustryType } from '~/markets';

import {
  amber,
  cyan,
  deepPurple,
  green,
  grey,
  lightBlue,
  purple,
  red,
  teal,
} from '@material-ui/core/colors';
import { ThemeOptions, createMuiTheme } from '@material-ui/core/styles';

const scoutviewTheme = (darkmode: boolean): ThemeOptions => ({
  palette: {
    type: darkmode ? 'dark' : 'light',

    primary: {
      main: darkmode ? teal[400] : teal[700],
    },
    secondary: {
      main: '#282828', //grey[800],
    },
    error: {
      main: red[400],
    },
  },
  props: {
    MuiFormControl: {
      variant: 'filled',
    },
    MuiTextField: {
      variant: 'filled',
      size: 'small',
    },
    MuiSelect: {
      variant: 'filled',
      // size: 'small',
    },
  },
  overrides: {
    MuiLink: {
      root: {
        cursor: 'pointer',
      },
    },
    MuiButton: {
      contained: {
        backgroundColor: darkmode ? grey[800] : grey[50],

        '&:hover': {
          backgroundColor: darkmode ? grey[600] : grey[200],
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            backgroundColor: darkmode ? grey[800] : grey[50],
          },
        },
      },
    },
  },
  typography: {
    fontFamily: [
      'Open Sans',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),

    // useNextVariants: true,

    h1: {
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
      fontWeight: 300,
      fontSize: '2rem',
    },
    h2: {
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
      fontWeight: 300,
      fontSize: '1.8rem',
    },
    h3: {
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
      fontWeight: 400,
      fontSize: '1.6rem',
    },
    h4: {
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
      fontWeight: 400,
      fontSize: '1.4rem',
    },
    h5: {
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
      fontWeight: 400,
      fontSize: '1.2rem',
    },
    h6: {
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
      fontSize: '1.0rem',
      fontWeight: 400,
    },

    button: {
      fontFamily: 'Montserrat',
    },

    overline: {
      lineHeight: 1,
      textTransform: 'uppercase',
    },
  },
});

export const getTheme = (darkmode: boolean, market?: IndustryType) =>
  createMuiTheme(merge(scoutviewTheme(darkmode), MARKET_THEMES(darkmode, market || 'Generic')));

// TODO: Move these into the theme object as custom entries so they can use market values
export const regionColors = {
  KEEPOUT: amber[700],
  FLIGHT: purple[500],
  TARGETED: red[500],
  FIELD: lightBlue['A400'],
  ZONE: deepPurple['A400'],
  FARM: green[500],
};

export const symbolicColors = {
  CROSSING_POINT: green['A400'],
  FLIGHT_PATH: lightBlue['A100'],
  INVALID_POLYGON: '#aaa',
};

export const colorForRole = (r: string) =>
  ({
    admin: 'black',
    pilot: red[500],
    farmer: green[500],
    agro: lightBlue[500],
    operations: purple[500],
    beta: cyan[500],
  }[r] || '?');

// TODO: Make a theme testing page? Shrink input boxes?
