import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import StandbyPage from '~/pages/StandbyPage';
import { getLandingPage } from '~/redux/selectors/global';
import { actions as globalActions } from '~/redux/slices/global';
import VersionBlock from '~/shared/VersionBlock';

import { CircularProgress, Grow, Typography } from '@material-ui/core';
/*
  The App's job is to manage app lifecycle. It determines if we are showing the login page, standby, app errors, 
  or if we're giving control over to the Main component to display scenes, or to any other ancillary components 
  that don't require scenes like the ScoutReports.
*/
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';

import { useSVDispatch, useSVSelector } from './redux/hooks';
import { RegulatoryReportPage } from './scenes/regulatory/FAADocsTable';
import ReactGA from 'react-ga';
import { initGA } from '~/googleAnalytics';

const LoginPanel = React.lazy(() => import('~/login'));
const Main = React.lazy(() => import('~/main'));

const styles = makeStyles((_) => ({
  centerer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },

  versionBlock: {
    position: 'absolute',
    right: 4,
    bottom: 4,

    padding: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    background: 'black',
    color: 'white',
    opacity: 0.25,
    userSelect: 'none',
    borderRadius: 8,
  },
}));

function App() {
  const classes = styles();

  const theme = useTheme();

  const loginState = useSVSelector((state) => state.global.loginState);
  const maintenance = useSVSelector((state) => state.global.maintenance);
  const landingPage = useSVSelector(getLandingPage);
  const fetchingglobal = useSVSelector((state) => state.global.fetchingglobal);
  const user = useSVSelector((state) => state.global.user);

  const dispatch = useSVDispatch();
  // This line will trigger on a route change
  const pageview = window.location.pathname + window.location.search;

  // On first mount, ping the server.
  React.useEffect(() => {
    const sayHello = () => dispatch(globalActions.sayHello());
    sayHello();
    const i = setInterval(() => {
      // const loginState = store.getState().global.loginState // Need to get this direct because otherwise we'd capture it from enclosing scope and not get updates
      // if (loginState == 'error' || loginState == 'maintenance') {
      sayHello(); // We say hello every 60 seconds. It updates our version info and maintenance data.
      // }
    }, 60000);
    return () => clearInterval(i);
  }, [dispatch]);

  React.useEffect(() => {
    const fetchGlobalData = () => dispatch(globalActions.fetchGlobalData());
    if ((loginState === 'probably' || loginState === true) && !(fetchingglobal || user)) {
      fetchGlobalData();
    }
  }, [dispatch, loginState, fetchingglobal, user]);

  React.useEffect(() => {
    initGA();
    ReactGA.pageview(pageview);
  }, [pageview]);

  const loadingIndicator = <></>;
  return (
    <>
      <StandbyPage
        showPhoto={
          loginState === false ||
          loginState === 'pending' ||
          loginState === 'maintenance' ||
          loginState === 'error'
        }
      />

      <Switch>
        {user && loginState === true && (
          <Route path='/regulatory/:year/:month'>
            <RegulatoryReportPage />
          </Route>
        )}

        {user && loginState === true && (
          <Route path='/'>
            <Switch>
              {landingPage != '/' && ( // Redirect root access to our known landing page.
                <Route path='/' exact render={() => <Redirect to={landingPage || '/'} />} />
              )}
            </Switch>

            <React.Suspense fallback={loadingIndicator}>
              <Main />
            </React.Suspense>
          </Route>
        )}

        {(loginState === false || loginState === 'pending') && (
          // Not yet logged in; display the log in page immediately.
          <>
            <Route
              path='/'
              render={() => (
                <>
                  <div className={classes.centerer}>
                    <React.Suspense fallback={<div />}>
                      <LoginPanel />
                    </React.Suspense>
                  </div>
                  <VersionBlock className={classes.versionBlock} />
                </>
              )}
            />
          </>
        )}
        {loginState === 'maintenance' && (
          // SV is down for maintenance. Show this to non-admin users.
          <Route>
            <div className={classes.centerer}>
              <Grow in>
                <Alert severity='warning' variant='filled'>
                  <AlertTitle>ScoutView Maintenance</AlertTitle>
                  {maintenance}
                  <Typography variant='caption'>
                    ScoutView will automatically reconnect when maintenance is <br />
                    complete, thank you for your patience!
                  </Typography>
                </Alert>
              </Grow>
              <CircularProgress
                variant='indeterminate'
                style={{ margin: 32, color: theme.palette.error.main }}
              />
            </div>
          </Route>
        )}
        {loginState === 'error' && (
          // There was an error saying hello. This does not bode
          <Route>
            <div className={classes.centerer}>
              <Grow in>
                <Alert severity='error' variant='filled'>
                  <AlertTitle>No Connection</AlertTitle>
                  American Robotics servers cannot be reached at this time. Retrying...
                </Alert>
              </Grow>
              <CircularProgress
                variant='indeterminate'
                style={{ margin: 32, color: theme.palette.error.main }}
              />
            </div>
          </Route>
        )}
      </Switch>
    </>
  );
}

// Need to re-add withRouter here to ensure redirect are followed: https://stackoverflow.com/questions/42875949/react-router-v4-redirect-not-working
export default withRouter(App);
