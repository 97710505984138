import React from 'react';
import { checkIsEmail } from '~/lib/email';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  TextField,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import AcceptButton from './AcceptButton';

const styles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(2),
  },
  accepting: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10,
    background: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    padding: theme.spacing(4),
  },
}));

export default function LinkAccountDialog({ open, invite, onClose }) {
  const classes = styles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [request, setRequest] = React.useState({
    existing: true,
    username: '',
    password: '',
  });

  const emailValid = checkIsEmail(request.username);
  const detailsValid = emailValid && request.password.length > 0;

  return (
    <Dialog fullScreen={fullScreen} fullWidth maxWidth='sm' open={open} onClose={onClose}>
      <DialogTitle>Link Existing Account</DialogTitle>
      <DialogContent>
        <List>
          <ListItem>
            <TextField
              autoComplete='email'
              fullWidth
              label='Email Address'
              value={request.username}
              error={!emailValid}
              onChange={(e) => setRequest({ ...request, username: e.target.value })}
            />
          </ListItem>
          <ListItem>
            <TextField
              autoComplete='password'
              fullWidth
              type='password'
              label='Password'
              value={request.password}
              onChange={(e) => setRequest({ ...request, password: e.target.value })}
            />
          </ListItem>
        </List>
        <Divider className={classes.divider} />
        <List>
          <ListItem>
            <AcceptButton invite={invite} request={request} disabled={!detailsValid} link />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions />
    </Dialog>
  );
}
