import React from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';

import MapContext from '../mapContext';
import DraggableMarker from './DraggableMarker';

const arRed = '#f44336';

const styles = (theme) => ({
  '@keyframes user-position-pulse': {
    from: {
      opacity: 1,
      width: '0%',
      height: '0%',
    },
    to: {
      opacity: 0,
      width: '100%',
      height: '100%',
    },
  },

  userPositionPulse: {
    animationName: '$user-position-pulse',
    animationDuration: '2s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-in-out',

    borderRadius: '50%',
    backgroundColor: arRed,
  },

  userPositionDot: {
    position: 'absolute',
    width: 12,
    height: 12,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    backgroundColor: arRed,
    border: '2px solid white',
    boxShadow:
      '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
  },

  userPositionFrame: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default connect((state) => ({
  userPosition: state.ui.userPosition,
}))(
  withStyles(styles)(({ classes, userPosition, ...other }) => {
    const coordinate = userPosition?.coords;

    return coordinate ? (
      <MapContext.Consumer>
        {(map) => (
          <DraggableMarker
            map={map}
            style={{ pointerEvents: 'none' }}
            coordinate={coordinate}
            anchor='top-left'
            offset={[-40, -40]}
            {...other}>
            <div className={classes.userPositionFrame} style={{ width: 80, height: 80 }}>
              <div className={classes.userPositionPulse} />
              <div className={classes.userPositionDot} />
            </div>
          </DraggableMarker>
        )}
      </MapContext.Consumer>
    ) : null;
  })
);
