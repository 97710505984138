import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Rnd } from 'react-rnd';
import { showHelpTopic } from '~/lib/modalServices';
import { actions as supportActions } from '~/redux/slices/support';
import AppbarSearch from '~/shared/AppbarSearch';
import ComponentForTopic from '~/support/ComponentForTopic';
import SearchResults from '~/support/SearchResults';

import {
  AppBar,
  Breadcrumbs,
  Container,
  Divider,
  Grow,
  IconButton,
  Link,
  Paper,
  Popover,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import PopOutIcon from '@material-ui/icons/OpenInNew';

const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    flexGrow: 1,
    background: theme.palette.background.paper,
    overflowY: 'auto',
    cursor: 'auto',
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    flexGrow: 1,
  },
  closeButton: {
    marginRight: -theme.spacing(2),
  },

  resizeHandleSE: {
    pointerEvents: 'none',
    position: 'absolute',
    right: 0,
    bottom: 0,
    borderTop: '8px solid transparent',
    borderLeft: '8px solid transparent',
    borderRight: `8px solid ${theme.palette.divider}`,
    borderBottom: `8px solid ${theme.palette.divider}`,
  },
  resizeHandleSW: {
    pointerEvents: 'none',
    position: 'absolute',
    left: 0,
    bottom: 0,
    borderTop: '8px solid transparent',
    borderRight: '8px solid transparent',
    borderLeft: `8px solid ${theme.palette.divider}`,
    borderBottom: `8px solid ${theme.palette.divider}`,
  },

  breadcrumbs: {
    background: theme.palette.divider,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

export default function SupportDialog({ open, onClose, topic, history }) {
  const classes = styles();
  const dispatch = useDispatch();

  const [shouldMount, setShouldMount] = React.useState(false);

  const [searchText, setSearchText] = React.useState(null); // Used for searching for strings

  React.useEffect(() => {
    // request available topics once on load
    dispatch(supportActions.requestAvailableTopics());
  }, [dispatch]);

  const allTopics = useSelector((state) => state.support.availableTopics);

  const onNav = (e, newTopic) => {
    const newHistory = newTopic == history[0] ? history : [newTopic, ...history];
    showHelpTopic(newTopic, newHistory);
    e.preventDefault();
  };

  React.useEffect(() => {
    if (open) setShouldMount(true);
  }, [open]);

  const popout = () =>
    searchText === null
      ? topic != ''
        ? window.open(`/support/${topic}`)
        : window.open(`/support`)
      : window.open(`/support?q=${searchText}`);

  const contents =
    searchText === null ? (
      topic === null ? null : (
        <ComponentForTopic topic={topic || 'index'} onNav={onNav} />
      )
    ) : (
      <SearchResults
        searchText={searchText}
        onNav={(topic) => {
          setSearchText(null);
          showHelpTopic(topic);
        }}
      />
    );

  const breadcrumbLinkForHistory = (i) => (
    <Link
      // variant='overline'
      color='primary'
      key={i}
      onClick={(e) => {
        showHelpTopic(history[i], history.slice(i));
        e.preventDefault();
      }}>
      {decodeURI(history[i]) || 'Help'}
    </Link>
  );

  return shouldMount ? (
    <Rnd
      default={{ x: 100, y: 60, width: 360, height: 600 }}
      minWidth={360}
      minHeight={400}
      bounds='window'>
      <Grow
        in={open}
        onExited={() => {
          setShouldMount(false);
        }}>
        <Paper elevation={4} classes={{ root: classes.root }}>
          <AppBar className='helpAppbar'>
            <Toolbar variant='dense'>
              <Typography variant='h6' className={classes.title}>
                Help
              </Typography>

              <AppbarSearch
                options={allTopics.map((x) => decodeURI(x))}
                onNavigate={showHelpTopic}
                onSearch={setSearchText}
              />

              <Tooltip title='Open help in a new window'>
                <IconButton color='inherit' onClick={popout}>
                  <PopOutIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title='Close help'>
                <IconButton color='inherit' onClick={onClose} className={classes.closeButton}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Toolbar>
          </AppBar>
          <Toolbar variant='dense' />
          {history.length > 1 && (
            <div className={classes.breadcrumbs}>
              <Breadcrumbs separator='‹'>
                {history.map((_, i) => breadcrumbLinkForHistory(i)).reverse()}
              </Breadcrumbs>
            </div>
          )}
          <div className={classes.container} onMouseDown={(e) => e.stopPropagation()}>
            {contents}
          </div>
          <div className={classes.resizeHandleSW} />
          <div className={classes.resizeHandleSE} />
        </Paper>
      </Grow>
    </Rnd>
  ) : null;
}
