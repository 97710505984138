export function csvEscape(x: string, encodeURI: boolean = true) {
  let v = '';
  if (x !== undefined) {
    v = `${x}`;
  }
  if (v.includes(',')) {
    v = v.replace('"', '""');
    v = `"${v}"`;
  }
  return encodeURI ? encodeURIComponent(v) : v;
}
