import * as api from '~/lib/api';
import { Coordinate } from '~/lib/customHooks';
import { showSnackbar } from '~/lib/modalServices';
import Logging from '~/logging';
import { Note } from '~/schema';
import { NoteComment, Json as NoteJson, fromjson, tojson } from '~/schema/Note';
import type { SVThunkAction } from '~/store';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface NotesState {
  pendingNote?: Note;
  visibleNote?: number; // ID of currently displayed note, null if none or pending
  uploadProgress: boolean | string; // If true, in progress. If string, an error occcurred. For comments or pending notes.
  notesById: { [id: number]: Note }; // Populated when we receive notes from the various API calls that can return them.
}

const initialState = {
  pendingNote: undefined,
  visibleNote: undefined,
  uploadProgress: false,
  notesById: {},
} as NotesState;

const slice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    createPendingNote(
      state,
      action: PayloadAction<{
        location: Coordinate;
        tr_id: number;
        region_id: number;
        author_user_id: string;
      }>
    ) {
      const { location, tr_id, region_id, author_user_id } = action.payload;

      state.pendingNote = {
        note: '',
        location,
        tr_id,
        region_id,
        author_user_id,
        attachments: [],
        comments: [],
      };
    },
    updatePendingNote(state, action: PayloadAction<Partial<Note> | undefined>) {
      if (action.payload && state.pendingNote != null) {
        state.pendingNote = { ...state.pendingNote, ...action.payload };
      } else {
        state.pendingNote = undefined;
      }
    },
    discardPendingNote(state) {
      state.pendingNote = undefined;
    },

    viewNote(state, action: PayloadAction<number>) {
      state.visibleNote = action.payload;
    },

    setUploadProgress(state, action: PayloadAction<boolean | string>) {
      state.uploadProgress = action.payload;
    },

    consumeNotes(state, action: PayloadAction<Note[]>) {
      for (const note of action.payload) {
        if (note.id != null) state.notesById[note.id] = note;
      }
    },

    deletedNote(state, action: PayloadAction<{ noteid: number }>) {
      const { noteid } = action.payload;
      if (state.visibleNote == noteid) {
        state.visibleNote = undefined;
      }

      delete state.notesById[noteid];
    },

    consumeComment(state, action: PayloadAction<NoteComment>) {
      // This is used when the API gives us a new comment, for instance after typing one. (Or if we get notified of one?)
      const comment = action.payload;
      if (comment.note_id in state.notesById) {
        const oldComments =
          state.notesById[comment.note_id].comments?.filter((c) => c.id != comment.id) || [];
        const newComments = [...oldComments, comment].sort((a, b) => (a.id || 0) - (b.id || 0));
        state.notesById[comment.note_id].comments = newComments;
      } else {
        Logging.warn(
          'Consuming an individual note comment without that note being previously loaded.'
        );
      }
    },
  },
});
export default slice.reducer;

// Pushes the pending note up to the server
const submitPendingNote = (): SVThunkAction => async (dispatch, getState) => {
  const pendingnote = getState().notes.pendingNote;
  if (pendingnote == null) return;

  const note = tojson(pendingnote);

  dispatch(slice.actions.setUploadProgress(true));

  const result = (await api.webrequest('POST', `imageryNotes`, note)) as NoteJson;

  dispatch(slice.actions.setUploadProgress(false));
  dispatch(slice.actions.updatePendingNote(undefined));

  // Add this result note to our list of notes by id
  dispatch(slice.actions.consumeNotes([fromjson(result)]));
};

const submitComment = (comment: NoteComment): SVThunkAction => async (dispatch) => {
  dispatch(slice.actions.setUploadProgress(true));

  const result = (await api.webrequest(
    'POST',
    `imageryNotes/${comment.note_id}/comment`,
    comment
  )) as NoteComment;

  dispatch(slice.actions.setUploadProgress(false));

  // Add this comment to the parent note
  dispatch(slice.actions.consumeComment(result));
};

const deleteNote = (noteid: number): SVThunkAction => async (dispatch) => {
  try {
    await api.webrequest('DELETE', `imageryNotes/${noteid}`);
    dispatch(slice.actions.deletedNote({ noteid }));
    showSnackbar('success', 'Note deleted.');
  } catch {
    showSnackbar('error', 'Could not delete note.');
  }
};

export const actions = {
  ...slice.actions,
  submitPendingNote,
  submitComment,
  deleteNote,
};
