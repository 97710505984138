import { byId, webrequest } from '~/lib/api';
import history from '~/lib/history';
import { showSnackbar } from '~/lib/modalServices';
import Logging from '~/logging';
import { actions as usershapeActions } from '~/redux/slices/usershape';

import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'regions',
  initialState: {
    farms: {
      /* farmId: {
        pending: bool, // is waiting for a response
        loading: bool, // should show a loading indicator
        error: null / str, 
        
        */
    },

    regions: {}, // by id
    tmpRegions: {}, // by id

    pendingSingleRegions: {}, // ids that we're retrieving are set to true
  },
  reducers: {
    requestBegin(state, action) {
      const farmId = action.payload;
      state.farms[farmId] = { pending: true, loading: false, error: null };
    },
    requestLoading(state, action) {
      const farmId = action.payload;
      state.farms[farmId] = { pending: true, loading: true, error: null };
    },
    requestComplete(state, action) {
      const { farmId, data, error } = action.payload;

      if (error) {
        state.farms[farmId] = { pending: false, loading: false, error };
      } else {
        state.farms[farmId] = {
          pending: false,
          loading: false,
          error: null,
        };
        Object.assign(state.regions, byId(data.regions));
      }
    },

    requestSingleBegin(state, action) {
      const regionId = action.payload;
      state.pendingSingleRegions[regionId] = true;
    },
    requestSingleComplete(state, action) {
      const { region, error } = action.payload;

      if (!error) {
        delete state.pendingSingleRegions[region.id];
        state.regions[region.id] = region;
      }
    },

    setTmpRegion(state, action) {
      const { id, region } = action.payload;
      state.tmpRegions[id] = region;
    },

    uploadBegin(state, action) {
      // TODO: some kind of spinner? do we care?
    },
    uploadComplete(state, action) {
      // TODO: Figure out what this means. payload can be { error }
      // If it's success, remove it from the tmp table. Update the main table regardless
      const { data, error } = action.payload;
      if (data) {
        delete state.tmpRegions[data.id];
        if (data.deleted) {
          delete state.regions[data.id];
        } else {
          state.regions[data.id] = data;
        }
      }
    },
  },
});

// This will set up the editing environment for the selected region.
const editRegion = (regionId) => async (dispatch, getState) => {
  const existing = getState().regionsScene.regions[regionId];

  if (existing) {
    dispatch(usershapeActions.setUsershapeFromWKT({ wktstr: existing.boundary }));
  } else {
    dispatch(usershapeActions.resetShape());
  }
};

// Requests all the regions for a farm for editing.
const getFarmRegions = (farmId) => async (dispatch) => {
  dispatch(slice.actions.requestBegin(farmId));

  const showLoading = setTimeout(() => dispatch(slice.actions.requestLoading(farmId)), 500);

  try {
    const data = await webrequest('GET', `sv/farm/${farmId}/regions`);
    clearTimeout(showLoading);
    dispatch(slice.actions.requestComplete({ farmId, data }));
  } catch (error) {
    clearTimeout(showLoading);
    Logging.error('Failed to load farm regions', error);

    dispatch(slice.actions.requestComplete({ farmId, error }));
  }
};

// Requests a single region if we're opening to that page.
const getSingleRegion = (regionId) => async (dispatch) => {
  dispatch(slice.actions.requestSingleBegin(regionId));

  // TODO: Don't think we need a loading timer

  try {
    const data = await webrequest('GET', `regions/${regionId}`);
    dispatch(slice.actions.requestSingleComplete({ regionId, region: data }));
  } catch (error) {
    Logging.error('Failed to load region', error);
    dispatch(slice.actions.requestSingleComplete({ regionId, error }));
  }
};

// This can be existing or new.
const uploadRegion = (region) => async (dispatch) => {
  dispatch(slice.actions.uploadBegin());

  const cleanRegion = { ...region, id: region.id ? region.id : null };

  try {
    const data = cleanRegion.id
      ? await webrequest('PUT', `regions/${cleanRegion.id}`, cleanRegion)
      : await webrequest('POST', `regions`, cleanRegion);

    dispatch(slice.actions.uploadComplete({ data }));
    history.push(`/regions?site=${cleanRegion.farm_id}`);

    showSnackbar('success', 'Region updated.');
  } catch (error) {
    dispatch(slice.actions.uploadComplete({ error }));
    Logging.error('Failed to upload region', error);

    showSnackbar('error', 'Failed to save region.');
  }
};

export default slice.reducer;

export const actions = {
  ...slice.actions,
  getFarmRegions,
  editRegion,
  uploadRegion,
  getSingleRegion,
};
