import React from 'react';
import { useSelector } from 'react-redux';
import * as Icons from '~/icons';

import { Avatar, Tooltip } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

export default function CropAvatar({ cropId, color, style, ...props }) {
  const cropTypes = useSelector((state) => state.global.cropTypes);
  const crop = cropTypes[cropId];
  const CropIcon = Icons.CropIcon(crop?.icon);
  const theme = useTheme();

  const backgroundColor = color || theme.palette.primary.main;
  const textColor = theme.palette.getContrastText(backgroundColor);

  return (
    <Tooltip title={crop?.name || 'Unspecified'}>
      <Avatar
        {...props}
        style={{
          backgroundColor,
          color: textColor,
          ...style,
        }}>
        <CropIcon />
      </Avatar>
    </Tooltip>
  );
}
