import * as api from '~/lib/api';
import Logging from '~/logging';
import {
  Flight,
  FlightNotes,
  HiddenData,
  Region,
  SVImageSet,
  Survey,
  TargetRequest,
  TargetResult,
} from '~/schema';
import { SVThunkAction } from '~/store';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface FlightPageResponse {
  flight: Flight;
  notes: FlightNotes;

  survey: Survey;
  target_requests: TargetRequest[];
  target_results: TargetResult[];

  regions: Region[];
  imagesets: SVImageSet[]; // one for each imagery region, yes?
  hiddendata: HiddenData[]; // what entries have we hidden?
}

interface FlightState {
  flights: {
    [flightId: number]: {
      pending: boolean; // is waiting for a response
      loading: boolean; // should show a loading indicator
      error: null | string;
      data?: FlightPageResponse;
    };
  };
}

const initialState: FlightState = {
  flights: {},
};

// Description of initial state
const slice = createSlice({
  name: 'fieldOverview',
  initialState,
  reducers: {
    requestBegin(state, action: PayloadAction<number>) {
      const flightId = action.payload;
      state.flights[flightId] = {
        pending: true,
        loading: false,
        error: null,
      };
    },
    requestLoading(state, action: PayloadAction<number>) {
      const flightId = action.payload;
      state.flights[flightId] = {
        pending: true,
        loading: true,
        error: null,
      };
    },
    requestSuccess(state, action: PayloadAction<{ flightId: number; data: FlightPageResponse }>) {
      const { flightId, data } = action.payload;
      state.flights[flightId] = {
        pending: false,
        loading: false,
        error: null,
        data,
      };
    },
    requestFailure(state, action: PayloadAction<{ flightId: number; error: string }>) {
      const { flightId, error } = action.payload;
      state.flights[flightId] = {
        pending: false,
        loading: false,
        error,
      };
    },

    setLocalHidden(state, action: PayloadAction<{ flightId: number; hiddendata: HiddenData }>) {
      const { flightId, hiddendata } = action.payload;

      // We've just updated a HiddenData
      if (state.flights[flightId].data == null) return;

      // First we remove the old
      state.flights[flightId].data!.hiddendata =
        state.flights[flightId].data!.hiddendata?.filter((hd) => hd.id != hiddendata.id) || [];

      // Then add the new, if it's still relevant
      if (hiddendata.hidden) {
        state.flights[flightId].data?.hiddendata.push(hiddendata);
      }
    },
  },
});

const manualHide = (
  flightId: number,
  target_results_id: number,
  region_id: number,
  reason: string
): SVThunkAction => async (dispatch) => {
  const payload = {
    target_results_id,
    region_id,
    reason,
  };

  try {
    const hiddendata = (await api.webrequest('POST', `sv/hidden_data`, payload)) as HiddenData;
    dispatch(slice.actions.setLocalHidden({ flightId, hiddendata }));
  } catch (error) {
    Logging.error('Failed to create hidden data', error);
  }
};

const manualUnhide = (flightId: number, hiddenid: number): SVThunkAction => async (dispatch) => {
  try {
    const hiddendata = (await api.webrequest('DELETE', `sv/hidden_data/${hiddenid}`)) as HiddenData;
    dispatch(slice.actions.setLocalHidden({ flightId, hiddendata }));
  } catch (error) {
    Logging.error('Failed to delete hidden data', error);
  }
};

const getFlightData = (flightId: number): SVThunkAction => async (dispatch) => {
  dispatch(slice.actions.requestBegin(flightId));

  const showLoading = setTimeout(() => dispatch(slice.actions.requestLoading(flightId)), 500);

  try {
    const data = (await api.webrequest('GET', `sv/flight/${flightId}`)) as FlightPageResponse;
    clearTimeout(showLoading);
    dispatch(slice.actions.requestSuccess({ flightId, data }));
  } catch (error) {
    Logging.error('Failed getting flight details', error);
    clearTimeout(showLoading);
    dispatch(slice.actions.requestFailure({ flightId, error }));
  }
};
export default slice.reducer;

export const actions = {
  ...slice.actions,
  getFlightData,
  manualHide,
  manualUnhide,
};
