import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import MapContext from '../mapContext';
import DraggableMarker, { Props as DraggableMarkerProps } from './DraggableMarker';

const styles = makeStyles((theme) => ({
  icon: {
    borderRadius: '50%',
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    borderWidth: 4,
    overflow: 'hidden',
    background: theme.palette.background.paper,
    color: theme.palette.primary.main,
    filter: 'drop-shadow(0px 4px 2px rgba(0,0,0,0.3))',
    cursor: 'pointer',
    width: 32,
    height: 32,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  headingWrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  headingIndicator: {
    position: 'absolute',
    width: 5,
    height: 64,
    bottom: 0,
    left: 16 - 2,

    opacity: 0.8,
    backgroundColor: 'white',
  },
}));

interface Props extends DraggableMarkerProps {
  setCoordinate?: (location: [number, number]) => void;
  label: string;
  color: string;
  yaw?: number;
}

export default function AssetOrbitControlMarker({
  setCoordinate,
  label,
  color,
  yaw,
  ...other
}: Props) {
  const ref = React.useRef<HTMLDivElement>(null);

  const classes = styles();

  return (
    <>
      <MapContext.Consumer>
        {(map) => (
          <DraggableMarker
            draggable={setCoordinate !== undefined}
            map={map || undefined}
            onDrag={setCoordinate}
            {...other}>
            {yaw !== undefined && (
              <div className={classes.headingWrapper} style={{ transform: `rotate(${yaw}deg)` }}>
                <div className={classes.headingIndicator} />
              </div>
            )}
            <div ref={ref} className={classes.icon} style={{ borderColor: color }}>
              <Typography variant='caption'>{label}</Typography>
            </div>
          </DraggableMarker>
        )}
      </MapContext.Consumer>
    </>
  );
}
