import React from 'react';
import * as Icons from '~/icons';

import {
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

export const ACTION_NAMES = {
  update: 'Update Params',
  activate: 'Put in Service',
  bringup_complete: 'Bringup Complete',
  deactivate: 'Put in Inventory',
  defect_resolved: 'Defect Resolved',
  found_defect: 'Found Defect',
  maintain: 'Put into Maintenance',
  maintenance_done: 'Maintenance Complete',
  mfg_begin: 'Begin Manufacturing',
  mfg_complete: 'Complete Manufacturing',
  repair: 'Needs Repair',
  repair_done: 'Repair Complete',
  req_remfg: 'Requires Remanufacture',
  retire: 'Permenantly Retire',
};

export const ACTION_COLOR = (theme, action) => {
  return fade(
    {
      update: theme.palette.info.main,
      activate: theme.palette.success.main,
      bringup_complete: theme.palette.success.main,
      deactivate: theme.palette.info.main,
      defect_resolved: theme.palette.success.main,
      found_defect: theme.palette.warning.main,
      maintain: theme.palette.warning.main,
      maintenance_done: theme.palette.success.main,
      mfg_begin: theme.palette.success.main,
      mfg_complete: theme.palette.success.main,
      repair: theme.palette.warning.main,
      repair_done: theme.palette.success.main,
      req_remfg: theme.palette.warning.main,
      retire: theme.palette.error.main,
    }[action] || theme.palette.text.primary,
    0.3
  );
};

export const STATE_COLOR = (theme, status) => {
  const LUX = theme.palette.type == 'dark' ? 'dark' : 'light';
  return {
    loading: undefined,
    PENDING_MFG: theme.palette.info[LUX],
    MFG_IN_PROGRESS: theme.palette.warning[LUX],
    BRINGUP: theme.palette.warning[LUX],
    BRINGUP_DEFECT: theme.palette.error[LUX],
    MFG_DEFECT: theme.palette.error[LUX],
    INVENTORY: theme.palette.info[LUX],
    IN_SERVICE: theme.palette.success[LUX],
    REPAIR: theme.palette.error[LUX],
    MAINTENANCE: theme.palette.warning[LUX],
    RETIRED: theme.palette.error[LUX],
  }[status];
};

export default function ActionBar({ title, status, actions, doAction, editing, save }) {
  const theme = useTheme();

  // Comes from the PartState enum in hardware.proto
  const STATE_NAME = {
    loading: 'Loading...',
    PENDING_MFG: 'Pending manufacture',
    MFG_IN_PROGRESS: 'Manufacture in progress',
    BRINGUP: 'In bringup',
    BRINGUP_DEFECT: 'Defect found in bringup',
    MFG_DEFECT: 'Defect found in manufacture',
    INVENTORY: 'Inventory',
    IN_SERVICE: 'In Service',
    REPAIR: 'Under Repair',
    MAINTENANCE: 'Under Maintenance',
    RETIRED: 'Retired from service',
  }[status];

  const state_color = STATE_COLOR(theme, status);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const doAndClose = (a) => {
    handleClose();
    doAction(a);
  };

  return (
    <Grid item xs={12}>
      <Paper
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          overflow: 'hidden',
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-begin',
            alignItems: 'center',
            flexGrow: 1,
            background: state_color,
            clipPath:
              'polygon( \
                0 0,  \
                100% 0,  \
                calc(100% - 20px) 100%, \
                0 100%  \
                )', // This produces that little angled swoosh
          }}>
          <Typography
            style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 8, paddingBottom: 8 }}
            variant='h4'>
            {title}
          </Typography>
          <Typography variant='h6'>{STATE_NAME}</Typography>
          <div style={{ flexGrow: 1 }} />
          {editing && (
            <Typography variant='h6' style={{ marginRight: 20 }}>
              (Unsaved Changes)
            </Typography>
          )}
        </div>
        <Divider orientation='vertical' />
        <Button onClick={handleClick}>
          Actions <Icons.MoreArrow />
        </Button>
        <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          {editing && (
            <MenuItem
              style={{ margin: 2, padding: [[4, 14]], background: ACTION_COLOR(theme, 'update') }}
              onClick={() => doAndClose('update')}>
              Update
            </MenuItem>
          )}
          {actions.map((action) => (
            <MenuItem
              key={action}
              onClick={() => doAndClose(action)}
              style={{ margin: 2, padding: [[4, 14]], background: ACTION_COLOR(theme, action) }}>
              {ACTION_NAMES[action]}
            </MenuItem>
          ))}
        </Menu>
      </Paper>
    </Grid>
  );
}

/* It's a wizard!

  We get here if we are doing an action. This might be a state transition, or an upadate

  in all cases: 
    we require a changelog.
    display a list of the edits.

    is this changing the assembly tree?
      adding a subassembly
        ?
      removing a subassembly
        ?
      removing self from parent (can't do yet)
        ?

    confirm ownership

    some actions require attached reports!
    allow them to be attached here.
    A report is some optional text and some optional attachments.
      - mfg_complete
      - found_defect
      - defect_resolved
      - repair
      - repair_done
    
    Do we require that the owner changes when we do certain actions?
    Only AR can accept parts in inventory for instance?
    Not sure if I should do this for the first pass.


*/
