import React from 'react';
import { useSelector } from 'react-redux';
import * as wkt from 'wellknown';
import route from '~/lib/activeScene';
import { addStaticMapPopup } from '~/lib/globalMapAccess';
import DividerSpacer from '~/shared/DividerSpacer';
import HelpHere from '~/shared/HelpHere';

import { Divider, List, ListItem, ListItemText, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import * as turf from '@turf/turf';

import { BasicOverlayControlComponent } from './common';

const PREF_KEY = 'analysis_asset_count';
const DEFAULT_PREFS = {};
const CTRL_SZ = [288, 28 * 8];
export const name = 'Asset Count';
export const description =
  'Visualize the number and distribution of identified objects in the region.';

// TODO TOTAL HACK FOR SIZE IN METERS, should be replaced once you have the preprocessor generating this
const px_to_m = 0.052;

function geometryForAsset(a) {
  var center = turf.point([a.lon, a.lat]);
  var front = turf.destination(center, (a.sz_x * px_to_m) / 2, -a.orientation, { units: 'meters' });
  var back = turf.destination(center, (a.sz_x * px_to_m) / 2, 180 + -a.orientation, {
    units: 'meters',
  });

  var fl = turf.destination(front, (a.sz_y * px_to_m) / 2, -a.orientation + 90, {
    units: 'meters',
  });
  var fr = turf.destination(front, (a.sz_y * px_to_m) / 2, -a.orientation - 90, {
    units: 'meters',
  });

  var bl = turf.destination(back, (a.sz_y * px_to_m) / 2, 180 + -a.orientation - 90, {
    units: 'meters',
  });
  var br = turf.destination(back, (a.sz_y * px_to_m) / 2, 180 + -a.orientation + 90, {
    units: 'meters',
  });

  return turf.polygon([
    [
      fl.geometry.coordinates,
      fr.geometry.coordinates,
      br.geometry.coordinates,
      bl.geometry.coordinates,

      fl.geometry.coordinates,
    ],
  ]);
}

function headingStr(angle) {
  let x = 16 * (angle / 360) + 0.5;
  if (x < 0) x += 16;
  if (x >= 16) x -= 16;

  if (x < 1) {
    return 'N';
  } else if (x < 2) {
    return 'NNE';
  } else if (x < 3) {
    return 'NE';
  } else if (x < 4) {
    return 'ENE';
  } else if (x < 5) {
    return 'E';
  } else if (x < 6) {
    return 'ESE';
  } else if (x < 7) {
    return 'SE';
  } else if (x < 8) {
    return 'SSE';
  } else if (x < 9) {
    return 'S';
  } else if (x < 10) {
    return 'SSW';
  } else if (x < 11) {
    return 'SW';
  } else if (x < 12) {
    return 'WSW';
  } else if (x < 13) {
    return 'W';
  } else if (x < 14) {
    return 'WNW';
  } else if (x < 15) {
    return 'NW';
  } else {
    return 'NNW';
  }
}

function toDegreesMinutesAndSeconds(coordinate) {
  var absolute = Math.abs(coordinate);
  var degrees = Math.floor(absolute);
  var minutesNotTruncated = (absolute - degrees) * 60;
  var minutes = Math.floor(minutesNotTruncated);
  var seconds = ((minutesNotTruncated - minutes) * 60).toFixed(3);

  return degrees + '°' + minutes + "'" + seconds + '"';
}

export function clickables(analysis) {
  const { asset_colors, all_assets } = parseAssets(analysis);

  let clickables = [];

  for (let i = 0; i < all_assets.length; i++) {
    const a = all_assets[i];
    const latitude = toDegreesMinutesAndSeconds(a.lat);
    const longitude = toDegreesMinutesAndSeconds(a.lon);
    let hdg = 360 - a.orientation;
    if (hdg < 0) hdg += 360;
    if (hdg >= 360) hdg -= 360;
    clickables.push({
      feature: wkt.stringify(geometryForAsset(a)),
      cursor: 'crosshair',
      onClick: (point) => {
        addStaticMapPopup(
          <div
            style={{
              background: '#fff',
            }}>
            <div
              style={{
                padding: 4,
                width: '100%',
                background: fade(a.color, 0.2),
              }}>
              <Typography variant='subtitle2'>Asset ID #{i}</Typography>
            </div>
            <div
              style={{
                padding: 4,
              }}>
              <Typography variant='body2'>Type:</Typography>
              <Typography variant='body2' color='textSecondary'>
                {a.asset}
              </Typography>
              <Divider />
              <Typography variant='body2'>Location:</Typography>
              <Typography variant='body2' color='textSecondary'>
                {latitude}
                {a.lat >= 0 ? 'N' : 'S'}
              </Typography>
              <Typography variant='body2' color='textSecondary'>
                {longitude}
                {a.lon >= 0 ? 'E' : 'W'}
              </Typography>
              <Divider />
              <Typography variant='body2'>Size:</Typography>
              <Typography variant='body2' color='textSecondary'>
                {(a.sz_x * px_to_m).toFixed(1)}m x {(a.sz_y * px_to_m).toFixed(1)}m
              </Typography>
              <Divider />
              <Typography variant='body2'>Orientation:</Typography>
              <Typography variant='body2' color='textSecondary'>
                {hdg}&deg; {headingStr(hdg)}
              </Typography>
            </div>
          </div>,
          a.lat,
          a.lon
        );
      },
    });
  }
  return clickables;
}

function parseAssets(analysis) {
  const data = JSON.parse(analysis.general_data);

  /*
    {
       "assets": {
      "Asset Name:" [
      {
        "px_x": 814.5,
        "px_y": 1508,
        "lat": 42.3324474,
        "lon": -71.5450265,
        "orientation": 30,
        "sz_x": 98,
        "sz_y": 52
        # Do I know the size in meters? let's pretend I do.
      }, ...      ],
      ...
    }
  }*/

  const asset_ids = Object.keys(data.assets);

  // Color cycle:
  const colors = [
    '#1f77b4',
    '#ff7f0e',
    '#2ca02c',
    '#d62728',
    '#9467bd',
    '#8c564b',
    '#e377c2',
    '#7f7f7f',
    '#bcbd22',
    '#17becf',
  ];

  let asset_colors = {};
  for (const k of asset_ids) {
    asset_colors[k] = colors[asset_ids.indexOf(k) % colors.length];
  }

  let all_assets = [];
  let i = 0;
  for (const k of asset_ids) {
    all_assets.push(
      ...data.assets[k].map((a) => ({
        ...a,
        asset: k,
        assetidx: asset_ids.indexOf(k),
        color: asset_colors[k],
      }))
    );
  }

  return { asset_colors, all_assets };
}

export function overlay(key, analysis, cell_data, preferences, colorForNDVI) {
  // Data contains a list of objects.
  // They are rectangles with a given angle heading, size, and type
  // we want to be able to get more information when clicked, how? it's not a region

  const { asset_colors, all_assets } = parseAssets(analysis);

  const source = {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: all_assets.map((a, i) => ({
        type: 'Feature',
        properties: {
          index: i,
          asset_id: a.assetidx,
          color: a.color,
        },
        geometry: geometryForAsset(a).geometry,
      })),
    },
  };

  let layers = [];

  layers.push({
    id: `analysis-layer-${name}`,
    type: 'fill',
    source: `analysis-source-${name}`,
    paint: {
      'fill-color': ['get', 'color'],
      'fill-opacity': preferences.analysisOpacity,
    },
  });

  layers.push({
    id: `analysis-layer-border-${name}`,
    type: 'line',
    source: `analysis-source-${name}`,
    paint: {
      'line-color': '#ffffff',
      'line-width': 1,
      'line-dasharray': [1, 2],
    },
  });

  if (preferences.analysisLabels) {
    // Also push up an overlay including raw numbers
    layers.push({
      id: `analysis-layer-ids-${name}`,
      type: 'symbol',
      source: `analysis-source-${name}`,
      layout: {
        'text-field': ['get', 'index'],
        'text-size': ['interpolate', ['linear'], ['zoom'], 18, 12, 26, 32],
        'text-padding': 1,
      },

      paint: {
        'text-color': 'hsl(0, 0%, 100%)',
        'text-halo-color': 'rgba(0,0,0,0.5)',
        'text-halo-width': 2,
        'text-halo-blur': 1,
      },
    });
  }

  return [
    {
      // Detail overlay for current trid
      srcid: `analysis-source-${name}`,
      source,
      layers,
    },
  ];
}

const listStyles = makeStyles((theme) => ({
  assetEntry: {
    borderStyle: 'solid',
    borderWidth: [[0, 0, 0, 4]],
    position: 'relative',
  },
  assetBg: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0.2,
  },

  help: {
    position: 'absolute',
    top: 4,
    right: 4,
  },
}));

function ControlComponent({ mapidx }) {
  const classes = listStyles();
  const theme = useTheme();

  const allAnalysis = useSelector((state) => route.visibleAnalytics(state, mapidx));

  // TODO: what if we are showing this from multiple zones?
  const analysis = allAnalysis?.length > 0 ? allAnalysis[0] : null;

  if (!analysis)
    return (
      <div
        style={{
          width: CTRL_SZ[0],
          height: CTRL_SZ[1],
          padding: theme.spacing(1),
        }}>
        <Typography variant='caption' color='error'>
          No asset counting data to display.
        </Typography>
      </div>
    );

  const { asset_colors, all_assets } = parseAssets(analysis);

  return (
    <div
      style={{
        width: CTRL_SZ[0],
        height: CTRL_SZ[1],
        padding: theme.spacing(1),
        overflowY: 'auto',
      }}>
      {/* <HelpHere className={classes.help} topic='Asset Count' /> */}

      <BasicOverlayControlComponent />
      <DividerSpacer />
      <List dense>
        {Object.keys(asset_colors).map((k) => {
          const c = asset_colors[k];
          const count = all_assets.filter((a) => a.asset == k).length;
          return (
            <ListItem key={k} className={classes.assetEntry} style={{ borderColor: c }}>
              <ListItemText primary={k} secondary={`${count} found`} />
              <div className={classes.assetBg} style={{ background: c }} />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}

export function controls(mapidx) {
  return {
    width: CTRL_SZ[0],
    height: CTRL_SZ[1],
    component: <ControlComponent mapidx={mapidx} />,
  };
}
