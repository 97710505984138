import * as MapboxGl from 'mapbox-gl';
import React from 'react';
import Logging from '~/logging';

export default class SimpleMarker extends React.Component {
  marker = null;

  constructor(props) {
    super(props);
    this.element = React.createRef();
  }

  componentDidMount() {
    if (this.props.map && this.element) this.initializeMarker();
  }

  initializeMarker() {
    const { coordinate, map } = this.props;

    try {
      this.marker = new MapboxGl.Marker({
        element: this.element.current,
      })
        .setLngLat(coordinate)
        .addTo(map);
    } catch (e) {
      Logging.error(`SimpleMarker failed to initialize at: "${coordinate}", ${e}`);
    }
  }

  componentWillUnmount() {
    if (this.marker) {
      this.marker.remove();
      this.marker.getElement().remove();
      this.marker = null;
    }
  }

  componentDidUpdate() {
    if (!this.marker && this.props.map) {
      this.initializeMarker();
    }
  }

  render() {
    const { children, coordinate, map, ...props } = this.props;
    return (
      <div id='simple-wrapper' {...props}>
        <div id='simple-elem' ref={this.element}>
          {children}
        </div>
      </div>
    );
  }
}
