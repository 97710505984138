import { DateTime } from 'luxon';
import React from 'react';
import { parseUTCISO } from '~/lib/time';

import { ListItem, Typography } from '@material-ui/core';

export default function PartHistoryEntry({ rev }) {
  const datestr = parseUTCISO(rev.date).toLocaleString(DateTime.DATE_MED);
  return (
    <ListItem style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
      <Typography variant='overline'>
        {datestr}, submitted by: {rev.by}
      </Typography>
      {Object.keys(rev.diff).map((field) => (
        <Typography key={field} variant='caption'>
          <em>{field}</em> changed from <em>{rev.diff[field].was || 'none'}</em> to{' '}
          <em>{rev.diff[field].is || 'none'}</em>
        </Typography>
      ))}
      <Typography variant='body2'>{rev.changelog}</Typography>
    </ListItem>
  );
}
