import React from 'react';
import { automaticGridDivsFromWKT } from '~/lib/analysisGrid';

import { Checkbox, Collapse, FormControlLabel, ListItem, TextField } from '@material-ui/core';

export default function EditGridDivisions({ region, onChange }) {
  return (
    <>
      <ListItem>
        <FormControlLabel
          control={
            <Checkbox
              color='primary'
              checked={region.grid_divisions === undefined}
              onChange={(evt) => {
                if (evt.target.checked) {
                  onChange(undefined);
                } else if (region.grid_divisions === undefined) {
                  const autoGridDiv = automaticGridDivsFromWKT(region.boundary);
                  onChange(autoGridDiv);
                }
              }}
              value='checkedA'
            />
          }
          label='Automatic grid divisions'
        />
      </ListItem>
      <Collapse in={region.grid_divisions !== undefined}>
        <ListItem>
          <TextField
            fullWidth
            label='Grid count X'
            disabled={region.grid_divisions === undefined}
            value={region.grid_divisions?.[0] || 1}
            onChange={(evt) => {
              onChange([parseInt(evt.target.value), region.grid_divisions?.[1]]);
            }}
            InputLabelProps={{ shrink: true }}
            inputProps={{
              type: 'number',
              min: '3',
              max: '1000',
            }}
          />
          &nbsp;
          <TextField
            fullWidth
            label='Grid count Y'
            disabled={region.grid_divisions === undefined}
            value={region.grid_divisions?.[1] || 1}
            onChange={(evt) => {
              onChange([region.grid_divisions?.[0], parseInt(evt.target.value)]);
            }}
            InputLabelProps={{ shrink: true }}
            inputProps={{
              type: 'number',
              min: '3',
              max: '1000',
            }}
          />
        </ListItem>
      </Collapse>
    </>
  );
}
