import React from 'react';

import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListSubheader,
  MenuItem,
  Select,
} from '@material-ui/core';

function RoleCreator({ allRoles, possibleTargets, onAdd, onClose, isOpen }) {
  const [targetIdx, setTargetIdx] = React.useState(null);
  const [roleId, setRoleId] = React.useState(null);

  const roleIds = allRoles.map((r) => r.id);

  const selectedRole = roleId !== null ? allRoles.find((r) => r.id == roleId) : null;
  const selectedTarget = targetIdx !== null ? possibleTargets[targetIdx] : null;

  const targetTypes = new Set(possibleTargets.map((pt) => pt.type));
  const targetsByType = {};
  targetTypes.forEach((targetType) => {
    targetsByType[targetType] = possibleTargets
      .map((pt, idx) => ({ idx, ...pt }))
      .filter((pt) => pt.type == targetType);
  });

  const targetIdxsPerRole = {};
  allRoles.forEach((role) => {
    targetIdxsPerRole[role.id] = [];
    for (const type of role.allowed_target_types) {
      targetIdxsPerRole[role.id].push(...targetsByType[type].map((t) => t.idx));
    }
  });

  var targetSelectContents = [];

  targetTypes.forEach((type) => {
    if (selectedRole?.allowed_target_types?.includes(type)) {
      targetSelectContents.push(
        <ListSubheader key={type}>
          Role target type <em>{type}</em>:
        </ListSubheader>
      );
      targetSelectContents.push(
        ...targetsByType[type].map((tgt) => (
          <MenuItem key={tgt.idx} value={tgt.idx}>
            {tgt.name}
          </MenuItem>
        ))
      );
    }
  });

  const roleSelectContents = roleIds.map((r) => (
    <MenuItem key={r} value={r}>
      {r}
    </MenuItem>
  ));

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth='sm'>
      <DialogTitle>Add role</DialogTitle>
      <DialogContent>
        <List>
          <ListItem>
            <FormControl fullWidth>
              <InputLabel htmlFor='target'>Role</InputLabel>
              <Select
                fullWidth
                error={selectedRole == null}
                value={roleId}
                inputProps={{ id: 'role' }}
                onChange={(e) => {
                  setRoleId(e.target.value);

                  const availableTgts = targetsByType[e.target.value] || [];
                  if (availableTgts.length == 1) {
                    setTargetIdx(availableTgts[0].idx); // If there's only one option, pick that
                  } else if (!targetIdxsPerRole[e.target.value].includes(targetIdx)) {
                    setTargetIdx(null); // If our old option is invalid, clear the input field
                  }
                }}>
                {roleSelectContents}
              </Select>
            </FormControl>
          </ListItem>
          <Collapse in={roleId !== null}>
            <ListItem>
              <FormControl fullWidth>
                <InputLabel htmlFor='target'>Target</InputLabel>
                <Select
                  fullWidth
                  error={selectedTarget == null}
                  value={targetIdx}
                  inputProps={{ id: 'target' }}
                  onChange={(e) => {
                    setTargetIdx(e.target.value);
                  }}>
                  {targetSelectContents}
                </Select>
              </FormControl>
            </ListItem>
          </Collapse>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()} color='secondary'>
          Cancel
        </Button>
        <Button
          disabled={selectedTarget == null || selectedRole == null}
          onClick={() =>
            onAdd({
              role_id: roleId,
              target_type: selectedTarget.type,
              target_id: selectedTarget.id,
            })
          }
          color='primary'>
          Add Role
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RoleCreator;
