import React from 'react';

import { Checkbox } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';

const createZoneStyles = makeStyles((theme) => ({
  root: {
    color: 'var(--checkedColor)',
    '&$checked': {
      color: 'var(--checkedColor)',
    },
  },
  checked: {},
}));

function ColoredCheckbox({ color, ...props }, ref) {
  const classes = createZoneStyles();

  return (
    <Checkbox
      ref={ref}
      classes={classes}
      style={{
        '--checkedColor': color,
      }}
      {...props}
    />
  );
}

export default React.forwardRef(ColoredCheckbox);
