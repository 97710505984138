import { DateTime } from 'luxon';
import React from 'react';
import * as Icons from '~/icons';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { DatePicker, TimePicker } from '@material-ui/pickers';

// String filter controls

function StringFilterControls({ column, filters, setFilters }) {
  const filter = filters[0];
  const values = filter?.value.split(',') || [];

  function setOption(option, checked) {
    const newValues = checked
      ? [...new Set([...values, option])]
      : values.filter((x) => x != option);
    if (newValues.length > 0) {
      setFilters([{ key: column.id, op: '=', value: newValues.join(',') }]);
    } else {
      setFilters([]);
    }
  }

  // TODO: if there are no options, allow arbitrary string search.
  // Even if there are options, show a textbox that lets you filter the checkboxes.

  return (
    <DialogContent dividers>
      <FormControl component='fieldset'>
        <FormGroup>
          {column.filter_opts_str.map((option) => (
            <FormControlLabel
              key={option}
              control={
                <Checkbox
                  color='primary'
                  checked={values.includes(option)}
                  onChange={(e) => setOption(option, event.target.checked)}
                />
              }
              label={option}
            />
          ))}
        </FormGroup>
      </FormControl>
    </DialogContent>
  );
}

// Number
function NumberFilterControls({ column, filters, setFilters }) {
  const minstr = filters.find((f) => f.op == '>')?.value || '';
  const maxstr = filters.find((f) => f.op == '<')?.value || '';

  const setMinMax = (min, max) => {
    let newfilters = [];
    let _min = min;
    let _max = max;

    if (_min !== '') newfilters.push({ key: column.id, op: '>', value: _min });
    if (_max !== '') newfilters.push({ key: column.id, op: '<', value: _max });

    setFilters(newfilters);
  };

  const minAllowed = column.filter_opts_num?.[0] || undefined;
  const maxAllowed = column.filter_opts_num?.[1] || undefined;

  return (
    <DialogContent dividers>
      <TextField
        fullWidth
        label='Min'
        value={minstr || ''}
        onChange={(e) => setMinMax(e.target.value, maxstr)}
        type='number'
        margin='normal'
        variant='filled'
        min={minAllowed}
        max={maxAllowed}
        error={minstr && maxstr && minstr > maxstr}
      />

      <TextField
        fullWidth
        label='Max'
        value={maxstr || ''}
        onChange={(e) => setMinMax(minstr, e.target.value)}
        type='number'
        margin='normal'
        variant='filled'
        min={minAllowed}
        max={maxAllowed}
        error={minstr && maxstr && minstr > maxstr}
      />
    </DialogContent>
  );
}

// Date
function DateFilteringControls({ column, filters, setFilters }) {
  const fromdatestr = filters.find((f) => f.op == '>')?.value;
  const todatestr = filters.find((f) => f.op == '<')?.value;

  const fromdate = fromdatestr ? DateTime.fromISO(fromdatestr) : null;
  const todate = todatestr ? DateTime.fromISO(todatestr) : null;

  const setMinMax = (from, to) => {
    let newfilters = [];

    if (from) newfilters.push({ key: column.id, op: '>', value: from.toISODate() });
    if (to) newfilters.push({ key: column.id, op: '<', value: to.toISODate() });

    setFilters(newfilters);
  };

  const error = fromdate && todate && todate.isBefore(fromdate);

  return (
    <DialogContent
      dividers
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <DatePicker
        keyboardIcon={<Icons.Date />}
        keyboard
        label='From'
        format='MMM D, YYYY'
        disableOpenOnEnter
        value={fromdate}
        onChange={(date) => setMinMax(date, todate)}
        error={error}
      />

      <DatePicker
        keyboardIcon={<Icons.Date />}
        keyboard
        label='To'
        format='MMM D, YYYY'
        disableOpenOnEnter
        value={todate}
        onChange={(date) => setMinMax(fromdate, date)}
        error={error}
      />
    </DialogContent>
  );
}

function FilteringPopup({ open, column, filters, onClose, onUpdate }) {
  const Controls =
    column.type == 'STR'
      ? StringFilterControls
      : column.type == 'NUM'
      ? NumberFilterControls
      : column.type == 'DATE'
      ? DateFilteringControls
      : React.Fragment;

  const [tmpFilters, setTmpFilters] = React.useState(null);

  return (
    <Dialog maxWidth='xs' open={open} onClose={onClose}>
      <DialogTitle id='confirmation-dialog-title'>Filter by {column.title}</DialogTitle>

      <Controls column={column} filters={tmpFilters || filters} setFilters={setTmpFilters} />

      <DialogActions>
        <Button
          onClick={() => {
            onUpdate([]);
            setTmpFilters(null);
          }}>
          Clear
        </Button>
        <Button
          color='primary'
          onClick={() => {
            onUpdate(tmpFilters || filters);
            setTmpFilters(null);
          }}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FilteringPopup;
