import React from 'react';
import { useSelector } from 'react-redux';
import * as Icons from '~/icons';
import { parseUTCISO } from '~/lib/time';
import SelectOrOther from '~/shared/SelectOrOther';

import {
  Button,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';

import NewParameterDialog from './NewParameterDialog';

const EditableTextField = ({ field, value, title, edited, editParameter, mandatory, ...etc }) => (
  <TextField
    size='small'
    fullWidth
    key={field}
    onChange={(e) => editParameter(field, e.target.value)}
    value={value}
    label={title}
    required={mandatory}
    error={mandatory && (!value || value == '')}
    InputProps={{
      endAdornment: edited ? (
        <InputAdornment position='end'>
          <IconButton size='small' onClick={() => editParameter(field, null)}>
            <Icons.Undo />
          </IconButton>
        </InputAdornment>
      ) : null,
    }}
    {...etc}
  />
);

const EditableDateField = ({ field, value, title, edited, editParameter, mandatory, ...etc }) => {
  return (
    <DatePicker
      fullWidth
      label={title}
      id={field}
      format='MMM d, yyyy'
      disableOpenOnEnter
      value={parseUTCISO(value)}
      onChange={(date) => editParameter(field, date.toISODate())}
      required={mandatory}
      error={mandatory && (!value || value == '')}
    />
  );
};

export default function ValueList({
  title,
  items,
  mandatoryItems,
  notes,
  location,
  part_of,
  mfg_date,
  org_id,
  editParameter,
  edits,
  locations,
}) {
  const newParamDialog = React.createRef();

  const itemsAndMandatory = [...mandatoryItems, ...items];
  const mandatoryKeys = mandatoryItems.map((i) => i.field);

  const parameterKeys = [
    ...new Set([...itemsAndMandatory.map((i) => i.field), ...Object.keys(edits || {})]),
  ].filter((x) => !['notes', 'org_id', 'location', 'mfg_date'].includes(x));

  const itemsByKey = items.reduce((obj, item) => {
    obj[item.field] = item;
    return obj;
  }, {});
  const mandatoryByKey = mandatoryItems.reduce((obj, item) => {
    obj[item.field] = item;
    return obj;
  }, {});

  // Make sure that the real items override the mandatory slots.
  const itemsAndMandatoryByKey = { ...mandatoryByKey, ...itemsByKey };

  const mergedParameters = parameterKeys.map(
    (key) => ({
      field: key,
      title: key in itemsAndMandatoryByKey ? itemsAndMandatoryByKey[key].title : key,
      value: key in edits ? edits[key] : itemsAndMandatoryByKey[key].value,
      edited: key in edits,
      mandatory: mandatoryKeys.includes(key),
    }),
    {}
  );

  const assemblyRootName = part_of ? `${part_of.type} ${part_of.sn}` : null;

  // Ok, really this should be a list of other organizations we can transfer the ownership to. I'm not sure how to get that right now.
  const my_org_permissions = useSelector((state) => state.global.org_permissions);
  const my_hw_orgs = Object.keys(my_org_permissions).filter((org_id) =>
    my_org_permissions[org_id].includes('GET_PART')
  );

  let owningOrgs = new Set(['American Robotics', ...my_hw_orgs]);
  if (org_id) owningOrgs.add(org_id);

  return (
    <Paper>
      <List>
        <ListItem>
          <ListItemText primary={title} />

          <ListItemSecondaryAction>
            <Button
              color='primary'
              onClick={async () => {
                const results = await newParamDialog.current.show();
                if (results) {
                  const { newKey, value } = results;
                  editParameter(newKey, value);
                }
              }}>
              Add Parameter
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem dense>
          <EditableTextField
            key='notes'
            multiline
            rowsMax='6'
            field='notes'
            value={'notes' in edits ? edits.notes : notes}
            title='Notes'
            edited={'notes' in edits}
            editParameter={editParameter}
          />
        </ListItem>
        <ListItem dense>
          {!part_of ? (
            <SelectOrOther
              style={{ flexGrow: 1 }}
              label='Location'
              value={'location' in edits ? edits.location : location}
              onChangeSelect={(x) => editParameter('location', x)}
              options={locations}
              allowArbitrary
            />
          ) : (
            // Cannot directly edit the location of a subassembly
            <TextField
              disabled
              size='small'
              fullWidth
              label={`Location (of ${assemblyRootName})`}
              value={location}
            />
          )}
        </ListItem>

        <ListItem dense>
          {!part_of ? (
            <SelectOrOther
              style={{ flexGrow: 1 }}
              label='Owner'
              value={'org_id' in edits ? edits.org_id : org_id}
              onChangeSelect={(x) => editParameter('org_id', x)}
              options={[...owningOrgs]}
            />
          ) : (
            // Cannot directly edit the owner of a subassembly
            <TextField disabled size='small' fullWidth label='Owner' value={org_id} />
          )}
        </ListItem>

        <ListItem dense>
          <EditableDateField
            mandatory
            key='mfg_date'
            field='mfg_date'
            value={'mfg_date' in edits ? edits.mfg_date : mfg_date}
            title='Manufacture Date'
            edited={'mfg_date' in edits}
            editParameter={editParameter}
          />
        </ListItem>

        <Divider />
        {mergedParameters?.map((p) => (
          <ListItem key={p.field} dense>
            <EditableTextField {...p} editParameter={editParameter} />
          </ListItem>
        ))}
      </List>
      <NewParameterDialog ref={newParamDialog} />
    </Paper>
  );
}
