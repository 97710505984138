import { DateTime } from 'luxon';

export function parseUTCISO(dt: string, tz?: string): DateTime {
  return DateTime.fromISO(dt, { zone: 'utc' }).setZone(tz || 'local');
}

export function serlializeUTCISO(dt: DateTime): string {
  return dt.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false });
}

export const wait = (ms: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};
