import React from 'react';
import { useSVSelector } from '~/redux/hooks';
import { term } from '~/markets';

import { Link, Menu, MenuItem, Typography } from '@material-ui/core';
import { allUnhiddenSites } from '~/redux/selectors/global';
import { changeCurrentOrg, changeCurrentSite } from '~/lib/navigation';
import route from '~/lib/activeScene';

interface LinkWithMenuProps {
  onSelect: (value: string) => void;
  options: { id: string; value: string }[];
  selectedId: string | null;
  default?: string;
  textIfNull?: string;
}

function LinkWithMenu(props: LinkWithMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<Element>();
  const handleClose = () => setAnchorEl(undefined);

  const handleClick = (itemId: string) => () => {
    props.onSelect(itemId);
    handleClose();
  };

  const linkText =
    props.selectedId === null
      ? props.textIfNull
      : props.options.find((x) => x.id == props.selectedId)?.value || props.default;

  return props.options.length > 1 ? (
    <>
      <Link
        color='inherit'
        onClick={(evt: React.MouseEvent) => setAnchorEl(evt.currentTarget)}
        underline='hover'>
        {linkText}
      </Link>
      <Link
        color='inherit'
        onClick={(evt: React.MouseEvent) => setAnchorEl(evt.currentTarget)}
        underline='none'
        style={{ opacity: 0.3, marginLeft: 4 }}>
        ▼
      </Link>
      <Menu open={!!anchorEl} anchorEl={anchorEl} keepMounted onClose={handleClose}>
        {[...props.options]
          .sort((a, b) => a.value.localeCompare(b.value))
          .map((item) => (
            <MenuItem
              key={item.id}
              onClick={handleClick(item.id)}
              selected={item.id == props.selectedId}>
              {item.value}
            </MenuItem>
          ))}
      </Menu>
    </>
  ) : (
    <Typography variant='h6' component='span'>
      {linkText}
    </Typography>
  ); // When there's only one option, don't even display it as a link
}

export default function OrgSiteLink() {
  const { orgId, siteId } = useSVSelector(route.orgSite);

  const allOrgs = useSVSelector((state) => state.global.organizations);
  const allSites = useSVSelector(allUnhiddenSites);

  // TODO: Do we know if the global data is ready yet? Display a loading indicator if not

  if (orgId == null) {
    // No organizations are available at all!
    return <Typography variant='h6'>No sites available</Typography>;
  }

  const sitesAtOrg = Object.values(allSites).filter((x) => x.grower_id == orgId);

  return (
    <>
      <Typography variant='h6' component='span' style={{ userSelect: 'none' }}>
        <LinkWithMenu
          onSelect={changeCurrentOrg}
          options={allOrgs.map((x) => ({ id: x.id, value: x.id }))}
          selectedId={orgId}
        />

        <span style={{ marginLeft: 8, marginRight: 8 }}>›</span>

        <LinkWithMenu
          onSelect={changeCurrentSite}
          options={sitesAtOrg.map((x) => ({ id: x.id, value: x.location }))}
          selectedId={siteId}
          textIfNull={`${sitesAtOrg.length} ${term('Sites')}`}
        />
      </Typography>
    </>
  );
}
