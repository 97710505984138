import Logging from '~/logging';
import * as turf from '@turf/turf';
import { Feature, MultiPolygon, Polygon, Position } from '@turf/turf';
import { UsershapeType, PolygonData, UsershapeState } from './types';

export function unionFeatures(features: Feature[]) {
  const polyfeatures: Feature<Polygon | MultiPolygon>[] = [];

  for (const f of features) {
    if (f.geometry.type == 'MultiPolygon' || f.geometry.type == 'Polygon')
      polyfeatures.push(f as Feature<Polygon | MultiPolygon>);
  }

  if (polyfeatures.length == 0) return undefined;
  let accumulator = polyfeatures[0];
  for (let i = 1; i < polyfeatures.length; i++) {
    accumulator = turf.union(accumulator, polyfeatures[i]);
  }
  return accumulator;
}

export function tidyUpState(state: UsershapeState) {
  // If nothing exists, we must be adding

  if (state.type) {
    // there are a few overrides that happen here.
    // For instance, a polygon with < 3 verts *must* still be adding with the latest one selected.

    const ring =
      state.selectedPolygonIdx != undefined && state.selectedRingIdx != undefined
        ? state.polygons[state.selectedPolygonIdx][state.selectedRingIdx]
        : undefined;

    if (state.type == 'polygon' && ring && ring.length < 3) {
      state.isAdding = true;
      state.selectedVertexIdx = ring.length - 1;
    }

    // If we delete one of teh two control points for a rectangle or circle, let the next click add it back.
    if (state.type == 'rectangle' || state.type == 'circle') {
      if (state.polygons[0][0].length == 1) {
        state.isAdding = true;
        state.selectedVertexIdx = 0;
      }
    }

    // Points only have one point!
    if (state.type == 'point' && state.polygons[0][0].length == 1) {
      state.isAdding = false;
      state.selectedPolygonIdx = undefined;
      state.selectedRingIdx = undefined;
      state.selectedVertexIdx = undefined;
    }

    // clear out any empty rings
    for (const i in state.polygons) {
      state.polygons[i] = state.polygons[i].filter((ring) => ring.length > 0);
    }

    // and then remove empty polygons.
    state.polygons = state.polygons.filter((polygon) => polygon.length > 0);

    if (state.polygons.length == 0) {
      state.type = undefined;
    }
  }
}

export function getPolygonsFromFeature(f: Feature): {
  type?: UsershapeType;
  polygons: PolygonData;
} {
  switch (f.geometry.type) {
    case 'Point':
      return { type: 'point', polygons: [[[f.geometry.coordinates as Position]]] };
    case 'LineString':
      return { type: 'line', polygons: [[f.geometry.coordinates as Position[]]] };
    case 'Polygon':
      return {
        type: 'polygon',
        polygons: [(f.geometry.coordinates as Position[][]).map((r) => r.slice(0, -1))],
      };
    case 'MultiPolygon':
      return {
        type: 'polygon',
        polygons: (f.geometry.coordinates as Position[][][]).map((p) =>
          p.map((r) => r.slice(0, -1))
        ),
      };
    default:
      Logging.warn(`Unsupported geometry type: ${f.geometry.type}`);
      return { type: undefined, polygons: [] };
  }
}

export function getMidpoints(ring: Position[], wrap: boolean): Position[] {
  if (ring.length > 1) {
    // we have midpoints to place
    return ring
      .filter((x, i) => i < ring.length - 1 || wrap)
      .map((x, i) => {
        const a = ring[i];
        const b = ring[(i + 1) % ring.length];
        const c = [(a[0] + b[0]) / 2, (a[1] + b[1]) / 2]; // find the midpoint after each vertex
        return c;
      });
  }
  return [];
}

// returns a feature representing the shape boundary, for drawing or extracting WKT.
export function usershapeToFeatures(
  polygons: PolygonData,
  isAdding: boolean,
  type?: UsershapeType,
  selectedPolygonIdx?: number,
  selectedRingIdx?: number
) {
  // First we have to remove empty objects.

  // Remove the rings with no vertices,
  let clean = polygons; //.map(polygon => polygon.filter(ring => ring.length > 0))

  // then remove polygons with no rings.
  clean = clean.filter((polygon) => polygon.length > 0);

  switch (type) {
    case 'polygon': {
      const featuresForPolyIdx = (pidx: number) => {
        const cleanPoly = clean[pidx];

        if (cleanPoly.length == 0) return [];

        const returnFeatures: Feature[] = []; // collect the different features we're returning
        const readyRings: Position[][] = []; // collect the rings that should go into the poly feature

        // TODO: Can I somehow show the pending add location, and draw a line to it?

        // Rings either are a point so no shape, a line, or are the full polygon.
        for (let ri = 0; ri < cleanPoly.length; ri++) {
          const ring = cleanPoly[ri];
          if (ring.length < 2) {
            continue;
          } else if (
            ring.length == 2 ||
            (isAdding && selectedRingIdx == ri && selectedPolygonIdx == pidx)
          ) {
            if (selectedRingIdx)
              returnFeatures.push(
                turf.lineString(cleanPoly[selectedRingIdx], {
                  polySelected: true,
                })
              );
          } else {
            readyRings.push(ring);
          }
        }

        if (readyRings.length > 0) {
          // close the rings of the polygon
          for (const ri in readyRings) {
            const ring = readyRings[ri];
            readyRings[ri] = [...ring, ring[0]];
          }

          returnFeatures.push(
            turf.polygon(readyRings, { polySelected: selectedPolygonIdx == pidx })
          );
        }
        return returnFeatures;
      };

      if (clean.length == 0) {
        return [];
      } else if (clean.length == 1) {
        return featuresForPolyIdx(0);
      } else {
        return clean.map((_, pIdx) => featuresForPolyIdx(pIdx)).flat();
      }
    }
    case 'line': {
      if (clean.length == 0 || clean[0].length == 0 || clean[0][0].length < 2) {
        return [];
      }

      return [turf.lineString(clean[0][0], { polySelected: selectedPolygonIdx == 0 })];
    }
    case 'point': {
      if (clean.length == 0 || clean[0].length == 0 || clean[0][0].length != 1) {
        return [];
      }

      return [turf.point(clean[0][0][0], { polySelected: selectedPolygonIdx == 0 })];
    }
    case 'circle': {
      // the circle must have both control points
      if (polygons.length == 0 || polygons[0].length == 0 || polygons[0][0].length != 2) {
        return [];
      }

      const Pa = polygons[0][0][0];
      const Pb = polygons[0][0][1];
      const fac = Math.cos((Pa[1] * Math.PI) / 180); // scale factor

      const r = Math.sqrt(((Pa[0] - Pb[0]) * fac) ** 2 + (Pa[1] - Pb[1]) ** 2);

      // Generates N evenly spaced angles
      const angles: number[] = [];

      const resolution = 32;

      for (let i = 0; i <= resolution; i++) {
        angles.push(((Math.PI * 2) / resolution) * i);
      }

      // Generates a circle of radius R centered around Pa
      const circlePolygon = angles.map((a) => [
        Pa[0] + (r * Math.cos(a)) / fac, // Compensate for lat/lon scale difference,
        Pa[1] + r * Math.sin(a),
      ]);

      return [turf.polygon([circlePolygon], { polySelected: selectedPolygonIdx == 0 })];
    }
    case 'rectangle': {
      // the rectangle must have both control points
      if (polygons.length == 0 || polygons[0].length == 0 || polygons[0][0].length != 2) {
        return [];
      }

      const Pa = polygons[0][0][0];
      const Pb = polygons[0][0][1];

      const minX = Math.min(Pa[0], Pb[0]);
      const maxX = Math.max(Pa[0], Pb[0]);
      const minY = Math.min(Pa[1], Pb[1]);
      const maxY = Math.max(Pa[1], Pb[1]);
      const rectanglePolygon = [
        [minX, minY],
        [maxX, minY],
        [maxX, maxY],
        [minX, maxY],
        [minX, minY],
      ];
      return [turf.polygon([rectanglePolygon], { polySelected: selectedPolygonIdx == 0 })];
    }
    default:
      return [];
  }
}
