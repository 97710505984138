import React from 'react';
import type { ZXCVBNResult } from 'zxcvbn';

import { Collapse, ListItem, TextField, Tooltip, Typography } from '@material-ui/core';
import { amber, green, red } from '@material-ui/core/colors';

let zxcvbn: (password: string, userInputs?: string[] | undefined) => ZXCVBNResult;

interface Props {
  isNew: boolean;
  keepOpen: boolean;
  password: string;
  confirmPassword: string;
  onChangePassword: (password: string) => void;
  onChangeConfirm: (password: string) => void;
  onChangeScore?: (score: number) => void;
}

export default function PasswordInput({
  isNew,
  keepOpen,
  password,
  confirmPassword,
  onChangePassword,
  onChangeConfirm,
  onChangeScore,
}: Props) {
  import('zxcvbn').then((x) => {
    zxcvbn = x.default;
  });

  const passwordCheck =
    !password || password.length < 8
      ? {
          score: -1,
          feedback: { warning: 'Password must be 8 characters or longer.' },
        }
      : zxcvbn?.(password) || {
          score: -1,
          feedback: {},
        };

  onChangeScore?.(passwordCheck.score);

  const passwordColor = [red[900], red[800], amber[800], green[800], green[500]][
    Math.max(0, passwordCheck.score)
  ];

  const passwordDescriptor = ['None', 'Very Weak', 'Weak', 'Strong', 'Excellent'][
    Math.max(0, passwordCheck.score)
  ];

  return (
    <>
      <ListItem>
        <TextField
          id='password'
          fullWidth
          InputProps={{ type: 'password' }}
          label={isNew ? 'Password' : 'Reset Password'}
          value={password || ''}
          error={isNew && !password}
          onChange={(e) => onChangePassword(e.target.value)}
        />
      </ListItem>
      <Collapse in={keepOpen || isNew || !!password}>
        <ListItem>
          <TextField
            id='confirmpassword'
            fullWidth
            InputProps={{ type: 'password' }}
            label={'Confirm Password'}
            value={confirmPassword || ''}
            error={password != confirmPassword}
            helperText={password == confirmPassword ? 'Passwords match' : 'Passwords do not match'}
            onChange={(e) => onChangeConfirm(e.target.value)}
          />
        </ListItem>

        <ListItem dense>
          <Tooltip title='Password quality meter'>
            <div
              style={{
                borderRadius: 12,
                overflow: 'hidden',
                margin: 4,
                position: 'relative',
                height: 24,
                width: '100%',
                border: `1px solid ${passwordColor}`,
              }}>
              <div
                style={{
                  position: 'absolute',
                  height: '100%',
                  width: `${(passwordCheck ? passwordCheck.score + 1 : 0) * 20}%`,
                  background: passwordColor,
                }}
              />
              <Typography
                style={{
                  position: 'absolute',
                  top: 2,
                  bottom: 0,
                  left: 12,
                  right: 12,
                  opacity: 0.6,
                }}
                variant='caption'>
                Password Strength: {passwordDescriptor}
              </Typography>
            </div>
          </Tooltip>
        </ListItem>

        <ListItem dense>
          <Typography variant='caption' style={{ color: passwordColor }}>
            {passwordCheck?.feedback.warning}
          </Typography>
        </ListItem>
      </Collapse>
    </>
  );
}
