import React from 'react';
import { connect } from 'react-redux';
import * as Icons from '~/icons';
import { actions as uiActions } from '~/redux/slices/ui';

import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Popover,
  Slider,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { getFilterRules, imagesets } from './selectors';
import { actions } from './slice';

const styles = {
  horizFlex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
};

const AvailableImageryFilterDialog = withStyles(styles)(
  connect(
    (state) => ({
      imagesets: imagesets(state),
      filterRules: getFilterRules(state),
    }),
    (dispatch) => ({
      setShowHidden: (x) => dispatch(actions.setFilterRules({ showHidden: x })),
      setImageryResFilter: (evt, x) => dispatch(actions.setFilterRules({ res: x })),
      clearImageryResFilter: () => dispatch(actions.setFilterRules({ res: [0, 100] })),
    })
  )(
    ({
      imagesets,
      filterRules,
      setShowHidden,
      setImageryResFilter,
      clearImageryResFilter,
      classes,
      anchorEl,
      onClose,
    }) => {
      const resolutions = imagesets.map((i) => i.actual_resolution * 1000).filter((r) => !!r);
      const marks = [
        { value: 0, label: 0 },
        ...[...new Set(resolutions.map((i) => Math.round(i / 5) * 5))].map((i) => ({
          value: i,
        })),
        { value: 100, label: 10 },
      ];

      const filterRes = filterRules.res || [0, 100];
      const filtering = !(filterRes[0] == 0 && filterRes[1] == 100);

      return (
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}>
          <div style={{ margin: '4px 12px' }}>
            {/* Fitlering of target results */}
            {imagesets.some((i) => i.hidden) && (
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      color='primary'
                      checked={filterRules.showHidden}
                      onChange={(e) => {
                        setShowHidden(e.target.checked);
                      }}
                    />
                  }
                  label='Show Hidden'
                />
              </div>
            )}
            <div className={`${classes.horizFlex}`}>
              <Typography variant='caption'>Filter Imagery by Res (cm/px)</Typography>

              <Button
                size='small'
                color='primary'
                disabled={!filtering}
                onClick={clearImageryResFilter}>
                Clear
              </Button>
            </div>

            <Slider
              value={[...filterRes]}
              onChange={setImageryResFilter}
              valueLabelDisplay='auto'
              valueLabelFormat={(x) => x / 10}
              step={null}
              max={100}
              min={0}
              marks={marks}
            />
          </div>
        </Popover>
      );
    }
  )
);

export default function AvailableImageryFilter({ color, ...props }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  return (
    <>
      <Button
        size='small'
        // color={anchorEl ? 'primary' : 'secondary'}
        onClick={(evt) => setAnchorEl(evt.currentTarget)}
        {...props}>
        <Icons.Filter style={{ marginRight: 8 }} />
        Filter Imagery
      </Button>

      {/* TODO: Put another button here that toggles whether or not admins can see hidden data. Usually we don't want that on */}

      <AvailableImageryFilterDialog anchorEl={anchorEl} onClose={() => setAnchorEl(null)} />
    </>
  );
}
