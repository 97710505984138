import classNames from 'classnames';
import React from 'react';

import { Button, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const style = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.paper,
    '&.toggled': {
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: theme.palette.action.active,
        opacity: 0.5,
      },
    },
  },
}));

export default function ToggleButtonWithTip({ tooltip, toggled, ...props }) {
  const classes = style();

  return (
    <Tooltip title={tooltip}>
      <Button classes={{ root: classNames(classes.root, { toggled }) }} {...props} />
    </Tooltip>
  );
}
