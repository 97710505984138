// WS calls and parsing related to scheduling
import * as api from '~/lib/api';
import { showSnackbar } from '~/lib/modalServices';
import Goal, * as goals from '~/schema/Goal';
import NoFlyTime, * as nofly from '~/schema/NoFlyTime';
import ScheduleData, * as SD from '~/schema/ScheduleData';
import ScheduledFlightRequest, * as SFR from '~/schema/ScheduledFlightRequest';

export async function getScheduleData(
  siteId: string,
  startDay: string,
  numDays: number
): Promise<ScheduleData> {
  const result: SD.Json = (await api.webrequest('GET', `sv/schedule/site/${siteId}`, undefined, {
    d: startDay,
    n: numDays,
  })) as SD.Json;

  return SD.fromjson(result);
}

export async function uploadSFR(sfr: ScheduledFlightRequest): Promise<ScheduledFlightRequest> {
  let result: SFR.Json;
  try {
    if (sfr.id === undefined) {
      result = (await api.webrequest('POST', `sv/schedule/sfr`, SFR.tojson(sfr))) as SFR.Json;
    } else {
      result = (await api.webrequest(
        'PUT',
        `sv/schedule/sfr/${sfr.id}`,
        SFR.tojson(sfr)
      )) as SFR.Json;
    }
    showSnackbar('success', 'Uploaded scheduled flight request.');

    return SFR.fromjson(result);
  } catch (e) {
    showSnackbar('error', 'Could not upload scheduled flight request.');
    throw e;
  }
}

export async function deleteSFR(sfrid: number): Promise<void> {
  try {
    await api.webrequest('DELETE', `sv/schedule/sfr/${sfrid}`);
    showSnackbar('success', 'Deleted scheduled flight request.');
  } catch (e) {
    showSnackbar('error', 'Could not delete scheduled flight request.');
  }
}

// TODO: POST, PUT, DELETE /schedule/nofly/<id>

export async function uploadNoFly(noflytime: NoFlyTime): Promise<NoFlyTime> {
  let result: nofly.Json;
  try {
    if (noflytime.id === undefined) {
      result = (await api.webrequest(
        'POST',
        `sv/schedule/nofly`,
        nofly.tojson(noflytime)
      )) as nofly.Json;
    } else {
      result = (await api.webrequest(
        'PUT',
        `sv/schedule/nofly/${noflytime.id}`,
        nofly.tojson(noflytime)
      )) as nofly.Json;
    }
    showSnackbar('success', 'Uploaded no-fly time.');

    return nofly.fromjson(result);
  } catch (e) {
    showSnackbar('error', 'Could not upload no-fly time.');
    throw e;
  }
}

export async function deleteNofly(noflyid: number): Promise<void> {
  try {
    await api.webrequest('DELETE', `sv/schedule/nofly/${noflyid}`);
    showSnackbar('success', 'Deleted no-fly time.');
  } catch (e) {
    showSnackbar('error', 'Could not delete no-fly time.');
  }
}

export async function uploadGoal(goal: Goal): Promise<Goal> {
  let result: Goal;
  try {
    if (goal.id === undefined) {
      result = (await api.webrequest('POST', `goal`, goals.tojson(goal))) as Goal;
    } else {
      result = (await api.webrequest('PUT', `goal/${goal.id}`, goals.tojson(goal))) as Goal;
    }
    showSnackbar('success', 'Uploaded goal.');

    return goals.fromjson(result);
  } catch (e) {
    showSnackbar('error', 'Could not upload goal.');
    throw e;
  }
}
