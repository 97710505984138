import * as wkt from 'wellknown';
import { Region } from '~/schema';

import area from '@turf/area';
import bbox from '@turf/bbox';
import booleanContains from '@turf/boolean-contains';
import booleanDisjoint from '@turf/boolean-disjoint';
import center from '@turf/center';
import { Position, feature, point, polygon } from '@turf/helpers';
import * as turf from '@turf/turf';

function polysFromMP(x: turf.Feature<turf.MultiPolygon>) {
  return x.geometry.coordinates.map((p) => polygon(p));
}

// works on multipolygons
export function betterBooleanContains(a: turf.Feature, b: turf.Feature) {
  const MP = 'MultiPolygon';

  const a_ps = a.geometry.type == MP ? polysFromMP(a as turf.Feature<turf.MultiPolygon>) : [a];
  const b_ps = b.geometry.type == MP ? polysFromMP(b as turf.Feature<turf.MultiPolygon>) : [b];

  // all bs must be contained within some as
  for (const b_p of b_ps) {
    let found = false;
    for (const a_p of a_ps) {
      if (booleanContains(a_p, b_p)) {
        found = true;
        break;
      }
    }
    // this b was not contained in an a
    if (!found) return false;
  }
  // we made it here without finding any exposed bs
  return true;
}

// This might be incorrect, or it might just be inconsistent typings?
function parseWkt(shapeWkt?: string): turf.Feature<turf.Geometry> | null {
  if (!shapeWkt) return null;
  return feature(wkt.parse(shapeWkt)) as turf.Feature<turf.Geometry>;
}

export function centerOfShape(shapewkt: string): Position | null {
  const f = parseWkt(shapewkt);
  return f != null ? center(f).geometry.coordinates : null;
}

export function isPointInFeature(p: Position, s: string) {
  const f = parseWkt(s);
  return f == null ? false : betterBooleanContains(f, point(p));
}

export function zoomForResolution(res: number, latitude: number) {
  const earthCircumference = 40075017;
  const latitudeRadians = latitude * (Math.PI / 180);

  return Math.log2((earthCircumference * Math.cos(latitudeRadians)) / res) - 9;
}

export const metersPerPixel = function (latitude: number, zoomLevel: number) {
  const earthCircumference = 40075017;
  const latitudeRadians = latitude * (Math.PI / 180);
  return (earthCircumference * Math.cos(latitudeRadians)) / Math.pow(2, zoomLevel + 9);
};

export function bboxOfPoints(bounds: number[][]): [[number, number], [number, number]] {
  const nonNullBounds = bounds.filter((b) => !!b);
  const lons = nonNullBounds.map((x) => x[0]);
  const lats = nonNullBounds.map((x) => x[1]);
  return [
    [Math.min(...lons), Math.min(...lats)],
    [Math.max(...lons), Math.max(...lats)],
  ];
}

export function bboxOfWKT(shape: string): [[number, number], [number, number]] | null {
  const f = parseWkt(shape);

  if (f?.geometry?.type.toUpperCase() == 'POINT') {
    const lat = f.geometry.coordinates[0] as number;
    const lng = f.geometry.coordinates[1] as number;
    return [
      [lat - 0.001, lng + 0.001],
      [lat + 0.001, lng - 0.001],
    ];
  }
  const bb = bbox(f);
  if (!isFinite(bb[0]) || !isFinite(bb[1]) || !isFinite(bb[2]) || !isFinite(bb[3])) {
    return null;
  }

  return [
    [bb[0], bb[1]],
    [bb[2], bb[3]],
  ];
}

export type UnitsType = 'METERS' | 'ACRES' | 'FEET';

export function areaOfFeature(units: UnitsType, f: turf.Feature) {
  // Can't overlap if you have no area
  if (!['Polygon', 'MultiPolygon'].includes(f.geometry.type)) {
    return 0;
  }

  const sqm = area(f);

  switch (units) {
    case 'METERS':
      return sqm;
    case 'ACRES':
      return sqm / 4046.86;
    case 'FEET':
      return sqm * 10.7639;
    default:
      return null;
  }
}

export function areaOfWKT(units: UnitsType, wkt: string) {
  const f = parseWkt(wkt);
  if (!f) return null;
  return areaOfFeature(units, f);
}

export function resolutionMmAt(altitude: number) {
  return altitude / 1.465;
}

export function resolutionAt(altitude: number) {
  const res = Math.round(resolutionMmAt(altitude) / 5) / 2;
  const alt = Math.round((altitude * 3.28084) / 5) * 5;
  if (res >= 100) {
    return `${alt}' (${res.toFixed(0)} cm res)`;
  } else {
    return `${alt}' (${res.toFixed(1)} cm res)`;
  }
}

export function mToFt(altitude: number) {
  const alt = Math.round((altitude * 3.28084) / 5) * 5;
  return `${alt}'`;
}

export function findDataRegions(flightRegion: Region, allRegions: Region[]) {
  if (flightRegion.type == 'FLIGHT') {
    const parent_poly = parseWkt(flightRegion.boundary);
    if (parent_poly == null) return null;

    return allRegions.filter((r) => {
      if (r.type != 'FIELD') return false;
      const sub_poly = parseWkt(r.boundary);
      if (sub_poly == null) return false;

      const contained = betterBooleanContains(parent_poly, sub_poly);
      return contained;
    });
  } else if (flightRegion.type == 'FIELD' || flightRegion.type == 'TARGETED') {
    return [flightRegion];
  }
}

export function doesRegionOverlapAny(region: Region, others: Region[]) {
  const poly = parseWkt(region.boundary);

  if (poly == null) return false;

  // Can't overlap if you have no area
  if (!['Polygon', 'MultiPolygon'].includes(poly.geometry.type)) {
    return false;
  }

  for (const other of others) {
    const other_poly = parseWkt(other.boundary);
    if (other_poly == null || !['Polygon', 'MultiPolygon'].includes(other_poly.geometry.type)) {
      continue;
    }
    if (!booleanDisjoint(poly, other_poly)) return true;
  }
  return false;
}

export function isFeatureContainedBy(poly: turf.Feature, others: turf.Feature[]) {
  // Can't overlap if you have no area

  if (!['Polygon', 'MultiPolygon'].includes(poly.geometry.type)) {
    return false;
  }

  for (const other of others) {
    if (!['Polygon', 'MultiPolygon'].includes(other.geometry.type)) {
      continue;
    }
    if (betterBooleanContains(other, poly)) return true;
  }
  return false;
}

export function isRegionContainedBy(region: Region, others: Region[]) {
  const poly = parseWkt(region.boundary);

  if (poly == null) return false;

  return isFeatureContainedBy(
    poly,
    others.flatMap((x) => {
      const f = parseWkt(x.boundary);
      if (f != null) return [f];
      else return [];
    })
  );
}
