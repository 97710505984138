import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { ListItem } from '@material-ui/core';

export default function ListItemLink({ to, children, ...props }) {
  if (to.startsWith('http://') || to.startsWith('https://')) {
    // external link
    return (
      <ListItem button component='a' href={to} style={{ textDecoration: 'none' }} {...props}>
        {children}
      </ListItem>
    );
  } else {
    // router link
    return (
      <ListItem button component={RouterLink} to={to} style={{ textDecoration: 'none' }} {...props}>
        {children}
      </ListItem>
    );
  }
}
