import { matchSorter } from 'match-sorter';
import React from 'react';

import { InputBase } from '@material-ui/core';
import { fade, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';

const styles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 1),
    // vertical padding + font size from searchIcon
    // paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

export default function AppbarSearch({ placeholder, options, onNavigate, onSearch }) {
  const classes = styles();

  const filterOptions = (options, { inputValue }) => {
    const filtered = matchSorter(options, inputValue);
    if (inputValue.length == 0) {
      return [];
    }
    return filtered;
  };

  const handleSearch = (value) => {
    if (options.includes(value)) {
      // navigate to this
      onNavigate(value);
    } else {
      onSearch(value);
    }
  };

  return (
    <div className={classes.search}>
      <Autocomplete
        freeSolo
        autoSelect
        clearOnBlur
        clearOnEscape
        selectOnFocus
        disableClearable
        size='small'
        filterOptions={filterOptions}
        noOptionsText='Search content...'
        onChange={(e, value) => handleSearch(value)}
        options={options}
        renderInput={(params) => (
          <InputBase
            {...params.InputProps}
            onMouseDown={(e) => e.stopPropagation()}
            placeholder={placeholder || 'Search…'}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{
              ...params.inputProps,
              style: { width: '100%' },
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        )}
      />
    </div>
  );
}
