import classNames from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';
import * as api from '~/lib/api';
import { parseUTCISO } from '~/lib/time';

import { Button, Card, Typography } from '@material-ui/core';
import { green, grey, indigo, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.background.default,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(2),
    '& > *': {
      marginBottom: theme.spacing(1),
    },
  },

  queueList: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    flexGrow: 1,
    alignItems: 'flex-start',
    '& > *': {
      margin: theme.spacing(1),
    },
  },

  cardGroup: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.divider,

    display: 'flex',
    minHeight: 0, // to fix flex scrolling
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  card: {
    flexShrink: 0,

    padding: theme.spacing(1),
    borderStyle: 'solid',
    borderWidth: 1,
    '&.processor': {
      '&.online': { borderColor: green[100] },
      '&.offline': { borderColor: red[100] },
    },
    '&.job': {
      '&.queued': { borderColor: grey[100] },
      '&.active': { borderColor: indigo[100] },
      '&.complete': { borderColor: green[100] },
      '&.error': { borderColor: red[100] },
    },
  },
}));

interface ProcessingServerProgress {
  request_id: number;
  percent?: number;
  region_id: number;
  phase: string;
  warnings?: string[];
  fatal_error?: string;
  state: 'PENDING' | 'IN_PROGRESS' | 'ERROR' | 'COMPLETE' | 'CANCELLED';
  timestamp: string;
}

interface ProcessorStatus {
  id: number;
  requested: string;
  started?: string;
  completed?: string;
  request: any; // TODO
  processorName: string;
  progress: ProcessingServerProgress;
}

interface QueueResult {
  processors: { [key: string]: string };
  on_queue: ProcessorStatus[];
  active: ProcessorStatus[];
  complete: ProcessorStatus[];
}

async function updateQueue(onComplete: (result: QueueResult) => void) {
  const results = (await api.webrequest('GET', 'processor/queue')) as QueueResult;
  onComplete(results);
}

export default function ProcessorTable() {
  const classes = styles();

  const [queue, setQueue] = React.useState<QueueResult>();

  React.useEffect(() => {
    updateQueue(setQueue);
    const i = setInterval(() => updateQueue(setQueue), 5000);
    return () => clearInterval(i);
  }, []);

  const restartRequest = async (request_id: number) => {
    await api.webrequest('POST', `processor/reset/${request_id}`);
    updateQueue(setQueue);
  };

  if (!queue) {
    return <div className={classes.root} />;
  }

  const queued = [...queue.on_queue].sort(
    (a, b) => a.request.targetResultId - b.request.targetResultId
  );
  const completed = [...queue.complete].sort((a, b) =>
    a.completed!.localeCompare(b.completed || '')
  );

  return (
    <div className={classes.root}>
      <Typography variant='h6'>Preprocessor status</Typography>
      <div className={classes.cardGroup} style={{ flexShrink: 0 }}>
        {Object.keys(queue.processors).map((name) => {
          const pingTime = parseUTCISO(queue.processors[name]);
          const pingBuffer = DateTime.now().minus({ minutes: 2 });
          const online = pingTime > pingBuffer;

          return (
            <Card
              key={name}
              className={classNames(classes.card, 'processor', online ? 'online' : 'offline')}>
              <Typography component='p' variant='subtitle2'>
                {name}
              </Typography>
              <Typography component='p' variant='caption'>
                Last contact {pingTime.toLocaleString(DateTime.DATETIME_FULL)}
              </Typography>
            </Card>
          );
        })}
      </div>
      <Typography variant='h6'>Job Queue</Typography>
      <div className={classes.cardGroup}>
        <div className={classes.queueList}>
          {queue.active.map((job) => {
            // TODO sort by effective date
            const requested = parseUTCISO(job.requested);
            const started = parseUTCISO(job.started!);
            const progressts = parseUTCISO(job.progress.timestamp);
            return (
              <Card key={job.id} className={classNames(classes.card, 'job', 'active')}>
                <Typography variant='subtitle2'>
                  #{job.id} {job.request.requestType} Target Result:
                  {job.request.targetResultId} Region:
                  {job.request.regionId || 'Any'}
                </Typography>
                <Typography component='p' variant='caption'>
                  Requested {requested.toLocaleString(DateTime.DATETIME_FULL)}
                </Typography>
                <Typography component='p' variant='caption'>
                  Started {started.toLocaleString(DateTime.DATETIME_FULL)}
                </Typography>
                <Typography component='p' variant='caption'>
                  {job.progress.phase} as of {progressts.toLocaleString(DateTime.DATETIME_FULL)}.{' '}
                  {job.progress.percent || 0}%
                </Typography>

                <Typography component='p' variant='caption'>
                  Processor: {job.processorName}
                </Typography>
                <Button size='small' onClick={() => restartRequest(job.id)}>
                  Restart
                </Button>
              </Card>
            );
          })}
          {queued.map((job) => {
            // TODO sort by effective date
            const requested = parseUTCISO(job.requested);
            return (
              <Card key={job.id} className={classNames(classes.card, 'job', 'queued')}>
                <Typography component='p' variant='subtitle2'>
                  #{job.id} {job.request.requestType} Target Result:
                  {job.request.targetResultId} Region:
                  {job.request.regionId || 'Any'}
                </Typography>
                <Typography component='p' variant='caption'>
                  Requested {requested.toLocaleString(DateTime.DATETIME_FULL)}
                </Typography>
              </Card>
            );
          })}
        </div>
        <div className={classes.queueList}>
          {completed.map((job) => {
            const requested = parseUTCISO(job.requested);
            const completed = parseUTCISO(job.completed!);
            const took = Math.round(completed.diff(requested).as('minutes'));
            return (
              <Card
                key={job.id}
                className={classNames(
                  classes.card,
                  'job',
                  job.progress.state == 'COMPLETE' ? 'complete' : 'error'
                )}>
                <Typography component='p' variant='subtitle2'>
                  #{job.id} {job.request.requestType} Target Result:
                  {job.request.targetResultId} Region:
                  {job.request.regionId || 'Any'}
                </Typography>
                <Typography component='p' variant='caption'>
                  {job.progress.state}
                </Typography>
                <Typography component='p' variant='caption'>
                  Completed {completed.toLocaleString(DateTime.DATETIME_FULL)}, took {took}m
                </Typography>
                <Typography component='p' variant='caption'>
                  Processor: {job.processorName}
                </Typography>
                <Button size='small' onClick={() => restartRequest(job.id)}>
                  Rerun
                </Button>
              </Card>
            );
          })}
        </div>
      </div>
    </div>
  );
}
