import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import history from '~/lib/history';
import Logging from '~/logging';
import { STATE_COLOR } from '~/scenes/part/ActionBar';
import FancyTable from '~/shared/FancyTable';

import { makeStyles, useTheme } from '@material-ui/core/styles';

import { getQuery } from './selectors';
import { actions } from './slice';

const styles = makeStyles((theme) => ({}));
function PartsTable({ requestRows, rows, columns, totalCount, isFetching, query }) {
  const classes = styles();
  const theme = useTheme();

  React.useEffect(() => {
    requestRows(query);
  }, [query, requestRows]);

  const setQuery = (query) => {
    const str = '?' + queryString.stringify(query, { arrayFormat: 'bracket' });
    history.push({
      search: str,
    });
  };

  const fre = /([a-zA-Z_.]+)(=|!=|<|>)([\w-,]+)/;

  const filterObjects = (query.filters || []).map((f) => {
    const parts = f.match(fre);
    if (!parts) {
      Logging.warn(`filter doesn't match regex`, { filter: f });
      return false;
    }
    return {
      key: parts[1],
      op: parts[2],
      value: parts[3],
    };
  });

  // Rows is an array, maps to columns...
  const rowsById = rows.map((r) => {
    let rowById = {};
    columns.forEach((c, ci) => {
      rowById[c.id] = r.values[ci] || undefined;
    });
    return rowById;
  });

  return (
    <FancyTable
      data={rowsById}
      count={totalCount}
      offset={query.offset}
      limit={query.limit}
      sortCol={query.sortCol}
      sortDir={query.sortDir}
      columns={columns}
      id={(x) => x.id}
      allLoading={isFetching}
      loading={(x) => false}
      paginate={true}
      onClick={(row) => {
        history.push(`/part/${row.type}/${row.sn}`);
      }}
      filters={filterObjects}
      setFilters={(newFilters) => {
        query.filters = newFilters.map((f) => `${f.key}${f.op}${f.value}`);
        setQuery(query);
      }}
      onNewQuery={(newquery) => {
        setQuery({ ...query, ...newquery });
      }}
      styleForCell={(col, val) => {
        if (col.id == 'state') {
          return { background: STATE_COLOR(theme, val) };
        }
      }}
    />
  );
}

const mapStateToProps = (state) => ({
  rows: state.hardwareScene.rows,
  columns: state.hardwareScene.columns,
  isFetching: state.hardwareScene.isFetching,
  totalCount: state.hardwareScene.totalCount,
  query: getQuery(state),
});

const mapDispatchToProps = (dispatch) => ({
  requestRows: (q) => dispatch(actions.requestRows(q)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PartsTable));
