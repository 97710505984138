import React from 'react';
import * as globalSelctors from '~/redux/selectors/global';

import { Typography } from '@material-ui/core';

import UserManagementPage from './UserManagementPage';
import slice, { actions } from './slice';

export { slice };

export const matches = (state) => globalSelctors.paramsForPath(state, '/users');

export const title = (state) => 'User Management';

export const breadcrumbs = (state) => ['User Management'];

export const component = (state) => <UserManagementPage />;

const _fetch = (dispatch) => {
  dispatch(actions.getUsers());
};
export const fetch = (state) => _fetch;

export const helpTopic = () => 'User Management';
