import classNames from 'classnames';
import React from 'react';
import * as Icons from '~/icons';

import { Grow, IconButton, Typography } from '@material-ui/core';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

import MapContext from '../mapContext';
import DraggableMarker from './DraggableMarker';

const styles = (theme) => ({
  farmText: {
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity 0.25s ease',
  },
  visible: {
    opacity: 1,
  },
});

export default withStyles(styles)(
  ({ classes, coordinate, name, visible, onClick, key, ...other }) => {
    const [focussed, setFocussed] = React.useState(false);
    const theme = useTheme();
    return (
      <MapContext.Consumer>
        {(map) => (
          <DraggableMarker coordinate={coordinate} map={map} key={key} {...other}>
            <Grow in={visible} mountOnEnter unmountOnExit appear>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                <IconButton
                  onMouseEnter={() => setFocussed(true)}
                  onMouseLeave={() => setFocussed(false)}
                  onClick={visible ? onClick : undefined}
                  style={{
                    background: fade(theme.palette.primary.main, 0.06),
                    color: 'white',
                    pointerEvents: 'auto',
                    cursor: 'zoom-in',
                  }}>
                  <Icons.Region />
                </IconButton>
                <Typography
                  className={classNames(classes.farmText, {
                    [classes.visible]: focussed,
                  })}
                  style={{
                    color: 'white',
                    textShadow: '0 0 5px #000',
                  }}>
                  {name}
                </Typography>
              </div>
            </Grow>
          </DraggableMarker>
        )}
      </MapContext.Consumer>
    );
  }
);
