import * as geojson from 'geojson';
import mapboxgl from 'mapbox-gl';
import * as wkt from 'wellknown';
import { centerOfShape } from '~/lib/geography';

import * as turf from '@turf/turf';

export default function sourcesForLayer(
  id: string,
  wktstr: string,
  title: string,
  dilation: number
): { [id: string]: mapboxgl.GeoJSONSourceRaw } {
  if (!wktstr) return {};

  let feature: turf.Feature<turf.Geometry, turf.helpers.Properties> = {
    type: 'Feature',
    geometry: wkt.parse(wktstr) as turf.Geometry,
    properties: null,
  };
  if (feature.geometry.type == 'LineString') {
    feature = turf.buffer(feature, Math.max(0.5, dilation || 0), { units: 'meters' });
  }

  const center = centerOfShape(wktstr)!;

  const polygonSource: mapboxgl.GeoJSONSourceRaw = {
    type: 'geojson',
    data: feature as geojson.Feature,
  };
  const textSource: mapboxgl.GeoJSONSourceRaw = {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: center,
          },
          properties: { title: title },
        },
      ],
    },
  };
  return {
    [`${id}-src`]: polygonSource,
    [`${id}-text-src`]: textSource,
  };
}
