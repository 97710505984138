import ReactGA from 'react-ga';

export const initGA = () => {
  ReactGA.initialize([
    {
      trackingId: 'UA-212261244-1',
      gaOptions: {
        siteSpeedSampleRate: 100,
      },
    },
  ]);
};

export const GAevent = (categoryName: string, eventName: string, labelName: string) => {
  ReactGA.event({
    category: categoryName, // Required
    action: eventName, // Required
    label: labelName,
  });
};

export const GAtiming = (categoryName: string, variableName: string, valueNum: number) => {
  ReactGA.timing({
    category: categoryName,
    variable: variableName,
    value: valueNum,
  });
};

export const GAexception = (detail: string) => {
  ReactGA.exception({ description: detail });
};
