import React from 'react';
import { connect } from 'react-redux';
import * as wkt from 'wellknown';
import Logging from '~/logging';
import { SafeSpot } from '~/map/markers';
import store from '~/store';

import { getTmpFarm, selectedFarmId } from './selectors';
import { actions } from './slice';

function SafeSpotMarker({ selectedFarmId, tmpFarm, ssidx, setTmpFarm, ...etc }) {
  const ss_new = tmpFarm.safelandlocations[ssidx];

  const coord = wkt.parse(ss_new.location)?.coordinates;

  if (!coord) {
    Logging.error(`Can't parse SS coordinates`, { coordinates: ss_new.location });
  }

  return (
    <SafeSpot
      name={ss_new.display_name}
      coordinate={coord}
      pending={ss_new.type == 'PRIMARY'}
      draggable={true}
      onDrag={(coord) => {
        const ssledits = [...tmpFarm.safelandlocations];
        const oldPoint = wkt.parse(ss_new.location).coordinates;
        ssledits[ssidx] = {
          ...ss_new,
          location: `POINT(${coord[0]} ${coord[1]} ${oldPoint[2] || 15})`,
        };
        setTmpFarm(selectedFarmId, { ...tmpFarm, safelandlocations: ssledits });
      }}
      {...etc}
    />
  );
}

const SmartSafeSpotMarker = connect(
  (state) => ({
    selectedFarmId: selectedFarmId(state),
    tmpFarm: getTmpFarm(state),
  }),
  (dispatch) => ({
    setTmpFarm: (id, farm) => dispatch(actions.setTmpFarm({ id, farm })),
  })
)(SafeSpotMarker);

export default SmartSafeSpotMarker;
