import produce from 'immer';
import stringify from 'json-stable-stringify';
import { DateTime } from 'luxon';
import React from 'react';
import AvatarEditor from 'react-avatar-edit';
import { connect } from 'react-redux';
import * as Icons from '~/icons';
import { showConfirmDialog } from '~/lib/modalServices';
import PasswordCheck from '~/shared/PasswordCheck';

import {
  Avatar,
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { amber, blue, green, purple, red, yellow } from '@material-ui/core/colors';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

import RoleCreator from './RoleCreator';
import { getTmpUser, selectedUser, selectedUserId } from './selectors';
import { actions } from './slice';

const styles = {
  buttonList: {
    display: 'flex',
    flexGrow: 1,
  },
  spacer: {
    width: 4,
  },
};

const timeFormat = DateTime.DATETIME_FULL;

function ListItemForRole({ possibleTargets, role, onRemove, ...etc }) {
  const theme = useTheme();
  const borderColor = theme.palette.primary.main;

  const target_name =
    possibleTargets.find((pt) => pt.type == role.target_type && pt.id == role.target_id)?.name ||
    '?';

  return (
    <ListItem
      {...etc}
      style={{
        borderLeftColor: borderColor,
        borderTopColor: 'transparent',
        borderBottomColor: 'transparent',
        borderRightColor: 'transparent',
        borderStyle: 'solid',
        borderWidth: 4,
      }}>
      <Typography variant='body1'>
        {role.role_id.toUpperCase()} on <em>{target_name}</em>
      </Typography>
      <ListItemSecondaryAction>
        <IconButton onClick={onRemove}>
          <Icons.Delete />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}

class UserEditPanel extends React.Component {
  state = {
    roleDialogOpen: false,
    confirmPassword: '',
    isEditingAvatar: false,
  };

  closeRoleDialog = () => this.setState({ ...this.state, roleDialogOpen: false });
  openRoleDialog = () => this.setState({ ...this.state, roleDialogOpen: true });

  revert = async () => {
    const { setTmpUser, selectedUserId } = this.props;

    if (this.isDirty()) {
      const confirm = await showConfirmDialog(
        'Discard changes',
        'Are you sure you want to discard unsaved changes?',
        'Discard'
      );
      if (!confirm) return;
    }

    setTmpUser(selectedUserId, undefined); // will clear the tmp entry
    this.setState({ ...this.state, isEditingAvatar: false });
  };

  save = () => {
    const { uploadUser, selectedUserId, tmpUser } = this.props;
    uploadUser(selectedUserId, tmpUser);

    this.setState({ ...this.state, isEditingAvatar: false });
  };

  addRole = (role) => {
    this.update((u) => {
      if (u.roles) {
        u.roles.push({ ...role, created: true });
      } else {
        u.roles = [{ ...role, created: true }];
      }
    });
    this.closeRoleDialog();
  };

  removeRole = (idx) => {
    this.update((u) => {
      const ridx = u.roles.findIndex((x) => x === u.roles.filter((r) => !r.deleted)[idx]);
      const role = u.roles[ridx];
      if (role.id) {
        // If it's an existing one with an ID, we have to mark for deletion.
        role.deleted = true;
        return;
      } else {
        // If we just created it, we can just remove it.
        u.roles.splice(ridx, 1);
      }
    });
  };

  update = (f) => {
    const { setTmpUser, selectedUserId, tmpUser } = this.props;
    const x = produce(tmpUser, (draft) => {
      f(draft);
    });
    setTmpUser(selectedUserId, x);
  };

  isDirty = () => {
    const { originalUser, tmpUser } = this.props;

    const cleaner = (k, v) => {
      const ignore = ['last_updated', 'id'];
      if (ignore.includes(k)) {
        return undefined;
      }
      if (v === null) {
        return undefined;
      }
      return v;
    };

    return (
      stringify(originalUser, { replacer: cleaner }) != stringify(tmpUser, { replacer: cleaner })
    );
  };

  render() {
    const { classes, tmpUser, originalUser, possibleTargets, allRoles } = this.props;
    const { confirmPassword, isEditingAvatar } = this.state;

    if (!tmpUser) return null; // TODO: a placeholder?

    const isDirty = this.isDirty();
    const isNew = originalUser.id === null;

    const isComplete =
      tmpUser.id != '' &&
      tmpUser.display_name != '' &&
      (!tmpUser.password || tmpUser.password == confirmPassword);

    return (
      <>
        <List dense>
          <ListItem>
            <Typography
              variant='h6'
              style={{
                textTransform: 'none',
              }}>
              {isNew ? 'Add New User' : tmpUser.id}
            </Typography>
          </ListItem>

          {isNew && (
            <ListItem>
              <TextField
                required
                id='id'
                label='Login ID / Email'
                fullWidth
                value={tmpUser.id || ''}
                error={!tmpUser.id}
                onChange={(e) => {
                  this.update((u) => (u.id = e.target.value.toLowerCase()));
                }}
              />
            </ListItem>
          )}

          <ListItem>
            <TextField
              required
              id='email'
              fullWidth
              label='Backup Email (if not id)'
              value={tmpUser.email || ''}
              error={tmpUser.email == ''}
              onChange={(e) => this.update((u) => (u.email = e.target.value))}
            />
          </ListItem>
          <ListItem>
            <TextField
              required
              id='name'
              fullWidth
              label='Full Name'
              value={tmpUser.display_name || ''}
              error={tmpUser.display_name == ''}
              onChange={(e) => this.update((u) => (u.display_name = e.target.value))}
            />
          </ListItem>

          <PasswordCheck
            isNew={isNew}
            password={tmpUser.password}
            onChangePassword={(v) => this.update((u) => (u.password = v))}
            confirmPassword={confirmPassword}
            onChangeConfirm={(v) => this.setState({ ...this.state, confirmPassword: v })}
          />

          <ListItem>
            {isEditingAvatar ? (
              <div style={{ padding: 12 }}>
                {/* to match the iconbutton */}
                <AvatarEditor
                  width={160}
                  imageWidth={160}
                  onCrop={(img) => this.update((u) => (u.avatar = img))}
                  onClose={() => {
                    this.update((u) => (u.avatar = null));
                  }}
                  src={tmpUser.avatar}
                  cropRadius={70}
                  label='Upload'
                />
              </div>
            ) : (
              <Tooltip title='Change Avatar'>
                <IconButton onClick={() => this.setState({ ...this.state, isEditingAvatar: true })}>
                  <Avatar style={{ width: 160, height: 160 }} src={tmpUser.avatar} />
                </IconButton>
              </Tooltip>
            )}
          </ListItem>

          <ListItem>
            <TextField
              id='pilot_id'
              fullWidth
              label='RPIC Certificate #'
              value={tmpUser.pilot_id || ''}
              onChange={(e) => this.update((u) => (u.pilot_id = e.target.value.toUpperCase()))}
            />
          </ListItem>

          <ListItem>
            <ToggleButtonGroup
              value={tmpUser.preferred_units || 'IMPERIAL'}
              exclusive
              onChange={(e, val) => this.update((u) => (u.preferred_units = val))}>
              <ToggleButton value='IMPERIAL'>
                <Typography variant='button'>US</Typography>
              </ToggleButton>
              <ToggleButton value='METRIC'>
                <Typography variant='button'>Metric</Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </ListItem>

          {/* Here are some special things we can set with their own checkboxes. */}
          <ListItem dense>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!tmpUser.deleted}
                  onChange={(_, checked) => this.update((u) => (u.deleted = !checked))}
                  value='Enabled'
                  color='primary'
                />
              }
              label='Account Enabled'
            />
          </ListItem>

          <ListItem dense>
            <FormControlLabel
              control={<Checkbox checked={tmpUser.confirmed} disabled color='primary' />}
              label='Email Confirmed'
            />
          </ListItem>
          <Divider />
          {tmpUser.roles
            ?.filter((r) => !r.deleted)
            ?.map((role, i) => (
              <ListItemForRole
                possibleTargets={possibleTargets}
                role={role}
                onRemove={() => this.removeRole(i)}
                dense
                key={i}
              />
            )) || (
            <ListItem dense>
              <ListItemText primary='No Roles Assigned' />
            </ListItem>
          )}
          <ListItem button dense onClick={this.openRoleDialog}>
            <ListItemIcon>
              <Icons.Add />
            </ListItemIcon>
            <ListItemText primary='Grant security role' />
          </ListItem>

          <Divider />
          <ListItem style={{ justifyContent: 'flex-end' }}>
            {!isNew && <Button onClick={this.revert}>Revert</Button>}
            <div className={classes.spacer} />
            <Button color='primary' disabled={!isDirty || !isComplete} onClick={this.save}>
              Save
            </Button>
          </ListItem>
          {!isNew && (
            <>
              <Divider />
              <ListItem>
                <ListItemText
                  primary={tmpUser.last_activity?.toLocaleString(timeFormat) || 'N/A'}
                  secondary='Last Access'
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={tmpUser.last_login?.toLocaleString(timeFormat) || 'N/A'}
                  secondary='Last Login'
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={tmpUser.last_updated?.toLocaleString(timeFormat) || 'N/A'}
                  secondary='Last Updated'
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={tmpUser.created?.toLocaleString(timeFormat) || 'N/A'}
                  secondary='Created'
                />
              </ListItem>
            </>
          )}
        </List>
        <RoleCreator
          allRoles={allRoles}
          possibleTargets={possibleTargets}
          onAdd={this.addRole}
          onClose={this.closeRoleDialog}
          isOpen={this.state.roleDialogOpen}
        />
      </>
    );
  }
}

export default connect(
  (state) => ({
    allRoles: state.usersScene.roles,
    tmpUser: getTmpUser(state),
    originalUser: selectedUser(state),
    selectedUserId: selectedUserId(state),
    possibleTargets: state.usersScene.possibleTargets,
  }),
  (dispatch) => ({
    setTmpUser: (id, user) => dispatch(actions.setTmpUser({ id, user })),
    uploadUser: (id, user) => dispatch(actions.uploadUser(id, user)),
  })
)(withStyles(styles)(UserEditPanel));
