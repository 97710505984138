import React from 'react';
import * as Icons from '~/icons';
import { showConfirmDialog } from '~/lib/modalServices';
import { Goal, Survey } from '~/schema';
import { GoalType } from '~/schema/Goal';

import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  TextField,
  makeStyles,
} from '@material-ui/core';

interface Props {
  open: boolean;
  onClose(): void;
  goal?: Goal;
  updateGoal(goal: Goal): Promise<void>;
  availableSurveys: Survey[];
}

const styles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    '& > *:not(:first-child)': { marginTop: theme.spacing(1) },
  },
}));

// Edit or create a new SFR.
export default function GoalEditDialog(props: Props) {
  const classes = styles();

  const [tmpGoal, setTmpGoal] = React.useState<Goal | undefined>();

  React.useEffect(() => {
    setTmpGoal(props.goal); // Update the internal state when we get a new event.
  }, [props.goal]);

  const save = async () => {
    if (tmpGoal !== undefined) await props.updateGoal(tmpGoal);
  };

  if (!tmpGoal) return null;

  const allTypes: GoalType[] = ['FREQUENT_AS_POSSIBLE', 'ONCE_PER_DAY', 'STOCKPILE_REPORTS'];
  const typeNames = {
    FREQUENT_AS_POSSIBLE: 'Image as frequently as possible',
    ONCE_PER_DAY: 'Image once per day',
    STOCKPILE_REPORTS: 'Stockpile Reports',
  };

  const unselectableTypes: GoalType[] = ['STOCKPILE_REPORTS'];
  const selectableTypes: GoalType[] = allTypes.filter((x) => !unselectableTypes.includes(x));

  const nameForSurvey = (surveyId: number) =>
    props.availableSurveys.find((s) => s.id == surveyId)?.display_name || 'Unknown';

  const firstSurveyId = tmpGoal.survey_ids_to_schedule?.[0] || undefined;

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth
      maxWidth='xs'>
      <DialogTitle>Imaging Goal</DialogTitle>
      <DialogContent className={classes.content}>
        <TextField
          fullWidth
          label='Goal Rule'
          select
          onChange={(evt) => setTmpGoal({ ...tmpGoal, goal_kind: evt.target.value as GoalType })}
          value={tmpGoal.goal_kind}>
          {allTypes.map((b) => (
            <MenuItem key={b} value={b} disabled={!selectableTypes.includes(b)}>
              {typeNames[b]}
            </MenuItem>
          ))}
        </TextField>

        <Divider />

        <Collapse in={['FREQUENT_AS_POSSIBLE', 'ONCE_PER_DAY'].includes(tmpGoal.goal_kind)}>
          <TextField
            fullWidth
            select
            SelectProps={{
              renderValue: (_) =>
                firstSurveyId ? nameForSurvey(firstSurveyId) : 'No surveys selected',
            }}
            label='Survey to run'
            value={firstSurveyId}
            error={!tmpGoal.survey_ids_to_schedule || tmpGoal.survey_ids_to_schedule.length == 0}
            onChange={(evt) => {
              const surveyIds = evt.target.value as unknown as number;
              setTmpGoal({
                ...tmpGoal,
                survey_ids_to_schedule: [surveyIds],
              });
            }}>
            {props.availableSurveys.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {/* <Checkbox
                  color='primary'
                  checked={(tmpGoal.survey_ids_to_schedule || []).indexOf(item.id) > -1}
                /> */}
                {item.display_name}
              </MenuItem>
            ))}
          </TextField>
        </Collapse>
      </DialogContent>
      <DialogActions>
        {tmpGoal?.id && (
          <IconButton
            style={{ color: 'red' }}
            onClick={async () => {
              if (
                await showConfirmDialog(
                  'Remove Goal',
                  'This will remove this imagery goal from this site.',
                  'Remove'
                )
              ) {
                await props.updateGoal({ ...tmpGoal, enabled: false });
              }
            }}>
            <Icons.Delete></Icons.Delete>
          </IconButton>
        )}

        <Button color='primary' onClick={props.onClose}>
          Cancel
        </Button>
        <Button aria-label='save' color='primary' onClick={save}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
