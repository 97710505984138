import queryString from 'query-string';
import { createSelector } from 'reselect';
import * as globalSelctors from '~/redux/selectors/global';

export const getQuery = createSelector(
  (state) => state.router.location.search,
  (state) => state.hardwareScene.searchStr,
  (queryStr, searchStr) => {
    const query = queryString.parse(queryStr, { arrayFormat: 'bracket' });
    return {
      filters: query.filters,
      sortDir: query.sortDir,
      sortCol: query.sortCol,
      offset: parseInt(query.offset) || 0,
      limit: parseInt(query.limit) || 20,
      search: searchStr ? searchStr : null,
    };
  }
);
