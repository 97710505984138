import React from 'react';

import { Slider, makeStyles } from '@material-ui/core';

const gradientStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
  },
  colorBar: {
    height: theme.spacing(2),
  },
  valueLabel: {
    left: 'calc(-50% - 4px)',
    bottom: theme.spacing(2),
    '& *': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
}));

export default function GradientEditor({ colors, stops, setStops, min, max, step, format }) {
  const classes = gradientStyles();

  min = min || 0;
  max = max || 1;
  step = step || 0.01;

  const toPc = (x) => `${Math.round(((x - min) / (max - min)) * 100)}%`;

  const extendedStops = [min, ...stops, max];

  // Produce a CSS gradient that matches these stops.
  const cssGradientStops = colors.map((c, i) => {
    return `${c} ${toPc(extendedStops[i])} ${toPc(extendedStops[i + 1])}`;
  });

  const cssGradient = `linear-gradient(90deg, ${cssGradientStops.join(', ')})`;

  const percentFormat = (x) => `${Math.round(x * 100)}%`;

  return (
    <div className={classes.container}>
      <div
        className={classes.colorBar}
        style={{
          background: cssGradient,
        }}
      />
      <Slider
        classes={{ valueLabel: classes.valueLabel }}
        track={false}
        valueLabelDisplay='auto'
        valueLabelFormat={format || percentFormat}
        min={min}
        max={max}
        step={step}
        value={[...stops]}
        onChange={(e, v) => {
          setStops(v);
        }}
      />
    </div>
  );
}
