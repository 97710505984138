import React from 'react';

import SupportDialog from './SupportDialog';

export interface HelpTopicPayload {
  topic: string;
  history: string[];
}

export default function SupportDialogHost({
  payload,
  onClose,
}: {
  payload: HelpTopicPayload | undefined;
  onClose: () => void;
}) {
  return (
    <SupportDialog
      open={payload != null}
      history={payload?.history || []}
      topic={payload?.topic || ''}
      onClose={onClose}
    />
  );
}
