import { DateTime, Duration } from 'luxon';
import { parseUTCISO } from '~/lib/time';

export default interface ScheduledFlightRequest {
  id?: number;
  base_sn: string;
  survey_id?: number;
  expected_datetime: DateTime;
  expected_duration_s: Duration;
  processing_parameters_id?: number;
  author?: string;
  goal_id?: number;
}

export interface Json {
  id?: number;
  base_sn: string;
  survey_id?: number;
  expected_datetime: string;
  expected_duration_s: number;
  processing_parameters_id?: number;
  author?: string;
  goal_id?: number;
}

export function fromjson(json: Json): ScheduledFlightRequest {
  return {
    ...json,
    base_sn: json.base_sn || '',
    expected_datetime: parseUTCISO(json.expected_datetime, 'utc'),
    expected_duration_s: Duration.fromObject({ seconds: json.expected_duration_s }),
  };
}

export function tojson(o: ScheduledFlightRequest): Json {
  return {
    ...o,
    expected_datetime: o.expected_datetime
      .toUTC()
      .toISO({ suppressMilliseconds: true, includeOffset: false }),
    expected_duration_s: o.expected_duration_s.as('seconds'),
  };
}
