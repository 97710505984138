import classNames from 'classnames';
import React from 'react';

import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

const styles = (theme) => ({
  table: {
    flexGrow: 1,
    width: '100%',
    // display: 'grid',
    // alignItems: 'stretch',
    // gridTemplateColumns: 'minmax(0,1fr) minmax(0,1fr)',
  },

  oddRow: {
    background: fade(theme.palette.action.hover, 0.04),
  },
  evenRow: {
    background: fade(theme.palette.action.hover, 0.02),
  },
  cell: { display: 'flex', alignItems: 'center', overflowX: 'auto' },

  cellKey: { paddingLeft: 4, paddingRight: 4, whiteSpace: 'nowrap' },

  cellValue: { display: 'flex', justifyContent: 'flex-end', whiteSpace: 'nowrap' },

  spacer: { flexGrow: 1 },
});

class SimpleTable extends React.Component {
  render() {
    const {
      classes,
      data, // expected: [ { title, value, units?}]
    } = this.props;

    return (
      <div className={classes.table}>
        {data.map((x, ei) => (
          <React.Fragment key={ei}>
            <div
              className={classNames(
                classes.cell,
                ei % 2 ? classes.evenRow : classes.oddRow,
                classes.cellKey
              )}>
              <Typography color='inherit'>
                <b>{x.title}:</b>
              </Typography>
              <div className={classes.spacer} />
              <Typography
                color='inherit'
                align='right'
                inline={true}
                className={classNames(classes.cellValue)}>
                {x.value}
              </Typography>
              {x.units ? (
                <Typography style={{ color: 'rgba(0,0,0,0.5)' }} align='right' inline={true}>
                  {x.units}
                </Typography>
              ) : null}
            </div>
          </React.Fragment>
        ))}
      </div>
    );
  }
}
export default withStyles(styles)(SimpleTable);
