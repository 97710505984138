import { DateTime } from 'luxon';
import React from 'react';
import { connect } from 'react-redux';
import * as Icons from '~/icons';
import { parseUTCISO } from '~/lib/time';
import { actions as noteActions } from '~/redux/slices/notes';
import { actions as uiActions } from '~/redux/slices/ui';
import ImageAttachments from '~/shared/ImageAttachments';
import UploadPhoto from '~/shared/UploadPhoto';
import UserAvatar from '~/shared/UserAvatar';

import {
  Avatar,
  Button,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import * as colors from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  scrollList: {
    maxHeight: 300,
    overflowY: 'auto',
  },

  cardActionArea: { position: 'relative', background: theme.palette.background.default },
});

const LIST_OF_COLORS_FOR_COMMENTORS = [
  colors.red.A700,
  colors.blue.A700,
  colors.amber.A700,
  colors.purple.A700,
  colors.lightGreen.A700,
  colors.indigo.A700,
  colors.yellow.A700,
  colors.teal.A700,
];

const formatTime = (tstr) => {
  return parseUTCISO(tstr).toLocaleString(DateTime.DATETIME_SHORT);
};

function ExistingNote({ note, showImages, submitComment, deleteNote, username, isAdmin, classes }) {
  const [commentTxt, setCommentTxt] = React.useState('');
  const [commentAtt, setCommentAtt] = React.useState([]);
  const uploadPhoto = React.createRef();

  const [popupAnchorEl, setPopupAnchorEl] = React.useState(null);

  const onNewPhoto = (photo) => {
    setCommentAtt([...commentAtt, photo]);
  };

  const updateCommentTxt = (e) => setCommentTxt(e.target.value);

  const doSubmitComment = (e) => {
    submitComment({
      note_id: note.id,
      note: commentTxt,
      attachments: commentAtt,
    });

    setCommentAtt([]);
    setCommentTxt('');
  };

  let colorForUsers = {
    [note.author_user_id]: LIST_OF_COLORS_FOR_COMMENTORS[0],
  };

  if (note.comments) {
    for (const comment of note.comments) {
      if (!Object.keys(colorForUsers).includes(comment.author_user_id)) {
        colorForUsers[comment.author_user_id] =
          LIST_OF_COLORS_FOR_COMMENTORS[Object.keys(colorForUsers).length % 8];
      }
    }
  }

  return (
    <>
      {note.attachments?.length > 0 && (
        <CardActionArea
          className={classes.cardActionArea}
          onClick={() => showImages(note.attachments)}>
          <CardMedia style={{ height: 200 }} image={note.attachments[0]} />

          <Icons.ZoomFarm
            style={{ opacity: 0.5, color: 'white', position: 'absolute', right: 8, bottom: 8 }}
          />
        </CardActionArea>
      )}
      <CardContent>
        {/* 
      Need to have:
        author_user_id
        last_updated
        note content
        attached images
        comments (and their attached images)
      */}
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            marginBottom: 8,
          }}>
          <ListItemAvatar>
            <UserAvatar
              userId={note.author_user_id}
              missingColor={colorForUsers[note.author_user_id]}
            />
          </ListItemAvatar>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              maxHeight: 100,
              overflowY: 'auto',
            }}>
            <Typography variant='body2'>{note.note}</Typography>
            <Typography style={{ opacity: 0.7 }} variant='caption'>
              {formatTime(note.last_updated)} by <em>{note.author_user_id}</em>
            </Typography>
          </div>

          {username == note.author_user_id && (
            <IconButton edge='end' onClick={(evt) => setPopupAnchorEl(evt.currentTarget)}>
              <Icons.MoreDots />
            </IconButton>
          )}

          <Menu
            anchorEl={popupAnchorEl}
            keepMounted
            open={Boolean(popupAnchorEl)}
            onClose={() => setPopupAnchorEl(null)}>
            <MenuItem
              onClick={() => {
                deleteNote(note.id);
                setPopupAnchorEl(null);
              }}>
              Delete Note
            </MenuItem>
          </Menu>
        </div>
      </CardContent>
      <Divider />
      <CardContent>
        <List disablePadding classes={{ root: classes.scrollList }}>
          {note.comments?.map((comment) => (
            <React.Fragment key={comment.id}>
              <ListItem key={comment.id} dense disableGutters alignItems='flex-start'>
                <ListItemAvatar>
                  <UserAvatar
                    userId={comment.author_user_id}
                    missingColor={colorForUsers[comment.author_user_id]}
                  />
                </ListItemAvatar>
                <ListItemText>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant='body2'>{comment.note}</Typography>

                    {comment.attachments && (
                      <ImageAttachments
                        left
                        images={comment.attachments}
                        onClick={() => showImages(comment.attachments)}
                      />
                    )}
                    <Typography style={{ opacity: 0.7 }} variant='caption'>
                      {formatTime(comment.last_updated)} by <em>{comment.author_user_id}</em>
                    </Typography>
                  </div>
                </ListItemText>
              </ListItem>
            </React.Fragment>
          ))}

          <ListItem dense disableGutters>
            <TextField
              fullWidth
              multiline
              rowsMax={4}
              label='Add comment'
              value={commentTxt}
              onChange={updateCommentTxt}
            />

            <ListItemSecondaryAction>
              <IconButton
                edge='end'
                onClick={() => {
                  uploadPhoto.current.trigger();
                }}>
                <Icons.AddAPhoto />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
          {commentTxt && (
            <Button color='primary' onClick={doSubmitComment}>
              Comment
            </Button>
          )}
          <ImageAttachments images={commentAtt} />
        </div>
      </CardContent>
      <UploadPhoto ref={uploadPhoto} onNewPhoto={onNewPhoto} />
    </>
  );
}

export default withStyles(styles)(
  connect(
    (state, props) => ({
      username: state.global.user.id,
    }),
    (dispatch) => ({
      showImages: (images) =>
        dispatch(uiActions.setLightbox({ images: images.map((i) => ({ src: i })), index: 0 })),
      submitComment: (comment) => dispatch(noteActions.submitComment(comment)),
      deleteNote: (noteid) => dispatch(noteActions.deleteNote(noteid)),
    })
  )(ExistingNote)
);
