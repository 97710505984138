import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Icons from '~/icons';
import * as api from '~/lib/api';
import channelConfig from '~/lib/channelConfig';
import { showSnackbar } from '~/lib/modalServices';
import Logging from '~/logging';
import { getAllChannels } from '~/redux/commonSelectors';
import { actions as globalActions } from '~/redux/slices/global';
import { actions as notificationActions } from '~/redux/slices/notifications';
import ColorPaletteSelector from '~/shared/ColorPaletteSelector';

import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';

import * as selectors from './selectors';

const RASTER_FORMATS = [
  ['TIF', 'GeoTIFF'],
  ['PNG', 'PNG'],
  ['JPG', 'JPEG'],
];
const VECTOR_FORMATS = [
  // TODO: ['SHP', 'ArcGIS Shapefile']
];

/* TODO: color scales
REDGREEN
VIRIDIS
INFERNO
GRAYSCALE
DEM
*/

const DOWNLOAD_FORMATS = [...RASTER_FORMATS, ...VECTOR_FORMATS];

const PALETTE_NAME_MAP = {
  // because the palettes in SV aren't named the same, of course
  normal: 'REDGREEN',
  viridis: 'VIRIDIS',
  inferno: 'INFERNO',
  grey: 'GRAYSCALE',
};

export default function DownloadDataButton() {
  // for debouncing requests
  const [isRequesting, setIsRequesting] = React.useState(false);

  const currentImageset = useSelector((state) => selectors.getCurrentImageset(state, 0));

  // We offer the user the choice to download the field, or any zones within it
  const field = useSelector(selectors.getRegion);
  const zones = useSelector(selectors.zonesForField);
  const regions = currentImageset?.isTargeted ? [currentImageset?.region] : [field, ...zones];

  const trid = currentImageset?.target_results_id;

  // The default selected channel is just whatever is visible in the main panel.
  const defaultChannel = useSelector(getAllChannels)[0];
  const defaultPalette = useSelector((state) => state.global.preferences.dataColors || 'normal');
  const defaultFormat = useSelector(
    (state) => state.global.preferences.downloadFormat || DOWNLOAD_FORMATS[0][0]
  );

  // State that holds the request we're building
  const [downloadFormat, setDownloadFormat] = React.useState(defaultFormat);
  const [regionId, setRegionId] = React.useState(regions[0].id);
  const [channel, setChannel] = React.useState(defaultChannel);
  const [palette, setPalette] = React.useState(defaultPalette);

  const dispatch = useDispatch();
  const updateNotifs = () => dispatch(notificationActions.update());
  const setPreference = (key, value) => dispatch(globalActions.setPreference({ [key]: value }));

  // Controling if the download dialog is open
  const [isDialogOpen, setDialogOpen] = React.useState(false);
  const showDialog = () => {
    // reset to defaults
    setDownloadFormat(defaultFormat);
    setRegionId(regions[0].id);
    setChannel(defaultChannel);
    setPalette(defaultPalette);
    setDialogOpen(true);
  };
  const closeDialog = () => setDialogOpen(false);

  // Sends the request to the server
  const requestDownload = async () => {
    const request = {
      request_type: 'DATA_DOWNLOAD',
      target_result_id: trid,
      region_id: regionId,
      data_download_request: {
        output_format: downloadFormat,
        channel: channel,
        color_palette: PALETTE_NAME_MAP[palette],
        scale: 1,
        window: [0, 1],
        analysis_overlay: null,
        analysis_options_json: null,
      },
    };

    setIsRequesting(true);
    try {
      await api.webrequest('POST', 'sv/downloads', request);

      // Trigger notification refresh to get the persistent notif
      updateNotifs();

      // display the download status dialog
      showSnackbar('info', 'Collecting data for download.');
      closeDialog();
    } catch (error) {
      Logging.error(`Failed to request data download`, error);
      showSnackbar('error', 'Failed to request download. Try again later.');
    }

    setIsRequesting(false);
  };

  // Populate requestError if the request, as set, is somehow invalid.
  let requestError = null;
  if (channel == 'RAW' && downloadFormat != 'TIF') {
    requestError = 'Can only download raw data as GeoTIFF.';
  }

  return (
    <>
      <Tooltip title='Download Data'>
        <IconButton color='inherit' onClick={showDialog}>
          <Icons.Download />
        </IconButton>
      </Tooltip>

      <Dialog open={isDialogOpen} onClose={closeDialog} maxWidth='sm' fullWidth>
        <DialogTitle>Download Data</DialogTitle>

        <DialogContent>
          <TextField
            label='Region'
            margin='dense'
            fullWidth
            select
            disabled={regions.length == 1 || isRequesting}
            value={regionId}
            onChange={(evt) => setRegionId(evt.target.value)}>
            {regions.map((r) => (
              <MenuItem key={r.id} value={r.id}>
                {r.display_name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            label='Download Format'
            margin='dense'
            fullWidth
            select
            disabled={isRequesting}
            value={downloadFormat}
            onChange={(evt) => {
              setDownloadFormat(evt.target.value);
              setPreference('downloadFormat', evt.target.value);
            }}>
            {DOWNLOAD_FORMATS.map((format) => (
              <MenuItem key={format[0]} value={format[0]}>
                {format[1]}
              </MenuItem>
            ))}
          </TextField>

          {RASTER_FORMATS.find((f) => f[0] == downloadFormat) ? (
            <>
              <TextField
                label='Imagery channel'
                margin='dense'
                fullWidth
                select
                disabled={isRequesting}
                value={channel}
                onChange={(evt) => setChannel(evt.target.value)}>
                {Object.keys(channelConfig).map((channelid) => (
                  <MenuItem key={channelid} value={channelid}>
                    {channelConfig[channelid].name}
                  </MenuItem>
                ))}
                {downloadFormat == 'TIF' && (
                  <MenuItem key='RAW' value='RAW'>
                    All Bands
                  </MenuItem>
                )}
              </TextField>

              <Collapse in={channelConfig[channel]?.usesFalseColorPalette}>
                <ColorPaletteSelector
                  value={palette}
                  onChange={(x) => setPalette(x)}
                  variant='filled'
                  label='False Color Palette'
                />
              </Collapse>
            </>
          ) : (
            <>{/*TODO: Vector format controls */}</>
          )}

          {requestError ? (
            <Typography variant='caption' color='error'>
              {requestError}
            </Typography>
          ) : (
            <>
              <Typography variant='caption' component='p'>
                The selected full-resolution data will be prepared for download. This may take a few
                minutes.
              </Typography>
              <Typography variant='caption' component='p'>
                You will receive a notification when your data is ready.
              </Typography>
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button
            variant='contained'
            color='primary'
            onClick={requestDownload}
            disabled={requestError != null || isRequesting}>
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
