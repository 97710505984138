import React from 'react';

function resizeImage(dataURL, fileType, maxWidth, maxHeight, onDone) {
  var image = new Image();
  image.src = dataURL;

  image.onload = function () {
    var width = image.width;
    var height = image.height;
    var shouldResize = width > maxWidth || height > maxHeight;

    if (!shouldResize) {
      onDone(dataURL);
      return;
    }

    var newWidth;
    var newHeight;

    if (width > height) {
      newHeight = height * (maxWidth / width);
      newWidth = maxWidth;
    } else {
      newWidth = width * (maxHeight / height);
      newHeight = maxHeight;
    }

    var canvas = document.createElement('canvas');

    canvas.width = newWidth;
    canvas.height = newHeight;

    var context = canvas.getContext('2d');

    context.drawImage(this, 0, 0, newWidth, newHeight);

    dataURL = canvas.toDataURL(fileType);

    onDone(dataURL);
  };

  image.onerror = function () {
    alert('There was an error processing your file!');
  };
}

// This is a class so we can get its ref
class UploadPhoto extends React.Component {
  ref = React.createRef();

  imageAdded = (e) => {
    const { onNewPhoto } = this.props;

    const file = e.target.files[0];

    var reader = new FileReader();

    reader.onloadend = function () {
      resizeImage(reader.result, 'image/jpeg', 1280, 720, (resized) => {
        onNewPhoto(resized);
      });
    };

    reader.onerror = function () {
      alert('There was an error reading the file');
    };

    reader.readAsDataURL(file);
  };

  trigger = () => {
    this.ref.current.click();
  };

  render() {
    return (
      <input
        type='file'
        accept='image/*'
        ref={this.ref}
        onChange={this.imageAdded}
        style={{ display: 'none' }}
      />
    );
  }
}

export default UploadPhoto;
