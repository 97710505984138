import React from 'react';
import * as globalSelctors from '~/redux/selectors/global';

import ProcessorTable from './ProcessorTable';

export const matches = (state) => globalSelctors.paramsForPath(state, '/processor');

export const title = (state) => 'Preprocessor Status';

export const breadcrumbs = () => ['Preprocessor Status'];

export const component = () => {
  return <ProcessorTable />;
};

export const helpTopic = () => 'Preprocessor Status';
