import React from 'react';
import { createSelector } from 'reselect';
import * as wkt from 'wellknown';
import { bboxOfWKT } from '~/lib/geography';
import history from '~/lib/history';
import * as Markers from '~/map/markers';
import * as globalSelctors from '~/redux/selectors/global';
import store from '~/store';
import { regionColors } from '~/theme';

import { Link, Typography } from '@material-ui/core';

import FarmEditPanel from './FarmEditPanel';
import FarmManagementPanel from './FarmManagementPanel';
import SmartSafeSpotMarker from './SmartSafeSpotMarker';
import { getTmpFarm, selectedFarm, selectedFarmId } from './selectors';
import slice, { actions } from './slice';
import OrgSiteLink from '~/shared/Appbar/OrgSiteLink';

export { slice };

const FarmSelector = ({ farm }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  return (
    <>
      {farm?.id ? (
        <>
          <Typography component='span' variant='h6'>
            Edit Site:&nbsp;
          </Typography>
          <OrgSiteLink />
        </>
      ) : (
        <Typography variant='h6'>Edit Farms</Typography>
      )}
    </>
  );
};

export const matches = (state) => globalSelctors.paramsForPath(state, '/farms');

export const title = (state) => 'Farm Management';

export const breadcrumbs = createSelector(selectedFarm, (farm) => [
  <FarmSelector key='farm' farm={farm} />,
]);

export const component = (state) => (selectedFarmId(state) ? null : <FarmManagementPanel />);
export const panel = (state) => (selectedFarmId(state) ? <FarmEditPanel /> : null);

// Mapbox style data
export const mapStyle = createSelector(
  selectedFarmId,
  getTmpFarm,
  (state) => state.farmsScene.editingKeepin,
  (farmid, tmpFarm, isEditingKeepin, details) => {
    const keepinRegions =
      tmpFarm?.keepin && !isEditingKeepin
        ? [
            {
              id: `${farmid}-keepin`,
              boundary: tmpFarm.keepin,
              display_name: 'KEEP-IN',
              color: regionColors['FARM'],
              outline: true,
              fill: true,
              name: true,
            },
          ]
        : [];

    const fieldRegions =
      tmpFarm?.fields?.map((f) => ({
        id: `${f.id}-field-for-farms`,
        boundary: f.boundary,
        display_name: f.display_name,
        color: regionColors['FIELD'],
        outline: true,
        fill: true,
        name: true,
      })) || [];

    return {
      imagery: [],
      regions: [...keepinRegions, ...fieldRegions],
    };
  }
);

export const defaultBounds = createSelector(selectedFarm, (farm) => {
  if (!farm || !farm.keepin || farm.keepin == '') return null;
  return bboxOfWKT(farm.keepin);
});
export const editingUsershape = createSelector(
  selectedFarmId,
  (state) => state.farmsScene.editingKeepin,
  (farmid, isEditingKeepin) =>
    farmid !== null && isEditingKeepin
      ? { color: regionColors.FARM, allowed: ['polygon', 'rectangle', 'circle'] }
      : null
);

export const markers = createSelector(getTmpFarm, (farm) => {
  var rval = [];

  const slmarkers = farm?.safelandlocations
    ?.filter((s) => !s.deleted)
    .map((s, i) => (
      <SmartSafeSpotMarker key={i} tmpFarm={farm} ssidx={farm.safelandlocations.indexOf(s)} />
    ));

  if (slmarkers) {
    rval.push(...slmarkers);
  }
  if (farm.planned_base_location) {
    const coord = wkt.parse(farm.planned_base_location).coordinates;

    rval.push(
      <Markers.Base
        name={'PLANNED'}
        coordinate={coord}
        pending={true}
        draggable={true}
        onDrag={(newcoord) => {
          store.dispatch(
            actions.setTmpFarm({
              id: farm.id,
              farm: { ...farm, planned_base_location: `POINT(${newcoord[0]} ${newcoord[1]})` },
            })
          );
        }}
      />
    );
  }
  return rval;
});

export const helpTopic = () => 'Manage Farms';
