import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Icons from '~/icons';
import history from '~/lib/history';
import { showConfirmDialog } from '~/lib/modalServices';

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  ListItem,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ChangeSeatAllowanceDialog from './ChangeSeatAllowanceDialog';
import EditGroupDialog from './EditGroupDialog';
import InviteDialog from './InviteDialog';
import NewGroupDialog from './NewGroupDialog';
import * as selectors from './selectors';
import { actions } from './slice';

const styles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    background: theme.palette.background.default,
    maxHeight: '100%',
    padding: theme.spacing(1),
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}));

export default function OrgPage() {
  const classes = styles();
  const dispatch = useDispatch();

  const [showChangeSeatDialog, setShowChangeSeatDialog] = React.useState(false);
  const [showInviteDialog, setShowInviteDialog] = React.useState(false);
  const [showNewGroupDialog, setShowNewGroupDialog] = React.useState(false);
  const [editGroup, setEditGroup] = React.useState(null);

  const orgData = useSelector(selectors.orgData);

  if (orgData.loading) {
    return <div className={classes.root}>Loading</div>;
  }

  const ActionButton = ({ title, icon, onClick, disabled }) => {
    const Icon = Icons[icon];
    return (
      <Tooltip title={title}>
        <IconButton size='small' onClick={onClick} disabled={disabled}>
          <Icon />
        </IconButton>
      </Tooltip>
    );
  };

  const editSite = (sid) => {
    // TODO: redirect to the manage farm page?
  };

  function updateOrgIndustry(evt) {
    // make a web call to set the industry of the current org
    dispatch(actions.setOrgIndustry(orgData.id, evt.target.value));
  }

  const freeAdminSeats =
    orgData.total_admin_seats - (orgData.seats?.filter((s) => s.type == 'ADMIN').length || 0);
  const freeUserSeats =
    orgData.total_user_seats - (orgData.seats?.filter((s) => s.type == 'USER').length || 0);

  const rootGroups = orgData.groups
    ? Object.values(orgData.groups).filter((g) => g.parent_id == null)
    : null;

  const rowsForGroup = (g, prefix = '') => (
    <React.Fragment key={g.id}>
      <TableRow>
        <TableCell align='left'>
          {prefix}
          <strong>{g.name}</strong>
        </TableCell>
        <TableCell align='right'>{Object.keys(g.members || {}).length || 0} Members</TableCell>
        <TableCell align='right'>{Object.keys(g.sites || {}).length || 0} Sites</TableCell>
        {g.permissions.includes('UPDATE_GROUP') && (
          <TableCell align='right'>
            <ActionButton icon='Edit' title='Modify Group' onClick={() => setEditGroup(g.id)} />
          </TableCell>
        )}
      </TableRow>
      {Object.values(orgData.groups)
        .filter((sg) => sg.parent_id == g.id)
        .map((sg) => rowsForGroup(sg, prefix + g.name + ' / '))}
    </React.Fragment>
  );

  const RowForSeat = ({ seat }) => {
    return (
      <TableRow>
        <TableCell align='left'>{seat.user_id}</TableCell>
        <TableCell align='right'>{seat.type}</TableCell>
        {orgData.permissions.includes('REMOVE_ORG_MEMBERS') && (
          <TableCell align='right'>
            <ActionButton
              icon='Close'
              title='Remove Member'
              onClick={async () => {
                if (
                  await showConfirmDialog(
                    'Remove Member',
                    `Are you sure you want to remove "${seat.user_id}" from your organization? They will no longer have access to any of your data or maps, and a seat will be freed.`,
                    'Remove'
                  )
                ) {
                  dispatch(actions.removeUser(seat.user_id, orgData.id));
                }
              }}
            />
          </TableCell>
        )}
      </TableRow>
    );
  };
  const RowForInvite = ({ invite }) => {
    return (
      <TableRow>
        <TableCell align='left'>{invite.email}</TableCell>
        <TableCell align='right'>Invite ({invite.state})</TableCell>
        {orgData.permissions.includes('REMOVE_ORG_MEMBERS') && (
          <TableCell align='right'>
            <ActionButton
              icon='Close'
              title='Revoke Invite'
              onClick={async () => {
                if (
                  await showConfirmDialog(
                    'Revoke Invitation',
                    'Are you sure you want to revoke this invitation? The recipient will still have the invitation email, but be unable to accept it.',
                    'Revoke'
                  )
                ) {
                  dispatch(actions.revokeInvite(invite.id, orgData.id));
                }
              }}
            />
          </TableCell>
        )}
      </TableRow>
    );
  };

  return (
    <div className={classes.root}>
      <Grid container direction='row' spacing={2}>
        <Grid item md container direction='column' spacing={2}>
          <Grid item>
            <Card>
              <CardContent>
                <Typography variant='h5'>Members</Typography>
                <Table size='small'>
                  <TableBody>
                    {orgData.seats?.map((s) => <RowForSeat key={s.user_id} seat={s} />) || (
                      <TableRow>
                        <Typography color='error'>No members</Typography>
                      </TableRow>
                    )}

                    {orgData.invites?.map((i) => (
                      <RowForInvite key={i.id} invite={i} />
                    ))}
                  </TableBody>
                </Table>
                <ListItem>
                  <Typography variant='caption'>
                    This organization has <strong>{freeAdminSeats}</strong> free Admin seats, and{' '}
                    <strong>{freeUserSeats}</strong> free user seats.
                  </Typography>
                </ListItem>
              </CardContent>

              <CardActions>
                {orgData.permissions.includes('SEND_INVITE') && (
                  <Button
                    color='primary'
                    size='small'
                    disabled={freeAdminSeats + freeUserSeats == 0}
                    onClick={() => setShowInviteDialog(true)}>
                    Invite New Members
                  </Button>
                )}
              </CardActions>
            </Card>
          </Grid>
        </Grid>
        <Grid item md container direction='column' spacing={2}>
          <Grid item>
            <Card>
              <CardContent>
                <Typography variant='h5'>Groups</Typography>

                <Table size='small'>
                  <TableBody>
                    {rootGroups?.map((g) => rowsForGroup(g)) || (
                      <TableRow>
                        <Typography color='error'>No groups</Typography>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </CardContent>
              <CardActions>
                <Button color='primary' size='small' onClick={() => setShowNewGroupDialog(true)}>
                  Create Group
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item>
            <Card>
              <CardContent>
                <Typography variant='h5'>Sites</Typography>

                <Table size='small'>
                  <TableBody>
                    {orgData.sites?.map((s) => (
                      <TableRow key={s}>
                        <TableCell align='left'>{s}</TableCell>
                        <TableCell align='right'>
                          <ActionButton icon='Edit' title='Manage Site' />
                        </TableCell>
                      </TableRow>
                    )) || (
                      <TableRow>
                        <Typography color='error'>No sites</Typography>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>

          {orgData.permissions.includes('UPDATE_SEAT_ALLOWANCE') && (
            <Grid item>
              <Card>
                <CardContent>
                  <Typography variant='h5'>Admin Settings</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        select
                        fullWidth
                        label='Industry Branding'
                        value={orgData.industry || 'Generic'}
                        onChange={updateOrgIndustry}>
                        <MenuItem value='Generic'>
                          <b>Generic</b>
                        </MenuItem>

                        <MenuItem value='Agriculture'>Agriculture</MenuItem>
                        <MenuItem value='Industrial'>Industrial</MenuItem>
                        <MenuItem value='Solar'>Solar</MenuItem>
                        <MenuItem value='Defense'>Defense</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        color='primary'
                        variant='outlined'
                        size='small'
                        onClick={() => setShowChangeSeatDialog(true)}>
                        Change Seat Allowance
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </Grid>

      {orgData.groups &&
        Object.values(orgData.groups).map((g) => (
          <EditGroupDialog
            key={g.id}
            open={editGroup == g.id}
            onClose={() => setEditGroup(null)}
            group={g}
          />
        ))}

      <ChangeSeatAllowanceDialog
        open={showChangeSeatDialog}
        onClose={() => setShowChangeSeatDialog(false)}
        orgid={orgData.id}
      />
      <InviteDialog
        open={showInviteDialog}
        onClose={() => setShowInviteDialog(false)}
        orgid={orgData.id}
      />
      <NewGroupDialog
        open={showNewGroupDialog}
        onClose={() => setShowNewGroupDialog(false)}
        orgid={orgData.id}
      />
    </div>
  );
}
