import React from 'react';
import { connect } from 'react-redux';
import * as Icons from '~/icons';
import history from '~/lib/history';
import FancyTableStatic from '~/shared/FancyTableStatic';

import { Button, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { focussedFarm, surveysForFarm } from './selectors';
import { actions } from './slice';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
    maxWidth: '100%',
    flexDirection: 'column',
    alignItems: 'stretch',
    background: theme.palette.background.default,
    maxHeight: '100%',
    padding: theme.spacing(1),
  },

  actionBar: {
    display: 'flex',
    padding: [[8, 16]],
    marginBottom: theme.spacing(1),
    justifyContent: 'space-between',
  },
  tableArea: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 1,
  },
});

const sortNumber = (a, b) => {
  if (isFinite(a - b)) {
    return a - b;
  } else {
    return isFinite(a) ? -1 : 1;
  }
};
const sortBool = (a, b) => {
  return sortNumber(a ? 1 : 0, b ? 1 : 0);
};
const sortString = (a, b) => a.localeCompare(b, undefined, { numeric: true });

class SurveysPage extends React.Component {
  clickedSurveyHandler = (survey) => {
    const { focussedFarm } = this.props;
    history.push(`/surveys?site=${focussedFarm.id}&surveyid=${survey.id}`);
  };

  render() {
    const { classes, surveys, focussedFarm } = this.props;

    const uniq = (objs, key) => [...new Set(objs.map((x) => x[key]))];

    const formattedSurveys = surveys.map((s) => ({
      ...s,
      enabled: s.enabled ? 'Enabled' : 'Disabled',
      high_priority: s.high_priority ? 'High' : 'Normal',
      needs_approval: s.needs_approval ? 'Yes' : 'No',
    }));

    const columns = [
      {
        id: 'id',
        type: 'STR',
        sortable: true,
        filterable: false,
        title: 'ID',
      },
      {
        id: 'display_name',
        type: 'STR',
        sortable: true,
        filterable: false,
        title: 'Name',
      },
      {
        id: 'type',
        type: 'STR',
        sortable: true,
        filterable: true,
        filter_opts_str: uniq(formattedSurveys, 'type'),
        title: 'Type',
      },
      {
        id: 'enabled',
        type: 'STR',
        sortable: true,
        filterable: true,
        filter_opts_str: uniq(formattedSurveys, 'enabled'),
        title: 'Enabled',
      },
      {
        id: 'high_priority',
        type: 'STR',
        sortable: true,
        filterable: true,
        filter_opts_str: uniq(formattedSurveys, 'high_priority'),
        title: 'High Priority',
      },
      {
        id: 'needs_approval',
        type: 'STR',
        sortable: true,
        filterable: true,
        filter_opts_str: uniq(formattedSurveys, 'needs_approval'),
        title: 'Needs Approval',
      },
      {
        id: 'author_id',
        type: 'STR',
        sortable: true,
        filterable: true,
        filter_opts_str: uniq(formattedSurveys, 'author_id'),
        title: 'Author',
      },
    ];

    return (
      <div className={classes.root}>
        <Paper className={classes.actionBar}>
          <div style={{ flexGrow: 1 }} />
          <Button
            color='primary'
            disabled={!focussedFarm}
            onClick={() => history.push(`/surveys?surveyid=0&site=${focussedFarm.id}`)}>
            Create Survey
          </Button>
        </Paper>
        <Paper className={classes.tableArea}>
          <FancyTableStatic
            data={formattedSurveys}
            columns={columns}
            onClick={this.clickedSurveyHandler}
          />
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  focussedFarm: focussedFarm(state),
  surveys: surveysForFarm(state),
});

const mapDispatchToProps = (dispatch) => ({
  editNewSurvey: (farmid) => dispatch(actions.editNewSurvey(farmid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SurveysPage));
