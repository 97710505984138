import React from 'react';
import { connect, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import * as Icons from '~/icons';
import history from '~/lib/history';
import FancyTableStatic from '~/shared/FancyTableStatic';

import {
  Avatar,
  Button,
  Chip,
  Dialog,
  Fade,
  List,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Paper,
  Slide,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import NewOrgDialog from './NewOrgDialog';
import { actions } from './slice';

const styles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    background: theme.palette.background.default,
    maxHeight: '100%',
    padding: theme.spacing(1),
  },

  actionBar: {
    display: 'flex',
    padding: [[8, 16]],
    marginBottom: theme.spacing(1),
    justifyContent: 'space-between',
  },
  tableArea: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 1,
  },
}));

function clickedRowHandler(org) {
  history.push(`/org/${org.id}`);
}

export default function OrgListPage() {
  const classes = styles();

  const rows = useSelector((state) => state.orglistScene.rows) || [];
  const columns = useSelector((state) => state.orglistScene.columns) || [];

  const canCreateOrg = useSelector((state) =>
    state.global.system_permissions.includes('POST_ORGS')
  );

  const [showNewOrgDialog, setShowNewOrgDialog] = React.useState(false);

  return (
    <div className={classes.root}>
      {canCreateOrg && (
        <>
          <Paper className={classes.actionBar}>
            <div style={{ flexGrow: 1 }} />
            <Button
              color='primary'
              onClick={() => {
                setShowNewOrgDialog(true);
              }}>
              Create Org
            </Button>
            <NewOrgDialog open={showNewOrgDialog} onClose={() => setShowNewOrgDialog(false)} />
          </Paper>
        </>
      )}
      <Paper className={classes.tableArea}>
        <FancyTableStatic
          data={rows}
          columns={columns}
          id={(x) => x.id}
          onClick={clickedRowHandler}
        />
      </Paper>
    </div>
  );
}
