import * as React from 'react';
import { useSVDispatch, useSVSelector } from '~/redux/hooks';
import { Preferences, actions as globalActions } from '~/redux/slices/global';

import { ButtonGroup } from '@material-ui/core';

import ToggleButtonWithTip from './ToggleButtonWithTip';

export default function TempUnitSwitch() {
  const dispatch = useSVDispatch();
  const setPreference = React.useCallback(
    (pref: Partial<Preferences>) => dispatch(globalActions.setPreference(pref)),
    [dispatch]
  );

  const isC = useSVSelector((state) => state.global.preferences.tempUnit == 'C');
  const setC = React.useCallback(() => {
    setPreference({ tempUnit: 'C' });
  }, [setPreference]);
  const setF = React.useCallback(() => {
    setPreference({ tempUnit: 'F' });
  }, [setPreference]);

  return (
    <ButtonGroup
      style={{ height: 24, opacity: 0.5 }}
      disableElevation
      color='secondary'
      orientation='horizontal'
      size='small'
      variant='text'>
      <ToggleButtonWithTip tooltip='Celcius' toggled={isC} onClick={setC}>
        °C
      </ToggleButtonWithTip>
      <ToggleButtonWithTip tooltip='Farenheit' toggled={!isC} onClick={setF}>
        °F
      </ToggleButtonWithTip>
    </ButtonGroup>
  );
}
