import classnames from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import * as Icons from '~/icons';
import { actions as uiactions } from '~/redux/slices/ui';

import { Badge, IconButton, Paper, Popover, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

import MapContext from '../mapContext';
import SimpleMarker from './SimpleMarker';

const styles = makeStyles((theme) => ({
  symbol: {
    borderRadius: '50%',
    borderColor: fade(theme.palette.primary.main, 0.5),
    borderStyle: 'solid',
    borderWidth: 4,
    overflow: 'hidden',
    background: 'transparent',
    width: 36,
    height: 36,
    position: 'relative',
    cursor: 'zoom-in',
  },
  innerCircle: {
    background: fade(theme.palette.background.paper, 0.8),
    color: theme.palette.text.primary,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'none',
  },
  thumbnail: {
    borderRadius: '50%',
    borderColor: fade(theme.palette.primary.main, 0.5),
    borderStyle: 'solid',
    borderWidth: 4,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: ' translate(-50%, -50%) rotate(var(--heading)) scale(0,0) ',
    opacity: 0,
    pointerEvents: 'none',
    transition: 'opacity 0.3s, transform 0.3s',
    '&.hovered': {
      transform: 'translate(-50%, -50%) rotate(var(--heading)) scale(1,1)',
      opacity: 1,
    },
  },
}));

export default function SampleMarker({ allImages, image, ...other }) {
  const [hovered, setHovered] = React.useState(false);

  const index = image.sampled_image_idx;

  const classes = styles();
  const dispatch = useDispatch();

  const sampleurl = (i) =>
    `/api/sv/sampledimage/${i.target_results_id}/${i.region_id}/${image.type}/${i.sampled_image_idx}`;

  const handleClick = () => {
    dispatch(
      uiactions.setLightbox({
        images: allImages.map((x) => ({
          src: sampleurl(x),
          mppx: x.sampled_image_data.mppx,
          type: x.sampled_image_data.type || 'IMAGE',
        })),
        index: index,
      })
    );
  };

  const location = [image.sampled_image_data.lon, image.sampled_image_data.lat];

  const thumbUrl = sampleurl(image) + '/thumb';

  return (
    <>
      <MapContext.Consumer>
        {(map) => (
          <SimpleMarker coordinate={location} map={map} {...other}>
            <Paper
              className={classes.symbol}
              onMouseOver={() => {
                setHovered(true);
              }}
              onMouseOut={() => {
                setHovered(false);
              }}
              onClick={handleClick}>
              <div className={classes.innerCircle}>
                <Typography variant='button'>{index + 1}</Typography>
              </div>
            </Paper>
            <img
              src={thumbUrl}
              className={classnames(classes.thumbnail, { hovered })}
              style={{ '--heading': `${image.sampled_image_data.up_heading}deg` }}
            />
          </SimpleMarker>
        )}
      </MapContext.Consumer>
    </>
  );
}
