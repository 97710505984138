import produce from 'immer';
import React, { useState, useEffect } from 'react';
import * as wkt from 'wellknown';
import * as geography from '~/lib/geography';
import history from '~/lib/history';
import { showConfirmDialog } from '~/lib/modalServices';
import { actions as usershapeActions } from '~/redux/slices/usershape';
import EditGridDivisions from '~/shared/EditGridDivisions';
import RightPanel from '~/shared/RightPanel';
import { areaUnitText } from '~/lib/units';
import { useSVDispatch, useSVSelector } from '~/redux/hooks';
import { showSnackbar } from '~/lib/modalServices';

import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Slider,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { getQueryOrgAndSite, getQueryParams } from '~/redux/selectors/global';
import { isDirty } from './utils';
import { Asset } from '~/schema';

import { getTmpAssetWithUsershape } from './selectors';
import { actions, fetchAssetById } from './slice';
import useFormBuffer from '~/shared/formBuffer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuIcon: {
      marginRight: 8,
      marginLeft: 8,
    },
    slider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    stack: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      width: '100%',
      margin: 0,
      paddingBottom: 4,
    },
    spacer: {
      flexShrink: 0,
      width: 16,
    },
  })
);

const EditAssetsPanel = () => {
  const dispatch = useSVDispatch();
  const classes = useStyles();

  const [simplificationTol, setSimplificationTol] = useState(1);
  const [assetHeightEntry, setAssetHeightEntry] = useState('0');
  const [assetNameEntry, setAssetNameEntry] = useState('');
  const [assetTypeEntry, setAssetTypeEntry] = useState('');
  const queryParams = useSVSelector((state) => getQueryParams(state));
  const assetOpenForEditingId =
    queryParams?.get('assetid') === 'new' ? 0 : Number(queryParams?.get('assetid'));
  const areaUnit = useSVSelector((state) => state.global.preferences.areaUnit);
  const { siteId } = useSVSelector((state) => getQueryOrgAndSite(state));
  const siteAssets = Object.values(useSVSelector((state) => state.assetsScene.assets));
  const userShape = useSVSelector((state) => state.usershape);
  const tmpAssetWithUserShape = useSVSelector((state) => getTmpAssetWithUsershape(state));
  const tmpAsset = useSVSelector((state) => state.assetsScene.tmpAsset);
  const assetUploadResult = useSVSelector((state) => state.assetsScene.assetUploadResult);
  const assetUploadPending = useSVSelector((state) => state.assetsScene.assetUploadPending);
  const isUserShapeDragging = useSVSelector((state) => state.usershape.isDragging);
  const isUserShapeAdding = useSVSelector((state) => state.usershape.isAdding);

  const updateTmp = (updateFunc: any) => {
    const x = produce(tmpAsset, (draft) => {
      updateFunc(draft);
    });

    dispatch(actions.setTmpAsset({ id: assetOpenForEditingId, asset: x }));
  };

  useEffect(() => {
    if (typeof assetOpenForEditingId === 'undefined') {
      console.error('Edit asset screen rendered without assetid in url.');
      history.push('/');
      return;
    }

    if (
      !Object.keys(siteAssets)
        .map((k) => Number(k))
        .includes(assetOpenForEditingId) &&
      assetOpenForEditingId !== 0
    ) {
      dispatch(fetchAssetById(assetOpenForEditingId));
    }

    if (tmpAsset?.shape) {
      dispatch(usershapeActions.setUsershapeFromWKT({ wktstr: tmpAsset.shape }));
    } else {
      dispatch(usershapeActions.resetShape());
    }

    return () => {
      // Unmount side effects
      dispatch(usershapeActions.resetShape());
      if (siteId) {
        dispatch(actions.fetchAssetsBySiteId(siteId));
      }
    };
  }, []);

  useEffect(() => {
    const tmpAssetHeight = getAssetHeight(tmpAsset as Asset);
    if (tmpAsset) {
      if (tmpAssetHeight) {
        setAssetHeightEntry(tmpAssetHeight?.toString() || '0');
      }

      setAssetNameEntry(tmpAsset?.display_name || '');
      setAssetTypeEntry(tmpAsset?.type || '');
    }
  }, [tmpAsset]);

  useEffect(() => {
    if (!tmpAsset) {
      dispatch(
        actions.setTmpAsset({
          id: assetOpenForEditingId,
          asset: tmpAssetWithUserShape || undefined,
        })
      );
    } else {
      if (!isUserShapeAdding && !isUserShapeDragging && tmpAssetWithUserShape?.shape) {
        updateTmp(
          (a: Asset) =>
            (a.shape = getShapeWithHeight(assetHeightEntry, tmpAssetWithUserShape.shape as string))
        );
      }
    }
  }, [tmpAssetWithUserShape?.shape, isUserShapeAdding, isUserShapeDragging, assetHeightEntry]);

  const handleUpdateAsset = () => {
    if (tmpAsset) {
      updateTmp((a: Asset) => {
        a.display_name = assetNameEntry;
        a.type = assetTypeEntry;
      });
    }
  };

  const assetParent = useFormBuffer(tmpAsset?.parent_asset_id, (parentIdAsString) => {
    updateTmp((a: Asset) => {
      a.parent_asset_id = Number(parentIdAsString);
    });
  });

  const assetIsDeleted = useFormBuffer(tmpAsset?.deleted, (isDeleted) => {
    updateTmp((a: Asset) => {
      a.deleted = !!isDeleted;
    });
  });

  useEffect(() => {
    if (typeof assetUploadResult === 'object' && assetUploadResult.display_name) {
      // handle asset upload success
      if (assetUploadResult?.deleted) {
        showSnackbar('success', `Asset ${assetUploadResult.display_name} deleted`);
      } else {
        showSnackbar('success', `Asset ${assetUploadResult.display_name} saved`);
      }
      dispatch(actions.setTmpAsset({ id: assetOpenForEditingId, asset: undefined }));
      history.push(`/assets?site=${siteId}`);
    }

    if (typeof assetUploadResult === 'string') {
      // handle asset upload failure
      showSnackbar('error', `Unable to create asset: ${assetUploadResult}`);
    }

    dispatch(actions.setAssetUploadResult(undefined));
  }, [assetUploadResult]);

  const canPolygonize =
    userShape?.type && ['rectangle', 'circle'].includes(userShape.type) && !userShape?.isAdding;
  const canSimplify = userShape?.type == 'polygon' && !userShape?.isAdding;
  const originalAsset = siteAssets.find((a) => a.id === tmpAsset?.id);

  const handleSubmit = () => {
    if (tmpAsset) {
      // If asset is being deleted, delete all child assets (this behavior may evolve)
      if (tmpAsset.deleted) {
        siteAssets
          .filter((a) => a?.parent_asset_id == assetOpenForEditingId)
          .forEach((a) => {
            dispatch(actions.uploadAsset({ ...a, deleted: true }));
          });
      }

      dispatch(actions.uploadAsset(tmpAsset));
    }
  };

  const handleCancel = async () => {
    if (isDirty(originalAsset, tmpAsset)) {
      const confirm = await showConfirmDialog(
        'Discard changes',
        'Are you sure you want to close the page without saving changes?',
        'Discard'
      );
      if (!confirm) return;
    }
    dispatch(actions.setTmpAsset({ id: assetOpenForEditingId, asset: undefined }));
    history.push(`/assets?site=${siteId}`);
  };

  const getShapeWithHeight = (hAsString: string, shape: string) => {
    const h = Number(hAsString);
    if (!shape) {
      return '';
    }

    const shapeGeo = wkt.parse(shape);
    if (!shapeGeo) return;

    if (shapeGeo.type === 'Point') {
      const shapeCoords = (shapeGeo as wkt.GeoJSONPoint).coordinates;
      const shapeCoordsWithHeight = [
        shapeCoords[0],
        shapeCoords[1],
        Number(h),
      ] as wkt.GeoJSONPosition;
      return wkt.stringify({ ...shapeGeo, coordinates: shapeCoordsWithHeight });
    }

    if (shapeGeo.type === 'LineString') {
      const shapeCoords = (shapeGeo as wkt.GeoJSONLineString).coordinates;
      const shapeCoordsWithHeight = shapeCoords.map(
        (pos) => [pos[0], pos[1], h] as wkt.GeoJSONPosition
      );
      return wkt.stringify({ ...shapeGeo, coordinates: shapeCoordsWithHeight });
    }

    if (shapeGeo.type === 'MultiPoint') {
      const shapeCoords = (shapeGeo as wkt.GeoJSONMultiPoint).coordinates;
      const shapeCoordsWithHeight = shapeCoords.map(
        (pos) => [pos[0], pos[1], h] as wkt.GeoJSONPosition
      );
      return wkt.stringify({ ...shapeGeo, coordinates: shapeCoordsWithHeight });
    }

    if (shapeGeo.type === 'Polygon') {
      const shapeCoords = (shapeGeo as wkt.GeoJSONPolygon).coordinates;
      const shapeCoordsWithHeight = shapeCoords.map((posArray) =>
        posArray.map((pos) => [pos[0], pos[1], h] as wkt.GeoJSONPosition)
      );
      return wkt.stringify({ ...shapeGeo, coordinates: shapeCoordsWithHeight });
    }

    if (shapeGeo.type === 'MultiPolygon') {
      const shapeCoords = (shapeGeo as wkt.GeoJSONMultiPolygon).coordinates;
      const shapeCoordsWithHeight = shapeCoords.map((posArray2D) =>
        posArray2D.map((posArray) =>
          posArray.map((pos) => [pos[0], pos[1], h] as wkt.GeoJSONPosition)
        )
      );
      return wkt.stringify({ ...shapeGeo, coordinates: shapeCoordsWithHeight });
    }

    console.error('getShapeWithHeight encountered invalid case');
    return '';
  };

  const getAssetHeight = (a: Asset) => {
    // All points in shape are assumed to have same height
    // Asset shape coords may only be 2D if height has not been set.
    if (a?.shape) {
      const shapeGeo = wkt.parse(a.shape);

      if (shapeGeo?.type === 'Point') {
        return shapeGeo.coordinates.length > 2 ? shapeGeo.coordinates[2] : 0;
      }

      if (shapeGeo?.type === 'MultiPoint' || shapeGeo?.type === 'LineString') {
        return shapeGeo.coordinates[0].length > 2 ? shapeGeo.coordinates[0][2] : 0;
      }

      if (shapeGeo?.type === 'Polygon') {
        return shapeGeo.coordinates[0][0].length > 2 ? shapeGeo.coordinates[0][0][2] : 0;
      }

      if (shapeGeo?.type === 'MultiPolygon') {
        return shapeGeo.coordinates[0][0][0].length > 2 ? shapeGeo.coordinates[0][0][0][2] : 0;
      }
    }

    return 0;
  };

  const ParentSelector = () => {
    const getItems = () => [
      <MenuItem key='no-parent-menu-item' value={undefined}>
        No Parent
      </MenuItem>,
      ...siteAssets
        .filter((a) => a.id != Number(assetOpenForEditingId))
        .map((a) => (
          <MenuItem key={`${a.display_name}-menu-item`} value={a.id}>
            {a.display_name}
          </MenuItem>
        )),
    ];

    return (
      <Select
        displayEmpty
        onChange={(e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) =>
          assetParent.set(Number(e.target.value))
        }
        renderValue={(v) => {
          return siteAssets.find((a) => v == a.id)?.display_name || 'No Parent';
        }}
        value={assetParent.value}
        fullWidth>
        {getItems()}
      </Select>
    );
  };

  const areaOfTmpAsset = tmpAsset?.shape ? geography.areaOfWKT(areaUnit, tmpAsset.shape) : 0;

  return (
    <RightPanel title='Edit Asset' back>
      <List disablePadding>
        <ListItem>
          <TextField
            fullWidth
            label='Name'
            onBlur={handleUpdateAsset}
            onChange={(evt) => setAssetNameEntry(evt.target.value)}
            value={assetNameEntry}
          />
        </ListItem>
        {userShape.type && (tmpAssetWithUserShape?.shape || tmpAsset?.shape) && (
          <ListItem>
            <ListItemText
              primary={`Area of asset: ${
                areaOfTmpAsset && areaOfTmpAsset > 0
                  ? `${areaOfTmpAsset.toFixed(2)} ${areaUnitText(areaUnit)}`
                  : 'N/A'
              }`}
            />
          </ListItem>
        )}
        <Divider />
        <ListItem>
          <TextField
            fullWidth
            label='Type'
            onBlur={handleUpdateAsset}
            onChange={(evt) => setAssetTypeEntry(evt.target.value)}
            value={assetTypeEntry}
          />
        </ListItem>
        {tmpAsset?.shape &&
          wkt.parse((tmpAsset as Asset).shape as string)?.type != 'Point' &&
          areaOfTmpAsset &&
          areaOfTmpAsset > 0 && (
            <>
              <EditGridDivisions
                region={tmpAsset}
                onChange={(newGridDiv: any) =>
                  updateTmp((a: Asset) => (a.grid_divisions = newGridDiv))
                }
              />
            </>
          )}
        <ListItem>
          <TextField
            fullWidth
            label='Asset Height'
            onChange={(evt) => setAssetHeightEntry(evt.target.value || '')}
            onBlur={() => {
              updateTmp(
                (a: Asset) =>
                  (a.shape = getShapeWithHeight(assetHeightEntry, tmpAsset?.shape as string))
              );
            }}
            value={assetHeightEntry}
          />
        </ListItem>
        <ListItem>
          <ParentSelector />
        </ListItem>
        {canPolygonize && (
          <>
            <Divider />
            <ListItem>
              <Button fullWidth onClick={() => dispatch(usershapeActions.polygonize)}>
                Shape to polygon
              </Button>
            </ListItem>
          </>
        )}
        {canSimplify && (
          <>
            <Divider />
            <ListItem>
              <div className={classes.stack}>
                <Typography variant='caption'>Simplification tolerance (m)</Typography>

                <Slider
                  defaultValue={1}
                  onChange={(_, value) => setSimplificationTol(value as number)}
                  classes={{ root: classes.slider }}
                  min={0}
                  max={Math.sqrt(10)}
                  step={0.01}
                  valueLabelFormat={(x) => x.toFixed(1)}
                  scale={(x) => x ** 2}
                  valueLabelDisplay='auto'
                />
                <Button
                  fullWidth
                  onClick={() => {
                    dispatch(usershapeActions.simplify({ tolerance: simplificationTol }));
                  }}>
                  Simplify polygon
                </Button>
              </div>
            </ListItem>
          </>
        )}
        <Divider />
        {originalAsset && (
          <ListItem>
            <FormControlLabel
              control={
                <Checkbox
                  color='primary'
                  checked={assetIsDeleted.value}
                  onChange={(evt) => assetIsDeleted.set(evt.target.checked)}
                />
              }
              label='Deleted'
            />
          </ListItem>
        )}
        <ListItem>
          <Button fullWidth variant='contained' color='secondary' onClick={handleCancel}>
            Close
          </Button>
          <div className={classes.spacer} />

          <Button
            fullWidth
            variant='contained'
            color='primary'
            disabled={
              !isDirty(originalAsset, tmpAsset) ||
              !tmpAsset?.shape ||
              userShape?.isAdding ||
              assetUploadPending
            }
            onClick={handleSubmit}>
            Save
          </Button>
        </ListItem>
        <Divider />
        {userShape.isAdding && (
          <ListItem>
            <Alert severity='info'>Continue drawing the shape.</Alert>
          </ListItem>
        )}
      </List>
    </RightPanel>
  );
};

export default EditAssetsPanel;
