import produce from 'immer';
import stringify from 'json-stable-stringify';
import React from 'react';
import { connect } from 'react-redux';
import * as wkt from 'wellknown';
import * as Icons from '~/icons';
import * as geography from '~/lib/geography';
import history from '~/lib/history';
import { showConfirmDialog } from '~/lib/modalServices';
import { term } from '~/markets';
import {
  getUsershapeAdding,
  getUsershapeEditing,
  getUsershapeValid,
} from '~/redux/slices/usershape';
import { actions as usershapeActions } from '~/redux/slices/usershape';
import EditGridDivisions from '~/shared/EditGridDivisions';
import RightPanel from '~/shared/RightPanel';
import { regionColors } from '~/theme';
import { areaUnitText, areatoPref } from '~/lib/units';

import {
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Slider,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

import {
  getRegionById,
  getTmpRegionWithUsershape,
  regionsForFarm,
  selectedRegionId,
} from './selectors';
import { actions } from './slice';

const styles = (theme) => ({
  menuIcon: {
    marginRight: 8,
    marginLeft: 8,
  },

  slider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  stack: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    width: '100%',
    margin: 0,
    paddingBottom: 4,
  },

  spacer: {
    flexShrink: 0,
    width: 16,
  },
});

class EditRegionPanel extends React.Component {
  // This builds the full draft region out of the draft and usershape.

  constructor(props) {
    super(props);
    this.simplificationTol = 1;
  }

  isDirty = () => {
    const { originalRegion, tmpRegion } = this.props;

    const cleaner = (k, v) => {
      const ignore = ['last_updated', 'id'];
      if (ignore.includes(k)) {
        return undefined;
      }
      if (v === null) {
        return undefined;
      }
      if (typeof v === 'number') {
        return Math.round(v * 100) / 100;
      }
      return v;
    };

    return (
      stringify(originalRegion, { replacer: cleaner }) !=
      stringify(tmpRegion, { replacer: cleaner })
    );
  };

  save = () => {
    const { setTmpRegion, selectedRegionId, resetShape, uploadRegion, tmpRegion } = this.props;
    uploadRegion(tmpRegion); // This'll close automatically on success
    resetShape();
    setTmpRegion(selectedRegionId, undefined); // will clear the tmp entry
  };

  revert = async () => {
    const { setTmpRegion, tmpRegion, selectedRegionId, resetShape } = this.props;

    if (this.isDirty()) {
      const confirm = await showConfirmDialog(
        'Discard changes',
        'Are you sure you want to close the page without saving changes?',
        'Discard'
      );
      if (!confirm) return;
    }

    resetShape();
    setTmpRegion(selectedRegionId, undefined); // will clear the tmp entry
    history.push(`/regions?site=${tmpRegion.farm_id}`);
  };

  updateTmp = (r) => {
    const { setTmpRegion, tmpRegion, selectedRegionId } = this.props;
    const x = produce(tmpRegion, (draft) => {
      r(draft);
    });
    setTmpRegion(selectedRegionId, x);
  };

  setCrossingIndex = (evt) => {
    if (evt.target.checked) {
      this.updateTmp((r) => (r.crossing_index = [0]));
    } else {
      this.updateTmp((r) => (r.crossing_index = []));
    }
  };

  render() {
    const {
      classes,
      doesShapeExist,
      originalRegion,
      isShapeValid,
      cropTypes,
      regionsForFarm,
      tmpRegion,
      usershapeAdding,
      canPolygonize,
      canSimplify,
      polygonize,
      simplify,
      areaUnit,
      usershapeEditing,
    } = this.props;

    // TODO: if region is null, loading indicator
    const region = tmpRegion;
    if (!region) return null;

    const isDirty = this.isDirty();

    const canEditShape = !region.immutable;

    const shape = region.boundary ? wkt.parse(region.boundary) : null;

    const crop = cropTypes[region.crop_type];
    const CropIcon = Icons.CropIcon(crop?.icon);

    // TODO: This should go somewhere else so the buttons know about it
    const allowedShapeTypes = {
      KEEPOUT: ['MultiPolygon', 'Polygon', 'LineString'],
      FLIGHT: ['MultiPolygon', 'Polygon'],
      TARGETED: ['MultiPolygon', 'Polygon'],
      FIELD: ['MultiPolygon', 'Polygon'],
      ZONE: ['MultiPolygon', 'Polygon'],
    };

    const isShapeWrongGeometry =
      shape && !allowedShapeTypes[region.type].includes(shape.type) && !usershapeAdding;

    const isFieldInvalid =
      region.boundary &&
      (isShapeValid || !canEditShape) &&
      region.type == 'FIELD' &&
      geography.doesRegionOverlapAny(
        region,
        regionsForFarm.filter((r) => r.id != region.id && r.type == 'FIELD')
      );

    const isTargetInvalid =
      region.boundary &&
      (isShapeValid || !canEditShape) &&
      region.type == 'TARGETED' &&
      !geography.isRegionContainedBy(
        region,
        regionsForFarm.filter((r) => r.id != region.id && r.type == 'FIELD')
      );

    const isZoneInvalid =
      region.boundary &&
      (isShapeValid || !canEditShape) &&
      region.type == 'ZONE' &&
      !geography.isRegionContainedBy(
        region,
        regionsForFarm.filter((r) => r.id != region.id && r.type == 'FIELD')
      );

    return (
      <RightPanel title='Edit Region' back>
        <List disablePadding>
          <ListItem>
            <TextField
              fullWidth
              label='Name'
              onChange={(evt) => this.updateTmp((r) => (r.display_name = evt.target.value))}
              value={region.display_name}
            />
          </ListItem>
          {region.boundary && ( // TODO: Should display linear distance if the boundary isn't a polygon?
            <ListItem>
              <ListItemText
                primary={`Area of region: ${geography
                  .areaOfWKT(areatoPref(areaUnit), region.boundary)
                  .toFixed(1)} ${areaUnitText(areaUnit)}`}
              />
            </ListItem>
          )}
          <Divider />
          <ListItem>
            <FormControl fullWidth>
              <InputLabel htmlFor='type'>Region type</InputLabel>
              <Select
                fullWidth
                inputProps={{ id: 'type' }}
                value={region.type}
                onChange={(evt) => {
                  this.updateTmp((r) => (r.type = evt.target.value));
                }}>
                <MenuItem
                  key='FIELD'
                  value='FIELD'
                  style={{
                    color: regionColors['FIELD'],
                  }}>
                  Field
                </MenuItem>
                <MenuItem
                  key='FLIGHT'
                  value='FLIGHT'
                  style={{
                    color: regionColors['FLIGHT'],
                  }}>
                  Flight region
                </MenuItem>
                <MenuItem
                  key='KEEPOUT'
                  value='KEEPOUT'
                  style={{
                    color: regionColors['KEEPOUT'],
                  }}>
                  Keepout zone
                </MenuItem>
                <MenuItem
                  key='TARGETED'
                  value='TARGETED'
                  style={{
                    color: regionColors['TARGETED'],
                  }}>
                  Targeted Scout
                </MenuItem>
                <MenuItem
                  key='ZONE'
                  value='ZONE'
                  style={{
                    color: regionColors['ZONE'],
                  }}>
                  Analysis Zone
                </MenuItem>
              </Select>
            </FormControl>
          </ListItem>
          {region.type == 'FIELD' && (
            <ListItem>
              <ListItemIcon style={{ color: green[800] }}>
                <CropIcon />
              </ListItemIcon>

              <FormControl fullWidth>
                <InputLabel htmlFor='croptype'>{term('Type')}</InputLabel>
                <Select
                  fullWidth
                  inputProps={{ id: 'croptype' }}
                  value={region.crop_type || 'none'}
                  onChange={(evt) => {
                    this.updateTmp(
                      (r) => (r.crop_type = evt.target.value == 'none' ? null : evt.target.value)
                    );
                  }}>
                  <MenuItem key={'none'} value={'none'}>
                    No {term('Type')} Specified
                  </MenuItem>
                  {Object.values(cropTypes).map((c) => {
                    return (
                      <MenuItem key={c.id} value={c.id}>
                        {/* <CIcon classes={{ root: classes.menuIcon }} /> */}
                        {c.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </ListItem>
          )}
          {['FIELD', 'ZONE'].includes(region.type) && (
            <>
              <EditGridDivisions
                region={region}
                onChange={(newGridDiv) => this.updateTmp((r) => (r.grid_divisions = newGridDiv))}
              />

              {!canEditShape && region.grid_divisions != originalRegion.grid_divisions && (
                <ListItem>
                  <Alert severity='warning'>
                    Altering grid divisions will invalidate existing analytics.
                  </Alert>
                </ListItem>
              )}
            </>
          )}

          {region.type == 'KEEPOUT' && (
            <>
              <ListItem>
                <div className={classes.stack}>
                  <Typography variant='caption'>
                    Keepout below: {region.ceiling || 0}m (
                    {Math.floor((region.ceiling || 0) * 3.28084)} &apos;)
                  </Typography>
                  <Slider
                    classes={{ root: classes.slider }}
                    value={region.ceiling || 0}
                    min={15}
                    max={215} // TODO: Should this not be a slider, since really there's no max?
                    step={5}
                    onChange={(evt, value) => {
                      this.updateTmp((r) => (r.ceiling = Math.max(15, value)));
                    }}
                  />
                </div>
              </ListItem>
              {shape && shape.type == 'LineString' && (
                <>
                  <ListItem>
                    <TextField
                      fullWidth
                      label='Keepout dilation'
                      InputProps={{
                        endAdornment: <InputAdornment position='end'>m</InputAdornment>,
                      }}
                      type='number'
                      value={region.dilation}
                      onChange={(evt) => {
                        this.updateTmp((r) => (r.dilation = evt.target.value));
                      }}
                      inputProps={{
                        min: 0,
                        max: 30,
                        step: 0.1,
                      }}
                    />
                  </ListItem>

                  <ListItem>
                    <div className={classes.stack}>
                      <Typography variant='caption'>Allow crossing at vertex</Typography>

                      <Slider
                        classes={{ root: classes.slider }}
                        value={region.crossing_index?.[0] || 0}
                        min={0}
                        max={shape.coordinates.length - 1}
                        step={1}
                        disabled={!(region.crossing_index?.length > 0)}
                        onChange={(evt, value) => {
                          this.updateTmp((r) => (r.crossing_index = [value]));
                        }}
                      />
                    </div>
                    {/* 
                  <TextField
                    label='Allow crossing at vertex'
                    type='number'
                    disabled={!region.crossing_index}
                    value={region.crossing_index ? region.crossing_index[0] : 0}
                    min={0}
                    max={shape.coordinates.length - 1}
                    onChange={evt =>
                      this.updateTmp(r => (r.crossing_index = [evt.target.value]))
                    }
                  /> */}
                    <Switch
                      color='primary'
                      checked={region.crossing_index?.length > 0}
                      onChange={this.setCrossingIndex}
                    />
                  </ListItem>
                </>
              )}
            </>
          )}

          {canPolygonize && (
            <>
              <Divider />
              <ListItem>
                <Button fullWidth onClick={polygonize}>
                  Shape to polygon
                </Button>
              </ListItem>
            </>
          )}
          {canSimplify && (
            <>
              <Divider />
              <ListItem>
                <div className={classes.stack}>
                  <Typography variant='caption'>Simplification tolerance (m)</Typography>

                  <Slider
                    defaultValue={1}
                    onChange={(_, value) => (this.simplificationTol = value)}
                    classes={{ root: classes.slider }}
                    min={0}
                    max={Math.sqrt(10)}
                    step={0.01}
                    valueLabelFormat={(x) => x.toFixed(1)}
                    scale={(x) => x ** 2}
                    valueLabelDisplay='auto'
                  />
                  <Button
                    fullWidth
                    onClick={() => {
                      simplify(this.simplificationTol);
                    }}>
                    Simplify polygon
                  </Button>
                </div>
              </ListItem>
            </>
          )}
          <Divider />
          {originalRegion && (
            <ListItem>
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    checked={region.deleted}
                    onChange={(evt) => this.updateTmp((r) => (r.deleted = evt.target.checked))}
                    value='checkedA'
                  />
                }
                label='Deleted'
              />
            </ListItem>
          )}
          <ListItem>
            <Button fullWidth variant='contained' color='secondary' onClick={this.revert}>
              Close
            </Button>
            <div className={classes.spacer} />

            <Button
              fullWidth
              variant='contained'
              color='primary'
              disabled={
                !(isShapeValid || !canEditShape) ||
                !isDirty ||
                !region.boundary ||
                isShapeWrongGeometry ||
                isFieldInvalid ||
                usershapeAdding
              }
              onClick={this.save}>
              Save
            </Button>
          </ListItem>

          <Divider />

          {!canEditShape && (
            <ListItem>
              <Alert severity='warning'>
                This region has already been used to collect data, so modification of its geometry
                is no longer allowed.
              </Alert>
            </ListItem>
          )}
          {canEditShape && !doesShapeExist && (
            <ListItem>
              <Alert severity='info'>
                Draw a shape for this region.{' '}
                {region.type == 'FIELD'
                  ? 'Fields must not overlap other fields.'
                  : region.type == 'FLIGHT'
                  ? 'Flight areas should completely encompass fields '
                  : region.type == 'TARGET'
                  ? 'Targeted scouts must be contained inside a single field.'
                  : region.type == 'ZONE'
                  ? 'Zones must be contained inside a single field.'
                  : null}
              </Alert>
            </ListItem>
          )}
          {canEditShape && !isShapeValid && region.boundary && doesShapeExist && (
            <ListItem>
              <Alert severity='error'>Please remove self-intersections from your region.</Alert>
            </ListItem>
          )}
          {isFieldInvalid && (
            <ListItem>
              <Alert severity='error'>
                Fields should not overlap other fields! Did you mean to make a different type of
                region, like a Flight Region or Zone?
              </Alert>
            </ListItem>
          )}
          {isShapeWrongGeometry && (
            <ListItem>
              <Alert severity='error'>
                This type of region does not support the specified geometry type.
              </Alert>
            </ListItem>
          )}
          {usershapeAdding && (
            <ListItem>
              <Alert severity='info'>Continue drawing the shape.</Alert>
            </ListItem>
          )}
          {isZoneInvalid && (
            <ListItem>
              <Alert severity='warning'>
                Warning: Zones must be entirely contained in a Field!
              </Alert>
            </ListItem>
          )}
          {isTargetInvalid && (
            <ListItem>
              <Alert severity='warning'>
                Warning: Targets must be entirely contained in a Field!
              </Alert>
            </ListItem>
          )}
        </List>
      </RightPanel>
    );
  }
}

// const keyForRegionId = r => `update-region-${r || 'new'}`
// const postData = (state, ownProps) => state.data.posts[keyForRegionId(ownProps.regionId)]

const mapStateToProps = (state) => ({
  usershapeAdding: getUsershapeAdding(state),
  usershapeEditing: getUsershapeEditing(state),

  isShapeValid: getUsershapeValid(state),
  doesShapeExist: state.usershape.type !== null,

  cropTypes: state.global.cropTypes,

  selectedRegionId: selectedRegionId(state),

  regionsForFarm: regionsForFarm(state),
  tmpRegion: getTmpRegionWithUsershape(state),
  originalRegion: getRegionById(state, selectedRegionId(state)),

  canPolygonize:
    ['rectangle', 'circle'].includes(state.usershape.type) && !state.usershape.isAdding,
  canSimplify: state.usershape.type == 'polygon' && !state.usershape.isAdding,
  areaUnit: state.global.preferences.areaUnit,
});
const mapDispatchToProps = (dispatch) => ({
  setTmpRegion: (id, region) => dispatch(actions.setTmpRegion({ id, region })),
  uploadRegion: (r) => dispatch(actions.uploadRegion(r)),
  polygonize: () => dispatch(usershapeActions.polygonize()),
  resetShape: () => dispatch(usershapeActions.resetShape()),
  simplify: (tolerance) => dispatch(usershapeActions.simplify({ tolerance })),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditRegionPanel));
