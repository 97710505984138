import mapboxgl from 'mapbox-gl';
import { repaintMaps } from '~/lib/globalMapAccess';
import Logging from '~/logging';
import type { Imagery, Palette } from '~/schema';

// TODO: Subscribe to changes in redux that affect our layers and redraw as necessary

type WindowRange = [number, number];
type ContrastLevels = [number, number, number];

let windowRange: WindowRange = [0, 1];
let windowRangeMapB: WindowRange = [0, 1];
let contrastLevels: ContrastLevels = [0, 1, 1];
let contrastLevelsMapB: ContrastLevels = [0, 1, 1];

export const setWindowRange = (x: WindowRange) => {
  windowRange = x;
  repaintMaps();
};

export const setWindowRangeMapB = (x: WindowRange) => {
  windowRangeMapB = x;
  repaintMaps();
};

export const setContrastLevels = (x: ContrastLevels) => {
  contrastLevels = x;
  repaintMaps();
};

export const setContrastLevelsMapB = (x: ContrastLevels) => {
  contrastLevelsMapB = x;
  repaintMaps();
};

interface DataRange {
  min: [number, number, number];
  max: [number, number, number];
  gamma: number;
}

const getDataRange = (img: Imagery): DataRange => {
  // Some kinds are easy. We don't alter them from their histogram-stretched tiles.
  if (['RGB_U', 'RGB', 'CIR'].includes(img.type)) {
    return { min: [0, 0, 0], max: [1, 1, 1], gamma: 10 };
  }

  const CIR_DAMP = 1;

  const getNormalizeRange = () => {
    switch (img.type) {
      case 'RRENIR': // Or the RRENIR from teh ortho
        switch (img.ortho_interpretation) {
          case 'ARBITRARY_12_BIT':
            return [
              [0, 4096],
              [0, 4096],
              [0, 4096],
            ];
          case 'REFLECTANCE_16_BIT':
            return [
              [0, 32768],
              [0, 32768],
              [0, 32768],
            ];
          default:
            Logging.warn(
              `Unrecognized interpretation ${img.ortho_interpretation} for type ${img.type}`
            );
            return [
              [0, 1],
              [0, 1],
              [0, 1],
            ];
        }
      case 'CIR': // We rebalance CIR a bit
        switch (img.ortho_interpretation) {
          case 'ARBITRARY_12_BIT':
            return [
              [0, 4096 * CIR_DAMP],
              [0, 4096],
              [0, 4096],
            ];
          case 'REFLECTANCE_16_BIT':
            return [
              [0, 32768 * CIR_DAMP],
              [0, 32768],
              [0, 32768],
            ];
          default:
            Logging.warn(
              `Unrecognized interpretation ${img.ortho_interpretation} for type ${img.type}`
            );
            return [
              [0, CIR_DAMP],
              [0, 1],
              [0, 1],
            ];
        }

      default:
        // DEM needs no scaling either
        return [
          [0, 1],
          [0, 1],
          [0, 1],
        ];
    }
  };

  const normalizeRange = getNormalizeRange();

  const normalizeOffset = [0, 1, 2].map((i) => normalizeRange[i][0]);
  const normalizeScale = [0, 1, 2].map((i) => normalizeRange[i][1] - normalizeRange[i][0]);

  return {
    min: [
      ((img.data_range_x[0] || 0) - normalizeOffset[0]) / normalizeScale[0],
      ((img.data_range_y[0] || 0) - normalizeOffset[1]) / normalizeScale[1],
      ((img.data_range_z[0] || 0) - normalizeOffset[2]) / normalizeScale[2],
    ],

    max: [
      ((img.data_range_x[1] || 1) - normalizeOffset[0]) / normalizeScale[0],
      ((img.data_range_y[1] || 1) - normalizeOffset[1]) / normalizeScale[1],
      ((img.data_range_z[1] || 1) - normalizeOffset[2]) / normalizeScale[2],
    ],

    gamma: img.gamma > 0 ? 1.4 : 1, // We say that we encode gamma at 2.2, but it's really at 1.4! What is this nonsense?
  };
};

const FRAG_SHADER_PREFIX = `
precision highp float;
`;

// Stolen from mapbox, handles automatic fades
function getFadeValues(
  tile: any,
  parentTile: any,
  sourceCache: any,
  transform: any
): { opacity: number; mix: number } {
  const fadeDuration = 0.3;

  function clamp(n: number, min: number, max: number) {
    return Math.min(max, Math.max(min, n));
  }

  if (fadeDuration > 0) {
    const nowFunc =
      window.performance && window.performance.now
        ? window.performance.now.bind(window.performance)
        : Date.now.bind(Date);
    const now = nowFunc();

    const sinceTile = (now - tile.timeAdded) / fadeDuration;
    const sinceParent = parentTile ? (now - parentTile.timeAdded) / fadeDuration : -1;

    const source = sourceCache.getSource();
    const idealZ = transform.coveringZoomLevel({
      tileSize: source.tileSize,
      roundZoom: source.roundZoom,
    });

    // if no parent or parent is older, fade in; if parent is younger, fade out
    const fadeIn =
      !parentTile ||
      Math.abs(parentTile.tileID.overscaledZ - idealZ) > Math.abs(tile.tileID.overscaledZ - idealZ);

    const childOpacity =
      fadeIn && tile.refreshedUponExpiration
        ? 1
        : clamp(fadeIn ? sinceTile : 1 - sinceParent, 0, 1);

    // we don't crossfade tiles that were just refreshed upon expiring:
    // once they're old enough to pass the crossfading threshold
    // (fadeDuration), unset the `refreshedUponExpiration` flag so we don't
    // incorrectly fail to crossfade them when zooming
    if (tile.refreshedUponExpiration && sinceTile >= 1) tile.refreshedUponExpiration = false;

    if (parentTile) {
      return {
        opacity: 1,
        mix: 1 - childOpacity,
      };
    } else {
      return {
        opacity: childOpacity,
        mix: 0,
      };
    }
  } else {
    return {
      opacity: 1,
      mix: 0,
    };
  }
}

// Stolen from mapbox, handles binding vertex/index buffers in a way I haven't bothered to figure out
class VertexArrayObject {
  boundProgram: WebGLProgram | null;
  boundLayoutVertexBuffer: any;
  boundPaintVertexBuffers: any;
  boundIndexBuffer: any;
  boundVertexOffset: any;
  boundDynamicVertexBuffer: any;
  boundDynamicVertexBuffer2: any;
  vao: WebGLVertexArrayObject | null;
  context: any;

  constructor() {
    this.boundProgram = null;
    this.boundLayoutVertexBuffer = null;
    this.boundPaintVertexBuffers = [];
    this.boundIndexBuffer = null;
    this.boundVertexOffset = null;
    this.boundDynamicVertexBuffer = null;
    this.vao = null;
  }

  bind(
    context: any,
    program: any,
    layoutVertexBuffer: any,
    paintVertexBuffers: any,
    indexBuffer: any,
    vertexOffset: any,
    dynamicVertexBuffer: any,
    dynamicVertexBuffer2: any
  ) {
    this.context = context;

    let paintBuffersDiffer = this.boundPaintVertexBuffers.length !== paintVertexBuffers.length;
    for (let i = 0; !paintBuffersDiffer && i < paintVertexBuffers.length; i++) {
      if (this.boundPaintVertexBuffers[i] !== paintVertexBuffers[i]) {
        paintBuffersDiffer = true;
      }
    }

    const isFreshBindRequired =
      !this.vao ||
      this.boundProgram !== program ||
      this.boundLayoutVertexBuffer !== layoutVertexBuffer ||
      paintBuffersDiffer ||
      this.boundIndexBuffer !== indexBuffer ||
      this.boundVertexOffset !== vertexOffset ||
      this.boundDynamicVertexBuffer !== dynamicVertexBuffer ||
      this.boundDynamicVertexBuffer2 !== dynamicVertexBuffer2;

    if (!context.extVertexArrayObject || isFreshBindRequired) {
      this.freshBind(
        program,
        layoutVertexBuffer,
        paintVertexBuffers,
        indexBuffer,
        vertexOffset,
        dynamicVertexBuffer,
        dynamicVertexBuffer2
      );
    } else {
      context.bindVertexArrayOES.set(this.vao);

      if (dynamicVertexBuffer) {
        // The buffer may have been updated. Rebind to upload data.
        dynamicVertexBuffer.bind();
      }

      if (indexBuffer && indexBuffer.dynamicDraw) {
        indexBuffer.bind();
      }

      if (dynamicVertexBuffer2) {
        dynamicVertexBuffer2.bind();
      }
    }
  }

  freshBind(
    program: any,
    layoutVertexBuffer: any,
    paintVertexBuffers: any,
    indexBuffer: any,
    vertexOffset: any,
    dynamicVertexBuffer: any,
    dynamicVertexBuffer2: any
  ) {
    let numPrevAttributes;
    const numNextAttributes = program.numAttributes;

    const context = this.context;
    const gl = context.gl;

    if (context.extVertexArrayObject) {
      if (this.vao) this.destroy();
      this.vao = context.extVertexArrayObject.createVertexArrayOES();
      context.bindVertexArrayOES.set(this.vao);
      numPrevAttributes = 0;

      // store the arguments so that we can verify them when the vao is bound again
      this.boundProgram = program;
      this.boundLayoutVertexBuffer = layoutVertexBuffer;
      this.boundPaintVertexBuffers = paintVertexBuffers;
      this.boundIndexBuffer = indexBuffer;
      this.boundVertexOffset = vertexOffset;
      this.boundDynamicVertexBuffer = dynamicVertexBuffer;
      this.boundDynamicVertexBuffer2 = dynamicVertexBuffer2;
    } else {
      numPrevAttributes = context.currentNumAttributes || 0;

      // Disable all attributes from the previous program that aren't used in
      // the new program. Note: attribute indices are *not* program specific!
      for (let i = numNextAttributes; i < numPrevAttributes; i++) {
        // WebGL breaks if you disable attribute 0.
        // http://stackoverflow.com/questions/20305231
        if (i !== 0) gl.disableVertexAttribArray(i);
      }
    }

    layoutVertexBuffer.enableAttributes(gl, program);
    for (const vertexBuffer of paintVertexBuffers) {
      vertexBuffer.enableAttributes(gl, program);
    }

    if (dynamicVertexBuffer) {
      dynamicVertexBuffer.enableAttributes(gl, program);
    }
    if (dynamicVertexBuffer2) {
      dynamicVertexBuffer2.enableAttributes(gl, program);
    }

    layoutVertexBuffer.bind();
    layoutVertexBuffer.setVertexAttribPointers(gl, program, vertexOffset);
    for (const vertexBuffer of paintVertexBuffers) {
      vertexBuffer.bind();
      vertexBuffer.setVertexAttribPointers(gl, program, vertexOffset);
    }

    if (dynamicVertexBuffer) {
      dynamicVertexBuffer.bind();
      dynamicVertexBuffer.setVertexAttribPointers(gl, program, vertexOffset);
    }
    if (indexBuffer) {
      indexBuffer.bind();
    }
    if (dynamicVertexBuffer2) {
      dynamicVertexBuffer2.bind();
      dynamicVertexBuffer2.setVertexAttribPointers(gl, program, vertexOffset);
    }

    context.currentNumAttributes = numNextAttributes;
  }

  destroy() {
    if (this.vao) {
      this.context.extVertexArrayObject.deleteVertexArrayOES(this.vao);
      this.vao = null;
    }
  }
}

const CUSTOM_UNIFORMS = [
  'u_matrix',
  'u_tl_parent',
  'u_scale_parent',
  'u_buffer_scale',
  'u_fade_t',
  'u_opacity',
  'u_image0',
  'u_image1',
  'u_falsecolor_start',
  'u_falsecolor_end',
  'u_data_start',
  'u_data_end',
  'u_gamma',
  'u_contrast_levels',
] as const;

type CustomUniforms = typeof CUSTOM_UNIFORMS[number];

// Our custom tileset renderer!
class CustomTilesetLayer implements mapboxgl.CustomLayerInterface {
  id: string;
  type: 'custom';
  renderingMode: '2d';

  source: string;
  imagery: Imagery;
  channel: string;
  shader: string;
  visible: boolean;
  palette: Palette;
  minzoom: number;
  layout: mapboxgl.AnyLayout;
  paint: mapboxgl.AnyPaint;
  map?: any; // This isn't mapboxgl.Map because we use internal functions not exposed in typings
  program?: WebGLProgram;

  attributes: Record<string, number>;

  uniforms: Record<CustomUniforms, WebGLUniformLocation | undefined | null>;
  mapidx: number;

  constructor(
    layerId: string,
    mapidx: number,
    source: string,
    imagery: Imagery,
    channel: string,
    shader: string,
    visible: boolean,
    palette: Palette,
    minzoom: number,
    layout: mapboxgl.Layout,
    paint: mapboxgl.AnyPaint
  ) {
    this.id = layerId;
    this.mapidx = mapidx;
    this.type = 'custom';
    this.renderingMode = '2d';
    this.imagery = imagery;
    this.channel = channel;
    this.minzoom = minzoom;
    this.source = source;
    this.visible = visible;
    this.shader = shader;
    this.palette = palette;
    this.minzoom = minzoom;
    this.layout = layout;
    this.paint = paint;
    this.attributes = {};

    this.uniforms = {} as Record<CustomUniforms, WebGLUniformLocation | undefined | null>;
  }

  onAdd(map: mapboxgl.Map, gl: WebGLRenderingContext) {
    this.map = map;

    // This vertex shader is verbatim the same as the raster vertex shader
    const vertexSource = `
            uniform mat4 u_matrix;
            uniform vec2 u_tl_parent;
            uniform float u_scale_parent;
            uniform float u_buffer_scale;
      
            attribute vec2 a_pos;
            attribute vec2 a_texture_pos;
      
            varying vec2 v_pos0;
            varying vec2 v_pos1;
      
            void main() {
                gl_Position = u_matrix * vec4(a_pos, 0, 1);
                // We are using Int16 for texture position coordinates to give us enough precision for
                // fractional coordinates. We use 8192 to scale the texture coordinates in the buffer
                // as an arbitrarily high number to preserve adequate precision when rendering.
                // This is also the same value as the EXTENT we are using for our tile buffer pos coordinates,
                // so math for modifying either is consistent.
                v_pos0 = (((a_texture_pos / 8192.0) - 0.5) / u_buffer_scale ) + 0.5;
                v_pos1 = (v_pos0 * u_scale_parent) + u_tl_parent;
            }
            `;

    const addLineNumbers = (x: string) =>
      x
        .split('\n')
        .map((line, index) => `${index + 1}\t ${line}`)
        .join('\n');

    const vertexShader = gl.createShader(gl.VERTEX_SHADER);
    if (vertexShader == null) throw 'Could not create GL resource';

    gl.shaderSource(vertexShader, vertexSource);
    gl.compileShader(vertexShader);

    if (!gl.getShaderParameter(vertexShader, gl.COMPILE_STATUS)) {
      const info = gl.getShaderInfoLog(vertexShader);
      throw 'Could not compile vertex program. \n\n' + info;
    }

    const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER);
    if (fragmentShader == null) throw 'Could not create GL resource';

    const fragmentSrc = [FRAG_SHADER_PREFIX, ...this.shader].join('\n');
    gl.shaderSource(fragmentShader, fragmentSrc);
    gl.compileShader(fragmentShader);

    if (!gl.getShaderParameter(fragmentShader, gl.COMPILE_STATUS)) {
      const info = gl.getShaderInfoLog(fragmentShader);
      throw (
        'Could not compile fragment program. \n\n' + addLineNumbers(fragmentSrc) + '\n\n' + info
      );
    }

    this.program = gl.createProgram() || undefined;
    if (this.program == null) throw 'Could not create GL resource';

    gl.attachShader(this.program, vertexShader);
    gl.attachShader(this.program, fragmentShader);
    gl.linkProgram(this.program);
    gl.validateProgram(this.program);

    if (!gl.getProgramParameter(this.program, gl.LINK_STATUS)) {
      const info = gl.getProgramInfoLog(this.program);
      throw 'Could not compile WebGL program. \n\n' + info;
    }

    // Store any uniform and attribute locations

    // The VertexBuffer assumes that 'this' looks like a Program, at least that it has attributes
    this.attributes = {
      a_pos: gl.getAttribLocation(this.program, 'a_pos'),
      a_texture_pos: gl.getAttribLocation(this.program, 'a_texture_pos'),
    };

    // Here are all the uniforms needed either by default for the render, or for any of our visual effects
    for (const u of CUSTOM_UNIFORMS) {
      this.uniforms[u] = gl.getUniformLocation(this.program, u);
    }
  }

  render(gl: WebGLRenderingContext, _matrix: number[]) {
    if (this.program == null || this.map == null) return;

    gl.useProgram(this.program);

    // This is needed because there's a cache that cares about the layer id
    const layerID = this.id;

    const painter = this.map.painter;
    // This is just the name of the source we're pulling from. On the satellite style, 'mapbox' is the satellite view.

    // This 'other' comes from mapbox's "two phase tile loading" I guess. We should really have the layer here to get it using an accessor.
    const sourceCache = this.map.style._getLayerSourceCache(this);

    if (!sourceCache) {
      Logging.warn(`Could not find item in sourceCaches`, { source: this.source });
      return;
    }
    const source = sourceCache.getSource();
    const coords = sourceCache.getVisibleCoordinates().reverse();

    const context = this.map.painter.context;

    // const colorMode = painter.colorModeForRenderPass();
    const minTileZ = coords.length && coords[0].overscaledZ;

    for (const coord of coords) {
      const tile = sourceCache.getTile(coord);

      // These are normally whole objects, but I've simplified them down into raw json.
      const depthMode = painter.depthModeForSublayer(coord.overscaledZ - minTileZ, true, gl.LESS);
      const stencilMode = {
        test: { func: 0x0207, mask: 0 },
        ref: 0,
        mask: 0,
        fail: 0x1e00,
        depthFail: 0x1e00,
        pass: 0x1e00,
      };
      const cullFaceMode = {
        enable: false,
        mode: 0x0405,
        frontFace: 0x0901,
      };
      const colorMode = painter.colorModeForRenderPass();

      const posMatrix = coord.posMatrix;
      tile.registerFadeDuration(0.3); // Was stored in the paint properties, here is hardcoded

      const parentTile = sourceCache.findLoadedParent(coord, 0),
        fade = getFadeValues(tile, parentTile, sourceCache, painter.transform);

      // Properties computed for the shader
      let parentScaleBy, parentTL;

      // Set up the two textures for this tile and its parent
      const textureFilter = gl.LINEAR;
      context.activeTexture.set(gl.TEXTURE0);
      tile.texture.bind(textureFilter, gl.CLAMP_TO_EDGE, gl.LINEAR_MIPMAP_NEAREST);

      context.activeTexture.set(gl.TEXTURE1);
      if (parentTile) {
        parentTile.texture.bind(textureFilter, gl.CLAMP_TO_EDGE, gl.LINEAR_MIPMAP_NEAREST);
        parentScaleBy = Math.pow(2, parentTile.tileID.overscaledZ - tile.tileID.overscaledZ);
        parentTL = [
          (tile.tileID.canonical.x * parentScaleBy) % 1,
          (tile.tileID.canonical.y * parentScaleBy) % 1,
        ];
      } else {
        tile.texture.bind(textureFilter, gl.CLAMP_TO_EDGE, gl.LINEAR_MIPMAP_NEAREST);
      }

      // I'm assuming our source is an "ImageSource", though I don't really know what that means.
      // I followed the implementation on that branch of the if to produce the following.
      const layoutVertexBuffer = source.boundsBuffer || painter.rasterBoundsBuffer;
      const indexBuffer = painter.quadTriangleIndexBuffer;
      const segments = source.boundsSegments || painter.rasterBoundsSegments;
      const drawMode = gl.TRIANGLES;

      // Set GL properties
      context.setDepthMode(depthMode);
      context.setStencilMode(stencilMode);
      context.setColorMode(colorMode);
      context.setCullFace(cullFaceMode);

      // Set uniforms
      const u = this.uniforms!;

      gl.uniformMatrix4fv(u.u_matrix!, false, posMatrix);
      gl.uniform2fv(u.u_tl_parent!, parentTL || [0, 0]);
      gl.uniform1f(u.u_scale_parent!, parentScaleBy || 1);
      gl.uniform1f(u.u_buffer_scale!, 1);
      gl.uniform1f(u.u_fade_t!, fade.mix);
      gl.uniform1f(u.u_opacity!, fade.opacity * (this.visible ? 1 : 0));
      gl.uniform1i(u.u_image0!, 0);
      gl.uniform1i(u.u_image1!, 1);

      // The data we get can be in a bunch of varying formats that we'd like to normalize before
      // the shader sees it. The ortho might be 12 bit (0-4096) or 16 bit reflectance (0-32k-64k), or
      // it could be the DEM that's in meters, or... etc. So let the imagery object tell us how to scale it for the shader.
      // Depending on the type of data, scale it from 0 to 1 appropriately before passing to the shader.
      const datarange = getDataRange(this.imagery);

      gl.uniform1f(u.u_falsecolor_start!, this.mapidx == 0 ? windowRange[0] : windowRangeMapB[0]);
      gl.uniform1f(u.u_falsecolor_end!, this.mapidx == 0 ? windowRange[1] : windowRangeMapB[1]);

      gl.uniform1f(u.u_gamma!, datarange.gamma);

      this.mapidx == 0
        ? gl.uniform3f(u.u_contrast_levels!, ...contrastLevels)
        : gl.uniform3f(u.u_contrast_levels!, ...contrastLevelsMapB);

      if (u.u_data_start != null && u.u_data_end != null) {
        gl.uniform3f(u.u_data_start, ...datarange.min);
        gl.uniform3f(u.u_data_end, ...datarange.max);
      }

      // Our draw mode is fixed, but I figured I'd leave this in anyway?
      const primitiveSize = {
        [gl.LINES]: 2,
        [gl.TRIANGLES]: 3,
        [gl.LINE_STRIP]: 1,
      }[drawMode];

      // Stolen from the draw function
      for (const segment of segments.get()) {
        const vaos = segment.vaos || (segment.vaos = {});
        const vao = vaos[layerID] || (vaos[layerID] = new VertexArrayObject());

        vao.bind(context, this, layoutVertexBuffer, [], indexBuffer, segment.vertexOffset);

        gl.drawElements(
          drawMode,
          segment.primitiveLength * primitiveSize,
          gl.UNSIGNED_SHORT,
          segment.primitiveOffset * primitiveSize * 2
        );
      }
    }
  }
}

const customLayerCache: CustomTilesetLayer[] = [];

export function getCustomLayer(
  i: Imagery,
  channel: string,
  shader: string,
  mapidx: number,
  palette: Palette
) {
  const layerId = `imagery-${i.id}_${mapidx}_${palette}`;
  const sourceId = `imagery-${i.id}-source`;
  const visible = i.visible;

  const opts = {
    minzoom: 13.5,
    layout: {},
    paint: {},
  };

  // Check if it's in the cache
  let custom = customLayerCache.find((x) => x.id == layerId);

  if (!custom) {
    custom = new CustomTilesetLayer(
      `${layerId}-${channel}_${mapidx}__${palette}`,
      mapidx,
      sourceId,
      i,
      channel,
      shader,
      visible,
      palette,
      opts.minzoom,
      opts.layout,
      opts.paint
    );

    customLayerCache.push(custom);
  }

  custom.visible = visible;

  const ghost = {
    type: 'raster',
    id: `${layerId}-ghost_${mapidx}`,
    source: sourceId,
    minzoom: opts.minzoom,
    layout: opts.layout,
    paint: { 'raster-opacity': 0 },
  };

  const newLayers = [ghost, custom];

  return newLayers;
}
