import React from 'react';

import { Checkbox, FormControlLabel, MenuItem, TextField } from '@material-ui/core';
import { resolutionAt } from '~/lib/geography';

import type Props from './trqControlProps';
import produce from 'immer';
import { InstructionsContinuous } from '~/schema/TargetRequest';
import useFormBuffer, { formBufferTextFieldProps } from '~/shared/formBuffer';

function wrapHeading(x: number) {
  while (x < 0) x += 360;
  while (x >= 360) x -= 360;
  return x;
}

const FT_PER_M = 3.28084;

export default function ContinuousControls({ ceiling, trq, editField }: Props) {
  let altitudesToShow: number[] = [];
  for (let x = 10; x <= Math.floor(ceiling * FT_PER_M) + 0.5; x += 10) {
    altitudesToShow = [x, ...altitudesToShow];
  }

  const instructions = trq.instructions_continuous;
  if (!instructions) throw 'Invalid TRQ instructions passed to ContinuousControls';

  const editContinuousFields = React.useCallback(
    (x: Partial<InstructionsContinuous>) => {
      editField({
        ...x,
        instructions_continuous: produce(instructions, (draft) => ({
          ...draft,
          ...x,
        })),
      });
    },
    [instructions, editField]
  );

  const form = {
    altitude: useFormBuffer<number>(
      instructions.altitude,
      React.useCallback(
        (value) => editContinuousFields({ altitude: value }),
        [editContinuousFields]
      ),
      React.useCallback(
        (value) => {
          if (isNaN(value)) return 'Invalid altitude';
          if (value <= 1) return 'Altitude too low';
          if (value > ceiling) return 'Altitude exceeds ceiling';
        },
        [ceiling]
      )
    ),

    heading: useFormBuffer<number | undefined>(
      instructions.heading,
      React.useCallback(
        (value) =>
          editContinuousFields({ heading: value === undefined ? undefined : wrapHeading(value) }),
        [editContinuousFields]
      ),
      React.useCallback((value) => {
        if (value !== undefined && isNaN(value)) return 'Invalid heading';
      }, [])
    ),
    speed: useFormBuffer<number | undefined>(
      instructions.speed,
      React.useCallback((value) => editContinuousFields({ speed: value }), [editContinuousFields]),
      React.useCallback((value) => {
        if (value !== undefined && isNaN(value)) return 'Invalid speed';
        if (value <= 0) return 'Speed must be positive';
        if (value > 20) return 'Scout cannot go that fast :(';
      }, [])
    ),
    frontlap: useFormBuffer<string>(
      instructions.frontlap.toFixed(2),
      React.useCallback(
        (value) => editContinuousFields({ frontlap: parseFloat(value) }),
        [editContinuousFields]
      ),
      React.useCallback((value) => {
        const f = Number(value);
        if (isNaN(f)) return 'Invalid frontlap';
        if (f < 0) return 'Frontlap too small';
        if (f >= 0.999) return 'Frontlap too large';
      }, [])
    ),
    sidelap: useFormBuffer<string>(
      instructions.sidelap.toFixed(2),
      React.useCallback(
        (value) => editContinuousFields({ sidelap: parseFloat(value) }),
        [editContinuousFields]
      ),
      React.useCallback((value) => {
        const f = Number(value);
        if (isNaN(f)) return 'Invalid sidelap';
        if (f < 0) return 'Sidelap too small';
        if (f >= 0.999) return 'Sidelap too large';
      }, [])
    ),
    dilate: useFormBuffer<boolean>(
      instructions.dilate,
      React.useCallback((value) => editContinuousFields({ dilate: value }), [editContinuousFields])
    ),
  };

  return (
    <>
      <TextField
        fullWidth
        select
        label='Altitude (ft AGL)'
        value={(form.altitude.value * FT_PER_M).toFixed(0)}
        error={!!form.altitude.err}
        helperText={form.altitude.err}
        onChange={(e) => form.altitude.set(parseFloat(e.target.value) / FT_PER_M)}>
        {altitudesToShow.map((altft) => {
          return (
            <MenuItem key={altft} value={altft.toFixed(0)}>
              {altft}&apos;
            </MenuItem>
          );
        })}
      </TextField>

      <div>
        <TextField
          fullWidth
          type='number'
          inputProps={{ min: 0, max: 359 }}
          value={form.heading.value}
          error={!!form.heading.err}
          helperText={form.heading.err}
          onChange={(e) => form.heading.set(parseInt(e.target.value))}
          label='Heading (deg)'
          placeholder='Automatic'
          InputLabelProps={{ shrink: true }}
          onClick={(evt) => evt.stopPropagation()}
        />

        <TextField
          fullWidth
          type='number'
          value={form.speed.value}
          error={!!form.speed.err}
          helperText={form.speed.err}
          onChange={(e) => form.speed.set(parseFloat(e.target.value))}
          label='Speed (m/s)'
          placeholder='Automatic'
          InputLabelProps={{ shrink: true }}
          onClick={(evt) => evt.stopPropagation()}
        />
      </div>
      <div>
        <TextField
          fullWidth
          {...formBufferTextFieldProps(form.frontlap)}
          label='Frontlap (dec)'
          onClick={(evt) => evt.stopPropagation()}
        />

        <TextField
          fullWidth
          {...formBufferTextFieldProps(form.sidelap)}
          label='Sidelap (dec)'
          onClick={(evt) => evt.stopPropagation()}
        />
      </div>

      <FormControlLabel
        control={
          <Checkbox
            checked={form.dilate.value}
            onChange={(e) => form.dilate.set(e.target.checked)}
            value='dilate'
            color='primary'
          />
        }
        label='Dilate Boundary'
      />
    </>
  );
}
