import * as React from 'react';

import { Backdrop, CircularProgress } from '@material-ui/core';

// This component will display a loading screen if it has been loading for more than 'delay' millis.
export default function LoadingBackdrop(props: { loading: boolean; delay: number }) {
  // State set to true when loading is true for longer than 'delay'.
  const [show, setShow] = React.useState(false);

  const timerRef = React.useRef<number|null>(null);

  React.useEffect(() => {
    if (props.loading && timerRef.current === null) {
      // Start the timer to turn on 'show'
      timerRef.current = window.setTimeout(() => {
        setShow(true);
      }, props.delay);
    } else {
      // Stop the timer, hide show
      if (timerRef.current) window.clearTimeout(timerRef.current);
      timerRef.current = null
      setShow(false);
    }
  }, [props.loading, props.delay]);

  return (
    <Backdrop style={{ zIndex: 100 }} open={show}>
      <CircularProgress color='primary' />
    </Backdrop>
  );
}
