import * as MapboxGl from 'mapbox-gl';
import React from 'react';
import ReactDOM from 'react-dom';
import { getTheme } from '~/theme';

import { MuiThemeProvider, ThemeProviderProps } from '@material-ui/core';

const globalMap: [MapboxGl.Map | null, MapboxGl.Map | null] = [null, null];

export function getGlobalMap(idx: number) {
  return globalMap[idx];
}

export function setGlobalMap(map: MapboxGl.Map, idx: number) {
  globalMap[idx] = map;
}

export function repaintMaps() {
  globalMap[0]?.triggerRepaint();
  globalMap[1]?.triggerRepaint();
}

export function projectCoordinate(c: MapboxGl.LngLatLike) {
  return globalMap[0]?.project(c);
}

export function screenshotMap() {
  // This lets us take a screenshot without the performance overhead of preserving the gl draw buffer.
  return new Promise(function (resolve, reject) {
    const map = globalMap[0];
    if (map == null) {
      reject();
      return;
    }

    map.once('render', () => resolve(map.getCanvas().toDataURL()));
    // trigger a render
    map.setBearing(map.getBearing());
  });
}

export function addStaticMapPopup(el: Element, lat: number, lng: number) {
  const placeholder = document.createElement('div');

  if (globalMap[0] == null) return;

  const elWrapped = React.createElement(MuiThemeProvider, {
    theme: getTheme(false),
    children: el,
  } as ThemeProviderProps);

  ReactDOM.render(elWrapped, placeholder);

  const marker = new MapboxGl.Popup()
    .setDOMContent(placeholder)
    .setLngLat({ lng: lng, lat: lat })
    .addTo(globalMap[0]);

  return marker;
}
