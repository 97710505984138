import React from 'react';

import { BasicStatsControlComponent, drawGridData } from './common';

const PREF_KEY = 'analysis_ndvi_std';
const DEFAULT_PREFS = { stops: [0.2, 0.4, 0.6, 0.8], isPercentile: true };
const CTRL_SZ = [288, 28 * 8];
export const name = 'NDVI Variance';
export const description = 'Calculates spread of NDVI within a cell.';

export function overlay(key, analysis, cell_data, preferences, colorForNDVI) {
  const myPreferences = { ...DEFAULT_PREFS, ...(preferences[PREF_KEY] || {}) };

  return drawGridData(
    key,
    'std',
    myPreferences.stops,
    myPreferences.isPercentile,
    cell_data,
    0.01, // from what's in cell data to real ndvi
    preferences.analysisOpacity,
    preferences.analysisLabels,
    colorForNDVI
  );
}

function ControlComponent() {
  return (
    <BasicStatsControlComponent
      summary='Cells calculate how much the NDVI value changes within them, with larger values representing more variance.'
      helpTopic='Variance'
      pref_key={PREF_KEY}
      default_prefs={DEFAULT_PREFS}
      ctrl_sz={CTRL_SZ}
      allowPercentile
    />
  );
}

export function controls() {
  return {
    width: CTRL_SZ[0],
    height: CTRL_SZ[1],
    component: <ControlComponent />,
  };
}
