import React from 'react';
import * as Icons from '~/icons';
import * as geography from '~/lib/geography';
import history from '~/lib/history';
import { actions as uiActions } from '~/redux/slices/ui';
import FilterBar from '~/shared/FilterBar';
import RegionAvatar from '~/shared/RegionAvatar';
import RegionDisplaySwitches from '~/shared/RegionDisplaySwitches';
import RightPanel from '~/shared/RightPanel';
import { areaUnitText, areatoPref } from '~/lib/units';
import { actions as usershapeActions } from '~/redux/slices/usershape';

import {
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';

import UploadShapefile from './UploadShapefile';
import { getFilteredRegions, focussedFarm, regionsForFarm } from './selectors';
import { makeStyles } from '@material-ui/core/styles';
import { useSVDispatch, useSVSelector } from '~/redux/hooks';
import type { Region } from '~/schema';

const styles = makeStyles((theme) => ({
  module: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function RegionsPanel() {
  const [importShapefile, setImportShapefile] = React.useState(false);

  const handleClose = () => {
    setImportShapefile(false);
  };

  const classes = styles();

  const farm = useSVSelector(focussedFarm);
  const totalRegions = useSVSelector((state) => regionsForFarm(state)?.length);
  const filteredRegions = useSVSelector((state) => getFilteredRegions(state)) as Region[];
  const areaUnit = useSVSelector(
    (state) => state.global.preferences.areaUnit
  ) as geography.UnitsType;
  const filterValue = useSVSelector((state) => state.ui.regionsPageFilter);

  const dispatch = useSVDispatch();
  const setFilterValue = (value: string) => dispatch(uiActions.setAssetsFilterValue(value));

  React.useEffect(() => {
    // Reset the usershape on first start
    dispatch(usershapeActions.resetShape());
  }, [dispatch]);

  return (
    <RightPanel title={farm?.location || 'Edit regions'} noScroll>
      <FilterBar
        fullWidth
        value={filterValue}
        disabled={totalRegions == 0}
        notFound={filteredRegions.length == 0}
        onChange={setFilterValue}
        placeholder='Filter Regions'
      />
      <div className={classes.module}>
        <RegionDisplaySwitches />
      </div>
      <Divider />
      {farm ? (
        <>
          <List disablePadding>
            <ListItem
              button
              onClick={() => history.push(`/regions?site=${farm.id}&regionid=new`)}
              dense>
              <ListItemIcon>
                <Icons.Add />
              </ListItemIcon>
              <ListItemText
                primary={filterValue == '' ? 'Create Region' : `Create Region "${filterValue}"`}
              />
            </ListItem>

            <ListItem dense button onClick={() => setImportShapefile(true)}>
              <ListItemIcon>
                <Icons.Polygon />
              </ListItemIcon>
              <ListItemText primary='Import Shapefile...' />
            </ListItem>
          </List>
          <Divider />
          <List
            style={{
              flexGrow: 1,
              overflowY: 'auto',
              overflowX: 'hidden',
              maxHeight: '100%',
            }}>
            {filteredRegions.map((r) => (
              <ListItem
                button
                onClick={() => history.push(`/regions?regionid=${r.id}`)}
                dense
                key={r.id}>
                <ListItemAvatar>
                  <RegionAvatar type={r.type} crop={r.crop_type} />
                </ListItemAvatar>
                <ListItemText
                  style={{ overflowWrap: 'break-word' }}
                  primary={r.display_name}
                  secondary={`${geography
                    .areaOfWKT(areatoPref(areaUnit), r.boundary)
                    ?.toFixed(1)} ${areaUnitText(areaUnit)}`}
                />
                {r.immutable && (
                  <ListItemSecondaryAction>
                    <Tooltip title='Region cannot be reshaped'>
                      <Icons.Locked />
                    </Tooltip>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
          </List>
          <UploadShapefile farmId={farm.id} open={importShapefile} handleClose={handleClose} />
        </>
      ) : (
        <Typography variant='body1'>Select a farm to view its regions</Typography>
      )}
    </RightPanel>
  );
}
