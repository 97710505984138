import React from 'react';
import { useLocation } from 'react-router-dom';
import * as api from '~/lib/api';
import history from '~/lib/history';
import { showSnackbar } from '~/lib/modalServices';
import Logging from '~/logging';

import { CircularProgress, Dialog, DialogContent, Grid, Typography } from '@material-ui/core';

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default function ConfirmEmail() {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const query = new URLSearchParams(useLocation().search);
  const code = query.get('c');

  React.useEffect(() => {
    if (loading || error) {
      return;
    }

    setLoading(true);
    sleep(300).then(() =>
      api
        .webrequest('POST', '/users/confirm', { confirm_code: code })
        .then(() => {
          // if we succeed, immediately redirect to login.
          history.push('/');
          showSnackbar('success', 'Email confirmed!');
        })
        .catch((err) => {
          setError(err);
          Logging.error('Error submitting confiration code', err);
          setLoading(false);
        })
    );
  }, [code, loading, error]);

  return (
    <Dialog open maxWidth='sm' fullWidth>
      <DialogContent>
        <Grid container justify='center' alignItems='center' style={{ minHeight: 150 }}>
          {loading && (
            <Grid item alignItems='center'>
              <CircularProgress size={120} />
            </Grid>
          )}

          {error && (
            <>
              <Grid item alignItems='center'>
                <Typography variant='h6' color='error'>
                  The confirmation code was not accepted.
                </Typography>
              </Grid>
              <Grid item alignItems='center'>
                <Typography variant='subtitle1'>
                  Email confirmation only needs to be done once. For further assistance, please
                  contact American Robotics Support at
                  <br />
                  <a href='mailto:support@american-robotics.com'>support@american-robotics.com</a>.
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
