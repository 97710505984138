import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Icons from '~/icons';
import channelConfig from '~/lib/channelConfig';
import { availableImageryChannels } from '~/map/selectors';
import { getAllChannels } from '~/redux/commonSelectors';
import { actions as globalActions } from '~/redux/slices/global';
import ToggleButtonWithTip from '~/shared/ToggleButtonWithTip';

import { ListItemText, Menu, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { GAevent } from '~/googleAnalytics';

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&.selected': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary, & .MuiListItemText-secondary': {
        color: theme.palette.primary.contrastText,
      },
    },
  },
}))(MenuItem);

export function ImageryChannelSelectorMenu({
  mapidx,
  anchorEl,
  onClose,
  anchorOrigin,
  transformOrigin,
}) {
  const allChannels = useSelector(getAllChannels);
  const dispatch = useDispatch();
  const availableChannels = useSelector((state) => availableImageryChannels(state, mapidx));
  const setAllChannels = (x) => dispatch(globalActions.setPreference({ channel: x }));

  const channel = allChannels[mapidx];

  const setChannel = (x) => {
    let newChannels = [...allChannels];
    newChannels[mapidx] = x;
    setAllChannels(newChannels);
    onClose();
  };

  return (
    <Menu
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      MenuListProps={{ disablePadding: true }}
      PaperProps={{
        style: {
          maxHeight: 360,
        },
      }}>
      {[...availableChannels].map((channelId) => (
        <StyledMenuItem
          key={channelId}
          classes={{ root: channel == channelId ? 'selected' : 'unselected' }}
          onClick={() => {
            GAevent('Setting', `Set channel to ${channelId}`, 'setChannel(channelId)');
            setChannel(channelId)}}>
          <ListItemText
            primary={channelConfig[channelId].name}
            secondary={channelConfig[channelId].tooltip}
          />
        </StyledMenuItem>
      ))}
    </Menu>
  );
}

export default function ImageryChannelSelector({ mapidx, ...props }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  return (
    <>
      <ToggleButtonWithTip
        tooltip='Imagery layers'
        toggled={anchorEl}
        onClick={(evt) => setAnchorEl(evt.currentTarget)}
        {...props}>
        <Icons.Indices />
      </ToggleButtonWithTip>
      <ImageryChannelSelectorMenu
        mapidx={mapidx}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      />
    </>
  );
}
