import React from 'react';
import { useLocation } from 'react-router-dom';
import * as api from '~/lib/api';
import history from '~/lib/history';
import { showSnackbar } from '~/lib/modalServices';
import PasswordCheck from '~/shared/PasswordCheck';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
  },
  title: {
    flexGrow: 1,
    whiteSpace: 'nowrap',
  },
  divider: {
    margin: 12,
    alignSelf: 'stretch',
  },
}));

export default function RecoverPassword() {
  const classes = styles();

  let query = new URLSearchParams(useLocation().search);
  const token = query.get('c');

  const [password, setPassword] = React.useState('');
  const [confirmPw, setConfirmPw] = React.useState('');
  const [pwScore, setPwScore] = React.useState(0);
  const pwValid = pwScore > 0 && confirmPw == password;

  const tryReset = async () => {
    try {
      await api.webrequest('post', `sv/recover`, {
        token,
        password,
      });

      history.push('/');
      showSnackbar('success', 'Password has been reset.');
    } catch (err) {
      console.error(err);
      showSnackbar('error', 'Error resetting password, try again.');
    }
  };

  document.title = 'Reset ScoutView Password';

  return (
    <Dialog
      open
      dividers
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
      hideBackdrop
      maxWidth='md'
      className={classes.container}>
      <DialogTitle>ScoutView Password Reset</DialogTitle>
      <DialogContent dividers>
        <Typography variant='subtitle1'>
          Please enter a new password for your ScoutView account.
        </Typography>
        <PasswordCheck
          keepOpen
          password={password}
          onChangePassword={setPassword}
          confirmPassword={confirmPw}
          onChangeConfirm={setConfirmPw}
          onChangeScore={setPwScore}
        />
      </DialogContent>
      <DialogActions>
        <Button color='primary' disabled={!pwValid} onClick={tryReset}>
          Reset Password
        </Button>
      </DialogActions>
    </Dialog>
  );
}
