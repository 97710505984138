import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { controlsForAnalysis, getAnalysisDescriptions } from '~/analysis';
import * as Icons from '~/icons';
import { actions as globalActions } from '~/redux/slices/global';
import MapLegendControlContainer from '~/shared/MapLegendControlContainer';

import { makeStyles, useTheme } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({}));

export default function MapAnalysisControls({ mapidx, analysis }) {
  const theme = useTheme();

  const classes = styles();

  const allAnalysisModes = useSelector((state) => state.global.preferences.analysisMode);
  const preferences = useSelector((state) => state.global.preferences);

  const metric = allAnalysisModes[mapidx];

  const desc = metric ? getAnalysisDescriptions(metric) : null;

  const dispatch = useDispatch();
  const setPreferences = (pref) => dispatch(globalActions.setPreference(pref));

  // const setAnalysisMode = x => {
  //   let newModes = [...allAnalysisModes]
  //   newModes[mapidx] = x
  //   dispatch(globalActions.setPreference({ analysisMode: newModes }))
  // }

  const controls = controlsForAnalysis(metric, mapidx);

  return (
    <MapLegendControlContainer
      title={
        <>
          Analysis: <b>{desc ? desc.name : 'None'}</b>
        </>
      }
      disabled={desc == null}
      Icon={Icons.Analysis}
      collapsed_width={theme.spacing(24)}
      collapsed_height={0}
      detail_width={controls?.width || 0}
      detail_height={controls?.height || 0}
      alwaysVisible={null}
      detailView={controls?.component}
    />
  );
}
