import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import App from '~/App';
import { AcceptInvite } from '~/acceptinvite';
// Redux
import history from '~/lib/history';
import { setMarket } from '~/markets';
import StandbyPage from '~/pages/StandbyPage';
import { RecoverPassword } from '~/recoverpassword';
import * as serviceWorkerRegistration from '~/serviceWorkerRegistration';
import store from '~/store';
import { SupportPage } from '~/support';
import { getTheme } from '~/theme';

import LuxonUtils from '@date-io/luxon';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import ConfirmEmail from './ConfirmEmail';
import { useSVSelector } from './redux/hooks';
import { createSelector } from 'reselect';
import { getQueryOrgAndSite } from '~/redux/selectors/global';

// window.Buffer = require('buffer/').Buffer // note: the trailing slash is important!

// Kill any old service workers
navigator?.serviceWorker?.getRegistrations()?.then(function (registrations) {
  for (const registration of registrations) {
    registration.unregister();
  }
});

serviceWorkerRegistration.unregister();

const selectActiveMarket = createSelector(
  (state) => state.global.preferences.test_market,
  (state) => state.global.organizations,
  getQueryOrgAndSite,
  (testMarket, allOrgs, currentOrgAndSite) => {
    return (
      testMarket ||
      (currentOrgAndSite.orgId
        ? allOrgs.find((x) => x.id == currentOrgAndSite.orgId)?.industry
        : null) ||
      'Generic'
    );
  }
);

function ScoutviewThemeProvider({ children }: { children?: React.ReactNode }) {
  const darkmode = useSVSelector((state) => state.global.preferences.darkmode);

  const market = useSVSelector(selectActiveMarket);

  setMarket(market);

  return <MuiThemeProvider theme={getTheme(darkmode, market)}>{children}</MuiThemeProvider>;
}
const ScoutReportPage = React.lazy(() => import('~/scoutReport'));
const loadingIndicator = <></>;

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ScoutviewThemeProvider>
        <CssBaseline />

        {/* This route exists so we can log to GA all our page accesses. */}
        <Route
          path='/'
          render={(_) => {
            // const pagename = (location.pathname + location.search).replace(/\/\d+/, '/<id>');
            // TODO: Log pagename
            return null;
          }}
        />

        <MuiPickersUtilsProvider utils={LuxonUtils}>
          <Switch>
            <Route path='/report/:reportId'>
              {/* TODO: Lazy load this */}
              {/* Reports can be visible without authentication, if they're finalized. Let the ScoutReport compoennt handle it */}
              <React.Suspense fallback={loadingIndicator}>
                <ScoutReportPage />
              </React.Suspense>
            </Route>

            <Route path='/support/:topic?'>
              {/* Support is always accessible.  */}
              <SupportPage />
            </Route>

            <Route path='/acceptinvite'>
              <AcceptInvite />
            </Route>

            <Route path='/confirm'>
              <ConfirmEmail />
            </Route>

            <Route path='/recover_password'>
              <StandbyPage showPhoto>
                <RecoverPassword />
              </StandbyPage>
            </Route>

            <Route>
              <App />
            </Route>
          </Switch>
        </MuiPickersUtilsProvider>
      </ScoutviewThemeProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
