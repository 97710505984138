import { DateTime } from 'luxon';
import React from 'react';
import history from '~/lib/history';
import { parseUTCISO } from '~/lib/time';

import { ListItem, ListItemText, Typography } from '@material-ui/core';

export default function FlightRow({ flight }) {
  const datestr = parseUTCISO(flight.date).toLocaleString(DateTime.DATE_MED);
  return (
    <ListItem dense button onClick={() => history.push(`/flight/${flight.id}`)}>
      <ListItemText
        primary={
          <Typography variant='body2'>
            {flight.farm_id}: <em>{flight.survey_name}</em>, Drone {flight.drone || 'N/A'}, Base{' '}
            {flight.base || 'N/A'}
          </Typography>
        }
        secondary={
          <Typography variant='caption'>
            <em>{datestr}</em>, {(flight.duration * 60).toFixed(1)}min
          </Typography>
        }
      />
    </ListItem>
  );
}
