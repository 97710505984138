import { byId, webrequest } from '~/lib/api';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Asset } from '~/schema';
import { RootState } from '~/store';
import { getQueryOrgAndSite } from '~/redux/selectors/global';

interface AssetsState {
  assets: { [key: string]: Asset };
  tmpAsset?: Asset;
  highlightedAssets: Asset[]; // Currently only a single asset can be highlighted array for future
  selectedAssets: Asset[]; // Currently only a single asset can be selected array for future
  pendingSingleAssets: { [key: string]: boolean }; // ids that we're retrieving are set to true
  assetUploadResult?: Asset | string;
  assetUploadPending?: boolean;
}

const assetsSceneInitialState: AssetsState = {
  assets: {}, // by id
  highlightedAssets: [], // Currently only a single asset can be highlighted array for future
  selectedAssets: [], // Currently only a single asset can be selected array for future
  pendingSingleAssets: {}, // ids that we're retrieving are set to true
};

export const fetchAssetsBySiteId = createAsyncThunk(
  'assets/fetchBySiteId',
  async (siteId: number | string, _) => {
    const data = await webrequest('GET', `sv/farm/${siteId}/assets`);
    return { siteId: siteId as string, data: data as { assets?: Asset[] } };
  }
);

export const fetchAssetById = createAsyncThunk('assets/fetchById', async (assetId: number, _) => {
  const data = await webrequest('GET', `assets/${assetId}`);
  return { assetId: assetId, data: data as Asset };
});

export const createTmpAsset = createAsyncThunk(
  'assets/createTmpAsset',
  async (assetId: number, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;

    if (assetId === 0) {
      return {
        id: assetId,
        site_id: getQueryOrgAndSite(state)?.siteId || '',
        deleted: false,
        display_name: '',
        type: '',
      } as Asset;
    }

    if (Object.keys(state.assetsScene.assets).includes(`${assetId}`)) {
      return state.assetsScene.assets[assetId];
    }

    if (assetId && `${assetId}`.length > 0) {
      const asset = await webrequest('GET', `assets/${assetId}`);
      return asset as Asset;
    }

    console.error('createTmpAsset encountered unexpected case');
    return {
      id: 0,
      site_id: '',
      deleted: false,
      display_name: '',
      type: '',
    } as Asset;
  }
);

export const uploadAsset = createAsyncThunk('assets/uploadAsset', async (asset: Asset, _) => {
  const cleanAsset = { ...asset, id: asset.id ? asset.id : null };
  const data = cleanAsset.id
    ? await webrequest('PUT', `assets/${cleanAsset.id}`, cleanAsset)
    : await webrequest('POST', `assets`, cleanAsset);
  return data as Asset;
});

const slice = createSlice({
  name: 'assets',
  initialState: assetsSceneInitialState,
  reducers: {
    setTmpAsset(state, action) {
      const { asset } = action.payload;
      state.tmpAsset = asset;
    },
    setHighlightedAssets(state, action: PayloadAction<Asset[]>) {
      const assets = action.payload;
      state.highlightedAssets = assets;
    },
    setSelectedAssets(state, action: PayloadAction<Asset[]>) {
      const assets = action.payload;
      state.selectedAssets = assets;
    },
    setUploadsPending(state, action: PayloadAction<boolean>) {
      state.assetUploadPending = action.payload;
    },
    setAssetUploadResult(state, action: PayloadAction<Asset | string | undefined>) {
      state.assetUploadResult = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchAssetsBySiteId.fulfilled,
      (state, action: PayloadAction<{ siteId: string; data: { assets?: Asset[] } }>) => {
        const { data } = action.payload;
        if (data.assets) Object.assign(state.assets, byId(data.assets.filter((a) => !a.deleted)));
      }
    );

    builder.addCase(fetchAssetById.pending, (state, action) => {
      const assetId = action.meta.arg;
      state.pendingSingleAssets[assetId] = true;
    });
    builder.addCase(
      fetchAssetById.fulfilled,
      (state, action: PayloadAction<{ assetId: number; data: Asset }>) => {
        const { assetId, data } = action.payload;
        delete state.pendingSingleAssets[assetId];
        state.assets[assetId] = data;
      }
    );

    builder.addCase(createTmpAsset.fulfilled, (state, action: PayloadAction<Asset>) => {
      const asset = action.payload;
      state.tmpAsset = asset;
    });

    builder.addCase(uploadAsset.pending, (state, _) => {
      state.assetUploadPending = true;
    });
    builder.addCase(uploadAsset.fulfilled, (state, action: PayloadAction<Asset>) => {
      state.assetUploadPending = false;

      const uploadedAsset = action.payload;
      if (uploadedAsset.deleted) {
        const assetsCopy = state.assets;
        delete assetsCopy[uploadedAsset.id];
        state.assets = assetsCopy;
        state.assetUploadResult = uploadedAsset;
      } else {
        state.assets[uploadedAsset.id] = uploadedAsset;
        state.assetUploadResult = uploadedAsset;
      }
    });
    builder.addCase(uploadAsset.rejected, (state, action) => {
      state.assetUploadPending = false;
      state.assetUploadResult = action.error.message;
    });
  },
});

export default slice.reducer;

export const actions = {
  ...slice.actions,
  fetchAssetsBySiteId,
  uploadAsset,
  fetchAssetById,
};
