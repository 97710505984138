import classNames from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';
import { connect } from 'react-redux';
import * as Icons from '~/icons';
import { getColorForNDVI } from '~/lib/colorScales';
import * as geography from '~/lib/geography';
import { parseUTCISO } from '~/lib/time';
import { setTimelineTime } from '~/lib/timeline';
import { resolutionToStr } from '~/lib/units';
import { toLocalTz } from '~/redux/commonSelectors';
import { actions as globalActions } from '~/redux/slices/global';
import { actions as uiActions } from '~/redux/slices/ui';

import { Chip, ListItem, Tooltip, Typography, withStyles } from '@material-ui/core';

import { regionColors } from '../../theme';
import ResChip from './ResChip';
import { getCurrentImageset } from './selectors';

const styles = (theme) => ({
  root: {
    textDecoration: 'none',
    paddingLeft: 8,
    paddingRight: 4,
    borderLeft: '4px solid transparent',
  },

  hidden: {
    opacity: 0.5,
    backgroundImage:
      'repeating-linear-gradient(45deg, rgba(0,0,0,0.1) 0px,  rgba(0,0,0,0.1) 25px, transparent 25px, transparent 50px, gray 50px)',
  },

  textArea: {
    alignSelf: 'stretch',
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
  },
});

function TargetedLink({
  setTimelineTime,
  toLocalTz,
  imageset,
  classes,
  farmId,
  zoomToBounds,
  currentImageset,

  ...props
}) {
  const localdatetime = toLocalTz(parseUTCISO(imageset.timestamp), farmId);
  const localDate = localdatetime.toLocaleString(DateTime.DATE_SHORT);
  const localTime = localdatetime.toLocaleString(DateTime.TIME_24_WITH_SHORT_OFFSET);

  const mpp = imageset.actual_resolution;
  const isHidden = imageset.hidden;
  const isActive = imageset.timestamp == currentImageset.timestamp;

  return (
    <ListItem
      selected={isActive}
      button
      onClick={() => {
        setTimelineTime(0, imageset.timestamp);

        zoomToBounds(geography.bboxOfWKT(imageset.region.boundary));
      }}
      className={classNames(classes.root, { [classes.hidden]: isHidden })}
      {...props}>
      <Tooltip title={resolutionToStr(mpp)}>
        <Icons.Targeted
          style={{ color: regionColors.TARGETED, width: 24, marginRight: 8, marginLeft: -8 }}
        />
      </Tooltip>

      <Tooltip title={localTime}>
        <div className={classes.textArea}>
          <Typography variant='subtitle2'>{localDate}</Typography>
        </div>
      </Tooltip>
      <div style={{ flexGrow: 1 }} />

      <ResChip mpp={mpp} />
    </ListItem>
  );
}

const mapStateToProps = (state) => ({
  setTimelineTime: setTimelineTime(state),
  toLocalTz: toLocalTz(state),
  currentImageset: getCurrentImageset(state, 0),
});

const mapDispatchToProps = (dispatch) => ({
  zoomToBounds: (b) => dispatch(uiActions.zoomToBounds(b)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TargetedLink));
