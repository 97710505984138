import { DateTime } from 'luxon';
import SunCalc from 'suncalc';

export const PX_PER_HR = 96;
export const DAYS_VISIBLE = 7;

export function dtToPixel(
  dt: DateTime,
  startHour: number,
  endHour: number,
  fromBottom = false
): number {
  const hr = dt.hour + dt.minute / 60;

  if (!fromBottom) {
    return (hr - startHour) * PX_PER_HR;
  } else {
    return (endHour - hr) * PX_PER_HR;
  }
}

// Calculate an approximate sunrise/noon/sunset time.
export function getSunTimes(day: DateTime, location: [number, number]): SunCalc.GetTimesResult {
  return SunCalc.getTimes(day.toJSDate(), location[1], location[0]);
}
