import * as api from '~/lib/api';
import { filterText } from '~/lib/filtering';
import Logging from '~/logging';
import { getQueryOrgAndSite, getFarmVisibilitySteps } from '~/redux/selectors/global';
import { Farm, Region } from '~/schema';
import type { SVImageSet, SVTargetNotif } from '~/schema';
import type { RootState, SVThunkAction } from '~/store';

import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';

interface FieldData {
  id: string;
  region: Region;
  target_notifs: SVTargetNotif[];
  latest_imageset: SVImageSet;
}

interface OverviewState {
  farmOverviews: {
    [id: string]: {
      pending: boolean;
      loading: boolean;
      error?: string;
      fields: FieldData[];
    };
  };
}

const initialState = {
  farmOverviews: {},
} as OverviewState;

const slice = createSlice({
  name: 'overviewScene',
  initialState,
  reducers: {
    begin(state, action: PayloadAction<{ id: string }>) {
      const { id } = action.payload;
      state.farmOverviews[id] = { pending: true, loading: false, error: undefined, fields: [] };
    },
    loading(state, action: PayloadAction<{ id: string }>) {
      const { id } = action.payload;
      state.farmOverviews[id] = { pending: true, loading: true, error: undefined, fields: [] };
    },
    success(state, action: PayloadAction<{ id: string; fields: FieldData[] }>) {
      const { id, fields } = action.payload;
      state.farmOverviews[id] = { pending: false, loading: false, error: undefined, fields };
    },
    failure(state, action: PayloadAction<{ id: string; error: string }>) {
      const { id, error } = action.payload;
      state.farmOverviews[id] = { pending: false, loading: false, error, fields: [] };
    },
  },
});
export default slice.reducer;

export const sitesAtSelectedOrg = createSelector(
  getQueryOrgAndSite,
  (state: RootState) => state.global.farms,
  (currentOrgAndSite, allSites) => {
    const visibleIds = Object.values(allSites)
      .filter((x) => x.grower_id == currentOrgAndSite.orgId)
      .map((x) => x.id);

    const sites: Record<string, Farm> = {};
    for (const id of visibleIds) {
      sites[id] = allSites[id];
    }
    return sites;
  }
);

export const visibleSites = createSelector(
  getQueryOrgAndSite,
  (state: RootState) => state.global.farms,
  (state: RootState) => state.global.preferences.hiddenFarmIds,
  (currentOrgAndSite, allSites, hiddenFarmIds) => {
    const visibleIds = Object.values(allSites)
      .filter((x) => x.grower_id == currentOrgAndSite.orgId && !hiddenFarmIds.includes(x.id))
      .map((x) => x.id);
    const sites: Record<string, Farm> = {};
    for (const id of visibleIds) {
      sites[id] = allSites[id];
    }
    return sites;
  }
);

export const allVisibleFieldData = createSelector(
  visibleSites,
  (state: RootState) => state.overviewScene.farmOverviews,
  getFarmVisibilitySteps,
  (farms, overviews, isVisible) => {
    const fields: FieldData[] = [];
    // Accumulate visible farm fields
    Object.values(farms)
      .filter(
        (f) =>
          isVisible[f.id] == 2 &&
          overviews[f.id] &&
          !overviews[f.id].pending &&
          !overviews[f.id].error
      )
      .forEach((f) => {
        fields.push(...(overviews[f.id].fields || []));
      });
    return fields;
  }
);

export const filteredFieldData = createSelector(
  (state) => state.ui.overviewPageFilter,
  (state) => state.ui.hideCropTypes,
  allVisibleFieldData,
  (filterValue, hideCropTypes, fieldData) => {
    const filtered = fieldData.filter(
      (r) =>
        filterText(r.region.display_name, filterValue) &&
        !hideCropTypes.includes(r.region.crop_type || 'Unspecified')
    );
    filtered.sort((a, b) =>
      a.region.display_name.localeCompare(b.region.display_name, undefined, {
        numeric: true,
      })
    );
    return filtered;
  }
);

export const getFarmOverview =
  (farm_id: string): SVThunkAction =>
  async (dispatch) => {
    dispatch(slice.actions.begin({ id: farm_id }));

    const showLoading = setTimeout(() => dispatch(slice.actions.loading({ id: farm_id })), 500);

    try {
      const data = (await api.webrequest('GET', `sv/farmOverview/${farm_id}`)) as {
        fields: FieldData[];
      };

      clearTimeout(showLoading);
      dispatch(slice.actions.success({ id: farm_id, fields: data.fields }));
    } catch (e) {
      Logging.error('Failed to get farm overview', e);

      clearTimeout(showLoading);
      dispatch(slice.actions.failure({ id: farm_id, error: `${e}` }));
    }
  };

export const actions = {
  ...slice.actions,
  getFarmOverview,
};
