import queryString from 'query-string';
import { createSelector } from 'reselect';
import * as globalSelctors from '~/redux/selectors/global';
import { paramsForPath } from '~/redux/selectors/global';

export const matches = (state) => paramsForPath(state, '/part/:type/:sn');

export const typecode = (type, sn) => `${type}:${sn}`;

const typecodeSelector = createSelector(matches, (params) => typecode(params.type, params.sn));

export const partData = createSelector(
  typecodeSelector,
  (state) => state.partScene.parts,
  (typecode, parts) => parts[typecode]
);

export const partEdits = createSelector(
  typecodeSelector,
  (state) => state.partScene.edits,
  (typecode, edits) => edits[typecode]
);

export const subassemblyEdits = createSelector(partEdits, (edits) => edits?.subassemblies || {});

export const isEdited = createSelector(partEdits, (edits) => {
  const editedParams = Object.keys(edits?.parameters || {});
  const editedSubs = Object.keys(edits?.subassemblies || {});
  const editedBadges = Object.keys(edits?.badges || {});
  return editedParams.length + editedSubs.length + editedBadges.length > 0;
});
