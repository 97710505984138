import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showSnackbar } from '~/lib/modalServices';
import Logging from '~/logging';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  TextField,
  Typography,
} from '@material-ui/core';

import { actions } from './slice';

const DEFAULT_SEATS = {
  admin: null,
  user: null,
};
function titleCase(str) {
  return str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
}
export default function ChangeSeatAllowanceDialog({ open, onClose, orgid }) {
  const [newSeats, setNewSeats] = React.useState(DEFAULT_SEATS);
  const dispatch = useDispatch();

  // Get data from redux
  const totalSeats = {
    admin: useSelector((state) => state.orgScene.orgs[orgid]?.total_admin_seats || 0),
    user: useSelector((state) => state.orgScene.orgs[orgid]?.total_user_seats || 0),
  };
  const usedSeats = {
    admin: useSelector(
      (state) => state.orgScene.orgs[orgid]?.seats?.filter((s) => s.type == 'ADMIN').length || 0
    ),
    user: useSelector(
      (state) => state.orgScene.orgs[orgid]?.seats?.filter((s) => s.type == 'USER').length || 0
    ),
  };

  let seats = {};
  for (const type of Object.keys(newSeats)) {
    seats[type] = newSeats[type] === null ? totalSeats[type] : newSeats[type];
  }

  const submitChange = async () => {
    try {
      // TODO:
      dispatch(actions.changeSeatAllowance(orgid, seats));
      // Action to submit new org to our list

      onClose();
    } catch (error) {
      Logging.error('Failed to update seats', error);
      showSnackbar('error', 'Could not update seats.');
    }
  };

  const handleConfirm = () => {
    submitChange();
  };

  const handleCancel = () => {
    setNewSeats(DEFAULT_SEATS);
    onClose(null);
  };

  const setField = (field, value) => {
    setNewSeats({ ...newSeats, [field]: value });
  };

  return (
    <Dialog open={open} onClose={handleCancel} disableBackdropClick disableEscapeKeyDown>
      <DialogTitle>Change Seat Allowance</DialogTitle>

      <DialogContent style={{ overflowY: 'auto' }}>
        <List>
          <ListItem>
            <Typography variant='body'>
              As an administrator, you may change the number of users to which an organization may
              grant access to their ScoutView data.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant='body'>
              If you decrease the seat count below the current number of users, it will not revoke
              anyone&apos;s access, but it will prevent new invitations until the number of active
              users decreases below the seat count.
            </Typography>
          </ListItem>
          <Divider />
          {Object.keys(newSeats).map((type) => (
            <ListItem key={type}>
              <TextField
                fullWidth
                label={titleCase(type) + ' Seats'}
                type='number'
                value={seats[type]}
                onChange={(e) => setField(type, parseInt(e.target.value))}
                InputLabelProps={{ shrink: true }}
                inputProps={{ min: 0 }}
                helperText={`${titleCase(type)} seats in use: ${usedSeats[type]}`}
                error={usedSeats[type] > seats[type]}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color='primary'>
          Cancel
        </Button>
        <Button onClick={handleConfirm} color='primary'>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
