import * as api from '~/lib/api';
import { showSnackbar } from '~/lib/modalServices';
import Logging from '~/logging';
import { actions as noteActions } from '~/redux/slices/notes';
import { fromjson as fromNoteJson } from '~/schema/Note';

import { createSlice } from '@reduxjs/toolkit';

// Description of initial state
const slice = createSlice({
  name: 'fieldOverview',
  initialState: {
    fields: {
      /* regionId: { 
        pending: bool, // is waiting for a response
        loading: bool, // should show a loading indicator
        error: null / str, 
        
        creatingTargetedSurvey: bool,
        creatingTargetedSurveyError

        creatingZone
        creatingZoneError

        tmpZoneDisplayName: '',
        tmpZoneGridDiv: undefined,
        
        region,
        target_notifs,
        imagesets,
        summary,
      } 
    */
    },

    mapState: null, // can be 'addNote', 'drawTarget', 'showTarget', etc.

    filterRules: { res: [0, 100], showHidden: false },
  },
  reducers: {
    requestBegin(state, action) {
      const regionId = action.payload;
      state.fields[regionId] = {
        pending: true,
        loading: false,
        error: null,
      };
    },
    requestLoading(state, action) {
      const regionId = action.payload;
      state.fields[regionId] = {
        pending: true,
        loading: true,
        error: null,
      };
    },
    requestSuccess(state, action) {
      const { regionId, data } = action.payload;
      state.fields[regionId] = {
        pending: false,
        loading: false,
        error: null,
        keepouts: [],
        farm_fields: [],
        imagesets: [],
        permissions: [],
        region: {},
        ...data,
      };
    },
    requestFailure(state, action) {
      const { regionId, error } = action.payload;
      state.fields[regionId] = {
        pending: false,
        loading: false,
        error,
      };
    },

    createTargetedSurveyBegin(state, action) {
      const { fieldId } = action.payload;
      state.fields[fieldId].creatingTargetedSurvey = true;
      state.fields[fieldId].creatingTargetedSurveyError = null;
    },
    createTargetedSurveyComplete(state, action) {
      const { fieldId, success } = action.payload;
      state.fields[fieldId].creatingTargetedSurvey = false;
      state.fields[fieldId].creatingTargetedSurveyError = !success;
    },
    createZoneBegin(state, action) {
      const { fieldId } = action.payload;
      state.fields[fieldId].creatingZone = true;
      state.fields[fieldId].creatingZoneError = null;
    },
    createZoneComplete(state, action) {
      const { fieldId, success, zone } = action.payload;
      state.fields[fieldId].creatingZone = false;
      state.fields[fieldId].creatingZoneError = !success;
      if (zone) {
        if (state.fields[fieldId].zones?.length > 0) {
          state.fields[fieldId].zones.push(zone);
        } else {
          state.fields[fieldId].zones = [zone];
        }
      }
    },

    deleteZoneComplete(state, action) {
      const { fieldId, zid, success } = action.payload;
      if (success) {
        // Remove from our local list if the deletion was a success
        state.fields[fieldId].zones = state.fields[fieldId].zones?.filter((z) => z.id != zid) || [];
      }
    },

    setMapState(state, action) {
      state.mapState = action.payload;
    },
    setFilterRules(state, action) {
      state.filterRules = { ...state.filterRules, ...action.payload };
    },

    setTmpZoneGridDiv(state, action) {
      const { fieldId, gridDiv } = action.payload;
      state.fields[fieldId].tmpZoneGridDiv = gridDiv;
    },
    setTmpZoneDisplayName(state, action) {
      const { fieldId, displayName } = action.payload;
      state.fields[fieldId].tmpZoneDisplayName = displayName;
    },
  },
});

const getFieldData = (regionId) => async (dispatch) => {
  dispatch(slice.actions.requestBegin(regionId));

  const showLoading = setTimeout(() => dispatch(slice.actions.requestLoading(regionId)), 500);

  try {
    const data = await api.webrequest('GET', `sv/field/${regionId}`);
    clearTimeout(showLoading);
    dispatch(slice.actions.requestSuccess({ regionId, data }));

    // Any notes that come in get loaded into the notes slice
    let notes = [];
    for (const imageset of data.imagesets || []) {
      if (imageset.notes) {
        notes.push(...imageset.notes);
      }
    }
    if (notes) {
      dispatch(noteActions.consumeNotes(notes.map((n) => fromNoteJson(n))));
    }
  } catch (error) {
    Logging.error('Failed to load field', error);
    clearTimeout(showLoading);
    dispatch(slice.actions.requestFailure({ regionId, error }));
  }
};

// This only works when you've got a survey with one explicit target!
const createTargetedSurvey = (survey, fieldId) => async (dispatch) => {
  const name = survey.display_name;

  dispatch(slice.actions.createTargetedSurveyBegin({ fieldId }));

  const tgtRequest = survey.target_requests[0];
  try {
    const tgtRegion = {
      id: null,
      type: 'TARGETED',
      farm_id: survey.farm_id,
      boundary: tgtRequest.boundary,
      display_name: name,
      field_id: fieldId,
    };

    const newRegion = await api.webrequest('POST', 'regions', tgtRegion);

    // Now create the survey
    const newSurvey = await api.webrequest('POST', 'surveys', {
      ...survey,
      target_requests: undefined,
    });

    // Create the target request
    await api.webrequest('POST', 'target_requests', {
      ...tgtRequest,
      boundary: undefined,
      flight_region_id: newRegion.id,
      survey_id: newSurvey.id,
    });

    dispatch(slice.actions.createTargetedSurveyComplete({ fieldId, success: true }));
  } catch (e) {
    Logging.error('Failed creating targeted survey', e);
    dispatch(slice.actions.createTargetedSurveyComplete({ fieldId, success: false }));

    return;
  }
};

const createZone =
  ({ zone, fieldId }) =>
  async (dispatch) => {
    dispatch(slice.actions.createZoneBegin({ fieldId }));
    try {
      const newZone = await api.webrequest('POST', 'regions', zone);
      dispatch(slice.actions.createZoneComplete({ fieldId, success: true, zone: newZone }));
    } catch (e) {
      Logging.error('Failed creating zone', e);
      dispatch(slice.actions.createZoneComplete({ fieldId, success: false }));
    }
  };

const deleteZone =
  ({ fieldId, zid }) =>
  async (dispatch) => {
    // dispatch(slice.actions.deleteZoneBegin({ zid }))
    try {
      await api.webrequest('DELETE', `regions/${zid}`);
      dispatch(slice.actions.deleteZoneComplete({ zid, fieldId, success: true }));
      showSnackbar('success', 'Zone deleted');
    } catch (e) {
      Logging.error('Failed deleting zone', e);
      dispatch(slice.actions.deleteZoneComplete({ zid, fieldId, success: false }));
      showSnackbar('error', 'Failed to delete zone');
    }
  };

export default slice.reducer;

export const actions = {
  ...slice.actions,
  getFieldData,
  createTargetedSurvey,
  createZone,
  deleteZone,
};
