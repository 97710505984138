import React from 'react';

import { Grid, List, ListItem, ListItemText, Paper } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

import FlightRow from './FlightRow';

export default function FlightHistory({ flights }) {
  const [page, setPage] = React.useState(0);
  const perPage = 5;
  const pages = Math.ceil((flights?.length || 0) / perPage);

  const slice = flights?.slice(page * perPage, (page + 1) * perPage) || [];

  const totalDuration = flights.reduce((hrs, f) => hrs + f.duration, 0);

  return (
    <Paper>
      <List>
        <ListItem>
          <ListItemText
            primary='Flight History'
            secondary={`${flights?.length || 0} flights, ${totalDuration.toFixed(2)} total hrs`}
          />
        </ListItem>
        {slice.map((f) => (
          <FlightRow key={f.id} flight={f} />
        ))}

        <ListItem>
          <Pagination
            count={pages}
            page={page + 1}
            onChange={(event, page) => {
              setPage(page - 1);
            }}
          />
        </ListItem>
      </List>
    </Paper>
  );
}
