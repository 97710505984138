import queryString from 'query-string';
import { createSelector } from 'reselect';
import store from '~/store';

import { actions } from './slice';

export const selectedUserId = createSelector(
  (state) => state.router.location.search,
  (query) => {
    const rval = queryString.parse(query).user || null;
    return rval;
  }
);

const newUser = {
  id: null,
  display_name: '',
  deleted: false,
  preferred_units: 'IMPERIAL',
};

export const selectedUser = createSelector(
  selectedUserId,
  (state) => state.usersScene.users,
  (userId, allUsers) => (userId == 'new' ? newUser : allUsers[userId])
);

// Will return the draft region, or the original if no change.
export const getTmpUser = createSelector(
  selectedUserId,
  selectedUser,
  (state) => state.usersScene.tmpUsers,
  (userid, user, tmpUsers) => {
    return selectedUser !== null ? tmpUsers[userid] || user : null;
  }
);
