import React from 'react';
import SnackbarService, { SnackbarPayload, SnackbarSeverity } from '~/lib/SnackbarService';
import { SupportDialogHost } from '~/support';
import { HelpTopicPayload } from '~/support/SupportDialogHost';

import ConfirmDialog, { ConfirmPayload, ConfirmPayloadCallback } from './ConfirmDialog';
import InputDialog, { InputDialogPayload, InputDialogPayloadCallback } from './InputDialog';
import {GAevent} from '~/googleAnalytics'; 

let _showConfirmDialog: (payload: ConfirmPayload) => Promise<boolean>;
let _showSnackbar: (payload: SnackbarPayload) => void;
let _showInputDialog: (payload: InputDialogPayload) => Promise<string | null>;
let _showHelptopic: (payload: HelpTopicPayload) => void;

export default function ModalServices() {
  const [showConfirmDialog, setShowConfirmDialog] = React.useState<ConfirmPayloadCallback>();
  const [showInputDialog, setShowInputDialog] = React.useState<InputDialogPayloadCallback>();
  const [showSnackbar, setShowSnackbar] = React.useState<SnackbarPayload>();
  const [showHelp, setShowHelp] = React.useState<HelpTopicPayload>();

  _showConfirmDialog = (payload) => {
    return new Promise((resolve) => setShowConfirmDialog({ ...payload, callback: resolve }));
  };
  const clearConfirmDialog = () => setShowConfirmDialog(undefined);

  _showSnackbar = setShowSnackbar;

  _showInputDialog = (payload) => {
    return new Promise((resolve) => setShowInputDialog({ ...payload, callback: resolve }));
  };

  _showHelptopic = setShowHelp;

  return (
    <>
      <ConfirmDialog payload={showConfirmDialog} close={clearConfirmDialog} />
      <InputDialog payload={showInputDialog} onClose={() => setShowInputDialog(undefined)}/>
      <SnackbarService payload={showSnackbar} onClear={() => setShowSnackbar(undefined)} />
      <SupportDialogHost payload={showHelp} onClose={() => setShowHelp(undefined)} />
    </> 
  );
}

export async function showConfirmDialog(title: string, message: string, confirm: string) {
  return await _showConfirmDialog?.({ title, message, confirm });
}

export function showSnackbar(severity: SnackbarSeverity, message: string) {
  return _showSnackbar?.({ severity, message });
}

export async function showInputDialog(
  title: string,
  message: string,
  confirm: string,
  label: string,
  type: string
) {
  return await _showInputDialog?.({ title, message, confirm, label, type });
}

export function showHelpTopic(topic: string, history?: string[]) {
  _showHelptopic?.({ topic, history: history || [topic] });
  GAevent('Click', 'Help button clicked', 'showHelpTopic');
}
