import classNames from 'classnames';
import React from 'react';
import * as Icons from '~/icons';

import { Fade, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

const styles = makeStyles((theme) => ({
  panel: {
    // userSelect: 'none',
    marginBottom: 2,
    borderRadius: 4,
    background: fade(theme.palette.background.paper, 0.7),

    overflow: 'hidden',

    zIndex: 1,
    width: 'var(--collapsed-width)',
    height: `calc(${theme.spacing(3.5)}px + var(--collapsed-height))`, // the 3.5 here is for the titlebar
    transition: 'height 0.3s ease, width 0.3s ease, background 0.1s linear, box-shadow 0.1s linear',

    boxShadow: [[2, 0, 8, 'transparent']],

    position: 'relative',

    opacity: 0.6,

    '&.enabled': {
      cursor: 'pointer',
      opacity: 1,

      '&:hover': {
        background: fade(theme.palette.background.paper, 0.92),
        boxShadow: [[2, 0, 8, 'black']],
        zIndex: 100,
      },
    },

    '&.expanded': {
      width: 'var(--detail-width)', //theme.spacing(36),
      height: `calc(var(--detail-height) + var(--collapsed-height) + ${theme.spacing(3.5)}px)`, //theme.spacing(23),
    },
  },

  collapsedArea: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,

    cursor: 'auto',
  },

  detailArea: {
    position: 'absolute',
    background: theme.palette.background.paper,
    top: theme.spacing(3.5),
    left: 0,
    cursor: 'auto',

    // We need explicit w/h here so that as it animates in,
    // the children know what height they're going to be when it's full.
    // Avoid some weird canvas redraw issues.
    height: `var(--detail-height)`,
    width: `var(--detail-width)`,
  },

  title: {
    position: 'absolute',
    top: theme.spacing(0.5),
    left: theme.spacing(4),
    userSelect: 'none',
  },

  icon: {
    position: 'absolute',
    top: 2,
    left: 2,
    opacity: 0.87,
  },
  toggleIcon: {
    position: 'absolute',
    top: 2,
    right: 2,
    opacity: 0.6,
  },
}));

export default function MapLegendControlContainer({
  title,
  Icon,
  alwaysVisible,
  detailView,
  collapsed_width,
  collapsed_height,
  detail_width,
  detail_height,
  disabled,
}) {
  const classes = styles();

  const [expanded, setExpanded] = React.useState(false);

  return (
    <div
      className={classNames(classes.panel, { enabled: !disabled, expanded: expanded && !disabled })}
      style={{
        '--collapsed-width': `${collapsed_width}px`,
        '--collapsed-height': `${collapsed_height}px`,
        '--detail-width': `${detail_width}px`,
        '--detail-height': `${detail_height}px`,
      }}
      onClick={() => {
        if (!disabled) setExpanded(!expanded);
      }}>
      <Typography className={classes.title} color='inherit' align='center' variant='caption'>
        {title}
      </Typography>

      <Icon size='small' className={classes.icon} />

      {!disabled &&
        (expanded ? (
          <Icons.Minimize size='small' className={classes.toggleIcon} />
        ) : (
          <Icons.Maximize size='small' className={classes.toggleIcon} />
        ))}

      <div className={classes.collapsedArea} onClick={(e) => e.stopPropagation()}>
        {alwaysVisible}
      </div>

      <Fade in={expanded && !disabled} mountOnEnter unmountOnExit>
        <div className={classes.detailArea} onClick={(e) => e.stopPropagation()}>
          {detailView}
        </div>
      </Fade>
    </div>
  );
}
