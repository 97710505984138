import React from 'react';

import { Grow, Typography } from '@material-ui/core';

import MapContext from '../mapContext';
import DraggableMarker from './DraggableMarker';

const arRed = '#f44336';

export default function Base({ name, coordinate, heading, pending, draggable, ...other }) {
  return (
    <MapContext.Consumer>
      {(map) => (
        <Grow in>
          <DraggableMarker
            {...other}
            draggable={draggable}
            style={{ pointerEvents: 'none' }}
            coordinate={coordinate}
            anchor='top-left'
            offset={[-20, -20]}
            map={map}>
            <Typography
              variant='caption'
              align='center'
              style={{
                position: 'absolute',
                top: 40,
                width: '100%',
                color: 'white',
                textShadow: '0 0 5px black',
                lineHeight: '1em',
                pointerEvents: 'none',
              }}>
              {name}
            </Typography>

            <svg width='40' height='40'>
              <defs>
                <filter id='dropshadow' x='-20%' y='-20%' width='200%' height='200%'>
                  <feGaussianBlur in='SourceAlpha' stdDeviation='3' />
                  <feOffset dx='0' dy='2' result='offsetblur' />
                  <feComponentTransfer>
                    <feFuncA type='linear' slope='0.8' />
                  </feComponentTransfer>
                  <feMerge>
                    <feMergeNode />
                    <feMergeNode in='SourceGraphic' />
                  </feMerge>
                </filter>
              </defs>
              <rect
                filter={draggable ? 'url(#dropshadow)' : undefined}
                x='5'
                y='5'
                width='30'
                height='30'
                fill='lightgray'
                stroke={pending ? arRed : 'white'}
                strokeWidth={draggable ? 4 : 2}
                transform={`rotate(${heading || 0}, 30,30)`}
                opacity={draggable ? 1 : 0.8}
              />
              <g transform='translate(8,8)'>
                <path
                  fill={arRed}
                  d='m 12.827035,14.613932 -5.8105075,0 c 0,0 2.762692,-7.3724911 2.8600946,-7.6452184 L 8.0312856,2.2987016 -1.7228e-6,21.823496 l 4.2095633228,0 1.4592681,-3.761512 8.5005903,0'
                />
                <path
                  fill='#222'
                  d='m 20.635182,14.385479 c 2.454546,-1.113932 3.364817,-3.320543 3.364817,-5.6422671 0,-3.9580875 -2.891971,-6.5684773 -7.719599,-6.5684773 l -4.27863,0 1.434474,3.6499412 3.347108,0 c 2.125148,0 3.171783,1.3760331 3.171783,2.9185361 0,1.4876031 -1.011216,2.8919721 -3.171783,2.8919721 l -1.062574,0 3.951004,10.190083 3.917356,0 z'
                />
              </g>
              {/* 
              {heading && (
                <polygon
                  points='30,15 20,45 40,45'
                  fill='darkgray'
                  stroke='white'
                  transform={`rotate(${heading}, 30,30)`}
                />
              )} */}
            </svg>
          </DraggableMarker>
        </Grow>
      )}
    </MapContext.Consumer>
  );
}
