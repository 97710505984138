import axios from 'axios';
import * as api from '~/lib/api';
import { showSnackbar } from '~/lib/modalServices';
import Logging from '~/logging';
import { ACTION_NAMES } from '~/scenes/part/ActionBar';

import { createSlice } from '@reduxjs/toolkit';

import { typecode } from './selectors';

const slice = createSlice({
  name: 'part',
  initialState: {
    parts: {}, // indexed by typecode
    // { isFetching: bool, ... }

    edits: {}, // indexed by typecode
    // { parameters: ..., subassemblies: ..., badges: ...}
  },

  reducers: {
    setFetching(state, action) {
      const { type, sn } = action.payload;
      state.parts[typecode(type, sn)] = { isFetching: true };
    },

    updateData(state, action) {
      const { type, sn, data } = action.payload;
      state.parts[typecode(type, sn)] = {
        isFetching: false,
        ...data,
      };
      // when we get new data, clear edits (for all parts, why not)
      state.edits = {};
    },

    editParameter(state, action) {
      const { type, sn, parameter, value } = action.payload;
      const code = typecode(type, sn);

      if (value == null) {
        // remove the key, don't set to null
        delete state.edits[code].parameters[parameter];
      } else {
        state.edits[code] = {
          ...state.edits[code],
          parameters: { ...state.edits[code]?.parameters, [parameter]: value },
        };
      }
    },

    editSubassembly(state, action) {
      const { type, sn, sasm_key, sasm_type, sasm_sn } = action.payload;
      const code = typecode(type, sn);

      state.edits[code] = {
        ...state.edits[code],
        subassemblies: {
          ...state.edits[code]?.subassemblies,
          [sasm_key]: sasm_type ? { type: sasm_type, sn: sasm_sn } : null,
        },
      };
    },

    revertSubassembly(state, action) {
      const { type, sn, sasm_key } = action.payload;
      const code = typecode(type, sn);

      delete state.edits[code].subassemblies[sasm_key];
    },
  },
});

const getPartData = (type, sn) => async (dispatch) => {
  dispatch(slice.actions.setFetching({ type, sn }));

  const data = await api.webrequest('GET', `sv/hardware/${type}/${sn}`);

  dispatch(
    slice.actions.updateData({
      type,
      sn,
      data,
    })
  );
};

const doPartAction =
  (type, sn, report_id, action, changelog, parameters, subassemblies, files) =>
  async (dispatch) => {
    try {
      // If we have 'files' and a report, upload them as attachments to the current report.

      if (files && files.length > 0 && report_id) {
        const comment = await api.webrequest('POST', `sv/hardware/report/${report_id}/comment`, {
          message: `Submitted during: ${ACTION_NAMES[action]}. ${changelog}`,
        });

        const formData = new FormData();

        for (const file of files) {
          formData.append('file[]', file);
        }

        const att = await axios({
          method: 'POST',
          url: `${api.cloudURL}/sv/hardware/comment/${comment.id}/attachment`,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
          data: formData,
        });
      }

      const data = await api.webrequest('POST', `sv/hardware/${type}/${sn}/${action}`, {
        parameters: { changelog: changelog, ...parameters }, // TODO: Other parameters, subassemblies we're changing
        subassemblies,
      });

      showSnackbar('success', 'Part record updated.');

      dispatch(getPartData(type, sn));
    } catch (error) {
      Logging.error('Could not update part record.', error);
      showSnackbar('error', 'Could not update part record.');
    }
  };

const doForceAddReport = (type, serial_number, report_type, files) => async (dispatch) => {
  try {
    if (!files && files.length == 0) {
      return;
    }

    // post forceaddreport, gets teh report
    const report = await api.webrequest('POST', `sv/hardware/report/force_add`, {
      type,
      serial_number,
      report_type,
    });

    const comment = await api.webrequest('POST', `sv/hardware/report/${report.id}/comment`, {
      message: `Submitted directly.`,
    });

    const formData = new FormData();

    for (const file of files) {
      formData.append('file[]', file);
    }

    const att = await axios({
      method: 'POST',
      url: `${api.cloudURL}/sv/hardware/comment/${comment.id}/attachment`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      withCredentials: true,
      data: formData,
    });

    // Refresh the page
    dispatch(getPartData(type, serial_number));
  } catch (error) {
    Logging.error('Could not force add report.', error);
    showSnackbar('error', 'Could not force add report.');
  }
};

export default slice.reducer;

export const actions = {
  ...slice.actions,
  getPartData,
  doPartAction,
  doForceAddReport,
};
