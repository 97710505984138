import { byId, webrequest } from '~/lib/api';
import history from '~/lib/history';
import { showSnackbar } from '~/lib/modalServices';
import { parseUTCISO } from '~/lib/time';
import Logging from '~/logging';

import { createSlice } from '@reduxjs/toolkit';

function standardizeUser(u) {
  let tweaked = {};
  for (const k of Object.keys(u)) {
    switch (k) {
      case 'last_login':
      case 'last_activity':
      case 'created':
      case 'last_updated':
        tweaked[k] = u[k] ? parseUTCISO(u[k]) : null;
        break;
      default:
        tweaked[k] = u[k];
    }
  }
  tweaked.preferred_units = tweaked.preferred_units || 'IMPERIAL';
  return tweaked;
}

function withRoleAssignments(u, ra) {
  u = { ...u, roles: ra.filter((r) => r.user_id == u.id) };
  return u;
}

// Description of initial state
const slice = createSlice({
  name: 'users',
  initialState: {
    users: {}, // by id
    roles: [],
    possibleTargets: [],

    tmpUsers: {}, // by user id or 'new'

    filterValue: null,
  },

  reducers: {
    setFilterValue(state, action) {
      state.filterValue = action.payload;
    },
    requestBegin(state, action) {
      state.pending = true;
      state.loading = false;
      state.error = null;
    },
    requestLoading(state, action) {
      state.loading = true;
    },
    requestComplete(state, action) {
      const { data, error } = action.payload;

      state.pending = false;
      state.loading = false;
      state.error = error;

      if (data) {
        state.users = byId(
          data.users.map((u) => standardizeUser(withRoleAssignments(u, data.roleAssignments)))
        );
        state.roles = data.roles;
        state.possibleTargets = data.possible_targets;
      }
    },

    setTmpUser(state, action) {
      const { id, user } = action.payload;
      state.tmpUsers[id] = user;
    },

    uploadBegin(state, action) {},
    uploadComplete(state, action) {
      const { id, user, error } = action.payload;
      if (user) {
        state.users[id] = standardizeUser(user);
        delete state.tmpUsers[id];
      } else {
        // handle error?
      }
    },
  },
});

const getUsers = () => async (dispatch) => {
  dispatch(slice.actions.requestBegin());

  const showLoading = setTimeout(() => dispatch(slice.actions.requestLoading()), 500);

  try {
    const data = await webrequest('GET', `admin/users`);
    clearTimeout(showLoading);
    dispatch(slice.actions.requestComplete({ data }));
  } catch (error) {
    Logging.error('Failed to get user list', error);
    clearTimeout(showLoading);
    dispatch(slice.actions.requestComplete({ error }));
  }
};

// This can be existing or new.
export const uploadUser = (id, user) => async (dispatch) => {
  dispatch(slice.actions.uploadBegin());

  try {
    const { roles, last_activity, last_login, created, last_updated, ...cleanUser } = user;

    const data =
      id == 'new'
        ? await webrequest('POST', `users`, cleanUser)
        : await webrequest('PUT', `users/${user.id}`, cleanUser);

    // roles can either be 'created' or 'deleted'
    var newRoles = [];
    if (roles) {
      for (const role of roles) {
        if (role.created) {
          const newRole = await webrequest('POST', `users/${data.id}/roles`, role);
          newRoles.push(newRole);
        } else if (role.deleted) {
          await webrequest('DELETE', `users/${data.id}/roles/${role.id}`);
        } else {
          // unchanged
          newRoles.push(role);
        }
      }
    }

    dispatch(slice.actions.uploadComplete({ id, user: { ...data, roles: newRoles } }));

    showSnackbar('success', 'User updated.');
  } catch (error) {
    dispatch(slice.actions.uploadComplete({ error }));
    showSnackbar('error', 'Failed to save user.');
  }
};

export default slice.reducer;

export const actions = {
  ...slice.actions,
  getUsers,
  uploadUser,
};
