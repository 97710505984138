import queryString from 'query-string';
import { createSelector } from 'reselect';
import store from '~/store';

import { actions } from './slice';

let farmDetailRequestCache = new Set();

export const selectedFarmId = createSelector(
  (state) => state.router.location.search,
  (state) => Object.keys(state.farmsScene.details),
  (query, farmDetailIds) => {
    const rval = queryString.parse(query).site || null;

    if (rval && !farmDetailRequestCache.has(rval)) {
      farmDetailRequestCache.add(rval);
      if (rval != 'new') {
        // request details for this farm
        store.dispatch(actions.getFarmDetails(rval));
      }
      store.dispatch(actions.editFarm(rval));
    }
    return rval;
  }
);

const newFarm = {
  id: null,
  timezone: 'America/Chicago',
  deleted: false,
  altitude_floor: 15.24,
  altitude_ceiling: 121.92,
  speed_limit: 12,
  keepin: null,
  grower_id: 'AR', // just default here
  location: '',
};

const selectedFarmDetails = createSelector(
  selectedFarmId,
  (state) => state.farmsScene.details,
  (farmid, details) => details[farmid]
);

export const selectedFarm = createSelector(
  selectedFarmId,
  (state) => state.global.farms,
  selectedFarmDetails,
  (farmId, allFarms, details) => (farmId == 'new' ? newFarm : { ...details, ...allFarms[farmId] })
);

// Will return the draft region, or the original if no change.
export const getTmpFarm = createSelector(
  selectedFarmId,
  selectedFarm,
  (state) => state.farmsScene.tmpFarms,
  selectedFarmDetails,
  (farmid, farm, tmpFarms, details) => {
    return selectedFarm !== null ? { ...details, ...(tmpFarms[farmid] || farm) } : null;
  }
);
