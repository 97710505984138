import React from 'react';
import * as globalSelctors from '~/redux/selectors/global';

import AllFlightsPanel from './AllFlightsPanel';

export const matches = (state) => globalSelctors.paramsForPath(state, '/flights');

export const title = (state) => 'Flight Logs';

export const breadcrumbs = () => ['Flight Logs'];

export const component = () => {
  return <AllFlightsPanel />;
};

export const helpTopic = () => 'Flight Logs';
