import { DateTime } from 'luxon';
import queryString from 'query-string';
import { createSelector } from 'reselect';
import { findDataRegions } from '~/lib/geography';
import { allFarms, getQueryOrgAndSite } from '~/redux/selectors/global';
import store, { RootState, SVDispatch } from '~/store';
import * as wkt from 'wellknown';
import union from '@turf/union';

import { Feature, Polygon, MultiPolygon } from 'geojson';

import { feature } from '@turf/helpers';

import { actions } from './slice';

import type { Asset, Region, Survey } from '~/schema';
import { TargetArea } from '~/schema/TargetRequest';

const requestedFarmIdCache: string[] = [];
const requestSurveysOnFirstAccess = (farmid: string) => {
  // Lazy load surveys from farms when we first encounter them
  if (!requestedFarmIdCache.includes(farmid)) {
    requestedFarmIdCache.push(farmid);
    (store.dispatch as SVDispatch)(actions.getFarmSurveys(farmid));
  }
};

// TODO: Might need to do some requesting here to get the survey and friends from a new api call?
export const getSelectedSurveyId = createSelector(
  (state: RootState) => state.router.location.search,
  (query): number | null => {
    const rval = queryString.parse(query).surveyid || null;
    if (Array.isArray(rval)) return parseInt(rval[0]);
    if (rval === null) return null;
    if (rval == 'new') return 0;
    return parseInt(rval);
  }
);

const newSurvey: Omit<Survey, 'farm_id'> = {
  id: 0,
  target_requests: [],
  display_name: 'Unnamed Survey',
  type: 'RECURRING',
  enabled: true,
  deleted: false,
  needs_approval: false,
  high_priority: false,
  keepout_others: false,
  payload_type: 'MICASENSE_REDEDGE_MX',
  payload_device_name: 'MICASENSE_REDEDGE_MX',
};

export const getTmpSurvey = createSelector(
  (state: RootState) => state.router.location.search,
  getSelectedSurveyId,
  (state: RootState) => state.surveysScene.surveys,
  (state: RootState) => state.surveysScene.tmpSurveys,
  getQueryOrgAndSite,

  (query, id, surveys, tmpSurveys, currentOrgAndSite) => {
    const fid = currentOrgAndSite.siteId || '';

    const newFullSurvey: Survey = { ...newSurvey, farm_id: fid };

    if (id == 0) {
      return {
        ...newFullSurvey,
        ...tmpSurveys[0],
      };
    } else if (id !== null) {
      return tmpSurveys[id] || surveys[id] || newFullSurvey;
    } else {
      return null; // This indicates that we shouldn't be on the edit page at all
    }
  }
);

export const focussedFarm = createSelector(
  getTmpSurvey,
  getQueryOrgAndSite,
  allFarms,
  (tmpSurvey, currentOrgAndSite, allFarms) => {
    const fid = tmpSurvey?.farm_id || currentOrgAndSite.siteId;

    const farm = fid ? allFarms[fid] : null;
    if (farm) {
      requestSurveysOnFirstAccess(farm.id);
    }
    return farm;
  }
);

export const surveysForFarm = createSelector(
  focussedFarm,
  (state: RootState) => state.surveysScene.surveys,
  (farm, surveys) => {
    return Object.values(surveys || {}).filter((r) => r.farm_id == farm?.id) || [];
  }
);

export const getSurveyById = createSelector(
  (state: RootState, id: number) => id,
  (state: RootState) => state.surveysScene.surveys,
  (id, surveys) => surveys[id]
);

export const regionsForFarm = createSelector(
  focussedFarm,
  (state: RootState) => state.surveysScene.regions,
  (farm, regions) => {
    return Object.values(regions || {}).filter((r) => r.farm_id == farm?.id && !r.deleted) || [];
  }
);
export const allRegionsForFarm = createSelector(
  focussedFarm,
  (state) => state.surveysScene.regions,
  (farm, regions) => {
    return Object.values(regions || {}).filter((r) => r.farm_id == farm?.id) || [];
  }
);

export const allAssets = createSelector(
  focussedFarm,
  (state) => state.surveysScene.assets,
  (farm, assets) => {
    return Object.values(assets || {}).filter((a) => a.site_id == farm?.id) || [];
  }
);

export const getTargetedAssets = createSelector(allAssets, getTmpSurvey, (assets, tmpSurvey) => {
  const rval: Asset[] = [];

  if (tmpSurvey) {
    for (const tr of tmpSurvey?.target_requests) {
      for (const targetArea of tr.target_areas) {
        const asset = assets.find((a) => a.id == targetArea.asset_id);
        if (asset) rval.push(asset);
      }
    }
  }
  return rval;
});

export const getKindsOfRegions = createSelector(
  getTmpSurvey,
  regionsForFarm,

  (survey, regions) => {
    const keepouts = regions.filter((r) => r.type == 'KEEPOUT');

    if (!survey) {
      return {
        available: [],
        availableData: [],
        data: [],
        flight: [],
        keepouts,
      };
    }

    const extantTRs = survey.target_requests.filter((tr) => !tr.deleted);

    const flightRegionIds = extantTRs.map((tr) => tr.flight_region_id).filter((x) => x);
    const flightRegions = regions.filter((r) => flightRegionIds.includes(r.id));

    const dataRegions = flightRegions
      .map((fr) => findDataRegions(fr, regions))
      .flat()
      .filter((r) => r && !flightRegions.includes(r)) as Region[];

    const availableRegions = regions.filter(
      (r) => !flightRegions.includes(r) && !dataRegions.includes(r) && !keepouts.includes(r)
    );

    const availableDataRegions = regions.filter((r) => ['FIELD', 'ZONE'].includes(r.type));

    return {
      available: availableRegions,
      availableData: availableDataRegions,
      data: dataRegions,
      flight: flightRegions,
      keepouts: keepouts,
    };
  }
);

export const getBaseLocation = createSelector(focussedFarm, (farm) => {
  return farm?.planned_base_location;
});

export const getTotalBoundaryWkt = createSelector(
  allAssets,
  allRegionsForFarm,
  (_state: any, targetAreas: TargetArea[]) => targetAreas,
  (assets, regions, targetAreas) => {
    // Get the shapes of all our flight regions or assets and convert it into a single boundary.
    const boundaries_wkt = targetAreas.map((ta) => {
      if (ta.asset_id) return assets?.find((a) => a.id == ta.asset_id)?.shape;
      if (ta.flight_region_id) return regions?.find((a) => a.id == ta.flight_region_id)?.boundary;
    });

    type FeatureType = Feature<Polygon | MultiPolygon>;

    const boundaries = boundaries_wkt
      .filter((b) => b != null && b != '')
      .map((b) => feature(wkt.parse(b!)) as FeatureType);

    // Why doesn't turf.union support arrays or a rest param??
    let boundaries_union = boundaries[0];
    for (let i = 1; i < boundaries.length; i++)
      boundaries_union = union(boundaries_union!, boundaries[i]);

    if (boundaries_union === undefined) return null;
    return wkt.stringify(boundaries_union.geometry as wkt.GeoJSONGeometry);
  }
);
