import produce from 'immer';
import { DateTime } from 'luxon';
import React from 'react';
import * as Icons from '~/icons';
import { checkIsEmail } from '~/lib/email';
import { showSnackbar } from '~/lib/modalServices';
import { serlializeUTCISO } from '~/lib/time';
import Logging from '~/logging';
import { useSVDispatch, useSVSelector } from '~/redux/hooks';
import { Group, Invite } from '~/schema/Org';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';

import { actions } from './slice';

const DEFAULT_INVITE: Invite = {
  emails: '',
  roles: [{ group_id: -1, role_id: 'none' }],
  duration: 31,
  seat_type: 'USER',
  message: '',
};

interface Props {
  open: boolean;
  onClose: () => void;
  orgid: string;
}

export default function InviteDialog({ open, onClose, orgid }: Props) {
  const [invite, setInvite] = React.useState<Invite>({ ...DEFAULT_INVITE });

  // Get group data from redux
  const groups = useSVSelector((state) => state.orgScene.orgs[orgid]?.groups || []);

  const groupsById: { [id: number]: Group } = {};
  groups.forEach((g: Group) => (groupsById[g.id] = g));

  const dispatch = useSVDispatch();

  const sendInvite = async (invite: Invite) => {
    if (!invite) return;
    try {
      for (const email of invite.emails?.split('\n') || []) {
        dispatch(
          actions.sendInvite({
            email,
            orgid: orgid,
            expires: serlializeUTCISO(DateTime.now().plus({ days: invite.duration })),
            ...invite,
            emails: undefined,
            duration: undefined,
          })
        );
      }
      // TODO Action to submit new org to our list
      onClose();
    } catch (error) {
      Logging.error('Failed to send invitation', error);
      showSnackbar('error', 'Could not send invitation.');
    }
  };

  const handleConfirm = () => {
    sendInvite(invite);
    setInvite({ ...DEFAULT_INVITE });
  };

  const handleCancel = () => {
    setInvite({ ...DEFAULT_INVITE });
    onClose();
  };

  const setField = (field: string, value: any) => {
    setInvite({ ...invite, [field]: value });
  };

  const isEmailValid = invite.emails!.split('\n').every((e) => checkIsEmail(e));
  const areGroupsValid =
    !invite.roles.some((r) => r.group_id == -1 || r.role_id == 'none') && invite.roles.length > 0;
  const isComplete = isEmailValid && areGroupsValid; // TODO: also check for at least one valid group assignment

  return (
    <Dialog open={open} onClose={handleCancel} disableBackdropClick disableEscapeKeyDown>
      <DialogTitle>Invite New Members</DialogTitle>

      <DialogContent style={{ overflowY: 'auto' }}>
        <ListItem>
          <Typography variant='body1'>
            Invite team members to join your organization and gain access to ScoutView. Enter their
            emails (one per line), and specify any group roles.
          </Typography>
        </ListItem>

        <ListItem>
          <TextField
            fullWidth
            multiline
            rows={3}
            rowsMax={5}
            label='Email Addresses to Invite'
            value={invite.emails}
            error={!invite.emails!.split('\n').every(checkIsEmail)}
            onChange={(e) =>
              setInvite(
                produce(invite, (inv) => {
                  inv.emails = e.target.value;
                })
              )
            }
          />
        </ListItem>
        <Divider />

        <ListItem>
          <TextField
            fullWidth
            label={
              invite.emails!.split('\n').length > 1
                ? 'Personal message to recipients'
                : 'Personal message to recipient'
            }
            value={invite.message}
            error={!invite.message}
            onChange={(e) => setField('message', e.target.value)}
          />
        </ListItem>

        <ListItem>
          <TextField
            select
            fullWidth
            label='Invitation is valid for'
            value={invite.duration}
            onChange={(e) => setField('duration', e.target.value)}>
            <MenuItem value={1}>1 Day</MenuItem>
            <MenuItem value={7}>1 Week</MenuItem>
            <MenuItem value={31}>1 Month</MenuItem>
            <MenuItem value={183}>6 Months</MenuItem>
            <MenuItem value={365}>1 Year</MenuItem>
          </TextField>
        </ListItem>

        <Divider />
        <List disablePadding>
          {invite.roles.length == 0 && (
            <ListItem>
              <Typography variant='caption' color='error'>
                Add one or more group memberships to this invite to give the recipient access.
              </Typography>
            </ListItem>
          )}
          {invite.roles.map((r, i) => (
            <ListItem key={i}>
              <TextField
                select
                fullWidth
                label='Group'
                value={r.group_id}
                error={r.group_id == -1}
                onChange={(e) =>
                  setInvite(
                    produce(invite, (inv) => {
                      inv.roles[i].group_id = parseInt(e.target.value);

                      // verify that the selected role id is valid in this group
                      if (
                        inv.roles[i].group_id == -1 ||
                        !groupsById[inv.roles[i].group_id]?.derived_roles.includes(
                          inv.roles[i].role_id
                        )
                      ) {
                        inv.roles[i].role_id = 'none'; // Reset to the invalid role
                      }
                    })
                  )
                }>
                <MenuItem value={-1}>
                  <i>Unset</i>
                </MenuItem>
                {groups.map((g) => (
                  <MenuItem key={g.id} value={g.id}>
                    {g.name}
                  </MenuItem>
                ))}
              </TextField>
              &nbsp;
              <TextField
                select
                fullWidth
                label='Role'
                disabled={r.group_id == -1}
                value={r.role_id}
                error={r.role_id == 'none' && r.group_id != -1}
                onChange={(e) =>
                  setInvite(
                    produce(invite, (inv) => {
                      inv.roles[i].role_id = e.target.value;
                    })
                  )
                }>
                <MenuItem value={'none'}>
                  <i>Unset</i>
                </MenuItem>

                {(groupsById[r.group_id]?.derived_roles || []).map((r) => (
                  <MenuItem key={r} value={r}>
                    {r}
                  </MenuItem>
                ))}
              </TextField>
              &nbsp;
              <Tooltip title='Remove role'>
                <span>
                  <IconButton
                    size='small'
                    disabled={invite.roles.length <= 1}
                    onClick={() =>
                      setInvite(
                        produce(invite, (inv) => {
                          inv.roles.splice(i, 1);
                        })
                      )
                    }>
                    <Icons.Close />
                  </IconButton>
                </span>
              </Tooltip>
            </ListItem>
          ))}
          <ListItem>
            <Button
              size='small'
              color='primary'
              onClick={() =>
                setInvite(
                  produce(invite, (inv) => {
                    inv.roles.push({ group_id: -1, role_id: 'none' });
                  })
                )
              }>
              Add Another Group Membership
            </Button>
          </ListItem>
          <ListItem>
            <FormControlLabel
              control={
                <Checkbox
                  checked={invite.seat_type == 'ADMIN'}
                  onChange={(evt) =>
                    setInvite(
                      produce(invite, (inv) => {
                        inv.seat_type = evt.target.checked ? 'ADMIN' : 'USER';
                      })
                    )
                  }
                  name='orgadmin'
                />
              }
              label='Let User Manage Organization'
            />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color='primary'>
          Cancel
        </Button>
        <Button variant='contained' onClick={handleConfirm} color='primary' disabled={!isComplete}>
          Send Invite
        </Button>
      </DialogActions>
    </Dialog>
  );
}
