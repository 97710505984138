import classNames from 'classnames';
import React from 'react';
import * as Icons from '~/icons';
import Logging from '~/logging';
import FilteringPopup from '~/shared/FilteringPopup';
import PaginationControl from '~/shared/PaginationControl';

import { Button, CircularProgress, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

const styles = makeStyles((theme) => ({
  wrapper: {
    flex: '1 1 0',
    minHeight: 0,
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  root: {
    position: 'relative',
    maxHeight: '100%',
    overflow: 'hidden',
    flexGrow: 1,
  },

  tablearea: {
    overflowX: 'auto',
    overflowY: 'auto',
    maxHeight: '100%',
    height: '100%',
  },

  progress: {
    zIndex: 99,
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0,0,0,0.5)',
    opacity: 0,
    userSelect: 'none',
    pointerEvents: 'none',
    transition: 'opacity 0.1s ease',

    '&.visible': {
      opacity: 1,
      pointerEvents: 'all',
    },
  },

  table: {
    flex: '1 1 0',
    borderCollapse: 'separate',
    borderSpacing: 0,
    minWidth: '100%',
    maxHeight: '100%',
  },

  row: {
    '&.even': {
      background: fade(theme.palette.action.hover, 0.02),
    },
    '&.odd': {
      background: 'transparent',
    },
    '&:hover': {
      background: theme.palette.action.hover,
    },

    '&.hidden': {
      color: theme.palette.text.disabled,
    },

    '&.selected': {
      background: theme.palette.action.selected,
    },

    '&.empty': {
      background: theme.palette.background.paper,
      opacity: 0,
    },
  },

  head: {
    position: 'sticky',
    top: 0,
  },

  cellContent: {
    minHeight: 30,
    padding: 4,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'inline-flex',
  },

  headerText: {
    whiteSpace: 'nowrap',
  },

  cell: {
    borderBottom: [[1, 'solid', theme.palette.divider]],

    '&.header': {
      padding: 0,
      background: theme.palette.background.default,
      position: 'sticky',
      top: 0,
      zIndex: 1, // To deal with opacity<1 cells
      borderRight: [[1, 'solid', theme.palette.divider]],
      // borderLeft: `1px solid #ccc`,
    },

    '&.number': {
      textAlign: 'right',
    },

    '&.filtering': {
      background: fade(theme.palette.action.hover, 0.08),
    },
  },

  buttonSet: {
    margin: 4,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  footer: {
    height: 30,
    background: theme.palette.background.default,
  },

  smallButton: {
    background: 'transparent',
    color: theme.palette.primary.main,

    minWidth: 32,
    padding: 0,
    transition: 'opacity 0.3s, color 0.3s',

    opacity: 0.8,

    '&:hover': {
      opacity: 1,
    },

    '&.selected': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },

  units: {
    opacity: 0.5,
  },

  sortIcon: {
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.shorter,
    }),
  },

  sortUp: {
    transform: 'rotate(0deg)',
  },
  sortDown: {
    transform: 'rotate(180deg)',
  },

  flex: { display: 'flex' },
  flexRight: { display: 'flex', justifyContent: 'flex-end' },

  clickable: { cursor: 'pointer' },
}));

export default function FancyTable({
  data,
  columns,
  loading,
  allLoading,
  onClick,
  filters,
  setFilters,
  hidden,
  paginate,
  sortCol,
  sortDir,
  selected,
  limit,
  children,
  onNewQuery,
  offset,
  count,
  styleForCell,
}) {
  const classes = styles();

  const [filtering, setFiltering] = React.useState(null);
  const filteredKeys = [...new Set(filters.map((f) => f.key))];

  const setSort = (sortCol, sortDir) => {
    onNewQuery({
      sortCol: sortCol,
      sortDir: sortDir,
      offset: offset,
      limit: limit,
    });
  };

  const setPage = (offset, limit) => {
    onNewQuery({
      sortCol: sortCol,
      sortDir: sortDir,
      offset: offset,
      limit: limit,
    });
  };

  const clickedSortHandler = (cid) => () => {
    if (cid == sortCol) {
      // Switch sort direction
      setSort(cid, sortDir == 'asc' ? 'desc' : 'asc');
    } else {
      // Start sorting
      setSort(cid, 'desc');
    }
  };

  const setFilter = (columnid, newColFilters) => {
    let newFilters = filters.filter((f) => f.key != columnid);
    newFilters.push(...newColFilters);
    setFilters(newFilters);
  };

  const rows = [...Array(limit).keys()];

  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
        <div className={classes.tablearea}>
          <table className={classes.table}>
            <thead className={classes.head}>
              <tr className={classNames(classes.row, 'header')}>
                {columns.map((c, ci) => (
                  <th key={ci} className={classNames(classes.cell, 'header')}>
                    <div className={classes.buttonSet}>
                      {c.filterable ? (
                        <Button
                          size='small'
                          className={classNames(classes.smallButton, {
                            selected: filteredKeys.includes(c.id),
                          })}
                          onClick={() => {
                            setFiltering(c.id);
                          }}>
                          <Icons.Filter color='inherit' fontSize='small' />
                        </Button>
                      ) : (
                        <div className={classes.smallButton} />
                      )}
                      <Typography variant='subtitle2' className={classes.headerText}>
                        {c.title}
                      </Typography>
                      {c.sortable ? (
                        <Button
                          size='small'
                          className={classNames(classes.smallButton, {
                            selected: sortCol == c.id,
                          })}
                          onClick={clickedSortHandler(c.id)}>
                          <Icons.UpArrow
                            color='inherit'
                            fontSize='small'
                            className={`${classes.sortIcon} ${
                              sortDir == 'desc' || sortCol !== c.id
                                ? classes.sortUp
                                : classes.sortDown
                            }`}
                          />
                        </Button>
                      ) : (
                        <div className={classes.smallButton} />
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className={classes.body}>
              {rows.map((xi) => {
                const key = `row-${xi}`;
                if (xi < data.length) {
                  const x = data[xi];
                  const l = loading?.(x) || false;

                  return (
                    <tr
                      key={key}
                      className={classNames(
                        classes.row,
                        xi % 2 == 0 ? 'even' : 'odd',
                        { [classes.clickable]: !!onClick },
                        { hidden: !!hidden?.(x) },
                        { selected: !!selected?.(x) }
                      )}
                      onClick={() => onClick?.(x)}>
                      {columns.map((c, ci) => {
                        const value = l ? '...' : x[c.id];
                        const units = c.units;
                        const ckey = `col-${x.id}-${ci}`;

                        switch (c.type) {
                          case 'NUM':
                            return (
                              <td
                                key={ckey}
                                className={classNames(classes.cell, 'number', {
                                  filtering: filteredKeys.includes(c.id),
                                })}
                                style={styleForCell?.(c, value)}>
                                <div className={classNames(classes.flexRight, classes.cellContent)}>
                                  <Typography variant='body2'>
                                    {c.fixed < 0 ? value : `${parseFloat(value).toFixed(c.fixed)}`}
                                  </Typography>
                                  {units && (
                                    <Typography variant='caption' className={classes.units}>
                                      {units}
                                    </Typography>
                                  )}
                                </div>
                              </td>
                            );
                          default:
                            return (
                              <td
                                key={ckey}
                                className={classNames(classes.cell, {
                                  filtering: filteredKeys.includes(c.id),
                                })}
                                style={styleForCell?.(c, value)}>
                                <div className={classes.cellContent}>
                                  {value ? (
                                    <>
                                      <Typography variant='body2'>{value}</Typography>
                                      {units && (
                                        <Typography variant='caption' className={classes.units}>
                                          {units}
                                        </Typography>
                                      )}
                                    </>
                                  ) : (
                                    <Typography variant='body2' style={{ opacity: 0.5 }}>
                                      <em>N/A</em>
                                    </Typography>
                                  )}
                                </div>
                              </td>
                            );
                        }
                      })}
                    </tr>
                  );
                } else {
                  return (
                    <tr key={key} className={classNames(classes.row, 'empty')}>
                      {columns.map((c, ci) => {
                        const ckey = `col-${xi}-${ci}`;
                        return (
                          <td key={ckey} className={classNames(classes.cell)}>
                            <div className={classNames(classes.flex, classes.cellContent)} />
                          </td>
                        );
                      })}
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </div>

        <div className={classNames(classes.progress, { visible: allLoading })}>
          <CircularProgress size={120} />
        </div>
      </div>

      {columns
        .filter((c) => c.filterable)
        .map((c) => (
          <FilteringPopup
            key={c.id}
            open={filtering == c.id}
            column={c}
            filters={filters.filter((f) => f.key == filtering)}
            onClose={() => {
              setFiltering(null);
            }}
            onUpdate={(newFilters) => {
              setFilter(c.id, newFilters);
              setFiltering(null);
            }}
          />
        ))}

      {paginate && (
        <>
          <Divider />
          <PaginationControl offset={offset} limit={limit} total={count} onUpdate={setPage}>
            {children}
          </PaginationControl>
        </>
      )}
    </div>
  );
}
