import React from 'react';
import { useParams } from 'react-router';
import * as api from '~/lib/api';
import { csvEscape } from '~/lib/csvEscape';
import { downloadBlob } from '~/lib/downloadBlob';
import { Duration } from 'luxon';

import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.background.default,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(2),
  },
  body: {
    display: 'flex',
    overflow: 'hidden',
  },
  reports: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    overflowY: 'auto',
    flexShrink: 0,
  },
  report: {
    flexGrow: 1,
    padding: theme.spacing(1),
    minWidth: 0,
    overflowX: 'auto',
  },

  table: {
    borderCollapse: 'collapse',
    whiteSpace: 'nowrap',
    textAlign: 'left',

    '& td,th': {
      padding: theme.spacing(1),
    },
  },

  row: {
    borderBottomStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.divider,
  },
  field: {},
  value: {
    fontWeight: 'bold',
  },

  divider: {
    margin: theme.spacing(2),
    borderTop: '1px dotted black',
  },
}));

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export function prepareFlightData(source) {
  const head = [
    'Date of flight',
    'GPS of flight/ScoutBase',
    'Location of flight/ScoutBase',
    'UAS registration number',
    'Make & Model',
    'RPIC name',
    'RPIC cert number',
    'Takeoff time',
    'Flight duration',
    'TASA active? (Y/N)',
    'Type of flight',
    'Flight complete? (Y/N)',
    'Type of Malfunction',
    'Notes',
  ];

  const rows = source.all_flights.map((flight) => {
    return [
      /* Date of flight */ flight.date,
      /* GPS of flight/ScoutBase */ csvEscape(flight.location_gps, false),
      /* Location of flight/ScoutBase */ flight.location_name,
      /* UAS registration number */ flight.registration_number,
      /* Make & Model */ flight.make_model,
      /* RPIC name */ csvEscape(flight.rpic_name, false),
      /* RPIC cert number */ flight.rpic_cert,
      /* Takeoff time */ flight.takeoff_time,
      /* Flight duration */ flight.duration_sec !== 'N/A'
        ? Duration.fromObject({ seconds: flight.duration_sec }).toFormat('h:mm:ss')
        : flight.duration_sec,
      /* TASA active? (Y/N) */ flight.tasa_active,
      /* Type of flight */ flight.flight_type,
      /* Flight complete? (Y/N) */ flight.complete,
      /* Type of Malfunction */ flight.aborts.length > 0
        ? csvEscape(flight.aborts.join(','), false)
        : '-',
      /* Notes */ flight.anomaly_notes.length > 0 ? csvEscape(flight.anomaly_notes, false) : '-',
    ].join(',');
  });

  return `${head.join(',')}\r\n${rows.join('\r\n')}`;
}

function DetailedFlightReport({ data, ...props }) {
  const classes = styles();

  return (
    <div {...props}>
      <Paper style={{ padding: 8, margin: 8 }}>
        <Typography variant='h5'>Monthly Operational Flight Report (Detailed Version)</Typography>
        <Typography variant='h6'>Section 1: Summary Information</Typography>
        <table className={classes.table}>
          <tbody>
            <tr className={classes.row}>
              <td className={classes.field}>Month:</td>
              <td className={classes.value}>
                {data.month} {data.year}
              </td>
            </tr>
            <tr className={classes.row}>
              <td className={classes.field}>Time Zone Used to Record Flight Times </td>
              <td className={classes.value}>{data.timezone}</td>
            </tr>
            <tr className={classes.row}>
              <td className={classes.field}>Certificate of Waiver, Exemption, or Authorization</td>
              <td className={classes.value}>{data.waivers?.join(', ') || 'None'}</td>
            </tr>
            <tr className={classes.row}>
              <td className={classes.field}>Mission Type </td>
              <td className={classes.value}>{data.mission_type}</td>
            </tr>
            <tr className={classes.row}>
              <td className={classes.field}>Flight Type</td>
              <td className={classes.value}>{data.flight_type}</td>
            </tr>
            <tr className={classes.row}>
              <td className={classes.field}>Airspace Class</td>
              <td className={classes.value}>{data.airspace_class?.join(', ') || 'Unspecified'}</td>
            </tr>
          </tbody>
        </table>
        <hr className={classes.divider} />
        <Typography variant='h6'>Section 2: Flight Data</Typography>
        <table className={classes.table}>
          <thead>
            <tr className={classes.row}>
              <th>Flight #</th>
              <th>AR Flight</th>
              <th>Aircraft Name</th>
              <th>Population Density</th>
              <th>Flight Type</th>
              <th>Launch Date</th>
              <th>Launch Time</th>
              <th>Launch Latitude</th>
              <th>Launch Longitude</th>
              <th>Recovery time</th>
              <th>Number of DAA Tracks</th>
              <th>Airspace Class</th>
              <th>Exceeded Parameters</th>
            </tr>
          </thead>
          <tbody>
            {data.flight_entries.map((flight) => {
              const [to_date, to_time] = flight.takeoff_time.split('T');
              const [l_date, l_time] = flight.landing_time?.split('T') || ['?', '?'];

              const [to_lat, to_lon] = flight.takeoff_location?.split(', ') || ['?', '?'];

              return (
                <tr key={flight.row_number} className={classes.row}>
                  <td>{flight.row_number}</td>
                  <td>{flight.flight_id}</td>
                  <td>
                    D{flight.drone_sn} B{flight.base_sn} S{flight.tail_number}
                  </td>
                  <td>{flight.population_density}</td>
                  <td>{data.flight_type == null ? flight.flight_type : '--'}</td>

                  <td>{to_date}</td>
                  <td>{to_time}</td>
                  <td>{to_lat}</td>
                  <td>{to_lon}</td>
                  <td>{l_time}</td>
                  <td>{flight.num_daa_tracks}</td>
                  <td>{flight.airspace_class?.join(', ') || 'Unspecified'}</td>
                  <td>{flight.exceeded_parameters ? 'YES' : 'NO'}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <hr className={classes.divider} />

        <Typography variant='h6'>
          Addendum to Detailed Flight Report: Detect and Avoid (DAA) Details
        </Typography>
        <table className={classes.table}>
          <thead>
            <tr className={classes.row}>
              <th>Flight #</th>
              <th>Track #</th>
              <th>Sensor Type</th>
              <th>Response</th>
              <th>Closest Horizontal Value</th>
              <th>Closest Horizontal Unit of Measure</th>
              <th>Closest Vertical Value</th>
              <th>Closest Vertical Unit of Measure</th>
            </tr>
          </thead>
          <tbody>
            {data.daa_encounters.map((daa) => {
              return (
                <tr key={`daa-${daa.flight_id}-${daa.row_number}`} className={classes.row}>
                  {/* <td>{daa.row_number}</td>
  <td>{daa.drone_sn}</td>
  <td>{daa.tail_number}</td>
            */}
                  <td>{daa.flight_id}</td>
                  <td>{daa.track_num}</td>
                  <td>{daa.sensor_type}</td>
                  <td>{daa.manuevered ? 'Maneuver' : 'Monitor'}</td>
                  <td>{daa.closest_hrange.toFixed(2)}</td>
                  <td>{daa.closest_hrange_units}</td>
                  <td>{daa.closest_vrange.toFixed(0)}</td>
                  <td>{daa.closest_vrange_units}</td>
                </tr>
              );
            })}
            {data.daa_encounters.length == 0 && (
              <tr>
                <td>No DAA tracks reported.</td>
              </tr>
            )}
          </tbody>
        </table>
      </Paper>
    </div>
  );
}

export function Report({ url }) {
  const { year, month } = useParams();
  const [loadingReport, setLoadingReport] = React.useState(false);
  const [CSVErrorMessage, setCSVErrorMessage] = React.useState('');
  const classes = styles();

  const [data, setData] = React.useState(null);
  const filename = `AR_${year}_${String(month).padStart(2, '0') || '00'}_report.csv`;

  React.useEffect(() => {
    api.webrequest('GET', url.substring(5)).then((results) => setData(results));
  }, [url]);

  if (!data) {
    return <div className={classes.report}>Loading...</div>;
  }

  return (
    <div className={classes.report}>
      <Paper style={{ padding: 8, margin: 8 }}>
        <Typography variant='h5'>Monthly Operational Flight Report (.csv)</Typography>
        <Typography variant='body2' component='p' gutterBottom>
          Download a .csv report of all flights from {monthNames[month - 1]} {year}.
        </Typography>
        <Button
          data-testid='regulatory-button-csv'
          color='secondary'
          variant='contained'
          disabled={loadingReport}
          onClick={() => {
            setCSVErrorMessage('');
            setLoadingReport(true);
            api.webrequest('GET', `sv/faa/v2/details/${year}/${month}`).then(
              (results) => {
                try {
                  downloadBlob(prepareFlightData(results), filename, 'text/csv;charset=utf-8;');
                } catch (e) {
                  console.error(e.message, e);
                  setCSVErrorMessage('There was a problem parsing flight details.');
                }
                setLoadingReport(false);
              },
              (reason) => {
                console.error(reason);
                setCSVErrorMessage('There was a problem loading flight details.');
                setLoadingReport(false);
              }
            );
          }}>
          {filename}
        </Button>
        <Typography
          variant='caption'
          component='span'
          style={{ color: 'red', paddingLeft: 10 }}
          data-testid='regulatory-error-csv'>
          {CSVErrorMessage}
        </Typography>
      </Paper>
      {data.detail_reports?.map((r, i) => {
        return <DetailedFlightReport key={`dfr-${i}`} data={r} />;
      })}
    </div>
  );
}

export function RegulatoryReportPage() {
  const { year, month } = useParams();

  const url = `/api/sv/faa/monthly_operational_details/${year}/${month}`;
  return <Report url={url} />;
}

export default function ProcessorTable() {
  const classes = styles();

  const [selectedIdx, setSelectedIdx] = React.useState(0);
  const [months, setMonths] = React.useState(null);

  React.useEffect(() => {
    api.webrequest('GET', 'sv/faa').then((results) => setMonths(results.months));
  }, []);

  const selectedMonth = months?.[selectedIdx];

  return (
    <div className={classes.root}>
      <Typography variant='h6'>Regulatory Documentation</Typography>
      <div className={classes.body}>
        <Paper className={classes.reports}>
          <List dense>
            {months?.map((entry, i) => (
              <ListItem
                key={i}
                button
                dense
                component='a'
                href={`/regulatory/${entry.year}/${entry.month}`}
                target='_blank'>
                <ListItemText
                  primary={`${entry.year} - ${monthNames[entry.month - 1]}`}
                  secondary={`${entry.flights} flights logged`}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      </div>
    </div>
  );
}
