import { DateTime } from 'luxon';
import { parseUTCISO } from '~/lib/time';

export default interface NoFlyInstance {
  datetime: DateTime;
  parent_id: number;
}

export interface Json {
  datetime: string;
  parent_id: number;
}

export function fromjson(json: Json): NoFlyInstance {
  return {
    ...json,
    datetime: parseUTCISO(json.datetime),
  };
}
