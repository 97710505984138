export function displayNameForFarm(f: any): string | null {
  return f ? `${f.grower_id} › ${f.location}` : null;
}

export function titleCase(str: string): string {
  return str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.replace(word[0], word[0].toUpperCase());
    })
    .join(' ');
}
