import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import { slices as sceneSlices } from '~/scenes';

import estimates from './slices/estimates';
import global from './slices/global';
import notes from './slices/notes';
import notifications from './slices/notifications';
import support from './slices/support';
import ui from './slices/ui';
import useravatars from './slices/useravatars';
import usershape from './slices/usershape';

export default (history: History<unknown>) =>
  combineReducers({
    router: connectRouter(history),
    global,
    notifications,
    usershape,
    ui,
    estimates,
    notes,
    support,
    useravatars,
    ...sceneSlices,
  });
