import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';

export interface InputDialogPayload {
  title: string;
  message: string;
  confirm: string;
  label: string;
  type: string;
}

type InputDialogCallback = (value: string | null) => void;

export interface InputDialogPayloadCallback extends InputDialogPayload {
  callback: InputDialogCallback;
}

export default function InputDialog({
  payload,
  onClose,
}: {
  payload?: InputDialogPayloadCallback;
  onClose: () => void;
}) {
  const [value, setValue] = React.useState('');

  function resolve(value: string | null) {
    payload?.callback(value);
    setValue('');
    onClose();
  }

  return (
    <Dialog
      open={payload != null}
      onClose={() => resolve(null)}
      disableBackdropClick
      disableEscapeKeyDown>
      {payload?.title && <DialogTitle>{payload?.title}</DialogTitle>}
      <DialogContent>
        <DialogContentText>{payload?.message}</DialogContentText>

        <TextField
          autoFocus
          margin='dense'
          id='value'
          label={payload?.label}
          type={payload?.type}
          fullWidth
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => resolve(null)} color='secondary'>
          Cancel
        </Button>
        <Button onClick={() => resolve(value)} color='primary'>
          {payload?.confirm || 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
