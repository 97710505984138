import classNames from 'classnames';
import React from 'react';
import { resolutionToStr } from '~/lib/units';

import { Tooltip, makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  root: {
    width: 8,
    height: 32,

    marginRight: 16,

    position: 'relative',

    color: theme.palette.primary.main,
    // background: theme.palette.divider,
  },

  center: {
    position: 'absolute',
    left: 3,
    right: 3,
    backgroundColor: 'currentColor',
    opacity: 0.8,

    '&.below': {
      opacity: 0.4,
    },
  },

  bar: {
    position: 'absolute',
    height: 2,
    // borderRadius: '50%',

    left: 0,
    right: 0,
    backgroundColor: 'currentColor',
  },
}));

// res in m/px
export default function ResIndicator({ res }) {
  const classes = styles();

  const highest = 0.08;
  const lowest = 0.01;

  // should go from 0 to 32-4=28
  const bottom = 28 * Math.min(1, Math.max(0, (res - lowest) / (highest - lowest)));

  return (
    <Tooltip title={resolutionToStr(res)}>
      <div className={classes.root}>
        <div className={classes.center} style={{ top: 0, bottom }} />
        <div
          className={classNames(classes.center, 'below')}
          style={{ bottom: 0, height: bottom }}
        />
        <div className={classes.bar} style={{ bottom }} />
      </div>
    </Tooltip>
  );
}
