import classNames from 'classnames';
import React from 'react';
import { getStopsForType } from '~/lib/colorScales';
import { degCtoPref, tempUnitText } from '~/lib/units';
import { getDataColors } from '~/redux/commonSelectors';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

function rgbToYIQ([r, g, b]) {
  return (r * 299 + g * 587 + b * 114) / 1000;
}

const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'stretch',
    flexGrow: 1,
    // margin: 2,
    // borderRadius: 4,
    overflow: 'hidden',
    userSelect: 'none',
  },
  entry: {
    height: '100%',
    flexGrow: 1,
    flexBasis: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // paddingLeft: theme.spacing(1),
    // paddingRight: theme.spacing(1),
    // paddingTop: theme.spacing(0.5),
    // paddingBottom: theme.spacing(0.5),
  },
}));

export function DEMColorLegend({ min, max, mean, className }) {
  const colorPalette = getDataColors;

  const displayValue = (v) => {
    let value = (v - mean) * 3.28084;
    let unit = "'";
    if (((max - min) / 5) * 3.28084 < 2 && mean > 0) {
      // if less than a two foot spread,
      // show inches
      value = value * 12;
      unit = '"';
    }

    if (value.toFixed(0) == '0') return 'AVG';
    return `${value > 0 && mean > 0 ? '+' : ''}${value.toFixed(0)}${unit}`;
  };
  return (
    <ColorLegend
      entries={getStopsForType(colorPalette, 'DEM').map((stop) => ({
        color: [stop[1][0], stop[1][1], stop[1][2]],
        text: displayValue(min + (max - min) * stop[0]),
      }))}
      className={className}
    />
  );
}

export function ThermalColorLegend({ type, mode, min, max, className }) {
  const displayValue = (v) => `${degCtoPref(v).toFixed(0)}${tempUnitText()}`;

  return (
    <ColorLegend
      entries={getStopsForType('inferno', mode).map((stop) => ({
        color: [stop[1][0], stop[1][1], stop[1][2]],
        text: displayValue(min + (max - min) * stop[0]),
      }))}
      className={className}
    />
  );
}

export function FalseColorLegend({ type, mode, min, max, className }) {
  const displayValue = (v) => v.toFixed(2);

  return (
    <ColorLegend
      entries={getStopsForType(type, mode).map((stop) => ({
        color: [stop[1][0], stop[1][1], stop[1][2]],
        text: displayValue(min + (max - min) * stop[0]),
      }))}
      className={className}
    />
  );
}

export function RGBColorLegend({ className }) {
  return (
    <ColorLegend
      entries={[
        {
          color: [255, 61, 61],
          text: 'RED',
        },
        {
          color: [61, 255, 61],

          text: 'GREEN',
        },
        {
          color: [61, 61, 255],

          text: 'BLUE',
        },
      ]}
      className={className}
    />
  );
}

export function CIRColorLegend({ className }) {
  return (
    <ColorLegend
      entries={[
        {
          color: [255, 61, 61],
          text: 'NIR',
        },
        {
          color: [61, 255, 61],
          text: 'RED',
        },
        {
          color: [61, 61, 255],
          text: 'GREEN',
        },
      ]}
      className={className}
    />
  );
}

// entries is [{ color: [r,g,b], text }]
function ColorLegend({ entries, className }) {
  const classes = styles();

  return (
    <div className={classNames(classes.container, className)}>
      {entries.map(({ color, text }, i) => {
        const textColor = rgbToYIQ(color) > 128 ? 'black' : 'white';

        return (
          <div
            key={i}
            className={classes.entry}
            style={{
              backgroundColor: `rgb(${color[0]},${color[1]},${color[2]})`,
            }}>
            <Typography style={{ color: textColor }} variant='overline'>
              {text}
            </Typography>
          </div>
        );
      })}
    </div>
  );
}
