import * as api from '~/lib/api';
import Logging from '~/logging';

import { createSlice } from '@reduxjs/toolkit';

import { getQuery } from './selectors';

const slice = createSlice({
  name: 'hardware',
  initialState: {
    isFetching: false,

    searchStr: '', // search hardware field

    rows: [], // Represents the data currently visible in the table, from teh last query
    columns: [], // the column definition given to us for this table

    types: [], // What kinds of parts does the server have for us?
    manufacturers: [], // all known manufacturers
    locations: [], // all known locations

    totalCount: 0,
    page: 0, // what page are we currently on
  },

  reducers: {
    setFetching(state, action) {
      state.isFetching = action.payload;
    },

    setSearchStr(state, action) {
      state.searchStr = action.payload;
    },

    updateData(state, action) {
      const { metadata, rows, columns } = action.payload;

      state.totalCount = metadata.count;

      const mfgr_col = columns.find((c) => c.id == 'mfgr');
      const type_col = columns.find((c) => c.id == 'type');
      const loc_col = columns.find((c) => c.id == 'loc');

      // Extract some data useful elsewhere.
      state.manufacturers = mfgr_col.filter_opts_str || [];
      state.locations = loc_col.filter_opts_str || [];
      state.types = [...new Set(['system', 'base', 'drone', ...type_col.filter_opts_str])];

      state.rows = rows || [];
      state.columns = columns || [];
      state.isFetching = false;
    },
  },
});

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

var isInCooldown = false;

const requestRows = (query) => async (dispatch, getState) => {
  // if we're already fetching, wait a sec and see if the query is the same.
  if (isInCooldown) {
    await sleep(300);

    const latestQ = getQuery(getState());
    if (JSON.stringify(latestQ) != JSON.stringify(query)) {
      return;
    }
  }
  isInCooldown = true;

  try {
    dispatch(slice.actions.setFetching(true));

    const params = {
      sortby: query.sortCol,
      sortdir: query.sortDir,
      offset: query.offset,
      limit: query.limit,
      filter: query.filters || [],
      search: query.search,
    };

    const result = await api.webrequest('GET', 'sv/hardware', {}, params);

    dispatch(slice.actions.updateData(result));
    await sleep(300);
  } catch (e) {
    Logging.error(`Error retrieving hardware data`, e);
  }
  isInCooldown = false;
};

export default slice.reducer;

export const actions = {
  ...slice.actions,
  requestRows,
};
