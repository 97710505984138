import { DateTime } from 'luxon';
import { createSelector } from 'reselect';
import { parseUTCISO } from '~/lib/time';
import { getTimelineTime } from '~/lib/timeline';
import * as globalSelectors from '~/redux/selectors/global';
import { getUsershapeWKT } from '~/redux/slices/usershape';

export const matches = (state) => globalSelectors.paramsForPath(state, '/field/:regionId');

export const getFieldData = createSelector(
  matches,
  (state) => state.fieldScene.fields,
  (params, fields) => fields[params.regionId]
);

export const getRegion = createSelector(getFieldData, (field) => field?.region);

export const isPending = createSelector(getFieldData, (field) => (field ? field.pending : true));

export const fieldsForFarm = createSelector(getFieldData, (field) => {
  return field?.farm_fields?.filter((f) => !f.deleted) || [];
});

export const getFilterRules = createSelector(
  (state) => state.fieldScene.filterRules,
  (filterRules) => filterRules
);

export const imagesets = createSelector(getFieldData, (fieldData) => {
  if (!fieldData || fieldData.pending || !(fieldData.imagesets || fieldData.targeted_imagesets)) {
    return [];
  }

  const allImagesets = [...(fieldData.imagesets || []), ...(fieldData.targeted_imagesets || [])];

  let sortedImagesets = allImagesets.slice().sort((a, b) => {
    if (a.timestamp < b.timestamp) return 1;
    if (a.timestamp > b.timestamp) return -1;
    return 0;
  });

  // go through and assign the previous full imageset to each targeted imageset
  let latestFullTimestamp = null;
  const fullTimestamps = fieldData.imagesets?.map((i) => i.timestamp) || [];
  for (let i = sortedImagesets.length - 1; i >= 0; i--) {
    const iset = sortedImagesets[i];

    const isTargeted = !fullTimestamps.includes(iset.timestamp);
    const isSampled = iset.request_type == 'SAMPLING';
    const isAsset = iset.request_type == 'ASSET_ORBIT';

    if (!isTargeted && !isSampled && !isAsset) {
      sortedImagesets[i] = {
        ...iset,
        isTargeted,
        isSampled,
        isAsset,
      };
      if (!iset.hidden) latestFullTimestamp = iset.timestamp;
    } else {
      // it should be overlaid on top of the most recent full ortho
      sortedImagesets[i] = {
        ...iset,
        isTargeted,
        isSampled,
        isAsset,
        background_timestamp: latestFullTimestamp,
        region: fieldData.targeted_regions?.find((r) => r.id == iset.imagery[0]?.region_id || null),
      };
    }
  }
  return sortedImagesets;
});

export const getFilteredImagesets = createSelector(
  imagesets,
  getFilterRules,
  (imagesets, filterRules) =>
    imagesets.filter((i) => {
      if (i.actual_resolution * 1000 < filterRules.res[0]) return false;
      if (i.actual_resolution * 1000 > filterRules.res[1]) return false;

      if (i.imagery[0].hidden && !filterRules.showHidden) return false;

      return true;
    })
);

export const getCurrentImageset = createSelector(
  getFieldData,
  getFilteredImagesets,
  (state, channel) => getTimelineTime(state, channel),
  (state, channel) => channel,
  (fieldData, imagesets, timelineTime, channel) => {
    if (!fieldData || !imagesets || imagesets.length == 0) return null;

    var bestImage = imagesets[0]; // start somewhere
    var err = Infinity;

    const timelineTimeDt = parseUTCISO(timelineTime) || DateTime.now();

    for (const i of imagesets) {
      const imagesetDt = parseUTCISO(i.timestamp);

      const delta = Math.abs(imagesetDt.diff(timelineTimeDt).as('seconds'));

      if (delta == 0) return i; // exact match short circuit
      if (delta < err) {
        bestImage = i;
        err = delta;
      }
    }
    return bestImage;
  }
);
export const getCurrentBackgroundImageset = createSelector(
  getCurrentImageset,
  getFilteredImagesets,
  (currentImageset, allImagesets) => {
    if (currentImageset?.isTargeted || currentImageset?.isSampled || currentImageset?.isAsset) {
      return allImagesets.find((x) => x.timestamp == currentImageset.background_timestamp);
    } else {
      return currentImageset;
    }
  }
);

export const zonesForField = createSelector(getFieldData, (field) => field?.zones || []);

export const getTmpZone = createSelector(getFieldData, getUsershapeWKT, (field, usershapeWkt) => {
  return {
    farm_id: field?.region?.farm_id,
    display_name: field?.tmpZoneDisplayName,
    type: 'ZONE',
    grid_divisions: field?.tmpZoneGridDiv,
    boundary: usershapeWkt,
  };
});

export const getVisibleZones = createSelector(
  (state) => state.global.preferences.hiddenZones,
  zonesForField,
  (hidden, zones) => {
    const myZoneIds = zones?.map((z) => z.id) || [];
    const vzids = myZoneIds?.filter((z) => !hidden.includes(z));
    return vzids;
  }
);

export const getZoneSummaries = createSelector(
  (state) => getCurrentImageset(state, 0),
  (state) => state.global.preferences.analysisMode[0],
  zonesForField,
  (imageset, analysisMode, zones) => {
    let zoneSummaries = {};

    if (!imageset?.zone_data) {
      return zoneSummaries;
    }

    for (const zone_data of imageset.zone_data) {
      const analysis = zone_data.available_analytics?.find((x) => x.type == analysisMode);
      const zone = zones.find((z) => z.id == zone_data.region.id);

      zoneSummaries[zone_data.region.id] = {
        processing: zone_data.processing,
        summary: zone && analysis?.summary,
      };
    }
    return zoneSummaries;
  }
);

export const getPermissions = createSelector(
  getFieldData,
  (fieldData) => fieldData?.permissions || []
);
