import { DateTime } from 'luxon';
import React from 'react';
import { ByWeekday, RRule } from 'rrule';

import { MenuItem, TextField, Typography, makeStyles } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';

const MONTHS: { m: string; days: number }[] = [
  { m: 'Jan', days: 31 },
  { m: 'Feb', days: 29 },
  { m: 'Mar', days: 31 },
  { m: 'Apr', days: 30 },
  { m: 'May', days: 31 },
  { m: 'Jun', days: 30 },
  { m: 'Jul', days: 31 },
  { m: 'Aug', days: 31 },
  { m: 'Sep', days: 30 },
  { m: 'Oct', days: 31 },
  { m: 'Nov', days: 30 },
  { m: 'Dev', days: 31 },
];

function first<T>(x: T | T[] | null | undefined): T | null | undefined {
  if (x === null) return null;
  if (x === undefined) return undefined;
  let ts: T[] = [];
  ts = ts.concat(x);
  return ts[0];
}
function asarr<T>(x: T | T[] | null | undefined): T[] | null | undefined {
  if (x === null) return null;
  if (x === undefined) return undefined;
  let ts: T[] = [];
  ts = ts.concat(x);
  return ts;
}
function MonthSelect({ onChange, value }: { onChange: (month: number) => void; value: number }) {
  return (
    <TextField
      aria-label='month'
      select
      label='Month'
      value={value}
      onChange={(evt) => onChange(parseInt(evt.target.value))}>
      {MONTHS.map((m, i) => (
        <MenuItem key={i} value={i + 1}>
          {m.m}
        </MenuItem>
      ))}
    </TextField>
  );
}
function DaySelect({
  onChange,
  value,
  month,
}: {
  onChange: (day: number) => void;
  value: number;
  month: number | null;
}) {
  const maxDays = month === null ? 31 : MONTHS[month - 1]?.days;
  return (
    <TextField
      aria-label='day'
      label='Day'
      type='number'
      value={value}
      onChange={(evt) => onChange(parseInt(evt.target.value))}
      error={value > maxDays}
      inputProps={{
        min: 1,
        max: maxDays,
      }}></TextField>
  );
}
DaySelect.defaultProps = { month: null };

function DayOfWeek({
  onChange,
  value,
}: {
  onChange: (dow: ByWeekday[]) => void;
  value: ByWeekday[];
}) {
  return (
    <ToggleButtonGroup size='small' value={value} onChange={(_, value) => onChange(value)}>
      <ToggleButton aria-label='sunday' value={RRule.SU}>
        Sun
      </ToggleButton>
      <ToggleButton aria-label='monday' value={RRule.MO}>
        Mon
      </ToggleButton>
      <ToggleButton aria-label='tuesday' value={RRule.TU}>
        Tue
      </ToggleButton>
      <ToggleButton aria-label='wednesday' value={RRule.WE}>
        Wed
      </ToggleButton>
      <ToggleButton aria-label='thursday' value={RRule.TH}>
        Thu
      </ToggleButton>
      <ToggleButton aria-label='friday' value={RRule.FR}>
        Fri
      </ToggleButton>
      <ToggleButton aria-label='saturday' value={RRule.SA}>
        Sat
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

const styles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > *:not(:first-child)': { marginLeft: theme.spacing(1) },
  },
}));

interface Props {
  startDate: string;
  rrule?: string;
  setRRule: (rrule?: string) => void;
}

export default function RRuleEditor({ startDate, rrule, setRRule }: Props) {
  const classes = styles();

  const originalOpts = RRule.parseString(rrule || '');
  const opts = {
    repeatFreq: originalOpts.freq === undefined ? -1 : originalOpts.freq,
    byMonth: first(originalOpts.bymonth) || 1,
    byMonthDay: first(originalOpts.bymonthday) || 1,
    byInterval: originalOpts.interval || 1,
    byWeekday: asarr(originalOpts.byweekday) || [],
    endDate: originalOpts.until?.toISOString()?.split('T')?.[0] || DateTime.now().toISODate(),
    endType: originalOpts.until ? 'UNTIL' : originalOpts.count ? 'COUNT' : 'NEVER',
    count: originalOpts.count || 1,
  };

  const update = (change: Partial<typeof opts>) => {
    const newOpts = { ...opts, ...change };
    const newRrule =
      newOpts.repeatFreq < 0
        ? undefined
        : new RRule({
            freq: newOpts.repeatFreq,
            bymonth: [RRule.YEARLY].includes(newOpts.repeatFreq) ? newOpts.byMonth : null,
            bymonthday: [RRule.YEARLY, RRule.MONTHLY].includes(newOpts.repeatFreq)
              ? newOpts.byMonthDay
              : null,
            byweekday: [RRule.WEEKLY].includes(newOpts.repeatFreq) ? newOpts.byWeekday : null,
            interval: [RRule.YEARLY].includes(newOpts.repeatFreq) ? 1 : newOpts.byInterval,
            count: newOpts.endType == 'COUNT' ? newOpts.count : null,
            until: newOpts.endType == 'UNTIL' && newOpts.endDate ? new Date(newOpts.endDate) : null,
          }).toString();

    if (newRrule || rrule) {
      setRRule(newRrule);
    }
  };

  return (
    <>
      <div className={classes.row}>
        <TextField
          aria-label='repeat'
          select
          style={{ flexGrow: 1 }}
          label='Repeat'
          value={opts.repeatFreq}
          onChange={(evt) => update({ repeatFreq: parseInt(evt.target.value) })}>
          <MenuItem aria-label='no repeat' value={-1}>
            <em>Don&apos;t Repeat</em>
          </MenuItem>
          <MenuItem aria-label='yearly' value={RRule.YEARLY}>
            Yearly
          </MenuItem>
          <MenuItem aria-label='monthly' value={RRule.MONTHLY}>
            Monthly
          </MenuItem>
          <MenuItem aria-label='weekly' value={RRule.WEEKLY}>
            Weekly
          </MenuItem>
          <MenuItem aria-label='daily' value={RRule.DAILY}>
            Daily
          </MenuItem>
        </TextField>

        {opts.repeatFreq == RRule.YEARLY ? (
          <>
            {/* RRULE:FREQ=YEARLY;BYMONTH=1;BYMONTHDAY=1 */}
            <Typography>on</Typography>
            <MonthSelect onChange={(x) => update({ byMonth: x })} value={opts.byMonth} />
            <DaySelect
              month={opts.byMonth}
              onChange={(x) => update({ byMonthDay: x })}
              value={opts.byMonthDay}
            />
          </>
        ) : opts.repeatFreq == RRule.MONTHLY ? (
          <>
            {/* RRULE:FREQ=MONTHLY;INTERVAL=1;BYMONTHDAY=1 */}
            <Typography>every</Typography>
            <TextField
              aria-label='month interval'
              type='number'
              inputProps={{ min: 1, max: 12 }}
              label='Months'
              value={opts.byInterval}
              onChange={(evt) => update({ byInterval: parseInt(evt.target.value) })}
            />
            <Typography>on day</Typography>
            <DaySelect onChange={(x) => update({ byMonthDay: x })} value={opts.byMonthDay} />
          </>
        ) : opts.repeatFreq == RRule.WEEKLY ? (
          <>
            {/* RRULE:FREQ=WEEKLY;INTERVAL=1;BYDAY=MO,TU,WE,TH,FR,SA,SU */}
            <Typography>every</Typography>
            <TextField
              aria-label='week interval'
              type='number'
              inputProps={{ min: 1, max: 52 }}
              label='Weeks'
              value={opts.byInterval}
              onChange={(evt) => update({ byInterval: parseInt(evt.target.value) })}
            />
          </>
        ) : opts.repeatFreq == RRule.DAILY ? (
          <>
            {/* RRULE:FREQ=DAILY;INTERVAL=1 */}
            <Typography>every</Typography>
            <TextField
              aria-label='day interval'
              type='number'
              inputProps={{ min: 1, max: 365 }}
              label='Days'
              value={opts.byInterval}
              onChange={(evt) => update({ byInterval: parseInt(evt.target.value) })}
            />
          </>
        ) : (
          <></>
        )}
      </div>
      {opts.repeatFreq == RRule.WEEKLY && (
        // This is tacked on at the end to add another row.
        <div className={classes.row}>
          <Typography>on</Typography>
          <DayOfWeek value={opts.byWeekday} onChange={(x) => update({ byWeekday: x })} />
        </div>
      )}

      {opts.repeatFreq >= 0 && (
        <div className={classes.row}>
          <TextField
            aria-label='end'
            select
            style={{ flexGrow: 1, flexShrink: 0 }}
            label='End'
            value={opts.endType}
            onChange={(evt) => update({ endType: evt.target.value })}>
            <MenuItem aria-label='never' value='NEVER'>
              never
            </MenuItem>
            <MenuItem aria-label='after' value='COUNT'>
              after
            </MenuItem>
            <MenuItem aria-label='on date' value='UNTIL'>
              on date
            </MenuItem>
          </TextField>
          {opts.endType == 'COUNT' ? (
            <TextField
              aria-label='number of repeats'
              type='number'
              inputProps={{ min: 1 }}
              label='Repeats'
              value={opts.count}
              onChange={(evt) => update({ count: parseInt(evt.target.value) })}
            />
          ) : opts.endType == 'UNTIL' ? (
            // UNTIL=20210401T040000Z
            <KeyboardDatePicker
              aria-label='until date'
              fullWidth
              label='End Date'
              minDate={startDate}
              minDateMessage='Please end after start'
              value={opts.endDate ? DateTime.fromISO(opts.endDate) : DateTime.now()}
              onChange={(newdt) => update({ endDate: newdt!.toISODate() })}
            />
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
}
