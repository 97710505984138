import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as supportActions } from '~/redux/slices/support';

import { List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import PageIcon from '@material-ui/icons/DescriptionSharp';
import Skeleton from '@material-ui/lab/Skeleton';

export default function SearchResults({ searchText, onNav }) {
  const dispatch = useDispatch();

  const searchResults = useSelector((state) => state.support.searchResults[searchText]);

  React.useEffect(() => {
    if (!searchResults) {
      dispatch(supportActions.searchHelp(searchText));
    }
  }, [dispatch, searchText, searchResults]);

  return (
    <>
      <Typography variant='h2' color='primary'>
        Search results
      </Typography>

      {!searchResults || searchResults.loading ? (
        <>
          <Skeleton variant='rect' height={120} />
        </>
      ) : (
        <>
          <Typography variant='subtitle2'>
            {searchResults.length == 0
              ? `No results found for "${searchText}.`
              : `${searchResults.length} results found for "${searchText}".`}
          </Typography>

          <List>
            {searchResults.map((topic) => (
              <ListItem key={topic} button onClick={() => onNav(topic)}>
                <ListItemAvatar>
                  <PageIcon color='primary' />
                </ListItemAvatar>
                <ListItemText>
                  <Typography variant='h6'>{decodeURI(topic)}</Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </>
      )}
    </>
  );
}
