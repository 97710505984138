import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import * as wkt from 'wellknown';
import * as Icons from '~/icons';
import { isFeatureContainedBy, resolutionAt } from '~/lib/geography';
import { showSnackbar } from '~/lib/modalServices';
import { actions as estimateActions } from '~/redux/slices/estimates';
import { actions as globalActions } from '~/redux/slices/global';
import { getUsershapeFeatures, getUsershapeValid, getUsershapeWKT } from '~/redux/slices/usershape';
import { actions as usershapeActions } from '~/redux/slices/usershape';
import { CollapsableModule } from '~/shared/CollapsableModule';
import ColoredButton from '~/shared/ColoredButton';
import EditGridDivisions from '~/shared/EditGridDivisions';
import PanelTopProgress from '~/shared/PanelTopProgress';
import { regionColors } from '~/theme';

import {
  Button,
  CircularProgress,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Slider,
  TextField,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import booleanContains from '@turf/boolean-contains';
import { feature } from '@turf/helpers';

import { getFieldData, getRegion, getTmpZone } from './selectors';
import { actions } from './slice';

const styles = (theme) => ({
  root: {},
  buttons: {
    display: 'flex',
    justifyItems: 'stretch',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
});

const isContainedInField = createSelector(
  getUsershapeFeatures,
  getRegion,
  (usershapeFeatures, field) => {
    if (!field || usershapeFeatures.length == 0) return false;

    const field_poly = feature(wkt.parse(field.boundary));
    return isFeatureContainedBy(usershapeFeatures[0], [field_poly]); // we know that targeted scouting only uses one shape
  }
);

const mapStateToProps = (state, props) => ({
  isPendingUsershape: state.usershape.type == null,
  isAddingUsershape: state.usershape.isAdding,
  isDraggingUsershape: state.usershape.isDragging,
  isShapeValid: getUsershapeValid(state),

  containedInField: isContainedInField(state),
  field: getFieldData(state),
  tmpZone: getTmpZone(state),
});

const mapDispatchToProps = (dispatch) => ({
  setUsershape: (x) => dispatch(usershapeActions.setUsershape(x)),
  setTmpZoneGridDiv: (x) => dispatch(actions.setTmpZoneGridDiv(x)),
  setTmpZoneDisplayName: (x) => dispatch(actions.setTmpZoneDisplayName(x)),
  resetUsershape: () => dispatch(usershapeActions.resetShape()),

  createZone: (zone, fieldId) => dispatch(actions.createZone({ zone, fieldId })),
});

let waitingForCreation = false;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(styles)(
    ({
      classes,
      field,
      isPendingUsershape,
      isAddingUsershape,
      isDraggingUsershape,
      containedInField,
      isShapeValid,
      resetUsershape,
      tmpZone,

      setTmpZoneGridDiv,
      setTmpZoneDisplayName,

      createZone,
      onCancel,
    }) => {
      React.useEffect(() => {
        const reset = () => {
          resetUsershape();
          setTmpZoneGridDiv({ fieldId: field.region.id, gridDiv: undefined });
          setTmpZoneDisplayName({ fieldId: field.region.id, displayName: '' });
        };

        reset(); // reset on mount
        return reset; // and unmount
      }, [field.region.id, resetUsershape, setTmpZoneDisplayName, setTmpZoneGridDiv]);

      const createZoneClicked = () => {
        waitingForCreation = true;
        createZone(tmpZone, field.region.id);
      };

      if (waitingForCreation && !field.creatingZone) {
        waitingForCreation = false;
        if (field.creatingZoneError) {
          showSnackbar('error', 'Cannot create analysis zone.');
        } else {
          showSnackbar('success', 'Analysis zone created.');
        }
        onCancel();
      }

      const isValid =
        !isDraggingUsershape &&
        !isAddingUsershape &&
        isShapeValid &&
        containedInField &&
        !isPendingUsershape &&
        tmpZone.display_name != '';

      return (
        <div className={classes.root}>
          <List>
            <Collapse in={isPendingUsershape}>
              <ListItem>
                <ListItemText primary='Draw a boundary inside the field to designate it as an analysis zone.' />
              </ListItem>
            </Collapse>
            <Collapse in={isAddingUsershape === true}>
              <ListItem>
                <ListItemText primary='Complete the shape to continue.' />
              </ListItem>
            </Collapse>

            <Collapse in={isAddingUsershape === false && !isShapeValid}>
              <ListItem>
                <ListItemText
                  primaryTypographyProps={{ color: 'error' }}
                  primary='Polygon is invalid; please remove self-intersections.'
                />
              </ListItem>
            </Collapse>

            <Collapse
              in={
                !isDraggingUsershape &&
                !isAddingUsershape &&
                isShapeValid &&
                !containedInField &&
                !isPendingUsershape
              }>
              <ListItem>
                <ListItemText
                  primaryTypographyProps={{ color: 'error' }}
                  primary='Must be contained within a field.'
                />
              </ListItem>
            </Collapse>

            <ListItem>
              <TextField
                fullWidth
                required
                id='display_name'
                label='Zone Name'
                error={tmpZone.display_name == ''}
                value={tmpZone.display_name}
                onChange={(e) => {
                  setTmpZoneDisplayName({
                    fieldId: field.region.id,
                    displayName: e.target.value,
                  });
                }}
              />
            </ListItem>

            <EditGridDivisions
              region={tmpZone}
              onChange={(newGridDiv) =>
                setTmpZoneGridDiv({ fieldId: field.region.id, gridDiv: newGridDiv })
              }
            />

            <div className={classes.buttons}>
              <Button
                variant='contained'
                color='secondary'
                onClick={onCancel}
                fullWidth
                size='small'>
                Cancel
              </Button>
              <ColoredButton
                color={regionColors.ZONE}
                onClick={createZoneClicked}
                fullWidth
                disabled={!isValid}
                size='small'>
                Create
              </ColoredButton>
            </div>

            {/* TODO: Confirm create zone button */}
          </List>
        </div>
      );
    }
  )
);
