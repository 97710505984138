import queryString from 'query-string';
import { createSelector } from 'reselect';
import { filterText } from '~/lib/filtering';
import { jumpToSite } from '~/lib/uiHelpers';
import Logging from '~/logging';
import { allFarms } from '~/redux/selectors/global';
import { getUsershapeWKT } from '~/redux/slices/usershape';
import store from '~/store';

import { actions } from './slice';

var lastSelectedRegionId = null;
export const selectedRegionId = createSelector(
  (state) => state.router.location.search,
  (state) => state.regionsScene.regions,
  (state) => state.regionsScene.pendingSingleRegions,

  (query, regions, pendingSingleRegions) => {
    const rval = queryString.parse(query).regionid || null;

    if (rval && rval != lastSelectedRegionId) {
      if (Object.keys(regions).includes(rval) || rval == 'new') {
        lastSelectedRegionId = rval;
        store.dispatch(actions.editRegion(rval)); // will reset the usershape
      } else {
        if (!pendingSingleRegions[rval]) {
          Logging.warn("Attempting to go to region that we don't have loaded", { regionId: rval });
          store.dispatch(actions.getSingleRegion(rval));
        }
      }
    }
    return rval;
  }
);

let requestedFarmIdCache = [];
const requestRegionsOnFirstAccess = (farmid) => {
  // Lazy load regions from farms when we first encounter them
  if (!requestedFarmIdCache.includes(farmid)) {
    requestedFarmIdCache.push(farmid);
    store.dispatch(actions.getFarmRegions(farmid));
  }
};

const newRegion = {
  id: '',
  display_name: 'Unnamed Region',
  type: 'FIELD',
};

// Will return the draft region, or the original if no change.
export const getTmpRegion = createSelector(
  (state) => state.router.location.search,
  allFarms,
  selectedRegionId,
  (state) => state.regionsScene.regions,
  (state) => state.regionsScene.tmpRegions,
  (query, allFarms, id, regions, tmpRegions) => {
    // if editing a usershape, that replaces our boundary

    if (id == 'new') {
      // we're editing a new region
      const farmid = queryString.parse(query).site;

      if (!Object.keys(allFarms).includes(farmid)) {
        Logging.warn("Can't edit region for unknown farm", { farmid });
        return null; // Can only add to farms we can see
      }
      return {
        ...newRegion,
        farm_id: farmid,
        ...(tmpRegions[id] || {}),
      };
    } else if (id !== null) {
      // editing a known region
      return tmpRegions[id] || regions[id];
    } else {
      // not editing
      return null;
    }
  }
);

export const getTmpRegionWithUsershape = createSelector(
  getTmpRegion,
  getUsershapeWKT,
  (region, usershapeWkt) => {
    if (!region) return null;

    let usershapeBoundary = {};

    if (usershapeWkt) {
      usershapeBoundary.boundary = usershapeWkt;
    }

    return { ...region, ...usershapeBoundary };
  }
);

let lastFocussedFarmId = null;
export const focussedFarm = createSelector(
  (state) => state.router.location.search,
  allFarms,
  getTmpRegion,
  (query, allFarms, tmpRegion) => {
    const fid = tmpRegion?.farm_id || queryString.parse(query).site;
    const farm = fid ? allFarms[fid] : null;
    if (farm) {
      requestRegionsOnFirstAccess(fid);

      // if (lastFocussedFarmId != fid) {
      //   jumpToFarm(farm);
      // }
    }
    return farm;
  }
);
const getRegionDisplayMode = (state) => state.global.preferences.regionDisplayMode;

const isTypeDisplayed = (type, displayMode) => {
  switch (type) {
    case 'FIELD':
      return displayMode.analysis;
    case 'FLIGHT':
      return displayMode.flight;
    case 'TARGETED':
      return displayMode.targeted;
    case 'ZONE':
      return displayMode.zone;
    case 'KEEPOUT':
      return displayMode.keepout;
    default:
      return true;
  }
};

export const regionsForFarm = createSelector(
  focussedFarm,
  (state) => state.regionsScene.regions,
  (farm, regions) => {
    return Object.values(regions || {}).filter((r) => r.farm_id == farm?.id && !r.deleted) || [];
  }
);

export const getFilteredRegions = createSelector(
  (state) => state.ui.regionsPageFilter,
  getRegionDisplayMode,
  regionsForFarm,
  (filterValue, displayMode, regions) => {
    let filtered = regions.filter(
      (r) => isTypeDisplayed(r.type, displayMode) && filterText(r.display_name, filterValue)
    );
    filtered.sort((a, b) =>
      a.display_name.localeCompare(b.display_name, undefined, { numeric: true })
    );
    return filtered;
  }
);

export const getRegionById = createSelector(
  (state, id) => id,
  (state) => state.regionsScene.regions,
  (id, regions) => regions[id]
);
