import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { GAevent } from '~/googleAnalytics';
import * as Icons from '~/icons';
import { getColorForNDVI } from '~/lib/colorScales';
import * as geography from '~/lib/geography';
import { displayNameForFarm } from '~/lib/names';
import { jumpToSite } from '~/lib/uiHelpers';
import { term } from '~/markets';
import { getChannel } from '~/redux/commonSelectors';
import { actions as globalActions } from '~/redux/slices/global';
import { actions as uiActions } from '~/redux/slices/ui';
import CropAvatar from '~/shared/CropAvatar';
import FilterBar from '~/shared/FilterBar';
import { HistogramStatic } from '~/shared/Histogram';
import ListItemLink from '~/shared/ListItemLink';
import PanelTopProgress from '~/shared/PanelTopProgress';
import RightPanel from '~/shared/RightPanel';
import { areaUnitText, areatoPref } from '~/lib/units';
import { useSVSelector } from '~/redux/hooks';
import {
  Avatar,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { allVisibleFieldData, filteredFieldData, sitesAtSelectedOrg } from './slice';

const styles = (theme) => ({
  listItem: {
    // paddingLeft: 16,
  },

  fieldCell: {
    display: 'flex',
    flexDirection: 'column',
  },

  histogram: {
    height: 48,
    width: 96,
  },

  sortIcon: {
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.shorter,
    }),
  },

  sortUp: {
    transform: 'rotate(0deg)',
  },
  sortDown: {
    transform: 'rotate(180deg)',
  },

  sortSubheader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: theme.palette.action.hover,
    paddingRight: 6,
    height: 30,
  },
  farmSubheader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: theme.palette.background.paper,
    paddingRight: 6,
    height: 30,
  },

  cropToggle: {
    margin: theme.spacing(0.5),
    display: 'flex',
    justifyContent: 'center',
  },

  cropButtonRoot: {
    height: 32,

    '&.selected': {
      color: theme.palette.primary.main,
    },
  },
});

function OverviewPanel({
  cropTypes,
  zoomTo,

  classes,
  sortKey,
  sortDir,
  setSort,

  overviews,
  fieldData, // id, region, target_notifs, latest_imageset

  allFieldData, // like fieldData but not filtered

  filterValue,
  setFilterValue,

  hideCropTypes,
  setHideCropTypes,

  colorForNDVI,

  channel,

  visibleSites,
  hiddenFarmIds,
  setHiddenFarmIds,
}) {
  const theme = useTheme();

  const [farmOption, setFarmOption] = React.useState(null);

  const areaUnit = useSVSelector((state) => state.global.preferences.areaUnit);

  const fieldsWithAnalysis = fieldData
    .map((f) => {
      const r = f.region;
      const crop = r.crop_type ? cropTypes[r.crop_type] : null;

      if (!f.latest_imageset?.summary) {
        return {
          region: r,
          summary: null,
          avgIndex: null,
          cropName: crop?.name || 'Unspecified',
          name: r.display_name,
        };
      } else {
        // This is mean relative to crop average.
        const avgIndex = f.latest_imageset?.summary?.find((s) => s.index == channel)?.mean;
        return {
          region: r,
          summary: f.latest_imageset?.summary,
          cropName: crop?.name || 'Unspecified',
          avgIndex,
          name: r.display_name,
        };
      }
    })
    .sort((a, b) => {
      if (a[sortKey] == null) return 1;
      if (b[sortKey] == null) return -1;
      if (typeof a[sortKey] == 'string') {
        return a[sortKey].localeCompare(b[sortKey], undefined, { numeric: true }) * sortDir;
      } else {
        return (a[sortKey] - b[sortKey]) * sortDir;
      }
    });

  // This displays a histogram for ALL FIELDS currently loaded.
  const averageHistogram = fieldsWithAnalysis.reduce((sum, x) => {
    const summary = x.summary?.find((s) => s.index == channel);
    for (let i = 0; i < 30; i++) {
      sum[i] += (summary?.histogram?.[i] || 0) / fieldsWithAnalysis.length;
    }
    return sum;
  }, Array(30).fill(0));

  const averageMean =
    fieldsWithAnalysis.reduce((sum, x) => {
      const summary = x.summary?.find((s) => s.index == channel);

      sum += summary?.mean || 0;
      return sum;
    }, 0) / fieldsWithAnalysis.length;

  const averageStd =
    fieldsWithAnalysis.reduce((sum, x) => {
      const summary = x.summary?.find((s) => s.index == channel);

      sum += summary?.std || 0;
      return sum;
    }, 0) / fieldsWithAnalysis.length;

  const averageSummary = {
    histogram: averageHistogram,
    mean: averageMean,
    std: averageStd,
    min: 0, // TODO
    max: 1,
  };

  const toggleSort = (key) => {
    setSort({
      dir: key == sortKey ? -sortDir : sortDir,
      key: key,
    });
  };

  const fieldsByFarm = fieldsWithAnalysis.reduce((o, f) => {
    const farmId = f.region.farm_id;
    return { ...o, [farmId]: [...(o[farmId] || []), f] };
  }, {});

  const anyFarmsLoading = Object.values(overviews).some((o) => o.pending);

  const allCrops = [
    ...new Set(allFieldData.map((f) => f.region.crop_type || 'Unspecified')),
  ].sort();
  const visibleCropTypes = allCrops.filter((c) => !hideCropTypes.includes(c));

  const handleToggleFarmVisibility = () => {
    const id = farmOption.id;
    const hiddenFarmSet = new Set(hiddenFarmIds);
    if (hiddenFarmSet.has(id)) {
      GAevent('Click', `toggle site ${id}`, 'hiddenFarmSet.delete(id)');
      hiddenFarmSet.delete(id);
    } else {
      hiddenFarmSet.add(id);
    }
    setHiddenFarmIds(Array.from(hiddenFarmSet));
    setFarmOption(null);
  };

  return (
    <RightPanel title='Overview' buttons={[]}>
      {anyFarmsLoading && <PanelTopProgress />}

      {allFieldData.length > 0 ? (
        <>
          {(allCrops.length > 1 || visibleCropTypes.length == 0) && (
            <div className={classes.cropToggle}>
              <ToggleButtonGroup
                value={visibleCropTypes}
                onChange={(e, showCrops) => {
                  GAevent('Toggle', 'Toggle asset types', 'setHideCropTypes()');
                  setHideCropTypes(allCrops.filter((c) => !showCrops.includes(c)));
                }}>
                {allCrops.map((crop) => {
                  const Icon = Icons.CropIcon(cropTypes[crop]?.icon);
                  return (
                    <Tooltip title={cropTypes[crop]?.name || 'Unspecified'} key={crop} value={crop}>
                      <ToggleButton
                        size='small'
                        classes={{
                          root: classes.cropButtonRoot,
                          selected: 'selected',
                        }}>
                        <Icon />
                      </ToggleButton>
                    </Tooltip>
                  );
                })}
              </ToggleButtonGroup>
            </div>
          )}
          <div className={classes.module}>
            <FilterBar
              fullWidth
              value={filterValue}
              disabled={allFieldData.length == 0}
              notFound={fieldsWithAnalysis.length == 0}
              onChange={setFilterValue}
              placeholder={`Filter ${term('Assets')}`}
            />
          </div>

          <List disablePadding>
            <ListSubheader className={classes.sortSubheader}>
              <Typography variant='overline'>Sort:</Typography>
              <div style={{ flexGrow: 1 }} />
              <Button
                color='secondary'
                size='small'
                onClick={() => {
                  GAevent('toggle', 'Toggle sort by name', 'toggleSort(name)');
                  toggleSort('name');
                }}>
                Name
                {sortKey == 'name' && (
                  <Icons.UpArrow
                    color='inherit'
                    className={`${classes.sortIcon} ${
                      sortDir > 0 ? classes.sortUp : classes.sortDown
                    }`}
                    fontSize='small'
                  />
                )}
              </Button>

              <Button
                color='secondary'
                size='small'
                onClick={() => {
                  GAevent('Toggle', 'Toggle sort by crop name', 'toggleSort(cropName)');
                  toggleSort('cropName');
                }}>
                {term('Type')}
                {sortKey == 'cropName' && (
                  <Icons.UpArrow
                    color='inherit'
                    className={`${classes.sortIcon} ${
                      sortDir > 0 ? classes.sortUp : classes.sortDown
                    }`}
                    fontSize='small'
                  />
                )}
              </Button>

              <Button
                color='secondary'
                size='small'
                onClick={() => {
                  GAevent('Toggle', 'Toggle sort by average', 'toggleSort(avgIndex)');
                  toggleSort('avgIndex');
                }}>
                Average
                {sortKey == 'avgIndex' && (
                  <Icons.UpArrow
                    color='inherit'
                    className={`${classes.sortIcon} ${
                      sortDir > 0 ? classes.sortUp : classes.sortDown
                    }`}
                    fontSize='small'
                  />
                )}
              </Button>
            </ListSubheader>

            {Object.keys(fieldsByFarm).map((farmId) => {
              return (
                <React.Fragment key={farmId}>
                  {Object.keys(fieldsByFarm).length > 1 && (
                    <ListSubheader className={classes.farmSubheader}>
                      <Typography variant='overline'>{farmId}</Typography>
                    </ListSubheader>
                  )}
                  {fieldsByFarm[farmId].map((f) => {
                    const colorRange = [0, 1];
                    const summary = f.summary?.find((s) => s.index == channel);

                    return (
                      <ListItemLink
                        dense
                        key={f.region.id}
                        to={`/field/${f.region.id}`}
                        // disableGutters
                        className={classes.listItem}
                        button>
                        <ListItemAvatar>
                          <CropAvatar
                            color={summary ? colorForNDVI(summary.mean) : undefined}
                            cropId={f.region.crop_type}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={f.name}
                          secondary={`${geography
                            .areaOfWKT(areatoPref(areaUnit), f.region.boundary)
                            .toFixed(1)} ${areaUnitText(areaUnit)}`}
                        />

                        {summary ? (
                          <ListItemSecondaryAction style={{ pointerEvents: 'none', zIndex: -1 }}>
                            <HistogramStatic
                              className={classes.histogram}
                              dataRange={[-1, 2]}
                              data={summary.histogram || [0, 0]}
                              colorRange={colorRange}
                              windowRange={[0, 1]}
                              remapValues={null}
                            />
                          </ListItemSecondaryAction>
                        ) : null}
                      </ListItemLink>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </List>
        </>
      ) : Object.keys(visibleSites).length > 0 ? (
        // TODO: A list of farms?

        <List disablePadding>
          {Object.values(visibleSites).map((f) => {
            const avatarStyle = hiddenFarmIds.includes(f.id)
              ? { backgroundColor: theme.palette.divider, opacity: 0.8 }
              : { backgroundColor: theme.palette.primary.main, opacity: 1 };

            return (
              <ListItem key={f.id} dense button onClick={jumpToSite(f.id)}>
                <ListItemAvatar>
                  <Avatar style={avatarStyle}>
                    <Icons.Region />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={f.location} secondary={f.grower_id} />
                <ListItemSecondaryAction
                  onClick={(evt) => setFarmOption({ id: f.id, anchorEl: evt.currentTarget })}>
                  <IconButton edge='end'>
                    <Icons.MoreDots />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      ) : (
        <Alert severity='warning'>No {term('sites')} are available for display.</Alert>
      )}

      <Menu
        anchorEl={farmOption?.anchorEl}
        keepMounted
        open={Boolean(farmOption)}
        onClose={() => setFarmOption(null)}>
        <MenuItem onClick={handleToggleFarmVisibility}>
          {hiddenFarmIds.includes(farmOption?.id) ? 'Show' : 'Hide'}
        </MenuItem>
      </Menu>
    </RightPanel>
  );
}

const sortedSites = createSelector(sitesAtSelectedOrg, (farms) => {
  return Object.values(farms).sort(
    (a, b) => a.grower_id.localeCompare(b.grower_id) || a.location.localeCompare(b.location)
  );
});

const mapStateToProps = (state) => ({
  cropTypes: state.global.cropTypes,

  visibleSites: sortedSites(state),
  hiddenFarmIds: state.global.preferences.hiddenFarmIds,

  sortKey: state.global.preferences.overviewSort.key,
  sortDir: state.global.preferences.overviewSort.dir,

  filterValue: state.ui.overviewPageFilter,
  hideCropTypes: state.ui.hideCropTypes,

  overviews: (state) => state.overviewScene.farmOverviews,
  fieldData: filteredFieldData(state),
  allFieldData: allVisibleFieldData(state),

  colorForNDVI: getColorForNDVI(state),

  channel: getChannel(state, 0),
});

const mapDispatchToProps = (dispatch) => ({
  setSort: (sort) => dispatch(globalActions.setPreference({ overviewSort: sort })),
  setFilterValue: (value) => dispatch(uiActions.setOverviewFilterValue(value)),
  setHideCropTypes: (value) => dispatch(uiActions.setHideCropTypes({ hideCropTypes: value })),
  setHiddenFarmIds: (ids) => dispatch(globalActions.setPreference({ hiddenFarmIds: ids })),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OverviewPanel));
