import { DateTime } from 'luxon';
import React from 'react';
import * as Icons from '~/icons';
import { ScheduledFlightRequest, Survey } from '~/schema';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { KeyboardDateTimePicker } from '@material-ui/pickers';

const styles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    '& > *:not(:first-child)': { marginTop: theme.spacing(1) },
  },
}));

// Edit or create a new SFR.
export default function SFREditDialog(props: {
  tz: string;
  onClose: () => void;
  event?: ScheduledFlightRequest;
  updateEvent(evt: ScheduledFlightRequest): Promise<void>;
  deleteEvent(evt: ScheduledFlightRequest): Promise<void>;
  availableSurveys: Survey[];
  availableBases: string[];
}) {
  const classes = styles();

  const [tmpEvent, setTmpEvent] = React.useState<ScheduledFlightRequest | undefined>();

  React.useEffect(() => {
    setTmpEvent(props.event); // Update the internal state when we get a new event.
  }, [props.event]);

  const save = async () => {
    if (tmpEvent !== undefined) await props.updateEvent(tmpEvent);
  };

  if (!tmpEvent) return null; // What do we do if we have no event set?

  const valid = !!tmpEvent.survey_id && !!tmpEvent.base_sn;

  return (
    <>
      <Dialog
        open={event != undefined}
        onClose={props.onClose}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth='xs'>
        <DialogTitle>Scheduled Flight</DialogTitle>
        <DialogContent className={classes.content}>
          <TextField
            fullWidth
            label='Base Station'
            disabled={props.availableBases.length == 0}
            error={!tmpEvent.base_sn}
            select
            onChange={(evt) => setTmpEvent({ ...tmpEvent, base_sn: evt.target.value })}
            value={tmpEvent.base_sn}>
            {props.availableBases.map((b) => (
              <MenuItem key={b} value={b}>
                Base {b}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            fullWidth
            select
            label='Survey'
            value={tmpEvent.survey_id}
            error={!tmpEvent.survey_id}
            onChange={(evt) => {
              const survey_id = evt.target.value;
              setTmpEvent({
                ...tmpEvent,
                survey_id: survey_id == 'none' ? undefined : parseInt(survey_id),
              });
            }}
            SelectProps={{
              defaultValue: 'none',
            }}>
            <MenuItem value='none' disabled>
              No survey selected
            </MenuItem>
            {props.availableSurveys.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.display_name}
              </MenuItem>
            ))}
          </TextField>

          <KeyboardDateTimePicker
            aria-label='planned flight time'
            fullWidth
            disablePast
            label={`Planned Flight Time (${props.tz})`}
            value={tmpEvent.expected_datetime.setZone(props.tz)}
            onChange={(newdt: DateTime | null) => {
              if (newdt) setTmpEvent({ ...tmpEvent, expected_datetime: newdt });
            }}
          />
        </DialogContent>
        <DialogActions>
          {tmpEvent?.id && (
            <IconButton
              style={{ color: 'red' }}
              onClick={async () => await props.deleteEvent(tmpEvent)}>
              <Icons.Delete></Icons.Delete>
            </IconButton>
          )}

          <Button color='primary' onClick={props.onClose}>
            Cancel
          </Button>
          <Button aria-label='save' color='primary' disabled={!valid} onClick={save}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
