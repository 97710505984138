import { DateTime } from 'luxon';
import queryString from 'query-string';
import React from 'react';
import { createSelector } from 'reselect';
import type { Scene } from '~/lib/activeScene';
import * as globalSelctors from '~/redux/selectors/global';
import type { RootState } from '~/store';

import SchedulePage from './SchedulePage';
import OrgSiteLink from '~/shared/Appbar/OrgSiteLink';

const selectedDate = createSelector(
  (state: RootState) => state.router.location.search,
  (queryStr) => {
    const query = queryString.parse(queryStr);
    return (query.d as string) || DateTime.now().toISODate();
  }
);

const scheduleScene: Partial<Scene> = {
  params: (state) => globalSelctors.paramsForPath(state, '/schedule'),

  title: () => 'Survey Schedule',

  breadcrumbs: () => ['Schedule', <OrgSiteLink key='orgsitelink' />],

  component: createSelector(globalSelctors.getQueryOrgAndSite, selectedDate, ({ siteId }, day0) => {
    // If there's no siteid... how do we pick? If you only have one, redirect. If you have multiple... a list?
    if (!siteId) return null;

    return <SchedulePage day0={day0} siteId={siteId} />;
  }),

  helpTopic: () => 'Schedule',
};

export default scheduleScene;
