import classNames from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';
import { connect } from 'react-redux';
import * as Icons from '~/icons';
import route from '~/lib/activeScene';
import { metaForAlerts } from '~/lib/alertHelpers';
import { getColorForNDVI } from '~/lib/colorScales';
import * as geography from '~/lib/geography';
import { parseUTCISO } from '~/lib/time';
import { setTimelineTime } from '~/lib/timeline';
import { term } from '~/markets';
import { getChannel } from '~/redux/commonSelectors';
import { toLocalTz } from '~/redux/commonSelectors';
import { actions as estimateActions } from '~/redux/slices/estimates';
import { actions as globalActions } from '~/redux/slices/global';
import { actions as usershapeActions } from '~/redux/slices/usershape';
import AnalysisControls from '~/shared/AnalysisControls';
import { AutoCollapsableModule, CollapsableModule } from '~/shared/CollapsableModule';
import CropAvatar from '~/shared/CropAvatar';
import PanelTopProgress from '~/shared/PanelTopProgress';
import RightPanel from '~/shared/RightPanel';
import { areaUnitText, areatoPref } from '~/lib/units';
import { useSVSelector } from '~/redux/hooks';
import {
  Avatar,
  Button,
  Checkbox,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

import AvailableImageryFilter from './AvailableImageryFilter';
import ImageryLink from './ImageryLink';
import TargetedLink from './TargetedLink';
import TargetedScoutControls from './TargetedScoutControls';
import ZoneControls from './ZoneControls';
import {
  getCurrentBackgroundImageset,
  getCurrentImageset,
  getFilteredImagesets,
  getPermissions,
  getVisibleZones,
  imagesets,
} from './selectors';
import { actions } from './slice';

/* Old detailed analytics stuff. Will pull it back in, but not here.
                            <MenuItem value='MEAN_PENTILE'>Mean (%)</MenuItem>
                            <MenuItem value='MEAN_ABS'>Mean (Absolute)</MenuItem>
                            <MenuItem value='MEDIAN_PENTILE'>Median (%)</MenuItem>
                            <MenuItem value='MEDIAN_ABS'>Median (Absolute)</MenuItem>
                            <MenuItem value='STD_PENTILE'>Consistency (%)</MenuItem>
                  */

const styles = makeStyles((theme) => ({
  module: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  buttonHeader: {
    background: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(1),
  },

  vertFlex: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  horizFlex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  horizFlexButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },

  targeted: {
    // TODO: this is copied from FlightRow
    background: `linear-gradient(to right, transparent, ${grey[200]})`,
  },

  areaOfConcernAvatar: {
    backgroundColor: '#ff8800',
  },

  cropButton: {},

  filterOptions: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  filterLabel: {
    margin: 0,
  },

  iconMargin: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  sectiontitle: {
    minHeight: 48,
    display: 'flex',
    alignItems: 'center',
  },

  shrinkButton: {
    marginLeft: -4,
    transition: 'transform 0.25s ease',
  },
  rotated: {
    transform: 'rotate(180deg)',
  },
  foldbar: {
    display: 'flex',
    alignItems: 'center',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  analysisControls: {
    overflow: 'hidden',
    flexShrink: 0,
    maxHeight: 300,
    transition: 'max-height 0.25s ease',
  },
  analysisControlsShrunk: {
    maxHeight: 0,
  },
}));

function FieldOverviewPanel({
  mapState,
  setMapState,
  pending,
  field,
  resetUsershape,
  cropType,
  allImagesets,
  filteredImagesets,
  requestReset,
  currentImageset,
  currentBackgroundImageset,
  colorForNDVI,
  setAllChannels,
  setAllAnalysisMode,
  toLocalTz,
  setTimelineTime,
  permissions,
  allAnalysisModes,
  isMapSplit,
  channel,
}) {
  const classes = styles();

  React.useEffect(() => {
    // mount/unmount effect
    return () => setMapState(null); // Clear the map state when we leave this page so nothing weird happens.
  }, [setMapState]);

  const summary = currentImageset?.summary?.find((s) => s.channel == channel); // TODO: if targeted scouts have summaries, make this currentImageset

  const currentDEMRange = currentImageset?.imagery?.find((i) => i.type == 'DEM')?.data_range_x;

  const alerts = allImagesets.reduce(
    (alerts, iset, i) => [
      ...alerts,
      ...(iset.alerts?.map((a) => ({ imagesetIdx: i, ...a })) || []),
    ],
    []
  );

  const analytics = currentImageset?.available_analytics?.find(
    (x) => x.metric == allAnalysisModes[0]
  );

  const areaUnit = useSVSelector((state) => state.global.preferences.areaUnit);
  return (
    <RightPanel>
      {pending && <PanelTopProgress />}

      <div className={classes.module}>
        <CropAvatar
          cropId={cropType?.id}
          style={{
            position: 'absolute',
            top: '50%',
            right: 4,
            transform: 'translateY(-50%)',
          }}
        />

        {field ? (
          <Typography variant='subtitle2'>{cropType?.name || `Unknown ${term('type')}`}</Typography>
        ) : (
          <Skeleton variant='text' />
        )}
        {field ? (
          <Typography variant='subtitle2'>
            {geography.areaOfWKT(areatoPref(areaUnit), field.boundary).toFixed(1)}{' '}
            {areaUnitText(areaUnit)}
          </Typography>
        ) : (
          <Skeleton variant='text' />
        )}
      </div>
      <Divider />
      {/* CURRENT area on pannel */}
      {/* {allAnalysisModes[0] && <AnalysisControls mapidx={0} analysis={analytics} />}
      {isMapSplit && allAnalysisModes[1] && <AnalysisControls mapidx={1} />} */}
      {field && permissions.includes('CREATE_TARGETED') && (
        <TargetedScoutControls
          farm_id={field.farm_id}
          fieldName={field.display_name}
          fields={field.fields}
          expanded={['drawTarget', 'showTarget'].includes(mapState)}
          setExpanded={(x) => {
            requestReset();
            resetUsershape(null);
            setMapState(x ? 'drawTarget' : null);
          }}
        />
      )}

      {alerts.length > 0 && permissions.includes('GET_ALERTS') && (
        <AutoCollapsableModule title='Alerts' Icon={Icons.Warning}>
          <List>
            {alerts.map((alert, i) => {
              const imageset = allImagesets[alert.imagesetIdx];
              const localtime = toLocalTz(parseUTCISO(imageset.timestamp), field.farm_id).format(
                'll'
              );
              const meta = metaForAlerts(alert.alert_type);

              return (
                <ListItem
                  key={i}
                  alignItems='flex-start'
                  button
                  dense
                  onClick={() => {
                    setTimelineTime(0, imageset.timestamp);
                    setAllChannels([meta.channel, meta.channel]);
                    setAllAnalysisMode([meta.analysis, meta.analysis]);
                  }}>
                  <ListItemAvatar>{meta.icon}</ListItemAvatar>
                  <div>
                    <Typography variant='overline'>
                      {localtime.toLocaleString(DateTime.DATETIME_FULL)}
                    </Typography>
                    <Typography variant='body2'>{alert.message}</Typography>
                  </div>
                </ListItem>
              );
            })}
          </List>
        </AutoCollapsableModule>
      )}

      {field && permissions.includes('CREATE_ZONE') && (
        <CollapsableModule
          title='Zones'
          Icon={Icons.Zones}
          expanded={['zoneMode', 'drawZone'].includes(mapState)}
          setExpanded={(x) => {
            setMapState(x ? 'zoneMode' : null);
          }}>
          <ZoneControls field={field} canEdit={permissions.includes('CREATE_ZONE')} />
        </CollapsableModule>
      )}

      <AutoCollapsableModule
        title={`Flights (${filteredImagesets.length})`}
        Icon={Icons.Drone}
        defaultOpen>
        <div className={classes.buttonHeader}>
          <AvailableImageryFilter />
        </div>

        {field ? (
          <List disablePadding>
            {filteredImagesets?.map((imgset) => (
              <React.Fragment key={imgset.timestamp}>
                {imgset.isTargeted ? (
                  <TargetedLink imageset={imgset} farmId={field.farm_id} />
                ) : (
                  <ImageryLink imageset={imgset} farmId={field.farm_id} />
                )}
              </React.Fragment>
            )) || <ListSubheader>No imagery that matches filters</ListSubheader>}
          </List>
        ) : (
          <Skeleton variant='rect' height={80} />
        )}
      </AutoCollapsableModule>
    </RightPanel>
  );
}

const mapStateToProps = (state, props) => ({
  mapState: state.fieldScene.mapState,
  cropType: props.field ? state.global.cropTypes[props.field.crop_type] : null,
  channel: getChannel(state, 0),
  allAnalysisModes: state.global.preferences.analysisMode,
  allImagesets: imagesets(state),
  filteredImagesets: getFilteredImagesets(state),
  currentImageset: getCurrentImageset(state, 0),
  currentBackgroundImageset: getCurrentBackgroundImageset(state, 0),
  colorForNDVI: getColorForNDVI(state),
  setTimelineTime: setTimelineTime(state),
  toLocalTz: toLocalTz(state),
  visibleZones: getVisibleZones(state),
  permissions: getPermissions(state),
  isMapSplit: route.isMapSplit(state),
});

const mapDispatchToProps = (dispatch) => ({
  resetUsershape: () => dispatch(usershapeActions.resetShape()),

  setAllChannels: (x) => dispatch(globalActions.setPreference({ channel: x })),
  setAllAnalysisMode: (x) => dispatch(globalActions.setPreference({ analysisMode: x })),

  setMapState: (x) => dispatch(actions.setMapState(x)),
  requestReset: () => dispatch(estimateActions.requestReset({ key: 'target' })),
  setShowZones: (x) => dispatch(globalActions.setPreference({ showZones: x })),
});

export default connect(mapStateToProps, mapDispatchToProps)(FieldOverviewPanel);
