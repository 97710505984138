import spline from 'cubic-spline';
import React from 'react';
import { connect } from 'react-redux';
import { getColorForNDVI, getColorForNDVIMapB } from '~/lib/colorScales';

import { Slider } from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'stretch',
    pointerEvents: 'auto',
    cursor: 'pointer',
  },
  histogram: {
    position: 'relative',
    height: '100%',
    margin: 0,
  },
  sliderContainer: {
    position: 'relative',
    height: 18,
  },
  sliderMin: {
    position: 'absolute',
    top: 6,
    right: theme.spacing(2),
    left: theme.spacing(2),
  },
  sliderMax: {
    position: 'absolute',
    top: 12,
    right: theme.spacing(2),
    left: theme.spacing(2),
  },

  sliderTrack: {
    opacity: 0.5,
  },
});

function drawHistogram(
  canvas,
  mapidx,
  colorForNDVI,
  colorForNDVIMapB,
  topMargin,
  bottomMargin,
  sideMargin,
  lineColor,
  textColor,
  data,
  dataRange,
  windowRange,
  colorRange,
  interactive,
  unlocked,
  dragging = null,
  remapValues = (x) => x
) {
  // Resize the canvas draw buffer appropriately
  canvas.width = canvas.clientWidth;
  canvas.height = canvas.clientHeight;

  const ctx = canvas.getContext('2d');

  // Make sure the window range is sorted
  const wr = windowRange ? windowRange.concat().sort() : [0, 1];

  // size of the whole canvas
  const w = canvas.width;
  const h = canvas.height;

  // bounds of the area to draw the graph
  const bottom = h - bottomMargin;
  const top = topMargin * 2;
  const left = sideMargin;
  const right = w - sideMargin;

  // size of the area to draw the graph
  const w1 = right - left;
  const h1 = bottom - top;

  // Clear the whole canvas to start with
  ctx.clearRect(0, 0, w, h);

  // Function to find the index in the data given an NDVI value.
  const valToIdx = (v) => ((v - dataRange[0]) / (dataRange[1] - dataRange[0])) * data.length;

  // Function to find the X coordinate on the canvas given the NDVI value.
  const valToX = (v) => left + ((v - windowRange[0]) / (windowRange[1] - windowRange[0])) * w1;

  // Coordinates of the 0-1 range of the color gradient.
  const cr = [valToX(colorRange[0]), valToX(colorRange[1])];
  if (isNaN(cr[0]) || isNaN(cr[1])) {
    console.error(`NaN value in color range!\n${colorRange}\n${windowRange}\n${dataRange}`);
    return;
  }

  // Data index where the window range covers
  const wi = wr.map((v) => valToIdx(v));

  // Two arrays to store the histogram drawing data
  const maxDataY = Math.max(...data);
  const dataY = data.map((v) => Math.pow(v / maxDataY, 0.25));
  const dataX = data.map((v, i) => i);

  // Gradient covering colorRange
  var colorscale = ctx.createLinearGradient(cr[0], 0, cr[1], 0);

  for (let i = 0; i <= 1; i += 0.1)
    colorscale.addColorStop(i, mapidx == 0 ? colorForNDVI(i) : colorForNDVIMapB(i));

  var rangescale = ctx.createLinearGradient(0, (top + bottom) / 2, 0, bottom);
  rangescale.addColorStop(0, 'rgba(0,0,0,0)');
  rangescale.addColorStop(1, 'rgba(0,0,0,0.1)');

  ctx.font = '12px Open Sans';
  ctx.textAlign = 'center';

  if (interactive) {
    ctx.fillStyle = rangescale;
    ctx.beginPath();
    ctx.rect(cr[0], top, cr[1] - cr[0], h1);
    ctx.fill();
  }

  // Draw the histogram
  ctx.beginPath();
  ctx.moveTo(left, bottom);
  for (let x = left; x <= right; x++) {
    const f = (x - left) / w1; // fraction of the way across the graph
    const i = f * (wi[1] - wi[0]) + wi[0];

    const spl = new spline(dataX, dataY);
    ctx.lineTo(x, bottom - Math.max(0, spl.at(i)) * h1);
  }
  ctx.lineTo(right, bottom);
  ctx.fillStyle = colorscale;
  ctx.lineWidth = 0;
  ctx.fill();

  if (interactive) {
    const drawHandle = (x, selected) => {
      if (unlocked) {
        ctx.fillStyle = colorscale;
        ctx.strokeStyle = lineColor;
      } else {
        ctx.fillStyle = textColor;
        ctx.strokeStyle = textColor;
      }

      ctx.lineWidth = selected ? 3 : 2;
      ctx.beginPath();
      ctx.moveTo(x, top + 6);
      ctx.lineTo(x, bottom);
      ctx.stroke();

      ctx.strokeStyle = 'white';
      ctx.beginPath();
      ctx.arc(x, top + 6, 6, 0, 2 * Math.PI);
      ctx.fill();
      if (dragging === null) {
        ctx.stroke();
      }
    };

    drawHandle(cr[0], dragging === 0);
    drawHandle(cr[1], dragging === 1);
  }

  function arange(step, round) {
    const rval = [];
    // For loop is trying to increment by 0
    for (let i = dataRange[0]; i <= dataRange[1]; i += step) rval.push((i / round) * round).toFixed;

    return rval;
  }

  if (textColor) {
    // Labels

    const range = dataRange[1] - dataRange[0]; // dataRange is [0 , 1] for NDVI, NDRE, OSAVI, and Chlorophyll

    const step = Math.round(range / 5); // step = 0

    const labels = step <= 1 ? arange(0.2, 0.1) : arange(step, 1);

    const fixed = range <= 1 ? 2 : 0;

    ctx.strokeStyle = colorscale;
    ctx.lineWidth = 1;

    labels.forEach((v) => {
      const big = true; //Math.abs(v % 0.5) <= 0.001
      ctx.beginPath();
      ctx.moveTo(valToX(v), bottom);
      ctx.lineTo(valToX(v), bottom + (big ? 8 : 6));

      ctx.stroke();

      if (big) {
        ctx.fillStyle = textColor;
        ctx.fillText(remapValues(v).toFixed(fixed), valToX(v), h - 2);
      }
    });
  }

  // Draw the axis line
  ctx.lineWidth = 1;

  ctx.strokeStyle = lineColor;
  ctx.beginPath();
  ctx.moveTo(left, bottom);
  ctx.lineTo(right, bottom);
  ctx.stroke();
}

class HistogramStatic extends React.Component {
  constructor(props) {
    super(props);
    this.canvas = React.createRef();
  }

  drawHistogram() {
    drawHistogram(
      this.canvas,
      this.props.mapidx,
      this.props.colorForNDVI,
      this.props.colorForNDVIMapB,
      this.props.theme.spacing(1),
      0,
      0,
      this.props.theme.palette.secondary.main,
      null, //this.props.theme.palette.secondary.light,
      this.props.data,
      this.props.dataRange,
      this.props.windowRange,
      this.props.colorRange,
      false,
      false,
      null,
      this.props.remapValues
    );
  }

  componentDidMount() {
    this.drawHistogram();
  }

  componentDidUpdate() {
    this.drawHistogram();
  }

  render() {
    const { dataRange, data, classes } = this.props;

    return (
      <div className={`${classes.container} ${this.props.className}`}>
        <canvas ref={this.canvas} className={classes.histogram} />
      </div>
    );
  }
}

const clamp = (a, b) => {
  return [Math.max(a[0], b[0]), Math.min(a[1], b[1])];
};

class HistogramSlider extends React.Component {
  dragging = null;

  constructor(props) {
    super(props);
    this.canvas = React.createRef();

    this.setCanvasRef = (element) => {
      this.canvas = element;

      if (!element) return;

      if (this.props.disabled) return;

      this.canvas.onmousedown = (e) => {
        this.moveTo(e.offsetX);
      };
      this.canvas.onmousemove = (e) => {
        if (this.dragging !== null) {
          this.moveTo(e.offsetX);
        }
      };

      this.canvas.onmouseup = (e) => {
        this.dragging = null;
        this.forceUpdate();
      };
      this.canvas.onmouseout = (e) => {
        this.dragging = null;
        this.forceUpdate();
      };
    };
  }

  moveTo(x) {
    const { windowRange, sliderRange } = this.props;
    // Convert the mouseX to domain
    const sideMargin = this.props.theme.spacing(2);
    const w = this.canvas.width;
    const left = sideMargin;
    const right = w - sideMargin;
    const w1 = right - left;

    const XToVal = (x) => ((sliderRange[1] - sliderRange[0]) * (x - left)) / w1 + sliderRange[0];

    const mouseVal = XToVal(x);
    const distToMin = Math.abs(mouseVal - windowRange[0]);
    const distToMax = Math.abs(mouseVal - windowRange[1]);

    if (this.dragging === null) {
      if (distToMin < distToMax) {
        this.dragging = 0;
      } else {
        this.dragging = 1;
      }
    }

    if (this.dragging == 0) {
      // Move [0]
      this.props.setWindowRange([
        Math.min(Math.max(mouseVal, sliderRange[0]), windowRange[1] - 0.1),
        windowRange[1],
      ]);
    } else {
      // Move [1]
      this.props.setWindowRange([
        windowRange[0],
        Math.max(Math.min(mouseVal, sliderRange[1]), windowRange[0] + 0.1),
      ]);
    }
  }

  drawHistogram() {
    drawHistogram(
      this.canvas,
      this.props.mapidx,
      this.props.colorForNDVI,
      this.props.colorForNDVIMapB,
      this.props.theme.spacing(1),
      22,
      this.props.theme.spacing(2),
      this.props.theme.palette.secondary.main,
      this.props.theme.palette.secondary.light,
      this.props.data,
      this.props.dataRange,
      this.props.sliderRange,
      clamp(this.props.windowRange || [0, 1], this.props.sliderRange || [0, 1]),
      true,
      !this.props.disabled,
      this.dragging,
      this.props.remapValues
    );
  }

  componentDidMount() {
    this.drawHistogram();
  }

  componentDidUpdate() {
    this.drawHistogram();
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <canvas ref={this.setCanvasRef} className={classes.histogram} width={264} height={96} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  colorForNDVI: getColorForNDVI(state),
  colorForNDVIMapB: getColorForNDVIMapB(state),
});

const _HistogramStatic = connect(mapStateToProps)(withTheme(withStyles(styles)(HistogramStatic)));
const _HistogramSlider = connect(mapStateToProps)(withTheme(withStyles(styles)(HistogramSlider)));

export { _HistogramStatic as HistogramStatic, _HistogramSlider as HistogramSlider };
