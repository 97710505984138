import mapboxgl, * as MapboxGl from 'mapbox-gl';
import React from 'react';
import * as GeoJSON from 'geojson';

export interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onClick' | 'onDrag'> {
  map?: mapboxgl.Map;
  draggable?: boolean;
  coordinate: [number, number];
  popup?: mapboxgl.Popup;
  onClick?: (event: MouseEvent) => void;
  onDrag?: (coord: [number, number]) => void;
}

export default function DraggableMarker({
  map,
  draggable,
  coordinate,
  popup,
  onClick,
  onDrag,
  children,
  ...props
}: Props) {
  const [dragging, setDragging] = React.useState(!!draggable);

  const marker = React.useRef<mapboxgl.Marker>();
  const wrapper = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const onClickListener = (e: MouseEvent) => {
      if (onClick != null) {
        onClick(e);
        e.stopPropagation();
      }
    };

    const onDragStart = () => {
      setDragging(true);
    };
    const onDragEnd = () => {
      setDragging(false);
      if (currentMarker) {
        const lngLat = currentMarker.getLngLat();
        onDrag?.([lngLat.lng, lngLat.lat]);
      }
    };

    if (wrapper.current == null) return; // Can't do anything yet

    if (map && marker.current == null) {
      // Creating a new marker!

      const newMarker = new MapboxGl.Marker({
        draggable: !!draggable,
        element: wrapper.current,
      })
        .setLngLat(coordinate)
        .setPopup(popup)
        .addTo(map);

      marker.current = newMarker;
    }

    const currentMarker = marker.current;
    const currentWrapper = wrapper.current;

    if (currentMarker && currentWrapper) {
      // Need to use this rather than react synthetic events to ensure
      // that bubbling works correctly against mapbox
      currentWrapper.addEventListener('click', onClickListener);

      currentMarker.on('dragstart', onDragStart);

      currentMarker.on('dragend', onDragEnd);
    }

    return () => {
      // We re-add the listener each time this runs so we make sure to update the callbacks
      currentWrapper?.removeEventListener('click', onClickListener);
      currentMarker?.off('dragstart', onDragStart);
      currentMarker?.off('dragend', onDragEnd);
    };
  }, [map, marker, coordinate, draggable, onClick, onDrag, popup]);

  // This effect only handles cleaning up the marker.
  React.useEffect(() => {
    return () => {
      marker.current?.remove();
      marker.current?.getElement().remove();
      marker.current = undefined;
    };
  }, []);

  React.useEffect(() => {
    if (!draggable && dragging) setDragging(false);
    marker.current?.setDraggable(!!draggable);
  }, [draggable, dragging]);

  React.useEffect(() => {
    try {
      if (!dragging && coordinate) marker.current?.setLngLat(coordinate);
    } catch (e) {
      console.warn(`Marker coordinate exception: {e}`);
    }
  }, [coordinate, dragging]);

  React.useEffect(() => {
    marker.current?.setPopup(popup);
  }, [popup]);

  //staticContext
  const pointerEvents = draggable || onClick ? 'auto' : 'none';

  return (
    <div {...props} style={{ pointerEvents }}>
      <div style={{ pointerEvents }} ref={wrapper}>
        {children}
      </div>
    </div>
  );
}
