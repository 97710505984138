import classNames from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import * as wkt from 'wellknown';
import * as Icons from '~/icons';
import { isFeatureContainedBy, resolutionAt } from '~/lib/geography';
import { showSnackbar } from '~/lib/modalServices';
import { actions as estimateActions } from '~/redux/slices/estimates';
import { actions as globalActions } from '~/redux/slices/global';
import { getUsershapeFeatures, getUsershapeValid, getUsershapeWKT } from '~/redux/slices/usershape';
import { actions as usershapeActions } from '~/redux/slices/usershape';
import { CollapsableModule } from '~/shared/CollapsableModule';
import PanelTopProgress from '~/shared/PanelTopProgress';

import {
  Button,
  CircularProgress,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Slider,
  TextField,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import booleanContains from '@turf/boolean-contains';
import { feature } from '@turf/helpers';

import { getFieldData, getRegion } from './selectors';
import { actions } from './slice';
import { GAevent } from '~/googleAnalytics';

const styles = (theme) => ({
  presetLabel: {
    justifyContent: 'space-between',
    marginLeft: 12,
    marginRight: 12,
  },
  selectedPreset: {
    flexGrow: 1,
    color: 'white',
    backgroundColor: theme.palette.secondary.main,
    margin: 2,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  deselectedPreset: {
    flexGrow: 1,
    margin: 2,
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    borderStyle: 'solid',
    borderWidth: 1,
  },
});

const presets = {
  a: {
    altFt: 400,
    altitude: 121.92,
    frontlap: 0.8,
    sidelap: 0.7,
    margin: 2,
  },
  b: {
    altFt: 200,
    altitude: 60.96,
    frontlap: 0.8,
    sidelap: 0.7,
    margin: 10,
  },
  c: {
    altFt: 100,
    altitude: 30.48,
    frontlap: 0.8,
    sidelap: 0.7,
    margin: 18,
  },
  d: {
    altFt: 50,
    altitude: 15.24,
    frontlap: 0.8,
    sidelap: 0.7,
    margin: 26,
  },
  e: {
    altFt: 25,
    altitude: 7.62,
    frontlap: 0.8,
    sidelap: 0.7,
    margin: 34,
  },
};

const isContainedInField = createSelector(
  getUsershapeFeatures,
  getRegion,
  (usershapeFeatures, field) => {
    if (!field || usershapeFeatures.length == 0) return false;

    const field_poly = feature(wkt.parse(field.boundary));
    return isFeatureContainedBy(usershapeFeatures[0], [field_poly]); // we know that targeted scouting only uses one shape
  }
);

const getTmpSurvey = createSelector(
  (state, props) => props.farm_id,
  getRegion,
  (state) => state.global.preferences.targetedPreset,
  getUsershapeWKT,

  (farm_id, field, targetedPreset, usershapeWkt) => ({
    high_priority: true,
    keepout_others: false,
    needs_approval: true,
    deleted: false,
    enabled: true,
    farm_id: farm_id,
    frequency: 'ONCE',
    start_date: DateTime.now().toISODate(),
    type: 'TARGETED',
    window_end: '18:00:00',
    window_start: '06:00:00',
    payload_type: 'MICASENSE_REDEDGE_MX', // TODO: This is a hack for us not knowing what payloads are available!
    payload_device_name: 'MICASENSE_REDEDGE_MX',
    target_requests: [
      {
        altitude: presets[targetedPreset].altitude,
        frontlap: presets[targetedPreset].frontlap,
        sidelap: presets[targetedPreset].sidelap,
        boundary: usershapeWkt,
      },
    ],
  })
);

const mapStateToProps = (state, props) => ({
  ceiling: state.global.farms[props.farm_id].altitude_ceiling || 122, // Yeah, hard coding 400' for targeted scouts
  isPendingUsershape: state.usershape.type == null,
  isAddingUsershape: state.usershape.isAdding,
  isDraggingUsershape: state.usershape.isDragging,
  isShapeValid: getUsershapeValid(state),

  targetedPreset: state.global.preferences.targetedPreset,
  tmpSurvey: getTmpSurvey(state, props),
  containedInField: isContainedInField(state),
  field: getFieldData(state),
  baseLocation: state.global.farms[props.farm_id].planned_base_location,
  estimate: state.estimates.estimates.target,
});

const mapDispatchToProps = (dispatch) => ({
  setUsershape: (x) => dispatch(usershapeActions.setUsershape(x)),
  setTargetedPreset: (x) => dispatch(globalActions.setPreference({ targetedPreset: x })),
  createTargetedSurvey: (survey, fieldid) =>
    dispatch(actions.createTargetedSurvey(survey, fieldid)),
  fetchSurveyEstimate: (survey, base) =>
    dispatch(estimateActions.fetchSurveyEstimate('target', survey, base)),
  requestReset: () => dispatch(estimateActions.requestReset({ key: 'target' })),
});

let waitingForCreation = false;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(styles)(
    ({
      classes,
      field,
      ceiling,
      estimate,
      expanded,
      setExpanded,
      isPendingUsershape,
      isAddingUsershape,
      isDraggingUsershape,

      containedInField,
      isShapeValid,
      targetedPreset,
      tmpSurvey,
      createTargetedSurvey,
      setTargetedPreset,
      fieldName,
      fetchSurveyEstimate,
      requestReset,
      baseLocation,
    }) => {
      const [displayName, setDisplayName] = React.useState(
        `Tgt:${fieldName} ${DateTime.now().toISODate()}`
      );

      const submitSurvey = () => {
        waitingForCreation = true;
        createTargetedSurvey({ ...tmpSurvey, display_name: displayName }, field.region.id);
      };

      if (waitingForCreation && !field.creatingTargetedScout) {
        waitingForCreation = false;
        if (field.creatingTargetedScoutError) {
          showSnackbar('error', 'Targeted survey error.');
        } else {
          showSnackbar('success', 'Targeted survey submitted!');
        }
        setExpanded(false);
      }

      const buttonForPreset = (x) => {
        return (
          ceiling >= presets[x].altitude && (
            <Button
              className={targetedPreset == x ? classes.selectedPreset : classes.deselectedPreset}
              classes={{ label: classes.presetLabel }}
              style={{
                marginRight: presets[x].margin,
                marginLeft: presets[x].margin,
              }}
              onClick={() => {
                GAevent(
                  'Click',
                  `Set target resolution to ${resolutionAt(presets[x].altitude)}`,
                  'setTargetPreset(x)'
                );
                setTargetedPreset(x);
              }}>
              <span>{resolutionAt(presets[x].altitude)}</span>
            </Button>
          )
        );
      };

      const surveyReady =
        !isDraggingUsershape &&
        !isAddingUsershape &&
        isShapeValid &&
        containedInField &&
        !isPendingUsershape;

      React.useEffect(() => {
        if (surveyReady) {
          fetchSurveyEstimate(tmpSurvey, baseLocation);
        } else {
          requestReset();
        }
      }, [tmpSurvey, surveyReady, fetchSurveyEstimate, baseLocation, requestReset]);

      return (
        <CollapsableModule
          title='Targeted Scouting'
          Icon={Icons.Targeted}
          expanded={expanded}
          setExpanded={setExpanded}>
          {estimate?.pending && <PanelTopProgress progress={estimate.progress} />}

          <List>
            <Collapse in={isPendingUsershape}>
              <ListItem>
                <ListItemText primary='Draw a boundary inside a single field to scout that area at high resolution.' />
              </ListItem>
            </Collapse>
            <Collapse in={isAddingUsershape === true}>
              <ListItem>
                <ListItemText primary='Complete the shape to continue.' />
              </ListItem>
            </Collapse>

            <Collapse in={isAddingUsershape === false && !isShapeValid}>
              <ListItem>
                <ListItemText
                  primaryTypographyProps={{ color: 'error' }}
                  primary='Polygon is invalid; please remove self-intersections.'
                />
              </ListItem>
            </Collapse>

            <Collapse
              in={
                !isDraggingUsershape &&
                !isAddingUsershape &&
                isShapeValid &&
                !containedInField &&
                !isPendingUsershape
              }>
              <ListItem>
                <ListItemText
                  primaryTypographyProps={{ color: 'error' }}
                  primary='Must be contained within a field.'
                />
              </ListItem>
            </Collapse>

            <ListItem>
              <Button
                fullWidth
                disabled={!surveyReady || field.creatingTargetedSurvey}
                color='primary'
                variant='contained'
                size='large'
                onClick={submitSurvey}>
                <Icons.Targeted style={{ marginRight: 8 }} />
                Scout
              </Button>
            </ListItem>

            <ListItem>
              <TextField
                fullWidth
                id='display_name'
                label='Targeted Survey Name'
                value={displayName}
                onChange={(e) => {
                  setDisplayName(e.target.value);
                }}
              />
            </ListItem>

            <ListItem style={{ flexDirection: 'column', alignItems: 'stretch' }}>
              {buttonForPreset('a')}
              {buttonForPreset('b')}
              {buttonForPreset('c')}
              {buttonForPreset('d')}
              {buttonForPreset('e')}
            </ListItem>
          </List>
        </CollapsableModule>
      );
    }
  )
);
