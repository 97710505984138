import { DateTime } from 'luxon';
import createCachedSelector from 're-reselect';
import { ParametricSelector, Selector, createSelector } from 'reselect';
import { Farm } from '~/schema';
import { RootState } from '~/store';

export const getAllChannels: Selector<any, string | [string, string]> = createSelector(
  (state) => state.global.preferences.channel,
  (c: string) => (c ? (Array.isArray(c) ? c : [c, c]) : ['RGB', 'NDVI'])
);

export const getChannel = createCachedSelector(
  (state: RootState) => state.global.preferences.channel,
  (_: RootState, mapidx: number) => mapidx || 0,
  (channel: string | string[], mapidx: number) => {
    if (Array.isArray(channel)) return channel[mapidx];
    else return channel;
  }
)((_, mapidx) => mapidx || 0);

export const toLocalTz = createSelector(
  (state: RootState) => state.global.farms,
  (farms: { [id: string]: Farm }) => {
    return (time: DateTime | undefined, farmid: string) => {
      const farmtz = farms[farmid]?.timezone || 'utc';
      return time?.setZone(farmtz);
    };
  }
);

export const getDataColors: ParametricSelector<any, string, string> = createCachedSelector(
  (state: RootState) => state.global.preferences.dataColors,
  (_: RootState, channel: string) => channel,
  (palette, channel) => {
    // We override the chosen palette if we're viewing thermal. Should let this be an option someday.
    return channel == 'THERMAL' ? 'inferno' : palette;
  }
)((_, channel) => channel);
