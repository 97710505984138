import { DateTime } from 'luxon';
import React from 'react';
import * as Icons from '~/icons';
import route from '~/lib/activeScene';
import { parseUTCISO } from '~/lib/time';
import { getTimelineTime } from '~/lib/timeline';
import { useSVSelector } from '~/redux/hooks';
import MapLegendControlContainer from '~/shared/MapLegendControlContainer';
import { TimelineButtons, TimelineSlider } from '~/shared/timeline/ImageryTimeline';

import { makeStyles, useTheme } from '@material-ui/core/styles';

const FMT_DATE: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' };
const FMT_TIME: Intl.DateTimeFormatOptions = DateTime.TIME_24_WITH_SHORT_OFFSET;

const styles = makeStyles((theme) => ({
  detailContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

interface Props {
  mapidx: number;
}

export default function MapTimestamp({ mapidx }: Props) {
  const theme = useTheme();

  const classes = styles();

  const timestamp = useSVSelector((state) => getTimelineTime(state, mapidx));
  const hasTimeline = useSVSelector(route.hasTimeline);

  if (!hasTimeline || hasTimeline.length == 0) return null;
  const ta = timestamp ? parseUTCISO(timestamp) : parseUTCISO(hasTimeline[0]); // null means latest

  const taFmt = ta ? (
    <>
      <b>{ta.toLocaleString(FMT_DATE)}</b>, {ta.toLocaleString(FMT_TIME)}
    </>
  ) : null;

  return (
    <MapLegendControlContainer
      title={taFmt}
      Icon={Icons.Schedule}
      collapsed_width={theme.spacing(24)}
      collapsed_height={0}
      detail_width={theme.spacing(36)}
      detail_height={theme.spacing(8.5)}
      alwaysVisible={null}
      disabled={false}
      detailView={
        <div className={classes.detailContainer}>
          <TimelineSlider mapidx={mapidx} />
          <div className={classes.buttonContainer}>
            <TimelineButtons mapidx={mapidx} />
          </div>
        </div>
      }
    />
  );
}
