import React from 'react';

import { ThemeOptions } from '@material-ui/core';
import { indigo, red, teal, yellow } from '@material-ui/core/colors';
import AgIcon from '@material-ui/icons/Eco';
import ErrIcon from '@material-ui/icons/Error';
import IndIcon from '@material-ui/icons/Public';
import DefIcon from '@material-ui/icons/TrackChanges';
import SolarIcon from '@material-ui/icons/WbSunny';

export type IndustryType = 'Generic' | 'Agriculture' | 'Industrial' | 'Solar' | 'Defense';

let market: IndustryType | undefined;

const TERMINOLOGY: Record<IndustryType, Record<string, string>> = {
  Agriculture: {
    org: 'grower',
    orgs: 'growers',
    site: 'farm',
    sites: 'farms',
    asset: 'field',
    assets: 'fields',
    type: 'crop',
  },
  Industrial: {},
  Solar: {},
  Defense: {},
  Generic: {},
};

export function ICONS(market: IndustryType): React.ReactNode {
  const icons: Record<IndustryType, React.ReactNode> = {
    Agriculture: AgIcon,
    Industrial: IndIcon,
    Solar: SolarIcon,
    Defense: DefIcon,
    Generic: React.Fragment,
  };

  return icons[market] || ErrIcon;
}

export const MARKET_THEMES = (darkmode: boolean, market: IndustryType): Partial<ThemeOptions> =>
  ({
    Generic: {
      palette: {
        primary: {
          main: darkmode ? teal[400] : teal[700],
        },
        secondary: {
          main: '#282828', //grey[800],
        },
      },
    },
    Agriculture: {
      palette: {
        primary: {
          main: darkmode ? teal[400] : teal[700],
        },
        secondary: {
          main: '#282828', //grey[800],
        },
      },
    },
    Industrial: {
      palette: {
        primary: {
          main: darkmode ? indigo[400] : indigo[500],
        },
        secondary: {
          main: '#282828', //grey[800],
        },
      },
    },

    Solar: {
      palette: {
        primary: {
          main: darkmode ? yellow[700] : yellow[800],
        },
        secondary: {
          main: '#282828', //grey[800],
        },
      },
    },

    Defense: {
      palette: {
        primary: {
          main: darkmode ? red[400] : red[900],
        },
        secondary: {
          main: '#282828', //grey[800],
        },
      },
    },
  }[market] || {});

export const setMarket = (x?: IndustryType) => {
  market = x;
};

export const getMarket = () => {
  return market;
};

function toTitleCase(x: string) {
  return x[0].toUpperCase() + x.substr(1).toLowerCase();
}

function isTitleCase(x: string) {
  return x == toTitleCase(x);
}
function isLowerCase(x: string) {
  return x == x.toLowerCase();
}
function isUpperCase(x: string) {
  return x == x.toUpperCase();
}

export function term(key: string) {
  const key_lc = key.toLowerCase();

  // return the terminology mapping for the given market, matching case
  const terminology = TERMINOLOGY[market || 'Agriculture'];

  const term = terminology[key_lc] || key_lc;

  //  Match term to case of key
  if (isTitleCase(key)) return toTitleCase(term);
  if (isLowerCase(key)) return term.toLowerCase();
  if (isUpperCase(key)) return term.toUpperCase();
  return term;
}
