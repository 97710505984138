import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import history from '~/lib/history';

import {
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { amber, blue, green, red } from '@material-ui/core/colors';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import MiscHardwareIcon from '@material-ui/icons/DeveloperBoard';
import SystemIcon from '@material-ui/icons/DeviceHub';
import { TreeItem, TreeView } from '@material-ui/lab';
import Pagination from '@material-ui/lab/Pagination';

export default function FactSheet({ data }) {
  const [editingMfgDate, setEditingMfgDate] = React.useState(false);

  return (
    <Paper>
      <List>
        <ListItem dense>
          <ListItemText
            primary={data.current_hw.values.mfg_id || 'None on file'}
            secondary='Manufacturer'
          />
        </ListItem>
        <ListItem dense>
          <ListItemText
            primary={data.total_flight_hrs.toFixed(1) + ' h'}
            secondary='Total flight hours'
          />
        </ListItem>
      </List>
    </Paper>
  );
}
