import { DateTime } from 'luxon';
import React from 'react';
import * as Icons from '~/icons';
import * as api from '~/lib/api';
import { showSnackbar } from '~/lib/modalServices';
import Logging from '~/logging';
import { useSVSelector } from '~/redux/hooks';
import { expectedTypes } from '~/scenes/part/partExpectations';
import { Part } from '~/schema';
import { PartState } from '~/schema/Part';
import SelectOrOther from '~/shared/SelectOrOther';

import {
  Button,
  Dialog,
  DialogContent,
  MenuItem,
  MobileStepper,
  TextField,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { DatePicker } from '@material-ui/pickers';

const initialStates = ['PENDING_MFG', 'MFG_IN_PROGRESS', 'BRINGUP', 'INVENTORY', 'IN_SERVICE'];

const defaultNewPart: Partial<Part> = {
  state: 'PENDING_MFG',
  changelog: 'Created',
  mfg_date: DateTime.now().toISODate(),
};

interface Props {
  show: boolean;
  onClose: (part?: Part) => void;
  fixedType?: string;
  allowMultiple: boolean;
}

function NewPartDialog({ show, onClose, fixedType, allowMultiple }: Props) {
  // TODO: These probably don't exist when we're created from the parts scene! Need to pass them in as props.
  const types = useSVSelector((state) => state.hardwareScene.types);
  const locations = useSVSelector((state) => state.hardwareScene.locations);

  const my_org_permissions = useSVSelector((state) => state.global.org_permissions);
  const my_hw_orgs = Object.keys(my_org_permissions).filter((org_id) =>
    my_org_permissions[org_id].includes('GET_PART')
  );

  const myDefaultNewPart: Partial<Part> = {
    ...defaultNewPart,
    org_id: my_hw_orgs.length != 1 ? undefined : my_hw_orgs[0],
  };

  const [newPart, setNewPart] = React.useState<Partial<Part>>(myDefaultNewPart);
  const [step, setStep] = React.useState(0);

  const alltypes = [...new Set([...types, ...expectedTypes])];

  const reset = () => {
    setNewPart({ ...myDefaultNewPart });
    setStep(0);
  };

  const save = async (newPart: Partial<Part>) => {
    try {
      const createdPart = (await api.webrequest('POST', `sv/hardware/${newPart.type}`, {
        ...newPart,
      })) as Part;

      showSnackbar('success', 'Created part.');
      onClose(createdPart);
    } catch (error) {
      Logging.error('Failed to create new part', error);

      showSnackbar('error', 'Could not create part.');
    }
  };

  const creating_sns = [...new Set(newPart.serial_number?.split(',') || [])];

  const handleConfirm = () => {
    const savePart = (p: Partial<Part>) => {
      if (fixedType) {
        save({ ...p, manufacturer: p.org_id, type: fixedType });
      } else {
        save({ ...p, manufacturer: p.org_id });
      }
    };

    if (allowMultiple) {
      // Save one for each unique entered SN.
      for (const sn of creating_sns) {
        savePart({ ...newPart, serial_number: sn });
      }
    } else {
      // Just save the one.
      savePart(newPart);
    }
    reset();
  };

  const handleCancel = () => {
    reset();
    onClose();
  };

  const setPartField = (update: Partial<Part>) => {
    const partUpdate = { ...newPart };
    // We might have some constraints to implement here
    for (const field of Object.keys(update)) {
      let value = update[field as keyof Part];

      if (field == 'type') {
        value = (value as string)
          .replace(' ', '_')
          .toLowerCase()
          .replace(/[^a-z_]*/g, '');
      }

      if (field == 'serial_number') {
        // If we allow multiple serial numbers, let the field have commas
        const disallowedRegex = allowMultiple ? /[^$A-Z0-9-,]/g : /[^$A-Z0-9-]/g;

        value = (value as string)
          .replace(' ', '-')
          .replace('_', '-')
          .toUpperCase()
          .replace(disallowedRegex, '');
      }

      (partUpdate[field as keyof Part] as any) = value;
    }

    setNewPart(partUpdate);
  };

  const basicTextInput = (field: keyof Part, title: string, etc = {}) => (
    <TextField
      fullWidth
      label={title}
      value={newPart[field] || ''}
      onChange={(e) => setPartField({ [field]: e.target.value })}
      {...etc}
    />
  );

  const newPartType = fixedType || newPart.type;

  const stepContents = [
    {
      dom: (
        <>
          <Typography gutterBottom variant='body1'>
            This dialog will guide you through the steps required to create new hardware entries in
            ScoutView.
          </Typography>

          {allowMultiple && (
            <Typography gutterBottom variant='body1'>
              You can bring up multiple identical parts simultaneously.
            </Typography>
          )}

          <SelectOrOther
            required
            label='Type of part'
            value={newPartType || ''}
            disabled={!!fixedType}
            onChangeSelect={(x) => setPartField({ type: x })}
            options={alltypes}
            allowArbitrary
          />
        </>
      ),
      complete: () => newPartType,
    },

    {
      dom: (
        <>
          <Typography gutterBottom variant='body1'>
            Please enter the following details about manufacture.
          </Typography>

          <SelectOrOther
            required
            label='Manufacturer'
            value={newPart.org_id || ''}
            onChangeSelect={(x) => {
              setPartField({ org_id: x });
            }}
            options={my_hw_orgs}
            allowArbitrary={false}
            disabled={false}
          />

          <SelectOrOther
            required
            label='Current Location'
            value={newPart.location || ''}
            onChangeSelect={(x) => setPartField({ location: x })}
            options={locations}
            allowArbitrary
            disabled={false}
          />

          <TextField
            select
            fullWidth
            label='Initial state'
            value={newPart.state}
            onChange={(e) => setPartField({ state: e.target.value as PartState })}>
            {initialStates.map((s) => (
              <MenuItem key={s} value={s}>
                {s}
              </MenuItem>
            ))}
          </TextField>

          <DatePicker
            fullWidth
            label='Manufacture Date (yyyy-mm-dd)'
            id='mfg_date'
            format='yyyy-MM-dd'
            value={DateTime.now()}
            onChange={(date) => setPartField({ mfg_date: date?.toISODate() })}
          />
        </>
      ),
      complete: () => Boolean(newPart.org_id) && Boolean(newPart.location),
    },

    {
      dom: (
        <>
          {allowMultiple ? (
            <Typography gutterBottom variant='body1'>
              Enter the new {newPartType} serial numbers you would like to add, separated by commas.
            </Typography>
          ) : (
            <Typography gutterBottom variant='body1'>
              Enter the serial number of the new {newPartType} you would like to add.
            </Typography>
          )}
          {basicTextInput('serial_number', 'Serial number', { required: true })}
        </>
      ),
      complete: () => Boolean(newPart.serial_number),
    },

    {
      dom: (
        <>
          <Typography gutterBottom variant='body1'>
            Please enter some optional version information, and any initial content for the notes
            field.
          </Typography>
          {basicTextInput('configuration', 'Configuration')}
          {basicTextInput('revision', 'Revision')}
          {basicTextInput('notes', 'Initial Notes', { multiline: true })}
        </>
      ),
      complete: () => true,
    },
  ];

  const theme = useTheme();
  return (
    <Dialog open={show} fullWidth maxWidth='sm' disableBackdropClick onClose={handleCancel}>
      {/* <DialogTitle>Bringup {allowMultiple ? 'Parts' : 'Part'}</DialogTitle> */}

      <DialogContent style={{ overflowY: 'auto' }}>
        <Typography gutterBottom variant='h5'>
          Bringup Wizard
        </Typography>
        {stepContents[step].dom}
      </DialogContent>
      {/* <DialogActions> */}
      <MobileStepper
        variant='progress'
        steps={stepContents.length}
        position='static'
        activeStep={step}
        // className={classes.root}
        nextButton={
          step == stepContents.length - 1 ? (
            <Button size='small' disabled={!stepContents[step].complete()} onClick={handleConfirm}>
              {allowMultiple
                ? `Create ${newPartType} x ${creating_sns.length}`
                : `Create ${newPartType}`}
              <Icons.Save />
            </Button>
          ) : (
            <Button
              size='small'
              onClick={() => setStep(step + 1)}
              disabled={step == stepContents.length - 1 || !stepContents[step].complete()}>
              Next
              {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </Button>
          )
        }
        backButton={
          <Button size='small' onClick={() => (step == 0 ? handleCancel() : setStep(step - 1))}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            {step == 0 ? 'Cancel' : 'Back'}
          </Button>
        }
      />
    </Dialog>
  );
}

export default NewPartDialog;
