import React from 'react';
import { useSelector } from 'react-redux';
import * as wkt from 'wellknown';
import route from '~/lib/activeScene';
import { addStaticMapPopup } from '~/lib/globalMapAccess';
import { degCtoPref, tempUnitText } from '~/lib/units';
import DividerSpacer from '~/shared/DividerSpacer';
import HelpHere from '~/shared/HelpHere';

import { Divider, List, ListItem, ListItemText, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import * as turf from '@turf/turf';

import { BasicOverlayControlComponent } from './common';

const PREF_KEY = 'analysis_solar_fault';
const DEFAULT_PREFS = {};
const CTRL_SZ = [288, 22 * 8];

export const name = 'Solar Faults';
export const description = 'Identify faulty solar panels by their heat signature.';

/*
This analysis data has entries under 'panels' like this:
i: int index
p: [x,y] as lat lon
h: heading, deg
s: [x,y] size in meters
mx: max temp 
mn: mean of nearby panels
*/

function geometryForAsset(a) {
  // Unpack our oddly packed assets into something simpler
  const [lon, lat, szx, szy, orient] = [
    parseFloat(a.p[0]),
    parseFloat(a.p[1]),
    a.s[0] / 100,
    a.s[1] / 100,
    a.h + 90,
  ];

  var center = turf.point([lon, lat]);
  var front = turf.destination(center, szx / 2, -orient, { units: 'meters' });
  var back = turf.destination(center, szx / 2, 180 + -orient, {
    units: 'meters',
  });

  var fl = turf.destination(front, szy / 2, -orient + 90, { units: 'meters' });
  var fr = turf.destination(front, szy / 2, -orient - 90, { units: 'meters' });

  var bl = turf.destination(back, szy / 2, 180 + -orient - 90, {
    units: 'meters',
  });
  var br = turf.destination(back, szy / 2, 180 + -orient + 90, {
    units: 'meters',
  });

  return turf.polygon([
    [
      fl.geometry.coordinates,
      fr.geometry.coordinates,
      br.geometry.coordinates,
      bl.geometry.coordinates,

      fl.geometry.coordinates,
    ],
  ]);
}

function toDegreesMinutesAndSeconds(coordinate) {
  var absolute = Math.abs(coordinate);
  var degrees = Math.floor(absolute);
  var minutesNotTruncated = (absolute - degrees) * 60;
  var minutes = Math.floor(minutesNotTruncated);
  var seconds = ((minutesNotTruncated - minutes) * 60).toFixed(3);

  return degrees + '°' + minutes + "'" + seconds + '"';
}

export function clickables(analysis) {
  const { count, std, panels } = parseAssets(analysis);

  let clickables = [];

  for (let i = 0; i < panels.length; i++) {
    const panel = panels[i];

    const lat = parseFloat(panel.p[1]);
    const lon = parseFloat(panel.p[0]);

    const latitude = toDegreesMinutesAndSeconds(lat);
    const longitude = toDegreesMinutesAndSeconds(lon);

    clickables.push({
      feature: wkt.stringify(geometryForAsset(panel)),
      cursor: 'crosshair',
      onClick: (point) => {
        addStaticMapPopup(
          <div
            style={{
              background: '#fff',
            }}>
            <div
              style={{
                padding: 4,
                width: '100%',
                background: 'rgba(1,1,1,0.2)',
              }}>
              <Typography variant='subtitle2'>Panel ID #{panel.i}</Typography>
            </div>
            <div
              style={{
                padding: 4,
              }}>
              <Typography variant='body2'>Location:</Typography>
              <Typography variant='body2' color='textSecondary'>
                {latitude}
                {lat >= 0 ? 'N' : 'S'}
              </Typography>
              <Typography variant='body2' color='textSecondary'>
                {longitude}
                {lon >= 0 ? 'E' : 'W'}
              </Typography>
              <Divider />
              <Typography variant='body2'>Average temp:</Typography>
              <Typography variant='body2' color='textSecondary'>
                {degCtoPref(panel.mn / 10)}
                {tempUnitText()}
              </Typography>
              <Divider />
              <Typography variant='body2'>Panel peak temp:</Typography>
              <Typography variant='body2' color='textSecondary'>
                {degCtoPref(panel.mx / 10)}
                {tempUnitText()}
              </Typography>
              <Typography variant='body2' color='textSecondary'>
                +{(((panel.mx - panel.mn) / panel.mn) * 100).toFixed(1)}% /{' '}
                {((panel.mx - panel.mn) / 10 / std).toFixed(1)}&sigma;
              </Typography>
            </div>
          </div>,
          lat,
          lon
        );
      },
    });
  }
  return clickables;
}

function parseAssets(analysis) {
  const data = JSON.parse(analysis.general_data);

  /*
    {
      "count": 20158, 
      "std": "0.045306", 
      "panels": [
        {
          "i": 31, 
          "p": ["-70.740674", "41.797932"], 
          "h": -90, 
          "s": [125, 88], 
          "mx": 306, 
          "mn": 303
        }, 
  }*/

  return data;
}

export function overlay(key, analysis, cell_data, preferences, colorForNDVI) {
  // Data contains a list of objects.
  // They are rectangles with a given angle heading, size, and type
  // we want to be able to get more information when clicked, how? it's not a region

  const { count, std, panels } = parseAssets(analysis);

  const source = {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: panels.map((a, i) => {
        const anomaly = (a.mx - a.mn) / 10 / parseFloat(std);

        return {
          type: 'Feature',
          properties: {
            index: i,
            str: `${(a.mx / 10).toFixed(1)}°`,
            anomaly,
          },
          geometry: geometryForAsset(a).geometry,
        };
      }),
    },
  };

  let layers = [];

  const color_interp = [
    'interpolate',
    ['linear'],
    ['get', 'anomaly'],
    5,
    'black',
    10,
    'red',
    15,
    'white',
  ];

  layers.push({
    id: `analysis-layer-${name}`,
    type: 'fill',
    source: `analysis-source-${name}`,
    paint: {
      // 'fill-color': ['get', 'color'],
      'fill-color': color_interp,
      'fill-opacity': preferences.analysisOpacity,
    },
  });

  layers.push({
    id: `analysis-layer-border-${name}`,
    type: 'line',
    source: `analysis-source-${name}`,
    paint: {
      'line-color': color_interp,
      'line-width': 1,
    },
  });

  if (preferences.analysisLabels) {
    // Also push up an overlay including raw numbers
    layers.push({
      id: `analysis-layer-ids-${name}`,
      type: 'symbol',
      source: `analysis-source-${name}`,
      layout: {
        'text-field': ['get', 'str'],
        'text-size': ['interpolate', ['linear'], ['zoom'], 18, 12, 26, 32],
        'text-padding': 1,
      },

      paint: {
        'text-color': 'hsl(0, 0%, 100%)',
        'text-halo-color': 'rgba(0,0,0,0.5)',
        'text-halo-width': 2,
        'text-halo-blur': 1,
      },
    });
  }

  return [
    {
      // Detail overlay for current trid
      srcid: `analysis-source-${name}`,
      source,
      layers,
    },
  ];
}

const listStyles = makeStyles((theme) => ({
  assetEntry: {
    borderStyle: 'solid',
    borderWidth: [[0, 0, 0, 4]],
    position: 'relative',
  },
  assetBg: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0.2,
  },

  help: {
    position: 'absolute',
    top: 4,
    right: 4,
  },
}));

function ControlComponent({ mapidx }) {
  const classes = listStyles();
  const theme = useTheme();

  const allAnalysis = useSelector((state) => route.visibleAnalytics(state, mapidx));

  // TODO: what if we are showing this from multiple zones?
  const analysis = allAnalysis?.length > 0 ? allAnalysis[0] : null;

  if (!analysis)
    return (
      <div
        style={{
          width: CTRL_SZ[0],
          height: CTRL_SZ[1],
          padding: theme.spacing(1),
        }}>
        <Typography variant='caption' color='error'>
          No solar fault data to display.
        </Typography>
      </div>
    );

  const { count, std, panels } = parseAssets(analysis);

  return (
    <div
      style={{
        width: CTRL_SZ[0],
        height: CTRL_SZ[1],
        padding: theme.spacing(1),
        overflowY: 'auto',
      }}>
      {/* <HelpHere className={classes.help} topic='Asset Count' /> */}

      <BasicOverlayControlComponent />
      <DividerSpacer />
      <List dense>
        <ListItem className={classes.assetEntry}>
          <ListItemText
            primary={`${count} panels identified.`}
            secondary={`${panels?.length || 0} panels exhibit a temperature anomaly.`}
          />
        </ListItem>
      </List>
    </div>
  );
}

export function controls(mapidx) {
  return {
    width: CTRL_SZ[0],
    height: CTRL_SZ[1],
    component: <ControlComponent mapidx={mapidx} />,
  };
}
