import produce from 'immer';
import React from 'react';
import { createSelector } from 'reselect';
import * as wkt from 'wellknown';
import { bboxOfWKT } from '~/lib/geography';
import { Markers } from '~/map';
import PlaceSampleMarker from '~/map/markers/PlaceSampleMarker';
import { getQueryOrgAndSite, paramsForPath } from '~/redux/selectors/global';
import store, { RootState } from '~/store';
import { regionColors } from '~/theme';

import { Link } from '@material-ui/core';
import * as turf from '@turf/turf';

import SurveyEditPanel from './SurveyEditPanel';
import SurveysPage from './SurveysPage';
import { getUpdatedTRList } from './helpers';
import {
  focussedFarm,
  getBaseLocation,
  getKindsOfRegions,
  getTmpSurvey,
  getSelectedSurveyId,
  getTargetedAssets,
  getTotalBoundaryWkt,
} from './selectors';
import { actions } from './slice';
import OrgSiteLink from '~/shared/Appbar/OrgSiteLink';

import type { MapStyle } from '~/lib/activeScene';

import GeoJSON from 'geojson';
import { GeoJSONSourceRaw } from 'mapbox-gl';
import { Asset, Survey, TargetRequest } from '~/schema';
import AssetOrbitControlMarker from '~/map/markers/AssetOrbitControlMarker';
import { point, bearing } from '@turf/turf';

import { getTheme } from '~/theme';
import { getEllipsePoints } from './targetRequestControls/ellipseMath';

export { default as slice } from './slice';

const getPerpendicularHeadingFromIndex = (
  cps: [number, number][],
  cpi: number,
  cw: boolean,
  continuous: boolean
): number => {
  // Get the next and previous capture points, find the angle between them, and rotate it
  let prevCp: number;
  let nextCp: number;
  if (continuous) {
    prevCp = (cpi - 1 + cps.length) % cps.length;
    nextCp = (cpi + 1) % cps.length;
  } else {
    prevCp = cpi == 0 ? 0 : cpi - 1;
    nextCp = cpi == cps.length - 1 ? cpi : cpi + 1;
  }

  const pathBearing = bearing(cps[prevCp], cps[nextCp]);
  const heading = (cw ? pathBearing + 90 : pathBearing + 270) % 360;
  return heading;
};

export const updateInstructions = (trq: TargetRequest, assets: Asset[]): void => {
  switch (trq.request_type) {
    case 'ASSET_ORBIT': {
      // Generate and update the instructions object based on the controls object
      // Currently only supporting generating point captures with default payload parameters
      // and for one ring
      const currentRing = trq.controls_asset_orbit!.orbit_rings[0];
      const cps = getEllipsePoints(currentRing.ellipse, currentRing.divisions);

      const alt = currentRing.ellipse.altitude_m;

      let targetAlt = 0;
      for (const ta of trq.target_areas) {
        const a = assets.find((a) => a.id == ta.asset_id);
        if (!a?.shape) continue;
        // There may be a more robust way to calculate this than the averaging stuff I'm doing here.h
        const points = turf.explode(wkt.parse(a.shape)!).features;
        const altAvg =
          points.reduce((a, p) => a + p.geometry.coordinates[2] || 0, 0) / points.length;
        targetAlt = altAvg / 2;
      }
      const pitch = (Math.atan2(alt - targetAlt, currentRing.ellipse.radius_m) / Math.PI) * 180;
      // assume at our specified radius, we need to be looking down to half the altitude of the asset?
      // Eventually we should let this be manually tweaked.

      trq.instructions_asset = {
        capture_points: cps.map((cp, cpi) => ({
          point: wkt.stringify({ type: 'Point', coordinates: [cp[0], cp[1], alt] }),
          payload_attitude: {
            // pitch should point the camera at half the height of the asset by default
            pitch,
            // Heading should be perpendicular to the path
            heading: getPerpendicularHeadingFromIndex(
              cps,
              cpi,
              currentRing.ellipse.clockwise,
              currentRing.ellipse.start_bearing == currentRing.ellipse.end_bearing
            ),
            roll: 0,
          },
          payload_parameters: {},
          camera_index: 0,
          capture_duration: 0,
        })),
      };
    }
  }
};
// Does this route apply?
export const matches = (state: RootState) => paramsForPath(state, '/surveys');

export const title = () => 'Edit Surveys';

export const breadcrumbs = createSelector(
  getTmpSurvey,
  focussedFarm,
  getQueryOrgAndSite,
  (tmpSurvey, farm, currentOrgAndSite) =>
    tmpSurvey === null
      ? ['Edit Surveys', <OrgSiteLink key='orgsitelink' />]
      : [
          <Link
            key='root'
            color='inherit'
            underline='hover'
            href={`surveys?site=${currentOrgAndSite.siteId}`}>
            Edit Surveys
          </Link>,
          <OrgSiteLink key='orgsitelink' />,
          tmpSurvey.id ? `Survey #${tmpSurvey.id}` : `New Survey`,
        ]
);

// We only have a mapstyle when display a survey.
export const mapStyle = createSelector(getTmpSurvey, getKindsOfRegions, (tmpSurvey, regions) => {
  if (!tmpSurvey) return null;

  const extras: MapStyle['extras'] = { layers: [], sources: {} };

  for (const tr of tmpSurvey.target_requests.filter((tr) => tr.deleted == false)) {
    // For sampling surveys, we display the path between the sample points.

    // TODO: Need to update this to look at sampling instructions!
    if (tr.request_type == 'SAMPLING') {
      const instructions = tr.instructions_sampling;
      const sampling_wkt = instructions?.sampling_locations;

      const multipoint = sampling_wkt ? (wkt.parse(sampling_wkt) as GeoJSON.MultiPoint) : null;
      if (!multipoint) continue;

      const linestring =
        multipoint.coordinates.length > 1 ? turf.lineString(multipoint.coordinates) : null;

      extras.sources['sampling_dots_src'] = {
        type: 'geojson',
        data: { type: 'Feature', geometry: multipoint },
      } as GeoJSONSourceRaw;

      extras.layers.push({
        id: 'sampling_dots',
        type: 'circle',
        source: 'sampling_dots_src',
        paint: {
          'circle-radius': 8,
          'circle-color': '#ffffff',
          'circle-opacity': 1,
        },
      });

      if (linestring) {
        extras.sources['sampling_lines_src'] = {
          type: 'geojson',
          data: linestring,
        } as GeoJSONSourceRaw;
        extras.layers.push({
          id: 'sampling_lines',
          type: 'line',
          source: 'sampling_lines_src',
          paint: {
            'line-width': 2,
            'line-color': '#ffffff',
            'line-dasharray': [2, 2],
          },
        });
      }
    }

    // For asset orbit surveys, we display the orbital path and the capture points.
    if (tr.request_type == 'ASSET_ORBIT' && tr.controls_asset_orbit) {
      const ctrl = tr.controls_asset_orbit;

      // The instructions object contains the synthesized capture points.
      // The controls object includes the metadata that we manipulate to create those capture points.

      // To draw the asset orbit path, we want to display points at the focii, points at the capture points,
      // and an ellipse arc that goes from the starting bearing to the ending bearing.

      for (const orbitRing of ctrl.orbit_rings) {
        // To generate a smooth curve path, we iterate over the arc with a large number of points.

        const arcRenderPointsGlobal = getEllipsePoints(orbitRing.ellipse, 64);

        // Now create a source/layer to view these points.

        extras.sources['asset-orbit-ideal-preview-src'] = {
          type: 'geojson',
          data: turf.lineString(arcRenderPointsGlobal),
        } as GeoJSONSourceRaw;

        extras.layers.push({
          id: 'asset-orbit-ideal-preview',
          type: 'line',
          source: 'asset-orbit-ideal-preview-src',
          paint: {
            'line-width': 6,
            'line-color': '#ff00ff',
            'line-dasharray': [2, 1],
          },
        });

        // Now we generate some helper lines, in this case connecting the focii

        const focii = orbitRing.ellipse.ellipse_focii.map(
          (ptstr) => wkt.parse(ptstr)!.coordinates as GeoJSON.Position
        );
        extras.sources['asset-orbit-focii-src'] = {
          type: 'geojson',
          data: turf.lineString(focii),
        } as GeoJSONSourceRaw;

        const theme = getTheme(false);

        extras.layers.push({
          id: 'asset-orbit-focii',
          type: 'line',
          source: 'asset-orbit-focii-src',
          paint: {
            'line-width': 12,
            'line-color': theme.palette.primary.main,
          },
        });
      }
    }
  }

  return {
    extras,
    imagery: [],
    regions: [
      ...regions.keepouts.map((r) => ({
        id: r.id,
        type: r.type,
        ceiling: r.ceiling,
        boundary: r.boundary,
        display_name: r.display_name,
        color: regionColors[r.type],
        outline: false,
        fill: true,
        name: false,
      })),
      ...regions.flight.map((r) => ({
        id: r.id,
        type: r.type,
        ceiling: r.ceiling,
        boundary: r.boundary,
        display_name: r.display_name,
        color: regionColors[r.type],
        outline: true,
        fill: true,
        name: true,
      })),
      ...regions.data.map((r) => ({
        id: r.id,
        type: r.type,
        ceiling: r.ceiling,
        boundary: r.boundary,
        display_name: r.display_name,
        color: regionColors[r.type],
        outline: false,
        fill: true,
        name: false,
      })),
      ...regions.available.map((r) => ({
        id: r.id,
        type: r.type,
        ceiling: r.ceiling,
        boundary: r.boundary,
        display_name: r.display_name,
        color: regionColors[r.type],
        outline: true,
        fill: false,
        name: false,
      })),
    ],
  };
});

// We switch between a panel and a full component, because we don't need the map to browse surveys.
export const component = createSelector(getTmpSurvey, (tmpSurvey) =>
  tmpSurvey === null ? <SurveysPage /> : null
);

export const panel = createSelector(getTmpSurvey, (tmpSurvey) =>
  tmpSurvey !== null ? <SurveyEditPanel /> : null
);

export const defaultBounds = createSelector(focussedFarm, (farm) => {
  if (!farm) return null;
  return bboxOfWKT(farm.keepin);
});

function updateTargetRequest(
  tmpSurvey: Survey,
  assets: Asset[],
  editingTR: TargetRequest,
  recipe: (draft: TargetRequest) => void
) {
  const tridx = tmpSurvey.target_requests.findIndex((tr) => tr == editingTR);

  const tgt = produce(editingTR, (draft) => {
    recipe(draft);
    updateInstructions(draft, assets);
  });

  const newTRList = getUpdatedTRList(tmpSurvey.target_requests, tgt, tridx);

  store.dispatch(
    actions.setTmpSurvey({
      id: tmpSurvey.id || 0,
      survey: produce(tmpSurvey, (draft) => {
        draft.target_requests = newTRList;
      }),
    })
  );
}

export const markers = createSelector(
  getBaseLocation,
  getTmpSurvey,
  (state) => state.surveysScene.editingTarget,
  getTargetedAssets,
  (baseLocation, tmpSurvey, editingTarget, targetedAssets) => {
    const baseCoord = baseLocation ? wkt.parse(baseLocation)?.coordinates : null;
    const theme = getTheme(false);

    const sampleMarkers: React.ReactNode[] = [];

    const activeTargetRequest =
      editingTarget !== null && editingTarget !== undefined && tmpSurvey
        ? tmpSurvey.target_requests.filter((tr) => !tr.deleted)[editingTarget]
        : null;
    if (!activeTargetRequest || !tmpSurvey) return [];

    switch (activeTargetRequest.request_type) {
      case 'SAMPLING': {
        // TODO: Need to update this to work with isntructions, not the deprecated fields
        const sampleGeoJSON = activeTargetRequest.instructions_sampling!.sampling_locations
          ? (wkt.parse(
              activeTargetRequest.instructions_sampling!.sampling_locations
            ) as GeoJSON.MultiPoint)
          : null;

        if (sampleGeoJSON && tmpSurvey) {
          sampleMarkers.push(
            ...sampleGeoJSON.coordinates.map((c, ci) => (
              <PlaceSampleMarker
                key={JSON.stringify(c)}
                location={[c[0], c[1]]}
                alt={c[2]}
                setCoordinate={(c: GeoJSON.Position) => {
                  // When we update a sample we need to duplicate that trq with the updated coordinate list.
                  const new_sampling_locations = wkt.parse(
                    activeTargetRequest.instructions_sampling!.sampling_locations!
                  )! as GeoJSON.MultiPoint;

                  if (c) {
                    new_sampling_locations.coordinates[ci] = c;
                  } else {
                    new_sampling_locations.coordinates.splice(ci, 1);
                  }
                  const new_sampling_wkt = wkt.stringify(
                    new_sampling_locations as wkt.GeoJSONMultiPoint
                  );
                  updateSample(tmpSurvey, activeTargetRequest, new_sampling_wkt);
                }}
              />
            ))
          );
        }
        break;
      }

      case 'ASSET_ORBIT': {
        // For asset orbit, we have markers that let us graphically control parameters.
        // Most importantly, the center / focii
        if (!activeTargetRequest.controls_asset_orbit) break;

        const controls = activeTargetRequest.controls_asset_orbit;

        const focii = controls.orbit_rings[0].ellipse.ellipse_focii.map(
          (x) => wkt.parse(x) as GeoJSON.Point
        );

        for (let i = 0; i < focii.length; i++) {
          sampleMarkers.push(
            <AssetOrbitControlMarker
              key={`F${i + 1}`}
              label={`F${i + 1}`}
              color={theme.palette.primary.main}
              coordinate={focii[i].coordinates as [number, number]}
              setCoordinate={(c: [number, number]) => {
                // Modify the ellipse focus

                updateTargetRequest(tmpSurvey, targetedAssets, activeTargetRequest, (tr) => {
                  tr.controls_asset_orbit!.orbit_rings[0].ellipse.ellipse_focii[i] = wkt.stringify(
                    point(c).geometry as wkt.GeoJSONPoint
                  );
                });
              }}
            />
          );
        }

        // Show where our capture points are.
        const orbitRing = controls.orbit_rings[0];

        const addCaptureMarker = (i: number, yaw: number, pos: [number, number]) => {
          sampleMarkers.push(
            <AssetOrbitControlMarker
              key={`C${i + 1}`}
              label={`C${i + 1}`}
              color='#FF00FF'
              yaw={yaw}
              coordinate={pos}
              setCoordinate={undefined}
            />
          );
        };

        const cps = getEllipsePoints(orbitRing.ellipse, orbitRing.divisions);

        cps.forEach((c, i) =>
          addCaptureMarker(
            i,
            getPerpendicularHeadingFromIndex(
              cps,
              i,
              orbitRing.ellipse.clockwise,
              orbitRing.ellipse.start_bearing == orbitRing.ellipse.end_bearing
            ),
            c
          )
        );

        break;
      }
    }

    const baseMarkers = baseCoord
      ? [
          <Markers.Base
            visible
            key='base'
            name=''
            coordinate={baseCoord}
            heading={0}
            draggable={false}
            pending={false}
          />,
        ]
      : [];

    return [...baseMarkers, ...sampleMarkers];
  }
);

function updateSample(tmpSurvey: Survey, editingSample: TargetRequest, new_sampling_wkt: string) {
  const tridx = tmpSurvey.target_requests.findIndex((tr) => tr == editingSample);

  const tgt = {
    ...editingSample,
    instructions_sampling: {
      ...editingSample.instructions_sampling,
      sampling_locations: new_sampling_wkt,
    },
  };
  const newTRList = getUpdatedTRList(tmpSurvey.target_requests, tgt, tridx);

  store.dispatch(
    actions.setTmpSurvey({
      id: tmpSurvey.id || 0,
      survey: produce(tmpSurvey, (draft) => {
        draft.target_requests = newTRList;
      }),
    })
  );
}

export const clickableFeatures = createSelector(
  focussedFarm,
  getTmpSurvey,
  getKindsOfRegions,
  (state) => state.surveysScene.editingTarget,
  (state) => {
    const trqs = getTmpSurvey(state)?.target_requests?.filter((tr) => !tr.deleted);
    const trq =
      trqs && state.surveysScene.editingTarget !== null
        ? trqs[state.surveysScene.editingTarget]
        : null;
    const targetAreas = trq?.target_areas || [];

    return getTotalBoundaryWkt(state, targetAreas);
  },
  (farm, tmpSurvey, regions, editingTarget, boundary) => {
    if (!tmpSurvey) return null; // This only applies when we have a map visible

    const editingSample =
      editingTarget !== null
        ? tmpSurvey.target_requests.filter((tr) => !tr.deleted)[editingTarget]
        : null;

    if (editingSample?.request_type == 'SAMPLING' && boundary) {
      return [
        {
          feature: boundary,
          cursor: 'crosshair',
          onClick: (point: [number, number]) => {
            // Add a new sampling point here
            let new_sampling_locations = editingSample.instructions_sampling!.sampling_locations
              ? (wkt.parse(
                  editingSample.instructions_sampling!.sampling_locations
                ) as wkt.GeoJSONMultiPoint)
              : null;

            if (new_sampling_locations) {
              new_sampling_locations.coordinates.push([point[0], point[1], 15.24]);
            } else {
              new_sampling_locations = turf.multiPoint([[point[0], point[1], 15.24]])
                .geometry as wkt.GeoJSONMultiPoint;
            }
            const new_sampling_wkt = wkt.stringify(new_sampling_locations);
            updateSample(tmpSurvey, editingSample, new_sampling_wkt);
          },
        },
      ];
    }
  }
);

export const estimateKey = getSelectedSurveyId;
export const helpTopic = () => 'Edit Surveys';
