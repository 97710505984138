import React from 'react';
import * as globalSelctors from '~/redux/selectors/global';

import FAADocsTable from './FAADocsTable';

export const matches = (state) => globalSelctors.paramsForPath(state, '/regulatory');

export const title = (state) => 'Regulatory Documentation';

export const breadcrumbs = () => ['Regulatory Documentation'];

export const component = () => {
  return <FAADocsTable />;
};

export const helpTopic = () => 'Regulatory Documentation';
