import React from 'react';
import { useSelector } from 'react-redux';
import * as Icons from '~/icons';
import history from '~/lib/history';
import { jumpToSite } from '~/lib/uiHelpers';
import { term } from '~/markets';
import FieldSelector from '~/shared/Appbar/FieldSelector';

import { Button, IconButton, Link, Tooltip, Typography } from '@material-ui/core';
import { GAevent } from '~/googleAnalytics';

const ZoomOutButton = ({ farm }) => {
  return (
    <Tooltip title={`Zoom to ${term('site')}`}>

      <IconButton color='inherit' 
      onClick={ () => {
        jumpToSite(farm.id)();
        GAevent('Zoom', `Zoom to farm ${farm.id}`, 'jumpToFarm(farm.id)')
        }}>
        <Icons.ZoomFarm />
      </IconButton>
    </Tooltip>
  );
};

export default ZoomOutButton;
