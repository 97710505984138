/**
 * @param content - data to be used as the file's contents
 * @param filename - destination filename, used unless user's browser is configured to always "Save As..."
 * @param contentType - MIME type : https://www.iana.org/assignments/media-types/media-types.xhtml
 */
export function downloadBlob(content: string, filename: string, contentType: string) {
  // Create a blob
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);

  // Create a virtual link element to use as the download vehicle
  const element = document.createElement('a');
  element.href = url;
  element.setAttribute('download', filename);
  element.click();
}
