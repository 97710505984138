import queryString from 'query-string';
import React from 'react';
import { withRouter } from 'react-router-dom';
import * as Icons from '~/icons';
import * as api from '~/lib/api';
import { csvEscape } from '~/lib/csvEscape';
import history from '~/lib/history';
import { showSnackbar } from '~/lib/modalServices';
import Logging from '~/logging';
import ColumnSelectDialog from '~/shared/ColumnSelectDialog';
import FancyTable from '~/shared/FancyTable';

import { IconButton, MenuItem, TextField, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.background.default,
    maxWidth: '100%',
    minWidth: '100%',
  },
  buttonBar: {
    display: 'flex',
    background: theme.palette.background.default,
    '& > *': {
      margin: 4,
    },
  },
  chipArray: {
    display: 'flex',
    justifyContent: 'flex-begin',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > *': { margin: theme.spacing(0.5) },
  },
});

class AllFlightsPanel extends React.Component {
  state = {
    isLoading: true,
    error: null,
    rows: [],
    columns: [],
    count: 0,
    filtering: null,
    filters: [], // what filters were in our last request
    isSelectingColumns: false,
    hiddenColIds: [], // Default hidden columns
    downloadingCSV: false,
  };

  componentDidMount() {
    this.requestRows().then(() => {});
  }
  componentWillUnmount() {}

  getQuery = () => {
    const query = queryString.parse(this.props.location.search, { arrayFormat: 'bracket' });
    return {
      filters: query.filters,
      sortDir: query.sortDir,
      sortCol: query.sortCol,
      offset: parseInt(query.offset) || 0,
      limit: parseInt(query.limit) || 20,
      tz: query.tz,
    };
  };

  setQuery = (query) => {
    const str = '?' + queryString.stringify(query, { arrayFormat: 'bracket' });
    history.push({
      search: str,
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.location.search != this.props.location.search) {
      this.requestRows().then(() => {});
    }
  }

  async requestRows() {
    const query = this.getQuery();

    const sortCol = query.sortCol;
    const sortDir = query.sortDir;
    const offset = query.offset || 0;
    const limit = query.limit || 20;
    const filters = query.filters || [];
    const tz = query.tz || 'utc';

    const fre = /([a-zA-Z_.]+)(=|!=|<|>)([\w-,]+)/;

    const filterObjects = filters.map((f) => {
      const parts = f.match(fre);
      if (!parts) {
        Logging.warn(`filter doesn't match regex`, { filter: f });
        return false;
      }
      return {
        key: parts[1],
        op: parts[2],
        value: parts[3],
      };
    });

    this.setState({ ...this.state, isLoading: true, error: null, filters: filterObjects });

    try {
      const params = {
        sortby: sortCol,
        sortdir: sortDir,
        offset: offset,
        limit: limit,
        filter: filters,
        tz: tz,
      };

      const results = await api.webrequest('GET', 'flightlogs', {}, params);

      this.setState({
        ...this.state,
        isLoading: false,
        rows: results.rows || [],
        columns: results.columns,
        count: results.metadata.count,
        error: null,
      });
    } catch (e) {
      Logging.error('Failed getting flight list', e);

      this.setState({ ...this.state, isLoading: false, rows: [], error: `${e}` });
    }
  }

  downloadCSV = async () => {
    let csvContent = [];

    const { columns, hiddenColIds } = this.state;

    this.setState({ ...this.state, downloadingCSV: true });

    const visibleColumns = columns.filter((c) => !hiddenColIds.includes(c.id));

    csvContent.push(visibleColumns.map((c) => csvEscape(c.title)).join(',') + '\r\n');

    // need to request *all* rows from the server!

    try {
      const query = this.getQuery();
      const params = {
        sortby: query.sortCol,
        sortdir: query.sortDir,
        offset: query.offset,
        limit: this.state.count + 10, // some buffer in case rows get added?
        filter: query.filters || [],
        tz: query.tz || 'utc',
      };

      // TODO: pop up a spinner of some sort

      const results = await api.webrequest('GET', 'flightlogs', {}, params);
      results.rows.forEach((row) => {
        const rowarr = visibleColumns.map((c) => {
          const idx = visibleColumns.findIndex((c2) => c2.id == c.id);
          return `${row.values[idx]}`;
        });

        const rowstr = rowarr.join(',');
        csvContent.push(rowstr + '\r\n');
      });

      let link = document.createElement('a');
      const file = new Blob([csvContent.join('')], { type: 'text/plain' });
      link.href = URL.createObjectURL(file);
      link.download = 'ScoutFlightLogs.csv';
      link.click();
    } catch (e) {
      showSnackbar('error', `Could not download CSV`);
      Logging.warn(`Failed to download flight logs CSV`, e);
    }

    this.setState({ ...this.state, downloadingCSV: false });
  };

  render() {
    const { classes } = this.props;
    const { isLoading, columns, rows, count, filters, hiddenColIds, downloadingCSV } = this.state;

    const query = this.getQuery();

    const visibleColumns = columns.filter((c) => !hiddenColIds.includes(c.id));

    // Rows is an array, maps to columns...
    const rowsById = rows.map((r) => {
      let rowById = {};
      columns.forEach((c, ci) => {
        rowById[c.id] = r.values[ci] || undefined;
      });
      return rowById;
    });

    return (
      <div className={classes.root}>
        <FancyTable
          data={rowsById}
          count={count}
          offset={query.offset}
          limit={query.limit}
          sortCol={query.sortCol}
          sortDir={query.sortDir}
          columns={visibleColumns}
          allLoading={isLoading || downloadingCSV}
          loading={() => false}
          paginate={true}
          onClick={(row) => {
            history.push(`/flight/${row.id}`);
          }}
          filters={filters}
          setFilters={(newFilters) => {
            const query = this.getQuery();
            query.filters = newFilters.map((f) => `${f.key}${f.op}${f.value}`);
            this.setQuery(query);
          }}
          onNewQuery={(query) => {
            this.setQuery({ ...this.getQuery(), ...query });
          }}>
          <Tooltip title='Show/Hide Columns'>
            <IconButton onClick={() => this.setState({ ...this.state, isSelectingColumns: true })}>
              <Icons.Columns />
            </IconButton>
          </Tooltip>

          <Tooltip title='Refresh'>
            <IconButton
              disabled={isLoading}
              onClick={() => {
                this.requestRows().then(() => {});
              }}>
              <Icons.Refresh />
            </IconButton>
          </Tooltip>
          <Tooltip title='Download CSV'>
            <IconButton onClick={this.downloadCSV}>
              <Icons.Download />
            </IconButton>
          </Tooltip>
          <TextField
            select
            label='Timezone'
            size='small'
            value={query.tz || 'utc'}
            onChange={(evt) => this.setQuery({ ...query, tz: evt.target.value })}>
            <MenuItem color='inherit' value={'utc'}>
              UTC
            </MenuItem>
            <MenuItem color='inherit' value={'farm'}>
              Farm
            </MenuItem>
          </TextField>
        </FancyTable>

        <ColumnSelectDialog
          show={this.state.isSelectingColumns}
          allColumns={columns}
          hiddenColIds={hiddenColIds}
          setHiddenColIds={(newHiddenIds) => {
            this.setState({
              ...this.state,
              hiddenColIds: newHiddenIds,
            });
          }}
          onClose={() => {
            this.setState({ ...this.state, isSelectingColumns: false });
          }}
        />
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(AllFlightsPanel));
