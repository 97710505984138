import React from 'react';
import FancyTable from '~/shared/FancyTable';

function sortData(data, columns, sortCol, sortDir) {
  const sortFunc = (a, b) => {
    const aval = a[sortCol];
    const bval = b[sortCol];

    const dir = sortDir == 'asc' ? 1 : -1;

    // TODO: make the column provide a sort function?

    switch (typeof aval) {
      case 'number':
        return (aval - bval) * dir;
      case 'string':
        return aval.localeCompare(bval, undefined, { numeric: true }) * dir;
      default:
        return 0;
    }
  };

  if (sortCol !== undefined) {
    const invalidData = data.filter((x) => {
      const v = x[sortCol];
      return v === undefined || v === null || (isNaN(v) && typeof v == 'number');
    });
    let validData = data.filter((x) => invalidData.indexOf(x) == -1);

    validData.sort(sortFunc);
    data = [...validData, ...invalidData];
  }

  return data;
}

function passesFilters(row, filters) {
  for (const f of filters) {
    switch (f.op) {
      case '=': {
        const values = f.value.split(',');
        if (!values.includes(row[f.key])) {
          return false;
        }
        break;
      }
      case '<':
        if (f.value < row[f.key]) {
          return false;
        }
        break;
      case '>':
        if (f.value > row[f.key]) {
          return false;
        }
        break;
      default:
        return false;
    }
  }
  return true;
}

export default function FancyTableStatic({
  data,
  columns,
  loading,
  hidden,
  selected,
  allLoading,
  onClick,
}) {
  const [sortDir, setSortDir] = React.useState('asc');
  const [sortCol, setSortCol] = React.useState(columns[0]?.id);

  const [filters, setFilters] = React.useState([]);

  const onNewQuery = (q) => {
    /* we've received updated state */
    if (q.sortCol != sortCol) setSortCol(q.sortCol);
    if (q.sortDir != sortDir) setSortDir(q.sortDir);
  };

  const filteredData = data.filter((x) => passesFilters(x, filters));
  const sortedFilteredData = sortData(filteredData, columns, sortCol, sortDir);

  return (
    <FancyTable
      data={sortedFilteredData}
      columns={columns}
      loading={loading}
      allLoading={allLoading}
      filters={filters}
      setFilters={setFilters}
      onClick={onClick}
      hidden={hidden}
      selected={selected}
      paginate={false} //Don't support pagination of static data
      sortCol={sortCol}
      sortDir={sortDir}
      offset={0}
      limit={data.length}
      onNewQuery={onNewQuery}
    />
  );
}
