import React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import * as Icons from '~/icons';
import history from '~/lib/history';

import { Button, IconButton, Link, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import TreeItem from '@material-ui/lab/TreeItem';

import partExpectations from './partExpectations';
import { subassemblyEdits } from './selectors';
import { actions } from './slice';

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,

    '&$selected > $content': {
      backgroundColor: fade(theme.palette.primary.main, 0.1),
      color: theme.palette.primary.dark,
    },
    '&$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },

  root_unset: {
    // opacity: 0.5,

    color: theme.palette.text.disabled,

    '&$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },

  content: {
    // color: theme.palette.text.secondary,
    borderRadius: 2,
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
    // borderBottom: `1px dashed ${theme.palette.divider}`,
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 0,
    marginRight: theme.spacing(1),
  },
}));

const PartTreeItem = React.memo((props) => {
  const {
    part,
    type,
    sasm_key,
    selectedId,
    isEditable,
    edits,
    editSubassembly,
    clearSubassembly,
    revertSubassembly,
    ...other
  } = props;

  const classes = useTreeItemStyles();

  const expectations = partExpectations(type || part?.type);
  const LabelIcon = expectations.icon;

  const addedSubassemblies = selectedId == part?.id ? Object.keys(edits || {}) : [];

  const isEdited = isEditable && sasm_key in edits;
  const editedSn = isEditable ? edits?.[sasm_key]?.sn : null;

  // TODO: include subassemblies added through editing
  const childrenKeys = part
    ? [
        ...new Set([
          ...Object.keys(expectations.subassemblies),
          ...part.children?.map((c) => c.key),
          ...addedSubassemblies,
        ]),
      ]
    : [];

  // TODO: include subassemblies added through editing
  var childrenByKey = childrenKeys.reduce((cbk, key) => {
    cbk[key] = {
      expectedType: expectations.subassemblies[key],
      part: part.children.find((c) => c.key == key) || null,
    };
    return cbk;
  }, {});

  return part ? (
    <TreeItem
      nodeId={part.id}
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color='inherit' className={classes.labelIcon} />
          {selectedId == part.id ? (
            <Typography variant='body2' className={classes.labelText}>
              {(sasm_key || part.type).toUpperCase()} {part.sn}
            </Typography>
          ) : (
            <Link
              component={RouterLink}
              to={`/part/${part.type}/${part.sn}`}
              // onClick={stopThrough}
              variant='body2'
              className={classes.labelText}>
              {(sasm_key || part.type).toUpperCase()} {part.sn}
            </Link>
          )}
          {isEdited && <Typography variant='caption'>Change to: {editedSn || 'None'}</Typography>}

          <div style={{ flexGrow: 1 }} />

          <Typography variant='caption'>{part.state}</Typography>

          {isEdited && (
            <Tooltip title={`Undo change to ${sasm_key}`}>
              <IconButton
                size='small'
                onClick={(e) => {
                  e.stopPropagation();
                  revertSubassembly(sasm_key);
                }}
                color='secondary'>
                <Icons.Undo fontSize='inherit' />
              </IconButton>
            </Tooltip>
          )}
          {isEditable && (
            // We can change or remove this subassembly
            <>
              <Tooltip title={`Remove ${sasm_key}`}>
                <IconButton
                  size='small'
                  onClick={(e) => {
                    e.stopPropagation();
                    clearSubassembly(sasm_key);
                  }}
                  color='secondary'>
                  <Icons.Close fontSize='inherit' />
                </IconButton>
              </Tooltip>
              <Tooltip title={`Replace ${sasm_key}`}>
                <IconButton
                  size='small'
                  onClick={(e) => {
                    e.stopPropagation();
                    editSubassembly(sasm_key);
                  }}
                  color='secondary'>
                  <Icons.Edit fontSize='inherit' />
                </IconButton>
              </Tooltip>
            </>
          )}
        </div>
      }
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}>
      {childrenKeys.map((key) => (
        <PartTreeItem
          key={key}
          sasm_key={key}
          type={childrenByKey[key].expectedType}
          part={childrenByKey[key].part}
          selectedId={selectedId}
          isEditable={selectedId == part.id}
          clearSubassembly={clearSubassembly}
          editSubassembly={editSubassembly}
          revertSubassembly={revertSubassembly}
          edits={edits}
        />
      ))}
    </TreeItem>
  ) : (
    <TreeItem
      nodeId={part?.id || sasm_key}
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color='inherit' className={classes.labelIcon} />
          {isEdited ? (
            <>
              <Typography variant='body2' className={classes.labelText}>
                {sasm_key.replace('_', ' ').toUpperCase()}
              </Typography>
              <Typography variant='caption'>Set to: {editedSn || 'None'}</Typography>
            </>
          ) : (
            <Typography variant='body2' className={classes.labelText}>
              NO {sasm_key.replace('_', ' ').toUpperCase()}
            </Typography>
          )}
          <div style={{ flexGrow: 1 }} />

          {isEdited && (
            <Tooltip title={`Undo change to ${sasm_key}`}>
              <IconButton
                size='small'
                onClick={(e) => {
                  e.stopPropagation();
                  revertSubassembly(sasm_key);
                }}
                color='secondary'>
                <Icons.Undo fontSize='inherit' />
              </IconButton>
            </Tooltip>
          )}

          {isEditable && (
            // We can add a subassembly here
            <>
              <Tooltip title={`Add ${sasm_key}`}>
                <IconButton
                  size='small'
                  onClick={(e) => {
                    e.stopPropagation();
                    editSubassembly(sasm_key);
                  }}
                  color='secondary'>
                  <Icons.Add fontSize='inherit' />
                </IconButton>
              </Tooltip>
            </>
          )}
        </div>
      }
      classes={{
        root: classes.root_unset,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}>
      {/* We don't show children of unset entries */}
    </TreeItem>
  );
});

const mapStateToProps = (state) => ({
  edits: subassemblyEdits(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PartTreeItem);
