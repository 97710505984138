import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import * as Icons from '~/icons';
import history from '~/lib/history';
import FancyTableStatic from '~/shared/FancyTableStatic';
import FilterBar from '~/shared/FilterBar';

import { Button, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  root: {
    overflowY: 'auto',
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    background: theme.palette.background.default,
    maxHeight: '100%',
    padding: theme.spacing(1),
  },

  tableArea: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 1,
  },

  actionBar: {
    padding: [[8, 16]],
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const sortNumber = (a, b) => {
  if (isFinite(a - b)) {
    return a - b;
  } else {
    return isFinite(a) ? -1 : 1;
  }
};
const sortString = (a, b) => a.localeCompare(b);

const clickedFarmHandler = (farm) => {
  history.push(`/farms?site=${farm.id}`);
};

function FarmManagementPanel({ farms }) {
  const [filterValue, setFilterValue] = React.useState('');

  const filteredFarms = farms.filter(
    (f) =>
      !filterValue ||
      f.id.includes(filterValue) ||
      f.location.includes(filterValue) ||
      f.grower_id.includes(filterValue)
  );

  const classes = styles();

  /*
  id
title
category
units
sortable
type       
        
filterable
filter_opts_str
filter_opts_num
filter_opts_bool
        
fixed
  */

  const uniq = (objs, key) => [...new Set(objs.map((x) => x[key]))];

  const columns = [
    {
      id: 'id',
      title: 'ID',
      type: 'STR',
      sortable: true,
    },
    {
      id: 'grower_id',
      title: 'Grower',
      type: 'STR',
      sortable: true,
      filterable: true,
      filter_opts_str: uniq(farms, 'grower_id'),
    },
    {
      id: 'location',
      title: 'Location',
      sortable: true,
      type: 'STR',
    },
    {
      id: 'timezone',
      title: 'TZ',
      type: 'STR',
      sortable: true,
      filterable: true,
      filter_opts_str: uniq(farms, 'timezone'),
    },
  ];

  return (
    <div className={classes.root}>
      <Paper className={classes.actionBar}>
        <FilterBar
          value={filterValue}
          disabled={!farms}
          notFound={!filteredFarms}
          onChange={setFilterValue}
          placeholder='Filter Sites'
        />

        <Button color='primary' onClick={() => history.push('/farms?site=new')}>
          New Site
        </Button>
      </Paper>
      <Paper className={classes.tableArea}>
        <FancyTableStatic data={filteredFarms} columns={columns} onClick={clickedFarmHandler} />
      </Paper>
    </div>
  );
}

const farmsArray = createSelector(
  (state) => state.global.farms,
  (farms) => Object.values(farms)
);

const mapStateToProps = (state) => ({
  farms: farmsArray(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FarmManagementPanel);
