import React from 'react';
import * as Icons from '~/icons';

import { IconButton, InputAdornment, TextField } from '@material-ui/core';

function FilterBar({ value, notFound, placeholder, onChange, disabled, ...etc }) {
  const handleChange = (evt) => {
    onChange(evt.target.value);
  };

  const clear = () => {
    onChange('');
  };
  return (
    <TextField
      size='small'
      disabled={disabled}
      value={value || ''}
      label={placeholder}
      onChange={handleChange}
      error={notFound && !disabled}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            {value?.length > 0 ? (
              <IconButton size='small' onClick={clear}>
                <Icons.Close color='inherit' />
              </IconButton>
            ) : (
              <Icons.Filter color='inherit' style={{ opacity: 0.6, pointerEvents: 'none' }} />
            )}
          </InputAdornment>
        ),
      }}
      {...etc}
    />
  );
}

export default FilterBar;
