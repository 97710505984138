import * as PolygonClipping from 'polygon-clipping';
import * as wkt from 'wellknown';
import { Asset, Region } from '~/schema';
import { bboxOfWKT } from '~/lib/geography';

import * as turf from '@turf/turf';

export function automaticGridDivsFromWKT(text: string) {
  const bbox = bboxOfWKT(text);
  if (!bbox) return [1, 1];

  const coslat = Math.cos((Math.PI / 180.0) * ((bbox[0][1] + bbox[1][1]) / 2));
  const max_grid_deg = 0.00009; // approx 10m wide grids default
  return [
    Math.ceil((Math.abs(bbox[0][0] - bbox[1][0]) * coslat) / max_grid_deg),
    Math.ceil(Math.abs(bbox[0][1] - bbox[1][1]) / max_grid_deg),
  ];
}

function getPolyFromWKT(text: string) {
  const poly = turf.feature(wkt.parse(text)) as turf.Feature<turf.Geometry>;

  if (!poly || !['Polygon', 'MultiPolygon'].includes(poly.geometry?.type)) {
    return null;
  }

  if (turf.kinks(poly as turf.Feature<turf.MultiPolygon | turf.Polygon>).features.length > 0) {
    return null;
  }
  return poly;
}

function getAnalysisGrid(
  xmin: number,
  xdivs: number,
  dx: number,
  ymin: number,
  ydivs: number,
  dy: number,
  region_poly: turf.Feature<turf.Geometry>
) {
  const grid: turf.Geometry[] = [];

  for (let iy = 0; iy < ydivs; iy++) {
    const y = ymin + iy * dy;
    for (let ix = 0; ix < xdivs; ix++) {
      const x = xmin + ix * dx;

      const grid_bbox: turf.helpers.BBox = [x, y, x + dx, y + dy];
      const grid_bbox_poly = turf.bboxPolygon(grid_bbox);

      // turf.intersect is broken! https://github.com/w8r/martinez/issues/102
      try {
        const grid_poly = PolygonClipping.intersection(
          grid_bbox_poly.geometry.coordinates as PolygonClipping.Polygon,
          region_poly.geometry.coordinates as PolygonClipping.Polygon | PolygonClipping.MultiPolygon
        );
        if (grid_poly != null) {
          grid.push({
            type: 'MultiPolygon', // polygon_clipping always returns multipolygon
            coordinates: grid_poly,
          });
          continue;
        }
      } catch (error) {
        console.warn('Polygon clipping reports error: {error}');
      }

      // Gotta push something to maintain spacing in the grid.
      // These are probably invalid so it won't be displayed.
      grid.push(grid_bbox_poly.geometry);
    }
  }

  return grid;
}
// TODO: Calculate the grid from the region the way that the processor does,
// that should be a parameter to the analysis modes
export function gridForRegion(r: Region) {
  const bbox = bboxOfWKT(r.boundary);
  if (!bbox) return [];

  const region_poly = getPolyFromWKT(r.boundary);
  if (!region_poly) return [];

  let grid_div = r.grid_divisions;

  if (grid_div?.length != 2) {
    grid_div = automaticGridDivsFromWKT(r.boundary);
  }

  const dx = Math.abs((bbox[0][0] - bbox[1][0]) / grid_div[0]);
  const dy = Math.abs((bbox[0][1] - bbox[1][1]) / grid_div[1]);

  return getAnalysisGrid(
    bbox[0][0],
    grid_div[1],
    dx,
    bbox[0][1],
    grid_div[0],

    dy,
    region_poly
  );
}

// Intentional duplicate of gridForRegion per Alex K.
export function gridForAsset(a: Asset) {
  if (!a?.shape) return [];

  const bbox = bboxOfWKT(a.shape);
  if (!bbox) return [];

  const asset_poly = getPolyFromWKT(a.shape);
  if (!asset_poly) return [];

  let grid_div = a.grid_divisions;

  if (grid_div?.length != 2) {
    grid_div = automaticGridDivsFromWKT(a.shape);
  }

  const dx = Math.abs((bbox[0][0] - bbox[1][0]) / grid_div[0]);
  const dy = Math.abs((bbox[0][1] - bbox[1][1]) / grid_div[1]);

  return getAnalysisGrid(
    bbox[0][0],
    grid_div[1],
    dx,
    bbox[0][1],
    grid_div[0],

    dy,
    asset_poly
  );
}

export function gridForCachedRegion(
  region: Region,
  grid_origin: [number, number],
  grid_divisions: number[],
  grid_cell_size: number[]
) {
  const region_poly = getPolyFromWKT(region.boundary);
  if (!region_poly) return [];

  const ox = grid_origin[0];
  const oy = grid_origin[1];
  const dx = grid_cell_size[0];
  const dy = grid_cell_size[1];

  return getAnalysisGrid(ox, grid_divisions[1], dx, oy, grid_divisions[0], dy, region_poly);
}

export function cellsForSamples(region: Region, samples: [number, number][]) {
  // Given the samples, produce geometries clipped to the region
  const region_poly = getPolyFromWKT(region.boundary);
  if (!region_poly) return [];

  const points = samples.map((s) => turf.point(s));
  const bbox = turf.bbox(region_poly);
  const domainFeatureCollection = turf.voronoi(turf.featureCollection(points), {
    bbox,
  });

  const domains: (turf.Geometry | null)[] = [];

  for (const domainFeature of domainFeatureCollection.features) {
    const cropped = PolygonClipping.intersection(
      domainFeature.geometry.coordinates as PolygonClipping.Polygon,
      region_poly.geometry.coordinates as PolygonClipping.Polygon | PolygonClipping.MultiPolygon
    );

    if (cropped != null) {
      domains.push({
        type: 'MultiPolygon', // polygon_clipping always returns multipolygon
        coordinates: cropped,
      });
    } else {
      // Was this sample point outside our region?
      domains.push(null);
    }
  }
  return domains;
}
