import createCachedSelector from 're-reselect';
import { matchPath } from 'react-router';
import { createSelector } from 'reselect';
import * as geography from '~/lib/geography';
import { getGlobalMap, projectCoordinate } from '~/lib/globalMapAccess';
import { Farm } from '~/schema';
import type { RootState } from '~/store';

// Use this to get query params parsed in a consistent way.
export const getQueryParams = createSelector(
  (state: RootState) => state.router.location.search,
  (search) => new URLSearchParams(search)
);

// Return all sites that haven't been hidden by the user
export const allUnhiddenSites = createSelector(
  (state: RootState) => state.global.farms,
  (state: RootState) => state.global.preferences.hiddenFarmIds,
  (sites, hiddenFarmIds) => {
    const rval: Record<string, Farm> = {};
    for (const id of Object.keys(sites)) {
      if (!hiddenFarmIds.includes(id)) rval[id] = sites[id];
    }
    return rval;
  }
);

// Show the currently selected organization based on query params
export const getQueryOrgAndSite = createSelector(
  getQueryParams,
  (state: RootState) => state.global.organizations,
  (state: RootState) => state.global.preferences.defaultOrgId,
  allUnhiddenSites,
  (query, orgs, defaultOrgId, sites) => {
    const orgIds = orgs.map((x) => x.id);

    const qOrg = query.get('o');
    const qSite = query.get('site');

    // Uses queryparams to get the chosen site and org ids.
    // This is limited by what we have access to.
    const currentOrgId: string | null =
      orgIds.length == 0
        ? null // If we have none, return none.
        : qOrg && orgIds.includes(qOrg)
        ? qOrg // If we have a valid selected option, use that
        : defaultOrgId || orgIds[0]; // Otherwise pick the default, or the first

    const siteIds = Object.values(sites)
      .filter(
        (site) => currentOrgId == null || site.grower_id == currentOrgId // Filter to currently selected org, if any
      )
      .sort(
        (a, b) => a.location.localeCompare(b.location) // sort by name
      )
      .map((x) => x.id);

    // Now check for queryparams mentioning the site.
    const currentSiteId: string | null =
      siteIds.length == 0
        ? null // If we have none, return none.
        : qSite && siteIds.includes(qSite)
        ? qSite // If we have a valid selected option, use that
        : siteIds.length == 1
        ? siteIds[0] // if we only have one, choose that
        : null; // otherwise we just haven't selected a site, that's ok

    // It's possible we got here by selecting just a site. If so, the selected org is that of the site.
    return {
      orgId: currentOrgId || (currentSiteId ? sites[currentSiteId].grower_id : null),
      siteId: currentSiteId,
    };
  }
);

// TODO: Update this to consider what modules are available; also we aren't doing farm-based urls right?
export const getLandingPage = createSelector(
  (state: RootState) => state.global.modules,
  (modules) => {
    if (modules.length == 0) {
      console.warn('No modules available.');
      return '/error';
    }
    if (modules.length == 1) return modules[0].route;
    if (modules.length > 1) return modules[0].route;
  }
);

export const allFarms = (state: RootState) => {
  return state.global.farms;
};

// TODO: put this in a 'routing' reducer?
export const paramsForPath = createCachedSelector(
  (state: RootState) => state.router?.location,
  (_: RootState, path: string) => path,
  (location, path) => {
    if (!location) return null;
    const match = matchPath(location.pathname, {
      path: path,
      exact: true,
    });

    return match?.params || null;
  }
)((_, path) => path);

// returns f(farm) => 0, 1, or 2 depending on if we should see just a dot, the outline, or the fields
export const getFarmVisibilitySteps = createSelector(
  (state: RootState) => state.ui.viewport,
  (state: RootState) => state.global.farms,
  (viewport, farms) => {
    const rval: Record<string, number> = {};
    for (const farmid of Object.keys(farms)) {
      const farm = farms[farmid];
      // what's the pixel size of this farm's keepout at this zl?
      const bbox = geography.bboxOfWKT(farm.keepin);
      if (bbox == null) {
        console.warn(`No bbox for ${farmid}`);
        rval[farmid] = -2;
        continue;
      }

      if (getGlobalMap(0) == null) {
        console.warn(`No global map initialized`);
        rval[farmid] = -2;
        continue;
      }

      const bbox_px = [projectCoordinate(bbox[0])!, projectCoordinate(bbox[1])!];

      const diagonal_px = Math.sqrt(
        Math.pow(bbox_px[0]['x'] - bbox_px[1]['x'], 2) +
          Math.pow(bbox_px[0]['y'] - bbox_px[1]['y'], 2)
      );

      const window_diag = Math.sqrt(
        Math.pow(window.innerHeight, 2) + Math.pow(window.innerWidth, 2)
      );

      const max_x = Math.max(bbox_px[0]['x'], bbox_px[1]['x']);
      const max_y = Math.max(bbox_px[0]['y'], bbox_px[1]['y']);
      const min_x = Math.min(bbox_px[0]['x'], bbox_px[1]['x']);
      const min_y = Math.min(bbox_px[0]['y'], bbox_px[1]['y']);

      const outOfView =
        min_x > window.innerWidth || min_y > window.innerHeight || max_x < 0 || max_y < 0;

      if (outOfView) {
        rval[farmid] = -1;
      } else if (diagonal_px < 20) {
        rval[farmid] = 0;
      } else if (diagonal_px < window_diag * 0.1) {
        rval[farmid] = 1;
      } else {
        rval[farmid] = 2;
      }
    }
    return rval;
  }
);
