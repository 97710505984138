import React from 'react';

import { Typography } from '@material-ui/core';

import MapContext from '../mapContext';
import DraggableMarker from './DraggableMarker';

const arRed = '#f44336';

export default function SafeSpot({ name, coordinate, pending, draggable, ...other }) {
  return (
    <MapContext.Consumer>
      {(map) => (
        <DraggableMarker
          style={{ pointerEvents: 'none' }}
          coordinate={coordinate}
          anchor='top-left'
          offset={[-18, -18]}
          map={map}
          draggable={draggable}
          {...other}>
          <Typography
            variant='body1'
            align='center'
            style={{
              position: 'absolute',
              top: 36,
              color: 'white',
              textShadow: '0 0 5px black',
              lineHeight: '1em',
              pointerEvents: 'none',
              width: '100%',
            }}>
            {name}
          </Typography>
          <svg width='36' height='36'>
            <defs>
              <filter id='dropshadow' x='-20%' y='-20%' width='200%' height='200%'>
                <feGaussianBlur in='SourceAlpha' stdDeviation='3' />
                <feOffset dx='0' dy='2' result='offsetblur' />
                <feComponentTransfer>
                  <feFuncA type='linear' slope='0.8' />
                </feComponentTransfer>
                <feMerge>
                  <feMergeNode />
                  <feMergeNode in='SourceGraphic' />
                </feMerge>
              </filter>
            </defs>

            <circle
              cx='18'
              cy='18'
              r='8'
              stroke={pending ? arRed : 'white'}
              fill='lightgray'
              opacity={draggable ? 1 : 0.8}
              strokeWidth={draggable ? 3 : 1}
              filter={draggable && 'url(#dropshadow)'}
            />

            <line
              x1={18 + 8}
              x2={18 + 8 + 8}
              y1={18}
              y2={18}
              stroke={'white'}
              strokeWidth='3'
              opacity={draggable ? 1 : 0.8}
            />
            <line
              x1={18 - 8}
              x2={18 - 8 - 8}
              y1={18}
              y2={18}
              stroke='white'
              strokeWidth='3'
              opacity={draggable ? 1 : 0.8}
            />

            <line
              y1={18 + 8}
              y2={18 + 8 + 8}
              x1={18}
              x2={18}
              stroke='white'
              strokeWidth='3'
              opacity={draggable ? 1 : 0.8}
            />
            <line
              y1={18 - 8}
              y2={18 - 8 - 8}
              x1={18}
              x2={18}
              stroke='white'
              strokeWidth='3'
              opacity={draggable ? 1 : 0.8}
            />

            <circle cx='18' cy='18' r='4' stroke={arRed} fill='transparent' strokeWidth='3' />
          </svg>
        </DraggableMarker>
      )}
    </MapContext.Consumer>
  );
}
