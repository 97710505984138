import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';
import * as Icons from '~/icons';
import history from '~/lib/history';
import { actions as globalActions } from '~/redux/slices/global';
import { actions as uiActions } from '~/redux/slices/ui';

import { Backdrop, Divider, Drawer, Hidden, IconButton, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const rightPanelWidth = 280;

const styles = (theme) => ({
  desktop_wrapper: {
    height: '100%',
  },

  desktop_paper: {
    position: 'relative',
    width: rightPanelWidth,

    overflow: 'hidden',

    display: 'flex',
    flexDirection: 'column',

    backgroundColor: theme.palette.background.paper,
  },

  mobile_paper: {
    position: 'relative',

    border: 'none',
    boxShadow:
      '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',

    overflow: 'hidden',

    maxHeight: `100%`,

    display: 'flex',
    flexDirection: 'column',

    backgroundColor: theme.palette.background.paper,
    opacity: 0.98,

    transition: 'border-radius 0.5s ease',
  },

  mobile_grabber: {
    position: 'absolute',
    left: '25%',
    right: '25%',
    top: 6,
    // height: 3,
    borderLeft: '1px solid rgba(0,0,0,0.18)',
    borderRight: '1px solid rgba(0,0,0,0.18)',
    borderTop: '1px solid rgba(0,0,0,0.12)',
    borderBottom: '1px solid rgba(0,0,0,0.24)',

    transition: 'opacity 0.5s ease',
  },

  desktop_scrollable: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxHeight: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  desktop_not_scrollable: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    maxHeight: '100%',
    overflowY: 'hidden',
    overflowX: 'hidden',
  },

  desktop_title: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 12,
    minHeight: 48,
    maxHeight: 48,
  },

  mobile_title: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 12,
    minHeight: 48,
    maxHeight: 48,
    transition: 'border-radius 1s ease',
  },

  title_curve: {
    borderRadius: '4px 4px 0 0',
  },
  flex_horizontal: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },

  flex_vertical: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },

  flex_spacer: {
    flexGrow: 1,
  },

  backButton: {
    marginLeft: -16,
    marginRight: -4,
  },
  shrinkButton: {
    marginLeft: -4,
    transition: 'transform 0.25s ease',
  },
  rotated: {
    transform: 'rotate(180deg)',
  },

  hidden: {
    opacity: 0,
  },
});

class RightPanel extends React.Component {
  handleBack = () => {
    const { back } = this.props;
    if (back === true) {
      history.goBack();
    } else if (typeof back == 'string') {
      history.push(back);
    }
  };

  render() {
    const {
      classes,
      title,
      buttons,
      rightPanelShrunk,
      setRightPanelShrunk,
      children,
      back,
      className,
      noScroll,
    } = this.props;

    const content = (
      <>
        <Divider />
        <div
          className={classNames(
            noScroll ? classes.desktop_not_scrollable : classes.desktop_scrollable
          )}>
          {children}
        </div>
      </>
    );

    return (
      <>
        <Hidden smUp>
          <SwipeableBottomSheet
            overflowHeight={48}
            marginTop={0}
            shadowTip={false}
            fullScreen={false}
            open={!rightPanelShrunk}
            onChange={(x) => setRightPanelShrunk(!x)}
            scrollTopAtClose
            topShadow={true}
            overlay={true}
            bodyStyle={{ backgroundColor: 'transparent' }}>
            <div
              className={classNames(classes.mobile_paper, className, {
                [classes.title_curve]: rightPanelShrunk,
              })}>
              <div
                className={classNames(classes.mobile_grabber, {
                  [classes.hidden]: !rightPanelShrunk,
                })}
              />
              <div
                className={classNames(classes.mobile_title)}
                onClick={() => setRightPanelShrunk(!rightPanelShrunk)}>
                <Typography
                  variant='h6'
                  style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}>
                  {title}
                </Typography>
                <div className={classes.flex_spacer} />
                {buttons}
              </div>
              {content}
            </div>
          </SwipeableBottomSheet>
        </Hidden>
        <Hidden xsDown>
          <Drawer
            variant='persistent'
            anchor='right'
            elevation={16}
            open={!rightPanelShrunk}
            classes={{ paper: classes.desktop_paper }}>
            {/*<div className={classes.desktop_title}>
               {back && (
                <IconButton className={classes.backButton} onClick={this.handleBack}>
                  <Icons.Prev />
                </IconButton>
              )}
              <Typography
                variant='h6'
                style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}>
                {title}
              </Typography>
              <div className={classes.flex_spacer} /> 
              {buttons}
            </div>
*/}
            {content}
          </Drawer>
        </Hidden>
      </>
    );
  }
}

export default connect(
  (state) => ({
    rightPanelShrunk: state.global.preferences.rightPanelShrunk,
  }),
  (dispatch) => ({
    setRightPanelShrunk: (shrunk) =>
      dispatch(globalActions.setPreference({ rightPanelShrunk: shrunk })),
  })
)(withStyles(styles)(RightPanel));
