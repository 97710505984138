import React from 'react';
import * as Icons from '~/icons';
import { showHelpTopic } from '~/lib/modalServices';

import { IconButton } from '@material-ui/core';

interface Props {
  topic: string;
  className?: string;
}

export default function HelpHere({ topic, className }: Props) {
  return (
    <IconButton
      className={className}
      color='inherit'
      size='small'
      style={{ flexGrow: 0, alignSelf: 'flex-start' }}
      onClick={() => showHelpTopic(topic)}>
      <Icons.Help />
    </IconButton>
  );
}
