import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as useravatarActions } from '~/redux/slices/useravatars';

import { Avatar, Tooltip } from '@material-ui/core';

export default function UserAvatar({ userId, noTooltip, missingColor, ...props }) {
  const myselfId = useSelector((state) => state.global.user?.id);
  const userAvatar = useSelector((state) => state.global.user?.avatar);
  const avatarImg = useSelector((state) => state.useravatars.avatarsByUserId[userId]);
  const src = userId == myselfId ? userAvatar : avatarImg?.img;

  // Long ago, the four selectors lived together in harmony

  const dispatch = useDispatch();

  // Then everything changed when we had to dispatch events to redux

  React.useEffect(() => {
    if (userId != myselfId && avatarImg === undefined) {
      dispatch(useravatarActions.requestUserId(userId));
    }
  }, [dispatch, avatarImg, userId, myselfId]);

  if (noTooltip) {
    return (
      <Avatar
        style={{ backgroundColor: !src ? missingColor : 'transparent' }}
        src={src}
        {...props}
      />
    );
  } else {
    return (
      <Tooltip title={userId}>
        <Avatar
          style={{ backgroundColor: !src ? missingColor : 'transparent' }}
          src={src}
          {...props}
        />
      </Tooltip>
    );
  }
}
