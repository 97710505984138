import React from 'react';
import { connect } from 'react-redux';
import { actions as globalActions } from '~/redux/slices/global';
import { regionColors } from '~/theme';

import { Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  panel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },

  analysis: {
    color: regionColors.FIELD,
    '&$checked': {
      color: regionColors.FIELD,
    },
  },
  flight: {
    color: regionColors.FLIGHT,
    '&$checked': {
      color: regionColors.FLIGHT,
    },
  },
  zone: {
    color: regionColors.ZONE,
    '&$checked': {
      color: regionColors.ZONE,
    },
  },
  targeted: {
    color: regionColors.TARGETED,
    '&$checked': {
      color: regionColors.TARGETED,
    },
  },
  keepout: {
    color: regionColors.KEEPOUT,
    '&$checked': {
      color: regionColors.KEEPOUT,
    },
  },
  checked: {},
});

const RegionDisplaySwitches = ({ regionDisplayMode, setRegionDisplayMode, classes }) => {
  const checkboxFor = (key) => {
    return (
      <Checkbox
        key={key}
        checked={regionDisplayMode[key]}
        value={key}
        onChange={(evt) =>
          setRegionDisplayMode({
            ...regionDisplayMode,
            [key]: evt.target.checked,
          })
        }
        classes={{
          root: classes[key],
          checked: classes.checked,
        }}
      />
    );
  };

  return (
    <div className={classes.panel}>
      {checkboxFor('analysis')}
      {checkboxFor('flight')}
      {checkboxFor('zone')}
      {checkboxFor('targeted')}
      {checkboxFor('keepout')}
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  regionDisplayMode: state.global.preferences.regionDisplayMode,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setRegionDisplayMode: (x) => dispatch(globalActions.setPreference({ regionDisplayMode: x })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(RegionDisplaySwitches));
