import React from 'react';
import AvatarEditor from 'react-avatar-edit';
import { checkIsEmail } from '~/lib/email';
import PasswordCheck from '~/shared/PasswordCheck';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import AcceptButton from './AcceptButton';

const styles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(2),
  },
  accepting: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10,
    background: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    padding: theme.spacing(4),
  },
}));

export default function CreateAccountDialog({ open, invite, onClose }) {
  const classes = styles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [request, setRequest] = React.useState({
    existing: false,
    username: invite.email,
    password: '',
    fullname: '',
    avatar: null,
  });

  const [confirmPw, setConfirmPw] = React.useState('');
  const [pwScore, setPwScore] = React.useState(0);

  const emailValid = checkIsEmail(request.username);
  const nameValid = request.fullname.length > 1;
  const pwValid = pwScore > 0 && confirmPw == request.password;

  const detailsValid = emailValid && nameValid && pwValid;

  return (
    <Dialog fullScreen={fullScreen} fullWidth maxWidth='sm' open={open} onClose={onClose}>
      <DialogTitle>Create New Account</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={8} xs={12}>
            <List>
              <ListItem>
                <TextField
                  autoComplete='name'
                  fullWidth
                  label='Full Name'
                  autoFocus
                  value={request.fullname}
                  error={!nameValid}
                  onChange={(e) => setRequest({ ...request, fullname: e.target.value })}
                />
              </ListItem>
              <ListItem>
                <TextField
                  autoComplete='email'
                  fullWidth
                  label='Email Address'
                  value={request.username}
                  error={!emailValid}
                  onChange={(e) => setRequest({ ...request, username: e.target.value })}
                />
              </ListItem>
            </List>
          </Grid>
          <Grid item sm={4} xs={12}>
            <AvatarEditor
              width={160}
              height={160}
              imageWidth={160}
              onCrop={(avatar) => setRequest({ ...request, avatar })}
              onClose={() => {
                setRequest({ ...request, avatar: null });
              }}
              src={request.avatar}
              cropRadius={70}
              label='Upload Avatar'
            />
          </Grid>
          <Grid item xs={12} align='center'>
            <Typography variant='caption' style={{ color: theme.palette.text.hint }}>
              Your full name and avatar help others identify you and your contributions when using
              social features of ScoutView. The avatar is optional.
            </Typography>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <List>
          <PasswordCheck
            isNew
            password={request.password}
            onChangePassword={(v) => setRequest({ ...request, password: v })}
            confirmPassword={confirmPw}
            onChangeConfirm={setConfirmPw}
            onChangeScore={setPwScore}
          />
          <Divider className={classes.divider} />

          <ListItem>
            <AcceptButton invite={invite} request={request} disabled={!detailsValid} />
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions />
    </Dialog>
  );
}
