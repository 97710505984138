import classNames from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';
import { connect } from 'react-redux';
import { useDispatch } from 'react-redux';
import * as Icons from '~/icons';
import route from '~/lib/activeScene';
import { metaForAlerts } from '~/lib/alertHelpers';
import { getColorForNDVI } from '~/lib/colorScales';
import * as geography from '~/lib/geography';
import history from '~/lib/history';
import { parseUTCISO } from '~/lib/time';
import { setTimelineTime } from '~/lib/timeline';
import { resolutionToStr } from '~/lib/units';
import { getChannel, toLocalTz } from '~/redux/commonSelectors';
import { actions as globalActions } from '~/redux/slices/global';
import { actions as uiActions } from '~/redux/slices/ui';
import ListItemLink from '~/shared/ListItemLink';
import ResIndicator from '~/shared/ResIndicator';

import {
  Badge,
  Button,
  Chip,
  Icon,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { regionColors } from '../../theme';
import ResChip from './ResChip';
import { getCurrentImageset } from './selectors';

const styles = (theme) => ({
  root: {
    textDecoration: 'none',
    paddingLeft: 8,
    paddingRight: 4,
    borderLeft: '4px solid white',
    borderColor: 'var(--ndvicolor)',
  },

  hidden: {
    opacity: 0.4,
  },

  textArea: {
    alignSelf: 'stretch',
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
});

function AlertLink({ alert }) {
  const dispatch = useDispatch();

  const setAllChannels = (x) => dispatch(globalActions.setPreference({ channel: x }));
  const setAllAnalysisMode = (x) => dispatch(globalActions.setPreference({ analysisMode: x }));

  const meta = metaForAlerts(alert.alert_type);
  return (
    <Tooltip key={alert.id} title={alert.message}>
      <IconButton
        size='small'
        onClick={() => {
          setAllChannels([meta.channel, meta.channel]);
          setAllAnalysisMode([meta.analysis, meta.analysis]);
        }}>
        {meta.icon}
      </IconButton>
    </Tooltip>
  );
}

function AnalyticsSummaryText({ value }) {
  const theme = useTheme();
  return (
    <Tooltip title={'Analysis'}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: theme.palette.text.secondary,
        }}>
        <span>{value}</span>
        <Icon fontSize='small' style={{ margin: 4 }}>
          <Icons.Analysis />
        </Icon>
      </div>
    </Tooltip>
  );
}

function ImageryLink({
  toLocalTz,
  setTimelineTime,
  imageset,
  classes,
  currentImageset,
  colorForNDVI,
  setAllChannels,
  setAllAnalysisMode,
  channel,
  farmId,
  isMapSplit,
  analysisMode,
  ...props
}) {
  const localdatetime = toLocalTz(parseUTCISO(imageset.timestamp), farmId);
  const localDate = localdatetime.toLocaleString(DateTime.DATE_MED);
  const localTime = localdatetime.toLocaleString(DateTime.TIME_24_WITH_SHORT_OFFSET);

  const hasAnalysis = (i) =>
    !analysisMode[i] ||
    imageset.available_analytics?.map((a) => a.metric).includes(analysisMode[i]);

  const analyticsSummary = (i) => {
    const s = analysisMode[i]
      ? imageset.available_analytics?.find((x) => x.metric == analysisMode[i])?.summary
      : null;
    if (typeof s == 'number') {
      return s.toFixed(0);
    } else {
      return s;
    }
  };

  const indexValue = imageset.summary?.find((s) => s.index == channel)?.mean;

  const mpp = imageset.actual_resolution;
  const isHidden = imageset.hidden || !(hasAnalysis(0) || (hasAnalysis(1) && isMapSplit));
  const isActive = imageset.timestamp == currentImageset.timestamp;
  const color = indexValue !== undefined ? colorForNDVI(indexValue) : null;

  const summary = [analyticsSummary(0), analyticsSummary(1)];
  const summaryText =
    summary[0] != null && summary[1] != null
      ? `${summary[0]} / ${summary[1]}`
      : summary[0] == null && summary[1] != null
      ? `- / ${summary[1]}`
      : summary[0] != null && summary[1] == null
      ? summary[0]
      : null;

  // TODO: Put these alerts somewhere
  const alerts = imageset.alerts?.map((alert, i) => <AlertLink key={i} alert={alert} />);

  return (
    <ListItem
      selected={isActive}
      button
      onClick={() => {
        setTimelineTime(0, imageset.timestamp);
      }}
      style={{ '--ndvicolor': color }}
      className={classNames(classes.root, { [classes.hidden]: isHidden })}
      {...props}>
      <ResIndicator res={mpp} />
      <div className={classes.textArea}>
        <Tooltip title={localTime}>
          <Typography variant='subtitle2'>{localDate}</Typography>
        </Tooltip>
      </div>

      <div style={{ flexGrow: 1 }} />
      <ResChip mpp={mpp} />
      {summaryText ? <AnalyticsSummaryText value={summaryText} /> : alerts}
    </ListItem>
  );
}

const mapStateToProps = (state) => ({
  toLocalTz: toLocalTz(state),
  currentImageset: getCurrentImageset(state, 0),
  setTimelineTime: setTimelineTime(state),
  colorForNDVI: getColorForNDVI(state),
  analysisMode: state.global.preferences.analysisMode,
  isMapSplit: route.isMapSplit(state),
  channel: getChannel(state, 0),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ImageryLink));
