import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getColorForNDVI } from '~/lib/colorScales';
import { actions as globalActions } from '~/redux/slices/global';
import DividerSpacer from '~/shared/DividerSpacer';
import GradientEditor from '~/shared/GradientEditor';
import HelpHere from '~/shared/HelpHere';
import ToggleButtonWithTip from '~/shared/ToggleButtonWithTip';

import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  Slider,
  Typography,
  useTheme,
} from '@material-ui/core';

function interpolate(i, values) {
  // where i is a float, give the index in values
  const low = Math.max(0, Math.floor(i));
  const hi = Math.min(values.length, Math.ceil(i));
  const frac = Math.min(1, Math.max(0, i - low));

  const lowv = values[low];
  const hiv = values[hi];

  return lowv * (1 - frac) + hiv * frac;
}

function percentile(x, values, scale) {
  return interpolate((values.length - 1) * x, values) * scale;
}

// Assumes cell data has 'area' and '<key>' fields,
// returns the map data to display it in the standard way
export function drawGridData(
  name,
  key,
  stops,
  isPercentile,
  cell_data,
  scale,
  analysisOpacity,
  analysisLabels,
  colorForNDVI,
  valueStr,
  precision = 2
) {
  const valueStrFunc = valueStr || ((c) => (c * scale).toFixed(precision));

  const source = {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: cell_data.map((c, i) => ({
        type: 'Feature',
        properties: {
          index: i,
          value: c[key] * scale,
          valuestr: c.valid ? valueStrFunc(c[key]) : null,
          valid: c.valid ? 1 : 0,
        },
        geometry: c.geometry,
      })),
    },
  };

  const bins = stops.length;

  const colors = [0, 0.25, 0.5, 0.75, 1].map((p) => colorForNDVI(p));

  let colorbins = ['case'];
  if (isPercentile) {
    // Calculate 5-number of the avaliable cells so we can translate our stops
    const values_where_valid = cell_data
      .filter((cd) => cd.valid)
      .map((cd) => cd[key])
      .sort((a, b) => a - b);

    const pcStops = stops.map((p) => percentile(p, values_where_valid, scale));
    for (let i = 0; i < bins; i++) {
      colorbins.push(['<=', ['get', 'value'], pcStops[i]]);
      colorbins.push(colors[i]);
    }
    colorbins.push(colors[colors.length - 1]);
  } else {
    // Just color based on the value of each cell
    for (let i = 0; i < bins; i++) {
      colorbins.push(['<=', ['get', 'value'], stops[i]]);
      colorbins.push(colors[i]);
    }
    colorbins.push(colors[colors.length - 1]);
  }

  let layers = [];

  layers.push({
    id: `analysis-layer-${name}`,
    type: 'fill',
    source: `analysis-source-${name}`,
    paint: {
      'fill-color': colorbins,
      'fill-opacity': analysisOpacity,
    },
    filter: ['all', ['==', ['get', 'valid'], 1]],
  });

  if (analysisLabels) {
    // Also push up an overlay including raw numbers
    layers.push({
      id: `analysis-layer-digits-${name}`,
      type: 'symbol',
      source: `analysis-source-${name}`,
      layout: {
        'text-field': ['get', 'valuestr'],
        'text-size': ['interpolate', ['linear'], ['zoom'], 18, 12, 26, 32],
        'text-padding': 1,
      },

      paint: {
        'text-color': 'hsl(0, 0%, 100%)',
        'text-halo-color': 'rgba(0,0,0,0.5)',
        'text-halo-width': 2,
        'text-halo-blur': 1,
      },
    });
  }

  return [
    {
      // Detail overlay for current trid
      srcid: `analysis-source-${name}`,
      source,
      layers,
    },
  ];
}

export function BasicOverlayControlComponent() {
  const theme = useTheme();

  const dispatch = useDispatch();
  const setPreference = (pref) => dispatch(globalActions.setPreference(pref));

  const preferences = useSelector((state) => state.global.preferences);
  const analysisOpacity = preferences.analysisOpacity;
  const setAnalysisOpacity = (x) => setPreference({ analysisOpacity: x });
  const analysisLabels = preferences.analysisLabels;
  const setAnalysisLabels = (x) => setPreference({ analysisLabels: x });

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <div style={{ flexGrow: 1, marginRight: theme.spacing(2) }}>
        <Typography variant='caption'>Overlay opacity</Typography>
        <Slider
          min={0}
          max={10}
          value={analysisOpacity * 10}
          marks
          onChange={(evt, val) => setAnalysisOpacity(val / 10)}
        />
      </div>
      <FormControlLabel
        control={
          <Checkbox
            checked={analysisLabels}
            onChange={() => setAnalysisLabels(!analysisLabels)}
            name='analysisLabels'
            color='primary'
          />
        }
        label='Labels'
      />
    </div>
  );
}

export function BasicStatsControlComponent({
  summary,
  helpTopic,
  pref_key,
  default_prefs,
  ctrl_sz,
  allowPercentile,
  min,
  max,
  step,
  format,
}) {
  const theme = useTheme();

  // Settings
  const preferences = useSelector((state) => state.global.preferences);
  const colorForNDVI = useSelector(getColorForNDVI);

  const myPreferences = { ...default_prefs, ...(preferences[pref_key] || {}) };

  const dispatch = useDispatch();
  const setPreference = (pref) => dispatch(globalActions.setPreference(pref));
  const setMyPreference = (pref) =>
    setPreference({
      [pref_key]: {
        ...myPreferences,
        ...pref,
      },
    });
  const resetPrefs = () => setPreference({ [pref_key]: null });

  const setStops = (x) => setMyPreference({ stops: x });

  // Colors are fixed, stops can move
  const colors = [0, 0.25, 0.5, 0.75, 1].map((p) => colorForNDVI(p));

  return (
    <div
      style={{
        width: ctrl_sz[0],
        height: ctrl_sz[1],
        padding: theme.spacing(1),
      }}>
      <div style={{ display: 'flex', alignItems: 'stretch' }}>
        <Typography variant='caption' component='p'>
          {summary}
        </Typography>
        <HelpHere topic={helpTopic} />
      </div>

      <DividerSpacer />
      <BasicOverlayControlComponent />
      <DividerSpacer />
      <GradientEditor
        isStepped
        colors={colors}
        stops={myPreferences.stops}
        setStops={setStops}
        min={min}
        max={max}
        step={step}
        format={format}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {allowPercentile ? (
          <ButtonGroup
            disableElevation
            color='inherit'
            orientation='horizontal'
            size='small'
            variant='outlined'>
            <ToggleButtonWithTip
              tooltip='Color by percentile grouping'
              toggled={myPreferences.isPercentile}
              onClick={() => setMyPreference({ isPercentile: true })}>
              Percentile
            </ToggleButtonWithTip>
            <ToggleButtonWithTip
              tooltip='Color by value'
              toggled={!myPreferences.isPercentile}
              onClick={() => setMyPreference({ isPercentile: false })}>
              Absolute
            </ToggleButtonWithTip>
          </ButtonGroup>
        ) : (
          <div />
        )}
        <Button variant='text' onClick={resetPrefs}>
          Reset
        </Button>
      </div>
    </div>
  );
}
