import { debounce } from '@material-ui/core';
import { useState, useEffect, useRef } from 'react';

// https://usehooks.com/useDebounce/
function useDebounce<T>(value: T, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

type FormBuffer<T> = { value: T; set: (value: T) => void; err: string | undefined };
export default function useFormBuffer<T>(
  realValue: T,
  setRealValue: (value: T) => void,
  checkError?: (value: T) => string | undefined,
  debouncems?: number
): FormBuffer<T> {
  // This hook will use internal state to store the input to a form, validate it, and only update the actual value if it is valid and debounced

  const lastRealValueRef = useRef<T>(realValue);
  const lastSetBufferRef = useRef<T>(realValue);

  const [buffer, setBuffer] = useState<T>(realValue);
  const debouncedBuffer = useDebounce(buffer, debouncems || 100);

  // If the real value is updated externally, copy it to our internal state.
  // This is generally an antipattern, but not today.
  useEffect(() => {
    if (realValue != lastRealValueRef.current && lastSetBufferRef.current != buffer) {
      // The value has changed externally
      setBuffer(realValue);
    }
    lastRealValueRef.current = realValue;
  }, [realValue, buffer, setBuffer, lastRealValueRef]);

  useEffect(() => {
    // If we get here, then the debounce timeout has expired and we should set the real value (if there's no error)
    if (checkError && checkError(debouncedBuffer) !== undefined) return;

    // Don't set it if it's already equal
    if (debouncedBuffer == lastSetBufferRef.current) return;

    // Don't set it if the real value is what changed
    if (realValue != lastRealValueRef.current) return;

    setRealValue(debouncedBuffer);
    lastSetBufferRef.current = debouncedBuffer;
  }, [checkError, debouncedBuffer, setRealValue, realValue, lastRealValueRef]);

  return { value: buffer, set: setBuffer, err: checkError?.(buffer) };
}

export function formBufferTextFieldProps<T>(fb: FormBuffer<T>) {
  return {
    value: fb.value,
    error: !!fb.err,
    helperText: fb.err,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => fb.set(e.target.value as unknown as T),
  };
}
