import * as Icons from '~/icons';

import MiscHardwareIcon from '@material-ui/icons/DeveloperBoard';
import SystemIcon from '@material-ui/icons/DeviceHub';

const defaults = {
  icon: MiscHardwareIcon,
  subassemblies: {},
  parameters: [],
};

const types = {
  system: {
    icon: SystemIcon,
    subassemblies: {
      drone: 'drone',
      base: 'base',
      tasa: 'tasa',
      req_hw: 'req_hw',
    },
    parameters: [
      {
        field: 'Registration Number',
        title: 'Registration Number',
      },
    ],
  },
  drone: {
    icon: Icons.Drone,
    subassemblies: {
      landing_gear: 'landing_gear',
      arm_1: 'arm',
      arm_2: 'arm',
      arm_3: 'arm',
      arm_4: 'arm',
      payload: 'payload',
      iodb: 'iodb',
      stress_plate: 'stress_plate',
      pdb: 'pdb',
    },
    parameters: [
      { field: 'radio_address', title: 'Radio Address' },
      { field: 'public_key', title: 'Public Key (b64)' },
    ],
  },
  base: {
    icon: Icons.Basestation,
    subassemblies: {
      platform: 'platform',
      e_box: 'e_box',
      power_inlet: 'power_inlet',
      rfbox: 'rfbox',
      dcdc_charge: 'dcdc_charge',
      gps_mast: 'gps_mast',
      computer: 'computer',
      modem: 'modem',
      frame: 'frame',
      platform_drive: 'platform_drive',
      roof_drive_1: 'roof_drive',
      roof_drive_2: 'roof_drive',
    },
    parameters: [{ field: 'radio_address', title: 'Radio Address' }],
  },

  landing_gear: {},
  arm: {
    subassemblies: {
      MOTOR: 'motor', // I know this is the only uppercase key. Sorry, historical reasons
    },
  },
  payload: {
    parameters: [
      { field: 'device_name', title: 'Device Name' },
      { field: 'version', title: 'Version' },
    ],
  },
  iodb: {},
  stress_plate: {},
  pdb: {},
  platform: {},
  e_box: {},
  power_inlet: {},
  rfbox: {},
  dcdc_charge: {},
  gps_mast: {},
  computer: {},
  modem: {},
  frame: {},
  platform_drive: {},
  roof_drive: {},
  tasa: {},
  req_hw: {},
  motor: {},
};

export const expectedTypes = Object.keys(types);

// returns an object containing all the stuff we need to display this part in the tree
export default function partExpectations(type) {
  return { ...defaults, ...types[type] };
}
