import React from 'react';
import { useSelector } from 'react-redux';

import { BasicStatsControlComponent, drawGridData } from './common';

const PREF_KEY = 'analysis_ndvi_vel';
const DEFAULT_PREFS = { stops: [-5, -2, 2, 5], isPercentile: false };
const CTRL_SZ = [288, 27 * 8];
export const name = 'NDVI Velocity';
export const description = 'Rate of change of NDVI over the past several images.';

const ABS_RNG = [-20, 20];

function valAsPct(s) {
  return (s - ABS_RNG[0]) / (ABS_RNG[1] - ABS_RNG[0]);
}

export function overlay(key, analysis, cell_data, preferences, colorForNDVI) {
  const myPreferences = { ...DEFAULT_PREFS, ...(preferences[PREF_KEY] || {}) };

  return drawGridData(
    key,
    'vels',
    myPreferences.stops.map((s) => (myPreferences.isPercentile ? valAsPct(s) : s)), // This is necessary because our absolute range doesn't work well as a percentile.
    myPreferences.isPercentile,
    cell_data,
    1,
    preferences.analysisOpacity,
    preferences.analysisLabels,
    colorForNDVI,
    (c) => c.toFixed(0)
  );
}

function ControlComponent() {
  const preferences = useSelector((state) => state.global.preferences);
  const myPreferences = { ...DEFAULT_PREFS, ...(preferences[PREF_KEY] || {}) };
  return (
    <BasicStatsControlComponent
      summary='Rate of change of NDVI over time, in units of NDVI hundredths per day.'
      helpTopic='Velocity'
      pref_key={PREF_KEY}
      default_prefs={DEFAULT_PREFS}
      ctrl_sz={CTRL_SZ}
      min={ABS_RNG[0]}
      max={ABS_RNG[1]}
      format={
        myPreferences.isPercentile
          ? (x) => `${Math.round(valAsPct(x) * 100)}%`
          : (x) => `${x.toFixed()}`
      }
      allowPercentile
    />
  );
}

export function controls() {
  return {
    width: CTRL_SZ[0],
    height: CTRL_SZ[1],
    component: <ControlComponent />,
  };
}
