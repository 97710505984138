import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { createSelector } from 'reselect';
import { Drone as DroneIcon } from '~/icons';
import { actions as supportActions } from '~/redux/slices/support';
import FeedbackDialog from '~/shared/FeedbackDialog';

import { Divider, Link, Typography } from '@material-ui/core';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { default as ExitIcon } from '@material-ui/icons/ExitToAppSharp';
import Skeleton from '@material-ui/lab/Skeleton';

// TODO: Make a Link HOC that overrides the behavior if it's a support link

function ImageOrVimeo({ alt, title, src }) {
  if (src.includes('player.vimeo.com')) {
    return (
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: 0,
          maxWidth: 800,
          paddingBottom: '51%', // aspect ratio of the video!
          margin: 'auto', // center it when the page is too large
        }}>
        <iframe
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
          }}
          title={title}
          src={src}
          frameBorder='0'
          allowFullScreen
        />
      </div>
    );
  } else {
    return (
      <div
        style={{
          width: '100%',
          maxWidth: 800,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}>
        <img style={{ maxWidth: '100%' }} alt={alt} title={title} src={src} />
      </div>
    );
  }
}

function SpacedDivider(props) {
  const theme = useTheme();
  return <Divider {...props} style={{ margin: theme.spacing(1) }} />;
}

function Heading({ level, ...props }) {
  const theme = useTheme();
  const margin = level >= 3 ? '0.35em' : 0;
  return (
    <>
      <Typography
        style={{ marginTop: margin, marginBottom: margin }}
        variant={`h${level}`}
        {...props}
      />
      {level < 3 && <Divider style={{ margin: theme.spacing(1) }} />}
    </>
  );
}
function Paragraph({ ...props }) {
  return <Typography paragraph variant='body1' {...props} />;
}

function InlineCode(props) {
  const theme = useTheme();
  return <code style={{ background: theme.palette.action.hover }} {...props} />;
}

function BlockCode({ value, ...props }) {
  const theme = useTheme();
  return (
    <pre
      style={{
        padding: theme.spacing(2),
        overflowX: 'auto',
        borderRadius: theme.spacing(1),
        background: theme.palette.action.hover,
        flexShrink: 0,
      }}>
      <code {...props}>{value}</code>{' '}
    </pre>
  );
}

const SpecialLink = (onNav) => {
  function TopicLink({ href, children }) {
    const valid = useSelector((state) => {
      if (href.startsWith('/support')) {
        const topic = href.slice(9);
        return state.support.availableTopics.includes(topic);
      } else return false;
    });

    // if it starts with / use RouterLink
    // if it starts with /support use a link with onNav
    if (href.startsWith('/support')) {
      const topic = href.slice(9);
      return (
        <Link
          href={href}
          color={valid ? 'primary' : 'error'}
          onClick={(e) => onNav(e, topic)}
          style={{ fontWeight: 600 }}>
          {children}
        </Link>
      );
    }

    if (href.startsWith('/')) {
      return (
        <Link component={RouterLink} to={href}>
          {children} <DroneIcon fontSize='inherit' />
        </Link>
      );
    }
    return (
      <Link target='_blank' rel='noopener' href={href}>
        {children} <ExitIcon fontSize='inherit' />
      </Link>
    );
  }
  return TopicLink;
};

export default function ComponentForTopic({ topic, onNav }) {
  const topicData = useSelector((state) => state.support.markdownByTopic[topic]);

  const [showFeedback, setShowFeedback] = React.useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (topic == '') return; // We don't call for the Table of Contents
    if (topicData === undefined) {
      dispatch(supportActions.requestTopic({ topic: topic }));
    }
  }, [topic, topicData, dispatch]);

  if (!topicData || topicData.loading) {
    return <Skeleton animation='wave' />;
  }

  if (topicData.error) {
    return (
      <>
        <Typography variant='h2' color='error'>
          Topic not found
        </Typography>

        <Typography variant='subtitle2'>
          If you arrived here via a link, please{' '}
          <Link onClick={() => setShowFeedback(true)}>submit feedback</Link> so we can fix the
          error.
        </Typography>
        <Typography variant='subtitle2'>
          If you entered this URL, please check your spelling and try again.
        </Typography>

        <FeedbackDialog
          initialText={`I was linked to the unavailable help page "${topic}"`}
          open={showFeedback}
          onClose={() => setShowFeedback(false)}
        />
      </>
    );
  }

  const NavSpecialLink = SpecialLink(onNav);

  return (
    <ReactMarkdown
      escapeHtml={false}
      source={topicData.md}
      renderers={{
        image: ImageOrVimeo,
        imageReference: ImageOrVimeo,
        paragraph: Paragraph,
        heading: Heading,
        thematicBreak: SpacedDivider,
        inlineCode: InlineCode,
        code: BlockCode,
        link: NavSpecialLink,
        linkReference: NavSpecialLink,
      }}
    />
  );
}
