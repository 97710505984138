import produce from 'immer';
import stringify from 'json-stable-stringify';
import mapboxgl from 'mapbox-gl';
import React from 'react';
import { createSelector } from 'reselect';
import * as wkt from 'wellknown';
import * as Icons from '~/icons';
import { areaOfFeature } from '~/lib/geography';
import { centerOfShape } from '~/lib/geography';
import history from '~/lib/history';
import { showConfirmDialog } from '~/lib/modalServices';
import { useSVDispatch, useSVSelector } from '~/redux/hooks';
import { getUsershapeValid, getUsershapeWKT } from '~/redux/slices/usershape';
import { Farm } from '~/schema';
import RightPanel from '~/shared/RightPanel';
import { RootState } from '~/store';
import { actions as usershapeActions } from '~/redux/slices/usershape';

import {
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { feature } from '@turf/helpers';
import * as turf from '@turf/turf';

import * as selectors from './selectors';
import { actions } from './slice';

const M_P_FT = 0.3048;

interface SystemSelectorProps {
  systems: string[];
  all: string[];
  onChange: (systemIds: string[]) => void;
}

function SystemSelector({ systems, all, onChange }: SystemSelectorProps) {
  return (
    <Autocomplete
      multiple
      disableClearable
      fullWidth // not yet released
      style={{ flexGrow: 1 }} // until fullWidth works
      value={systems || []}
      onChange={(event, newValue) => onChange(newValue)}
      options={all}
      getOptionLabel={(option) => option}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip key={option} label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField {...params} fullWidth label='Assigned Systems' variant='outlined' />
      )}
    />
  );
}

const styles = makeStyles((theme) => ({
  flex: {
    display: 'flex',
  },
  buttonContainer: {
    display: 'flex',
    '& > *': {
      marginLeft: 4,
      marginRight: 4,
    },
  },

  flexGrow: {
    flex: 1,
  },
  container: {
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },

  spacer: {
    flexShrink: 0,
    width: 8,
  },

  errorLabel: {
    color: theme.palette.error.main,
  },

  chipArray: {
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

const getAllFarmIds = createSelector(
  (state: RootState) => state.global.farms,
  (allFarms) => Object.keys(allFarms)
);

export default function FarmEditPanel() {
  const selectedFarmId = useSVSelector(selectors.selectedFarmId) as string;
  const originalFarm = useSVSelector(selectors.selectedFarm);
  const isEditingKeepin = useSVSelector((state) => state.farmsScene.editingKeepin);
  const tmpFarm = useSVSelector(selectors.getTmpFarm) as Farm | undefined;
  const allFarmIds = useSVSelector(getAllFarmIds);
  const growers = useSVSelector((state) => state.global.growers);
  // const usershapeAdding = useSVSelector( getUsershapeAdding)
  // const usershapeEditing = useSVSelector( getUsershapeEditing)
  const usershapeWkt = useSVSelector(getUsershapeWKT);
  const isUsershapeValid = useSVSelector(getUsershapeValid);
  const viewportCenter = useSVSelector((state) => state.ui.viewport.center);
  const userPosition = useSVSelector((state) => state.ui.userPosition);

  const dispatch = useSVDispatch();

  const setTmpFarm = React.useCallback(
    (id: string, farm: Farm | undefined) => dispatch(actions.setTmpFarm({ id, farm })),
    [dispatch]
  );

  const uploadFarm = (id: string, f: Farm) => dispatch(actions.uploadFarm(id, f));
  const setEditingKeepin = (x: boolean) => {
    dispatch(actions.setEditingKeepin(x));
    if (tmpFarm?.keepin) {
      dispatch(usershapeActions.setUsershapeFromWKT({ wktstr: tmpFarm.keepin }));
    } else {
      dispatch(usershapeActions.resetShape());
    }
  };

  const updateTmp = React.useCallback(
    (f: (tmp: Farm) => void) => {
      const x = produce(tmpFarm, (farm: Farm) => {
        f(farm);
        return;
      });

      setTmpFarm(selectedFarmId, x);
    },
    [tmpFarm, setTmpFarm, selectedFarmId]
  );

  React.useEffect(() => {
    if (isUsershapeValid && usershapeWkt && tmpFarm?.keepin != usershapeWkt) {
      // We want to update the tmp farm, not have to deal with merged nonsense
      updateTmp((f: Farm) => (f.keepin = usershapeWkt));
    }
  }, [usershapeWkt, isUsershapeValid, updateTmp, tmpFarm]);

  if (!tmpFarm) return null; // We really need tmpfarm.

  function cleaner<Val>(k: string, v: Val): Val | undefined {
    const ignore = ['last_updated', 'id'];
    if (ignore.includes(k)) {
      return undefined;
    }
    if (v === null) {
      return undefined;
    }

    function isNum(x: Val | number): x is number {
      return typeof v === 'number';
    }

    if (isNum(v)) {
      // This is a little sketchy with TS, but it's valid
      return (Math.round(v * 100) / 100) as unknown as Val;
    }
    return v;
  }

  const isDirty =
    stringify(originalFarm, { replacer: cleaner }) != stringify(tmpFarm, { replacer: cleaner });

  const saveFarm = async () => {
    uploadFarm(selectedFarmId, tmpFarm); // This'll close automatically on success
  };

  const revert = async () => {
    if (isDirty) {
      const confirm = await showConfirmDialog(
        'Discard changes',
        'Are you sure you want to close the page without saving changes?',
        'Discard'
      );
      if (!confirm) return;
    }

    setTmpFarm(selectedFarmId, undefined);
    history.push('/farms');
  };

  const handleSetEditingKeepin = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setEditingKeepin(evt.target.checked);
  };

  const setPlannedBaseLocation = () => {
    // Find the center of the keepin, place the base there
    if (!tmpFarm) return;

    const center = centerOfShape(tmpFarm.keepin);
    if (center === null) return;
    const centerWkt = `POINT(${center[0]} ${center[1]})`;
    updateTmp((f) => (f.planned_base_location = centerWkt));
  };

  const classes = styles();

  const farm = tmpFarm;

  const isNew = originalFarm.id == undefined;

  const farmIdInvalid =
    !farm.id || (allFarmIds.includes(farm.id) && farm.id != farm.id) || farm.id == 'new';
  const keepinInvalid = !farm.keepin;
  const safeSpotsValid =
    farm.safelandlocations?.length > 0 &&
    farm.safelandlocations?.filter((s) => s.type == 'PRIMARY')?.length >= 1;

  const isFarmValid =
    !farmIdInvalid &&
    farm.grower_id &&
    farm.location &&
    !keepinInvalid &&
    safeSpotsValid &&
    farm.altitude_floor &&
    farm.altitude_ceiling &&
    farm.speed_limit;

  // calculate teh area of the farm's keepin
  const keepin_feat = farm.keepin ? feature(wkt.parse(farm.keepin)) : null;
  const keepin_area = keepin_feat
    ? Math.round(
        areaOfFeature('ACRES', keepin_feat as turf.Feature<turf.Geometry, turf.Properties>) || 0
      )
    : 0;

  const setSafeLandWkt = (ssidx: number, coord: string[]) => {
    updateTmp((f) => {
      f.safelandlocations[ssidx].location = `POINT(${coord[0]} ${coord[1]} ${coord[2] || 15})`;
    });
  };

  const parseSLL = (wktstr: string): [string, string, string] => {
    const pointRE = /POINT\s*\(([-.\d]*) ([-.\d]*) ([.\d]*)\)/;
    const vals = wktstr.match(pointRE);
    if (!vals || vals.length != 4) return ['', '', ''];
    return [vals[1], vals[2], vals[3]];
  };

  return (
    <RightPanel title={isNew ? 'New Farm' : `Edit Farm`} back>
      <List disablePadding>
        {/* Need to display the id, but only allow editing it if we are 'creating' */}
        <ListItem>
          <TextField
            id='id'
            fullWidth
            label='Unique Farm ID'
            value={farm.id || ''}
            disabled={!isNew}
            error={farmIdInvalid}
            onChange={(e) => {
              updateTmp((f) => (f.id = e.target.value));
            }}
          />
        </ListItem>
        <ListItem>
          <FormControlLabel
            control={
              <Switch
                id='editingKeepin'
                color='primary'
                checked={isEditingKeepin}
                onChange={handleSetEditingKeepin}
                value='editing'
              />
            }
            label='Edit Keepin'
            classes={{ label: keepinInvalid ? classes.errorLabel : undefined }}
          />
        </ListItem>
        <ListItem>
          <Typography variant='caption'>
            Keepin covers <em>{keepin_area}</em> ac
          </Typography>
        </ListItem>

        {farm.keepin && !farm.planned_base_location && (
          <ListItem>
            <Button
              variant='contained'
              color='secondary'
              onClick={setPlannedBaseLocation}
              fullWidth>
              Plan Base Location
            </Button>
          </ListItem>
        )}

        {/* TODO: This needs to be a dropdown? */}
        <ListItem>
          <FormControl fullWidth>
            <InputLabel htmlFor='grower'>Grower</InputLabel>
            <Select
              inputProps={{
                id: 'grower',
              }}
              value={growers.includes(farm.grower_id) ? farm.grower_id : ''}
              onChange={(e) => updateTmp((f) => (f.grower_id = e.target.value as string))}>
              {growers.map((x) => (
                <MenuItem key={x} value={x}>
                  {x}
                </MenuItem>
              ))}
              <MenuItem value={''}>New Grower...</MenuItem>
            </Select>
          </FormControl>
        </ListItem>
        {!growers.includes(farm.grower_id) && (
          <ListItem>
            <TextField
              id='grower_id'
              fullWidth
              label='New Grower ID'
              value={farm.grower_id || ''}
              error={!farm.grower_id}
              onChange={(e) => updateTmp((f) => (f.grower_id = e.target.value))}
            />
          </ListItem>
        )}
        <ListItem>
          <TextField
            id='location'
            fullWidth
            label='Location (e.g. town)'
            error={!farm.location}
            value={farm.location || ''}
            onChange={(e) => updateTmp((f) => (f.location = e.target.value))}
          />
        </ListItem>
        <ListItem>
          <TextField
            id='ceiling'
            fullWidth
            error={!farm.altitude_ceiling}
            label='Flight Ceiling'
            InputProps={{ endAdornment: <InputAdornment position='end'>ft</InputAdornment> }}
            value={Math.round(farm.altitude_ceiling / M_P_FT) || ''}
            onChange={(e) =>
              updateTmp(
                (f) => (f.altitude_ceiling = Math.max(0, parseFloat(e.target.value) * M_P_FT || 0))
              )
            }
          />
        </ListItem>
        <ListItem>
          <TextField
            id='floor'
            fullWidth
            error={!farm.altitude_floor}
            label='Travel Floor'
            InputProps={{ endAdornment: <InputAdornment position='end'>ft</InputAdornment> }}
            value={Math.round(farm.altitude_floor / M_P_FT) || ''}
            onChange={(e) =>
              updateTmp(
                (f) => (f.altitude_floor = Math.max(0, parseFloat(e.target.value) * M_P_FT || 0))
              )
            }
          />
        </ListItem>
        <ListItem>
          <TextField
            id='speed'
            fullWidth
            error={!farm.speed_limit}
            label='Speed Limit'
            InputProps={{ endAdornment: <InputAdornment position='end'>m/s</InputAdornment> }}
            value={farm.speed_limit || ''}
            onChange={(e) =>
              updateTmp((f) => (f.speed_limit = Math.max(0, parseFloat(e.target.value) || 0)))
            }
          />
        </ListItem>
        <ListItem>
          <FormControl fullWidth>
            <InputLabel htmlFor='timezone'>Timezone</InputLabel>
            <Select
              inputProps={{
                id: 'timezone',
              }}
              value={farm.timezone}
              onChange={(e) => updateTmp((f) => (f.timezone = e.target.value as string))}>
              <MenuItem value={'America/New_York'}>Eastern</MenuItem>
              <MenuItem value={'America/Chicago'}>Central</MenuItem>
              <MenuItem value={'America/Denver'}>Mountain</MenuItem>
              <MenuItem value={'America/Los_Angeles'}>Pacific</MenuItem>
              <MenuItem value={'America/Honolulu'}>Hawaii-Aleutian</MenuItem>
              <MenuItem value={'America/Anchorage'}>Alaska</MenuItem>
              <MenuItem value={'America/Phoenix'}>Arizona (MST)</MenuItem>
            </Select>
          </FormControl>
        </ListItem>
        <Divider />
        {farm.safelandlocations
          ?.filter((ss) => !ss.deleted)
          .map((ss) => {
            const ssi = farm.safelandlocations.indexOf(ss);
            const coordinates = parseSLL(ss.location);
            const lon = coordinates[0];
            const lat = coordinates[1];
            const alt = coordinates[2];

            return (
              <React.Fragment key={ssi}>
                <ListItem>
                  <TextField
                    fullWidth
                    label='Safe Spot'
                    error={!ss.display_name}
                    value={ss.display_name || ''}
                    onChange={(e) => {
                      updateTmp((f) => (f.safelandlocations[ssi].display_name = e.target.value));
                    }}
                  />
                </ListItem>
                <ListItem dense>
                  <TextField
                    fullWidth
                    label='Lat'
                    error={parseFloat(lat) < -90 || parseFloat(lat) > 90 || isNaN(parseFloat(lat))}
                    value={lat}
                    onChange={(e) => {
                      setSafeLandWkt(ssi, [coordinates[0], e.target.value, coordinates[2]]);
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>&deg;</InputAdornment>,
                    }}
                  />
                </ListItem>
                <ListItem dense>
                  <TextField
                    fullWidth
                    label='Lon'
                    error={
                      parseFloat(lon) < -180 || parseFloat(lon) > 180 || isNaN(parseFloat(lon))
                    }
                    value={lon}
                    onChange={(e) => {
                      setSafeLandWkt(ssi, [e.target.value, coordinates[1], coordinates[2]]);
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>&deg;</InputAdornment>,
                    }}
                  />
                </ListItem>
                <ListItem dense>
                  <TextField
                    fullWidth
                    label='Alt'
                    type='number'
                    error={false}
                    value={Math.round(parseFloat(alt) / M_P_FT)}
                    inputProps={{ step: 5 }}
                    onChange={(e) => {
                      const alt = parseFloat(e.target.value) * M_P_FT;
                      if (alt > farm.altitude_ceiling || alt < 0) return;
                      setSafeLandWkt(ssi, [coordinates[0], coordinates[1], `${alt}`]);
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>ft</InputAdornment>,
                    }}
                  />
                </ListItem>
                <ListItem divider dense>
                  <Button
                    size='small'
                    onClick={() => {
                      updateTmp((f) => (f.safelandlocations[ssi].deleted = true));
                    }}>
                    Delete
                  </Button>
                  <div className={classes.flexGrow} />

                  {userPosition != null && (
                    <>
                      <Tooltip title='Set safe spot to device location'>
                        <IconButton
                          size='small'
                          color='primary'
                          onClick={() => {
                            const wktstr = `POINT(${userPosition?.coords?.[0]} ${userPosition?.coords?.[1]} ${coordinates[2]})`;

                            updateTmp((f) => (f.safelandlocations[ssi].location = wktstr));
                          }}>
                          <Icons.Location />
                        </IconButton>
                      </Tooltip>

                      <div className={classes.flexGrow} />
                    </>
                  )}
                  <FormControlLabel
                    value='primary'
                    label='Primary'
                    labelPlacement='start'
                    control={
                      <Checkbox
                        checked={ss.type == 'PRIMARY'}
                        onChange={(evt) =>
                          updateTmp((f) => {
                            evt.target.checked
                              ? (f.safelandlocations[ssi].type = 'PRIMARY')
                              : (f.safelandlocations[ssi].type = 'FIELD_SPOT');
                          })
                        }
                        color='primary'
                      />
                    }
                  />
                </ListItem>
              </React.Fragment>
            );
          })}

        <ListItem
          button
          onClick={() => {
            updateTmp((f) => {
              const vpc = mapboxgl.LngLat.convert(viewportCenter);
              const newss = {
                location: `POINT(${vpc.lng} ${vpc.lat} ${f.altitude_floor || 0})`,
                display_name: 'New',
                farm_id: f.id,
                type: !f.safelandlocations ? 'PRIMARY' : 'FIELD_SPOT',
              };

              if (f.safelandlocations) f.safelandlocations.push(newss);
              else f.safelandlocations = [newss];
            });
          }}>
          <Icons.Add />
          <div style={{ paddingLeft: 8, flexGrow: 1 }}>
            <Typography variant='body2'>Add Safe Spot</Typography>
          </div>
        </ListItem>
        <Divider />

        {!safeSpotsValid && (
          <>
            <ListItem>
              <Typography variant='caption' color='error'>
                Must have one primary safe spot.
              </Typography>
            </ListItem>
            <Divider />
          </>
        )}
        {!isNew && (
          <>
            <ListItem>
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    checked={farm.deleted}
                    onChange={(evt) => updateTmp((f) => (f.deleted = evt.target.checked))}
                    value='checkedA'
                  />
                }
                label='Deleted'
              />
            </ListItem>
            <Divider />
          </>
        )}
        {(farm.available_systems?.length || 0) > 0 && (
          <>
            <ListItem>
              <SystemSelector
                systems={farm.systems || []}
                all={farm.available_systems || []}
                onChange={(systems) => updateTmp((f) => (f.systems = systems))}
              />
            </ListItem>

            <Divider />
          </>
        )}
        <ListItem>
          <Button fullWidth onClick={revert} variant='contained' color='secondary'>
            Close
          </Button>

          <div className={classes.spacer} />
          <Button
            fullWidth
            onClick={saveFarm}
            color='primary'
            variant='contained'
            disabled={!isDirty || !isFarmValid}>
            Save
          </Button>
        </ListItem>
      </List>
    </RightPanel>
  );
}
