import React from 'react';
import * as Icons from '~/icons';

import {
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import TreeView from '@material-ui/lab/TreeView';

import PartTreeItem from './PartTreeItem';
import SetSubassemblyDialog from './SetSubassemblyDialog';
import partExpectations from './partExpectations';

const useAssemblyTreeStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

export default function AssemblyTree({
  tree,
  type,
  sn,
  sasms,
  partId,
  editSubassembly,
  revertSubassembly,
  availableSubassemblies,
}) {
  const classes = useAssemblyTreeStyles();
  const [showSasmDialog, setShowSasmDialog] = React.useState(null); // can be null or { key, keyType }

  function findParents(part) {
    if (part.children?.some((c) => c.id == partId)) {
      return [part.id]; // I'm the first parent
    }

    var parentsBelowMe = [];
    for (const c of part.children || []) {
      parentsBelowMe.push(...findParents(c));
    }

    if (parentsBelowMe.length > 0) {
      return [part.id, ...parentsBelowMe];
    } else {
      return [];
    }
  }

  async function editSubassemblyBound(sasm_key) {
    const expectations = partExpectations(type);
    const keyType = sasm_key
      ? expectations.subassemblies[sasm_key] || sasms.find((c) => c.key == sasm_key)?.type
      : null;

    setShowSasmDialog({ key: sasm_key, keyType });
  }

  function clearSubassemblyBound(sasm_key) {
    editSubassembly({ type, sn, sasm_key, sasm_type: null, sasm_sn: null });
  }
  function revertSubassemblyBound(sasm_key) {
    revertSubassembly({ type, sn, sasm_key, sasm_type: null, sasm_sn: null });
  }

  const parentIds = findParents(tree);

  return (
    <>
      <Paper>
        <List>
          <ListItem>
            <ListItemText primary='Assembly Tree' />
            <ListItemSecondaryAction>
              <Button color='primary' onClick={() => editSubassemblyBound(null)}>
                Add Subassembly
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <TreeView
              selected={partId}
              defaultExpanded={[partId, ...parentIds]}
              defaultCollapseIcon={<ArrowDropDownIcon />}
              defaultExpandIcon={<ArrowRightIcon />}
              defaultEndIcon={<div style={{ width: 24 }} />}
              className={classes.root}>
              <PartTreeItem
                part={tree}
                selectedId={partId}
                editSubassembly={editSubassemblyBound}
                clearSubassembly={clearSubassemblyBound}
                revertSubassembly={revertSubassemblyBound}
              />
            </TreeView>
          </ListItem>
        </List>
      </Paper>
      <SetSubassemblyDialog
        parameters={showSasmDialog}
        onClose={(results) => {
          if (results) {
            const { chosenKey, chosenType, chosenSN } = results;
            editSubassembly({
              type,
              sn,
              sasm_key: showSasmDialog.key || chosenKey,
              sasm_type: showSasmDialog.keyType || chosenType,
              sasm_sn: chosenSN,
            });
          }
          setShowSasmDialog(null);
        }}
        availableSubassemblies={availableSubassemblies}
      />
    </>
  );
}
