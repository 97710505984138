import { Property as CSSProperty } from 'csstype';
import mapboxgl from 'mapbox-gl';
import React from 'react';
import { ParametricSelector, Selector } from 'reselect';
import Logging from '~/logging';
import { getQueryOrgAndSite } from '~/redux/selectors/global';
import { UsershapeType } from '~/redux/slices/usershape/types';
import type { Imagery, SVImageSet } from '~/schema';
import { DisplayRegion } from '~/schema/Region';
import { DisplayAsset } from '~/schema/Asset';
import type { RootState, SVThunkAction } from '~/store';

export interface MapStyle {
  imagery: Imagery[];
  regions?: DisplayRegion[];
  assets?: DisplayAsset[];
  extras: { layers: mapboxgl.AnyLayer[]; sources: Record<string, mapboxgl.Source> };
}

export interface MapControls {
  NW?: React.ReactNode[];
  NE?: React.ReactNode[];
  SW?: React.ReactNode[];
  SE?: React.ReactNode[];
  footer?: React.ReactNode[];
}

export interface ClickableFeature {
  feature: string;
  cursor: CSSProperty.Cursor;
  onClick: (cursor: [number, number]) => void;
}

type BoundingBox = [[number, number], [number, number]];

type Analytics = unknown;
type ImageryRange = [[number, number], [number, number], [number, number]];

export interface Scene {
  orgSite: Selector<RootState, { orgId: string | null; siteId: string | null }>;
  matches: Selector<RootState, boolean>;
  params: Selector<RootState, Record<string, any> | null>;
  title: Selector<RootState, string>;
  breadcrumbs: Selector<RootState, React.ReactNode[]>;
  mapStyle: ParametricSelector<RootState, number, MapStyle | null>;
  imagesets: Selector<RootState, SVImageSet[]>;
  appbarActions: Selector<RootState, React.ReactNode[]>;
  visibleImagesets: ParametricSelector<RootState, number, SVImageSet[]>;
  visibleAnalytics: ParametricSelector<RootState, number, Analytics[]>;
  estimateKey: Selector<RootState, string | null>;
  panel: Selector<RootState, React.ReactNode | null>;
  fetch: Selector<RootState, SVThunkAction | null>;
  defaultBounds: Selector<RootState, BoundingBox | null>;
  resetBounds: Selector<RootState, BoundingBox | null>;
  maxBounds: Selector<RootState, BoundingBox | null>;
  markers: Selector<RootState, React.ReactNode[]>;
  controls: Selector<RootState, MapControls>;
  component: Selector<RootState, React.ReactNode | null>;
  editingUsershape: Selector<
    RootState,
    {
      color?: string;
      allowPolyHoles: boolean;
      allowMultiPolygons: boolean;
      allowed: UsershapeType[];
    } | null
  >;
  shouldUsershapeLock: Selector<RootState, boolean>;
  hasTimeline: Selector<RootState, string[] | null>;
  flightPath: Selector<RootState, string | null>;
  infoText: Selector<RootState, string | null>;
  imageryResolution: Selector<RootState, number[]>;
  imageryRange: ParametricSelector<RootState, number, ImageryRange | null>;
  helpTopic: Selector<RootState, string>;
  isMapSplit: Selector<RootState, boolean>;
  clickableFeatures: Selector<RootState, ClickableFeature[]>;
}

const allScenes: Partial<Scene>[] = [];

export const registerScene = (x: Partial<Scene>) => {
  if (allScenes.includes(x)) return;
  allScenes.push(x);
};

const currentScene = (state: RootState) => {
  if (!state) {
    return null;
  }
  for (const r of allScenes) {
    if (r.matches?.(state) || r.params?.(state) != null) {
      return r;
    }
  }

  if (allScenes.length > 0)
    Logging.warn('No scene matches route', { location: window.location.href });

  return null;
};

const EMTPY_ARRAY: any[] = [];
const EMPTY_OBJ = {};

const activeScene: Scene = {
  // How does this route know which org/site we're looking at? By default, it's the query params.
  orgSite: (state) => currentScene(state)?.orgSite?.(state) || getQueryOrgAndSite(state),

  // Do we match the path?
  matches: (state) =>
    currentScene(state)?.matches?.(state) || currentScene(state)?.params?.(state) != null || false,
  // URL parameters that this Scene gets
  params: (state) => currentScene(state)?.params?.(state) || null,

  // The title of the page.
  title: (state) => currentScene(state)?.title?.(state) || '404 Not Found',

  // Component to display in the title bar
  breadcrumbs: (state) => currentScene(state)?.breadcrumbs?.(state) || EMTPY_ARRAY,

  // A help topic that should link from this route
  helpTopic: (state) => currentScene(state)?.helpTopic?.(state) || '',

  // Actions that go on the left
  appbarActions: (state) => currentScene(state)?.appbarActions?.(state) || EMTPY_ARRAY,

  // Style to apply to the map. mapidx is 0 or 1, for split screen
  mapStyle: (state, mapidx) => currentScene(state)?.mapStyle?.(state, mapidx) || null,

  // Imagesets that are currently displayed. Used so components know what analytics are available, etc.
  imagesets: (state) => currentScene(state)?.imagesets?.(state) || EMTPY_ARRAY,

  visibleImagesets: (state, mapidx) =>
    currentScene(state)?.visibleImagesets?.(state, mapidx) || EMTPY_ARRAY,

  // Contextual panel
  panel: (state) => currentScene(state)?.panel?.(state) || null,

  // dispatch => {} function to call when the Scene loads
  fetch: (state) => currentScene(state)?.fetch?.(state) || null,

  // a bounds like object that the map will default to at this Scene
  defaultBounds: (state) => currentScene(state)?.defaultBounds?.(state) || null,

  // like default bounds, but only to be used when resetting or initializing. Defaults to default.
  resetBounds: (state) =>
    currentScene(state)?.resetBounds?.(state) ||
    currentScene(state)?.defaultBounds?.(state) ||
    null,

  maxBounds: (state) => currentScene(state)?.maxBounds?.(state) || null,

  // markers for the map to display
  markers: (state) => currentScene(state)?.markers?.(state) || EMTPY_ARRAY,

  // Extra controls to put around the map
  controls: (state) => currentScene(state)?.controls?.(state) || EMPTY_OBJ,

  // A component to display instead of the map
  component: (state) => currentScene(state)?.component?.(state),

  // true if we should be displaying the usershape editing controls,
  // null/false if not
  editingUsershape: (state) => currentScene(state)?.editingUsershape?.(state) || null,

  // String key used to find the flight estimate, if any. else null
  estimateKey: (state) => {
    const key = currentScene(state)?.estimateKey?.(state);
    return key !== undefined ? key : null;
  },

  // Returns true or false, if true, disable usershape editing controls.
  shouldUsershapeLock: (state) => currentScene(state)?.shouldUsershapeLock?.(state) || false,

  // Does this map support timeline scrubbing?
  hasTimeline: (state) => currentScene(state)?.hasTimeline?.(state) || null,

  // null unless you want to display a flight path, if so, wkt linestring
  flightPath: (state) => currentScene(state)?.flightPath?.(state) || null,

  // Any features on the map that should respond to click/hover
  clickableFeatures: (state) => currentScene(state)?.clickableFeatures?.(state) || EMTPY_ARRAY,

  infoText: (state) => currentScene(state)?.infoText?.(state) || null,

  imageryResolution: (state) => currentScene(state)?.imageryResolution?.(state) || EMTPY_ARRAY,
  imageryRange: (state, mapidx) => currentScene(state)?.imageryRange?.(state, mapidx) || null,

  visibleAnalytics: (state, mapidx) =>
    currentScene(state)?.visibleAnalytics?.(state, mapidx) || EMTPY_ARRAY,

  // For checking if a map split is desired and active
  isMapSplit: (state) => currentScene(state)?.isMapSplit?.(state) || false,
};

export default activeScene;
