import { DateTime, Duration } from 'luxon';
import React from 'react';
import * as Icons from '~/icons';
import { NoFlyTime } from '~/schema';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  ListItemText,
  MenuItem,
  TextField,
  makeStyles,
} from '@material-ui/core';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';

import RRuleEditor from './RRuleEditor';

interface Props {
  tz: string;
  open: boolean;
  onClose(): void;
  event?: NoFlyTime;
  updateEvent(evt: NoFlyTime): Promise<void>;
  deleteEvent(evt: NoFlyTime): Promise<void>;
  avaialbleRegions: any[]; // Region objects
}

const styles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    '& > *:not(:first-child)': { marginTop: theme.spacing(1) },
  },
}));
// Edit or create a new SFR.
export default function NoFlyEditDialog({
  tz,
  open,
  onClose,
  event,
  updateEvent,
  deleteEvent,
  avaialbleRegions,
}: Props) {
  const classes = styles();

  const [tmpEvent, setTmpEvent] = React.useState<NoFlyTime>();

  const [durHour, _setDurHour] = React.useState(0);
  const [durMin, _setDurMin] = React.useState(0);

  function clamp(x: number, min: number, max: number) {
    if (x > max) return max;
    if (x < min) return min;
    return x;
  }

  function setDur(hour: number, min: number) {
    if (isNaN(hour) || isNaN(min)) return;

    hour = clamp(hour, 0, 24);
    min = clamp(Math.round(min / 15) * 15, 0, 300);
    _setDurHour(hour);
    _setDurMin(min);
    if (tmpEvent)
      setTmpEvent({
        ...tmpEvent,
        duration_minutes: Duration.fromObject({ hours: hour, minutes: min }),
      });
  }

  React.useEffect(() => {
    setTmpEvent(event); // Update the internal state when we get a new event.
    if (event) {
      _setDurHour(event.duration_minutes.hours);
      _setDurMin(event.duration_minutes.minutes);
    } else {
      _setDurHour(0);
      _setDurMin(0);
    }
  }, [event]);

  const save = async () => {
    if (tmpEvent != null) await updateEvent(tmpEvent);
  };

  if (!tmpEvent) return null; // What do we do if we have no event set?

  // const setStartTime = (time: string) => {
  //   tmpEvent.start_datetime.set({ time });
  //   setTmpEvent({ ...tmpEvent, start_datetime: `${startDate}T${time}` });
  // };
  // const setStartDate = (date: string) => {
  //   setTmpEvent({ ...tmpEvent, start_datetime: `${date}T${startTime}` });
  // };

  const setStartDt = (dt: DateTime) => {
    setTmpEvent({ ...tmpEvent, start_datetime: dt });
  };

  const allValid = tmpEvent && tmpEvent.duration_minutes.as('minutes') > 0;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth='xs'
      disableEscapeKeyDown
      disableBackdropClick>
      <DialogTitle>No-fly time</DialogTitle>
      <DialogContent className={classes.content}>
        <TextField
          fullWidth
          label='Description'
          aria-label='description'
          onChange={(evt) => setTmpEvent({ ...tmpEvent, description: evt.target.value })}
          value={tmpEvent.description}
        />

        <TextField
          select
          fullWidth
          aria-label='regions to avoid'
          label='Regions To Avoid'
          InputLabelProps={{ shrink: true }}
          SelectProps={{
            renderValue: (selected) => {
              const items = selected as number[];
              if (items.length === 0) {
                return <em>Entire Site</em>;
              }
              return items
                .map((rid) => avaialbleRegions.find((r) => r.id == rid)?.display_name || '?')
                .join(', ');
            },
            displayEmpty: true,
            multiple: true,
          }}
          value={tmpEvent.region_ids}
          onChange={(evt: React.ChangeEvent<HTMLTextAreaElement & { value: string[] }>) => {
            const newRegions = [...new Set(evt.target.value.map((x) => parseInt(x)))].sort();
            setTmpEvent({
              ...tmpEvent,
              region_ids: newRegions,
            });
          }}>
          {avaialbleRegions.map((r) => (
            <MenuItem key={r.id} value={r.id}>
              <Checkbox color='primary' checked={tmpEvent.region_ids.indexOf(r.id) > -1} />
              <ListItemText primary={r.display_name} />
            </MenuItem>
          ))}
        </TextField>
        <div style={{ display: 'flex' }}>
          <TextField
            label='Duration (h)'
            aria-label='hours'
            type='number'
            onChange={(evt) => setDur(parseInt(evt.target.value), durMin)}
            value={durHour}
            error={tmpEvent?.duration_minutes.valueOf() == 0}
          />
          <div style={{ width: 8 }} />
          <TextField
            label='Duration (m)'
            aria-label='minutes'
            type='number'
            onChange={(evt) => setDur(durHour, parseInt(evt.target.value))}
            value={durMin}
            error={tmpEvent?.duration_minutes.valueOf() == 0}
            inputProps={{ step: 15 }}
          />
        </div>
        <KeyboardTimePicker
          fullWidth
          aria-label='time'
          label={`Time (${tz})`}
          keyboardIcon={<Icons.Time />}
          InputLabelProps={{ shrink: true }}
          value={tmpEvent.start_datetime.setZone(tz)}
          onChange={(newdt) => {
            if (newdt) setStartDt(newdt);
          }}
        />
        <Divider />
        <KeyboardDatePicker
          fullWidth
          aria-label='date'
          label={tmpEvent.rrule == null ? 'Date' : 'Start Date'}
          value={tmpEvent.start_datetime.setZone(tz)}
          onChange={(newdt) => {
            if (newdt) setStartDt(newdt);
          }}
        />

        <RRuleEditor
          startDate={tmpEvent.start_datetime.toISODate()}
          rrule={tmpEvent.rrule}
          setRRule={(rrule) => setTmpEvent({ ...tmpEvent, rrule })}
        />
      </DialogContent>
      <DialogActions>
        {tmpEvent?.id && (
          <IconButton style={{ color: 'red' }} onClick={async () => await deleteEvent(tmpEvent)}>
            <Icons.Delete></Icons.Delete>
          </IconButton>
        )}

        <Button color='primary' onClick={onClose}>
          Cancel
        </Button>
        <Button aria-label='save' color='primary' onClick={save} disabled={!allValid}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
