import React from 'react';

export default function ImageAttachments({ images, left, onClick, ...props }) {
  return (
    <div
      onClick={onClick}
      style={{
        display: 'flex',
        justifyContent: left ? 'flex-begin' : 'flex-end',
        flexWrap: 'wrap',
        cursor: onClick ? 'pointer' : 'default',
      }}
      {...props}>
      {images.map((a, i) => (
        <img
          key={i}
          style={{
            width: 64,
            height: 64,
            margin: 4,
            objectFit: 'cover',
            border: '1px solid #333',
          }}
          src={a}
        />
      ))}
    </div>
  );
}
