import React from 'react';
import { resolutionToStr } from '~/lib/units';

import { Chip, Tooltip } from '@material-ui/core';
import { lime } from '@material-ui/core/colors';

export default function ResChip({ mpp }) {
  return mpp <= 0.01 ? (
    <Tooltip title={`Ultra High Res: ${resolutionToStr(mpp)}`}>
      <Chip size='small' style={{ background: lime.A400, color: 'black' }} label='UHR' />
    </Tooltip>
  ) : mpp <= 0.02 ? (
    <Tooltip title={`High Res: ${resolutionToStr(mpp)}`}>
      <Chip size='small' style={{ background: lime.A700, color: 'black' }} label='HR' />
    </Tooltip>
  ) : null;
}
