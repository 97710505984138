import React from 'react';
import { useSVSelector } from '~/redux/hooks';
import { getScoutviewBuild, getScoutviewCommit } from '~/svVersion';

import { Typography } from '@material-ui/core';

export default function VersionBlock({ className }: { className: string }) {
  const fetchingglobal = useSVSelector((state) => state.global.fetchingglobal);
  const version = useSVSelector((state) => state.global.version);

  return (
    <div className={className}>
      <Typography variant='caption' color='inherit'>
        Client v{getScoutviewBuild()}:{getScoutviewCommit()}
      </Typography>
      {fetchingglobal ? (
        <Typography variant='caption' color='inherit'>
          Connecting to Server...
        </Typography>
      ) : version == null ? (
        <Typography variant='caption' color='inherit'>
          Server Version unavailable
        </Typography>
      ) : (
        <Typography variant='caption' color='inherit'>
          Server v{version.version}.{version.build}:{version.commit}
        </Typography>
      )}
    </div>
  );
}
