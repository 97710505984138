import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { ARLogo } from '~/icons';
import history from '~/lib/history';
import { actions as supportActions } from '~/redux/slices/support';
import AppbarSearch from '~/shared/AppbarSearch';
import TM from '~/shared/TM';
import ComponentForTopic from '~/support/ComponentForTopic';
import SearchResults from '~/support/SearchResults';

import {
  AppBar,
  Link,
  List,
  ListItem,
  Paper,
  Toolbar,
  Typography,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  fullscreen: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    top: 0,
    bottom: 0,
    width: '100%',
    background: theme.palette.background.default,
    overflowY: 'auto',
  },
  container: {
    flexGrow: 1,
    width: '100%',
    maxWidth: 900,
    // background: theme.palette.background.paper,
    padding: theme.spacing(2),
    margin: 'auto',
  },
  title: {
    flexGrow: 1,
    whiteSpace: 'nowrap',
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function SupportPage() {
  const classes = styles();
  const theme = useTheme();

  const dispatch = useDispatch();
  React.useEffect(() => {
    // request available topics once on load
    dispatch(supportActions.requestAvailableTopics());
  }, [dispatch]);

  const allTopics = useSelector((state) => state.support.availableTopics);

  const { topic } = useParams();
  let query = useQuery();

  const searchText = query.get('q');

  document.title = `${topic || 'Table of Contents'} - ScoutView Help Center`;

  const onNavLink = (e, newTopic) => {
    history.push(`/support/${newTopic}`);
    e.preventDefault();
  };

  const onNavSearch = (topic) => history.push(`/support/${encodeURI(topic)}`);

  const contents =
    searchText === null ? (
      <ComponentForTopic topic={topic || 'index'} onNav={onNavLink} />
    ) : (
      <SearchResults searchText={searchText} onNav={onNavSearch} />
    );

  return (
    <div className={classes.fullscreen}>
      <AppBar className='helpAppbar'>
        <Toolbar>
          <ARLogo style={{ marginRight: theme.spacing(2) }} />

          <Typography variant='h6' className={classes.title}>
            <Link onClick={() => history.push(`/support`)} color='inherit'>
              ScoutView
              <TM /> Help Center
            </Link>
          </Typography>
          <AppbarSearch
            options={allTopics.map((x) => decodeURI(x))}
            onNavigate={onNavSearch}
            onSearch={(q) => history.push(`/support?q=${q}`)}
          />
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Paper className={classes.container}>{contents}</Paper>
    </div>
  );
}
