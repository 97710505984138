import { createSelector } from 'reselect';
import * as globalSelctors from '~/redux/selectors/global';
import { RootState } from '~/store';

type FlightPageParams = {
  flightId?: number;
};

export const matches = (state: RootState): FlightPageParams | null =>
  globalSelctors.paramsForPath(state, '/flight/:flightId');

export const getFlightData = createSelector(
  matches,
  (state) => state.flightScene.flights,
  (params, flights) => flights[params!.flightId!]?.data
);

export const getFlightPending = createSelector(
  matches,
  (state) => state.flightScene.flights,
  (params, flights) => flights[params!.flightId!]?.pending
);

export const getFarm = createSelector(
  getFlightData,
  (state) => state.global.farms,
  (data, farms) => (data?.survey ? farms[data.survey.farm_id] : null)
);
