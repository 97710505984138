import React from 'react';

import { Typography, MenuItem, TextField, Button } from '@material-ui/core';
import { mToFt } from '~/lib/geography';
import * as wkt from 'wellknown';
import produce from 'immer';

import { Feature, Polygon, MultiPolygon } from 'geojson';

import { feature, multiPoint } from '@turf/helpers';
import pointGrid from '@turf/point-grid';
import bbox from '@turf/bbox';
import union from '@turf/union';

import type Props from './trqControlProps';
import { InstructionsSampling } from '~/schema/TargetRequest';
import { getTotalBoundaryWkt } from '../selectors';
import { useSVSelector } from '~/redux/hooks';

// returns wkt multipoint from number spacing (m) and wkt polygon boundary
function getSamplingPointGrid(spacing: number, altitude: number, boundary: string) {
  const bnds = feature(wkt.parse(boundary));
  if (bnds.geometry?.type == 'Polygon' || bnds.geometry?.type == 'MultiPolygon') {
    const extent = bbox(bnds);
    const grid = pointGrid(extent, spacing / 1000, {
      mask: bnds as Feature<Polygon | MultiPolygon>,
    });
    const mp = multiPoint(grid.features.map((f) => [...f.geometry.coordinates, altitude]));
    return wkt.stringify(mp.geometry as wkt.GeoJSONMultiPoint);
  } else {
    throw `Unrecognized boundary type: ${bnds.type}`;
  }
}

export default function SamplingControls({ trq, targetAreas, ceiling, editField }: Props) {
  const [autoSampleSpacing, setAutoSampleSpacing] = React.useState(100);
  const [autoSampleAlt, setAutoSampleAlt] = React.useState(50);

  // TODO: You are passing the target areas into props, which are region or asset ids.
  // You must generate a boundary here in order to make the sampling grid.
  // To generate that boundary, pull in the asset and region objects from redux, don't
  // try to pass them as props.

  const boundary_wkt = useSVSelector((state) => getTotalBoundaryWkt(state, targetAreas));
  if (!boundary_wkt) throw 'No boundary available in SamplingControls';

  const instructions = trq.instructions_sampling;
  if (!instructions) throw 'Invalid sampling instructions passed to SamplingControls';

  const editSamplingFields = React.useCallback(
    (x: Partial<InstructionsSampling>) => {
      editField({
        ...x,
        instructions_sampling: produce(instructions, (draft) => ({
          ...draft,
          ...x,
        })),
      });
    },
    [instructions, editField]
  );

  let altitudesToShow = [50, 25, 10];
  for (let x = 100; x < Math.floor(ceiling * 3.28084); x += 50) {
    altitudesToShow = [x, ...altitudesToShow];
  }

  const generateSamplingGrid = () => {
    editSamplingFields({
      sampling_locations: getSamplingPointGrid(
        autoSampleSpacing / 3.28084,
        autoSampleAlt / 3.28084,
        boundary_wkt
      ),
    });
  };
  const clearSamplingPoints = () => {
    editSamplingFields({
      sampling_locations: wkt.stringify(multiPoint([]).geometry as wkt.GeoJSONMultiPoint),
    });
  };

  return (
    <>
      <TextField
        fullWidth
        select
        label='Transit Altitude'
        value={(trq.sampling_transit_alt || ceiling).toFixed(2)}
        onChange={(e) => {
          editSamplingFields({
            sampling_transit_alt: Math.min(parseFloat(e.target.value), ceiling),
          });
        }}>
        {ceiling && <MenuItem value={ceiling.toFixed(2)}>Max, {mToFt(ceiling)}</MenuItem>}
        {altitudesToShow.map((altft) => {
          const altm = altft / 3.28084;
          return (
            <MenuItem key={altm} value={altm.toFixed(2)}>
              {altft}&apos;
            </MenuItem>
          );
        })}
      </TextField>

      <Typography variant='caption'>
        To automatically place sampling points, specify parameters below and click generate
      </Typography>

      <TextField
        fullWidth
        type='number'
        inputProps={{ min: 10, max: 1000, step: 10 }}
        onChange={(evt) => {
          setAutoSampleSpacing(parseInt(evt.target.value));
        }}
        label='Spacing (ft)'
        onClick={(evt) => evt.stopPropagation()}
        value={autoSampleSpacing}
      />
      <TextField
        fullWidth
        type='number'
        inputProps={{ min: 10, max: 400, step: 5 }}
        onChange={(evt) => {
          setAutoSampleAlt(parseInt(evt.target.value));
        }}
        label='Altitude (ft)'
        onClick={(evt) => evt.stopPropagation()}
        value={autoSampleAlt}
      />
      <Button variant='contained' color='primary' fullWidth onClick={generateSamplingGrid}>
        Generate
      </Button>
      <Button variant='text' color='primary' fullWidth onClick={clearSamplingPoints}>
        Clear All
      </Button>
    </>
  );
}
