import * as geography from '~/lib/geography';
import * as wkt from 'wellknown';

import { Link, Typography } from '@material-ui/core';
import {
  getFilteredRegions,
  focussedFarm,
  getTmpRegion,
  getTmpRegionWithUsershape,
  regionsForFarm,
  selectedRegionId,
} from './selectors';

import EditRegionPanel from './EditRegionPanel';
import OrgSiteLink from '~/shared/Appbar/OrgSiteLink';
import React from 'react';
import RegionsPanel from './RegionsPanel';
import { Link as RouterLink } from 'react-router-dom';
import { actions } from './slice';
import { createSelector } from 'reselect';
import { getUsershapeEditing } from '~/redux/slices/usershape';
import { gridForRegion } from '~/lib/analysisGrid';
import history from '~/lib/history';
import { paramsForPath } from '~/redux/selectors/global';
import { regionColors } from '~/theme';
import store from '~/store';

export { default as slice } from './slice';

// Does this route apply?
export const matches = (state) => paramsForPath(state, '/regions');

export const title = () => 'Manage Regions';
export const breadcrumbs = () => ['Manage Regions', <OrgSiteLink key='orgsitelink' />];

// Mapbox style data
export const mapStyle = createSelector(
  focussedFarm,
  regionsForFarm,
  getFilteredRegions,
  getTmpRegionWithUsershape,
  getUsershapeEditing,
  (farm, regions, filtered, tmpRegion, usershapeEditing) => {
    let crossingPoints = [];
    let usershapeDilation = [];
    let extras = { layers: [], sources: {} };
    if (tmpRegion?.boundary) {
      const shape = wkt.parse(tmpRegion.boundary);

      if (shape) {
        if (shape.type == 'LineString') {
          crossingPoints = tmpRegion.crossing_index
            ?.filter((i) => i < shape.coordinates.length)
            ?.map((i) => shape.coordinates[i]);
        }

        if (tmpRegion.dilation) {
          usershapeDilation = tmpRegion.dilation;
        }
      }

      if (['FIELD', 'ZONE'].includes(tmpRegion.type) && !usershapeEditing) {
        const grid_geometries = gridForRegion(tmpRegion);
        extras.sources['grid-src'] = {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: grid_geometries.map((s, i) => ({
              type: 'Feature',
              geometry: s,
            })),
          },
        };

        extras.layers.push({
          id: `grid-preview`,
          type: 'line',
          source: `grid-src`,
          paint: {
            'line-color': regionColors[tmpRegion.type],
          },
          // filter: ['all', ['==', ['get', 'valid'], 1]],
        });
      }
    }

    let regionsToDisplay = regions
      .filter((r) => r.id != tmpRegion?.id || r.immutable)
      .map((r) => {
        const showDetails = filtered.includes(r) && (!tmpRegion || r.id == tmpRegion.id);
        return {
          id: r.id,
          type: r.type,
          ceiling: r.ceiling,
          boundary: r.boundary,
          display_name: r.display_name,
          color: regionColors[r.type],
          outline: filtered.includes(r),
          fill: showDetails,
          name: showDetails,
        };
      });

    if (farm && farm.keepin) {
      const keepinRegion = {
        id: `${farm.id}-keepin`,
        boundary: farm.keepin,
        color: regionColors['FARM'],
        outline: true,
        fill: false,
        name: false,
      };

      regionsToDisplay.push(keepinRegion);
    }

    return {
      extras,
      imagery: [],
      crossingPoints,
      usershapeDilation,
      regions: regionsToDisplay,
    };
  }
);

// Panel
export const panel = createSelector(getTmpRegion, (tmpRegion) =>
  tmpRegion !== null ? <EditRegionPanel /> : <RegionsPanel />
);

export const defaultBounds = createSelector(regionsForFarm, getTmpRegion, (regions, tmpRegion) => {
  if (tmpRegion?.boundary) {
    return geography.bboxOfWKT(tmpRegion.boundary);
  } else {
    if (regions.length == 0) return null;
    return geography.bboxOfPoints(
      regions.map((r) => geography.bboxOfWKT(r.boundary)).reduce((arr, x) => arr.concat(x), [])
    );
  }
});

export const clickableFeatures = createSelector(
  getFilteredRegions,
  selectedRegionId,
  (regions, editingId) => {
    if (editingId) return [];

    return regions.map((r) => ({
      feature: r.boundary,
      cursor: 'pointer',
      onClick: () =>
        history.push({
          pathname: '/regions',
          search: `?regionid=${r.id}`,
        }),
    }));
  }
);

export const editingUsershape = createSelector(getTmpRegion, (r) => {
  if (r == null || r.immutable) return null;

  let allowed = ['polygon', 'rectangle', 'circle'];
  if (r.type == 'KEEPOUT') allowed.push('line');

  return {
    color: regionColors[r.type],
    ceiling: r.ceiling,
    allowed,
    allowPolyHoles: true,
    allowMultiPolygons: true,
  };
});
export const helpTopic = () => 'Edit Regions';
