import React from 'react';
import * as api from '~/lib/api';
import history from '~/lib/history';
import Logging from '~/logging';
import { Invite } from '~/schema/Org';

import { Button, CircularProgress, Dialog, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(2),
  },
  accepting: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    padding: theme.spacing(4),
  },
}));

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

interface SVAcceptInvite {
  existing: boolean;
  username: string;
  password: string;
}

interface Props {
  invite: Invite;
  request: SVAcceptInvite;
  disabled: boolean;
  link: boolean;
}

export default function AcceptButton({ invite, request, disabled, link }: Props) {
  const classes = styles();

  const [accepting, setAccepting] = React.useState(false);
  const [accepted, setAccepted] = React.useState(false);
  const [acceptFail, setAcceptFail] = React.useState(false);

  const acceptInvite = async () => {
    setAccepted(false);
    setAcceptFail(false);
    setAccepting(true);
    await sleep(1000); // seriously this helps
    try {
      await api.webrequest('post', `sv/invite/${invite.id}/accept`, request);
      setAccepted(true);
    } catch (err) {
      Logging.error('Error accepting invite', err);
      setAcceptFail(true);
    }
  };
  return (
    <>
      <Button
        fullWidth
        variant='contained'
        color='primary'
        disabled={disabled}
        onClick={acceptInvite}>
        {link ? 'Link Account' : 'Create Account'}
      </Button>
      <Dialog open={accepting} disableEscapeKeyDown disableBackdropClick fullWidth maxWidth='sm'>
        <div className={classes.accepting}>
          {!accepted && !acceptFail && <CircularProgress variant='indeterminate' />}
          {accepted && link && (
            <Grid container spacing={4}>
              <Grid item xs={12} alignItems='center'>
                <Typography variant='h5'>Your account has been linked!</Typography>
              </Grid>
              <Grid item xs={12} alignItems='center'>
                <Typography variant='subtitle1'>
                  Click below to be redirected to ScoutView.
                </Typography>
              </Grid>
              <Grid item xs={12} alignItems='center'>
                <Button color='primary' onClick={() => history.push('/')}>
                  Continue to ScoutView
                </Button>
              </Grid>
            </Grid>
          )}

          {accepted && !link && (
            <Grid container spacing={4}>
              <Grid item xs={12} alignItems='center'>
                <Typography variant='h5'>Your account has been created!</Typography>
              </Grid>
              {request.username != invite.email ? (
                <>
                  <Grid item xs={12} alignItems='center'>
                    <Typography variant='caption'>
                      The email associated with this new account is different from where the invite
                      was sent. Check the email you specified during account creation for a
                      confirmation link to finish setting up your account.
                    </Typography>
                  </Grid>

                  <Grid item xs={12} alignItems='center'>
                    <Button color='primary' onClick={() => window.close()}>
                      Close This Window
                    </Button>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} alignItems='center'>
                    <Typography variant='subtitle1'>Click below to login to ScoutView.</Typography>
                  </Grid>

                  <Grid item xs={12} alignItems='center'>
                    <Button color='primary' onClick={() => history.push('/')}>
                      Continue to ScoutView
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          )}
          {acceptFail && link && (
            <Grid container spacing={4}>
              <Grid item xs={12} alignItems='center'>
                <Typography variant='h5' color='error'>
                  There was an error linking your account.
                </Typography>
              </Grid>
              <Grid item xs={12} alignItems='center'>
                <Typography variant='subtitle1'>
                  Go back and verify that your username and password are correct. If the error
                  persists, contact American Robotics Support at{' '}
                  <a href='mailto:support@american-robotics.com'>support@american-robotics.com</a>.
                </Typography>
              </Grid>
              <Grid item xs={12} alignItems='center'>
                <Button variant='outlined' color='primary' onClick={() => setAccepting(false)}>
                  Go Back
                </Button>
              </Grid>
            </Grid>
          )}
          {acceptFail && !link && (
            <Grid container spacing={4}>
              <Grid item xs={12} alignItems='center'>
                <Typography variant='h5' color='error'>
                  There was an error creating your account.
                </Typography>
              </Grid>
              <Grid item xs={12} alignItems='center'>
                <Typography variant='subtitle1'>
                  Go back and verify that you have filled in the required fields and try again. If
                  the error persists, contact American Robotics Support at{' '}
                  <a href='mailto:support@american-robotics.com'>support@american-robotics.com</a>.
                </Typography>
              </Grid>
              <Grid item xs={12} alignItems='center'>
                <Button variant='outlined' color='primary' onClick={() => setAccepting(false)}>
                  Go Back
                </Button>
              </Grid>
            </Grid>
          )}
        </div>
      </Dialog>
    </>
  );
}
