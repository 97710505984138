import createCachedSelector from 're-reselect';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import * as wkt from 'wellknown';
import { clickablesForAnalysis, overlaysForAnalysis } from '~/analysis';
import * as Icons from '~/icons';
import { gridForRegion } from '~/lib/analysisGrid';
import * as api from '~/lib/api';
import { getColorForNDVI } from '~/lib/colorScales';
import * as geography from '~/lib/geography';
import { screenshotMap } from '~/lib/globalMapAccess';
import history from '~/lib/history';
import { showSnackbar } from '~/lib/modalServices';
import { displayNameForFarm } from '~/lib/names';
import Logging from '~/logging';
import MapAnalysisControls from '~/map/MapAnalysisControls';
import MapColorLegend from '~/map/MapColorLegend';
import MapTimestamp from '~/map/MapTimestamp';
import { getChannel } from '~/redux/commonSelectors';
import * as globalSelectors from '~/redux/selectors/global';
import { actions as globalActions } from '~/redux/slices/global';
import { actions as noteActions } from '~/redux/slices/notes';
import { getUsershapeEditing } from '~/redux/slices/usershape';
import { getUsershapeWKT } from '~/redux/slices/usershape';
import FieldSelector from '~/shared/Appbar/FieldSelector';
import OrgSiteLink from '~/shared/Appbar/OrgSiteLink';
import ZoomOutButton from '~/shared/Appbar/ZoomOutButton';
import ButtonWithTip from '~/shared/ButtonWithTip';
import ImageryAnnotationControls from '~/shared/ImageryAnnotationControls';
import ImageryControls from '~/shared/ImageryControls';
import store from '~/store';
import { regionColors } from '~/theme';

import { Button, ButtonGroup, Fade } from '@material-ui/core';

import DownloadDataButton from './DownloadDataButton';
import FieldOverviewPanel from './FieldOverviewPanel';
import {
  fieldsForFarm,
  getCurrentBackgroundImageset,
  getCurrentImageset,
  getFieldData,
  getFilterRules,
  getFilteredImagesets,
  getPermissions,
  getRegion,
  getTmpZone,
  getVisibleZones,
  getZoneSummaries,
  isPending,
  matches,
} from './selectors';
import slice, { actions } from './slice';

export { slice, matches };

export const orgSite = createSelector(globalSelectors.allFarms, getRegion, (allFarms, field) => {
  if (!field) return { orgId: null, siteId: null };

  const farm = allFarms[field.farm_id];
  return { orgId: farm?.grower_id || null, siteId: farm?.id || null };
});

export const title = createSelector(globalSelectors.allFarms, getRegion, (allFarms, field) => {
  if (!field) return null;

  const farm = allFarms[field.farm_id];

  if (!farm) return field.farm_id;

  const grower = farm.grower_id || '?';

  return `${grower}/${farm.location}/${field.display_name}`;
});

export const breadcrumbs = createSelector(getRegion, fieldsForFarm, (field, fields) => {
  const bc = [<OrgSiteLink key='orgsitelink' />];
  if (field)
    bc.push(
      <FieldSelector
        key='field'
        field={field}
        fields={fields}
        onSelect={(fieldid) => history.push(`/field/${fieldid}`)}
      />
    );
  return bc;
});

export const panel = createSelector(getFieldData, isPending, (fieldData, pending) => {
  return <FieldOverviewPanel field={fieldData?.region} pending={pending || !fieldData} />;
});

export const appbarActions = createSelector(
  globalSelectors.allFarms,
  getRegion,
  (allFarms, field) => {
    if (!field) return null;

    const farm = allFarms[field.farm_id];

    return [<DownloadDataButton key='download' />, <ZoomOutButton key='zoomout' farm={farm} />];
  }
);

// The field view is the only one that supports mapsplit. It's controlled by this field in 'ui'.
export const isMapSplit = (state) => state.ui.isMapSplit;

export const controls = createSelector(
  matches,
  getRegion,
  (state) => isMapSplit(state),
  (state) => state.fieldScene.mapState,
  (state) => getCurrentImageset(state, 0),
  (state) => state.global.activeReport,
  getPermissions,
  (params, region, isMapSplit, mapState, currentImageset, activeReportId, permissions) => {
    const canSeeFlightLogs = permissions.includes('SEE_FLIGHT_LOGS');
    const canSeeAnalytics = permissions.includes('GET_ANALYTICS');
    const canCreateNotes = permissions.includes('CREATE_IMAGERY_NOTE');
    const canCreateReport = permissions.includes('CREATE_SCOUT_REPORT');

    // Ok so my problem here is that the analysis controls expect a copy of the analytics.
    // For some reason. When we're viewing zones, we might see multiple copies of analytics up at once.
    // How should I deal with that? I don't want to be cheap and just pick one arbitrarially.
    // For now I'm setting it to null because I'm not sure we ever even use this value.
    const analytics = null;
    // const analytics =
    //   mapState == 'zoneMode'
    //     ? currentImageset?.available_analytics?.find(x => x.metric == analysisMode[channel])
    //     : z_d.available_analytics?.find(x => x.metric == analysisMode[channel])

    return {
      NE: [
        <div style={{ display: 'flex', alignItems: 'flex-start' }} key='ne'>
          <ImageryControls
            mapidx={0}
            key='imctl'
            showSplitControls={!isMapSplit}
            showAnalysisControls={canSeeAnalytics}
          />
          {isMapSplit && (
            <ImageryControls
              mapidx={1}
              style={{ marginLeft: 4 }}
              showSplitControls
              showAnalysisControls={canSeeAnalytics}
            />
          )}
        </div>,
      ],
      NW: ['drawTarget', 'showTarget', 'drawZone'].includes(mapState)
        ? [] // Don't show any of this panel when working on targeted scouts, zones
        : [
            <div style={{ display: 'flex', alignItems: 'flex-start' }} key='nw'>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <ImageryAnnotationControls
                  key='notectl'
                  isAddingNote={mapState == 'addNote'}
                  addNote={
                    canCreateNotes
                      ? () =>
                          store.dispatch(
                            actions.setMapState(mapState == 'addNote' ? null : 'addNote')
                          )
                      : null
                  }
                  activeReportId={activeReportId}
                  addToReport={
                    canCreateReport
                      ? async () => {
                          let reportId = activeReportId;

                          try {
                            if (!reportId) {
                              // We have to create a report first.

                              const newReport = await api.webrequest('POST', 'scoutReport', {
                                // TODO: What do I even need here. A default title/summary?
                                finalized: false,
                                title: 'New Report',
                                summary: '',
                              });

                              reportId = newReport.id;
                            }

                            // TODO: This should eventually save the style object so we can have an interactive map. for now I'm just saving the image

                            // Create a new module with a title and attachment. (Should we add to an existing module of this field?)
                            const mapImage = await screenshotMap();

                            // var w = window.open('')
                            // let image = new Image()
                            // image.src = mapImage
                            // w.document.write(image.outerHTML)

                            // TODO: I wish there was a cleaner way to get this
                            const trid = currentImageset?.target_results_id;

                            await api.webrequest('POST', `scoutReport/${reportId}/module`, {
                              report_id: reportId,
                              region_id: region.id,
                              tr_id: trid,
                              title: region.display_name, // TODO: better title?
                              commentary: '',
                              attachments: [mapImage],
                            });

                            store.dispatch(
                              globalActions.setActiveReport({ activeReport: reportId })
                            );

                            showSnackbar('success', 'Screen added to report.');
                          } catch (e) {
                            Logging.warn(`Failed to add to report`, { error: e });

                            showSnackbar('warning', 'Could not add to report.');
                          }
                        }
                      : null
                  }
                />
                {currentImageset && canSeeFlightLogs && (
                  <ButtonGroup
                    color='inherit'
                    orientation='vertical'
                    size='small'
                    variant='contained'
                    style={{ marginTop: 4 }}>
                    <ButtonWithTip
                      tooltip='Flight Log'
                      onClick={() => history.push(`/flight/${currentImageset.flight_id}`)}>
                      <Icons.Drone />
                    </ButtonWithTip>
                  </ButtonGroup>
                )}
              </div>
            </div>,
          ],

      SE: (
        <Fade in={isMapSplit} mountOnEnter unmountOnExit>
          {/* If the map is split, this is where the B side controls live */}
          <>
            <MapTimestamp mapidx={1} />
            <MapAnalysisControls mapidx={1} />
            <MapColorLegend mapidx={1} />
          </>
        </Fade>
      ),
      footer: (
        <>
          <MapTimestamp mapidx={0} />
          <MapAnalysisControls mapidx={0} />
          <MapColorLegend mapidx={0} />
        </>
      ),
    };
  }
);

const getVisibleNotes = createSelector(
  (state) => matches(state).regionId,
  (state) => getCurrentImageset(state, 0),
  (state) => state.notes.notesById,
  (regionId, imageset, notesById) => {
    const targetId = imageset?.target_results_id;
    const allNotes = Object.values(notesById).filter((n) => n.region_id == regionId);

    return {
      current: allNotes.filter((n) => n.tr_id == targetId),
      past: allNotes.filter((n) => n.tr_id < targetId), // this is probably okay to assume it's chronological
      future: allNotes.filter((n) => n.tr_id > targetId),
    };
  }
);

export const mapStyle = createCachedSelector(
  (state) => state.global.preferences.analysisMode,
  getFieldData,
  getFilteredImagesets,
  getVisibleNotes,
  (_, mapidx) => mapidx,
  getCurrentImageset,
  getCurrentBackgroundImageset,
  (state) => state.fieldScene.mapState,
  getColorForNDVI,
  getUsershapeEditing,
  getTmpZone,
  getVisibleZones,
  getZoneSummaries,
  (state) => state.global.preferences.showZoneGrid,
  (state) => state.global.preferences,
  (
    analysisMode,
    fieldData,
    imagesets,
    visibleNotes,
    mapidx,
    currentImageset,
    currentBackgroundImageset,
    mapState,
    colorForNDVI,
    usershapeEditing,
    tmpZone,
    visibleZones,
    zoneSummaries,
    showZoneGrid,
    preferences
  ) => {
    if (!fieldData || fieldData.pending) {
      return { imagery: [], regions: [] };
    }

    // Find the index in imagesets that matches our timeline time
    const currentImageryIdx = imagesets.findIndex((iset) => iset == currentImageset);
    const currentBackgroundIdx = imagesets.findIndex((iset) => iset == currentBackgroundImageset);

    var imagery =
      imagesets
        .map((iset, k) => {
          const visible = k == currentImageryIdx || k == currentBackgroundIdx;
          return iset.imagery.map((img) => ({
            ...img,
            visible,
            unloaded:
              !visible &&
              !(Math.abs(k - currentImageryIdx) <= 2 || k == 0 || k == imagesets.length - 1), // First and last ones are always loaded since we can jump to them
          }));
        })
        .reverse()
        .flat() || [];

    var regions = [
      {
        id: fieldData.region.id,
        type: fieldData.region.type,
        ceiling: fieldData.region.ceiling,
        boundary: fieldData.region.boundary,
        display_name: fieldData.region.display_name,
        color: regionColors[fieldData.region.type],
        outline: true,
        fill: false,
        name: false,
      },
    ];

    // If we are in targeted scouting mode, need to show keepouts
    if (['drawTarget', 'showTarget'].includes(mapState)) {
      regions.push(
        ...fieldData.keepouts.map((r) => ({
          id: r.id,
          type: r.type,
          ceiling: r.ceiling,
          boundary: r.boundary,
          display_name: r.display_name,
          color: regionColors.KEEPOUT,
          outline: true,
          fill: true,
          name: true,
        }))
      );
    }

    let extras = { layers: [], sources: {} };

    if (mapState == 'drawZone') {
      if (!usershapeEditing && tmpZone.boundary) {
        const grid_geometries = gridForRegion(tmpZone);
        extras.sources['grid-src'] = {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: grid_geometries.map((s) => ({
              type: 'Feature',
              geometry: s,
            })),
          },
        };

        extras.layers.push({
          id: `grid-preview`,
          type: 'line',
          source: `grid-src`,
          paint: {
            'line-color': regionColors.ZONE,
          },
        });
      }
    }

    if (currentImageset?.isTargeted) {
      const r = currentImageset.region;
      if (!r) return;

      regions.push({
        id: r.id,
        type: r.type,
        ceiling: r.ceiling,
        boundary: r.boundary,
        display_name: 'TGT',
        color: regionColors[r.type],
        outline: true,
        fill: false,
        name: false,
      });
    }

    // TODO: What to do about notes that aren't on the current imageset?
    const currentNoteIds = visibleNotes.current.map((n) => n.id);
    const otherNoteIds = [...visibleNotes.future, ...visibleNotes.past].map((n) => n.id);

    let overlays = [];

    if (mapState != 'zoneMode') {
      // don't display analysis overlays in zone mode. Just the zones.

      // TODO: This probably requires requesting the complete analytics for this mode. It'll have loading, etc states
      const analytics = currentImageset?.available_analytics?.find(
        (x) => x.metric == analysisMode[mapidx]
      );

      // What do we do for regions that aren't the field? do we display them on this view at all?
      overlays = overlaysForAnalysis(analytics, fieldData.region, preferences, colorForNDVI);
    }

    if (mapState == 'drawZone' || mapState == 'zoneMode') {
      visibleZones.map((zid) => {
        const zone = fieldData.zones.find((z) => z.id == zid);
        if (!zone) return;

        // We also display an analytics number summary on the zone.
        const z_sum = zoneSummaries[zid];

        // TODO: How to get this text from the analytics properly?
        const text = z_sum?.summary
          ? `${zone.display_name}\n${Math.round(z_sum.summary * 100)}`
          : zone.display_name;

        regions.push({
          id: zone.id,
          type: zone.type,
          boundary: zone.boundary,
          display_name: text,
          color: regionColors.ZONE,
          outline: true,
          fill: false,
          name: true,
        });
      });
    }
    if (mapState == 'zoneMode') {
      // in zone mode we display the analysis in the zone regions based on their summaries.
      currentImageset?.zone_data?.forEach((z_d) => {
        if (!visibleZones.includes(z_d.region.id)) return;

        const analytics = z_d.available_analytics?.find((x) => x.metric == analysisMode[mapidx]);

        if (showZoneGrid) {
          overlays.push(...overlaysForAnalysis(analytics, z_d.region, preferences, colorForNDVI));
        } else {
          // TODO: push an overlay that's just the whole reigon colored in by the summary?
        }
      });
    }

    return {
      // TODO: how do we add the detailed analytics overlays?
      imagery,
      // On the summary page, we display all analysis regions for our farm
      regions,

      currentNoteIds,
      otherNoteIds,

      overlays,
      extras,
    };
  }
)((_, mapidx) => {
  return mapidx; // Cached by map mapidx
});

export const fetch = createSelector(
  (state) => matches(state).regionId,
  (regionId) => (dispatch) => {
    dispatch(actions.getFieldData(regionId));
  }
);

export const defaultBounds = createSelector(getRegion, (r) =>
  r ? geography.bboxOfWKT(r.boundary) : null
);

// TODO: Reenable this if you want to limit bounds in field view
// export const maxBounds = createSelector(
//   getRegion,
//   r => {
//     if (!r) return null
//     const bbox = geography.bboxOfWKT(r.boundary)

//     const ratio = Math.cos((bbox[0][0] * Math.PI) / 180)
//     const bufferScale = 1 // how much buffer space on each side

//     // The max bounds are just +1 of the largest dimension.
//     const dlat = bbox[1][0] - bbox[0][0]
//     const dlon = (bbox[1][1] - bbox[0][1]) / ratio

//     const latSgn = Math.sign(dlat)
//     const lonSgn = Math.sign(dlon)

//     const buffer = Math.max(Math.abs(dlat), Math.abs(dlon)) * bufferScale

//     bbox[0][0] -= buffer * latSgn
//     bbox[0][1] -= buffer * lonSgn * ratio
//     bbox[1][0] += buffer * latSgn
//     bbox[1][1] += buffer * lonSgn * ratio
//     return bbox
//   }
// )

export const hasTimeline = createSelector(getFilteredImagesets, (is) => {
  if (!is) return []; // we need data to show the timeline
  if (is.length <= 1) return []; // Don't show the timeline if only one entry
  return is.map((i) => i.timestamp);
});

export const clickableFeatures = createSelector(
  getRegion,
  (state) => state.global.user.id,
  (state) => state.fieldScene.mapState,
  (state) => getCurrentImageset(state, 0),
  (state) => state.global.preferences.analysisMode,
  (r, username, mapState, imageset, analysisMode) => {
    if (!r) return [];

    const analytics = imageset?.available_analytics?.find((x) => x.metric == analysisMode[0]);
    const analysisClickables = clickablesForAnalysis(analytics);

    switch (mapState) {
      case 'addNote':
        return [
          {
            feature: r.boundary,
            cursor: 'crosshair',
            onClick: (point) => {
              if (!imageset) {
                showSnackbar('error', 'No imagery to annotate.');
              } else {
                store.dispatch(actions.setMapState(null));
                store.dispatch(
                  noteActions.createPendingNote({
                    location: point,
                    tr_id: imageset.target_results_id,
                    region_id: r.id,
                    author_user_id: username,
                  })
                );
              }
            },
          },
        ];
      default:
        return [...analysisClickables];
    }
  }
);

export const infoText = createSelector(
  (state) => state.fieldScene.mapState,
  (state) => getCurrentImageset(state, 0),
  (state) => state.global.preferences.analysisMode,

  (mapState, currentImageset, analysisMode) => {
    switch (mapState) {
      case 'addNote':
        return 'Select location for new note';
      case 'drawTarget':
        return 'Draw area in field to scout';
      case 'drawZone':
        return 'Draw zone in field to analyze';
      case 'zoneMode':
        return null;
      default: {
        if (analysisMode[0]) {
          const analytics = currentImageset?.available_analytics?.find(
            (x) => x.metric == analysisMode[0]
          );

          if (!analytics) {
            return `${analysisMode[0]} unavailable for this data set.`;
          }
        }

        return null;
      }
    }
  }
);

// This page always shows the usershape with a key of 'target'
export const shouldUsershapeLock = createSelector(
  (state) => state.fieldScene.mapState,
  (mapState) => mapState == 'showTarget'
);

export const editingUsershape = createSelector(
  (state) => state.fieldScene.mapState,
  (mapState) => {
    switch (mapState) {
      case 'drawTarget':
        return { color: regionColors.TARGETED, allowed: ['polygon', 'rectangle', 'circle'] };
      case 'drawZone':
        return { color: regionColors.ZONE, allowed: ['polygon', 'rectangle', 'circle'] };
      default:
        return null;
    }
  }
);

export const estimateKey = () => 'target';

export const imageryResolution = createSelector(
  (state) => getCurrentImageset(state, 0),
  (imageset) => {
    let rval = [];
    if (imageset) {
      rval.push(imageset.actual_resolution);
    }
    return rval;
  }
);
export const imageryRange = createSelector(getCurrentImageset, getChannel, (imageset, channel) => {
  const imagery = imageset?.imagery?.find((i) => i.type == channel);
  if (imagery) {
    return [imagery.data_range_x, imagery.data_range_y, imagery.data_range_z];
  } else return null;
});

export const visibleAnalytics = createSelector(
  getCurrentImageset,
  (state) => state.fieldScene.mapState,
  (state) => state.global.preferences.analysisMode,
  (_, mapidx) => mapidx,

  (currentImageset, mapState, analysisMode, mapidx) => {
    if (mapState != 'zoneMode') {
      return (
        currentImageset?.available_analytics?.filter((x) => x.metric == analysisMode[mapidx]) || []
      );
    } else {
      return [];
    }
  }
);

export const imagesets = getFilteredImagesets;

export const visibleImagesets = (state, mapchannel) => {
  const ciset = getCurrentImageset(state, mapchannel);
  if (ciset) {
    return [ciset];
  } else {
    return [];
  }
};

export const helpTopic = () => 'Asset View';
