import { createSelector } from 'reselect';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import * as wkt from 'wellknown';
import route from '~/lib/activeScene';
import channelConfig from '~/lib/channelConfig';
import isEqual from '~/lib/deep_equal';
import LayersForRegion from '~/lib/layersForRegion';
import LayersForAsset from '~/lib/layersForAsset';
import sourcesForLayer from '~/lib/sourcesForLayer';
import layersForUsershape from '~/shared/usershape/layersForUsershape';
import Logging from '~/logging';
import { getChannel } from '~/redux/commonSelectors';
import { getDataColors } from '~/redux/commonSelectors';
import {
  getUsershapeAdding,
  getUsershapeEditing,
  getUsershapeFeatures,
  getUsershapeMidpointFeature,
  getUsershapeValid,
  getUsershapeVertexFeature,
} from '~/redux/slices/usershape';
import { regionColors, symbolicColors } from '~/theme';

import { lightBlue } from '@material-ui/core/colors';
import * as turf from '@turf/turf';

import satStylesheet from './baseStyles/sat.json';
import sat2Stylesheet from './baseStyles/sat2.json';
import topoStylesheet from './baseStyles/topo.json';
import { layerForImagery, sourceForImagery } from './imageryStyle';

export const getBaseStyle = (state) => {
  switch (state.global.preferences.backdrop) {
    case 'SAT':
      return satStylesheet;
    case 'SAT2':
      return sat2Stylesheet;
    case 'TOPO':
      return topoStylesheet;
    default:
      return {};
  }
};

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const routeMapDescription = createDeepEqualSelector(
  (state, channel) => route.mapStyle(state, channel),
  (mapStyle) => {
    let mapDesc = {
      imagery: [], // contains layers
      assets: [],
      regions: [], // contains { id, fill, outline, outlineShadow, text }
      overlays: [], // contains { ... TODO }
      noteIds: [], // contains { noteId: int, current: bool } (if it's not current, it's faded in the map)
      editor: false, // true if we should display the usershape
      flightPlan: null, // a layer representing the flight plan
      flightPath: null, // a layer representing a flown flight path
      extras: { layers: [], sources: {} }, // any extra layers to add
      ...(mapStyle || {}), // the page provides whichever of these it wants
    };
    return mapDesc;
  }
);

const getEstimatePath = createSelector(
  (state) => route.estimateKey(state),
  (state) => state.estimates.estimates,
  (key, estimates) => {
    if (key === null || typeof key === 'undefined' || !estimates) {
      return null;
    }

    const estimate = estimates[key];
    if (!estimate) return null;
    if (estimate.pending || !estimate.result) return null;

    // TODO: there's more information in teh result here! Display them somehow
    const waypoints = estimate.result.waypoints;

    return waypoints.map((wp) => [wp.lon, wp.lat, wp.alt]);
  }
);

// Reuse this for flight paths and plans
const addFlightDots = (tag, style, waypoints, width, color) => {
  style.sources['flightplan-src'] = {
    type: 'geojson',
    lineMetrics: false,
    data: {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: waypoints,
      },
    },
  };

  style.layers.push({
    id: 'flightplan-line' + tag,
    type: 'line',
    source: 'flightplan-src',
    paint: {
      'line-color': color,
      'line-width': width,
    },
    layout: {
      'line-cap': 'round',
      'line-join': 'miter',
    },
  });
};

const availableImageryChannels = createSelector(
  routeMapDescription,
  getChannel,

  (mapDesc, channel) => {
    const codeForImagery = (i) => `${i.region_id}_${i.target_results_id}`;

    // For each region per TR, only load the first of the visible types
    const visible = new Set(mapDesc.imagery.filter((i) => i.visible).map(codeForImagery));

    let availableChannels = new Set();

    for (const code of visible) {
      // Find the one imagery item that matches the id and visibleImageryType
      for (const c of Object.keys(channelConfig)) {
        const image = mapDesc.imagery.find(
          (i) => codeForImagery(i) == code && channelConfig[c].tiletypes.includes(i.type)
        );
        // yay we found imagery
        if (image) availableChannels.add(c);
      }
    }

    return availableChannels;
  }
);

const getVisibleImagery = createSelector(routeMapDescription, getChannel, (mapDesc, channel) => {
  const visibleImageryType = new Set(channelConfig[channel].tiletypes || []);

  if (!visibleImageryType) {
    Logging.warn(`Unsupported channel ${channel}`);
  }

  const codeForImagery = (i) => `${i.region_id}_${i.target_results_id}`;
  const loadedCodes = new Set(mapDesc.imagery.filter((i) => !i.unloaded).map(codeForImagery));

  // Find the imagery items that matches the id and visibleImageryType
  const imagery = mapDesc.imagery.filter(
    (i) => loadedCodes.has(codeForImagery(i)) && visibleImageryType.has(i.type)
  );

  return imagery;
});

// Params is the channel, 0 or 1, for which map we're on
const getMapStyle = createSelector(
  getVisibleImagery,
  routeMapDescription,
  getChannel,
  (state) => route.shouldUsershapeLock(state),
  getEstimatePath,
  (state) => route.flightPath(state),
  (state) => state.global.preferences.showFlightPath,
  (state, mapidx) => mapidx,
  (state, mapidx) => getDataColors(state, getChannel(state, mapidx)),
  (state) => state.ui.viewport.pitch > 0,

  (state) => route.editingUsershape(state),

  getUsershapeFeatures,
  getUsershapeVertexFeature,
  getUsershapeMidpointFeature,
  getUsershapeValid,
  getUsershapeEditing,
  getUsershapeAdding,

  (
    visibleImagery,
    mapDesc,
    channel,
    usershapeLocked,
    estimatePath,
    flightPathWkt,
    showFlightPath,
    mapidx,
    palette,
    tilted,
    editingUsershape,
    usershapeFeatures,
    usershapeVertexFeature,
    usershapeMidpointFeature,
    usershapeValid,
    usershapeEditing,
    usershapeAdding
  ) => {
    let style = {
      version: 8,
      sources: {},
      layers: [],
    };

    // some imagery doesn't have a tileset.
    const imageryWithTileset = visibleImagery.filter((i) => i.tileset);

    imageryWithTileset
      .map((i) => sourceForImagery(i))
      .forEach(({ id, ...src }) => (style.sources[id] = src));

    const imageryLayers = imageryWithTileset.reduce(
      (arr, i) => arr.concat(layerForImagery(i, channel, mapidx, palette)),
      []
    );
    style.layers.push(...imageryLayers);

    // { fill, outlineShadow, outline, text: layer}
    const regionDescLayers = mapDesc.regions.map((x) =>
      LayersForRegion(
        x.id,
        x.type,
        x.ceiling,
        x.color,
        x.outline ? 1 : 0,
        x.fill ? 0.3 : 0,
        x.name ? true : false,
        tilted
      )
    );

    const getAssetHeight = (a) => {
      if (!a.shape) {
        return 0;
      }

      const shapeGeo = wkt.parse(a.shape);
      if (shapeGeo.type == 'Point') {
        return shapeGeo.coordinates[0].length > 2 ? shapeGeo.coordinates[0][2] : 0;
      }

      return shapeGeo.coordinates[0][0].length > 2 ? shapeGeo.coordinates[0][0][2] : 0;
    };

    const assetDescLayers =
      mapDesc.assets?.map((x) =>
        LayersForAsset(
          x.id,
          getAssetHeight(x),
          x.color,
          x.outline ? 1 : 0,
          x.fill ? 0.3 : 0,
          x.name ? true : false,
          tilted
        )
      ) || [];

    const descLayers = [...regionDescLayers, ...assetDescLayers];
    for (const r of mapDesc.regions) {
      const sForR = sourcesForLayer(r.id, r.boundary, r.display_name, r.dilation);
      Object.assign(style.sources, sForR);
    }

    for (const a of mapDesc.assets) {
      const sForA = sourcesForLayer(a.id, a.shape, a.display_name, a.dilation);
      Object.assign(style.sources, sForA);
    }

    // Set these layers and sources into
    descLayers.forEach((x) => style.layers.push(x.outlineShadow));
    descLayers.forEach((x) => style.layers.push(x.outline));
    descLayers.forEach((x) => style.layers.push(x.fill));

    // Add Analysis overlays TODO: this should be more sophistocated?
    mapDesc.overlays.forEach((ovl) => {
      style.sources[ovl.srcid] = ovl.source;
      style.layers.push(...ovl.layers);
    });

    const plotFlightPath = (waypoints, key, color) => {
      // Draw an estimated flight path, if we have one

      // Draw a rectangle for each line segment.

      if (tilted) {
        addFlightDots('shadow', style, waypoints, 5, 'rgba(0,0,0,0.35)'); // shadow

        const segments = [];
        for (let i = 0; i < waypoints.length - 2; i++) {
          let j = i + 1;
          let line = {
            type: 'LineString',
            coordinates: [
              [waypoints[i][0], waypoints[i][1]],
              [waypoints[j][0], waypoints[j][1]],
            ],
          };
          let feat = turf.buffer(line, 0.5, { units: 'meters', steps: 1 });

          const maxAlt = Math.max(waypoints[i][2], waypoints[j][2]) + 0.25;
          const minAlt = Math.min(waypoints[i][2], waypoints[j][2]) - 0.25;

          // A little hack here to make it go down to the ground on first or last
          segments.push({
            f: feat,
            minAlt: i == 0 || j == waypoints.length - 1 ? 0 : minAlt,
            maxAlt,
          });
        }

        style.sources[`${key}-3d`] = {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: segments.map((seg) => ({
              ...seg.f,
              properties: {
                height: seg.maxAlt,
                base: seg.minAlt,
              },
            })),
          },
        };

        style.layers.push({
          id: `${key}-3d`,
          type: 'fill-extrusion',
          source: `${key}-3d`,
          paint: {
            'fill-extrusion-color': color,
            'fill-extrusion-opacity': 1,
            'fill-extrusion-height': ['get', 'height'],
            'fill-extrusion-base': ['get', 'base'],
          },
        });
      } else {
        // Not tilted
        addFlightDots('shadow', style, waypoints, 5, 'rgba(0,0,0,0.35)');
        addFlightDots('2d', style, waypoints, 3, color);
      }
    };

    if (editingUsershape) {
      const usershapeAppearance = layersForUsershape(
        usershapeFeatures,
        usershapeVertexFeature,
        usershapeMidpointFeature,
        editingUsershape.color || regionColors.TARGETED,
        usershapeValid,
        usershapeEditing,
        usershapeAdding,

        usershapeLocked,
        editingUsershape.ceiling || editingUsershape.height,
        tilted,
        mapDesc.usershapeDilation || 0,
        mapDesc.crossingPoints
      );
      Object.assign(style.sources, usershapeAppearance.sources);
      style.layers = style.layers.concat(usershapeAppearance.layers);
    }

    if (showFlightPath && flightPathWkt) {
      const flightPath = wkt.parse(flightPathWkt);
      if (flightPath && flightPath.coordinates) {
        plotFlightPath(flightPath.coordinates, 'log-path', symbolicColors.FLIGHT_PATH);
      }
    }
    if (estimatePath) plotFlightPath(estimatePath, 'estimate-path', symbolicColors.FLIGHT_PATH);

    // We put these on last so semitransparent fills display correctly
    regionDescLayers.forEach((x) => style.layers.push(x.fill));
    regionDescLayers.forEach((x) => style.layers.push(x.text));

    // add any extras
    style.sources = { ...style.sources, ...mapDesc.extras.sources };
    style.layers = [...style.layers, ...mapDesc.extras.layers];

    return style;
  }
);

export { routeMapDescription, getMapStyle, getVisibleImagery, availableImageryChannels };
