import axios from 'axios';

interface VersionData {
  build: number | string;
  commit: string;
}

async function getVersionData() {
  try {
    const resp = await axios({
      method: 'GET',
      url: `/version.json`,
      responseType: 'json',
      withCredentials: false,
    });
    return resp.data as VersionData;
  } catch (e) {
    return { build: 'dev', commit: 'dev' } as VersionData;
  }
}

let versionData: VersionData | null = null;

getVersionData().then((data) => {
  versionData = data;
});

export function getScoutviewCommit() {
  return versionData?.commit || '?';
}
export function getScoutviewBuild() {
  return versionData?.build || '?';
}
