import React from 'react';

import { MenuItem, TextField } from '@material-ui/core';

import * as wkt from 'wellknown';

import center from '@turf/center';
import { geometryCollection } from '@turf/helpers';

import type Props from './trqControlProps';

import type { Asset, TargetRequest } from '~/schema';
import { ControlsAssetOrbit, EllipseSpec, YawBehavior } from '~/schema/TargetRequest';
import { useSVSelector } from '~/redux/hooks';
import * as selectors from '../selectors';

// Need this as a type predicate so that we can filter out nulls from arrays in a type-happy way
function nonnull<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

// We need a way to generate a default target request for a given asset.
export function defaultTRQForAssetOrbit(assets: Asset[], surveyId: number): TargetRequest {
  const rings: EllipseSpec[] = [];

  const assetGeometries = assets.map((a) => (a.shape ? wkt.parse(a.shape) : null));

  const assetGeometry = geometryCollection(assetGeometries.filter(nonnull));

  const centerPoint = center(assetGeometry); // Returns a point feature

  rings.push({
    ellipse: {
      radius_m: 30.48,
      altitude_m: 60,
      ellipse_focii: [
        wkt.stringify(centerPoint.geometry as wkt.GeoJSONPoint),
        wkt.stringify(centerPoint.geometry as wkt.GeoJSONPoint),
      ],
      start_bearing: 0,
      end_bearing: 0,
      clockwise: true,
      yaw_behavior: 'HOLD_FRONT_TO_CIRCLE_CENTER',
    },
    divisions: 12,
  });

  return {
    survey_id: surveyId,
    deleted: false,
    last_updated: '',
    target_areas: assets.map((a) => ({ asset_id: a.id })),
    request_type: 'ASSET_ORBIT',
    instructions_asset: {
      capture_points: [],
    },

    controls_asset_orbit: {
      orbit_rings: rings,
      capture_point_overrides: [],
    },
  };
}

import produce from 'immer';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import useFormBuffer from '~/shared/formBuffer';
import { updateInstructions } from '..';

export default function AssetOrbitControls({ trq, editField }: Props) {
  const targetedAssets = useSVSelector(selectors.getTargetedAssets);

  // We assert that for this kind of TRQ, we have an asset instruction.
  if (!trq.controls_asset_orbit) {
    throw 'Invalid TRQ instructions/controls passed to AssetOrbitControls';
  }
  // TODO this should be done in a subcoomponent dealing with editing rings directly
  const currentRing = trq.controls_asset_orbit?.orbit_rings[0];

  const editControls = React.useCallback(
    (recipe: (draft: ControlsAssetOrbit) => void) => {
      const newTrq = produce(trq, (draft) => {
        if (draft.controls_asset_orbit) {
          draft.controls_asset_orbit = produce(draft.controls_asset_orbit, recipe);
          updateInstructions(draft, targetedAssets);
        }
      });

      if (newTrq) {
        editField(newTrq);
      }
    },
    [editField, trq, targetedAssets]
  );

  // Here are all the form hooks
  const form = {
    radius: useFormBuffer<number>(
      Math.round(currentRing.ellipse.radius_m * 3.28084),
      React.useCallback(
        (value) =>
          editControls((draft) => {
            draft.orbit_rings[0].ellipse.radius_m = value / 3.28084;
          }),
        [editControls]
      ),
      React.useCallback((value) => {
        if (isNaN(value)) return 'Invalid radius';
        if (value < 1) return 'Radius too low';
        if (value > 1000) return 'Radius too high';
      }, [])
    ),

    altitude: useFormBuffer<number>(
      Math.round(currentRing.ellipse.altitude_m * 3.28084),
      React.useCallback(
        (value) =>
          editControls((draft) => {
            draft.orbit_rings[0].ellipse.altitude_m = value / 3.28084;
          }),
        [editControls]
      ),
      React.useCallback((value) => {
        if (isNaN(value)) return 'Invalid altitude';
        if (value < 1) return 'Altitude too low';
        if (value > 1000) return 'Altitude too high';
      }, [])
    ),
    divisions: useFormBuffer<number>(
      Math.round(currentRing.divisions),
      React.useCallback(
        (value) =>
          editControls((draft) => {
            draft.orbit_rings[0].divisions = value;
          }),
        [editControls]
      ),
      React.useCallback((value) => {
        if (isNaN(value)) return 'Invalid';
        if (value < 2) return 'Need at least 2 captures';
        if (value > 64) return 'No more than 64 captures';
      }, [])
    ),
    startBearing: useFormBuffer<number>(
      Math.round(currentRing.ellipse.start_bearing),
      React.useCallback(
        (value) =>
          editControls((draft) => {
            draft.orbit_rings[0].ellipse.start_bearing = value;
          }),
        [editControls]
      ),
      React.useCallback((value) => {
        if (isNaN(value) || value < 0 || value > 359) return 'Invalid bearing';
      }, [])
    ),
    endBearing: useFormBuffer<number>(
      Math.round(currentRing.ellipse.end_bearing),
      React.useCallback(
        (value) =>
          editControls((draft) => {
            draft.orbit_rings[0].ellipse.end_bearing = value;
          }),
        [editControls]
      ),
      React.useCallback((value) => {
        if (isNaN(value) || value < 0 || value > 359) return 'Invalid bearing';
      }, [])
    ),
    cw: useFormBuffer<boolean>(
      currentRing.ellipse.clockwise,
      React.useCallback(
        (value) =>
          editControls((draft) => {
            draft.orbit_rings[0].ellipse.clockwise = value;
          }),
        [editControls]
      )
    ),
  };

  return (
    <>
      <TextField
        fullWidth
        type='number'
        inputProps={{ min: 1, max: 1000, step: 1 }}
        onChange={(evt) => form.radius.set(parseFloat(evt.target.value))}
        label='Radius of orbit (ft)'
        value={form.radius.value.toFixed(0)}
        error={!!form.radius.err}
        helperText={form.radius.err}
      />

      <TextField
        fullWidth
        type='number'
        inputProps={{ min: 10, max: 1000, step: 1 }}
        onChange={(evt) => form.altitude.set(parseFloat(evt.target.value))}
        label='Altitude of orbit (ft AGL)'
        value={form.altitude.value.toFixed(0)}
      />

      <TextField
        fullWidth
        type='number'
        inputProps={{ min: 2, max: 64, step: 1 }}
        onChange={(evt) => form.divisions.set(parseInt(evt.target.value))}
        label='Number of captures around orbit'
        value={form.divisions.value}
        error={!!form.divisions.err}
        helperText={form.divisions.err}
      />

      <TextField
        fullWidth
        type='number'
        inputProps={{ min: 0, max: 359, step: 1 }}
        onChange={(evt) => form.startBearing.set(parseInt(evt.target.value))}
        label='Start at bearing (deg)'
        value={form.startBearing.value}
        error={!!form.startBearing.err}
        helperText={form.startBearing.err}
      />

      <TextField
        fullWidth
        type='number'
        inputProps={{ min: 0, max: 359, step: 1 }}
        onChange={(evt) => form.endBearing.set(parseInt(evt.target.value))}
        label='End at bearing (deg)'
        value={form.endBearing.value}
        error={!!form.endBearing.err}
        helperText={form.endBearing.err}
      />

      <ToggleButtonGroup
        size='small'
        exclusive
        onChange={(_, value) => form.cw.set(value == 'CW')}
        value={form.cw.value ? 'CW' : 'CCW'}>
        <ToggleButton value={'CW'}>CW</ToggleButton>
        <ToggleButton value={'CCW'}>CCW</ToggleButton>
      </ToggleButtonGroup>

      <TextField
        fullWidth
        disabled
        select
        onChange={(evt) => {
          editControls((draft) => {
            draft.orbit_rings[0].ellipse.yaw_behavior = evt.target.value as YawBehavior;
          });
        }}
        label='Yaw Behavior'
        onClick={(evt) => evt.stopPropagation()}
        value={currentRing.ellipse.yaw_behavior}>
        <MenuItem value='HOLD_FRONT_TO_CIRCLE_CENTER'>HOLD_FRONT_TO_CIRCLE_CENTER</MenuItem>
        <MenuItem value='HOLD_INITIAL_HEADING'>HOLD_INITIAL_HEADING</MenuItem>
        <MenuItem value='HOLD_FRONT_TANGENT_TO_CIRCLE'>HOLD_FRONT_TANGENT_TO_CIRCLE</MenuItem>
      </TextField>
      {/* 
 

camera target altitude

capture duration (0 for still, >0 for video)

Ignoring fiels like payload paramters and capture angle so far.

*/}
    </>
  );
}
