import { getFillLayer, getOutlineLayer, getOutlineShadowLayer } from '~/lib/layersForRegion';
// import { getFillLayer, getOutlineLayer, getOutlineShadowLayer } from '~/lib/layersForRegion';
import { regionColors } from '~/theme';
import { symbolicColors } from '~/theme';

import * as turf from '@turf/turf';
import { RegionType } from '~/schema';
import mapboxgl from 'mapbox-gl';

export default function layersForUsershape(
  usershapeFeatures: GeoJSON.Feature[],
  usershapeVertexFeature: GeoJSON.FeatureCollection,
  usershapeMidpointFeature: GeoJSON.Feature,
  color: string,
  usershapeValid: boolean,
  usershapeEditing: boolean,
  usershapeAdding: boolean,
  usershapeLocked: boolean,
  ceiling: number,
  tilted: boolean,
  dilation?: number,
  crossingPoints?: GeoJSON.Position[]
): { sources: Record<string, mapboxgl.AnySourceData>; layers: mapboxgl.AnyLayer[] } {
  // the sources/layers for our editor
  const sources: Record<string, mapboxgl.AnySourceData> = {};
  const layers: mapboxgl.AnyLayer[] = [];

  // Get colors for the boundary we're drawing
  const editorColor = usershapeValid ? color : symbolicColors.INVALID_POLYGON;

  for (const fi in usershapeFeatures) {
    const feature = usershapeFeatures[fi];

    sources[`usershape-${fi}-src`] = {
      type: 'geojson',
      data: feature,
    };

    const type = feature.geometry.type;

    // In some cases we want to show a buffer around the usershape
    if (dilation && type == 'LineString') {
      const buffered = turf.buffer(feature, dilation, { units: 'meters' });

      sources[`usershape-dilation-${fi}-src`] = {
        type: 'geojson',
        data: buffered,
      };

      layers.push({
        id: `usershape-dilation-fill`,
        type: 'fill',
        minzoom: 1,
        source: `usershape-dilation-${fi}-src`,
        paint: {
          'fill-color': editorColor,
          'fill-opacity': 0.62,
        },
      });
    }

    if (['Polygon', 'MultiPolygon'].includes(type) && usershapeValid) {
      layers.push(getFillLayer(`usershape-${fi}`, ceiling, editorColor, 0.6, tilted));
    }
  }

  if (crossingPoints) {
    sources['crossing_points_src'] = {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'MultiPoint',
          coordinates: crossingPoints,
        },
        properties: {},
      },
    };

    layers.push({
      id: 'crossing_points',
      source: 'crossing_points_src',
      type: 'circle',
      paint: {
        'circle-radius': 16,
        'circle-color': symbolicColors.CROSSING_POINT,
      },
    });
  }

  for (const fi in usershapeFeatures) {
    layers.push(getOutlineShadowLayer(`usershape-${fi}`, 1));
    const outlineLayer = getOutlineLayer(
      `usershape-${fi}`,
      ['case', ['get', 'polySelected'], '#FFF', editorColor],
      1
    );
    layers.push(outlineLayer);
  }

  if (!usershapeLocked) {
    if (!usershapeAdding) {
      if (usershapeMidpointFeature) {
        sources['usershapeMidpoint-src'] = {
          type: 'geojson',
          data: usershapeMidpointFeature,
        };

        layers.push({
          id: 'usershapeMidpoint',
          type: 'circle',
          source: 'usershapeMidpoint-src',
          paint: {
            'circle-radius': 4,
            'circle-color': editorColor,
            'circle-stroke-width': 1,
            'circle-stroke-color': 'black',
            'circle-stroke-opacity': 0.8,
          },
        });
      }
    }
    if (usershapeVertexFeature) {
      sources['usershapeControl-src'] = {
        type: 'geojson',
        data: usershapeVertexFeature,
      };

      layers.push({
        id: 'usershapeControl',
        type: 'circle',
        source: 'usershapeControl-src',
        paint: {
          'circle-radius': 8,
          'circle-color': ['case', ['get', 'selectedVertex'], '#FFF', editorColor],
          'circle-stroke-width': ['case', ['get', 'canCompleteAt'], 3, 1],
          'circle-stroke-color': ['case', ['get', 'selectedRing'], '#FFF', '#000'],
          'circle-stroke-opacity': 0.8,
        },
      });
    }
  }
  return { sources, layers };
}
