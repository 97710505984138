import * as wkt from 'wellknown';
import { Coordinate } from '~/lib/customHooks';
import { Note } from '~/map/markers';

export default interface Note {
  id?: number;
  location: Coordinate;
  note: string;
  author_user_id: string;
  tr_id: number;
  region_id: number;
  attachments: string[];
  comments: NoteComment[];
}

export interface NoteComment {
  id?: number;
  note_id: number;
}

export interface Json extends Omit<Note, 'location'> {
  location: string;
}

export function fromjson(json: Json): Note {
  return {
    ...json,
    // Asserting here that location is a valid point.
    location: wkt.parse(json.location)!.coordinates as Coordinate,
  };
}
export function tojson(o: Note): Json {
  return {
    ...o,
    location: wkt.stringify({
      type: 'Point', // TODO: May have to expand this if we want polygon notes
      coordinates: o.location,
    }),
  };
}
