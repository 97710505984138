import classNames from 'classnames';
import React from 'react';

import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    height: 28,
    display: 'flex',
    flexGrow: 1,
    paddingTop: 2,
    paddingRight: 16,
    paddingLeft: 16,
  },
  track: {
    height: 8,
    flexGrow: 1,
    background: theme.palette.grey[300],
    position: 'relative',
  },
  used: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    background: theme.palette.primary.main,
    borderRadius: 4,
  },
});

function BatteryMeter({ min, max, classes }) {
  return (
    <div className={classes.container}>
      <div className={classes.track}>
        <div className={classes.used} style={{ left: `${min}%`, right: `${100 - max}%` }} />
        <Typography
          variant='caption'
          style={{ position: 'absolute', top: 8, right: `${100 - min}%` }}>
          {min.toFixed(0)}%
        </Typography>

        <Typography variant='caption' style={{ position: 'absolute', top: 8, left: `${max}%` }}>
          {max.toFixed(0)}%
        </Typography>
      </div>
    </div>
  );
}

export default withStyles(styles)(BatteryMeter);
