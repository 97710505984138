import { Selector } from 'reselect';
import { createSelector } from 'reselect';
import { getDataColors } from '~/redux/commonSelectors';
import { getChannel } from '~/redux/commonSelectors';
import { Palette } from '~/schema';
import { RootState } from '~/store';

type RGB = [number, number, number];

function hexToRGB(hex: string): RGB {
  return [
    parseInt(hex.slice(1, 3), 16),
    parseInt(hex.slice(3, 5), 16),
    parseInt(hex.slice(5, 7), 16),
  ];
}

const palettes: Record<Palette, RGB[]> = {
  normal: ['#ff0000', '#eeff00', '#00aa00', '#005500'].map((x) => hexToRGB(x)),
  viridis: ['#440D53', '#34608D', '#2CB17E', '#FDE725'].map((x) => hexToRGB(x)),
  inferno: ['#000000', '#64156E', '#F2741C', '#FCFDA3'].map((x) => hexToRGB(x)),
  grey: ['#000000', '#313131', '#8a8a8a', '#ffffff'].map((x) => hexToRGB(x)),
};

type StopsEntry = [number, RGB];

const stopsForType: Record<Palette, Record<string, StopsEntry[]>> = {
  normal: {
    NDVI: [
      [0, palettes.normal[0]],
      [0.33, palettes.normal[1]],
      [0.66, palettes.normal[2]],
      [1, palettes.normal[3]],
    ],
    DEM: [
      [0.0, [0.090196 * 255, 0.066667 * 255, 0.407843 * 255]],
      [0.25, [0.458824 * 255, 0.898039 * 255, 0.792157 * 255]],
      [0.5, [0.011765 * 255, 0.290196 * 255, 0.007843 * 255]],
      [0.75, [0.992157 * 255, 0.94902 * 255, 0.662745 * 255]],
      [1.0, [0.317647 * 255, 0.180392 * 255, 0.066667 * 255]],
    ],
  },

  viridis: {
    NDVI: [
      [0, palettes.viridis[0]],
      [0.33, palettes.viridis[1]],
      [0.66, palettes.viridis[2]],
      [1, palettes.viridis[3]],
    ],
    DEM: [
      [0.0, [0.090196 * 255, 0.066667 * 255, 0.407843 * 255]],
      [0.25, [0.458824 * 255, 0.898039 * 255, 0.792157 * 255]],
      [0.5, [0.011765 * 255, 0.290196 * 255, 0.007843 * 255]],
      [0.75, [0.992157 * 255, 0.94902 * 255, 0.662745 * 255]],
      [1.0, [0.317647 * 255, 0.180392 * 255, 0.066667 * 255]],
    ],
  },
  inferno: {
    NDVI: [
      [0, palettes.inferno[0]],
      [0.33, palettes.inferno[1]],
      [0.66, palettes.inferno[2]],
      [1, palettes.inferno[3]],
    ],
    DEM: [
      [0.0, [0.090196 * 255, 0.066667 * 255, 0.407843 * 255]],
      [0.25, [0.458824 * 255, 0.898039 * 255, 0.792157 * 255]],
      [0.5, [0.011765 * 255, 0.290196 * 255, 0.007843 * 255]],
      [0.75, [0.992157 * 255, 0.94902 * 255, 0.662745 * 255]],
      [1.0, [0.317647 * 255, 0.180392 * 255, 0.066667 * 255]],
    ],
  },
  grey: {
    NDVI: [
      [0, palettes.grey[0]],
      [0.33, palettes.grey[1]],
      [0.66, palettes.grey[2]],
      [1, palettes.grey[3]],
    ],
    DEM: [
      [0.0, [0.090196 * 255, 0.066667 * 255, 0.407843 * 255]],
      [0.25, [0.458824 * 255, 0.898039 * 255, 0.792157 * 255]],
      [0.5, [0.011765 * 255, 0.290196 * 255, 0.007843 * 255]],
      [0.75, [0.992157 * 255, 0.94902 * 255, 0.662745 * 255]],
      [1.0, [0.317647 * 255, 0.180392 * 255, 0.066667 * 255]],
    ],
  },
};

export function getStopsForType(palette: Palette, type: string): StopsEntry[] {
  if (palette in stopsForType) {
    if (type in stopsForType[palette]) {
      return stopsForType[palette][type];
    } else {
      return stopsForType[palette].NDVI;
    }
  } else return getStopsForType('normal', type);
}

export function getShaderStopValues(palette: Palette, type: string) {
  // TODO: how to get palette? Need to recompile shaders? Should it be a param of the shaders? Selectors to get them?
  const stops = getStopsForType(palette, type);

  const len = stops.length;

  let glsl = `\nvoid getStopColors(inout vec3[${len}] stop_cols) {\n`;
  for (let i = 0; i < len; i++) {
    const r = (stops[i][1][0] / 255).toFixed(3);
    const g = (stops[i][1][1] / 255).toFixed(3);
    const b = (stops[i][1][2] / 255).toFixed(3);

    glsl += `  stop_cols[${i}] = vec3(${r},${g},${b});\n`;
  }
  glsl += `}`;

  return glsl;
}

export const getDemoGradient = (palette: Palette, type: string) => {
  const gradient = getStopsForType(palette, type);
  // [ [stop, [r,g,b]], ... ]

  function entryForStop(x: StopsEntry) {
    const [stop, [r, g, b]] = x;
    return `rgb(${r}, ${g}, ${b}) ${Math.floor(stop * 100)}%`;
  }

  const rval = 'linear-gradient(to right, ' + gradient.map((g) => entryForStop(g)).join(', ') + ')';
  return rval;
};

export const getColorForNDVI: Selector<any, (v: number) => string> = createSelector(
  (state: RootState) => getDataColors(state, getChannel(state, 0)) as Palette,

  (type) => (v) => colorForNDVI(type, v)
);
export const getColorForNDVIMapB: Selector<any, (v: number) => string> = createSelector(
  (state: RootState) => getDataColors(state, getChannel(state, 1)) as Palette,

  (type) => (v) => colorForNDVI(type, v)
);
function colorForNDVI(type: Palette, v: number) {
  const gradient = getStopsForType(type, 'NDVI');

  let color = null;

  function interpolate(x: number, a: RGB, b: RGB) {
    return [a[0] * (1 - x) + b[0] * x, a[1] * (1 - x) + b[1] * x, a[2] * (1 - x) + b[2] * x];
  }

  for (let i = 0; i < gradient.length - 1; i++) {
    const start = gradient[i][0];
    const stop = gradient[i + 1][0];
    if (v >= start && v < stop) {
      const x = (v - start) / (stop - start);
      color = interpolate(x, gradient[i][1], gradient[i + 1][1]);
    }
  }
  if (v < gradient[0][0]) color = gradient[0][1];
  if (v >= gradient[gradient.length - 1][0]) color = gradient[gradient.length - 1][1];

  function toHex(d: number) {
    const hex = Number(Math.floor(d)).toString(16);
    if (hex.length < 2) {
      return '0' + hex;
    } else {
      return hex;
    }
  }

  color = color || [0xf4, 0x43, 0x36];

  return `#${toHex(color[0])}${toHex(color[1])}${toHex(color[2])}`;
}
