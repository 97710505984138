import { createSelector } from 'reselect';
import simplify from 'simplify-geojson';
import * as wkt from 'wellknown';
import { betterBooleanContains } from '~/lib/geography';
import { showSnackbar } from '~/lib/modalServices';
import Logging from '~/logging';
import type { RootState } from '~/store';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import * as turf from '@turf/turf';
import { Feature, MultiPolygon, Polygon, Position } from '@turf/turf';

import * as util from './usUtils';

import { UsershapeType, UsershapeState } from './types';
const initialState: UsershapeState = {
  isAdding: false,
  isDragging: false,
  type: undefined,
  polygons: [],
  selectedPolygonIdx: undefined,
  selectedRingIdx: undefined,
  selectedVertexIdx: undefined,
};

const slice = createSlice({
  name: 'usershape',
  initialState,

  reducers: {
    setDragging(state, action: PayloadAction<boolean>) {
      state.isDragging = action.payload;

      const ring =
        state.selectedPolygonIdx != undefined && state.selectedRingIdx != undefined
          ? state.polygons[state.selectedPolygonIdx][state.selectedRingIdx]
          : undefined;

      // Special case; releasing the control points of rect/circle while adding cancels adding when there are two of them.
      if ((state.type == 'rectangle' || state.type == 'circle') && ring?.length == 2) {
        state.isAdding = false;
        state.selectedVertexIdx = undefined;
      }

      util.tidyUpState(state); // to clean up dragging vertices together, really
    },

    // setAdding(state, action: PayloadAction<{ adding: boolean; polygon: number; ring: number }>) {
    //   const { adding, polygon, ring } = action.payload;
    //   state.isAdding = adding;
    //   if (adding) {
    //     state.selectedPolygonIdx = polygon;
    //     state.selectedRingIdx = ring;
    //     state.selectedVertexIdx = undefined;
    //   }
    // },

    selectGeometry(
      state,
      action: PayloadAction<{ polygon: number; ring: number; vertex: number }>
    ) {
      const { polygon, ring, vertex } = action.payload;

      // Selection can mean different things in context.
      // If we are adding, then selection stops adding.
      if (vertex) {
        if (state.isAdding) {
          // if we're a polygon, we have to click on the first vertex to complete the shape.
          // otherwise, no effect
          if (state.type == 'polygon') {
            if (
              polygon != state.selectedPolygonIdx ||
              ring != state.selectedRingIdx ||
              vertex != 0
            ) {
              // clicked on the wrong vertex
              return;
            } else {
              // we can complete the shape.
            }
          } else {
            // not a polygon, just stop adding.
          }
        }
      }
      state.selectedPolygonIdx = polygon;
      state.selectedRingIdx = ring;
      state.selectedVertexIdx = vertex;
      state.isDragging = vertex != undefined;
      state.isAdding = false;
    },

    setIdle(state) {
      state.isDragging = false;
      state.isAdding = false;

      state.selectedPolygonIdx = undefined;
      state.selectedRingIdx = undefined;
      state.selectedVertexIdx = undefined;
      util.tidyUpState(state);
    },

    setUsershapeFromWKT(state, action: PayloadAction<{ wktstr: string }>) {
      const { wktstr } = action.payload;

      const geojson = wkt.parse(wktstr);
      const feat = turf.feature(geojson);
      const cleanFeat = turf.cleanCoords(feat);

      state.isAdding = false;
      state.isDragging = false;
      state.selectedPolygonIdx = undefined;
      state.selectedRingIdx = undefined;
      state.selectedVertexIdx = undefined;

      const { type, polygons } = util.getPolygonsFromFeature(cleanFeat);
      state.type = type;
      state.polygons = polygons;

      util.tidyUpState(state);
    },

    resetShape(state) {
      state.type = undefined;
      state.polygons = [];
      state.selectedPolygonIdx = undefined;
      state.selectedRingIdx = undefined;
      state.selectedVertexIdx = undefined;
      state.isDragging = false;
      state.isAdding = false;
      util.tidyUpState(state);
    },

    createShape(state, action: PayloadAction<{ type: UsershapeType }>) {
      const { type } = action.payload;

      // this creates no verts, but puts us in adding mode with stuff set up
      state.type = type;
      state.isAdding = true;
      state.isDragging = false;
      state.selectedPolygonIdx = undefined;
      state.selectedRingIdx = undefined; // todo unless we're creating a new inner ring?
      state.selectedVertexIdx = undefined;
    },

    addInnerRing(state) {
      // TODO: add a ring to teh selected polygon

      if (state.selectedPolygonIdx === undefined || state.selectedRingIdx !== 0) {
        Logging.warn("Can't add inner ring without an outer ring selected");
        return;
      }

      state.isDragging = false;
      state.isAdding = true;
      state.polygons[state.selectedPolygonIdx].push([]);
      state.selectedRingIdx = state.polygons[state.selectedPolygonIdx].length - 1;
      state.selectedVertexIdx = undefined;
    },

    addPolygon(state) {
      // this is for starting a new add process for a new polygon of a multipolygon.
      state.isDragging = false;
      state.isAdding = true;
      state.polygons.push([[]]);
      state.selectedPolygonIdx = state.polygons.length - 1;
      state.selectedRingIdx = 0;
      state.selectedVertexIdx = undefined;
    },

    completeShape(state) {
      state.selectedPolygonIdx == undefined;
      state.selectedRingIdx == undefined;
      state.selectedVertexIdx == undefined;
      state.isDragging = false;
      state.isAdding = false;
      util.tidyUpState(state);
    },

    dragVertex(state, action: PayloadAction<{ to: number[] }>) {
      const { to } = action.payload;

      if (
        state.selectedPolygonIdx === undefined ||
        state.selectedRingIdx === undefined ||
        state.selectedVertexIdx === undefined
      ) {
        Logging.warn("Can't drag a vertex without a vertex selected");
        return;
      }

      const ring = state.polygons[state.selectedPolygonIdx][state.selectedRingIdx];

      if (state.type == 'circle' && state.selectedVertexIdx == 0) {
        // drag both when dragging the center of a circle
        const center = ring[0];
        const edge = ring[1];

        const circle_offset = [edge[0] - center[0], edge[1] - center[1]];

        ring[0] = to;
        ring[1] = [to[0] + circle_offset[0], to[1] + circle_offset[1]];
      } else {
        if (state.selectedRingIdx > 0) {
          // we can't drag an inner ring outside the parent
          const parentRing = state.polygons[state.selectedPolygonIdx][0];
          const parent = turf.polygon([[...parentRing, parentRing[0]]]);

          if (betterBooleanContains(parent, turf.point(to))) {
            ring[state.selectedVertexIdx] = to;
          } else {
            Logging.warn('Cannot put inner ring outside polygon.');
          }
        } else {
          ring[state.selectedVertexIdx] = to;
        }
      }
    },

    subdivideSegment(
      state,
      action: PayloadAction<{
        before: number;
        after: number;
        polyidx: number;
        ringidx: number;
      }>
    ) {
      const { before, after, polyidx, ringidx } = action.payload;

      const ring = state.polygons[polyidx][ringidx];

      const a = ring[before];
      const b = ring[after % ring.length];
      const c = [(a[0] + b[0]) / 2, (a[1] + b[1]) / 2];
      const insidx = after % ring.length;

      ring.splice(insidx, 0, c);
      state.selectedPolygonIdx = polyidx;
      state.selectedRingIdx = ringidx;
      state.selectedVertexIdx = insidx;
      state.isDragging = true;
    },

    createVertex(state, action: PayloadAction<{ at: Position }>) {
      const { at } = action.payload;

      if (state.selectedPolygonIdx === undefined) {
        state.selectedPolygonIdx = state.polygons.length;
        state.polygons.push([]);
      }
      if (state.selectedRingIdx === undefined) {
        state.selectedRingIdx = state.polygons[state.selectedPolygonIdx].length;
        state.polygons[state.selectedPolygonIdx].push([]);
      }

      const ring = state.polygons[state.selectedPolygonIdx][state.selectedRingIdx];

      if (state.type == 'rectangle' || state.type == 'circle') {
        // creating verts for these is unique. we only ever create the second, or both, at once.
        if (ring.length == 0) {
          ring.push(at); // first
          ring.push(at); // second
        } else if (ring.length == 1) {
          ring.push(at); // second
        } else {
          Logging.warn("Can't create third vertex for this type", { type: state.type });
        }
      } else {
        // just add one vertex

        if (state.selectedRingIdx > 0) {
          // when adding holes, we have to do it inside the parent.
          const parentRing = state.polygons[state.selectedPolygonIdx][0];
          const parent = turf.polygon([[...parentRing, parentRing[0]]]);

          if (betterBooleanContains(parent, turf.point(at))) {
            ring.push(at);
          } else {
            Logging.warn('Cannot place inner ring outside polygon.');
          }
        } else {
          // adding to outer ring. go wild.
          ring.push(at);
        }
      }

      state.selectedVertexIdx = ring.length - 1;
      state.isDragging = true;
    },

    deleteVertex(state) {
      // TODO: check that we actually have a vertex selected

      if (
        state.selectedPolygonIdx === undefined ||
        state.selectedRingIdx === undefined ||
        state.selectedVertexIdx === undefined
      ) {
        Logging.warn("Can't delete a vertex without a vertex selected");
        return;
      }
      const ring = state.polygons[state.selectedPolygonIdx][state.selectedRingIdx];

      ring.splice(state.selectedVertexIdx, 1);

      // Select the next index in the list
      const mod = (x: number, n: number) => ((x % n) + n) % n;
      const newSelectedIdx =
        ring.length > 0 ? mod(state.selectedVertexIdx - 1, ring.length) : undefined;

      state.selectedVertexIdx = newSelectedIdx;

      // in the case of polygons, if deleting this vertex would drop the ring to size 2 or fewer, we have to go into adding mode.
      if (state.type == 'polygon' && ring.length < 3) {
        state.isAdding = true;
      }

      // for all other  shapes this happens if you get to 1.
      if (state.type != 'polygon' && ring.length == 1) {
        state.isAdding = true;
      }

      if (ring.length == 0) {
        // if we just deleted the last vertex of the outer ring, make sure to get rid of teh whole polygon.
        if (state.selectedRingIdx == 0) {
          state.polygons[state.selectedPolygonIdx] = [];
        }

        // if we delete the last vertex, just stop.
        state.isAdding = false;
        state.isDragging = false;
        state.selectedPolygonIdx = undefined;
        state.selectedRingIdx = undefined;
        state.selectedVertexIdx = undefined;
      }

      util.tidyUpState(state);
    },
    // turns all the meta shapes into polygon shapes
    polygonize(state) {
      if (state.type != 'rectangle' && state.type != 'circle') {
        Logging.warn('Cannot polygonize this shape', { type: state.type });
        return;
      }

      const features = util.usershapeToFeatures(
        state.polygons,
        false,
        state.type,
        undefined,
        undefined
      );
      const feature = util.unionFeatures(features);

      if (!feature?.geometry) {
        Logging.warn('Cannot polygonize, no geometry');
        return;
      }

      const { type, polygons } = util.getPolygonsFromFeature(feature);

      state.isAdding = false;
      state.isDragging = false;
      state.selectedPolygonIdx = undefined;
      state.selectedRingIdx = undefined;
      state.selectedVertexIdx = undefined;
      state.type = type;
      state.polygons = polygons;

      util.tidyUpState(state);
    },

    // simplifies the polygons to some specified tolerance, removes kinks, etc.
    simplify(state, action: PayloadAction<{ tolerance: number }>) {
      const { tolerance } = action.payload;

      if (state.type != 'polygon') {
        Logging.warn('Cannot simplify this shape', { type: state.type });
        return;
      }

      const features = util.usershapeToFeatures(
        state.polygons,
        false,
        state.type,
        undefined,
        undefined
      );
      const feature = util.unionFeatures(features);

      if (!feature?.geometry) {
        Logging.warn('Cannot simplfy, no geometry');
        return;
      }

      const beforePts = turf.explode(feature).features.length;

      const simplefeature = simplify(feature, tolerance / 111320);

      const afterPts = turf.explode(simplefeature).features.length;

      const removed = beforePts - afterPts;
      if (removed > 0) {
        showSnackbar('success', `Removed ${removed} ${removed == 1 ? 'vertex' : 'vertices'}.`);
      } else {
        showSnackbar('success', 'Shape already simple.');
      }

      const { type, polygons } = util.getPolygonsFromFeature(simplefeature);

      state.isAdding = false;
      state.isDragging = false;
      state.selectedPolygonIdx = undefined;
      state.selectedRingIdx = undefined;
      state.selectedVertexIdx = undefined;
      state.type = type;
      state.polygons = polygons;

      util.tidyUpState(state);
    },
  },
});

export default slice.reducer;
export const actions = slice.actions;

export const getUsershapeFeatures = createSelector(
  (state: RootState) => state.usershape.polygons,
  (state: RootState) => state.usershape.isAdding,
  (state: RootState) => state.usershape.type,
  (state: RootState) => state.usershape.selectedPolygonIdx,
  (state: RootState) => state.usershape.selectedRingIdx,
  util.usershapeToFeatures
);

export const getUsershapeFeatureReduced = createSelector(getUsershapeFeatures, (features) => {
  try {
    if (features[0].geometry.type == 'Point' || features[0].geometry.type == 'LineString') {
      return features[0];
    }
    const rval = util.unionFeatures(features);
    return rval;
  } catch {
    return undefined;
  }
});

export const getUsershapeWKT = createSelector(getUsershapeFeatureReduced, (feature) => {
  return feature ? wkt.stringify(feature.geometry as wkt.GeoJSONGeometry) : undefined;
});

// returns a feature collection representing the vertex control points
export const getUsershapeVertexFeature = createSelector(
  (state: RootState) => state.usershape.polygons,
  (state: RootState) => state.usershape.selectedPolygonIdx,
  (state: RootState) => state.usershape.selectedRingIdx,
  (state: RootState) => state.usershape.selectedVertexIdx,
  (state: RootState) => state.usershape.type,
  (state: RootState) => state.usershape.isAdding,
  (polygons, selectedPolygonIdx, selectedRingIdx, selectedVertexIdx, type, isAdding) => {
    const features: Feature[] = [];

    if (type == 'point' && polygons.length > 0) {
      const vertex = polygons[0][0][0] as unknown;
      return turf.point(vertex as Position);
    }

    polygons.forEach((polygon, polygonIdx) =>
      polygon.forEach((ring, ringIdx) =>
        ring.forEach((vertex, vertexIdx) => {
          features.push(
            turf.point(vertex, {
              polygonIdx,
              ringIdx,
              vertexIdx,
              selectedVertex:
                polygonIdx == selectedPolygonIdx &&
                ringIdx == selectedRingIdx &&
                vertexIdx == selectedVertexIdx,
              selectedRing: polygonIdx == selectedPolygonIdx && ringIdx == selectedRingIdx,
              canCompleteAt:
                type == 'polygon' &&
                isAdding &&
                polygonIdx == selectedPolygonIdx &&
                ringIdx == selectedRingIdx &&
                vertexIdx == 0,
            })
          );
        })
      )
    );

    return turf.featureCollection(features);
  }
);

// returns a feature collection representing the midpoint control points for polygons/lines
export const getUsershapeMidpointFeature = createSelector(
  (state: RootState) => state.usershape.type,
  (state: RootState) => state.usershape.polygons,
  (type, polygons) => {
    if (type != 'polygon' && type != 'line') return undefined;

    const features: Feature[] = [];
    polygons.forEach((polygon, polygonIdx) =>
      polygon.forEach((ring, ringIdx) =>
        util.getMidpoints(ring, type == 'polygon').forEach((vertex, vertexIdx) => {
          features.push(
            turf.point(vertex, {
              polygonIdx,
              ringIdx,
              vertexIdx: vertexIdx + 0.5, // cause we're in the middle
            })
          );
        })
      )
    );

    return turf.featureCollection(features);
  }
);

export const getUsershapeValid = createSelector(
  (state) => state.usershape.type,
  getUsershapeFeatures,
  (type, features) => {
    const kinks = features.reduce((acc, x) => {
      if (x.geometry.type == 'Polygon') {
        return turf.kinks(x as Feature<Polygon>).features.length + acc;
      } else if (x.geometry.type == 'MultiPolygon') {
        return turf.kinks(x as Feature<MultiPolygon>).features.length + acc;
      } else return acc;
    }, 0);
    return features.length == 0 || (type == 'polygon' ? kinks == 0 : true);
  }
);

export const getUsershapeEditing = (state: RootState) =>
  state.usershape.isDragging || state.usershape.isAdding;
export const getUsershapeDragging = (state: RootState) => state.usershape.isDragging;
export const getUsershapeAdding = (state: RootState) => state.usershape.isAdding;
