import * as React from 'react';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  MenuItem,
  TextField,
  Divider,
  FormControl,
  InputLabel,
  Select,
  Input,
} from '@material-ui/core';

import { Asset, Region } from '~/schema';
import { RequestType } from '~/schema/TargetRequest';
import targetRequestControls from './targetRequestControls';

interface Props {
  open: boolean;
  onClose: () => void;
  createTrq: (type: RequestType, assets: Asset[], region?: Region) => void;
  regions: Region[];
  assets: Asset[];
}

export default function AddTargetDialog(props: Props) {
  const [selectedTargetType, setSelectedTargetType] = React.useState<RequestType | null>(null);
  const [selectedAssets, setSelectedAssets] = React.useState<number[]>([]);
  const [selectedRegion, setSelectedRegion] = React.useState<number | null>(null);

  const ready = selectedTargetType != null && (selectedAssets.length > 0 || selectedRegion != null);

  return (
    <Dialog fullWidth maxWidth='xs' open={props.open} onClose={props.onClose}>
      <DialogTitle>Add Target</DialogTitle>
      <DialogContent>
        <TextField
          select
          fullWidth
          value={selectedTargetType}
          label='Behavior'
          onChange={(e) => setSelectedTargetType(e.target.value as RequestType)}>
          {(Object.keys(targetRequestControls) as RequestType[]).map((rqType) => (
            <MenuItem key={rqType} value={rqType}>
              {targetRequestControls[rqType].name}
            </MenuItem>
          ))}
        </TextField>
        <Divider />
        {selectedTargetType !== null &&
          targetRequestControls[selectedTargetType].operatesOn.includes('region') && (
            <TextField
              select
              fullWidth
              value={selectedRegion?.toString() || 'none'}
              label='Region to target'
              onChange={(e) =>
                setSelectedRegion(e.target.value == 'none' ? null : parseInt(e.target.value))
              }>
              {props.regions.map((region) => (
                <MenuItem key={region.id} value={region.id}>
                  {region.type} {region.display_name}
                </MenuItem>
              ))}

              <MenuItem value={'none'}>None (updown)</MenuItem>
            </TextField>
          )}
        {selectedTargetType !== null &&
          targetRequestControls[selectedTargetType].operatesOn.includes('assets') && (
            <FormControl fullWidth variant='filled'>
              <InputLabel id='asset-select-label'>Asset(s) to target</InputLabel>
              <Select
                labelId='asset-select-label'
                multiple
                value={selectedAssets}
                onChange={(e) => setSelectedAssets(e.target.value as number[])}
                input={<Input />}>
                {props.assets.map((asset) => (
                  <MenuItem key={asset.id} value={asset.id}>
                    {/* This needs to be a single string or the select displays it with commas... */}
                    {`${asset.type}:${asset.display_name}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!ready}
          onClick={() => {
            if (ready) {
              props.createTrq(
                selectedTargetType!, // I can assert this because we check with `ready`
                selectedAssets.map((x) => props.assets.find((a) => a.id == x)!),
                props.regions.find((r) => r.id == selectedRegion)
              );
              props.onClose();
            }
          }}
          color='primary'>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
