import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Icons from '~/icons';
import { getColorForNDVI } from '~/lib/colorScales';
import * as geography from '~/lib/geography';
import { showConfirmDialog } from '~/lib/modalServices';
import { actions as globalActions } from '~/redux/slices/global';
import { actions as uiActions } from '~/redux/slices/ui';
import ColoredButton from '~/shared/ColoredButton';
import ColoredCheckbox from '~/shared/ColoredCheckbox';
import { regionColors } from '~/theme';
import { areaUnitText, areatoPref } from '~/lib/units';
import { useSVSelector } from '~/redux/hooks';
import {
  Avatar,
  CircularProgress,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import CreateZoneControls from './CreateZoneControls';
import { getVisibleZones, getZoneSummaries, zonesForField } from './selectors';
import { actions } from './slice';

const styles = makeStyles((theme) => ({
  buttonHeader: {
    background: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'flex-start',
    padding: theme.spacing(1),
  },
}));

export default function ZoneControls({ field, canEdit }) {
  const classes = styles();
  const dispatch = useDispatch();
  const zones = useSelector(zonesForField);
  const visibleZones = useSelector(getVisibleZones);
  const mapState = useSelector((state) => state.fieldScene.mapState);

  const showZoneGrid = useSelector((state) => state.global.preferences.showZoneGrid);

  const colorForNDVI = useSelector(getColorForNDVI);

  const zoneSummaries = useSelector(getZoneSummaries);

  const analysisMode = useSelector((state) => state.global.preferences.analysisMode);

  const [isDeleting, setDeleting] = React.useState(false);

  const areaUnit = useSVSelector((state) => state.global.preferences.areaUnit);

  // TODO: function here for changing zone visibility, clear all, select all
  const clearVisibleZones = () => {
    dispatch(
      globalActions.setZoneVisibility({
        zoneIds: zones.map((z) => z.id),
        visible: false,
      })
    );
  };

  const showAllZones = () => {
    dispatch(
      globalActions.setZoneVisibility({
        zoneIds: zones.map((z) => z.id),
        visible: true,
      })
    );
  };

  const deleteZone = (zid) => {
    dispatch(actions.deleteZone({ fieldId: field.id, zid }));
  };

  return (
    <>
      <ListItem dense>
        <Typography variant='caption'>
          Zones are areas within your field that are analyzed in isolation, such as separate plots
          or areas with different physical conditions.
        </Typography>
      </ListItem>

      <Collapse in={mapState != 'drawZone'}>
        <div className={classes.buttonHeader}>
          {canEdit && (
            <Tooltip title='Create zone'>
              <IconButton
                size='small'
                style={{ color: regionColors.ZONE }}
                onClick={() => dispatch(actions.setMapState('drawZone'))}>
                <Icons.Add />
              </IconButton>
            </Tooltip>
          )}

          {canEdit && (
            <Tooltip title='Delete zones'>
              <IconButton
                size='small'
                style={{ margin: 8, color: isDeleting ? red[600] : 'default' }}
                onClick={() => setDeleting(!isDeleting)}>
                <Icons.Delete />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title={showZoneGrid ? 'Hide grid within zone' : 'Show grid within zone'}>
            <IconButton
              size='small'
              style={{ margin: 8 }}
              color={showZoneGrid ? 'primary' : 'default'}
              onClick={() =>
                dispatch(
                  globalActions.setPreference({
                    showZoneGrid: !showZoneGrid,
                  })
                )
              }>
              <Icons.Analysis />
            </IconButton>
          </Tooltip>

          <div style={{ flexGrow: 1 }} />
          {visibleZones?.length > 0 ? (
            <Tooltip title='Hide all'>
              <IconButton style={{ marginRight: 13 }} size='small' onClick={clearVisibleZones}>
                <Icons.Close />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title='Show all'>
              <IconButton style={{ marginRight: 13 }} size='small' onClick={showAllZones}>
                <Icons.SelectAll />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <List>
          {zones?.length > 0 ? (
            zones.map((z) => {
              const summary = zoneSummaries[z.id];

              return (
                <ListItem
                  key={z.id}
                  dense
                  button
                  onClick={() => {
                    dispatch(uiActions.zoomToBounds(geography.bboxOfWKT(z.boundary)));
                  }}>
                  <ListItemAvatar>
                    {summary?.summary ? ( // TODO: Might need to make this more custom for each analysis type. have a function to return the whole avatar?
                      <ListItemAvatar>
                        <Avatar
                          style={{
                            backgroundColor: colorForNDVI(summary?.summary),
                          }}>
                          {Math.round(summary.summary * 100)}
                        </Avatar>
                      </ListItemAvatar>
                    ) : summary?.processing ? (
                      <Tooltip title={`Processing analytics for this dataset`}>
                        <CircularProgress variant='indeterminate' />
                      </Tooltip>
                    ) : analysisMode[0] == null ? (
                      <Tooltip title={`Select analysis overlay to see zone breakdown`}>
                        <Avatar>
                          <Icons.Analysis />
                        </Avatar>
                      </Tooltip>
                    ) : (
                      <Tooltip title={`No ${analysisMode[0]} for this zone from this imagery`}>
                        <Avatar>?</Avatar>
                      </Tooltip>
                    )}
                  </ListItemAvatar>

                  <ListItemText
                    primary={z.display_name}
                    secondary={`${geography
                      .areaOfWKT(areatoPref(areaUnit), z.boundary)
                      .toFixed(2)} ${areaUnitText(areaUnit)}`}
                  />
                  <ListItemSecondaryAction>
                    {isDeleting ? (
                      <Tooltip title='Delete this zone'>
                        <IconButton
                          style={{ color: red[600] }}
                          onClick={async () => {
                            if (
                              await showConfirmDialog(
                                'Delete Zone',
                                `Do you want to delete zone ${z.display_name}? This cannot be undone.`,
                                'Delete'
                              )
                            ) {
                              // delete this zone
                              deleteZone(z.id);
                            }
                          }}>
                          <Icons.Delete />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title='Zone visibility'>
                        <ColoredCheckbox
                          color={regionColors.ZONE}
                          checked={visibleZones?.includes(z.id) || false}
                          onChange={(e) =>
                            dispatch(
                              globalActions.setZoneVisibility({
                                zoneIds: [z.id],
                                visible: e.target.checked,
                              })
                            )
                          }
                        />
                      </Tooltip>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })
          ) : (
            <ListItem dense>
              <Typography variant='caption' color='error'>
                No zones in {field.display_name}
              </Typography>
            </ListItem>
          )}
        </List>
      </Collapse>
      <Collapse in={mapState == 'drawZone'} unmountOnExit>
        <CreateZoneControls onCancel={() => dispatch(actions.setMapState('zoneMode'))} />
      </Collapse>
    </>
  );
}
