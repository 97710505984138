import React from 'react';

import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';

class NewParameterDialog extends React.Component {
  state = {
    isOpen: false,
    newKey: '',
    value: '',
  };

  handleCancel = () => {
    this.setState({ isOpen: false });
    this.resolve(false);
  };

  handleConfirm = () => {
    const { newKey, value } = this.state;
    this.resolve({ newKey, value });
    this.setState({
      newKey: '',
      value: '',
      isOpen: false,
    });
  };

  show = () => {
    this.setState({ isOpen: true });
    return new Promise((res) => {
      this.resolve = res;
    });
  };

  render() {
    const { isOpen, newKey, value } = this.state;

    return (
      <Dialog open={isOpen} onClose={this.handleCancel}>
        <DialogTitle>New Parameter</DialogTitle>

        <DialogContent>
          <TextField
            fullWidth
            label='Parameter Name'
            value={newKey}
            onChange={(e) =>
              this.setState({
                ...this.state,
                newKey: e.target.value,
              })
            }
          />
          <TextField
            fullWidth
            label='Parameter Value'
            value={value}
            onChange={(e) =>
              this.setState({
                ...this.state,
                value: e.target.value,
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel} color='primary'>
            Cancel
          </Button>
          <Button onClick={this.handleConfirm} color='primary' disabled={!newKey}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default NewParameterDialog;
