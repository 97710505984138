import { DateTime } from 'luxon';
import * as api from '~/lib/api';
import { serlializeUTCISO } from '~/lib/time';
import Notification from '~/schema/Notification';
import type { SVThunkAction } from '~/store';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface NotificationsState {
  all: Notification[];
}

const initialState = {
  all: [],
} as NotificationsState;

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    updateNotification(state, action: PayloadAction<Notification>) {
      state.all = [...state.all.filter((x) => x.id != action.payload.id), action.payload];
    },
    updateAllNotifications(state, action: PayloadAction<Notification[]>) {
      state.all = action.payload;
    },
  },
});

export default slice.reducer;

export const update = (): SVThunkAction => async (dispatch) => {
  try {
    const result = (await api.webrequest('GET', 'notifications')) as {
      notifications: Notification[];
    };
    //dispatch(slice.actions.updateAllNotifications(result.notifications || []));
  } catch (e) {
    // Depending on the status?
    // Logging.error('Error retrieving notifications', e)
  }
};

export const markAsRead =
  (notif: Notification, read: boolean): SVThunkAction =>
  async (dispatch) => {
    try {
      if (read) {
        if (notif.read_at == null) {
          notif.read_at = serlializeUTCISO(DateTime.now());
          dispatch(slice.actions.updateNotification(notif));
          await api.webrequest('PUT', `notifications/${notif.id}`, notif);
        }
      } else {
        notif.read_at = undefined;
        dispatch(slice.actions.updateNotification(notif));
        await api.webrequest('PUT', `notifications/${notif.id}`, notif);
      }
    } catch (e) {
      // Logging.error('Error putting notifications', e);
    }
  };

export const markAllAsRead = (): SVThunkAction => async (dispatch, getState) => {
  try {
    const all = getState().notifications.all;
    const now = serlializeUTCISO(DateTime.now());

    dispatch(
      slice.actions.updateAllNotifications(
        all.map((n) => ({ ...n, read_at: n.persistent ? n.read_at : now }))
      )
    );

    await api.webrequest('POST', `notifications/readAll`);
  } catch (e) {
    // Logging.error('Error reading all notifications', e);
  }
};

export const actions = {
  ...slice.actions,
  update,
  markAsRead,
  markAllAsRead,
};
