import React, { useState, useEffect } from 'react';
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { TreeItem, TreeView } from '@material-ui/lab';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import * as Icons from '~/icons';
import history from '~/lib/history';
import RightPanel from '~/shared/RightPanel';
import { repaintMaps } from '~/lib/globalMapAccess';
import {
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon,
  Asset as AssetIcon,
} from '~/icons';
import { actions } from './slice';
import { regionColors } from '~/theme';
import { useSVDispatch, useSVSelector } from '~/redux/hooks';
import { getQueryOrgAndSite } from '~/redux/selectors/global';
import { Asset } from '~/schema';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    module: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    treeItemselected: {
      backgroundColor: regionColors['FIELD'],
    },
  })
);

const AssetsTreeViewPanel = () => {
  const [treeContextMenuAnchor, setTreeContextMenuAnchor] = useState<Element | null>(null);
  const [expandedNodes, setExpandedNodes] = useState(['0']);
  const [selectedTreeViewNodeId, setSelectedTreeViewNodeId] = useState('');
  const { siteId } = useSVSelector((state) => getQueryOrgAndSite(state));
  const selectedAssets = useSVSelector((state) => state.assetsScene.selectedAssets);
  const siteAssets = Object.values(useSVSelector((state) => state.assetsScene.assets));
  const dispatch = useSVDispatch();
  const classes = useStyles();

  useEffect(() => {
    // OnMount side effects
    if (siteId && siteAssets.length === 0) {
      dispatch(actions.fetchAssetsBySiteId(siteId));
    }
    return (
      //onDismount side effects
      () => {
        dispatch(actions.setSelectedAssets([]));
        dispatch(actions.setHighlightedAssets([]));
      }
    );
  }, [siteId]);

  const handleAssetRightClick = (args: { asset: Asset; event: React.MouseEvent }) => {
    const { asset, event } = args;
    event.preventDefault();
    event.stopPropagation();
    setTreeContextMenuAnchor(event.currentTarget);
    dispatch(actions.setSelectedAssets([asset]));
  };

  const handleMenuClose = () => setTreeContextMenuAnchor(null);

  const selectedAsset = selectedAssets.length > 0 ? selectedAssets[0] : null;
  const visited: number[] = [];
  let nodeId = 0;
  const nodeIdByAssetId: { [key: string]: number } = {};

  const createTreeItemForAsset = (a: Asset) => {
    if (!a) {
      return;
    }

    visited.push(a.id);
    nodeId += 1;
    nodeIdByAssetId[`${a.id}`] = nodeId;

    return (
      <TreeItem
        classes={{ selected: classes.treeItemselected }}
        nodeId={`${nodeId}`}
        onLabelClick={(e) => {
          e.stopPropagation();
          setSelectedTreeViewNodeId(nodeIdByAssetId[a.id].toString());
          dispatch(actions.setSelectedAssets([a]));
        }}
        key={a.id}
        endIcon={<AssetIcon />}
        label={a.display_name}
        onContextMenu={(e) => handleAssetRightClick({ asset: a, event: e })}
        onMouseEnter={() => {
          dispatch(actions.setHighlightedAssets([a]));
          repaintMaps();
        }}
        onMouseLeave={() => {
          dispatch(actions.setHighlightedAssets([]));
          repaintMaps();
        }}>
        {siteAssets
          .filter((child) => child.parent_asset_id === a.id)
          .map((child) => {
            return createTreeItemForAsset(child);
          })}
      </TreeItem>
    );
  };

  return (
    <RightPanel title={siteId || 'Edit assets'} noScroll>
      <Menu
        id='asset-context-menu'
        anchorEl={treeContextMenuAnchor}
        open={Boolean(treeContextMenuAnchor)}
        onClose={() => setTreeContextMenuAnchor(null)}>
        <MenuItem
          onClick={() => {
            if (selectedAsset) {
              history.push(`/assets?site=${siteId}&assetid=${selectedAsset.id}`);
            }
            handleMenuClose();
          }}>
          Edit Asset
        </MenuItem>
      </Menu>
      {siteId ? (
        <>
          <List>
            <ListItem
              button
              onClick={() => history.push(`/assets?site=${siteId}&assetid=new`)}
              dense>
              <ListItemIcon>
                <Icons.Add />
              </ListItemIcon>
              <ListItemText primary={'Create Asset'} />
            </ListItem>
          </List>
          <Divider />
          <TreeView
            selected={selectedTreeViewNodeId}
            onNodeToggle={(e, n) => setExpandedNodes(n)}
            expanded={expandedNodes}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            style={{
              flexGrow: 1,
              overflowY: 'auto',
              overflowX: 'hidden',
              maxHeight: '100%',
              paddingLeft: 8,
            }}>
            <TreeItem
              onLabelClick={() => dispatch(actions.setSelectedAssets([]))}
              endIcon={<AssetIcon />}
              label={siteId}
              nodeId={'0'}>
              {siteAssets
                .filter((a) => !visited.includes(a.id) && !a.parent_asset_id)
                .map((a) => createTreeItemForAsset(a))}
            </TreeItem>
          </TreeView>
        </>
      ) : (
        <Typography variant='body1'>Select a site to view its assets</Typography>
      )}
    </RightPanel>
  );
};

export default AssetsTreeViewPanel;
