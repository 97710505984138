import React from 'react';
import { connect } from 'react-redux';
import FilterBar from '~/shared/FilterBar';

import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import NewPartDialog from '../../shared/NewPartDialog';
import PartsTable from './PartsTable';
import { getQuery } from './selectors';
import { actions } from './slice';

const styles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    background: theme.palette.background.default,
    maxHeight: '100%',
    maxWidth: '100%',
    padding: theme.spacing(1),
  },
  actionBar: {
    display: 'flex',
    padding: [[8, 16]],
    marginBottom: theme.spacing(1),
    justifyContent: 'space-between',
  },
  tableArea: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 1,
  },
  filterBar: {
    // flexGrow: 1,
    marginRight: theme.spacing(1),
  },
}));

function HardwarePage({ searchStr, setSearchStr, query, requestRows }) {
  const classes = styles();

  const [showPartDialog, setShowPartDialog] = React.useState(false);

  return (
    <div className={classes.root}>
      <Paper className={classes.actionBar}>
        <FilterBar
          placeholder='Search Hardware'
          value={searchStr}
          onChange={setSearchStr}
          className={classes.filterBar}
        />

        <div style={{ flexGrow: 1 }} />
        <Button
          color='primary'
          onClick={() => {
            setShowPartDialog(true);
          }}>
          Create New Parts
        </Button>
      </Paper>

      <Paper className={classes.tableArea}>
        <PartsTable />
      </Paper>

      <NewPartDialog
        allowMultiple
        show={showPartDialog}
        onClose={(createdPart) => {
          if (createdPart) {
            requestRows(query); // TODO: This, or navigate to the part page?
          }
          setShowPartDialog(false);
        }}
      />
    </div>
  );
}
export default connect(
  (state) => ({
    searchStr: state.hardwareScene.searchStr,
    query: getQuery(state),
  }),
  (dispatch) => ({
    setSearchStr: (x) => dispatch(actions.setSearchStr(x)),
    requestRows: (q) => dispatch(actions.requestRows(q)),
  })
)(HardwarePage);
