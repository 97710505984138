import * as React from 'react';

import ContinuousControls from './ContinuousControls';
import SamplingControls from './SamplingControls';
import type Props from './trqControlProps';

import type { RequestType } from '~/schema/TargetRequest';
import type TargetRequest from '~/schema/TargetRequest';

import { Typography } from '@material-ui/core';
import AssetOrbitControls from './AssetOrbitControls';

function displayInFeet(m: number) {
  return `${Math.round((m * 3.28084) / 5) * 5}'`;
}

interface ControlsUIType {
  name: string; // How we display it to the user
  Subtitle: React.FC<{ trq: TargetRequest }>;
  Control: React.FC<Props>;
  operatesOn: ('region' | 'assets')[];
}

const controls: Record<RequestType, ControlsUIType> = {
  CONTINUOUS: {
    name: 'Continuous',
    operatesOn: ['region', 'assets'],
    Subtitle: function Subtitle({ trq }) {
      return (
        <Typography variant='subtitle2'>
          Orthomosaic @ {displayInFeet(trq.altitude || 0)}
        </Typography>
      );
    },
    Control: ContinuousControls,
  },
  CONTINUOUS_RAW: {
    name: 'Continuous Raw',
    operatesOn: ['region', 'assets'],
    Subtitle: function Subtitle({ trq }) {
      return (
        <Typography variant='subtitle2'>
          Orthomosaic @ {displayInFeet(trq.altitude || 0)}
        </Typography>
      );
    },
    Control: ContinuousControls,
  },
  SAMPLING: {
    name: 'Sampling',
    operatesOn: ['region', 'assets'],
    Subtitle: function Subtitle({ trq }) {
      return <Typography variant='subtitle2'>Sampling Mission</Typography>;
    },
    Control: SamplingControls,
  },
  ASSET_ORBIT: {
    name: 'Asset Orbit',
    operatesOn: ['assets'],
    Subtitle: function Subtitle({ trq }) {
      return <Typography variant='subtitle2'>Asset Orbit Mission</Typography>;
    },
    Control: AssetOrbitControls,
  },
};

export default controls;
