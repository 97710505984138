import React from 'react';

import { Fade, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// import DroneProgress from '~/shared/DroneProgress' // to display while loading global data

const styles = makeStyles((theme) => ({
  progressBackground: {
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',

    top: 0,
    bottom: 0,
    width: '100%',
    overflow: 'hidden',

    zIndex: -3,
    background: theme.palette.secondary.dark,

    backgroundImage:
      'linear-gradient(rgba(255,255,255,0.03) 1px, transparent 1px), \
      linear-gradient(90deg, rgba(255,255,255,.03) 1px, transparent 1px); \
      background-size: 100px 50px, 50px 100px, 20px 20px, 20px 20px; \
      background-position:-2px -2px, -2px -2px, -1px -1px, -1px -1px;',
  },

  bgimage: {
    position: 'fixed',

    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: 'hidden',
    zIndex: -1,

    backgroundImage: 'url(/images/scout-bg.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    filter: 'saturate(25%) brightness(20%)',

    opacity: 0.5,
  },

  imageLow: {
    zIndex: -2,
    position: 'absolute',
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },

  showImage: {
    opacity: 1,
  },
  hideImage: {
    opacity: 0,
  },

  imageHigh: {
    transition: '0.3s opacity linear',
    zIndex: -1,
    position: 'absolute',
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));

export default function StandbyPage({
  showPhoto,
  children,
}: {
  showPhoto: boolean;
  children?: React.ReactNode;
}) {
  const classes = styles();
  return (
    <div className={classes.progressBackground}>
      <Hidden xsDown>
        <Fade in={showPhoto} mountOnEnter unmountOnExit>
          <div>
            <div className={classes.bgimage} />
          </div>
        </Fade>
      </Hidden>
      {children}
    </div>
  );
}
