import classNames from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';
import { connect } from 'react-redux';
import * as Icons from '~/icons';
import history from '~/lib/history';
import { parseUTCISO } from '~/lib/time';

import {
  Button,
  Dialog,
  DialogActions,
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { amber, blue, green, red } from '@material-ui/core/colors';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import ExpandMore from '@material-ui/icons/ExpandMore';

function ForceAddReportDialog({ open, onClose, doAddReport }) {
  const [type, setType] = React.useState('MANUFACTURE');
  const [files, setFiles] = React.useState([]);

  function humanFileSize(size) {
    var i = Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
  }

  const uploadFile = ({ target }) => {
    setFiles([...(files || []), ...target.files]);
  };

  const removeFile = (file) => {
    setFiles(files.filter((f) => f != file));
  };

  const TYPES = {
    MANUFACTURE: 'Manufacture Report',
    BRINGUP: 'Bringup report',
    MAINTENANCE: 'Maintenance report',
    DEFECT: 'Defect report',
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='sm'>
      <input type='file' onChange={uploadFile} id='upload-part-report' multiple hidden />
      <List dense>
        <ListItem>
          <TextField
            select
            label='Type'
            fullWidth
            value={type}
            onChange={(e) => setType(e.target.value)}>
            {Object.keys(TYPES).map((t) => (
              <MenuItem key={t} value={t}>
                {TYPES[t]}
              </MenuItem>
            ))}
          </TextField>
        </ListItem>

        {files.map((file, i) => (
          <ListItem key={i}>
            <ListItemText
              primary={file.name}
              secondary={`${file.type}, ${humanFileSize(file.size)}`}
            />
            <ListItemSecondaryAction>
              <IconButton onClick={() => removeFile(file)}>
                <Icons.Delete />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <DialogActions>
        <Button
          htmlFor='upload-part-report'
          color='primary'
          component='label'
          type='submit'
          style={{ margin: 4 }}>
          Attach Report Files
        </Button>
        <Button
          color='primary'
          disabled={files.length == 0}
          onClick={() => doAddReport('MANUFACTURE', files)}>
          Submit Report
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = makeStyles((theme) => ({
  content: { justifyContent: 'space-between' },
}));

function ReportCard({ r, open, toggleOpen, current }) {
  const latest_comment =
    r.comments?.length > 0 ? parseUTCISO(r.comments[r.comments.length - 1].timestamp) : null;

  const classes = styles();

  return (
    <>
      <ExpansionPanel
        expanded={open}
        onChange={toggleOpen}
        style={{
          width: '100%',
          maxWidth: '100%',
        }}>
        <ExpansionPanelSummary expandIcon={<ExpandMore />} classes={{ content: classes.content }}>
          {current ? (
            <Typography variant='h6'>
              <b>{r.type} Report (Active)</b>
            </Typography>
          ) : (
            <Typography variant='h6'>{r.type} Report</Typography>
          )}
          <Typography variant='subtitle1'>
            {latest_comment?.toLocaleString(DateTime.DATETIME_SHORT)}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ flexDirection: 'column' }}>
          {r.comments?.map((c) => (
            <React.Fragment key={c.id}>
              <Typography component='p' variant='caption'>
                {c.author} @ {parseUTCISO(c.timestamp).toLocaleString(DateTime.DATETIME_SHORT)}
              </Typography>
              <Typography component='p' variant='body2'>
                {c.content}
              </Typography>
              <ul>
                {c.attachment_names.map((name, i) => (
                  <li key={i}>
                    <a href={`/api/sv/hardware/comment/${c.id}/attachment/${name}`}>{name}</a>{' '}
                    <em>({c.attachment_types[i]})</em>
                  </li>
                ))}
              </ul>
            </React.Fragment>
          ))}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </>
  );
}

export default function ReportSheet({ report, old_reports, doAddReport }) {
  const sorted_reports = [...old_reports].sort((a, b) => b.id - a.id);

  const [forceAddOpen, setForceAddOpen] = React.useState(false);

  const [open, setOpen] = React.useState(null);

  return (
    <Paper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 8,
        }}>
        <Typography variant='h6'>Attached Reports</Typography>
        <Button color='primary' onClick={() => setForceAddOpen(true)}>
          Add Extra Report
        </Button>
        <ForceAddReportDialog
          open={forceAddOpen}
          doAddReport={doAddReport}
          onClose={() => setForceAddOpen(false)}
        />
      </div>
      {report ? (
        <ReportCard
          r={report}
          current
          open={open === 0}
          toggleOpen={() => setOpen(open == 0 ? null : 0)}
        />
      ) : (
        <List>
          <ListItem>
            <Typography variant='caption'>No open report</Typography>
          </ListItem>
        </List>
      )}
      {sorted_reports.map((r, i) => (
        <ReportCard
          key={i}
          r={r}
          open={open === i + 1}
          toggleOpen={() => setOpen(open == i + 1 ? null : i + 1)}
        />
      ))}
    </Paper>
  );
}
``;
