import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as Icons from '~/icons';
import { resolutionAt } from '~/lib/geography';

import { Button, Grow, Typography } from '@material-ui/core';

import MapContext from '../mapContext';
import DraggableMarker from './DraggableMarker';

export default connect((state) => ({
  showBubbles: state.global.preferences.showTgtBubbles && state.ui.viewport.zoom > 13.5,
  showDetailedBubbles: state.global.preferences.showTgtBubbles && state.ui.viewport.zoom > 16,
}))(
  withRouter(
    ({
      coordinate,
      altitude,
      timestamp,
      visible,
      onClick,
      showBubbles,
      showDetailedBubbles,
      ...other
    }) => {
      const button = (
        <Button
          color='primary'
          variant='contained'
          size='small'
          style={{ minWidth: 32, padding: 4 }}>
          {showDetailedBubbles ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}>
              <Typography variant='caption' color='inherit'>
                Targeted Survey
              </Typography>
              {timestamp}
              <Typography variant='caption' style={{ textTransform: 'none' }} color='inherit'>
                {resolutionAt(altitude)}
              </Typography>
            </div>
          ) : (
            <Icons.Targeted />
          )}
        </Button>
      );

      return (
        <MapContext.Consumer>
          {(map) => (
            <DraggableMarker
              coordinate={coordinate}
              map={map}
              onClick={showBubbles && visible ? onClick : undefined}
              {...other}>
              <Grow in={showBubbles && visible} mountOnEnter unmountOnExit appear>
                {button}
              </Grow>
            </DraggableMarker>
          )}
        </MapContext.Consumer>
      );
    }
  )
);
