import { Ellipse } from '~/schema/TargetRequest';
import * as wkt from 'wellknown';
import * as turf from '@turf/turf';

function getEllipsePointCalculator(ellipse: Ellipse) {
  // Find the average of the focii. This is the center.
  const focii = ellipse.ellipse_focii.map(
    (ptstr) => wkt.parse(ptstr)!.coordinates as GeoJSON.Position
  );

  const center = [(focii[0][0] + focii[1][0]) / 2, (focii[0][1] + focii[1][1]) / 2];

  const factor = Math.cos((center[1] * Math.PI) / 180);

  // Find the focus coordinates in meters relative to the center.
  // The X direction must be divided out by the cosine of the latitude to account for Earth being an inconvenient shape.
  const f1 = [(focii[0][0] - center[0]) * 111111 * factor, (focii[0][1] - center[1]) * 111111];
  // Figure out the major and semimajor axes of the ellipse in meters.
  const c = Math.sqrt(f1[0] * f1[0] + f1[1] * f1[1]); // linear eccentricity
  const a = c + ellipse.radius_m; // semi-major axis
  const b = Math.sqrt(a * a - c * c); // semi-minor axis

  // Note that these values are just the lengths of the axes; the axes are really vectors.
  const fAngle = Math.atan2(f1[1], f1[0]); // This is the angle from the center to F1, what we consider zero

  return (aDeg: number): [number, number] => {
    // Given the angle around teh ellipse, return the coordinates in local space for this point.
    // we have the 90-x here to convert from math angles to geographic angles.
    const aRad = ((90 - aDeg) * Math.PI) / 180 - fAngle;

    // we need to account for the orientation of the focii.

    // TODO: This function should have an option that lets you switch from actual angle, to normalized angle.

    const r =
      (a * b) / Math.sqrt(Math.pow(a * Math.sin(aRad), 2) + Math.pow(b * Math.cos(aRad), 2));
    return [
      center[0] + (r * Math.cos(aRad + fAngle)) / 111111 / factor,
      center[1] + (r * Math.sin(aRad + fAngle)) / 111111,
    ];
  };
}

export function getEllipsePoints(ellipse: Ellipse, divisions: number): [number, number][] {
  const curve: [number, number][] = [];

  const ellipsePoint = getEllipsePointCalculator(ellipse);

  // if we specify a start and end point for the arc, we want to include those points.
  // otherwise, leave a gap at the end so we don't duplicate the entry bearing.
  const complete = ellipse.start_bearing == ellipse.end_bearing;
  const divs = Math.max(divisions - (complete ? 0 : 1), 1);

  // To get points that are equally spaced, instead of at equal angles, we need to approximate
  // since there is no closed form solution for this on an ellipse.

  // We generate a bunch of points, by angle, and then treat it like a big polygon,
  // picking whatever point along the path best divides the linear segment distance.

  const highResDivs = 100 * divisions;

  let startAngle = ellipse.start_bearing;
  const endAngle = ellipse.end_bearing;
  let delta = 0;
  if (ellipse.clockwise) {
    while (startAngle >= endAngle) startAngle -= 360;
    delta = (endAngle - startAngle) / highResDivs;
  } else {
    while (startAngle <= endAngle) startAngle += 360;
    delta = (endAngle - startAngle) / highResDivs;
  }

  for (let i = 0; i <= highResDivs; i++) {
    const a = startAngle + delta * i;
    curve.push(ellipsePoint(a));
  }

  // Now let's go around and figure out the circumference
  let circumference = 0;
  for (let i = 1; i < curve.length; i++) circumference += turf.distance(curve[i - 1], curve[i]);

  // Now we figure out which curves best match our divisions
  const spacing = circumference / divs;
  let accumulator = 0;
  const rval: [number, number][] = [];
  for (let i = 1; i < curve.length && rval.length < divisions; i++) {
    accumulator += turf.distance(curve[i - 1], curve[i]);
    if (accumulator >= rval.length * spacing) rval.push(curve[i - 1]); // Add this curve point to our return list
  }
  // We may not reach the one at the very end, numerical precision dependent,
  // so add that last curve segment as the end if necessary
  if (rval.length < divisions) rval.push(curve[curve.length - 1]);

  return rval;
}
