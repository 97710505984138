// This file stores all the information about different imagery channels.
// Keeping it in one place makes it much easier to update or add new channels!

import React from 'react';
import { getShaderStopValues } from '~/lib/colorScales';
import { ChannelType, Palette, TileType } from '~/schema';
import glsl_demColorMain from '~/shaders/demColorMain.glsl';
import glsl_falseColorCubeMain from '~/shaders/falseColorCubeMain.glsl';
import glsl_falseColorMain from '~/shaders/falseColorMain.glsl';
import glsl_fourFalseColor from '~/shaders/fourFalseColor.glsl';
import glsl_sixFalseColor from '~/shaders/sixFalseColor.glsl';
import glsl_trueColorMain from '~/shaders/trueColorMain.glsl';
import {
  CIRColorLegend,
  DEMColorLegend,
  FalseColorLegend,
  RGBColorLegend,
  ThermalColorLegend,
} from '~/shared/ColorScaleLegend';
import TempUnitSwitch from '~/shared/TempUnitSwitch';

import { degCtoPref } from './units';

interface ChannelConfig {
  name: string;
  tooltip: string;
  controlType: 'gamma' | 'histogram' | 'dem';
  usesFalseColorPalette: boolean;
  tiletypes: TileType[];
  legend: React.FunctionComponent<any>;
  controlBar?: React.FunctionComponent<any>;

  remapValues?: (x: number) => number; // For converting between data and display values

  histogram: {
    fullDataRange: (range?: { min: number; max: number }) => [number, number];
    sliderRange: (range?: { min: number; max: number }) => [number, number];
  } | null;

  shader: (palette: Palette, tiletype: TileType) => string[];
}

const channelConfig: Record<ChannelType, ChannelConfig> = {
  RGB: {
    name: 'RGB',
    tooltip: 'Visible light, what the human eye sees',

    controlType: 'gamma',

    tiletypes: ['RGB', 'RGB_U'],
    legend: RGBColorLegend,

    usesFalseColorPalette: false,
    histogram: null,

    shader: (palette, tiletype) => {
      switch (tiletype) {
        case 'RGB':
          return [
            'vec3 getRGB(vec3 x, vec3 levels) { return pow(max(vec3(0.0), (x - levels[0])) / levels[2], vec3(levels[1])); }',
            glsl_trueColorMain,
          ];
        case 'RGB_U':
          return [
            'vec3 getRGB(vec3 x, vec3 levels) { return pow(max(vec3(0.0), (x - levels[0])) / levels[2], vec3(levels[1])); }',
            glsl_trueColorMain,
          ];
        default:
          return [];
      }
    },
  },
  CIR: {
    name: 'CIR',
    tooltip: 'Traditional Colorized Infrared',
    tiletypes: ['CIR'],
    controlType: 'gamma',
    legend: CIRColorLegend,
    usesFalseColorPalette: false,
    histogram: null,

    shader: (palette, tiletype) => {
      switch (tiletype) {
        case 'CIR':
          return [
            `vec3 getRGB(vec3 x, vec3 levels) { return pow(max(vec3(0.0), (x - levels[0])) / levels[2], vec3(levels[1])); }`,
            glsl_trueColorMain,
          ];
        default:
          return [];
      }
    },
  },
  CHL: {
    name: 'Chlorophyll',
    tooltip: 'An index that brings out the contribution from chlorophyll only',
    tiletypes: ['RRENIR'],
    usesFalseColorPalette: true,
    controlType: 'histogram',

    histogram: {
      fullDataRange: (_range) => [0, 1],
      sliderRange: (_range) => [0, 1],
    },

    legend: FalseColorLegend,

    shader: (palette, tiletype) => {
      switch (tiletype) {
        case 'RRENIR':
          return [
            getShaderStopValues(palette, 'NDVI'),
            glsl_fourFalseColor,
            `float getIntensity(vec3 x) { return  (log((x.b / x.g)) / 3.0); }`,
            glsl_falseColorMain,
          ];
        default:
          return [];
      }
    },
  },
  NDVI: {
    name: 'NDVI',
    tooltip: 'Normalized Difference Vegetation Index',
    tiletypes: ['RRENIR'],
    legend: FalseColorLegend,

    usesFalseColorPalette: true,
    controlType: 'histogram',

    histogram: {
      fullDataRange: (_range) => [0, 1],
      sliderRange: (_range) => [0, 1],
    },
    shader: (palette, tiletype) => {
      switch (tiletype) {
        case 'RRENIR':
          return [
            getShaderStopValues(palette, 'NDVI'),
            glsl_fourFalseColor,
            `float getIntensity(vec3 x) { return (x.b - x.r) / (x.b + x.r); }`,
            glsl_falseColorMain,
          ];
        default:
          return [];
      }
    },
  },
  NDRE: {
    name: 'NDRE',
    tooltip: 'Normalized Difference Red-Edge',
    tiletypes: ['RRENIR'],
    legend: FalseColorLegend,

    usesFalseColorPalette: true,
    controlType: 'histogram',

    histogram: {
      fullDataRange: (_range) => [0, 1],
      sliderRange: (_range) => [0, 1],
    },
    shader: (palette, tiletype) => {
      switch (tiletype) {
        case 'RRENIR':
          return [
            getShaderStopValues(palette, 'NDVI'),
            glsl_fourFalseColor,
            `float getIntensity(vec3 x) { return (x.b - x.g) / (x.b + x.g); }`,
            glsl_falseColorMain,
          ];
        default:
          return [];
      }
    },
  },
  OSAVI: {
    name: 'OSAVI',
    tooltip: 'Optimized Soil-Adjusted Vegetation Index',
    tiletypes: ['RRENIR'],
    legend: FalseColorLegend,

    usesFalseColorPalette: true,
    controlType: 'histogram',

    histogram: {
      fullDataRange: (_range) => [0, 1],
      sliderRange: (_range) => [0, 1],
    },
    shader: (palette, tiletype) => {
      switch (tiletype) {
        case 'RRENIR':
          return [
            getShaderStopValues(palette, 'NDVI'),
            glsl_fourFalseColor,
            `float getIntensity(vec3 x) { return 1.16 * (x.b - x.r) / (x.b + x.r + 0.16); }`,
            glsl_falseColorMain,
          ];
        default:
          return [];
      }
    },
  },
  THERMAL: {
    name: 'Thermal',
    tooltip: 'Temperature of the surface',
    tiletypes: ['THERMAL'],
    legend: ThermalColorLegend,

    usesFalseColorPalette: true,
    controlType: 'histogram',

    controlBar: TempUnitSwitch,
    remapValues: degCtoPref,
    histogram: {
      fullDataRange: (range) => (range ? [range.min, range.max] : [0, 100]),
      sliderRange: (range) => (range ? [range.min, range.max] : [0, 100]),
    },
    shader: (palette, tiletype) => {
      switch (tiletype) {
        case 'THERMAL':
          return [
            getShaderStopValues(palette, 'NDVI'),
            glsl_fourFalseColor,
            glsl_falseColorCubeMain,
          ];
        default:
          return [];
      }
    },
  },
  DEM: {
    name: 'Elevation',
    tooltip: 'Digital Elevation Model, relative height',
    tiletypes: ['DEM'],
    legend: DEMColorLegend,

    usesFalseColorPalette: true,
    controlType: 'dem',
    histogram: null,
    shader: (palette, tiletype) => {
      switch (tiletype) {
        case 'DEM':
          return [getShaderStopValues(palette, 'DEM'), glsl_sixFalseColor, glsl_demColorMain];
        default:
          return [];
      }
    },
  },
};

export default channelConfig;
