import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Icons from '~/icons';
import route from '~/lib/activeScene';
import channelConfig from '~/lib/channelConfig';
import { getChannel } from '~/redux/commonSelectors';
import { actions as uiActions } from '~/redux/slices/ui';
import { HistogramSlider } from '~/shared/Histogram';
import { ImageryChannelSelectorMenu } from '~/shared/ImageryChannelSelector';
import MapLegendControlContainer from '~/shared/MapLegendControlContainer';

import { Button, IconButton, Slider, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { clamp } from 'lodash';

const styles = makeStyles((theme) => ({
  legend: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    height: theme.spacing(2),
    zIndex: 1,
  },

  histogramError: {
    position: 'absolute',
    top: 0,
    left: theme.spacing(2),
    right: theme.spacing(2),
    bottom: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
  },

  gammaControls: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
    right: theme.spacing(1),
    bottom: theme.spacing(4),

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  buttonArea: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  contrastImage: {
    flexGrow: 1,
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

export default function MapColorLegend({ mapidx }) {
  const classes = styles();
  const theme = useTheme();

  const [icsmAnchorEl, setICSMAnchorEl] = React.useState(null);

  const channel = useSelector((state) => getChannel(state, mapidx));
  const windowRange = useSelector((state) => state.ui.windowRange[getChannel(state, mapidx)]);
  const windowRangeMapB = useSelector(
    (state) => state.ui.windowRangeMapB[getChannel(state, mapidx)]
  );
  // const dataColors = useSelector(state => getDataColors(state, channel))
  const dataRange = useSelector((state) => route.imageryRange(state, mapidx));
  const levels = useSelector((state) => state.ui.contrastLevels);
  const levelsMapB = useSelector((state) => state.ui.contrastLevelsMapB);

  // In order to display information from analytics summar(ies), we need to
  // know the imagesets being displayed.
  const imagesets = useSelector((state) => route.visibleImagesets(state, mapidx));

  let summary = null;
  if (imagesets?.length == 1) {
    // if there's only one, this is easy:
    summary = imagesets[0].summary?.find((s) => s.channel == channel);
  } else if (imagesets?.length > 1) {
    // If there are many imagesets, we need to combine them.
    const averageHistogram = imagesets.reduce((sum, x) => {
      const summary = x.summary?.find((s) => s.index == channel);
      for (let i = 0; i < 30; i++) {
        sum[i] += (summary?.histogram?.[i] || 0) / imagesets.length;
      }
      return sum;
    }, Array(30).fill(0));

    const averageMean =
      imagesets.reduce((sum, x) => {
        const summary = x.summary?.find((s) => s.index == channel);

        sum += summary?.mean || 0;
        return sum;
      }, 0) / imagesets.length;

    const averageStd =
      imagesets.reduce((sum, x) => {
        const summary = x.summary?.find((s) => s.index == channel);

        sum += summary?.std || 0;
        return sum;
      }, 0) / imagesets.length;

    // Extract only the valid summaries for this channel for visible imagesets
    const imageSetChannelSummaries = imagesets
      .map((x) => x.summary?.find((s) => s.index == channel))
      .filter((x) => x != null);

    summary = {
      histogram: averageHistogram,
      mean: averageMean,
      std: averageStd,
      min: Math.min(...imageSetChannelSummaries.map((x) => x.min)),
      max: Math.max(...imageSetChannelSummaries.map((x) => x.max)),
    };
  }

  const dispatch = useDispatch();
  const setWindowRange = (x) => dispatch(uiActions.setWindowRange(x));
  const setWindowRangeMapB = (x) => dispatch(uiActions.setWindowRangeMapB(x));
  const setContrastLevels = (x) => dispatch(uiActions.setContrastLevels(x));
  const setContrastLevelsMapB = (x) => dispatch(uiActions.setContrastLevelsMapB(x));

  const hasHistogram = channelConfig[channel].controlType == 'histogram';
  const hasGamma = channelConfig[channel].controlType == 'gamma';

  const fullDataRange = channelConfig[channel].histogram?.fullDataRange(summary);
  const sliderRange = channelConfig[channel].histogram?.sliderRange(summary);

  const LegendComponent = channelConfig[channel].legend;

  const legend = LegendComponent ? (
    <LegendComponent
      className={classes.legend}
      min={mapidx == 0 ? windowRange[0] : windowRangeMapB[0]}
      max={mapidx == 0 ? windowRange[1] : windowRangeMapB[1]}
      mean={summary?.mean}
    />
  ) : null;

  const ControlsComponent = channelConfig[channel].controlBar;
  const controls = ControlsComponent ? (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <ControlsComponent />
    </div>
  ) : null;

  const handleGetLevels = () => {
    if (mapidx == 0) {
      return (1 / levels[1]) * 100;
    } else return (1 / levelsMapB[1]) * 100;
  };

  const handleChangeGamma = (evt, x) => {
    if (mapidx == 0) {
      setContrastLevels([levels[0], 100 / x, levels[2]]);
    } else setContrastLevelsMapB([levelsMapB[0], 100 / x, levelsMapB[2]]);
  };
  const handleChangeWindow = (x) => {
    if (mapidx == 0) {
      setWindowRange({ channel, range: x });
    } else setWindowRangeMapB({ channel, range: x });
  };

  function heightForChannel(channel) {
    // The height of the control box given the channel we're editing.
    // The thermal channel has an extra row of controls on top (C/F)
    // so it must be slightly taller.
    switch (channel) {
      case 'THERMAL':
        return 18;
      default:
        return 16;
    }
  }

  const setWindowRangeThisMapIdx = mapidx == 0 ? setWindowRange : setWindowRangeMapB;

  return (
    <MapLegendControlContainer
      title={
        <>
          Imagery: <b>{channelConfig[channel].name}</b>
        </>
      }
      Icon={Icons.Indices}
      collapsed_width={theme.spacing(24)}
      collapsed_height={theme.spacing(2)}
      detail_width={theme.spacing(36)}
      detail_height={theme.spacing(heightForChannel(channel))}
      alwaysVisible={legend}
      detailView={
        <>
          {controls}
          {hasHistogram ? (
            <>
              <HistogramSlider
                mapidx={mapidx}
                dataRange={fullDataRange}
                sliderRange={sliderRange}
                data={summary?.histogram || [0, 0, 0]}
                windowRange={mapidx == 0 ? windowRange : windowRangeMapB}
                setWindowRange={handleChangeWindow}
                remapValues={channelConfig[channel].remapValues}
              />

              {!summary && (
                <div className={classes.histogramError}>
                  <Typography variant='caption' color='error' align='center'>
                    No histogram available for this dataset.
                  </Typography>
                </div>
              )}
            </>
          ) : hasGamma ? (
            <div className={classes.gammaControls}>
              <Typography variant='caption'>Exposure Compensation</Typography>
              <span style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                <IconButton
                  size='small'
                  onClick={(e) => handleChangeGamma(e, (1 / levels[1]) * 100 - 10)}>
                  <Icons.ZoomOut />
                </IconButton>

                <Slider
                  value={handleGetLevels()}
                  onChange={handleChangeGamma}
                  valueLabelDisplay='auto'
                  valueLabelFormat={(x) => (x / 100).toFixed(2)}
                  steps={1}
                  max={200}
                  min={10}
                />

                <IconButton
                  size='small'
                  onClick={(e) => handleChangeGamma(e, (1 / levels[1]) * 100 + 10)}>
                  <Icons.ZoomIn />
                </IconButton>
              </span>
              <img src={`/images/gammascale.png`} className={classes.contrastImage} />
            </div>
          ) : (
            <div className={classes.histogramError}>
              <Typography variant='caption' color='error' align='center'>
                No controls for this layer.
              </Typography>
            </div>
          )}
          <div className={classes.buttonArea}>
            <Button
              size='small'
              color='primary'
              variant='text'
              onClick={(evt) => setICSMAnchorEl(evt.currentTarget)}>
              {channel}
            </Button>

            <ImageryChannelSelectorMenu
              mapidx={mapidx}
              anchorEl={icsmAnchorEl}
              onClose={() => setICSMAnchorEl(null)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            />
            {hasHistogram ? (
              <>
                <Button
                  size='small'
                  onClick={() => {
                    handleChangeWindow(fullDataRange);
                  }}>
                  Absolute
                </Button>

                <Button
                  size='small'
                  disabled={!summary}
                  onClick={() => {
                    setWindowRangeThisMapIdx({
                      channel,
                      range: [
                        clamp(
                          clamp(summary?.mean, ...fullDataRange) - summary?.std * 2,
                          ...fullDataRange
                        ),
                        clamp(
                          clamp(summary?.mean, ...fullDataRange) + summary?.std * 2,
                          ...fullDataRange
                        ),
                      ],
                    });
                  }}>
                  <span style={{ textTransform: 'none' }}>2&sigma;</span>
                  &nbsp; Relative
                </Button>
              </>
            ) : hasGamma ? (
              <Button
                size='small'
                onClick={
                  mapidx == 0
                    ? () => setContrastLevels([0, 1, 1])
                    : () => setContrastLevelsMapB([0, 1, 1])
                }>
                Reset
              </Button>
            ) : null}
          </div>
        </>
      }
    />
  );
}
