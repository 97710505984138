import { debounce } from 'ts-debounce';
import * as api from '~/lib/api';
import { byId } from '~/lib/api';
import Logging from '~/logging';
import { IndustryType } from '~/markets';
import {
  BackdropType,
  ChannelType,
  CropType,
  Farm,
  LoginState,
  MapSplitStyle,
  Palette,
  Region,
  SVGlobal,
  SVHello,
  Survey,
  TargetRequest,
  User,
} from '~/schema';

import { UsershapeType } from './usershape/types';
import { SVNavModule, PayloadType } from '~/schema/SVGlobal';
import type { SVDispatch, SVThunkAction } from '~/store';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { UnitsType } from '~/lib/geography';

export interface Preferences {
  backdrop: BackdropType;
  channel: [ChannelType, ChannelType];
  regionDisplayMode: {
    analysis: boolean;
    flight: boolean;
    targeted: boolean;
    keepout: boolean;
  };
  showTgtBubbles: true;
  usershapeType?: UsershapeType;
  showFlightPath: boolean;
  showFlightLogs: boolean;
  showFlightImagery: boolean;
  showFlightRegions: boolean;

  showDetailOverlay: boolean;
  defaultFarmId?: string;
  defaultOrgId?: string;

  overviewSort: { dir: -1 | 1; key: string };
  targetedPreset: string;

  analysisMode: [string?, string?];
  analysisOpacity: number;
  analysisLabels: boolean;

  mapSplitStyle: MapSplitStyle; // overlap or side
  showNotes: number; // show all notes

  darkmode: boolean;

  dataColors: Palette; // color scheme preference, normal/colorblind

  hiddenZones: number[]; // for the zone selector
  showZoneGrid: boolean;

  test_market?: IndustryType;

  hiddenFarmIds: string[];

  units?: 'IMPERIAL' | 'METRIC';

  tempUnit: 'C' | 'F';

  areaUnit: UnitsType;
}

const DEFAULT_PREFERENCES: Preferences = {
  // All the default preferences!
  backdrop: 'SAT2',
  channel: ['RGB', 'NDVI'],
  regionDisplayMode: {
    analysis: true,
    flight: false,
    targeted: false,
    keepout: true,
  },
  showTgtBubbles: true,
  usershapeType: 'rectangle',
  showFlightPath: true,
  showFlightLogs: false,
  showFlightImagery: true,
  showFlightRegions: true,

  showDetailOverlay: true,

  /* @deprecated */
  defaultFarmId: undefined,

  defaultOrgId: undefined, // Will choose what org is displayed when none is in the URL

  overviewSort: { dir: -1, key: 'health' },
  targetedPreset: 'c',

  analysisMode: [undefined, undefined],
  analysisOpacity: 0.8,
  analysisLabels: false,

  mapSplitStyle: 'side', // overlap or side
  showNotes: 2, // show all notes

  dataColors: 'normal', // color scheme preference, normal/colorblind
  darkmode: false,

  hiddenZones: [], // for the zone selector
  showZoneGrid: false,

  test_market: undefined,

  hiddenFarmIds: [], // by default, show all farms we access

  tempUnit: 'C',

  areaUnit: 'ACRES',
};

interface GlobalState {
  loginState: LoginState;
  version?: { version: string; build: number; commit: string; datetime: string };
  loginFailure?: string;
  maintenance?: string;

  fetchingglobal: boolean;
  user?: User;
  farms: { [id: string]: Farm };

  /* @depecrated */
  growers: string[];

  organizations: {
    id: string;
    industry: IndustryType;
  }[]; // These are orgs we can display in the data dropdown. Any org that owns sites we can access, basically.

  cropTypes: { [id: string]: CropType };
  assets: { [id: number]: Region };
  regions: { [id: number]: Region };
  surveys: { [id: number]: Survey };
  target_requests: { [id: number]: TargetRequest };

  system_permissions: string[];
  /* @deprecated */
  org_permissions: { [id: string]: string[] };
  site_permissions: { [id: string]: string[] };

  modules: SVNavModule[];

  activeReport?: string;

  preferences: Preferences;

  payloadTypes: PayloadType[];
}

const initialState: GlobalState = {
  /* we might have a session before we load global data. 
    Assume we do for starters, if it fails we'll set it to 
    false and display the login screen. */

  loginState: 'unknown', // can be 'unknown', 'probably', 'pending', true, false, 'maintenance'
  version: undefined,
  loginFailure: undefined, // String explaining error, if any
  maintenance: undefined, // maintenance downtime message

  // TODO: Add global fetch fails, separate from being logged out

  fetchingglobal: false,
  user: undefined, // null user implies that we don't have global data yet.
  farms: {}, // should be by ID
  growers: [],

  organizations: [],

  cropTypes: {}, // ok, that's global

  // TODO: This stuff is probably wrong
  regions: {}, // really per farm
  assets: {},
  surveys: {}, // per farm
  target_requests: {}, // per survey!

  system_permissions: [],
  org_permissions: {},
  site_permissions: {},

  modules: [],

  activeReport: undefined,

  preferences: { ...DEFAULT_PREFERENCES },

  payloadTypes: [],
};

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    getGlobalDataBegin(state) {
      state.fetchingglobal = true;
    },
    getGlobalDataSuccess(state, action: PayloadAction<SVGlobal>) {
      const data = action.payload;

      // Preferences are sent as an array of key-value pairs
      // where the value is json encoded. We decode and merge with
      // default prefs here
      const newPrefs: Preferences = {
        ...DEFAULT_PREFERENCES,
      };

      data.preferences?.forEach((p) => {
        try {
          const key = p.key as keyof Preferences;
          const value = JSON.parse(p.value);
          (newPrefs[key] as any) = value;
        } catch (e) {
          Logging.warn('Failed to parse server preference', {
            preference: p.key,
            value: p.value,
            error: e,
          });
        }
      });

      Logging.setUserId(data.user.id);

      state.fetchingglobal = false;
      state.loginState = true;
      state.user = data.user;
      if (data.farms) {
        state.farms = byId(data.farms) || {};
        state.growers = [...new Set(data.farms.map((f) => f.grower_id))];
      } else {
        state.farms = {};
        state.growers = [];
      }
      state.cropTypes = byId(data.crop_types) || {};

      state.system_permissions = data.system_permissions || [];

      state.modules = data.modules || [];

      state.organizations = data.organizations || [];

      state.org_permissions = {};
      data.org_permissions?.forEach(
        (kvp) => (state.org_permissions[kvp.id] = kvp.permissions || [])
      );

      state.site_permissions = {};
      data.site_permissions?.forEach(
        (kvp) => (state.site_permissions[kvp.id] = kvp.permissions || [])
      );

      state.preferences = newPrefs;
      state.activeReport = data.activeReport;

      state.payloadTypes = data.payloadTypes;
    },

    getGlobalDataFailure(state) {
      state.fetchingglobal = false;
      state.loginState = false;
      state.loginFailure = 'Server error accessing account';
    },

    helloBegin() {
      // state.loginState = 'unknown'
    },

    helloSuccess(state, action: PayloadAction<SVHello>) {
      const { authenticated, version, build, commit, datetime, maintenance, maintenanceBypass } =
        action.payload;

      if (maintenance && !maintenanceBypass) {
        // This happens even if we're logged in
        state.loginState = 'maintenance';
      }

      if (state.loginState != true) {
        // We don't know if we're logged in yet
        state.loginState = authenticated ? 'probably' : false;
      }

      state.maintenance = maintenance;
      state.version = { version, build, commit, datetime };
      state.loginFailure = undefined;
    },

    helloFailure(state) {
      state.loginState = 'error';
      state.loginFailure = 'Cannot reach the American Robotics servers';
    },
    loginBegin(state) {
      state.loginState = 'pending';
      state.user = undefined; // undefined user implies that we don't have global data yet.
    },

    loginSuccess(state) {
      state.loginState = true;
    },
    loginFailure(state, action) {
      const { loginFailure } = action.payload;
      state.loginState = false;
      state.loginFailure = loginFailure;
    },

    // Resets us to initial state, but logged out. All global data gone!
    // Note that other reducers should listen for logout to clean up.
    logout() {
      return {
        ...initialState,
        loginState: false,
        loginFailure: 'Logged out.',
      };
    },

    setPreference(state, action: PayloadAction<Partial<Preferences>>) {
      state.preferences = { ...state.preferences, ...action.payload };
    },

    setActiveReport(state, action: PayloadAction<{ activeReport?: string }>) {
      const { activeReport } = action.payload;
      state.activeReport = activeReport;
    },

    updateSelfUser(state, action: PayloadAction<{ user: User }>) {
      const { user } = action.payload;
      state.user = user;
    },

    setZoneVisibility(state, action: PayloadAction<{ zoneIds: number[]; visible: boolean }>) {
      const { zoneIds, visible } = action.payload;
      if (visible) {
        state.preferences.hiddenZones = state.preferences.hiddenZones.filter(
          (z) => !zoneIds.includes(z)
        );
      } else {
        state.preferences.hiddenZones = [...new Set(state.preferences.hiddenZones), ...zoneIds];
      }
    },

    updateSingleFarm(state, action: PayloadAction<{ farm: Farm }>) {
      const { farm } = action.payload;
      state.farms[farm.id] = farm;
    },
  },
});

const logout = () => async (dispatch: SVDispatch) => {
  await api.logout();
  dispatch(slice.actions.logout());
};

const _listOfChangedPrefs: Record<string, any> = {};

const _uploadPrefs = debounce(
  () => {
    // We should upload this preference change to the server too, though it doesn't matter if it returns.
    const posts = Object.keys(_listOfChangedPrefs).map((key) =>
      api.webrequest('POST', 'sv/preference', {
        key,
        value: JSON.stringify(_listOfChangedPrefs[key]),
      })
    );
    Promise.all(posts).then();
  },
  500,
  { isImmediate: true }
);

const setPreference =
  (values: Partial<Preferences>): SVThunkAction =>
  async (dispatch, getState) => {
    for (const _key of Object.keys(values)) {
      const key = _key as keyof Preferences;
      const value = values[key];
      if (getState().global.preferences[key] != value) {
        _listOfChangedPrefs[key] = value;
      }
    }

    _uploadPrefs();

    dispatch(slice.actions.setPreference(values));
  };

// Verifies API connection, gets version, sees if we have a session
const sayHello = (): SVThunkAction => async (dispatch) => {
  dispatch(slice.actions.helloBegin());

  try {
    const hello = (await api.webrequest('GET', 'sv/hello')) as SVHello;
    // We can contact the server
    dispatch(slice.actions.helloSuccess(hello));
  } catch {
    // Problem contacting the server.
    dispatch(slice.actions.helloFailure());
  }
};

const fetchGlobalData = (): SVThunkAction => async (dispatch, getState) => {
  dispatch(slice.actions.getGlobalDataBegin());

  // Check if we think we're logged in.
  if (getState().global.loginState === false) {
    // If not, abort right now.
    // TODO: store the last global request time, and request updates using the query param
    //{ reason: 'Not logged in' }
    dispatch(slice.actions.getGlobalDataFailure());
    return;
  }

  // Try to retrieve global data.
  try {
    const data = (await api.webrequest('GET', 'sv/global')) as SVGlobal;
    // On success, call the success action.
    dispatch(slice.actions.getGlobalDataSuccess(data));
  } catch (e) {
    // On failure, call the failure action with the appropriate status.
    //{ reason: `Request failed: ${e}` }
    dispatch(slice.actions.getGlobalDataFailure());
  }
};

const attemptLogin =
  (username: string, password: string): SVThunkAction =>
  async (dispatch) => {
    // Clear the global data and loggedIn flags.
    dispatch(slice.actions.loginBegin());

    // Ask the server for a login session.
    try {
      await api.webrequest('POST', 'sv/login', { username, password });
      // On success, request global data!
      Logging.setUserId(username);

      dispatch(slice.actions.loginSuccess());
    } catch (err) {
      // Logging.error('Login error', err)

      // On failure, report it
      let loginFailure = `Error: ${err.response.statusText}`;
      switch (err.response.status) {
        case 500:
          loginFailure = 'Server error, try again later';
          break;
        case 401:
          loginFailure = 'Incorrect login credentials';
          break;
        case 504:
          loginFailure = 'Server not responding, try again later';
          break;
      }
      Logging.setUserId(null);

      dispatch(slice.actions.loginFailure({ loginFailure }));
      return;
    }

    dispatch(fetchGlobalData());
  };

// selectors

export default slice.reducer;

export const actions = {
  ...slice.actions,
  logout,
  setPreference,
  sayHello,
  fetchGlobalData,
  attemptLogin,
};
