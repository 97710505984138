import React from 'react';

import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';

import NewPartDialog from '../../shared/NewPartDialog';

export default function SetSubassemblyDialog({ parameters, onClose, availableSubassemblies }) {
  const [chosenKey, setChosenKey] = React.useState(null);
  const [chosenType, setChosenType] = React.useState(null);
  const [chosenSN, setChosenSN] = React.useState(null);

  const [showPartDialog, setShowPartDialog] = React.useState(false);

  const handleCancel = () => {
    onClose(null);
  };

  const handleConfirm = () => {
    onClose({ chosenKey, chosenType, chosenSN });
  };

  const possibleTypes = [...new Set(availableSubassemblies.map((s) => s.type))];

  return (
    <>
      <Dialog open={parameters != null} onClose={handleCancel}>
        <DialogTitle>Set Subassembly</DialogTitle>

        <DialogContent>
          {!parameters?.key && (
            <>
              <TextField
                fullWidth
                label='Slot Name'
                value={chosenKey}
                onChange={(e) =>
                  setChosenKey(
                    e.target.value
                      .replace(' ', '_')
                      .toUpperCase()
                      .replace(/[^A-Z0-9_]/g, '')
                  )
                }
              />

              <TextField
                fullWidth
                select
                label='Subassembly Type'
                disabled={!chosenKey}
                value={chosenType}
                onChange={(e) => setChosenType(e.target.value)}>
                {possibleTypes.map((t) => (
                  <MenuItem key={t} value={t}>
                    {t.toUpperCase()}
                  </MenuItem>
                ))}
              </TextField>
            </>
          )}
          <Collapse in={parameters?.keyType || chosenType}>
            {availableSubassemblies.filter((s) => s.type == (parameters?.keyType || chosenType))
              .length == 0 ? (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography color='error' variant='body2' gutterBottom>
                  Looks like there are no free parts of the required type.
                </Typography>
              </div>
            ) : (
              <TextField
                fullWidth
                select
                disabled={!(parameters?.keyType || chosenType)}
                label='Subassembly'
                value={chosenSN}
                onChange={(e) => setChosenSN(e.target.value)}>
                {availableSubassemblies
                  .filter((s) => s.type == (parameters?.keyType || chosenType))
                  .map((s) => (
                    <MenuItem key={s.sn} value={s.sn}>
                      {s.type} {s.sn} ({s.state})
                    </MenuItem>
                  ))}
              </TextField>
            )}
            <Button
              fullWidth
              color='secondary'
              onClick={() => {
                setShowPartDialog(true);
              }}>
              Bringup new {parameters?.keyType}
            </Button>
          </Collapse>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleConfirm} color='primary' disabled={!chosenSN}>
            Set
          </Button>
        </DialogActions>
      </Dialog>

      <NewPartDialog
        show={showPartDialog}
        onClose={(createdPart) => {
          if (createdPart) {
            onClose({
              chosenKey,
              chosenType: createdPart.type,
              chosenSN: createdPart.serial_number,
            });
          }
          setShowPartDialog(false);
        }}
        fixedType={parameters?.keyType}
      />
    </>
  );
}
