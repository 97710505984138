import React from 'react';

import { Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

export type SnackbarSeverity = 'success' | 'info' | 'warning' | 'error';
export interface SnackbarPayload {
  severity: SnackbarSeverity;
  message: string;
}
export default function SnackbarService({
  payload,
  onClear,
}: {
  payload?: SnackbarPayload;
  onClear: () => void;
}) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      onClose={onClear}
      open={payload != null}
      autoHideDuration={3000}>
      <Alert severity={payload?.severity || 'info'}>{payload?.message || ''}</Alert>
    </Snackbar>
  );
}
