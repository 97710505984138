import classNames from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';
import * as Icons from '~/icons';
import { Coordinate } from '~/lib/customHooks';
import history from '~/lib/history';
import { parseUTCISO } from '~/lib/time';
import { NoFlyTime, ScheduleData, ScheduledFlightRequest } from '~/schema';
import { regionColors } from '~/theme';

import {
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  fade,
  makeStyles,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { Block } from '@material-ui/icons';
import WbSunny from '@material-ui/icons/WbSunny';
import { Skeleton } from '@material-ui/lab';

import { getSunTimes } from './common';

const style = makeStyles((theme) => ({
  timeText: {
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: theme.spacing(10),
  },

  nowBar: {
    position: 'relative',
    zIndex: 99,
    height: 2,
    width: '100%',
    background: `linear-gradient(90deg, ${theme.palette.primary.light} 0%, transparent 100%)`,

    margin: 0,
    '&:before': {
      position: 'absolute',
      content: '""',
      display: 'block',
      top: -5,
      left: -5,
      height: 12,
      width: 12,
      borderRadius: '50%',
      background: theme.palette.primary.light,
    },
  },

  scheduleItem: {
    margin: 2,

    '&.weather': {
      background: grey[200],
    },
    '&.flight': {
      background: fade(regionColors.FIELD, 0.3),
    },
    '&.targeted': {
      background: fade(regionColors.TARGETED, 0.3),
    },
    '&.nft': {
      background: fade(regionColors.KEEPOUT, 0.3),
    },
    '&.request': {
      background: fade(regionColors.FIELD, 0.15),
    },
    '&.targetedrequest': {
      background: fade(regionColors.TARGETED, 0.3),
    },
    '&.goalrequest': {
      background: fade(regionColors.FIELD, 0.2),
    },
    '&.date': {
      background: grey[200],
    },
    '&.error': {
      color: theme.palette.error.main,
    },
  },
}));

export interface NoEventEntryProps {
  text: string;
}

export interface EventEntryProps {
  name: string;
  startTime: DateTime;
  endTime?: DateTime; // may not be known or not apply
  status?: string;
  type:
    | 'weather'
    | 'flight'
    | 'targeted'
    | 'nft'
    | 'request'
    | 'targetedrequest'
    | 'goalrequest'
    | 'date'
    | 'error';
  icon?: React.ReactNode; // optional icon override
  action?: React.ReactNode; // optional listitemsecondaryaction
  baseSn?: string;
  onClick?: () => void;
}
function EventEntry(props: EventEntryProps) {
  const classes = style();

  const icon: React.ReactNode = props.icon ||
    {
      weather: <WbSunny />,
      targeted: <Icons.Targeted />,
      flight: null,
      nft: <Block />,
      request: <Icons.Schedule />,
      goalrequest: <Icons.Goal />,
      targetedrequest: <Icons.Targeted />,
      date: <WbSunny />,
      error: <Icons.Close />,
    }[props.type] || <Icons.Schedule />;

  const startTimeStr = props.startTime.toLocaleString(DateTime.TIME_SIMPLE);
  const endTimeStr = props.endTime?.toLocaleString(DateTime.TIME_SIMPLE) || undefined;

  return (
    <ListItem
      button={(props.onClick != undefined) as any}
      onClick={props.onClick}
      style={{ borderLeftStyle: props.onClick != undefined ? 'solid' : 'none' }}
      className={classNames(classes.scheduleItem, props.type)}>
      <ListItemIcon>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>{icon}</div>
      </ListItemIcon>

      {!['date', 'error'].includes(props.type) && (
        <ListItemText
          className={classes.timeText}
          primaryTypographyProps={{ variant: 'button' }}
          primary={startTimeStr}
          secondaryTypographyProps={{ variant: 'button' }}
          secondary={endTimeStr}
        />
      )}
      <ListItemText primary={props.name} secondary={props.status} />

      {props.action && <ListItemSecondaryAction>{props.action}</ListItemSecondaryAction>}
    </ListItem>
  );
}

function sunTimes(day: DateTime, location: Coordinate, tz: string): [DateTime, DateTime] {
  const sun = getSunTimes(day, location);

  const sunriseTime = DateTime.fromObject({
    year: day.year,
    month: day.month,
    day: day.day,
    hour: sun.sunrise.getHours(),
    minute: sun.sunrise.getMinutes(),
    zone: tz,
  });
  const sunsetTime = DateTime.fromObject({
    year: day.year,
    month: day.month,
    day: day.day,
    hour: sun.sunset.getHours(),
    minute: sun.sunset.getMinutes(),
    zone: tz,
  });
  return [sunriseTime, sunsetTime];
}

export default function EventScheduleList(props: {
  siteid: string;
  day: DateTime;

  data: ScheduleData;

  loading: boolean;

  canEditRequests: boolean;
  canSeeFlightLog: boolean;

  now: DateTime;

  onChangeSFR: (item: ScheduledFlightRequest) => Promise<void>;
  onDeleteSFR: (id: number) => Promise<void>;
  onChangeNoFly: (item: NoFlyTime) => Promise<void>;
  onDeleteNoFly: (id: number) => Promise<void>;

  setNoFlyToEdit: (item: NoFlyTime) => void;
  setSfrToEdit: (item: ScheduledFlightRequest) => void;
}) {
  const classes = style();

  const surveyForId = (id?: number) => props.data.surveysAvailable.find((s) => s.id == id);

  // Given all the flights, noflies, and sfrs in the data, build an ordered list of them.
  const events: EventEntryProps[] = [];

  const isUnscheduled = props.day.startOf('day') > props.now.plus({ days: 3 }).startOf('day');

  if (isUnscheduled) {
    events.push({
      name: 'Scout does not schedule this far in advance.',
      startTime: props.day.endOf('day'),
      type: 'error',
      status: '',
    });
  }

  if (!props.loading) {
    props.data.flights.forEach((flight) => {
      const survey = surveyForId(flight.survey_id);
      const targeted = survey?.type == 'TARGETED';

      const startTime = parseUTCISO(flight.takeoff_time, props.data.tz);
      const endTime = flight.landing_time
        ? parseUTCISO(flight.landing_time, props.data.tz)
        : undefined;

      // Skip entries that aren't today
      if (startTime.toISODate() != props.day.toISODate()) return;

      events.push({
        name: `${survey?.display_name || 'Unknown Survey'}`,
        startTime: startTime,
        endTime: endTime,
        status: flight.data_status,
        icon: ['COMPLETE', 'NO DATA'].includes(flight.data_status) ? (
          <Icons.Save />
        ) : (
          <CircularProgress size={24} color='secondary' />
        ),
        type: targeted ? 'targeted' : 'flight',
        baseSn: flight.base_sn,
        // TODO: add an onClick here that takes us to the imagery for this flight. (How, if it's multiple fields?)
        action: props.canSeeFlightLog ? (
          <IconButton onClick={() => history.push(`/flight/${flight.flight_id}`)}>
            <Icons.Drone />
          </IconButton>
        ) : undefined,
      });
    });

    props.data.scheduledFlightRequests.forEach((sfr) => {
      const survey = surveyForId(sfr.survey_id);
      const targeted = survey?.type == 'TARGETED';

      const isGoal = sfr.goal_id !== undefined;

      const start = sfr.expected_datetime.setZone(props.data.tz);
      const end = start.plus(sfr.expected_duration_s);

      // Skip entries that aren't today
      if (start.toISODate() != props.day.toISODate()) return;

      events.push({
        name: survey?.display_name || 'Unknown Survey',
        startTime: start,
        endTime: end,
        status: end < props.now ? 'DELAYED' : 'SCHEDULED',
        type: isGoal ? 'goalrequest' : targeted ? 'targetedrequest' : 'request',
        baseSn: sfr.base_sn,
        onClick: props.canEditRequests ? () => props.setSfrToEdit(sfr) : undefined,
      });
    });

    props.data.noFlyInstances.forEach((nfi) => {
      const nft = props.data.noFlyTimes.find((x) => x.id == nfi.parent_id);
      if (!nft) return; // this is an error

      const start = nfi.datetime.setZone(props.data.tz);
      const end = start.plus(nft.duration_minutes);

      // Skip entries that aren't today
      if (start.toISODate() != props.day.toISODate()) return;

      events.push({
        name: nft.description,
        startTime: start,
        endTime: end,
        status: 'No-fly time',
        type: 'nft',
        onClick: () => props.setNoFlyToEdit(nft),
      });
    });
  }

  function casualDateName(d: DateTime): string {
    if (d.toISODate() == props.now.toISODate()) {
      return 'Today: ' + d.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
    } else if (d.toISODate() == props.now.plus({ days: 1 }).toISODate()) {
      return 'Tomorrow: ' + d.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
    } else if (d.toISODate() == props.now.plus({ days: -1 }).toISODate()) {
      return 'Yesterday: ' + d.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
    } else {
      return d.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
    }
  }

  const sun = sunTimes(props.day, props.data.location, props.data.tz);
  events.push({
    name: casualDateName(props.day),
    startTime: props.day.startOf('day'),
    type: 'date',
    status: `Sun: ${sun[0].toLocaleString({
      timeStyle: 'short',
    })} - ${sun[1].toLocaleString({ timeStyle: 'short' })} ${sun[1].toFormat('ZZZZ')}`,
  });

  events.sort((a, b) => a.startTime.diff(b.startTime).as('seconds'));

  return (
    <List dense disablePadding>
      {/* Display events before the Now Bar. */}
      {events
        .filter((e) => (e.endTime || e.startTime) < props.now)
        .map((e, i) => (
          <EventEntry key={i} {...e} />
        ))}

      {/* If we're viewing today, display a little bar indicating where we are in the schedule. */}
      {props.now.toISODate() == props.day.toISODate() && <div className={classes.nowBar} />}

      {/* Display events after the Now Bar. */}
      {events
        .filter((e) => (e.endTime || e.startTime) >= props.now)
        .map((e, i) => (
          <EventEntry key={i} {...e} />
        ))}
      {/* {props.loading && <LoadingEventEntry />} */}
    </List>
  );
}
