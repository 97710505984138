import queryString from 'query-string';
import { createSelector } from 'reselect';
import * as globalSelctors from '~/redux/selectors/global';
import store from '~/store';

import { actions } from './slice';

export const matches = (state) => globalSelctors.paramsForPath(state, '/org/:orgid');

export const orgData = createSelector(
  matches,
  (state) => state.orgScene.orgs,
  (params, orgs) => {
    return orgs[params.orgid] || { loading: true };
  }
);
