import classNames from 'classnames';
import React from 'react';
import * as Icons from '~/icons';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker, TimePicker } from '@material-ui/pickers';

const styles = makeStyles((theme) => ({
  grid: {
    display: 'grid',
    gridGap: 8,
    gridTemplateColumns: 'repeat(2,1fr)',
    gridAutoFlow: 'row',
    marginBottom: theme.spacing(1),
  },
}));

export default function ColumnSelectDialog({
  show,
  allColumns,
  hiddenColIds,
  setHiddenColIds,
  onClose,
}) {
  const classes = styles();
  const categories = [...new Set(allColumns.map((c) => c.category || 'misc'))];

  let colsByCat = {};
  categories.forEach((cat) =>
    (colsByCat[cat] = allColumns.filter((c) => (c.category || 'misc') == cat)).map((c) => ({
      id: c.id,
      title: c.title,
    }))
  );

  const showColumn = (id) => {
    setHiddenColIds(hiddenColIds.filter((x) => x != id));
  };
  const hideColumn = (id) => {
    setHiddenColIds([...new Set([...hiddenColIds, id])]);
  };

  return (
    <Dialog maxWidth='xs' open={show} onClose={onClose}>
      <DialogTitle id='confirmation-dialog-title'>Show/Hide Columns</DialogTitle>

      <DialogContent dividers>
        {categories.map((cat, i) => (
          <React.Fragment key={i}>
            {i > 0 && (
              <Divider
                variant='fullWidth'
                style={{
                  marginBottom: 8,
                }}
              />
            )}
            <Typography variant='h6'>{cat}</Typography>
            <div className={classes.grid}>
              {colsByCat[cat].map((c) => (
                <FormControlLabel
                  key={c.id}
                  control={
                    <Checkbox
                      color='primary'
                      checked={!hiddenColIds.includes(c.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          showColumn(c.id);
                        } else {
                          hideColumn(c.id);
                        }
                      }}
                    />
                  }
                  label={c.title}
                />
              ))}
            </div>
          </React.Fragment>
        ))}
      </DialogContent>

      <DialogActions>
        <Button color='primary' onClick={onClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
