import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import mapboxgl, { Map as MapboxGlMap, CustomLayerInterface } from 'mapbox-gl';
import SphereModel from '../../models/pointSphere.glb';
import stringify from 'json-stable-stringify';
import { Mesh } from 'three';

export const isDirty = (original: any, tmp: any) => {
  const cleaner = (k: any, v: any) => {
    const ignore = ['last_updated', 'id'];
    if (ignore.includes(k)) {
      return undefined;
    }
    if (v === null) {
      return undefined;
    }
    if (typeof v === 'number') {
      return Math.round(v * 100) / 100;
    }
    return v;
  };

  return stringify(original, { replacer: cleaner }) != stringify(tmp, { replacer: cleaner });
};

interface ThreeJSLayerInterface extends CustomLayerInterface {
  scene?: THREE.Scene;
  camera?: THREE.Camera;
  renderer?: THREE.WebGLRenderer;
}

export const get3DPointLayer = (
  lng: number,
  lat: number,
  alt: number,
  options: any
): ThreeJSLayerInterface => {
  const modelOrigin: [number, number] = [lng, lat];
  const modelAltitude = alt;
  const modelRotate = [Math.PI / 2, 0, 0];
  const { color } = options;

  const material = new THREE.MeshStandardMaterial({
    color: color,
    roughness: 0.5,
  });

  const modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
    modelOrigin,
    modelAltitude
  );

  const modelTransform = {
    translateX: modelAsMercatorCoordinate.x,
    translateY: modelAsMercatorCoordinate.y,
    translateZ: modelAsMercatorCoordinate.z,
    rotateX: modelRotate[0],
    rotateY: modelRotate[1],
    rotateZ: modelRotate[2],
    /* Since the 3D model is in real world meters, a scale transform needs to be
     * applied since the CustomLayerInterface expects units in MercatorCoordinates.
     */
    scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits() * 2,
  };

  return {
    id: `3d-layer-for-${color}-pt-at-${lat}-${lng}-${alt}`,
    type: 'custom',
    renderingMode: '3d',
    onAdd: function (map: MapboxGlMap, gl: WebGLRenderingContext): void {
      this.camera = new THREE.Camera();
      this.scene = new THREE.Scene();

      // create two three.js lights to illuminate the model
      const directionalLight = new THREE.DirectionalLight(0xffffff);
      directionalLight.position.set(0, -70, 100).normalize();
      this.scene.add(directionalLight);

      const directionalLight2 = new THREE.DirectionalLight(0xffffff);
      directionalLight2.position.set(0, 70, 100).normalize();
      this.scene.add(directionalLight2);

      const loader = new GLTFLoader();
      loader.load(SphereModel, (gltf) => {
        if (!this.scene) return; // This is just to appease TS

        gltf.scene.traverse((o) => {
          if (o instanceof Mesh) o.material = material;
        });

        this.scene.add(gltf.scene);

        if (alt > 0) {
          const geometry = new THREE.CylinderGeometry(0.1, 0.1, alt / 2, 8);
          const cylinder = new THREE.Mesh(geometry, material);
          cylinder.translateY(-alt / 4);
          this.scene.add(cylinder);
        }
      });

      // use the Mapbox GL JS map canvas for three.js
      this.renderer = new THREE.WebGLRenderer({
        canvas: map.getCanvas(),
        context: gl,
        antialias: true,
      });

      this.renderer.autoClear = false;
    },
    render: function (gl: WebGLRenderingContext, matrix: number[]) {
      const rotationX = new THREE.Matrix4().makeRotationAxis(
        new THREE.Vector3(1, 0, 0),
        modelTransform.rotateX
      );
      const rotationY = new THREE.Matrix4().makeRotationAxis(
        new THREE.Vector3(0, 1, 0),
        modelTransform.rotateY
      );
      const rotationZ = new THREE.Matrix4().makeRotationAxis(
        new THREE.Vector3(0, 0, 1),
        modelTransform.rotateZ
      );

      const m = new THREE.Matrix4().fromArray(matrix);
      const l = new THREE.Matrix4()
        .makeTranslation(
          modelTransform.translateX,
          modelTransform.translateY,
          modelTransform.translateZ || 0
        )
        .scale(new THREE.Vector3(modelTransform.scale, -modelTransform.scale, modelTransform.scale))
        .multiply(rotationX)
        .multiply(rotationY)
        .multiply(rotationZ);

      if (this.camera) {
        this.camera.projectionMatrix = m.multiply(l);
      }

      if (this.renderer && this.scene && this.camera) {
        this.renderer.resetState();
        this.renderer.render(this.scene, this.camera);
      }
    },
  };
};
