import React from 'react';

import { Grid, List, ListItem, ListItemText, Paper } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

import PartHistoryEntry from './PartHistoryEntry';

export default function PartHistory({ history }) {
  const [page, setPage] = React.useState(0);
  const perPage = 5;
  const pages = Math.ceil((history?.length || 0) / perPage);

  const slice = history?.slice(page * perPage, (page + 1) * perPage) || [];

  return (
    <Paper>
      <List>
        <ListItem>
          <ListItemText primary='Part Update History' />
        </ListItem>
        {slice.map((partrev) => (
          <PartHistoryEntry key={partrev.id} rev={partrev} />
        ))}

        <ListItem>
          <Pagination
            count={pages}
            page={page + 1}
            onChange={(event, page) => {
              setPage(page - 1);
            }}
          />
        </ListItem>
      </List>
    </Paper>
  );
}
