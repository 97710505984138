import React from 'react';
import { createSelector } from 'reselect';
import * as wkt from 'wellknown';
import { bboxOfWKT } from '~/lib/geography';
import history from '~/lib/history';
import { Markers } from '~/map';
import { paramsForPath } from '~/redux/selectors/global';
import store from '~/store';
import { regionColors } from '~/theme';

import { Button, Typography } from '@material-ui/core';

import HardwarePage from './HardwarePage';
import { actions } from './slice';

export { default as slice } from './slice';

// Does this route apply?
export const matches = (state) => paramsForPath(state, '/hardware');

export const title = () => 'Hardware Management';
export const breadcrumbs = () => ['Hardware Management'];
export const component = () => <HardwarePage />;

export const helpTopic = () => 'Hardware Management';
