import { webrequest } from '~/lib/api';
import { showSnackbar } from '~/lib/modalServices';
import Logging from '~/logging';
import { Org } from '~/schema';
import { Group, Invite } from '~/schema/Org';
import { SVThunkAction } from '~/store';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IndustryType } from '~/markets';

interface OrgState {
  orgs: {
    [id: string]: Org;
  };
  pending: boolean;
  loading: boolean;
  error?: string;
}

// Description of initial state
const slice = createSlice({
  name: 'orgs',
  initialState: {
    orgs: {}, // Data for org pages, indexed by id
  } as OrgState,

  reducers: {
    orgRequestBegin(state) {
      state.pending = true;
      state.loading = false;
      state.error = undefined;
    },
    orgRequestLoading(state) {
      state.loading = true;
    },
    orgRequestComplete(state, action: PayloadAction<{ data?: Org; error?: string }>) {
      const { data, error } = action.payload;

      state.pending = false;
      state.loading = false;
      state.error = error;

      if (data) {
        state.orgs[data.id] = data;
      }
    },
  },
});

const getOrgData =
  (orgid: string): SVThunkAction =>
  async (dispatch) => {
    dispatch(slice.actions.orgRequestBegin());
    const showLoading = setTimeout(() => dispatch(slice.actions.orgRequestLoading()), 500);
    try {
      const data = (await webrequest('GET', `org/${orgid}`)) as Org;
      clearTimeout(showLoading);
      dispatch(slice.actions.orgRequestComplete({ data }));
    } catch (error) {
      Logging.error('Failed to get org list', error);
      clearTimeout(showLoading);
      dispatch(slice.actions.orgRequestComplete({ error }));
    }
  };

const removeUser =
  (email: string, orgid: string): SVThunkAction =>
  async (dispatch) => {
    try {
      await webrequest('DELETE', `orgs/${orgid}/seat/${email}`);
      showSnackbar('success', 'Removed member.');
      dispatch(getOrgData(orgid));
    } catch (error) {
      Logging.error('Failed to remove member', error);
      showSnackbar('error', 'Could not remove member.');
    }
  };

const revokeInvite =
  (inviteid: number, orgid: string): SVThunkAction =>
  async (dispatch) => {
    try {
      await webrequest('DELETE', `sv/invite/${inviteid}`);
      showSnackbar('success', 'Revoked invite.');
      dispatch(getOrgData(orgid));
    } catch (error) {
      Logging.error('Failed to revoke invite', error);
      showSnackbar('error', 'Could not revoke invite.');
    }
  };

const addGroup =
  (group: Group): SVThunkAction =>
  async (dispatch) => {
    try {
      await webrequest('POST', `groups`, group);
      showSnackbar('success', 'Created Group.');
      dispatch(getOrgData(group.org_id));
    } catch (error) {
      Logging.error('Failed to create group', error);
      showSnackbar('error', 'Could not create group.');
    }
  };
const updateGroup =
  (group: Group): SVThunkAction =>
  async (dispatch) => {
    try {
      await webrequest('PUT', `groups/${group.id}`, group);
      showSnackbar('success', 'Updated Group.');
      dispatch(getOrgData(group.org_id));
    } catch (error) {
      Logging.error('Failed to update group', error);
      showSnackbar('error', 'Could not update group.');
    }
  };

const sendInvite =
  (invite: Invite): SVThunkAction =>
  async (_dispatch) => {
    try {
      await webrequest('POST', `sv/invite`, invite);
      showSnackbar('success', 'Invitation sent!');
    } catch (error) {
      Logging.error('Failed to send invitation', error);
      showSnackbar('error', 'Could not send invitation.');
    }
  };

const changeSeatAllowance =
  (orgid: string, seats: number): SVThunkAction =>
  async (dispatch) => {
    try {
      await webrequest('PUT', `orgs/${orgid}/seat_allowance`, seats);
      showSnackbar('success', 'Seats updated.');
      dispatch(getOrgData(orgid));
    } catch (error) {
      Logging.error('Failed to change seat allowance', error);
      showSnackbar('error', 'Could not change seats.');
    }
  };

const setOrgIndustry =
  (orgid: string, industry: IndustryType): SVThunkAction =>
  async (dispatch) => {
    try {
      await webrequest('PUT', `orgs/${orgid}`, { industry });
      showSnackbar('success', 'Updated industry.');
      dispatch(getOrgData(orgid));
    } catch (error) {
      Logging.error('Failed to update industry', error);
      showSnackbar('error', 'Could not update industry.');
    }
  };

export default slice.reducer;

export const actions = {
  ...slice.actions,
  getOrgData,
  removeUser,
  revokeInvite,
  addGroup,
  updateGroup,
  sendInvite,
  changeSeatAllowance,
  setOrgIndustry,
};
