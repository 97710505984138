import store from '~/store';
import { UnitsType } from './geography';

const METERS_PER_MILE = 1609.34;
const METERS_PER_FOOT = 0.3048;
const METERS_PER_INCH = 0.0254;

const MPS_PER_MPH = 0.44704;

export function linear(): string {
  switch (store.getState().global.preferences.units) {
    case 'METRIC':
      return 'm';
    default:
      return 'ft';
  }
}

export function distanceToUser(x: number): number {
  switch (store.getState().global.preferences.units) {
    case 'METRIC':
      return x;
    default:
      return x / METERS_PER_FOOT;
  }
}

export function distanceFromUser(x: number): number {
  switch (store.getState().global.preferences.units) {
    case 'METRIC':
      return x;
    default:
      return x * METERS_PER_FOOT;
  }
}

export function speedToUser(x: number): number {
  switch (store.getState().global.preferences.units) {
    case 'METRIC':
      return x;
    default:
      return x / MPS_PER_MPH;
  }
}

export function speedFromUser(x: number): number {
  switch (store.getState().global.preferences.units) {
    case 'METRIC':
      return x;
    default:
      return x * MPS_PER_MPH;
  }
}

export function distance(x: number): string {
  switch (store.getState().global.preferences.units) {
    case 'METRIC': {
      let value = 0;
      let units = '?';

      if (x >= 1000) {
        value = x / 1000;
        units = 'km';
      } else if (x >= 1) {
        value = x;
        units = 'm';
      } else {
        value = x * 100;
        units = 'cm';
      }

      return `${value.toFixed(2)} ${units}`;
    }
    default: {
      // Imperial

      if (x >= METERS_PER_MILE) {
        const thisvalue = (x / METERS_PER_MILE).toFixed(2);
        return `${thisvalue} mi`;
      } else {
        let result = '';
        let value = x;

        if (value >= METERS_PER_FOOT) {
          const thisvalue = Math.floor(value / METERS_PER_FOOT);
          result += `${thisvalue} ft `;
          value -= thisvalue * METERS_PER_FOOT;
        }
        if (value >= METERS_PER_INCH || result == '') {
          const thisvalue = Math.floor(value / METERS_PER_INCH);
          result += `${thisvalue} in`;
        }

        return result;
      }
    }
  }
}

export function resolutionToStr(mppx: number): string {
  if (!mppx) return '';
  const cmppx = mppx * 100;
  const kmppx = mppx / 1000;

  return mppx > 100
    ? `${kmppx.toFixed(1)} km/px`
    : mppx > 10
    ? `${mppx.toFixed(0)} m/px`
    : cmppx > 10
    ? `${cmppx.toFixed(0)} cm/px`
    : cmppx > 1
    ? `${cmppx.toFixed(1)} cm/px`
    : `${cmppx.toFixed(2)} cm/px`;
}

export function tempUnitText(): string {
  // Note; if you ever add kelvin, it's just K, not °K.
  return `°${store.getState().global.preferences.tempUnit}`;
}

export function degCtoPref(c: number): number {
  switch (store.getState().global.preferences.tempUnit) {
    case 'C':
      return c;
    case 'F':
      return (c * 9) / 5 + 32;
  }
}

export function areatoPref(areaUnit: UnitsType): UnitsType {
  return areaUnit;
}

export function areaUnitText(areaUnit: UnitsType): string | undefined {
  switch (areaUnit) {
    case 'ACRES':
      return 'acres';
    case 'METERS':
      return 'm²';
    case 'FEET':
      return 'ft²';
  }
}
