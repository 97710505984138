import React from 'react';

import { BasicStatsControlComponent, drawGridData } from './common';

const PREF_KEY = 'analysis_cover';
const DEFAULT_PREFS = { stops: [0.5, 0.9, 0.95, 0.99], isPercentile: false };
const CTRL_SZ = [288, 25 * 8];
export const name = 'Ground Cover';
export const description = 'Fraction of the cell covered by vegetation.';

export function overlay(key, analysis, cell_data, preferences, colorForNDVI) {
  const myPreferences = { ...DEFAULT_PREFS, ...(preferences[PREF_KEY] || {}) };

  return drawGridData(
    key,
    'cover',
    myPreferences.stops,
    myPreferences.isPercentile,
    cell_data,
    0.01,
    preferences.analysisOpacity,
    preferences.analysisLabels,
    colorForNDVI,
    (c) => `${c.toFixed(0)}%`
  );
}

function ControlComponent() {
  return (
    <BasicStatsControlComponent
      summary='Fraction of the cell covered by vegetation.'
      helpTopic='Cover'
      pref_key={PREF_KEY}
      default_prefs={DEFAULT_PREFS}
      ctrl_sz={CTRL_SZ}
      allowPercentile
    />
  );
}

export function controls() {
  return {
    width: CTRL_SZ[0],
    height: CTRL_SZ[1],
    component: <ControlComponent />,
  };
}
