import classNames from 'classnames';
import React from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import * as shp from 'shpjs';
import * as wkt from 'wellknown';
import * as Icons from '~/icons';
import * as api from '~/lib/api';
import { showSnackbar } from '~/lib/modalServices';
import Logging from '~/logging';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Slide,
  Typography,
  withMobileDialog,
} from '@material-ui/core';
import { blue, cyan, green, grey, orange } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';

function Transition(props) {
  return <Slide direction='up' {...props} />;
}

const styles = {
  dropZone: {
    position: 'relative',
    width: '100%',
    minHeight: '100px',
    minWidth: 400,
    // backgroundColor: '#F0F0F0',
    border: 'dashed',
    // borderColor: '#C8C8C8',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
    borderRadius: 4,
  },
  activeStripes: {
    border: 'solid',
    backgroundImage:
      'repeating-linear-gradient(-45deg, rgba(0,0,0,0), rgba(0,0,0,0) 25px, rgba(0,0,0,0.1) 25px, rgba(0,0,0,0.1) 50px)',
    animation: 'progress 2s linear infinite !important',
    backgroundSize: '150% 100%',
  },

  fileTable: {
    width: '100%',
    tableLayout: 'auto',
  },

  type: {
    borderRadius: 4,
    width: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  },

  'type-...': {
    backgroundColor: grey[800],
  },
  'type-?': {
    backgroundColor: orange[800],
  },
  'type-ZIP': {
    backgroundColor: green[800],
  },
  'type-SHP': {
    backgroundColor: cyan[800],
  },
  'type-DBF': {
    backgroundColor: blue[800],
  },
  'type-ERR': {
    backgroundColor: orange[800],
  },

  checkbox: {
    padding: 0,
  },
};

function fieldsFromFeature(feature, name) {
  const getSuffix = (i) => {
    const ALPHABET = 'ABCDEFGHIJKKLMNOPQRSTUVWXYZ';
    let output = '';

    for (;;) {
      output = ALPHABET.charAt(i % 26) + output;
      i = Math.floor(i / 26) - 1;
      if (i < 0) {
        return output;
      }
    }
  };

  let polygons = [];
  if (feature.geometry.type.toUpperCase() == 'POLYGON') {
    polygons.push(feature.geometry.coordinates);
  } else if (feature.geometry.type.toUpperCase() == 'MULTIPOLYGON') {
    polygons.push(...feature.geometry.coordinates);
  }

  return polygons.map((poly, i) => ({
    name:
      (feature.properties?.Field || feature.properties?.FIELD || name) +
      (polygons.length > 1 ? ' (' + getSuffix(i) + ')' : ''),
    geometry: { type: 'Polygon', coordinates: poly },
    selected: true,
  }));
}

function fieldFromCollection(collection, name) {
  let fields = [];
  for (const [idx, feature] of collection.features.entries()) {
    // Each feature is a region
    fields.push(...fieldsFromFeature(feature, `${name} #${idx}`));
  }
  return fields;
}

async function createRegions(regions) {
  for (const region of regions) {
    try {
      const newRegion = await api.webrequest('POST', 'regions', region);
    } catch (e) {
      console.error(`Failed to created region: ${e}`);
      showSnackbar('error', 'Failed to import some or all regions.');
    }
  }
  showSnackbar('success', 'Imported all regions, please refresh.');
}

class UploadShapefile extends React.Component {
  state = {
    fileObjects: [],
    fields: [],
  };

  reset() {
    this.setState({
      fileObjects: [],
      fields: [],
    });
  }

  async handleInstantiate() {
    const { farmId, handleClose } = this.props;

    createRegions(
      this.state.fields.map((field) => ({
        id: null,
        type: 'FIELD',
        display_name: field.name,
        farm_id: farmId,
        boundary: wkt.stringify(field.geometry),
      }))
    );
    handleClose();
  }

  async onChange() {
    let fileObjects = this.state.fileObjects;
    let fields = [];

    for (let fileObject of fileObjects) {
      const name = fileObject.file.name;
      const type = fileObject.file.type;

      try {
        if (type == 'application/zip') {
          // Is it a zip?
          const output = shp.parseZip(fileObject.buffer);

          // output is an array of FeatureCollections
          for (const collection of output) {
            fields.push(...fieldFromCollection(collection, name));
          }

          fileObject.type = 'ZIP';
        } else if (type == 'application/x-esri-shape') {
          // Is it a shp?
          // Does it have a matching dbf?
          const dbfname = name.replace(/\.[^/.]+$/, '.dbf');
          const dbf = fileObjects.find((fo) => fo.file.name == dbfname);

          const shpBuffer = fileObject.buffer;

          if (dbf) {
            const combined = shp.combine([
              await shp.parseShp(shpBuffer),
              await shp.parseDbf(dbf.buffer),
            ]);
            fields.push(...fieldFromCollection(combined, name));
          } else {
            const shpfile = await shp.parseShp(shpBuffer);
            shpfile.forEach((g, i) =>
              fields.push(...this.fieldFromFeature({ geometry: g }, `${name} #${i}`))
            );
          }
          fileObject.type = 'SHP';
        } else if (type == 'application/x-dbf') {
          fileObject.type = 'DBF';
        } else {
          fileObject.type = '?';
        }
      } catch (error) {
        Logging.error('Failed to load shapefile', error);
        fileObject.type = 'ERR';
      }
    }

    this.setState({
      ...this.state,
      fields,
      fileObjects,
    });
  }

  onDropAccepted(files) {
    const _this = this;

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        _this.setState(
          {
            ...this.state,
            fileObjects: _this.state.fileObjects.concat({
              file: file,
              buffer: event.target.result,
            }),
          },
          () => {
            _this.onChange(_this.state.fileObjects.map((fileObject) => fileObject.file));
          }
        );
      };
      reader.readAsArrayBuffer(file);
    });
  }

  handleRemove = (fileIndex) => {
    const { fileObjects } = this.state;
    fileObjects.splice(fileIndex, 1);
    this.setState({ ...this.state, fileObjects }, () => {
      this.onChange(this.state.fileObjects.map((fileObject) => fileObject.file));
    });
  };

  render() {
    const { classes, open, farmId, fullScreen, handleClose } = this.props;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        disableBackdropClick
        scroll='paper'
        onClose={handleClose}
        onExited={() => this.reset()}>
        <DialogTitle>Import Shapefiles</DialogTitle>

        <DialogContent>
          <Dropzone
            accept={[
              'application/x-esri-shape',
              'application/x-dbf',
              'application/zip',
              '.shp',
              '.dbf',
              '.zip',
            ]}
            onDropAccepted={this.onDropAccepted.bind(this)}>
            {({ getRootProps, getInputProps, isDragActive }) => {
              return (
                <div
                  {...getRootProps()}
                  className={classNames(classes.dropZone, {
                    [classes.activeStripes]: isDragActive,
                  })}>
                  <input {...getInputProps()} />
                  <Icons.Upload fontSize='large' color='secondary' />

                  <Typography variant='body1'>
                    Select ArcGIS Shape Files (.shp &amp; .dbf, or .zip)
                  </Typography>
                </div>
              );
            }}
          </Dropzone>

          <List dense classes={{ root: classes.fileTable }}>
            {this.state.fileObjects.map((fileObject, idx) => (
              <ListItem key={idx}>
                <div className={classNames(classes.type, classes[`type-${fileObject.type}`])}>
                  <Typography variant='caption' color='inherit'>
                    {fileObject.type || '...'}
                  </Typography>
                </div>
                <ListItemText
                  primaryTypographyProps={{
                    style: { overflow: 'hidden', textOverflow: 'ellipsis' },
                  }}
                  primary={fileObject.file.name}
                />
                <ListItemSecondaryAction>
                  <IconButton onClick={() => this.handleRemove(idx)}>
                    <Icons.Delete />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          {this.state.fields.length ? (
            <>
              <Divider />

              <List dense classes={{ root: classes.fileTable }}>
                {this.state.fields.map((field, idx) => (
                  <ListItem
                    key={idx}
                    button
                    onClick={() => {
                      field.selected = !field.selected;
                      this.setState({ ...this.state });
                    }}>
                    <Checkbox
                      classes={{ root: classes.checkbox }}
                      color='primary'
                      checked={field.selected}
                      tabIndex={-1}
                      disableRipple
                    />

                    <ListItemText
                      primaryTypographyProps={{
                        style: { overflow: 'hidden', textOverflow: 'ellipsis' },
                      }}
                      primary={field.name}
                      secondary={field.size}
                    />
                  </ListItem>
                ))}
              </List>
            </>
          ) : null}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color='secondary'>
            Cancel
          </Button>

          <Button
            onClick={() => this.handleInstantiate()}
            color='primary'
            disabled={this.state.fields.filter((f) => f.selected).length == 0}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(UploadShapefile);
