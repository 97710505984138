import { webrequest } from '~/lib/api';
import { Coordinate } from '~/lib/customHooks';
import { showSnackbar } from '~/lib/modalServices';
import { wait } from '~/lib/time';
import Logging from '~/logging';
import { MissionObject, Survey } from '~/schema';
import { SVDispatch } from '~/store';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface EstimateState {
  estimates: {
    [id: string]: {
      requestNumber: number;
      pending: boolean;
      progress: number;
      result?: MissionObject;
      error?: string;
    };
  };
}

const initialState: EstimateState = {
  estimates: {},
};

const slice = createSlice({
  name: 'estimates',
  initialState,
  reducers: {
    requestBegin(state, action: PayloadAction<{ key: string; requestNumber: number }>) {
      const { key, requestNumber } = action.payload;
      state.estimates[key] = {
        requestNumber,
        pending: true,
        progress: 0,
      };
    },
    requestProgress(state, action: PayloadAction<{ key: string; progress: number }>) {
      const { key, progress } = action.payload;
      state.estimates[key].progress = progress;
    },
    requestComplete(
      state,
      action: PayloadAction<{ key: string; result?: MissionObject; error?: string }>
    ) {
      const { key, result, error } = action.payload;
      state.estimates[key].progress = 100;
      state.estimates[key].pending = false;
      state.estimates[key].result = result;
      state.estimates[key].error = error;
    },
    requestReset(state, action: PayloadAction<{ key: string }>) {
      const { key } = action.payload;
      delete state.estimates[key];
    },
  },
});

export default slice.reducer;

// This is a mapping kept out of redux. Used for debouncing requests.
const latestRequestByKey: { [id: string]: number } = {};
let lastRequestNumber = 0;

export const actions = {
  ...slice.actions,

  // Other actions

  // Request an estimate from the server for the given survey json. Its id acts as the estimate key.
  fetchSurveyEstimate:
    (key: string, survey: Survey, base_location: string) => async (dispatch: SVDispatch) => {
      if (!base_location) {
        Logging.warn("No base location given, can't estimate survey", { survey });
        return;
      }

      const request = {
        survey: {
          type: survey.type,
          farm_id: survey.farm_id,
          keepout_others: survey.keepout_others,
          payload_type: survey.payload_type,
          payload_device_name: survey.payload_device_name,
        }, //filter this explicitly to things the estimator cares about

        // TODO We currently don't have a good way to send in temp target requests.
        // targets: survey.target_requests.filter((tr) => 'flight_region_id' in tr && !tr.deleted),
        // temp_targets: survey.target_requests.filter((tr) => 'boundary' in tr && !tr.deleted),
        targets: survey.target_requests.filter((tr) => !tr.deleted),

        base_location,
      };

      const requestNumber = ++lastRequestNumber;

      latestRequestByKey[key] = requestNumber;

      dispatch(slice.actions.requestBegin({ key, requestNumber }));

      // Debounce this; we won't send a request if within 1 second it has been overridden.
      await wait(1000);

      if (latestRequestByKey[key] != requestNumber) {
        return; // check to make sure we're still the latest request.
      }

      try {
        const wptRequest = (await webrequest('POST', 'sv/waypoints', request)) as {
          request_id: number;
        };
        const serverRequestId = wptRequest.request_id;

        dispatch(slice.actions.requestProgress({ key, progress: 0 }));

        // Request progress every 0.5 seconds or whatever until it returns complete
        for (;;) {
          await wait(500);

          if (latestRequestByKey[key] != requestNumber) {
            // A new request has come in, cancel this one
            await webrequest('DELETE', `sv/waypoints/${serverRequestId}`);
            return;
          }
          const wptUpdate = (await webrequest('GET', `sv/waypoints/${serverRequestId}`)) as {
            status: string;
            progress?: number;
            plan?: MissionObject;
          };
          if (wptUpdate.status == 'complete') {
            const result = wptUpdate.plan;
            dispatch(slice.actions.requestComplete({ key, result }));
            showSnackbar('info', 'Flight plan updated.');
            return;
          } else if (wptUpdate.status == 'failed') {
            dispatch(
              slice.actions.requestComplete({
                key,
                error: `Waypoint request failed to find a route.`,
              })
            );
            showSnackbar('error', 'Could not generate flight plan.');
            return;
          } else {
            dispatch(slice.actions.requestProgress({ key, progress: wptUpdate.progress! }));
          }
        }
      } catch (e: any) {
        // On failure, call the failure action with the appropriate status.
        Logging.error('Could not generate flight plan', e);

        dispatch(slice.actions.requestComplete({ key, error: `Waypoint request failed.` }));
        showSnackbar('error', 'Could not generate flight plan.');
        return;
      }
    },
};
