import React from 'react';
import { getDemoGradient } from '~/lib/colorScales';

import { MenuItem, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  gradientBox: {
    height: 24,
    minWidth: 180,
    width: '100%',
    flexGrow: 1,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(1),
  },
  gradientItem: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight: theme.spacing(3),
  },
}));

export default function ColorPaletteSelector({ value, onChange, ...props }) {
  const classes = styles();

  const getMenuItemForGradient = (choice, title) => (
    <MenuItem key={choice} value={choice}>
      <div className={classes.gradientItem}>
        <Typography variant='caption'>{title}</Typography>
        <div
          className={classes.gradientBox}
          style={{ background: getDemoGradient(choice, 'NDVI') }}
        />
      </div>
    </MenuItem>
  );

  return (
    <TextField
      select
      fullWidth
      margin='dense'
      value={value}
      onChange={(e) => onChange(e.target.value)}
      {...props}>
      {getMenuItemForGradient('normal', 'Red to Green')}
      {getMenuItemForGradient('viridis', 'Viridis')}
      {getMenuItemForGradient('inferno', 'Inferno')}
      {getMenuItemForGradient('grey', 'Grayscale')}
    </TextField>
  );
}
