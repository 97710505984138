import React from 'react';

import { LinearProgress, withStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';

const styles = (theme) => ({
  linearColorPrimary: {
    backgroundColor: fade(theme.palette.primary.main, 0.2),
  },
  linearBarColorPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
});

export default withStyles(styles)(({ progress, classes }) => {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    const t = setTimeout(() => setShow(true), 750);
    return () => clearTimeout(t);
  }, []);

  if (!show) return null;

  return (
    <LinearProgress
      variant={progress === undefined ? 'indeterminate' : 'determinate'}
      value={progress}
      style={{ position: 'absolute', top: 0, left: 0, right: 0 }}
      classes={{
        colorPrimary: classes.linearColorPrimary,
        barColorPrimary: classes.linearBarColorPrimary,
      }}
    />
  );
});
