import React from 'react';
import { connect } from 'react-redux';
import * as Icons from '~/icons';
import { actions as globalActions } from '~/redux/slices/global';
import ButtonWithTip from '~/shared/ButtonWithTip';
import ToggleButtonWithTip from '~/shared/ToggleButtonWithTip';

import { ButtonGroup, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    '& > :not(:last-child)': {
      marginBottom: 4,
    },
  },
};

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&.selected': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary, & .MuiListItemText-secondary': {
        color: theme.palette.primary.contrastText,
      },
    },
  },
}))(MenuItem);

function ShowNotesSelector({ showNotes, setShowNotes, ...props }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const notesOptions = ['Hide notes', 'Show notes only on current imagery', 'Show all notes'];

  return (
    <>
      <ToggleButtonWithTip
        tooltip='Show note pins'
        toggled={anchorEl || showNotes > 0}
        onClick={(evt) => setAnchorEl(evt.currentTarget)}
        {...props}>
        <Icons.NoteMark />
      </ToggleButtonWithTip>

      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        MenuListProps={{ disablePadding: true }}>
        {notesOptions.map((txt, i) => (
          <StyledMenuItem
            key={i}
            classes={{ root: showNotes == i ? 'selected' : 'unselected' }}
            onClick={() => setShowNotes(i)}>
            <ListItemText primary={txt} />
          </StyledMenuItem>
        ))}
      </Menu>
    </>
  );
}

function ImageryAnnotationControls({
  classes,
  isAddingNote,
  addNote,
  activeReportId,
  addToReport,
  tileProgress,

  showNotes,
  setShowNotes,
}) {
  const [activityPending, setActivityPending] = React.useState(false);

  const addToReportWithActivity = async () => {
    setActivityPending(true);
    try {
      await addToReport();
    } catch (e) {
      // No error response here
    } finally {
      setActivityPending(false);
    }
  };

  return (
    <div className={classes.container}>
      {/*       
      <ButtonGroup orientation='vertical' size='small' variant='contained'>
        <ButtonWithTip tooltip='Share page'>
          <Icons.Share />
        </ButtonWithTip>
      </ButtonGroup> */}

      <ButtonGroup color='inherit' orientation='vertical' size='small' variant='contained'>
        {activeReportId && addToReport && (
          <ButtonWithTip
            disabled={tileProgress || activityPending}
            tooltip='Add to report'
            onClick={addToReportWithActivity}>
            <Icons.NewReport />
          </ButtonWithTip>
        )}

        {activeReportId && addToReport && (
          <ButtonWithTip
            tooltip='View pending report'
            onClick={() => window.open(`/report/${activeReportId}`)}>
            <Icons.Report color='primary' />
          </ButtonWithTip>
        )}

        {!activeReportId && addToReport && (
          <ButtonWithTip
            disabled={tileProgress || activityPending}
            tooltip='Create report'
            onClick={addToReportWithActivity}>
            <Icons.NewReport />
          </ButtonWithTip>
        )}
      </ButtonGroup>

      <ButtonGroup color='inherit' orientation='vertical' size='small' variant='contained'>
        {addNote && <ShowNotesSelector showNotes={showNotes} setShowNotes={setShowNotes} />}
        {addNote && (
          <ToggleButtonWithTip
            tooltip='Add note'
            disabled={showNotes == 0} // I don't want to deal with creating notes while they're hidden...
            toggled={isAddingNote}
            onClick={addNote}>
            <Icons.Notes />
          </ToggleButtonWithTip>
        )}
        {/*  TODO: Add back in the measurement tool when you have time
        <ButtonWithTip tooltip='Measure distances and areas'>
          <Icons.Measure />
        </ButtonWithTip> */}
      </ButtonGroup>
    </div>
  );
}

export default withStyles(styles)(
  connect(
    (state) => ({
      showNotes: state.global.preferences.showNotes,
      tileProgress: state.ui.tileProgress,
    }),
    (dispatch) => ({
      setShowNotes: (x) => dispatch(globalActions.setPreference({ showNotes: x })),
    })
  )(ImageryAnnotationControls)
);
