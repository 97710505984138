import classnames from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import * as Icons from '~/icons';
import { actions as uiactions } from '~/redux/slices/ui';

import {
  Badge,
  Button,
  IconButton,
  Paper,
  Popover,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

import MapContext from '../mapContext';
import DraggableMarker from './DraggableMarker';

const styles = makeStyles((theme) => ({
  icon: {
    borderRadius: '50%',
    borderColor: fade(theme.palette.primary.main, 0.5),
    borderStyle: 'solid',
    borderWidth: 4,
    overflow: 'hidden',
    background: theme.palette.background.paper,
    color: theme.palette.primary.main,
    filter: 'drop-shadow(0px 4px 2px rgba(0,0,0,0.3))',
    cursor: 'pointer',
    width: 24,
    height: 24,
  },
}));

function SampleEdit({ anchorEl, onClose, alt, setAlt }) {
  return (
    <Popover
      hideBackdrop={false}
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}>
      <Paper style={{ display: 'flex' }}>
        <TextField
          fullWidth
          autoFocus
          type='number'
          inputProps={{ min: 10, max: 400, step: 5 }}
          onChange={(evt) => {
            setAlt(parseInt(evt.target.value));
          }}
          label='Altitude (ft)'
          onClick={(evt) => evt.stopPropagation()}
          value={alt}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              onClose();
            }
          }}
        />
        <IconButton
          fullWidth
          onClick={() => {
            setAlt(0);
          }}>
          <Icons.Delete />
        </IconButton>
      </Paper>
    </Popover>
  );
}

export default function PlaceSampleMarker({ location, alt, setCoordinate, ...other }) {
  const ref = React.useRef();

  const [edit, setEdit] = React.useState(false);
  const [tmpAlt, setTmpAlt] = React.useState(null);

  const classes = styles();
  const altFt = Math.round(alt * 3.28084);

  const handleClick = (evt) => {
    setTmpAlt(altFt);
    setEdit(true);
  };

  return (
    <>
      <MapContext.Consumer>
        {(map) => (
          <DraggableMarker
            draggable
            coordinate={location}
            map={map}
            onDrag={(coord) => setCoordinate([coord[0], coord[1], alt])}
            onClick={handleClick}
            {...other}>
            <Badge badgeContent={`${altFt}'`} color='primary'>
              <div ref={ref} className={classes.icon} />
            </Badge>
          </DraggableMarker>
        )}
      </MapContext.Consumer>
      <SampleEdit
        anchorEl={edit ? ref.current : null}
        onClose={() => {
          setEdit(false);
          setCoordinate([location[0], location[1], tmpAlt / 3.28084]);
          setTmpAlt(null);
        }}
        alt={tmpAlt}
        setAlt={(alt) => {
          if (alt > 0) {
            setTmpAlt(alt);
          } else {
            setEdit(false);
            setCoordinate(null);
            setTmpAlt(null);
          }
        }}
      />
    </>
  );
}
