import * as wkt from 'wellknown';
import { byId, byNumId, webrequest } from '~/lib/api';
import history from '~/lib/history';
import { showSnackbar } from '~/lib/modalServices';
import Logging from '~/logging';

import type { TargetRequest, Survey, Region, Asset } from '~/schema';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { SVDispatch } from '~/store';
import { DateTime } from 'luxon';

interface SurveyState {
  farms: Record<
    string,
    { pending: boolean; loading: boolean; error: null | string; base_locations?: string[] }
  >;
  regions: Record<number, Region>;
  assets: Record<number, Asset>;
  surveys: Record<number, Survey>;
  tmpSurveys: Record<number | string, Survey>;
  editingTarget: number | null;
  mapState: 'place_base' | null;
}

interface SVSurveysApiType {
  farmId: string;
  surveys?: Survey[];
  regions?: Region[];
  assets?: Asset[];
  target_requests?: TargetRequest[];
}

const slice = createSlice({
  name: 'surveys',
  initialState: {
    farms: {
      /* farmId: {
        pending: bool, // is waiting for a response
        loading: bool, // should show a loading indicator
        error: null / str,
        base_locations: [str] // array of wkt points for known real base locations
        */
    },

    regions: {}, // by id, needed as possible targets for surveys we edit
    assets: {},
    surveys: {}, // by id
    tmpSurveys: {}, // by id

    editingTarget: null, // which target within the survey we're showing a dialog for

    mapState: null, // 'place_base'
  } as SurveyState,

  reducers: {
    setMapState(state, action: PayloadAction<SurveyState['mapState']>) {
      state.mapState = action.payload;
    },
    requestBegin(state, action: PayloadAction<string>) {
      const farmId = action.payload;
      state.farms[farmId] = { pending: true, loading: false, error: null };
    },
    requestLoading(state, action: PayloadAction<string>) {
      const farmId = action.payload;
      state.farms[farmId] = { pending: true, loading: true, error: null };
    },
    requestComplete(
      state,
      action: PayloadAction<
        {
          error?: string;
        } & SVSurveysApiType
      >
    ) {
      const { farmId, surveys, regions, assets, target_requests, error } = action.payload;

      if (error) {
        state.farms[farmId] = { pending: false, loading: false, error };
      } else {
        state.farms[farmId] = {
          pending: false,
          loading: false,
          error: null,
        };
        Object.assign(state.regions, byNumId<Region>(regions || []));
        Object.assign(state.assets, byNumId<Asset>(assets || []));

        surveys?.forEach((s) => {
          state.surveys[s.id] = {
            ...s,
            target_requests: target_requests?.filter((t) => t.survey_id == s.id) || [],
          };
        });
      }
    },

    setEditingSurveyTarget(state, action: PayloadAction<number | null>) {
      state.editingTarget = action.payload;
    },

    setTmpSurvey(state, action: PayloadAction<{ id: number; survey: Survey | undefined }>) {
      const { id, survey } = action.payload;
      if (survey === undefined) {
        delete state.tmpSurveys[id];
      } else {
        state.tmpSurveys[id] = survey;
      }
    },

    uploadBegin(_state, _action: PayloadAction<void>) {
      // TODO: some kind of spinner? do we care?
    },
    uploadComplete(
      state,
      action: PayloadAction<{ survey: Survey; target_requests: TargetRequest[] }>
    ) {
      const { survey, target_requests } = action.payload;
      if (survey) {
        delete state.tmpSurveys[survey.id];
        if (!survey.deleted) {
          state.surveys[survey.id] = { ...survey, target_requests };
        } else {
          delete state.surveys[survey.id];
        }
      }
    },
  },
});

// Requests surveys (and associated regions) for a farm
export const getFarmSurveys = (farmId: string) => async (dispatch: SVDispatch) => {
  dispatch(slice.actions.requestBegin(farmId));
  const showLoading = setTimeout(() => dispatch(slice.actions.requestLoading(farmId)), 500);

  try {
    const data = (await webrequest('GET', `sv/farm/${farmId}/surveys`)) as SVSurveysApiType;
    clearTimeout(showLoading);
    dispatch(
      slice.actions.requestComplete({
        farmId,
        surveys: data.surveys,
        regions: data.regions,
        assets: data.assets,
        target_requests: data.target_requests,
      })
    );
  } catch (error: any) {
    Logging.error('Failed to load farm surveys', error);

    clearTimeout(showLoading);
    dispatch(slice.actions.requestComplete({ farmId, error }));
  }
};

// This can be existing or new.
export const uploadSurvey = (survey: Survey) => async (dispatch: SVDispatch) => {
  dispatch(slice.actions.uploadBegin());

  try {
    const result = survey.id
      ? ((await webrequest('PUT', `surveys/${survey.id}`, {
          ...survey,
          target_requests: undefined,
        })) as Survey)
      : ((await webrequest('POST', `surveys`, {
          // Provide some useful defaults for fields that we're deprecating
          start_date: DateTime.now().toISODate(),
          window_start: '00:00:00',
          window_end: '23:59:59',
          frequency: 'ONCE',
          ...survey,
          id: undefined, // falsy isn't good enough, need undefined
          target_requests: undefined,
        })) as Survey);

    const newtrs: TargetRequest[] = [];

    // We need to either add or delete target requests.
    for (const tr of survey.target_requests) {
      if (tr.id) {
        // The only way we're allowed to modify a TR is to delete it
        if (tr.deleted) {
          await webrequest('DELETE', `target_requests/${tr.id}`);
        } else {
          newtrs.push(tr); // this one survives
        }
      } else if (!tr.deleted) {
        // Add this TR

        const trResult = (await webrequest('POST', `target_requests`, {
          ...tr,
          survey_id: result.id,
        })) as TargetRequest;
        newtrs.push(trResult);
      }
    }
    showSnackbar('success', 'Survey updated.');

    // Save this new stuff into our datastore.
    dispatch(
      actions.uploadComplete({
        survey: result,
        target_requests: newtrs,
      })
    );
    history.push(`/surveys?site=${survey.farm_id}`);
  } catch (error: any) {
    Logging.error('Failed to post survey', error);

    showSnackbar('error', 'Failed to save survey.');
  }
};

export default slice.reducer;

export const actions = {
  ...slice.actions,
  getFarmSurveys,
  uploadSurvey,
};
