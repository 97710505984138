import { routerMiddleware } from 'connected-react-router';
import { AnyAction, applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware, { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { createLogger } from 'redux-logger';
import {
  setContrastLevels,
  setContrastLevelsMapB,
  setWindowRange,
  setWindowRangeMapB,
} from '~/lib/customLayers';
import history from '~/lib/history';
import reducers from '~/redux/reducers';

function configureStore() {
  const options = {
    trace: true,
  };
  const logger = createLogger();
  const store = createStore(
    reducers(history),
    undefined,
    composeWithDevTools(options)(
      applyMiddleware(thunkMiddleware, routerMiddleware(history), logger)
    )
  );

  // Configure global subscribers:

  // This is the special subscriber for the map's shader parameters
  let oldWindowRange: number[] | undefined;
  let oldWindowRangeMapB: number[] | undefined;
  let oldContrastLevels: number[] | undefined;
  let oldContrastLevelsMapB: number[] | undefined;
  store.subscribe(() => {
    // The window range must be updated whenever we change channel.

    const newWindowRange =
      store.getState().ui.windowRange[store.getState().global.preferences.channel[0]];
    const newWindowRangeMapB =
      store.getState().ui.windowRangeMapB[store.getState().global.preferences.channel[1]];
    const newContrastLevels = store.getState().ui.contrastLevels;
    const newContrastLevelsMapB = store.getState().ui.contrastLevelsMapB;

    if (newWindowRange != oldWindowRange) {
      oldWindowRange = newWindowRange;
      if (newWindowRange) setWindowRange(newWindowRange);
    }

    if (newWindowRangeMapB != oldWindowRangeMapB) {
      oldWindowRangeMapB = newWindowRangeMapB;
      if (newWindowRangeMapB) setWindowRangeMapB(newWindowRangeMapB);
    }

    if (newContrastLevels != oldContrastLevels) {
      oldContrastLevels = newContrastLevels;
      setContrastLevels(newContrastLevels);
    }

    if (newContrastLevelsMapB != oldContrastLevelsMapB) {
      oldContrastLevelsMapB = newContrastLevelsMapB;
      setContrastLevelsMapB(newContrastLevelsMapB);
    }
  });

  return store;
}

const store = configureStore();

export type RootState = ReturnType<typeof store.getState>;
export type SVDispatch = ThunkDispatch<RootState, any, AnyAction>;
export type SVThunkAction = ThunkAction<void, RootState, unknown, AnyAction>;

export default store;
