import React from 'react';
import { connect } from 'react-redux';
import * as Icons from '~/icons';
import { regionColors } from '~/theme';

import { Avatar } from '@material-ui/core';

class RegionAvatar extends React.Component {
  iconForRegion = () => {
    const { cropTypes, type, crop } = this.props;

    switch (type) {
      case 'FIELD': {
        const CropIcon = Icons.CropIcon(cropTypes[crop]?.icon);
        return <CropIcon />;
      }
      case 'FLIGHT':
        return <Icons.Drone />;
      case 'TARGETED':
        return <Icons.Targeted />;
      case 'ZONE':
        return <Icons.Zones />;
      case 'KEEPOUT':
        return <Icons.Keepout style={{ marginLeft: -3 }} />;
    }
  };

  render() {
    const { type } = this.props;

    return (
      <Avatar style={{ backgroundColor: regionColors[type], color: 'white' }}>
        {this.iconForRegion()}
      </Avatar>
    );
  }
}
const mapStateToProps = (state) => ({
  cropTypes: state.global.cropTypes,
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RegionAvatar);
