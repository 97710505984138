import axios from 'axios';
import React from 'react';
import { showSnackbar } from '~/lib/modalServices';
import Logging from '~/logging';
import { useSVSelector } from '~/redux/hooks';
import store from '~/store';
import { getScoutviewBuild, getScoutviewCommit } from '~/svVersion';

import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from '@material-ui/core';

async function makeFeedbackRequest(text: string, diag: any, version: string) {
  const feedback = {
    text: text,
    diag: diag ? store.getState() : false,
    version,
  };

  return axios({
    method: 'POST',
    url: '/api/sv/feedback',
    responseType: 'json',
    withCredentials: false,
    data: feedback,
  })
    .then(() => {
      return true;
    })
    .catch((err) => {
      Logging.error('Failed to upload feedback', err);

      return false;
    });
}

interface Props {
  open: boolean;
  onClose: () => void;
  initialText?: string;
}

export default function FeedbackDialog({ open, onClose, initialText }: Props) {
  const [pending, setPending] = React.useState(false);

  const serverVersion = useSVSelector((state) => state.global.version);

  const clientVersionStr = `${getScoutviewBuild()}:${getScoutviewCommit()}`;
  const serverVersionStr = `${serverVersion?.build || '?'}:${serverVersion?.commit || '?'}`;

  const versionStr = `Server: ${serverVersionStr}, Client: ${clientVersionStr}`;

  const [text, setText] = React.useState(initialText || '');
  const [diag, setDiag] = React.useState(false);

  return (
    <Dialog open={open} maxWidth='sm' fullWidth>
      <DialogTitle>Feedback</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          fullWidth
          multiline
          disabled={pending}
          rows='6'
          label='Describe your issue or suggestion'
          value={text}
          onChange={(e) => setText(e.target.value)}
        />

        <FormControlLabel
          control={
            <Checkbox
              disabled={pending}
              color='primary'
              checked={diag}
              onChange={(e) => setDiag(e.target.checked)}
            />
          }
          label='Include diagnostics for debugging'
        />
      </DialogContent>
      <DialogActions>
        {pending && <CircularProgress variant='indeterminate' />}
        <Button disabled={pending} onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={async () => {
            setPending(true);
            if (await makeFeedbackRequest(text, diag, versionStr)) {
              showSnackbar('success', 'Thank you for your feedback');
            } else {
              showSnackbar('warning', 'Problem sending feedback, please try again');
            }
            onClose();
            setPending(false);
          }}
          variant='contained'
          disabled={text.length < 5 || pending}
          color='primary'>
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}
