import * as geography from '~/lib/geography';
import * as wkt from 'wellknown';

import { getFocussedSiteAssets, focussedSite, getTmpAssetWithUsershape } from './selectors';

import EditAssetPanel from './EditAssetsPanel';
import AssetsTreeViewPanel from './AssetsTreeViewPanel';
import OrgSiteLink from '~/shared/Appbar/OrgSiteLink';
import React from 'react';
import store from '~/store';
import { createSelector } from 'reselect';
import { getUsershapeEditing } from '~/redux/slices/usershape';
import { gridForAsset } from '~/lib/analysisGrid';
import { getQueryOrgAndSite, getQueryParams, paramsForPath } from '~/redux/selectors/global';
import { regionColors } from '~/theme';
import { get3DPointLayer } from './utils';
import { lightBlue } from '@material-ui/core/colors';
import { AnySourceData, Layer } from 'mapbox-gl';
import { RootState } from '~/store';
import { isArray } from 'lodash';
import { Geometry } from 'geojson';
import type { SVDispatch } from '~/store';
import { createTmpAsset, fetchAssetsBySiteId } from './slice';
export { default as slice } from './slice';

// Does this route apply?
export const matches = (state: RootState) => paramsForPath(state, '/assets');

export const title = () => 'Manage Assets';
export const breadcrumbs = () => ['Manage Assets', React.createElement(OrgSiteLink)];

// Mapbox style data
export const mapStyle = createSelector(
  focussedSite,
  getFocussedSiteAssets,
  getFocussedSiteAssets,
  getTmpAssetWithUsershape,
  (state) => state.assetsScene.highlightedAssets,
  getUsershapeEditing,
  (site, assets, filtered, tmpAsset, highlightedAssets, usershapeEditing) => {
    const extras: { layers: Layer[]; sources: { [key: string]: AnySourceData } } = {
      layers: [],
      sources: {},
    };

    if (tmpAsset?.shape) {
      const shape = wkt.parse(tmpAsset.shape);

      if (shape) {
        if (!usershapeEditing) {
          const grid_geometries = gridForAsset(tmpAsset);
          extras.sources['grid-src'] = {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: grid_geometries.map(
                (s, i) =>
                  ({
                    type: 'Feature',
                    geometry: s,
                  } as GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>)
              ),
            },
          };

          extras.layers.push({
            id: `grid-preview`,
            type: 'line',
            source: `grid-src`,
            paint: {
              'line-width': 10,
              'line-color': regionColors['FIELD'],
            },
          });
        }
      }
    }

    assets.filter((a) => !highlightedAssets.includes(a));
    assets.forEach((a) => {
      if (!a.shape) {
        return;
      }

      const assetGeo = wkt.parse(a.shape);
      if (assetGeo?.type == 'Point' && isArray(assetGeo.coordinates)) {
        if (highlightedAssets.includes(a)) {
          extras.layers.push(
            get3DPointLayer(
              assetGeo.coordinates[0],
              assetGeo.coordinates[1],
              assetGeo.coordinates[2] || 0,
              { color: 0xffff00 }
            )
          );
        } else {
          extras.layers.push(
            get3DPointLayer(
              assetGeo.coordinates[0],
              assetGeo.coordinates[1],
              assetGeo.coordinates[2] || 0,
              { color: lightBlue[400] }
            )
          );
        }
      }
    });

    highlightedAssets.forEach((asset) => {
      if (asset.shape && wkt.parse(asset.shape)) {
        extras.sources[`boundary-for-highlighted-asset-${asset.id}`] = {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: wkt.parse(asset.shape) as Geometry,
            properties: {},
          },
        };
      }

      extras.layers.push({
        id: `line-for-highlighted-asset-${asset.id}`,
        type: 'line',
        source: `boundary-for-highlighted-asset-${asset.id}`,
        paint: {
          'line-width': 8,
          'line-color': regionColors['FIELD'],
        },
      });
    });

    const assetsToDisplay: any[] = assets
      .filter((a) => a.id != tmpAsset?.id)
      .map((a) => {
        const showDetails = filtered.includes(a) && (!tmpAsset || a.id == tmpAsset.id);
        return {
          id: a.id,
          shape: a.shape,
          display_name: a.display_name,
          color: regionColors['FIELD'],
          outline: filtered.includes(a),
          fill: showDetails,
          name: showDetails,
        };
      });

    if (site && site.keepin) {
      const keepinOutline = {
        id: `${site.id}-keepin-asset`,
        shape: site.keepin,
        color: regionColors['FARM'],
        outline: true,
        fill: false,
        name: false,
      };

      assetsToDisplay.push(keepinOutline);
    }

    return {
      imagery: [],
      assets: assetsToDisplay,
      extras,
    };
  }
);

// Panel
export const panel = createSelector(
  (state: RootState) => state.assetsScene.tmpAsset,
  (tmpAsset) => {
    return tmpAsset ? <EditAssetPanel /> : <AssetsTreeViewPanel />;
  }
);

export const fetch = createSelector(getQueryOrgAndSite, getQueryParams, (orgAndSite, query) => {
  const dispatch: SVDispatch = store.dispatch as SVDispatch;
  const focussedSiteId = orgAndSite.siteId;
  const assetIdParam = query?.get('assetid');

  if (focussedSiteId) {
    dispatch(fetchAssetsBySiteId(focussedSiteId));
  }

  if (assetIdParam === 'new') {
    dispatch(createTmpAsset(0));
  } else if (assetIdParam) {
    dispatch(createTmpAsset(Number(assetIdParam)));
  }
});

export const defaultBounds = createSelector(
  focussedSite,
  (state) => state.assetsScene.selectedAssets,
  (site, selectedAssets) => {
    if (selectedAssets?.length > 0) {
      const assetsWithShapes = selectedAssets.filter((a) => a.shape);
      const shapeBoxes: [[number, number], [number, number]][] = assetsWithShapes.map(
        (a) => geography.bboxOfWKT(a.shape as string) as [[number, number], [number, number]]
      );
      const boxPoints = shapeBoxes.reduce((arr, x) => arr.concat(x), [] as number[][]);
      return geography.bboxOfPoints(boxPoints);
    }

    if (site && site.keepin) {
      return geography.bboxOfWKT(site.keepin as string);
    }

    return null;
  }
);

// TODO: Determine desired asset onClick behavior for map (if any)
// export const clickableFeatures = createSelector(
//   getFocussedSiteAssets,
//   assetOpenForEditingId,
//   (assets, editingId) => {
//     if (editingId) return [];

//     return assets.map((a) => ({
//       feature: a.shape,
//       cursor: 'pointer',
//       onClick: () =>
//         history.push({
//           pathname: '/assets',
//           search: `?assetid=${a.id}`,
//         }),
//     }));
//   }
// );

export const editingUsershape = createSelector(
  (state: RootState) => state.assetsScene.tmpAsset,
  (a) => {
    if (a == null) return null;

    const allowed = ['line', 'polygon', 'rectangle', 'circle', 'point'];

    return {
      color: regionColors['FIELD'], // Asset color scheme TBD
      allowed,
      allowPolyHoles: true,
      allowMultiPolygons: true,
    };
  }
);
export const helpTopic = () => 'Edit Assets';
