export function getUpdatedTRList(oldTRQs, newTRQ, idx) {
  // TODO:  Do any sanity checks on tgt?

  let old_target_requests = [...oldTRQs];
  if (old_target_requests[idx].id) {
    // if this was previously saved, mark it deleted
    old_target_requests[idx] = {
      ...old_target_requests[idx],
      deleted: true,
    };
  } else {
    // if it was a new temp one anyway, remove it.
    old_target_requests.splice(idx, 1);
  }
  // add this new target
  const new_target_requests = [
    ...old_target_requests,
    {
      ...newTRQ,
      id: null,
    },
  ];

  return new_target_requests;
}
