import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import * as Icons from '~/icons';
import { actions as noteActions } from '~/redux/slices/notes';
import ExistingNote from '~/shared/Notes/ExistingNote';
import PendingNote from '~/shared/Notes/PendingNote';

import { Badge, Popover } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import MapContext from '../mapContext';
import SimpleMarker from './SimpleMarker';

const styles = {
  popover: {
    minWidth: 300,
    maxWidth: 500,
  },
};

function Note({
  classes,
  isOpen,
  openNote,
  pending,
  closeNote,
  note,
  noteId,
  location,
  hidden,
  ...other
}) {
  const ref = React.useRef();

  if (!location) return null;

  const openMe = () => {
    if (note.id) {
      openNote(note.id);
    } else {
      return;
    }
  };

  const NoteIcon = hidden ? Icons.NoteMarkOld : Icons.NoteMark;

  return (
    <>
      <MapContext.Consumer>
        {(map) => (
          <SimpleMarker coordinate={location} map={map} {...other}>
            <Badge
              badgeContent={note.comments?.length}
              color='secondary'
              style={{
                transform: 'translateY(-50%)',
              }}>
              <NoteIcon
                id='simple-test'
                ref={ref}
                style={{
                  color: 'white',
                  filter: 'drop-shadow(0px 4px 2px rgba(0,0,0,0.3))',
                  cursor: 'pointer',
                }}
                onClick={openMe}
              />
            </Badge>
          </SimpleMarker>
        )}
      </MapContext.Consumer>

      <Popover
        open={isOpen || pending || false}
        disableBackdropClick={pending}
        disableEscapeKeyDown={pending}
        anchorEl={ref.current}
        onClose={closeNote}
        BackdropProps={{ invisible: false }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        classes={{ paper: classes.popover }}>
        {noteId ? <ExistingNote note={note} /> : <PendingNote />}
      </Popover>
    </>
  );
}

const getNote = (state, noteId) =>
  noteId ? state.notes.notesById[noteId] : state.notes.pendingNote;

const getLocation = createSelector(getNote, (note) => note?.location);

export default withStyles(styles)(
  connect(
    (state, props) => ({
      note: getNote(state, props.noteId),
      location: getLocation(state, props.noteId),
      isOpen: (props.noteId && state.notes.visibleNote == props.noteId) || props.noteId == null,
    }),
    (dispatch) => ({
      openNote: (id) => dispatch(noteActions.viewNote(id)), // this would have no effect on pending notes
      closeNote: () => dispatch(noteActions.viewNote(null)), // this would have no effect on pending notes
    })
  )(Note)
);
