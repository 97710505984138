import stringify from 'json-stable-stringify';
import Rollbar from 'rollbar';
import { getScoutviewCommit } from '~/svVersion';

let rollbar: Rollbar | undefined;

let lastMessage: Rollbar.LogArgument | null = null;
let lastExtra: Rollbar.LogArgument | null = null;

const environment =
  {
    'scoutview.american-robotics.com': 'production',
    'scoutview-dev.american-robotics.com': 'scoutview-dev',
  }[window.location.hostname] || 'development';

function debounce(msg: Rollbar.LogArgument, extra: Rollbar.LogArgument) {
  if (msg == lastMessage) {
    if (extra == lastExtra) return true;
    try {
      if (stringify(extra) == stringify(lastExtra)) {
        return true;
      }
    } catch {
      if (environment == 'development') console.warn(`Cannot stringify extra: ${extra}`);
    }
  }
  lastMessage = msg;
  lastExtra = extra;
  return false;
}

function initializeRollbar() {
  if (rollbar == null && environment != 'development') {
    rollbar = new Rollbar({
      accessToken: 'd2ded1163af84db5a188dbacc5a6280a',
      verbose: true,
      hostSafeList: ['scoutview.american-robotics.com', 'scoutview-dev.american-robotics.com'], // reduce noise from apps outside the codebase
      payload: {
        environment,
        client: {
          javascript: {
            source_map_enabled: true, // true by default
            guess_uncaught_frames: true,
            code_version: getScoutviewCommit(),
          },
        },
      },
      captureUncaught: true,
      captureUnhandledRejections: true,
      autoInstrument: true,
      addErrorContext: true,
    });
    rollbar?.global({ itemsPerMinute: 6 });
  }
}

const Logging = {
  setUserId(userId: string | null) {
    initializeRollbar();
    rollbar?.configure({
      payload: {
        person: {
          id: userId,
        },
      },
    });
  },

  error(msg: Rollbar.LogArgument, extra?: Rollbar.LogArgument) {
    initializeRollbar();
    if (debounce(msg, extra)) return;
    if (rollbar) {
      rollbar.error(msg, extra);
    } else {
      console.error(msg);
      if (extra) console.error(extra);
    }
  },

  warn(msg: Rollbar.LogArgument, extra?: Rollbar.LogArgument) {
    initializeRollbar();
    if (debounce(msg, extra)) return;
    if (rollbar) {
      rollbar.warn(msg, extra);
    } else {
      console.warn(msg);
      if (extra) console.warn(extra);
    }
  },
};

export default Logging;
