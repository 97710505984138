import * as api from '~/lib/api';
import { webrequest } from '~/lib/api';
import { showSnackbar } from '~/lib/modalServices';
import Logging from '~/logging';
import { actions as globalActions } from '~/redux/slices/global';
import { actions as usershapeActions } from '~/redux/slices/usershape';

import { createSlice } from '@reduxjs/toolkit';

// Description of initial state
const slice = createSlice({
  name: 'farms',
  initialState: {
    details: {}, // by farm id, includes safe spots and field boundaries.
    tmpFarms: {}, // by farm id, holding edits
    editingKeepin: false,
  },
  reducers: {
    setEditingKeepin(state, action) {
      state.editingKeepin = action.payload;
    },
    requestBegin(state, action) {
      const { farmId } = action.payload;
      state.details[farmId] = {
        pending: true,
        loading: false,
        error: null,
      };
    },
    requestLoading(state, action) {
      const { farmId } = action.payload;
      state.details[farmId] = {
        pending: true,
        loading: true,
        error: null,
      };
    },
    requestSuccess(state, action) {
      const { farmId, data } = action.payload;
      state.details[farmId] = {
        pending: false,
        loading: false,
        error: null,
        ...data,
      };
    },
    requestFailure(state, action) {
      const { farmId, error } = action.payload;
      state.details[farmId] = {
        pending: false,
        loading: false,
        error,
      };
    },

    populateNewFarm(state, action) {
      state.tmpFarms[''] = {
        id: '',
        // TODO: New farm data
      };
    },

    setTmpFarm(state, action) {
      const { id, farm } = action.payload;
      state.tmpFarms[id] = farm;
    },

    uploadBegin(state, action) {
      // TODO: some kind of spinner? do we care?
    },
    uploadComplete(state, action) {
      // TODO: Figure out what this means. payload can be { error }
      // If it's success, remove it from the tmp table. Update the main table regardless
      const { farmid, ssls, data, error } = action.payload;
      if (data) {
        state.tmpFarms[data.id] = undefined;
        state.details[farmid] = { ...data, safelandlocations: ssls };
      }
    },
  },
});

const getFarmDetails = (farmId) => async (dispatch) => {
  dispatch(slice.actions.requestBegin({ farmId }));

  const showLoading = setTimeout(() => dispatch(slice.actions.requestLoading({ farmId })), 500);

  try {
    const data = await api.webrequest('GET', `sv/farm/${farmId}`);
    clearTimeout(showLoading);
    dispatch(slice.actions.requestSuccess({ farmId, data }));
  } catch (error) {
    Logging.error('Failed to load farm data', error);
    clearTimeout(showLoading);
    dispatch(slice.actions.requestFailure({ farmId, error }));
  }
};
export const editFarm = (farmId) => async (dispatch, getState) => {
  // keepin is global.
  const existing = getState().global.farms[farmId];

  dispatch(slice.actions.setEditingKeepin(false));
  if (existing) {
    dispatch(usershapeActions.setUsershapeFromWKT({ wktstr: existing.keepin }));
  } else {
    dispatch(usershapeActions.resetShape());
  }
};

export const editNewFarm = (farmId) => async (dispatch) => {
  dispatch(actions.populateNewFarm(farmId));
  dispatch(usershapeActions.setUsershape({ regionKey: '' }));
};

// This can be existing or new.
export const uploadFarm = (id, farm) => async (dispatch) => {
  dispatch(slice.actions.uploadBegin());

  // Have to pull these fields out of the farm object, they aren't really there.
  const cleanFarm = {
    ...farm,
    fields: undefined,
    safelandlocations: undefined,
    available_systems: undefined,
  };

  // is 'var' so we can update it
  const safelandlocations = [...farm.safelandlocations];
  try {
    const data =
      id == 'new'
        ? await webrequest('POST', `farms`, cleanFarm)
        : await webrequest('PUT', `farms/${farm.id}`, cleanFarm);

    // Update the safe land locations

    let newssls = [];
    // Also delete, update, or post any safe spots that have changed.
    for (const ss of safelandlocations) {
      if (ss.id) {
        if (ss.deleted) {
          // Delete
          await webrequest('DELETE', `safelandlocations/${ss.id}`);
        } else {
          // Update
          const ssresult = await webrequest('PUT', `safelandlocations/${ss.id}`, {
            ...ss,
            deleted: undefined,
            farm_id: data.id,
          });
          newssls.push(ssresult);
        }
      } else if (!ss.deleted) {
        // Create
        const ssresult = await webrequest('POST', `safelandlocations`, {
          ...ss,
          deleted: undefined,
          farm_id: data.id,
        });
        newssls.push(ssresult);
      }
    }

    dispatch(globalActions.updateSingleFarm({ farm: data }));
    dispatch(slice.actions.uploadComplete({ farmid: id, ssls: newssls, data }));

    showSnackbar('success', 'Farm updated.');
    // update global data to fetch it
    // dispatch(slice.actions.fetchGlobalData())

    // history.push('/farms')
  } catch (error) {
    dispatch(slice.actions.uploadComplete({ error }));
    Logging.error(`Error uploading farm`, error);
    showSnackbar('error', 'Failed to save farm.');
  }
};

export default slice.reducer;

export const actions = {
  ...slice.actions,
  getFarmDetails,
  uploadFarm,
  editNewFarm,
  editFarm,
};
