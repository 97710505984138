import classNames from 'classnames';
import React from 'react';
import { ExpandMore } from '~/icons';

import { ButtonBase, Divider, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  moduleView: {
    position: 'relative',
    height: 0,
    minHeight: 0,
    transition: 'min-height 0.25s',
    overflow: 'hidden',
  },
  moduleViewOpen: {
    height: 'auto',
    flexShrink: 0,
  },
  moduleViewScroll: {
    overflowY: 'auto',
    flexShrink: 1,
  },

  shrinkButton: {
    margin: theme.spacing(1),
    transition: 'transform 0.25s ease',
  },
  rotated: {
    transform: 'rotate(180deg)',
  },
  iconMargin: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  foldbar: {
    // ...theme.typography.button,

    userSelect: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    transition: 'backgroundColor 0.25s ease',

    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

export function CollapsableModule({
  Icon,
  iconProps,
  title,
  scrollable,
  expanded,
  setExpanded,
  children,
  ...props
}) {
  const classes = styles();
  return (
    <React.Fragment {...props}>
      <ButtonBase
        className={classes.foldbar}
        onClick={() => {
          setExpanded(!expanded);
        }}>
        <Icon color='inherit' className={classes.iconMargin} {...iconProps} />
        <Typography variant='subtitle1'>{title}</Typography>

        <div style={{ flexGrow: 1 }} />
        <ExpandMore
          className={classNames(classes.shrinkButton, {
            [classes.rotated]: expanded,
          })}
        />
      </ButtonBase>

      <div
        className={classNames(classes.moduleView, {
          [classes.moduleViewOpen]: expanded,
          [classes.moduleViewScroll]: scrollable,
        })}>
        {children}
      </div>

      <Divider />
    </React.Fragment>
  );
}

export function AutoCollapsableModule({ defaultOpen, ...props }) {
  const [expanded, setExpanded] = React.useState(defaultOpen);
  return <CollapsableModule expanded={expanded} setExpanded={setExpanded} {...props} />;
}
