import axios from 'axios';
import Logging from '~/logging';
import type { SVThunkAction } from '~/store';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface SupportState {
  availableTopics: string[];
  markdownByTopic: { [topic: string]: { loading: boolean; error: boolean; md?: string } };
  searchResults: { [query: string]: { loading: boolean; results: string[] } };
}

const initialState = {
  availableTopics: [],
  markdownByTopic: {},
  searchResults: {},
} as SupportState;

const slice = createSlice({
  name: 'support',
  initialState,
  reducers: {
    startLoadingTopic(state, action: PayloadAction<{ topic: string }>) {
      const { topic } = action.payload;
      state.markdownByTopic[topic] = { loading: true, error: false, md: undefined };
    },
    receivedTopic(state, action: PayloadAction<{ topic: string; md: string }>) {
      const { topic, md } = action.payload;
      state.markdownByTopic[topic] = { loading: false, error: false, md };
    },
    receivedTopicError(state, action: PayloadAction<{ topic: string }>) {
      const { topic } = action.payload;
      state.markdownByTopic[topic] = { loading: false, error: true, md: undefined };
    },
    receivedAvailableTopics(state, action: PayloadAction<{ topics: string[] }>) {
      const { topics } = action.payload;
      state.availableTopics = topics;
    },
    receivedSearchResults(
      state,
      action: PayloadAction<{ query: string; results: { loading: boolean; results: string[] } }>
    ) {
      const { query, results } = action.payload;
      state.searchResults[query] = results;
    },
  },
});

export default slice.reducer;

const requestAvailableTopics = (): SVThunkAction => async (dispatch) => {
  const resp = await axios({
    method: 'GET',
    url: `/api/sv/help`,
    responseType: 'json',
    withCredentials: false,
  });

  dispatch(slice.actions.receivedAvailableTopics({ topics: resp.data }));
};

// Pushes the pending note up to the server
const requestTopic = (topic: string): SVThunkAction => async (dispatch) => {
  if (!topic) {
    Logging.warn("Can't request empty topic");
    return;
  }

  dispatch(slice.actions.startLoadingTopic({ topic }));

  try {
    // I'm not using api webrequest here because it's a little unique?

    const resp = await axios({
      method: 'GET',
      url: `/api/sv/help/${topic}.md`,
      responseType: 'text',
      withCredentials: false,
    });

    dispatch(slice.actions.receivedTopic({ topic, md: resp.data }));
  } catch (e) {
    Logging.warn(`Missing help topic ${topic}`);
    dispatch(slice.actions.receivedTopicError({ topic }));
    return;
  }
};

const searchHelp = (query: string): SVThunkAction => async (dispatch) => {
  const resp = await axios({
    method: 'GET',
    url: `/api/sv/help`,
    params: { q: query },
    responseType: 'text',
    withCredentials: false,
  });

  dispatch(slice.actions.receivedSearchResults({ query, results: resp.data }));
};

export const actions = {
  ...slice.actions,
  requestAvailableTopics,
  requestTopic,
  searchHelp,
};
