import React from 'react';

import { Button } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';

const createZoneStyles = makeStyles((theme) => ({
  root: {
    color: 'var(--textColor)',
    backgroundColor: 'var(--bgColor)',
    '&:hover': {
      backgroundColor: 'var(--hoverColor)',
    },
  },
}));

function ColoredButton({ color, ...props }, ref) {
  const theme = useTheme();
  const classes = createZoneStyles();

  const textColor = theme.palette.getContrastText(color);
  const hoverColor = lighten(color, 0.25);

  return (
    <Button
      ref={ref}
      variant='contained'
      classes={classes}
      style={{ '--hoverColor': hoverColor, '--textColor': textColor, '--bgColor': color }}
      {...props}
    />
  );
}
export default React.forwardRef(ColoredButton);
