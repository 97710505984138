import React from 'react';
import { connect } from 'react-redux';
import { webrequest } from '~/lib/api';
import { showSnackbar } from '~/lib/modalServices';
import { titleCase } from '~/lib/names';
import Logging from '~/logging';

import { Button, Grid, List, ListItem, ListItemText, Paper, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import ActionBar from './ActionBar';
import AssemblyTree from './AssemblyTree';
import CertificationBadges from './CertificationBadges';
import FactSheet from './FactSheet';
import FlightHistory from './FlightHistory';
import PartChangeDialog from './PartChangeDialog';
import PartHistory from './PartHistory';
import ReportSheet from './ReportSheet';
import ValueList from './ValueList';
import partExpectations from './partExpectations';
import { isEdited, matches, partData, partEdits } from './selectors';
import { actions } from './slice';

function ChangeBasePassword({ sn }) {
  const [pw, setPw] = React.useState('');

  const onSubmit = async () => {
    try {
      await webrequest('POST', `sv/base_password/${sn}`, { password: pw });
      showSnackbar('success', 'Base password changed.');
      setPw('');
    } catch (e) {
      Logging.error(`Failed to download flight logs CSV`, e);
      showSnackbar('error', 'Base password change failed.');
    }
  };

  return (
    <Paper>
      <List>
        <ListItem dense>
          <TextField
            size='small'
            fullWidth
            type='password'
            onChange={(e) => setPw(e.target.value)}
            value={pw}
            label='Change Base Password'
          />
          <Button
            color='primary'
            variant='contained'
            style={{ marginLeft: 8 }}
            onClick={onSubmit}
            disabled={pw.length < 3}>
            Change
          </Button>
        </ListItem>
      </List>
    </Paper>
  );
}

const styles = (theme) => ({
  root: {
    overflowY: 'auto',
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    background: theme.palette.background.default,
    maxHeight: '100%',
    padding: theme.spacing(2),
  },

  paper: {
    height: '100%',
  },
});

function PartPage({
  classes,
  params,
  data,
  doAction,
  doForceAddReport,
  editParameter,
  edits,
  isEdited,
  revertSubassembly,
  editSubassembly,
}) {
  if (!data || data.isFetching || !data.current_hw)
    return (
      <div className={classes.root}>
        <Grid container spacing={2}>
          <ActionBar
            title={`${titleCase(params.type)} ${params.sn}`}
            status={data?.isFetching ? 'loading' : 'Unable to load.'}
            actions={[]}
            editing={false}
            save={null}
          />
        </Grid>
      </div>
    );
  const changeDialog = React.createRef();

  const expectations = partExpectations(params.type);

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <ActionBar
          title={`${titleCase(params.type)} ${params.sn}`}
          status={data.state}
          actions={data.actions}
          doAction={async (a) =>
            changeDialog.current.show(
              a,
              data.current_hw,
              edits,
              data.report !== null ? true : false,
              (action, changelog, parameters, subassemblies, files) =>
                doAction(
                  params.type,
                  params.sn,
                  data.report?.id,
                  action,
                  changelog,
                  parameters,
                  subassemblies,
                  files
                )
            )
          }
          editing={isEdited}
        />

        <Grid item xs={12} sm={6}>
          <Grid container spacing={2} direction='column' justify='flex-start' alignItems='stretch'>
            <Grid item xs={12}>
              <FactSheet data={data} />
            </Grid>

            <Grid item xs={12}>
              <ValueList
                title='Parameters'
                editing={true}
                items={data.current_hw.parameters}
                mandatoryItems={expectations.parameters}
                notes={data.current_hw.notes}
                location={data.current_hw.location}
                part_of={data.part_of}
                mfg_date={data.current_hw.mfg_date}
                org_id={data.current_hw.org_id}
                locations={data.locations || []}
                editParameter={(parameter, value) =>
                  editParameter({ type: params.type, sn: params.sn, parameter, value })
                }
                edits={edits?.parameters || []}
              />
            </Grid>
            {params.type == 'base' && (
              <Grid item xs={12}>
                <ChangeBasePassword sn={params.sn} />
              </Grid>
            )}
            <Grid item xs={12}>
              <ReportSheet
                report={data.report}
                old_reports={data.old_reports || []}
                doAddReport={async (type, files) => {
                  doForceAddReport(params.type, params.sn, type, files);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2} direction='column' justify='flex-start' alignItems='stretch'>
            <Grid item xs={12}>
              <AssemblyTree
                tree={data.assembly_tree}
                partId={data.id}
                type={params.type}
                sn={params.sn}
                sasms={data.current_hw.subassemblies || []}
                editSubassembly={editSubassembly}
                revertSubassembly={revertSubassembly}
                availableSubassemblies={data.available_subassemblies || []}
              />
            </Grid>
            <Grid item xs={12}>
              <PartHistory history={data.history} />
            </Grid>
            <Grid item xs={12}>
              <FlightHistory flights={data.flights} />
            </Grid>
            {/* <Grid item xs={12}>
              <CertificationBadges mine={data.badges.mine} inherited={data.badges.inherited} />
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
      <PartChangeDialog ref={changeDialog} />
    </div>
  );
}
const mapStateToProps = (state) => ({
  params: matches(state),
  data: partData(state),
  edits: partEdits(state),
  isEdited: isEdited(state),
});

const mapDispatchToProps = (dispatch) => ({
  doAction: (type, sn, report_id, action, changelog, parameters, subassemblies, files) =>
    dispatch(
      actions.doPartAction(type, sn, report_id, action, changelog, parameters, subassemblies, files)
    ),

  doForceAddReport: (type, sn, report_type, files) =>
    dispatch(actions.doForceAddReport(type, sn, report_type, files)),

  editParameter: (x) => dispatch(actions.editParameter(x)),
  editSubassembly: (x) => dispatch(actions.editSubassembly(x)),
  revertSubassembly: (x) => dispatch(actions.revertSubassembly(x)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PartPage));
