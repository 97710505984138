import { Scene, registerScene } from '../lib/activeScene';
import * as assets from './assets';
import * as farms from './farms';
import * as field from './field';
import flight, { slice as flightSlice } from './flight';
import * as flights from './flights';
import * as hardware from './hardware';
import * as org from './org';
import * as orglist from './orglist';
import overview, { slice as overviewSlice } from './overview';
import * as part from './part';
import * as processor from './processor';
import * as regions from './regions';
import * as regulatory from './regulatory';
import schedule from './schedule';
import * as surveys from './surveys';
import * as users from './users';

// List of routes, in order of path match resolution
export function populateRoutes() {
  [
    assets,
    overview,
    field,
    regions,
    surveys,
    farms,
    hardware,
    users,
    flights,
    flight,
    part,
    processor,
    orglist,
    org,
    regulatory,
    schedule,
  ].forEach((x) => registerScene(x as Partial<Scene>)); // Once all the scenes are typed, we can remove this cast
}

export const slices = {
  fieldScene: field.slice,
  assetsScene: assets.slice,
  regionsScene: regions.slice,
  surveysScene: surveys.slice,
  farmsScene: farms.slice,
  hardwareScene: hardware.slice,
  usersScene: users.slice,
  flightScene: flightSlice,
  partScene: part.slice,
  orglistScene: orglist.slice,
  orgScene: org.slice,
  overviewScene: overviewSlice,
};
