import classnames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import * as Icons from '~/icons';
import route from '~/lib/activeScene';
import { getTimelineTime, setTimelineTime } from '~/lib/timeline';

import { Button, Hidden, Paper, Slider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const TinyIconButton = ({ children, ...props }) => (
  <Button size='small' color='inherit' style={{ minWidth: 32, padding: 0 }} {...props}>
    {children}
  </Button>
);

const styles = makeStyles((theme) => ({
  timelineContainer: {
    position: 'relative',

    opacity: 0.92,

    height: theme.spacing(4),

    // borderRadius: theme.spacing(0.5),
    margin: theme.spacing(0.5),
    // background: theme.palette.background.paper,
    color: theme.palette.text.primary,

    display: 'flex',
    alignItems: 'center',
    // boxShadow:
    //   '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
  },
  sliderWrapper: {
    flexGrow: 1,
    maxHeight: '100%',
    // paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(1),
    marginLeft: 0,
    marginRight: 0, //theme.spacing(1),
    width: 'auto',
    position: 'relative', // to give the tickmarks a context
    display: 'flex',
    alignItems: 'center',
  },
  tickmark: {
    height: '50%',
    width: 2,
    marginLeft: -1, // To center it
    background: theme.palette.text.secondary,
    position: 'absolute',
    transform: 'translateX(1px)',
  },

  pointer: {
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 8,

    position: 'absolute',
    bottom: -16,

    width: 0,
    height: 0,

    [theme.breakpoints.down('xs')]: {
      borderTopColor: 'rgba(0,0,0,0.65)',
    },
    [theme.breakpoints.up('sm')]: {
      borderTopColor: theme.palette.background.paper,
    },

    transition: 'left 0.3s',

    '&.left': {
      left: 16,
    },
    '&.right': {
      left: 'calc(100% - 32px)',
    },
  },
  timeIndicator: {
    margin: theme.spacing(1),
    width: 42,
    textAlign: 'center',
  },
}));

export function TimelineSlider({ mapidx }) {
  const classes = styles();

  const stops = useSelector(route.hasTimeline);
  const timelineTime = useSelector((state) => getTimelineTime(state, mapidx));
  const setTimelineTimeFn = useSelector(setTimelineTime);

  const currentStopIdx = stopIdxForTime(stops, timelineTime);

  const latestStop = 0;
  const earliestStop = stops.length - 1;

  const setStop = (idx) => {
    if (idx >= stops.length || idx < 0 || idx == currentStopIdx) return;
    setTimelineTimeFn(mapidx, stops[idx]);
  };
  const onChange = (evt, value) => {
    setStop(Math.round(stops.length - value - 1));
  };

  return (
    <div className={classes.sliderWrapper}>
      <div
        id='ticks'
        style={{
          right: 0,
          left: 0,
          height: '100%',
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          // background: ndviGradient,
        }}>
        {stops.map((stop, idx) => (
          <div
            key={stop}
            className={classnames(classes.tickmark, {
              // [classes.targetedTickmark]: isTargeted[idx],
            })}
            style={{
              right: `${(idx / (stops.length - 1)) * 100}%`,
            }}
          />
        ))}
      </div>
      <Slider
        min={latestStop}
        max={earliestStop}
        value={stops.length - currentStopIdx - 1} // We are inverted, 0 is latest
        onChange={onChange}
      />
    </div>
  );
}

function stopIdxForTime(stops, t) {
  const csi = stops.findIndex((f) => f == t);
  return csi < 0 ? 0 : csi; // -1 means not found, but we treat it as latest
}

export function TimelineButtons({ mapidx }) {
  const stops = useSelector(route.hasTimeline);
  const timelineTime = useSelector((state) => getTimelineTime(state, mapidx));
  const setTimelineTimeFn = useSelector(setTimelineTime);

  const currentStopIdx = stopIdxForTime(stops, timelineTime);

  const latestStop = 0;
  const earliestStop = stops.length - 1;

  const laterStop = (value) => {
    let idx = value - 1;
    if (idx < 0) idx += stops.length;
    return idx;
  };

  const earlierStop = (value) => {
    return (value + 1) % stops.length;
  };

  const setStop = (idx) => {
    if (idx >= stops.length || idx < 0 || idx == currentStopIdx) return;
    setTimelineTimeFn(mapidx, stops[idx]);
  };
  const gotoLaterStop = () => {
    setStop(laterStop(currentStopIdx));
  };
  const gotoEarlierStop = () => {
    setStop(earlierStop(currentStopIdx));
  };
  const gotoStop = (x) => () => {
    setStop(x);
  };
  return (
    <>
      <TinyIconButton disabled={currentStopIdx == earliestStop} onClick={gotoStop(earliestStop)}>
        <Icons.First />
      </TinyIconButton>

      <TinyIconButton disabled={currentStopIdx == earliestStop} onClick={gotoEarlierStop}>
        <Icons.Prev />
      </TinyIconButton>
      <TinyIconButton disabled={currentStopIdx == latestStop} onClick={gotoLaterStop}>
        <Icons.Next />
      </TinyIconButton>

      <TinyIconButton disabled={currentStopIdx == latestStop} onClick={gotoStop(latestStop)}>
        <Icons.Last />
      </TinyIconButton>
    </>
  );
}
