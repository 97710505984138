import produce from 'immer';
import type { Draft } from 'immer';
import React from 'react';
import * as Icons from '~/icons';
import isEqual from '~/lib/deep_equal';
import history from '~/lib/history';
import { showConfirmDialog } from '~/lib/modalServices';
import { actions as estimateActions } from '~/redux/slices/estimates';
import PanelTopProgress from '~/shared/PanelTopProgress';
import RightPanel from '~/shared/RightPanel';
import stringify from 'json-stable-stringify';

import { RequestType, TargetArea } from '~/schema/TargetRequest';
import { Survey, TargetRequest, Asset, Region } from '~/schema';

import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';

import EditableTargetRequest from './EditableTargetRequest';
import { getUpdatedTRList } from './helpers';
import {
  allRegionsForFarm,
  allAssets,
  focussedFarm,
  getBaseLocation,
  getKindsOfRegions,
  getSurveyById,
  getTmpSurvey,
  getSelectedSurveyId,
} from './selectors';
import { actions } from './slice';
import { useSVDispatch, useSVSelector } from '~/redux/hooks';
import { defaultTRQForAssetOrbit } from './targetRequestControls/AssetOrbitControls';
import AddTargetDialog from './AddTargetDialog';

// These are the only names we're allowed to put in survey.payload_type.
const ENUM_PAYLOAD_NAMES = [
  'OTHER',
  'MICASENSE_REDEDGE_MX',
  'XT2_THERMAL',
  'MICASENSE_ALTUM',
  'DALSA_4020_RGB',
];

const styles = makeStyles((theme) => ({
  menuIcon: {
    marginRight: 8,
    marginLeft: 8,
  },

  slider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  stack: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    width: '100%',
    margin: 0,
    paddingBottom: 4,
  },

  spacer: {
    flexShrink: 0,
    width: 16,
  },

  horizSpace: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '100%',
  },

  buttonContainer: {
    paddingTop: 8,

    display: 'flex',
    '& > *': {
      marginLeft: 4,
      marginRight: 4,
    },
  },

  flexGrow: {
    flex: 1,
  },
}));

export default function SurveyEditPanel() {
  const classes = styles();

  const selectedSurveyId = useSVSelector((state) => getSelectedSurveyId(state));

  const tmpSurvey = useSVSelector(getTmpSurvey);
  const originalSurvey = useSVSelector((state) =>
    typeof selectedSurveyId == 'number' ? getSurveyById(state, selectedSurveyId) : null
  );
  const editingTarget = useSVSelector((state) => state.surveysScene.editingTarget);
  const regions = useSVSelector(allRegionsForFarm);
  const assets = useSVSelector(allAssets);

  const ceiling = useSVSelector((state) => focussedFarm(state)?.altitude_ceiling || 120);
  const estimate = useSVSelector((state) =>
    selectedSurveyId != null ? state.estimates.estimates[selectedSurveyId.toString()] : null
  );
  const baseLocation = useSVSelector(getBaseLocation);
  const payloadTypes = useSVSelector((state) => state.global.payloadTypes);

  const dispatch = useSVDispatch();
  const setEditingTarget = (t: number | null) => dispatch(actions.setEditingSurveyTarget(t));
  const setTmpSurvey = (id: number, survey: Survey | undefined) =>
    dispatch(actions.setTmpSurvey({ id, survey }));
  const uploadSurvey = (s: Survey) => dispatch(actions.uploadSurvey(s));
  const fetchSurveyEstimate = React.useCallback(
    (survey: Survey, base_location: string) =>
      dispatch(
        estimateActions.fetchSurveyEstimate((survey.id || 0).toString(), survey, base_location)
      ),
    [dispatch]
  );

  const [targetDialog, setTargetDialog] = React.useState(false);

  const cleaner = (k: string, v: any) => {
    const ignore = ['last_updated', 'id'];
    if (ignore.includes(k)) {
      return undefined;
    }
    if (v === null) {
      return undefined;
    }
    if (typeof v === 'number') {
      return Math.round(v * 100) / 100;
    }
    return v;
  };

  const isDirty =
    stringify(originalSurvey, { replacer: cleaner }) != stringify(tmpSurvey, { replacer: cleaner });

  const revert = async () => {
    if (isDirty) {
      const confirm = await showConfirmDialog(
        'Discard changes',
        'Are you sure you want to close the page without saving changes?',
        'Discard'
      );
      if (!confirm) return;
    }

    if (tmpSurvey !== null) {
      setTmpSurvey(tmpSurvey.id, undefined); // will clear the tmp entry
      history.push(`/surveys?site=${tmpSurvey.farm_id}`);
    }
  };

  const save = () => {
    if (tmpSurvey !== null) {
      uploadSurvey(tmpSurvey);
    }
  };

  // This section deals with packaging the survey for estimate calcls and sending it off.
  const lastMinimizedSurvey = React.useRef<any | null>(null);

  const forceRunEstimate = () => runEstimate(true);

  const runEstimate = React.useCallback(
    (force: boolean = false) => {
      if (tmpSurvey && baseLocation) {
        const minimizedSurvey = {
          type: tmpSurvey.type,
          farm_id: tmpSurvey.farm_id,
          keepout_others: tmpSurvey.keepout_others,
          target_requests: tmpSurvey.target_requests,
          payload_type: tmpSurvey.payload_type,
          payload_device_name: tmpSurvey.payload_device_name,
          baseLocation,
        };

        if (force || stringify(lastMinimizedSurvey.current) != stringify(minimizedSurvey)) {
          if (tmpSurvey.target_requests.length > 0) {
            fetchSurveyEstimate(tmpSurvey, baseLocation);
          }
        }

        lastMinimizedSurvey.current = minimizedSurvey;
      }
    },
    [tmpSurvey, lastMinimizedSurvey, fetchSurveyEstimate, baseLocation]
  );

  const editField = (x: Partial<Survey>) => {
    if (tmpSurvey !== null) {
      setTmpSurvey(
        tmpSurvey.id,
        produce(tmpSurvey, (d: Survey) => {
          return { ...d, ...x };
        })
      );
    }
  };

  const isNew = tmpSurvey?.id === 0;

  const createTrq = (type: RequestType, assets: Asset[], region?: Region) => {
    if (!tmpSurvey) return; // We need this to be nonnull
    // Depending on the request type, we can generate some default target request.
    // Do we have the asset/region(s) here yet?

    let tr: TargetRequest;

    // Assemble the target areas, that's common to all
    const targetAreas: TargetArea[] = [];
    if (region != null) {
      targetAreas.push({
        flight_region_id: region.id,
      });
    }
    targetAreas.push(
      ...assets.map(
        (a) =>
          ({
            asset_id: a.id!,
          } as TargetArea)
      )
    );

    switch (type) {
      case 'CONTINUOUS':
      case 'CONTINUOUS_RAW':
        tr = {
          survey_id: tmpSurvey.id,
          deleted: false,
          last_updated: '',
          flight_region_id: region?.id,
          target_areas: targetAreas,
          altitude: ceiling,
          frontlap: 0.7,
          sidelap: 0.7,
          dilate: true,
          request_type: type,
          instructions_continuous: {
            stitch: type == 'CONTINUOUS',
            altitude: ceiling,
            frontlap: 0.7,
            sidelap: 0.7,
            dilate: true,
          },
        };
        break;
      case 'SAMPLING':
        tr = {
          survey_id: tmpSurvey.id,
          deleted: false,
          last_updated: '',
          flight_region_id: region?.id,

          target_areas: targetAreas,
          sampling_locations: '',
          request_type: type,
          instructions_sampling: {
            sampling_locations: '',
          },
        };
        break;
      case 'ASSET_ORBIT':
        tr = defaultTRQForAssetOrbit(assets, tmpSurvey.id);
        break;
    }

    editField({ target_requests: [...tmpSurvey.target_requests, tr] });
  };

  React.useEffect(() => {
    if (tmpSurvey && baseLocation && estimate != null && !estimate.pending && !estimate.error) {
      runEstimate(); // Regenerate estimates when things change, once you've run them once. If it's the same it's free.
    }
  }, [tmpSurvey, baseLocation, estimate, runEstimate]);

  if (tmpSurvey == null) return null;
  return (
    <RightPanel title={isNew ? 'Create Survey' : `Edit Survey`}>
      {estimate?.pending && <PanelTopProgress progress={estimate.progress} />}

      <List disablePadding>
        <ListItem disableGutters dense>
          <TextField
            fullWidth
            error={tmpSurvey.display_name == ''}
            type='text'
            onChange={(evt) => editField({ display_name: evt.target.value })}
            label='Survey Name'
            value={tmpSurvey.display_name}
          />
        </ListItem>
        {/* TODO: Someday add in the survey/keepout time switch */}

        <Divider />

        {!baseLocation ? (
          <ListItem>
            <Typography color='error' variant='caption'>
              Cannot estimate survey; set Base Station Location in site configuration first
            </Typography>
          </ListItem>
        ) : estimate == null ? (
          <ListItem>
            <Button variant='outlined' fullWidth color='primary' onClick={forceRunEstimate}>
              Estimate flight plan
            </Button>
          </ListItem>
        ) : estimate.pending ? (
          <>
            <ListItem>
              <ListItemIcon>
                <CircularProgress size={24} variant='indeterminate' value={estimate?.progress} />
              </ListItemIcon>
              <ListItemText
                primary='Generating flight plan'
                secondary={
                  estimate?.progress < 1
                    ? 'Initializing...'
                    : estimate?.progress < 50
                    ? 'Navigating...'
                    : estimate?.progress < 55
                    ? 'Reticulating splines...'
                    : 'Finalizing...'
                }
              />
            </ListItem>
            <ListItem>
              <Button variant='outlined' fullWidth color='primary' disabled>
                Estimate flight plan
              </Button>
            </ListItem>
          </>
        ) : estimate.error ? (
          <>
            <ListItem>
              <ListItemIcon>
                <Icons.Warning color='error' />
              </ListItemIcon>
              <ListItemText
                primary='Cannot get flight plan'
                secondary='Check settings and try again'
              />
            </ListItem>

            <ListItem>
              <Button variant='outlined' fullWidth color='primary' onClick={forceRunEstimate}>
                Estimate flight plan
              </Button>
            </ListItem>
          </>
        ) : estimate.result ? (
          <ListItem style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
            <ListItemText primary='Flight plan generated.' />

            <Typography variant='body2' color='secondary'>
              Estimated duration: {Math.floor(estimate.result.estimate_secs / 60)}m{' '}
              {Math.floor(estimate.result.estimate_secs % 60)}s
            </Typography>
            <Typography variant='caption'>
              This is an estimate: actual flight plan may vary due to field conditions.
            </Typography>
          </ListItem>
        ) : null}

        <Divider />
        {/* Specify targets */}
        <ListItem>
          <Typography variant='caption'>
            Drone will {tmpSurvey.type == 'NO_FLY' ? 'stay out of' : 'fly'} these regions during the
            specified time interval:
          </Typography>
        </ListItem>

        {tmpSurvey.target_requests
          .map((tr, tridx) => ({ tr, tridx }))
          .filter(({ tr, tridx: _ }) => !tr.deleted)
          .map(({ tr, tridx }, shownIdx) => {
            const targetAreas = tr.target_areas || [{ flight_region_id: tr.flight_region_id }];

            return (
              <EditableTargetRequest
                key={shownIdx}
                expanded={editingTarget == shownIdx}
                setExpanded={(x) => setEditingTarget(x ? shownIdx : null)}
                ceiling={ceiling || 122}
                targetAreas={targetAreas}
                trq={tr}
                onUpdate={(tgt) => {
                  editField({
                    target_requests: getUpdatedTRList(tmpSurvey.target_requests, tgt, tridx),
                  });
                }}
              />
            );
          })}

        {!tmpSurvey.target_requests.some((tr) => !tr.deleted) && (
          <ListItem>
            <ListItemIcon>
              <Icons.Layout />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ color: 'error' }}
              primary='No targets'
              secondary='Survey will be an updown until targets are added'
            />
          </ListItem>
        )}

        <ListItem
          button
          onClick={(_) => {
            setTargetDialog(true);
          }}>
          <ListItemIcon>
            <Icons.Add />
          </ListItemIcon>
          <ListItemText>Add Target</ListItemText>
        </ListItem>

        {/* Dialog with dropdown for type and chip select for regions/assets */}
        <AddTargetDialog
          open={targetDialog}
          onClose={() => setTargetDialog(false)}
          createTrq={createTrq}
          regions={regions}
          assets={assets}
        />

        <Divider />
        <ListItem dense>
          <TextField
            select
            label='Imaging Payload'
            fullWidth
            value={tmpSurvey.payload_device_name} // We treat payload_device_name as canonical, payload_type is deprecated
            onChange={(e) => {
              editField({
                payload_device_name: e.target.value,
                payload_type: ENUM_PAYLOAD_NAMES.includes(e.target.value)
                  ? e.target.value
                  : 'OTHER',
              });
            }}>
            {payloadTypes.map((payload) => (
              <MenuItem key={payload.device_name} value={payload.device_name}>
                {payload.device_name}
              </MenuItem>
            ))}
          </TextField>
        </ListItem>

        <ListItem dense>
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                checked={!!tmpSurvey.high_priority}
                onChange={(e) => {
                  editField({ high_priority: e.target.checked });
                }}
                value='high_priority'
              />
            }
            label='High Priority'
          />
        </ListItem>

        <ListItem dense>
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                checked={!!tmpSurvey.enabled}
                onChange={(e) => {
                  editField({ enabled: e.target.checked });
                }}
                value='enabled'
              />
            }
            label='Enabled'
          />
        </ListItem>

        {/* Disable, Delete, Revert, Save */}
        {!isNew && (
          <ListItem dense>
            <Button
              variant='outlined'
              fullWidth
              color={tmpSurvey.deleted ? 'secondary' : 'primary'}
              onClick={() => editField({ deleted: !tmpSurvey.deleted })}>
              {tmpSurvey.deleted ? 'Enable' : 'Delete'}
            </Button>
          </ListItem>
        )}
        <ListItem dense>
          <Button fullWidth variant='contained' color='secondary' onClick={revert}>
            Close
          </Button>
          <div className={classes.spacer} />
          <Button fullWidth variant='contained' color='primary' disabled={!isDirty} onClick={save}>
            Save
          </Button>
        </ListItem>
      </List>
    </RightPanel>
  );
}
