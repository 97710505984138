import React from 'react';
import { connect } from 'react-redux';
import * as Icons from '~/icons';
import { actions as globalActions } from '~/redux/slices/global';
import ToggleButtonWithTip from '~/shared/ToggleButtonWithTip';

import { Button, ButtonGroup } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { GAevent } from '~/googleAnalytics';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    '& > :not(:last-child)': {
      marginBottom: 4,
    },
  },
};

function FlightMapControls({
  classes,
  showFlightPath,
  showFlightImagery,
  showFlightRegions,
  setShowFlightImagery,
  setShowFlightRegions,
  setShowFlightPath,
}) {
  return (
    <div className={classes.container}>
      <ButtonGroup color='inherit' orientation='vertical' size='small' variant='contained'>
        <ToggleButtonWithTip
          tooltip='Show flight path'
          toggled={showFlightPath}
          onClick={() => {
            GAevent('Click', `Show flight path ${showFlightPath}`, 'setShowFlightPath(!showFlightPath)');
            setShowFlightPath(!showFlightPath)}}>
          <Icons.Drone />
        </ToggleButtonWithTip>
        <ToggleButtonWithTip
          tooltip='Show regions'
          toggled={showFlightRegions}
          onClick={() => {
            GAevent('Click',  `Show flight regions ${showFlightRegions}`, 'setShowFlightRegions(!showFlightRegions)');
            setShowFlightRegions(!showFlightRegions)}}>
          <Icons.Layout />
        </ToggleButtonWithTip>
        <ToggleButtonWithTip
          tooltip='Show imagery'
          toggled={showFlightImagery}
          onClick={() => {
            GAevent('Click', `Show flight imagery ${showFlightImagery}`, 'setShowFlightImagery(!showFlightImagery)');
            setShowFlightImagery(!showFlightImagery)}}>
          <Icons.Photo />
        </ToggleButtonWithTip>
      </ButtonGroup>
    </div>
  );
}

export default withStyles(styles)(
  connect(
    (state) => ({
      showFlightPath: state.global.preferences.showFlightPath,
      showFlightImagery: state.global.preferences.showFlightImagery,
      showFlightRegions: state.global.preferences.showFlightRegions,
    }),
    (dispatch) => ({
      setShowFlightImagery: (x) => dispatch(globalActions.setPreference({ showFlightImagery: x })),
      setShowFlightRegions: (x) => dispatch(globalActions.setPreference({ showFlightRegions: x })),
      setShowFlightPath: (x) => dispatch(globalActions.setPreference({ showFlightPath: x })),
    })
  )(FlightMapControls)
);
