import { DateTime } from 'luxon';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import * as Icons from '~/icons';
import history from '~/lib/history';
import FancyTableStatic from '~/shared/FancyTableStatic';
import FilterBar from '~/shared/FilterBar';
import { colorForRole } from '~/theme';

import {
  Avatar,
  Button,
  Chip,
  Fade,
  List,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Paper,
  Slide,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import UserEditPanel from './UserEditPanel';
import { selectedUserId } from './selectors';
import { actions } from './slice';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    flexShrink: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    background: theme.palette.background.default,
    maxHeight: '100%',
  },

  module: {
    padding: 8,
    background: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  tableArea: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 1,
  },

  actionBar: {
    padding: [[8, 16]],
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  pane: {
    display: 'flex',
    flexDirection: 'column',
    margin: 4,
  },
});

const sortNumber = (a, b) => {
  if (isFinite(a - b)) {
    return a - b;
  } else {
    return isFinite(a) ? -1 : 1;
  }
};
const sortBool = (a, b) => {
  return sortNumber(a ? 1 : 0, b ? 1 : 0);
};
const sortString = (a, b) => a.localeCompare(b, undefined, { numeric: true });
const sortTime = (a, b) => a - b;

const clickedUserHandler = (user) => {
  history.push(`/users?user=${user.id}`);
};

class UserManagementPage extends React.Component {
  render() {
    const {
      classes,
      allUsers,
      filteredUsers,
      setFilterValue,
      filterValue,
      selectedUserId,
    } = this.props;

    const formattedUsers = filteredUsers.map((u) => ({
      ...u,
      created: u.created.toLocaleString(DateTime.DATE_SHORT),
      last_activity: u.last_activity?.toLocaleString(DateTime.DATE_SHORT),
    }));

    const columns = [
      {
        id: 'id',
        title: 'ID',
        type: 'STR',
        sortable: true,
      },
      {
        id: 'display_name',
        title: 'Name',
        type: 'STR',
        sortable: true,
      },
      {
        id: 'created',
        title: 'Created',
        type: 'STR',
        sortable: true,
      },
      {
        id: 'last_activity',
        title: 'Last access',
        type: 'STR',
        sortable: true,
      },
    ];

    return (
      <div className={classes.root}>
        <div className={classes.pane} style={{ flexGrow: 1 }}>
          <Paper className={classes.actionBar}>
            <FilterBar
              value={filterValue}
              disabled={!allUsers}
              notFound={!filteredUsers}
              onChange={setFilterValue}
              placeholder='Filter Users'
            />
            <Button
              color='primary'
              disabled={selectedUserId == 'new'}
              onClick={() => history.push('/users?user=new')}>
              New User
            </Button>
          </Paper>
          <Paper className={classes.tableArea}>
            <FancyTableStatic
              data={formattedUsers}
              columns={columns}
              id={(x) => x.id}
              onClick={clickedUserHandler}
              hidden={(x) => x.deleted}
              selected={(x) => x.id == selectedUserId}
            />
          </Paper>
        </div>
        <Paper
          className={classes.pane}
          style={{ minWidth: 280, width: 420, maxWidth: 420, flexGrow: 0, overflowY: 'auto' }}>
          <UserEditPanel />
        </Paper>
      </div>
    );
  }
}

function userPasses(user, filter) {
  if (filter.length == 0) return true;
  for (const f of filter) {
    if (user.id.toLowerCase().includes(f)) return true;
    if (user.display_name?.toLowerCase()?.includes(f)) return true;
    if (user.pilot_id?.toLowerCase()?.includes(f)) return true;

    for (const role of user.roles) {
      if (role.target_id.toLowerCase().includes(f)) return true;
      if (role.role_id.toLowerCase().includes(f)) return true;
    }
  }
  return false;
}

const getAllUsers = createSelector(
  (state) => state.usersScene.users,
  (users) => Object.values(users)
);

const getFilteredUsers = createSelector(
  getAllUsers,
  (state) => state.usersScene.filterValue,
  // TODO: apply filtering and sorting here
  (users, fv) => users.filter((u) => userPasses(u, fv?.toLowerCase()?.split(' ') || []))
);

const mapStateToProps = (state) => ({
  allUsers: getAllUsers(state),
  filteredUsers: getFilteredUsers(state),
  roleAssignments: state.usersScene.roleAssignments,
  filterValue: state.usersScene.filterValue,
  selectedUserId: selectedUserId(state),
});

const mapDispatchToProps = (dispatch) => ({
  setFilterValue: (value) => {
    return dispatch(actions.setFilterValue(value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserManagementPage));
