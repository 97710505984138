import React from 'react';
import { useLocation } from 'react-router-dom';
import { ARLogo } from '~/icons';
import * as api from '~/lib/api';
import TM from '~/shared/TM';

import {
  AppBar,
  Button,
  CircularProgress,
  Divider,
  Hidden,
  Link,
  List,
  ListItem,
  Paper,
  Toolbar,
  Typography,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import AcceptButton from './AcceptButton';
import CreateAccountDialog from './CreateAccountDialog';
import LinkAccountDialog from './LinkAccountDialog';

const styles = makeStyles((theme) => ({
  fullscreen: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',

    alignItems: 'center',
    justifyContent: 'center',

    top: 0,
    bottom: 0,
    width: '100%',
    overflow: 'hidden',

    background: theme.palette.background.default,
  },
  bgimage: {
    position: 'fixed',

    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: 'hidden',
    zIndex: -1,

    backgroundImage: 'url(images/scout-bg.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundPosition: [['center', 'center']],
    backgroundSize: 'cover',
    filter: 'saturate(25%) brightness(50%)',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(4),
  },
  title: {
    flexGrow: 1,
    whiteSpace: 'nowrap',
  },
  divider: {
    margin: 12,
    alignSelf: 'stretch',
  },
}));

export default function AcceptInvite() {
  const classes = styles();
  const theme = useTheme();

  const [inviteDeets, setInviteDeets] = React.useState(null);
  const [inviteLoading, setInviteLoading] = React.useState(true);
  const [inviteErr, setInviteErr] = React.useState(false);

  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  const [creatingAccount, setCreatingAccount] = React.useState(false);
  const [linkingAccount, setLinkingAccount] = React.useState(false);

  let query = new URLSearchParams(useLocation().search);
  const inviteid = query.get('id');

  React.useEffect(() => {
    api
      .webrequest('GET', 'sv/hello')
      .then((hello) => setIsLoggedIn(hello.authenticated))
      .catch(() => setIsLoggedIn(false));

    api
      .webrequest('GET', `sv/invite/${inviteid}`)
      .then((invite) => {
        setInviteDeets(invite);
        setInviteLoading(false);
      })
      .catch((err) => {
        setInviteLoading(false);
        setInviteErr(true);
      });
  }, [inviteid]);

  document.title = 'Accept ScoutView Invitation';

  return (
    <div className={classes.fullscreen}>
      <Hidden xsDown>
        <div className={classes.bgimage} />
      </Hidden>
      <AppBar>
        <Toolbar>
          <ARLogo style={{ marginRight: theme.spacing(2) }} />

          <Typography variant='h6' className={classes.title}>
            ScoutView
            <TM />: Accept Invitation
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Paper className={classes.container}>
        {inviteLoading ? (
          <CircularProgress variant='indeterminate' />
        ) : inviteErr ? (
          <>
            <Typography variant='subtitle1' color='error'>
              There was an error retrieving your invitation.
            </Typography>
            <Typography variant='subtitle2' align='center'>
              It may have expired or been cancelled. Reply to the invitation email, or contact{' '}
              <a href='mailto:support@american-robotics.com'>support@american-robotics.com</a> for
              assistance.
            </Typography>
          </>
        ) : inviteDeets.state == 'ACCEPTED' ? (
          <>
            <Typography variant='subtitle1' color='error'>
              This invitation has already been accepted.
            </Typography>
            <Typography variant='subtitle2' align='center'>
              Invitations may only be used once. If you have any questions, reply to the invitation
              email, or contact{' '}
              <a href='mailto:support@american-robotics.com'>support@american-robotics.com</a> for
              assistance.
            </Typography>
          </>
        ) : inviteDeets.state == 'EXPIRED' ? (
          <>
            <Typography variant='subtitle1' color='error'>
              This invitation has expired.
            </Typography>
            <Typography variant='subtitle2' align='center'>
              Invitations may only be accepted within a few days of being issued. You may contact
              the individual who sent the invitation by replying to the invitation email, or contact{' '}
              <a href='mailto:support@american-robotics.com'>support@american-robotics.com</a> for
              assistance.
            </Typography>
          </>
        ) : inviteDeets.state == 'REVOKED' ? (
          <>
            <Typography variant='subtitle1' color='error'>
              This invitation has been revoked.
            </Typography>
            <Typography variant='subtitle2' align='center'>
              If you have any questions, reply to the invitation email, or contact{' '}
              <a href='mailto:support@american-robotics.com'>support@american-robotics.com</a> for
              assistance.
            </Typography>
          </>
        ) : inviteDeets.state == 'FAILED' ? (
          <>
            <Typography variant='subtitle1' color='error'>
              This invitation failed to send.
            </Typography>
          </>
        ) : (
          // It's finally pending!
          <>
            {/* <pre>{JSON.stringify(inviteDeets, null, 2)}</pre> */}
            <Typography variant='subtitle1'>You have been invited to join:</Typography>
            <Typography variant='h5' paragraph>
              {inviteDeets.orgid}
            </Typography>

            {isLoggedIn ? (
              <>
                <Typography variant='caption' style={{ maxWidth: 300 }}>
                  You can accept this invitation with your currently logged in account:
                </Typography>
                <Typography variant='subtitle2' paragraph>
                  <strong>{isLoggedIn}</strong>
                </Typography>

                <AcceptButton
                  invite={inviteDeets}
                  request={{
                    existing: true,
                    username: isLoggedIn,
                  }}
                  link
                />
                <Divider variant='middle' className={classes.divider} />

                <Button
                  variant={isLoggedIn ? 'text' : 'contained'}
                  color='primary'
                  onClick={() => setLinkingAccount(true)}>
                  Link a different account
                </Button>

                <Button
                  variant={isLoggedIn ? 'text' : 'contained'}
                  color='primary'
                  onClick={() => setCreatingAccount(true)}>
                  Create new account
                </Button>
              </>
            ) : (
              <>
                <Button
                  fullWidth
                  variant={isLoggedIn ? 'text' : 'contained'}
                  color='primary'
                  onClick={() => setCreatingAccount(true)}>
                  Create new account
                </Button>

                <Divider variant='middle' className={classes.divider} />

                <Button
                  variant={isLoggedIn ? 'contained' : 'text'}
                  color='primary'
                  onClick={() => setLinkingAccount(true)}>
                  Link Existing Account
                </Button>
                <Typography variant='caption' style={{ margin: 12, maxWidth: 300 }}>
                  If you already have a ScoutView account, you can log in with it here to join this
                  organization.
                </Typography>
              </>
            )}
            <CreateAccountDialog
              open={creatingAccount}
              invite={inviteDeets}
              onClose={() => setCreatingAccount(false)}
            />
            <LinkAccountDialog
              open={linkingAccount}
              invite={inviteDeets}
              onClose={() => setLinkingAccount(false)}
            />
          </>
        )}
      </Paper>
    </div>
  );
}

/*
PENDING
ACCEPTED
EXPIRED
REVOKED
FAILED
*/
