import React from 'react';
import { createSelector } from 'reselect';
import { Scene } from '~/lib/activeScene';
import * as geography from '~/lib/geography';
import { Imagery, Region } from '~/schema';
import ImageryAnnotationControls from '~/shared/ImageryAnnotationControls';
import ImageryControls from '~/shared/ImageryControls';
import { RootState } from '~/store';
import { regionColors } from '~/theme';

import FlightMapControls from './FlightMapControls';
import FlightPanel from './FlightPanel';
import { getFlightData, getFlightPending, matches } from './selectors';
import slice, { actions } from './slice';

export { slice, matches };

const flightScene: Partial<Scene> = {
  params: matches,

  title: createSelector(matches, (params) => `Flight ${params!.flightId}`),

  breadcrumbs: createSelector(
    matches,
    getFlightData,

    (params, flightData) => [
      { name: 'Flight Logs', to: '/flights' },

      {
        name: flightData?.survey?.display_name || '...',
        to: flightData?.survey
          ? `/surveys?site=${flightData.survey.farm_id}&surveyid=${flightData.survey.id}`
          : '/surveys',
      },
      `#${params!.flightId}`,
    ]
  ),

  imagesets: createSelector(getFlightData, (flightData) => {
    return flightData?.imagesets || [];
  }),

  panel: createSelector(matches, (_params) => <FlightPanel />),

  mapStyle: createSelector(
    (state: RootState) => state.global.preferences.analysisMode,
    (state: RootState) => state.global.preferences.showFlightRegions,
    (state: RootState) => state.global.preferences.showFlightImagery,
    getFlightData,
    getFlightPending,
    (analysisMode, showFlightRegions, showFlightImagery, flightData, flightPending) => {
      if (!flightData || flightPending) {
        return null;
      }

      const imagesets = flightData.imagesets;

      const imagery =
        imagesets?.reduce<Imagery[]>(
          (imgs, imageset) => [
            ...imgs,
            ...(imageset.imagery?.map((img) => ({
              ...img,
              visible: true,
              unloaded: false,
            })) || []),
          ],
          []
        ) || [];

      const regions: Region[] =
        flightData.regions?.map((r) => {
          return {
            id: r.id,
            type: r.type,
            ceiling: r.ceiling,
            boundary: r.boundary,
            display_name: r.display_name,
            farm_id: '',
            crop_type: '',
            grid_divisions: [],
            color: regionColors[r.type],
            outline: r.type != 'KEEPOUT',
            fill: r.type == 'KEEPOUT',
            name: r.type != 'KEEPOUT',
            deleted: false,
            immutable: false,
          };
        }) || [];

      // I'm skipping analysis overlays on this scene.

      return {
        // TODO: how do we add the detailed analytics overlays?
        imagery: showFlightImagery ? imagery : [],
        // On the summary page, we display all analysis regions for our farm
        regions: showFlightRegions ? regions : [],
        extras: { layers: [], sources: {} },
      };
    }
  ),

  flightPath: createSelector(getFlightData, (f) => f?.notes?.flight_path || null),

  fetch: createSelector(
    (state: RootState) => matches(state),
    (params) => (dispatch) => {
      if (params!.flightId) dispatch(actions.getFlightData(params!.flightId));
    }
  ),

  defaultBounds: createSelector(getFlightData, (f) => {
    // TODO: If we don't have a flight path, should it fall back to regions? farm?
    if (f?.notes?.flight_path == null) return null;
    return geography.bboxOfWKT(f?.notes?.flight_path);
  }),

  imageryResolution: createSelector(getFlightData, (flightData) => {
    const imagesets = flightData?.imagesets;
    const imagesetsWithRes = imagesets?.filter((x) => x.actual_resolution) || [];
    const res = imagesetsWithRes.map((x) => x.actual_resolution);
    if (!res) return [];
    return res;
  }),

  controls: () => {
    return {
      NE: [
        <ImageryControls
          mapidx={0}
          key='imctl'
          setMapSplitStyle={undefined}
          showSplitControls={false}
          showAnalysisControls={false}
        />,
      ],
      NW: [
        <FlightMapControls key='flightctl' />,
        <ImageryAnnotationControls
          key='notectl'
          isAddingNote={false}
          addNote={undefined}
          activeReportId={undefined}
          addToReport={undefined}
        />,
      ],
    };
  },

  helpTopic: () => 'Flight Log',
};

export default flightScene;
