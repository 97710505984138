import * as MapboxGL from 'mapbox-gl';
import { createSelector } from 'reselect';
import { GAevent } from '~/googleAnalytics';
import { Coordinate } from '~/lib/customHooks';
import * as geography from '~/lib/geography';
import type { RootState } from '~/store';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

function verifyAndSetCamera(state: UIState, c: MapCameraType) {
  // if (c.bounds && !c.bounds.every((x) => x.every((y) => isFinite(y)))) {
  //   Logging.warn('Camera bounds are invalid!', { camera: c });
  //   return;
  // }

  // Sometimes we might send { lat, lon } objects in for Center. Check that here.
  if (c.options?.center && 'lat' in c.options.center && 'lon' in c.options.center) {
    c.options.center = [c.options.center.lon, c.options.center.lat];
    // if (isNaN(c.options.center[0]) || isNaN(c.options.center[1])) {
    //   Logging.warn("Couldn't parse camera coordinates.", { camera: c });
    //   return;
    // }
  }
  state.mapCamera = c;
}

interface MapCameraType {
  target: 'bounds' | 'options';
  options?: MapboxGL.CameraOptions;
  bounds?: MapboxGL.LngLatBoundsLike;
  complete: boolean; // Set to true when the map automatically moves here. Cleared when we navigate or otherwise reset
}

export interface UserPositionType {
  watch: number;
  coords?: Coordinate;
  accuracy?: number;
}

interface UIState {
  showLeftPanel: boolean;
  rightPanelShrunk: boolean;
  userPosition?: UserPositionType; // coordinates of the user's device

  viewport: {
    center: MapboxGL.LngLatLike;
    zoom: number;
    pitch: number;
    automatic: boolean;
  }; // reported from the map

  tileProgress: boolean;

  mapCamera: MapCameraType;

  showTimeline: boolean;

  regionsPageFilter: string;
  overviewPageFilter: string;

  imageryResFilter: [number, number];

  hideCropTypes: string[];

  // TODO: These will be per-window soon
  contrastLevels: [number, number, number];
  contrastLevelsMapB: [number, number, number];

  windowRange: { [channel: string]: [number, number] };
  windowRangeMapB: { [channel: string]: [number, number] };

  isMapSplit: boolean; // If true, we're using maps A and B

  lightbox?: string[]; // if set to an array of image urls, will display in a lightbox dialog
  lightboxIndex: number;
}

const initialState = {
  showLeftPanel: false,

  rightPanelShrunk: false,

  userPosition: undefined, // coordinates of the user's device

  viewport: {
    center: new MapboxGL.LngLat(0, 0),
    zoom: 0,
    pitch: 0,
    automatic: true,
  }, // reported from the map

  tileProgress: false,

  mapCamera: {
    // initial view of whole US
    target: 'options',
    options: {
      center: {
        lng: -105.085,
        lat: 39.869,
      },
      zoom: 3.36,
    },
    complete: false, // Set to true when the map automatically moves here. Cleared when we navigate or otherwise reset
  },

  showTimeline: false,

  regionsPageFilter: '',
  overviewPageFilter: '',

  imageryResFilter: [0, 100],

  hideCropTypes: [],

  contrastLevels: [0, 1, 1], // TODO: These will be per-window soon
  contrastLevelsMapB: [0, 1, 1],

  windowRange: {
    RGB: [0, 1],
    CIR: [0, 1],
    CHL: [0, 1],
    NDVI: [0, 1],
    NDRE: [0, 1],
    OSAVI: [0, 1],
    THERMAL: [-10, 40],
    DEM: [0, 1],
  },

  windowRangeMapB: {
    RGB: [0, 1],
    CIR: [0, 1],
    CHL: [0, 1],
    NDVI: [0, 1],
    NDRE: [0, 1],
    OSAVI: [0, 1],
    THERMAL: [-10, 40],
    DEM: [0, 1],
  },

  isMapSplit: false, // If true, we're using maps A and B

  lightbox: undefined, // if set to an array of image urls, will display in a lightbox dialog
  lightboxIndex: 0, // if set to an array of image urls, will display in a lightbox dialog
} as UIState;

const slice = createSlice({
  name: 'ui',

  initialState,
  reducers: {
    markMapCameraComplete(state, action: PayloadAction<boolean>) {
      state.mapCamera.complete = action.payload;
    },
    zoomToBounds(state, action: PayloadAction<MapboxGL.LngLatBoundsLike>) {
      verifyAndSetCamera(state, {
        target: 'bounds',
        bounds: action.payload,
        complete: false,
      });
    },

    zoomToPoint(state, action: PayloadAction<{ point: MapboxGL.LngLatLike; zoom?: number }>) {
      const { point, zoom } = action.payload;

      const c: MapCameraType = {
        target: 'options',
        options: {},
        complete: false,
      };
      if (point) c.options!.center = point;
      if (zoom) c.options!.zoom = zoom;

      verifyAndSetCamera(state, c);
    },

    tiltTo(state, action: PayloadAction<number>) {
      verifyAndSetCamera(state, {
        target: 'options',
        options: { pitch: action.payload },
        complete: false,
      });
    },

    changeZoom(state, action: PayloadAction<number>) {
      verifyAndSetCamera(state, {
        ...state.mapCamera,
        target: 'options',
        options: { zoom: state.viewport.zoom + action.payload },
        complete: false,
      });
    },
    setZoom(state, action: PayloadAction<number>) {
      verifyAndSetCamera(state, {
        ...state.mapCamera,
        target: 'options',
        options: { zoom: action.payload },
        complete: false,
      });
    },

    showLeftPanel(state, action: PayloadAction<boolean>) {
      state.showLeftPanel = action.payload;
      GAevent('Click', `Show left navigation drawer ${action.payload}`, 'showLeftPanel');
    },

    setUserPosition(state, action: PayloadAction<UserPositionType | undefined>) {
      state.userPosition = action.payload;
    },

    updateViewport(
      state,
      action: PayloadAction<{ pitch: number; zoom: number; center: Coordinate; automatic: boolean }>
    ) {
      const { pitch, zoom, center, automatic } = action.payload;
      state.viewport = {
        pitch: pitch,
        zoom: zoom,
        center: center,
        automatic: automatic === undefined ? state.viewport.automatic : automatic,
      };
    },

    setTileProgress(state, action: PayloadAction<boolean>) {
      state.tileProgress = action.payload;
    },

    setAssetsFilterValue(state, action: PayloadAction<string>) {
      state.regionsPageFilter = action.payload;
    },

    setOverviewFilterValue(state, action: PayloadAction<string>) {
      state.overviewPageFilter = action.payload;
    },

    setShowTimeline(state, action: PayloadAction<boolean>) {
      state.showTimeline = action.payload;
    },

    setImageryResFilter(state, action: PayloadAction<[number, number]>) {
      state.imageryResFilter = action.payload;
    },

    setRightPanelShrunk(state, action: PayloadAction<boolean>) {
      state.rightPanelShrunk = action.payload;
    },

    setContrastLevels(state, action: PayloadAction<[number, number, number]>) {
      state.contrastLevels = action.payload;
    },

    setContrastLevelsMapB(state, action: PayloadAction<[number, number, number]>) {
      state.contrastLevelsMapB = action.payload;
    },

    setWindowRange(state, action: PayloadAction<{ channel: string; range: [number, number] }>) {
      const { channel, range } = action.payload;
      state.windowRange[channel] = [range[0], range[1]];
    },

    setWindowRangeMapB(state, action: PayloadAction<{ channel: string; range: [number, number] }>) {
      const { channel, range } = action.payload;
      state.windowRangeMapB[channel] = [range[0], range[1]];
    },

    setMapSplit(state, action: PayloadAction<boolean>) {
      // TODO: need to refactor this to use query param!
      GAevent('Click', `Set map split ${action.payload}`, 'setMapSplit');
      state.isMapSplit = action.payload;
    },

    setLightbox(state, action: PayloadAction<{ images: string[]; index: number }>) {
      state.lightbox = action.payload.images;
      state.lightboxIndex = action.payload.index;
    },
    setLightboxIndex(state, action: PayloadAction<{ index: number }>) {
      state.lightboxIndex = action.payload.index;
    },

    setHideCropTypes(state, action: PayloadAction<{ hideCropTypes: string[] }>) {
      state.hideCropTypes = action.payload.hideCropTypes;
    },
  },
});

export default slice.reducer;
export const actions = slice.actions;

export const getMetersPerPixel = createSelector(
  (state: RootState) => MapboxGL.LngLat.convert(state.ui.viewport.center).lat,
  (state: RootState) => state.ui.viewport.zoom,
  (lat, zoom) => geography.metersPerPixel(lat, zoom)
);
