import produce from 'immer';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showSnackbar } from '~/lib/modalServices';
import Logging from '~/logging';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';

import * as selectors from './selectors';
import { actions } from './slice';

export default function EditGroupDialog({ open, onClose, group }) {
  const [changes, setchanges] = React.useState({});

  const dispatch = useDispatch();

  const newGroup = { ...group, ...changes };

  const sites = useSelector(selectors.orgData).sites || [];
  const users = useSelector(selectors.orgData).seats?.map((s) => s.user_id) || [];
  const roles = group.derived_roles || [];

  const save = async () => {
    try {
      dispatch(actions.updateGroup(newGroup));
      onClose();
    } catch (error) {
      Logging.error('Failed to create new Group', error);
      showSnackbar('error', 'Could not create Group.');
    }
  };

  const handleConfirm = () => {
    save();
    setchanges({});
  };

  const handleCancel = () => {
    setchanges({});
    onClose();
  };

  const setField = (field, value) => {
    setchanges({ ...changes, [field]: value });
  };

  return (
    <Dialog open={open} onClose={handleCancel} disableBackdropClick disableEscapeKeyDown>
      <List>
        <ListItem>
          <DialogTitle>
            Manage Group <em>{group.name}</em>
          </DialogTitle>
        </ListItem>

        <ListItem>
          <TextField
            fullWidth
            label='Group name'
            value={newGroup.name}
            onChange={(e) => setField('name', e.target.value)}
            error={newGroup.name == ''}
          />
        </ListItem>

        <ListItem>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>Sites included in group</FormLabel>
            <FormGroup>
              {sites.map((site) => (
                <FormControlLabel
                  key={site}
                  control={
                    <Checkbox
                      color='primary'
                      checked={newGroup.sites?.includes(site) || false}
                      onChange={(e) =>
                        setField(
                          'sites',
                          e.target.checked
                            ? [...new Set([...(newGroup.sites || []), site])]
                            : newGroup.sites?.filter((s) => s != site) || []
                        )
                      }
                    />
                  }
                  label={site}
                />
              ))}
            </FormGroup>
            <FormHelperText>
              Membership to this group, or this group&apos;s parents, will grant access to these
              sites.
            </FormHelperText>
          </FormControl>
        </ListItem>
        <Divider />
        <ListItem>
          <Typography variant='subtitle1'>Member Assignments</Typography>
        </ListItem>
        <ListItem style={{ flexDirection: 'column' }}>
          {users.map((username) => {
            const ex_member_idx = newGroup.members?.findIndex((m) => m.username == username);
            const ex_member = ex_member_idx > -1 ? newGroup.members[ex_member_idx] : null;

            return (
              <TextField
                key={username}
                size='small'
                select
                fullWidth
                label={username}
                InputLabelProps={{ shrink: true }}
                SelectProps={{
                  multiple: true,
                  displayEmpty: true,
                  value: ex_member?.roles || [],
                  onChange: (e) => {
                    if (ex_member_idx > -1) {
                      setField(
                        'members',
                        produce(newGroup.members, (members) => {
                          members[ex_member_idx].roles = e.target.value;
                        })
                      );
                    } else {
                      setField('members', [
                        ...(newGroup.members || []),
                        { username, roles: e.target.value },
                      ]);
                    }
                  },
                  renderValue: (selected) => selected?.join(', ') || '',
                }}>
                {roles.map((role) => (
                  <MenuItem key={role} value={role}>
                    <Checkbox color='primary' checked={ex_member?.roles?.includes(role) || false} />
                    <ListItemText primary={role} />
                  </MenuItem>
                ))}
              </TextField>
            );
          })}
        </ListItem>
      </List>
      <DialogContent style={{ overflowY: 'auto' }} />
      <DialogActions>
        <Button onClick={handleCancel} color='primary'>
          Cancel
        </Button>
        <Button onClick={handleConfirm} color='primary' disabled={Object.keys(changes).length == 0}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
