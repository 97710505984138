import React from 'react';

import { BasicStatsControlComponent, drawGridData } from './common';

const PREF_KEY = 'analysis_ndvi_mean';
const DEFAULT_PREFS = { stops: [0.2, 0.4, 0.6, 0.8], isPercentile: true };
const CTRL_SZ = [288, 26 * 8];
export const name = 'Stand Count';
export const description = 'Identifies and counts all distinguishable plants.';

export function overlay(key, analysis, cell_data, preferences, colorForNDVI) {
  const myPreferences = { ...DEFAULT_PREFS, ...(preferences[PREF_KEY] || {}) };

  return drawGridData(
    key,
    'count',
    myPreferences.stops,
    myPreferences.isPercentile,
    cell_data,
    1, // count is count
    preferences.analysisOpacity,
    preferences.analysisLabels,
    colorForNDVI
  );
}

function ControlComponent() {
  return (
    <BasicStatsControlComponent
      summary='Cells count the number of plants within each.'
      helpTopic='Count'
      pref_key={PREF_KEY}
      default_prefs={DEFAULT_PREFS}
      ctrl_sz={CTRL_SZ}
      allowPercentile
    />
  );
}

export function controls() {
  return {
    width: CTRL_SZ[0],
    height: CTRL_SZ[1],
    component: <ControlComponent />,
  };
}
